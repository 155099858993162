import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioBufferHelperOld from "models/audioEditor/AudioBufferHelperOld";
import Utils from "models/helper/Utils";
import AudioPosition from "models/audioEditor/AudioPosition";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import RSVP from "rsvp";

const DEFAULT_MIME_TYPE_FOR_AUDIO_RENDER = "audio/wav";
const Rollout = RolloutHelper.getInstance();
const TURN_ON_HACK_IN_AUDIO_EDITOR_TO_PREVENT_STALLED_PLAYBACK = Rollout.isFeatureEnabled(Rollout.FEATURES.TURN_ON_HACK_IN_AUDIO_EDITOR_TO_PREVENT_STALLED_PLAYBACK);
const DO_RENDER_FILE_FOR_PLAYBACK = true;
const SequencerRenderer =  Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);
        this._sequencer = attributes.sequencer;
        this._nodeCidToAudioBuffer = {};
        this._audioUrlToAudioId = {};
        this._nodeCidToAudioBufferRenderingPromise = {};
        this._maxNumberOfChannels = 0;
        this._finalRenderExportData = null;
        this._finalRenderBuffer = null;
        this._finalRenderDurationInMilliseconds = 0;
        this._finalRenderWithMasterEffectsDurationInMilliseconds = 0;
        this._finalRenderExportDataWithMasterEffectsApplied = null;
        this._finalRenderBufferWithMasterEffectsApplied = null;
        this._renderingInProgress =  null;
        this._arrayOfAudioPositionToSequencerNodePair = [];
        this._indexOFCurrentPlaybackAudioPosition = 0;
        this.set("renderedBufferIsPlaying", false);
        this.set("currentlyPlayingSequencerNode", null);
        this._sequencer.setRenderedBufferPlaying(false);
        this._updateCurrentlyPlayingSequencerNode(null);
    },


    _renderNode : function(nodeToRender){
        if(Utils.getInstance().isWordSequencerNodeInstance(nodeToRender)){
            return this._renderWordSequencerNode(nodeToRender);
        }
        if(Utils.getInstance().isAudioEventSequencerNodeInstance(nodeToRender)){
            return this._renderAudioEventSequencerNode(nodeToRender);
        }
        if(Utils.getInstance().isPauseSequencerNodeInstance(nodeToRender)){
            return this._renderPauseSequencerNode(nodeToRender);
        }
        if(Utils.getInstance().isPunctuationSequencerNodeInstance(nodeToRender)){
            return RSVP.Promise.resolve(null);
        }
        if(Utils.getInstance().isDeletedSequencerNodeInstance(nodeToRender)){
            return RSVP.Promise.resolve(null);
        }
        if(Utils.getInstance().isStartMusicWrapSequencerNodeInstance(nodeToRender)){
            return RSVP.Promise.resolve(null);
        }

        if(Utils.getInstance().isEndMusicWrapSequencerNodeInstance(nodeToRender)){
            return this._renderEndMusicWrapSequencerNode(nodeToRender);
        }

        if(Utils.getInstance().isAudioSequencerNodeInstance(nodeToRender)){
            return this._renderAudioSequencerNode(nodeToRender);
        }

        if(Utils.getInstance().isVideoSequencerNodeInstance(nodeToRender)){
            return this._renderVideoSequencerNode(nodeToRender);
        }
        const audioSegment = nodeToRender.getAudioSegment();
        if(Utils.getInstance().isUnsupportedAudioSegmentInstance(audioSegment)){
            return RSVP.Promise.resolve(null);
        }
    },

    getSequencer:function(){
        return this._sequencer;
    },

    _renderBasicSequencerNode : function(nodeToRender, audioType){
        const audioSegment = nodeToRender.getAudioSegment();
        if(audioSegment){
            const audioUrl = audioSegment.getAudioUrl();
            
            if(audioUrl){
                const previousNodeToCrossfadeAtTheBeginingWith = nodeToRender.getSequencerNodeToCrossfadeAtTheBeginingWith();
                const nextNodeToCrossfadeAtTheEndWith = nodeToRender.getSequencerNodeToCrossfadeAtTheEndWith();
                let previousNodeCrossfadeNodeOutsideAudioBuffer = null;
                let nextNodeCrossfadeNodeOutsideAudioBuffer = null;
                const promises = [];

                //Render the previous node ghose audiobuffer that should be merged with the node currently rendering
                if(previousNodeToCrossfadeAtTheBeginingWith && previousNodeToCrossfadeAtTheBeginingWith.getApplyCrossfadeAtTheEnd()){
                    const p1 = this._getPreviousNodeCrossfadeNodeOutsideAudioBuffer(previousNodeToCrossfadeAtTheBeginingWith).then(function(crossfadeStartBuffer){
                        if(crossfadeStartBuffer){
                            previousNodeCrossfadeNodeOutsideAudioBuffer = crossfadeStartBuffer;
                        }
                    });
                    promises.push(p1);
                }

                //Render the next node ghose audiobuffer that should be merged with the node currently rendering
                if(nextNodeToCrossfadeAtTheEndWith && nextNodeToCrossfadeAtTheEndWith.getApplyCrossfadeAtTheBegining()){
                    const p2 = this._getNextNodeCrossfadeNodeOutsideAudioBuffer(nextNodeToCrossfadeAtTheEndWith).then(function(crossfadeEndBuffer){
                        if(crossfadeEndBuffer){
                            nextNodeCrossfadeNodeOutsideAudioBuffer = crossfadeEndBuffer;
                        }
                    });
                    promises.push(p2);
                }

                return RSVP.Promise.all(promises).then((function(){
                    const startTimeInMilliseconds = audioSegment.getStartTime();
                    const endTimeInMilliseconds = audioSegment.getEndTime();

                    if(endTimeInMilliseconds == 0 || (endTimeInMilliseconds > 0 && startTimeInMilliseconds >= 0 && endTimeInMilliseconds - startTimeInMilliseconds == 0)){
                        return RSVP.Promise.resolve(null);
                    }
                    let promiseRetrieveAudioBuffer  = null;
                    if(endTimeInMilliseconds && startTimeInMilliseconds >= 0 && endTimeInMilliseconds - startTimeInMilliseconds > 0){
                        promiseRetrieveAudioBuffer = AudioBufferHelperOld.getInstance().getAudioBufferSlice(this._audioUrlToAudioId[audioUrl], startTimeInMilliseconds, endTimeInMilliseconds, audioType, this._sequencer.getSequencerSettings());
                    }else{
                        promiseRetrieveAudioBuffer = AudioBufferHelperOld.getInstance().getAudioBufferFromUrl(audioUrl, this._audioUrlToAudioId[audioUrl],  audioType, this._sequencer.getSequencerSettings());
                    }

                    return promiseRetrieveAudioBuffer.then((function(audioBuffer){
                        const applyCrossfadeForCurrentNodeAtBeginning = nodeToRender.needAutoCrossfadeIfNeighborNodesAreDifferentFromOriginal() && previousNodeToCrossfadeAtTheBeginingWith &&  previousNodeToCrossfadeAtTheBeginingWith.canLetFadeIfNeighborNodesAreDifferentFromOriginal();
                        const applyCrossfadeForCurrentNodeAtEnd = nodeToRender.needAutoCrossfadeIfNeighborNodesAreDifferentFromOriginal() && nextNodeToCrossfadeAtTheEndWith && nextNodeToCrossfadeAtTheEndWith.canLetFadeIfNeighborNodesAreDifferentFromOriginal();
                        return this._applyCrossfadeToSequencerNodeToGlueWithCloseNodes(nodeToRender, audioBuffer, applyCrossfadeForCurrentNodeAtBeginning, applyCrossfadeForCurrentNodeAtEnd);
                    }).bind(this)).then((function(audioBufferWithCrossfadeApplied){
                        return this._mergeAudioBufferWithCrossfadeAtBeginingAndEnd(audioBufferWithCrossfadeApplied, previousNodeCrossfadeNodeOutsideAudioBuffer,nextNodeCrossfadeNodeOutsideAudioBuffer);
                    }).bind(this)).then((function(audioBuffer){
                        return this._applyAudioFiltersFromTargetNodeToAudioBuffer(nodeToRender, audioBuffer);
                    }).bind(this)).then((function(finalAudioBufferWithEffectsApplied){
                        return finalAudioBufferWithEffectsApplied
                    }).bind(this))

                }).bind(this))


            }
        }
        return RSVP.Promise.resolve(null);
    },


    _getPreviousNodeCrossfadeNodeOutsideAudioBuffer : function(nodeToRender){
        if(nodeToRender && nodeToRender.getAudioSegment() && nodeToRender.getAudioSegment().getAudioUrl()){
            const audioSegment = nodeToRender.getAudioSegment();
            const audioUrl = audioSegment.getAudioUrl();
            const totalCrossFadeDurationInMilliseconds = nodeToRender.getApplyCrossfadeAtTheEndDurationInMilliseconds();
            const startTimeAndEndTime = audioSegment.getStartTimeAndEndTimeOfNextAudioSegment(totalCrossFadeDurationInMilliseconds/2);
            if(startTimeAndEndTime && startTimeAndEndTime.length == 2){
                const startTimeInMilliseconds = startTimeAndEndTime[0];
                const endTimeInMilliseconds = startTimeAndEndTime[1];
                return AudioBufferHelperOld.getInstance().getAudioBufferSlice(this._audioUrlToAudioId[audioUrl], startTimeInMilliseconds, endTimeInMilliseconds, AudioBufferHelperOld.getInstance().getAudioTypes().VOICE, this._sequencer.getSequencerSettings()).then((function(audioBuffer){
                    if(audioBuffer){
                //apply power crossfade for the half the duration from ~1 to 0 gain
                const audioBufferWithPaddingLeft =  AudioBufferHelperOld.getInstance().padAudioLeft(audioBuffer, totalCrossFadeDurationInMilliseconds/2);
                return AudioBufferHelperOld.getInstance().applyFadeOutToAudioBuffer(audioBufferWithPaddingLeft, 1, 0, totalCrossFadeDurationInMilliseconds, true);
                
            }
        }).bind(this)).then(function(audioBufferWithPaddingLeftAndCrossfadeApplied){
                    if(audioBufferWithPaddingLeftAndCrossfadeApplied){
                        return AudioBufferHelperOld.getInstance().getAudioBufferSliceFromBuffer(audioBufferWithPaddingLeftAndCrossfadeApplied, totalCrossFadeDurationInMilliseconds/2);
                    }	
                    return null;
                });
    }
}
return RSVP.Promise.resolve(null);

},

_getNextNodeCrossfadeNodeOutsideAudioBuffer : function(nodeToRender){
if(nodeToRender && nodeToRender.getAudioSegment() && nodeToRender.getAudioSegment().getAudioUrl()){
    const audioSegment = nodeToRender.getAudioSegment();
    const audioUrl = audioSegment.getAudioUrl();
    const totalCrossFadeDurationInMilliseconds = nodeToRender.getApplyCrossfadeAtTheBeginingDurationInMilliseconds();
    const startTimeAndEndTime = audioSegment.getStartTimeAndEndTimeOfPreviousAudioSegment(totalCrossFadeDurationInMilliseconds/2);
    if(startTimeAndEndTime && startTimeAndEndTime.length == 2){
        const startTimeInMilliseconds = startTimeAndEndTime[0];
        const endTimeInMilliseconds = startTimeAndEndTime[1];
        return AudioBufferHelperOld.getInstance().getAudioBufferSlice(this._audioUrlToAudioId[audioUrl], startTimeInMilliseconds, endTimeInMilliseconds, AudioBufferHelperOld.getInstance().getAudioTypes().VOICE, this._sequencer.getSequencerSettings()).then((function(audioBuffer){
            if(audioBuffer){
                //apply power crossfade for the half the duration from 0 to ~1 gain
                const audioBufferWithPaddingRight =  AudioBufferHelperOld.getInstance().padAudioRight(audioBuffer, totalCrossFadeDurationInMilliseconds/2);
                return AudioBufferHelperOld.getInstance().applyFadeInToAudioBuffer(audioBufferWithPaddingRight, 0, 1, totalCrossFadeDurationInMilliseconds, true);
                
            }
        }).bind(this)).then(function(audioBufferWithPaddingRightAndCrossfadeApplied){
            if(audioBufferWithPaddingRightAndCrossfadeApplied){
                return AudioBufferHelperOld.getInstance().getAudioBufferSliceFromBuffer(audioBufferWithPaddingRightAndCrossfadeApplied, 0 , totalCrossFadeDurationInMilliseconds/2);
            }	
            return null;
        });
    }
}
return RSVP.Promise.resolve(null);

},

_applyCrossfadeToSequencerNodeToGlueWithCloseNodes : function(nodeToRender, audioBufferToApplyCrossfadeOn, applyAtStart, applyAtTheEnd){
const totalCrossFadeInDurationInMilliseconds = nodeToRender.getApplyCrossfadeAtTheBeginingDurationInMilliseconds();
const totalCrossFadeOutDurationInMilliseconds = nodeToRender.getApplyCrossfadeAtTheEndDurationInMilliseconds();
if(applyAtStart && applyAtTheEnd){

    const audioBufferWithPaddingLeftAndRight = AudioBufferHelperOld.getInstance().padAudioLeft(AudioBufferHelperOld.getInstance().padAudioRight(audioBufferToApplyCrossfadeOn, totalCrossFadeOutDurationInMilliseconds/2), totalCrossFadeInDurationInMilliseconds/2);
    return AudioBufferHelperOld.getInstance().applyFadeInAndFadeOutToAudioBuffer(audioBufferWithPaddingLeftAndRight, 0, 1, totalCrossFadeInDurationInMilliseconds, 1, 0, totalCrossFadeOutDurationInMilliseconds, true).then((function(audioBufferCrossfadeWithPaddingLeftAndRight){
        return AudioBufferHelperOld.getInstance().getAudioBufferSliceFromBuffer(audioBufferCrossfadeWithPaddingLeftAndRight, totalCrossFadeInDurationInMilliseconds/2 , (AudioBufferHelperOld.getInstance().getMaxAudioBufferDuration(audioBufferCrossfadeWithPaddingLeftAndRight)*1000 -totalCrossFadeOutDurationInMilliseconds/2));
    }).bind(this));
}else{
    if(applyAtStart){
        const audioBufferWithPaddingLeft = AudioBufferHelperOld.getInstance().padAudioLeft(audioBufferToApplyCrossfadeOn, totalCrossFadeInDurationInMilliseconds/2);
        return AudioBufferHelperOld.getInstance().applyFadeInToAudioBuffer(audioBufferWithPaddingLeft, 0, 1, totalCrossFadeInDurationInMilliseconds, true).then((function(audioBufferCrossfadeWithPaddingLeft){
            return AudioBufferHelperOld.getInstance().getAudioBufferSliceFromBuffer(audioBufferCrossfadeWithPaddingLeft, totalCrossFadeInDurationInMilliseconds/2);
        }).bind(this));
    }else{
        if(applyAtTheEnd){
            const audioBufferWithPaddingRight = AudioBufferHelperOld.getInstance().padAudioRight(audioBufferToApplyCrossfadeOn, totalCrossFadeOutDurationInMilliseconds/2);
            return AudioBufferHelperOld.getInstance().applyFadeOutToAudioBuffer(audioBufferWithPaddingRight, 1, 0, totalCrossFadeOutDurationInMilliseconds, true).then((function(audioBufferCrossfadeWithPaddingRight){
                return AudioBufferHelperOld.getInstance().getAudioBufferSliceFromBuffer(audioBufferCrossfadeWithPaddingRight,0,  (AudioBufferHelperOld.getInstance().getMaxAudioBufferDuration(audioBufferCrossfadeWithPaddingRight)*1000 -totalCrossFadeOutDurationInMilliseconds/2));
            }).bind(this));
        }
    }

}
return RSVP.Promise.resolve(audioBufferToApplyCrossfadeOn);
},

_mergeAudioBufferWithCrossfadeAtBeginingAndEnd : function(audioBufferWithCrossfade,previousNodeCrossfadeNodeOutsideAudioBuffer, nextNodeCrossfadeNodeOutsideAudioBuffer ){
const audioBuffersToMerge = [audioBufferWithCrossfade];
if(previousNodeCrossfadeNodeOutsideAudioBuffer){
    audioBuffersToMerge.push(previousNodeCrossfadeNodeOutsideAudioBuffer);
}
if(nextNodeCrossfadeNodeOutsideAudioBuffer){
    const paddingInMillisecondsToAdd = AudioBufferHelperOld.getInstance().getMaxAudioBufferDuration(audioBufferWithCrossfade)*1000 - AudioBufferHelperOld.getInstance().getMaxAudioBufferDuration(nextNodeCrossfadeNodeOutsideAudioBuffer) *1000
    const nextNodeCrossfadeNodeOutsideAudioBufferWithLeftPadding = AudioBufferHelperOld.getInstance().padAudioLeft(nextNodeCrossfadeNodeOutsideAudioBuffer, paddingInMillisecondsToAdd);
    audioBuffersToMerge.push(nextNodeCrossfadeNodeOutsideAudioBufferWithLeftPadding);
    
}
if(audioBuffersToMerge.length > 1){
    return AudioBufferHelperOld.getInstance().mergeMultipleAudioBuffers(audioBuffersToMerge);
}else{
    return RSVP.Promise.resolve(audioBufferWithCrossfade);
}
},

_getAudioFiltersFromWrappedNodes: function(node){
let audioFiltersFromWrappedNode = [];
if(node.getStartWrapNodes()){
    for(let i =0; i < node.getStartWrapNodes().length; i++){
        const startNode = node.getStartWrapNodes()[i];
        if(startNode.isModifyingAudioBufferFromWrappedNodeAudioSegment()){
            audioFiltersFromWrappedNode = audioFiltersFromWrappedNode.concat(startNode.getAudioFilters());
        }
    }
}
return audioFiltersFromWrappedNode;
},

_renderWordSequencerNode : function(nodeToRender){
return this._renderBasicSequencerNode(nodeToRender, AudioBufferHelperOld.getInstance().getAudioTypes().VOICE);
},

_applyFiltersOnAudioBuffer : function(audioBuffer, audioFiltersArray){
return AudioBufferHelperOld.getInstance().applyFiltersToAudioBuffer(audioBuffer, audioFiltersArray);
},

_renderPauseSequencerNode : function(nodeToRender){
return this._renderBasicSequencerNode(nodeToRender, AudioBufferHelperOld.getInstance().getAudioTypes().VOICE);
},

_renderAudioEventSequencerNode : function(nodeToRender){
    return this._renderBasicSequencerNode(nodeToRender, AudioBufferHelperOld.getInstance().getAudioTypes().VOICE);
},

_renderEndMusicWrapSequencerNode : function(nodeToRender){
const audioSegment = nodeToRender.getAudioSegment();
if(!audioSegment || !nodeToRender.isCreatingAudioSegmentThatWillBeMergeWithWrappedNodeAudioSegment()){
    return RSVP.Promise.resolve(null);
}
const cidOfWrappedNodes = [];
const promisesOfNodeThatNeedToBerenderedFirst = [];
let nodeWrapped = nodeToRender.getStartWrappedNode();
const audioUrl = audioSegment.getAudioUrl();
const trimStart = audioSegment.getStartTime();
const trimEnd = audioSegment.getEndTime();
let totalDurationInMillisecondsOfContainedWrapNodes = 0;
while(nodeWrapped){
    if(Utils.getInstance().isWrapSequencerNodeInstance(nodeWrapped)){
        totalDurationInMillisecondsOfContainedWrapNodes = totalDurationInMillisecondsOfContainedWrapNodes + nodeWrapped.getCurrentNodeMargin();
    }else{
        const cid = nodeWrapped.cid;
        cidOfWrappedNodes.push(cid);
        const nodeRenderPromise =  this._nodeCidToAudioBufferRenderingPromise[cid];
        if(nodeRenderPromise){
            promisesOfNodeThatNeedToBerenderedFirst.push(nodeRenderPromise);
        }
    }
    if(nodeWrapped == nodeToRender.getEndWrappedNode()){
        nodeWrapped = null;
    }else{
        nodeWrapped = nodeWrapped.getNext();
    }
}

return Promise.all(promisesOfNodeThatNeedToBerenderedFirst).then((function(){
    const totalDurationInMillisecondsOfWrappedNodes = this._getDurationInMillisecondsOfNodeWithCid(cidOfWrappedNodes);
    const startPaddingInMilliseconds = nodeToRender.getIntroPaddingInMilliseconds();
    const endPaddingInMilliseconds = nodeToRender.getOutroPaddingInMilliseconds();
    const loopToMatchWrapNodeDuration = nodeToRender.getLoopToMatchWrapNodeDuration();
    const durationInMilliseconds = startPaddingInMilliseconds + totalDurationInMillisecondsOfWrappedNodes + endPaddingInMilliseconds + totalDurationInMillisecondsOfContainedWrapNodes;
    return AudioBufferHelperOld.getInstance().generateAudioBufferMatchingDuration(this._audioUrlToAudioId[audioUrl],trimStart, trimEnd, durationInMilliseconds, loopToMatchWrapNodeDuration, AudioBufferHelperOld.getInstance().getAudioTypes().WRAP, this._sequencer.getSequencerSettings());
}).bind(this)).then((function(audioBuffer){
    return this._applyAudioFiltersFromTargetNodeToAudioBuffer(nodeToRender, audioBuffer);
}).bind(this)).then((function(finalAudioBufferWithEffectsApplied){
    return finalAudioBufferWithEffectsApplied
}).bind(this))

},

_renderAudioSequencerNode : function(nodeToRender){
return this._renderBasicSequencerNode(nodeToRender, AudioBufferHelperOld.getInstance().getAudioTypes().INSERT);
},

_renderVideoSequencerNode : function(nodeToRender){
    return this._renderBasicSequencerNode(nodeToRender, AudioBufferHelperOld.getInstance().getAudioTypes().INSERT);
},

_applyAudioFiltersFromTargetNodeToAudioBuffer : function(targetNode, audioBuffer){
if(audioBuffer && targetNode){
    const nodeSpecificAudioFilters = targetNode.getAudioFilters();
    const audioFiltersFromWrappedNode = this._getAudioFiltersFromWrappedNodes(targetNode);
    const allAudioFilters = nodeSpecificAudioFilters.concat(audioFiltersFromWrappedNode);
    return this._applyFiltersOnAudioBuffer(audioBuffer, allAudioFilters).then((function(finalAudioBufferWithEffectsApplied){
        return finalAudioBufferWithEffectsApplied;
    }).bind(this))
}
return RSVP.Promise.resolve(null);

},

_getDurationInMillisecondsOfNodeWithCid : function(nodeCidArray){
let totalDurationInMilliseconds = 0;
if(nodeCidArray){
    for(let i = 0; i < nodeCidArray.length; i++){
        const audioBuffer = this._nodeCidToAudioBuffer[nodeCidArray[i]];
        if(audioBuffer && AudioBufferHelperOld.getInstance().getMaxAudioBufferDuration(audioBuffer)){
            totalDurationInMilliseconds = totalDurationInMilliseconds + AudioBufferHelperOld.getInstance().getMaxAudioBufferDuration(audioBuffer) * 1000;
        }
    }
}
return totalDurationInMilliseconds;
},



_loadAllMasterAudioBuffersInAudioBufferHelper : function(){
const audioUrlsToLoad = [];
const audioUrlsToAudioTypes = {};
let node = this._sequencer.getFirstNode();
const promises = [];
while(node){
    const audioSegment = node.getAudioSegment();
    if(audioSegment){
        const audioUrl = audioSegment.getAudioUrl();
        let audioType;
        if(audioUrl){
            if(audioUrlsToLoad.indexOf(audioUrl) == -1){
                audioUrlsToLoad.push(audioUrl);
            } 
            if(!audioUrlsToAudioTypes[audioUrl]){
                audioUrlsToAudioTypes[audioUrl] = [];
            }
            if(Utils.getInstance().isWordSequencerNodeInstance(node)){
                audioType = AudioBufferHelperOld.getInstance().getAudioTypes().VOICE;
                if(audioUrlsToAudioTypes[audioUrl].indexOf(audioType) == -1){
                    audioUrlsToAudioTypes[audioUrl].push(audioType);
                }
            }
            if(Utils.getInstance().isAudioEventSequencerNodeInstance(node)){
                audioType = AudioBufferHelperOld.getInstance().getAudioTypes().VOICE;
                if(audioUrlsToAudioTypes[audioUrl].indexOf(audioType) == -1){
                    audioUrlsToAudioTypes[audioUrl].push(audioType);
                }
            }
            if(Utils.getInstance().isPauseSequencerNodeInstance(node)){
                audioType = AudioBufferHelperOld.getInstance().getAudioTypes().VOICE;
                if(audioUrlsToAudioTypes[audioUrl].indexOf(audioType) == -1){
                    audioUrlsToAudioTypes[audioUrl].push(audioType);
                }
            }


            if(Utils.getInstance().isEndMusicWrapSequencerNodeInstance(node)){
                audioType = AudioBufferHelperOld.getInstance().getAudioTypes().WRAP;
                if(audioUrlsToAudioTypes[audioUrl].indexOf(audioType) == -1){
                    audioUrlsToAudioTypes[audioUrl].push(audioType);
                }
            }

            if(Utils.getInstance().isAudioSequencerNodeInstance(node)){
                audioType = AudioBufferHelperOld.getInstance().getAudioTypes().INSERT;
                if(audioUrlsToAudioTypes[audioUrl].indexOf(audioType) == -1){
                    audioUrlsToAudioTypes[audioUrl].push(audioType);
                }
            }
        }
    }
    node = node.getNext();
}
/*for(let i =0; i < audioUrlsToLoad.length; i++){
    const audioUrl = audioUrlsToLoad[i];
    const audioId = audioUrl;
    this._audioUrlToAudioId[audioUrl] = audioId;
    promises.push(AudioBufferHelperOld.getInstance().loadAudioBufferFromUrl(audioUrl, audioId));
}*/
for(let audioUrl in audioUrlsToAudioTypes){
    const audioTypes  = audioUrlsToAudioTypes[audioUrl];
    const audioId = audioUrl;
    this._audioUrlToAudioId[audioUrl] = audioId;
    if(audioTypes.length > 0){
        for(let i =0; i < audioTypes.length; i++){
            const audioType = audioTypes[i];


            promises.push(AudioBufferHelperOld.getInstance().loadAudioBufferFromUrl(audioUrl, audioId, audioType, this._sequencer.getSequencerSettings()));
        }
    }else{
        promises.push(AudioBufferHelperOld.getInstance().loadAudioBufferFromUrl(audioUrl, audioId, null, this._sequencer.getSequencerSettings()));
    }
}
return Promise.all(promises);
},

_createAudioBufferForEachSequencerNode : function(){
let nodeToRender = this._sequencer.getFirstNode();
const promises = [];
while(nodeToRender){
    const nodeCid = nodeToRender.cid;
    const promiseAudioBufferRendering = this._renderNode(nodeToRender);
    this._nodeCidToAudioBufferRenderingPromise[nodeToRender.cid] = promiseAudioBufferRendering;
    promiseAudioBufferRendering.then((function(audioBuffer){
        //if(audioBuffer){
            this.self._addAudioBufferToMapByCid(this.nodeCid, audioBuffer);
            //}
        }).bind({"self": this, "nodeCid": nodeCid}))
    promises.push(promiseAudioBufferRendering);
    nodeToRender = nodeToRender.getNext();
}
return Promise.all(promises);
},

_addAudioBufferToMapByCid : function(cid, audioBuffer){
if(audioBuffer){
    this._nodeCidToAudioBuffer[cid] = audioBuffer;
    if(this._maxNumberOfChannels < audioBuffer.numberOfChannels){
        this._maxNumberOfChannels = audioBuffer.numberOfChannels;
    }
}
},

_trackSequencerNodeAudioPosition :  function(sequencerNode, startTimeInMilliseconds, endTimeInMilliseconds){
const audioPosition = new AudioPosition();
audioPosition.setStartTime(startTimeInMilliseconds);
audioPosition.setEndTime(endTimeInMilliseconds);
this._arrayOfAudioPositionToSequencerNodePair.push([audioPosition, sequencerNode]);
},



_concatenateAllAudioBuffersToCreateOne : function(){
let renderedNodeToConcatenate = this._sequencer.getFirstNode();
const arrayOfAudioBufferToConcatenateWithoutWrapNode = [];
const arrayOfStartWrapNodes = [];
let totalBufferDurationInMilliseconds = 0;
const startWrapNodeCidToStartTimeInMilliseconds = {};
const startWrapNodeCidToPaddedAudioBuffer = {};
const arrayOfWrapNodePaddedAudioBuffer = [];
const arrayOfDuckingPropertiesForWrapAudioBuffer =  [];
const arrayOfTotalDurationForWrapAudioBuffer = [];
const arrayOfDuckingMargin = [];

const paddedAudioBufferCreationPromises = [];
let baseAudioBufferWithoutWrapAudioMerge = null;
let atLeastOneNodeHasDuckingTurnedOn = false;

while(renderedNodeToConcatenate){
    if(Utils.getInstance().isWrapSequencerNodeInstance(renderedNodeToConcatenate)){
        if(Utils.getInstance().isStartMusicWrapSequencerNodeInstance(renderedNodeToConcatenate)){
            const startNodeCid = renderedNodeToConcatenate.cid;
            arrayOfStartWrapNodes.push(renderedNodeToConcatenate);
            startWrapNodeCidToStartTimeInMilliseconds[startNodeCid] = totalBufferDurationInMilliseconds;
            const matchingEndNode = renderedNodeToConcatenate.getEndWrapSequencerNode();
            const isDuckingTurnedOn =  !!renderedNodeToConcatenate.getDucking();
            if(!atLeastOneNodeHasDuckingTurnedOn  && isDuckingTurnedOn){
                atLeastOneNodeHasDuckingTurnedOn =  true;
            }
            const endNodeCid = matchingEndNode.cid;
            const audioBufferForNode = this._nodeCidToAudioBuffer[endNodeCid];
            if(audioBufferForNode){
                /*const prommisePaddedAudioBufferCreationPromise = AudioBufferHelperOld.getInstance().padAudioBuffer(audioBufferForNode, totalBufferDurationInMilliseconds, 0).then((function(paddedAudioBuffer){
                    startWrapNodeCidToPaddedAudioBuffer[this.startNodeCid] = paddedAudioBuffer;
                    arrayOfWrapNodePaddedAudioBuffer.push(paddedAudioBuffer);
                    arrayOfTotalDurationForWrapAudioBuffer.push(totalBufferDurationInMilliseconds);
                    arrayOfDuckingPropertiesForWrapAudioBuffer.push(this.isDuckingTurnedOn);
                }).bind({"self": this, "startNodeCid": startNodeCid,"endNodeCid": endNodeCid,"isDuckingTurnedOn": isDuckingTurnedOn}));
                paddedAudioBufferCreationPromises.push(prommisePaddedAudioBufferCreationPromise);*/

                arrayOfWrapNodePaddedAudioBuffer.push(audioBufferForNode);
                arrayOfTotalDurationForWrapAudioBuffer.push(totalBufferDurationInMilliseconds);
                arrayOfDuckingPropertiesForWrapAudioBuffer.push(isDuckingTurnedOn);
                arrayOfDuckingMargin.push({"startMargin": renderedNodeToConcatenate.getIntroPaddingInMilliseconds(), "endMargin": renderedNodeToConcatenate.getOutroPaddingInMilliseconds() })
            }
        }
        const marginInMilliseconds = renderedNodeToConcatenate.getCurrentNodeMargin();

        if(marginInMilliseconds){
            arrayOfAudioBufferToConcatenateWithoutWrapNode.push(AudioBufferHelperOld.getInstance().generateAudioBufferSilence(marginInMilliseconds, this._maxNumberOfChannels));
        }
        this._trackSequencerNodeAudioPosition(renderedNodeToConcatenate, totalBufferDurationInMilliseconds, totalBufferDurationInMilliseconds + marginInMilliseconds);
        totalBufferDurationInMilliseconds = totalBufferDurationInMilliseconds + marginInMilliseconds;


    }else{
        const nodeCid = renderedNodeToConcatenate.cid;
        const audioBuffer = this._nodeCidToAudioBuffer[nodeCid];
        if(audioBuffer){
            arrayOfAudioBufferToConcatenateWithoutWrapNode.push(audioBuffer);
            this._trackSequencerNodeAudioPosition(renderedNodeToConcatenate, totalBufferDurationInMilliseconds, totalBufferDurationInMilliseconds + AudioBufferHelperOld.getInstance().getMaxAudioBufferDuration(audioBuffer)*1000);
            totalBufferDurationInMilliseconds = totalBufferDurationInMilliseconds + AudioBufferHelperOld.getInstance().getMaxAudioBufferDuration(audioBuffer)*1000;
        }

    }
    renderedNodeToConcatenate = renderedNodeToConcatenate.getNext();
}
const concatenateBaseAudioBufferPromise =  AudioBufferHelperOld.getInstance().concatenateAudioBuffers(arrayOfAudioBufferToConcatenateWithoutWrapNode).then((function(audioBufferWithoutWrapAudioMerged){
    baseAudioBufferWithoutWrapAudioMerge = audioBufferWithoutWrapAudioMerged;
}).bind(this))
const promises = [concatenateBaseAudioBufferPromise].concat(paddedAudioBufferCreationPromises);

return Promise.all(promises).then((function(){
    if(arrayOfWrapNodePaddedAudioBuffer && arrayOfWrapNodePaddedAudioBuffer.length > 0){
    //if(atLeastOneNodeHasDuckingTurnedOn){
        return AudioBufferHelperOld.getInstance().mergeMultipleAudioBuffersWithDuckingAndAddPadding(arrayOfWrapNodePaddedAudioBuffer, baseAudioBufferWithoutWrapAudioMerge, arrayOfDuckingPropertiesForWrapAudioBuffer, arrayOfTotalDurationForWrapAudioBuffer, arrayOfDuckingMargin);
        //return AudioBufferHelperOld.getInstance().mergeMultipleAudioBuffersWithDucking(arrayOfWrapNodePaddedAudioBuffer, baseAudioBufferWithoutWrapAudioMerge, arrayOfDuckingPropertiesForWrapAudioBuffer);
        /*}else{
            arrayOfWrapNodePaddedAudioBuffer.push(baseAudioBufferWithoutWrapAudioMerge);
            return AudioBufferHelperOld.getInstance().mergeMultipleAudioBuffers(arrayOfWrapNodePaddedAudioBuffer)	
        }*/
    }else{
        return baseAudioBufferWithoutWrapAudioMerge;
    }
}).bind(this));


},


_updateCurrentlyPlayingSequencerNode : function(sequencerNode){
const currentPlayingSequencerNode  = this.get("currentlyPlayingSequencerNode");
if(currentPlayingSequencerNode){
    currentPlayingSequencerNode.setPlaying(false);
}
if(sequencerNode){
    sequencerNode.setPlaying(true);
}
this.set("currentlyPlayingSequencerNode", sequencerNode);
},

_updateCurrentlyPlayingNode : function(){
if(this._audioBufferSourceNodeToPlay){
    const playbackTImeInMilliseconds = AudioBufferHelperOld.getInstance().getPlaybackTimeForSourceNode(this._audioBufferSourceNodeToPlay);
    for(let i =0;  i < this._arrayOfAudioPositionToSequencerNodePair.length; i++){
        const index = (this._indexOFCurrentPlaybackAudioPosition + i)% this._arrayOfAudioPositionToSequencerNodePair.length;
        const pair = this._arrayOfAudioPositionToSequencerNodePair[index];
        const audioPosition = pair[0];
        const sequencerNode = pair[1];
        if(audioPosition.getStartTime() <= playbackTImeInMilliseconds && playbackTImeInMilliseconds <= audioPosition.getEndTime() ){
            this._indexOFCurrentPlaybackAudioPosition  = index;
            this._updateCurrentlyPlayingSequencerNode(sequencerNode);
        }
    }
}
},


_onAudioBufferFinishPlaying: function(){
this.stopPlayingRenderedBuffer(true);
},

_onAudioBufferStartingPlaying: function(){
this._onAudioBufferPlaybackLoaded();
this.set("renderedBufferIsPlaying", true);
this._sequencer.setRenderedBufferPlaying(true);
this._scheduleStopPlayerOnAudioStartedPlaying()
},

_scheduleStopPlayerOnAudioStartedPlaying : function(){
if(this._stopAfterMilliseconds > 0){
    this._pendingStopPlayerTimeoutFunctionId =  setTimeout((function(){
        this.stopPlayingRenderedBuffer();
        this._clearScheduledStopPlayer();
    }).bind(this), this._stopAfterMilliseconds);
}	
},

_clearScheduledStopPlayer: function(stopAfterMilliseconds){
if(this._pendingStopPlayerTimeoutFunctionId){
    clearTimeout(this._pendingStopPlayerTimeoutFunctionId);
    this._stopAfterMilliseconds = null;
}
if(stopAfterMilliseconds > 0){
    this._stopAfterMilliseconds = stopAfterMilliseconds;
}
},

_onAudioBufferPlaybackLoading : function(){
this.set("renderedBufferPlaybackLoading", true);
this._sequencer.setRenderedBufferPlaybackLoading(true);
},

_onAudioBufferPlaybackLoaded : function(){
this.set("renderedBufferPlaybackLoading", false);
this._sequencer.setRenderedBufferPlaybackLoading(false);
},

_createAndPlayAudioBufferSourceNode : function(sequencerNodeToStartFrom, stopAfterMilliseconds, sequencerNodeToStopPlayAt, onAudioErrorPlayingHandler){
this._onAudioBufferPlaybackLoading();

const audioPositionAndIndex = sequencerNodeToStartFrom ? this._getAudioPositionAndIndexForSequencerNode(sequencerNodeToStartFrom): null;
const index = audioPositionAndIndex? audioPositionAndIndex.index: 0;
const audioPositionInMillisecondsToStartFrom  = audioPositionAndIndex? audioPositionAndIndex.audioPosition.getStartTime(): 0;
const audioPositionInMillisecondsToEndAtFrom  = audioPositionAndIndex? audioPositionAndIndex.audioPosition.getEndTime() + stopAfterMilliseconds: 0 + stopAfterMilliseconds;
if(!stopAfterMilliseconds && sequencerNodeToStopPlayAt){
    const nodeToStopAtPositionAndIndex = this._getAudioPositionAndIndexForSequencerNode(sequencerNodeToStopPlayAt);
    if(nodeToStopAtPositionAndIndex && nodeToStopAtPositionAndIndex.audioPosition && nodeToStopAtPositionAndIndex.audioPosition.getEndTime()){
        stopAfterMilliseconds = nodeToStopAtPositionAndIndex.audioPosition.getEndTime() - audioPositionInMillisecondsToStartFrom;
    }
}
this._clearScheduledStopPlayer(stopAfterMilliseconds);
const onPlaybackError = (function(error){
    if(!TURN_ON_HACK_IN_AUDIO_EDITOR_TO_PREVENT_STALLED_PLAYBACK || !window.chrome || (error && error.code !== 3) ){
        //Only show error if the hack is turned off and the error code is 3 which is "AUDIO_RENDERER_ERROR: audio render error"
        window.alertErrorMessage(error);
        this.stopPlayingRenderedBuffer(true);
    }
    if(onAudioErrorPlayingHandler){
        onAudioErrorPlayingHandler(error);
    }
}).bind(this)
this._audioBufferSourceNodeToPlay = null;
if(DO_RENDER_FILE_FOR_PLAYBACK){
    this._audioBufferSourceNodeToPlay = AudioBufferHelperOld.getInstance().playAudioBuffer(this._getLatestRenderedBuffer(), 0,  audioPositionInMillisecondsToStartFrom, this._onAudioBufferFinishPlaying.bind(this), this._onAudioBufferStartingPlaying.bind(this), onPlaybackError);
}else{
    this._audioBufferSourceNodeToPlay = AudioBufferHelperOld.getInstance().playAudioUrl(this._getLatestRenderedData().url, 0,  audioPositionInMillisecondsToStartFrom, this._onAudioBufferFinishPlaying.bind(this), this._onAudioBufferStartingPlaying.bind(this), onPlaybackError);
}
this._indexOFCurrentPlaybackAudioPosition  = index;
this._updateCurrentlyPlayingSequencerNode(sequencerNodeToStartFrom);
this._timerIdToTrackPlaybackProgress = setInterval(this._updateCurrentlyPlayingNode.bind(this), 100);
},


_reinitialize : function(){
this._arrayOfAudioPositionToSequencerNodePair = [];
},



_updateFinalRenderDurationInMilliseconds : function(finalRenderDurationInMilliseconds){
this._finalRenderDurationInMilliseconds = finalRenderDurationInMilliseconds;
},	

_updateFinalRenderWithMasterEffectsDurationInMilliseconds : function(finalRenderWithMasterEffectsDurationInMilliseconds){
this._finalRenderWithMasterEffectsDurationInMilliseconds = finalRenderWithMasterEffectsDurationInMilliseconds;
},	

isLatestRenderedBufferCreated: function(){
return DO_RENDER_FILE_FOR_PLAYBACK? this._isLatestRenderedBufferCreated(): this._isLatestRenderedDataCreated();
},


_updateLatestRenderedDataCreated: function(finalRenderExportData){
this._finalRenderExportData = finalRenderExportData;
},

_isLatestRenderedDataCreated: function(){
!!this._finalRenderExportData;
},

_updateLatestRenderedBufferCreated: function(finalRenderBuffer){
this._finalRenderBuffer = finalRenderBuffer;
},

_isLatestRenderedBufferCreated: function(){
!!this._finalRenderBuffer;
},

_clearLatestRenderedBufferCreated : function(){
delete this._finalRenderBuffer;
},


_isFinalRenderedDataWithMasterEffectsAppliedCreated: function(){
return !!this._finalRenderExportDataWithMasterEffectsApplied;
},

_isFinalRenderedBufferWithMasterEffectsAppliedCreated: function(){
return !!this._finalRenderBufferWithMasterEffectsApplied;
},

_updateFinalRenderedDataWithMasterEffectsAppliedCreated: function(finalRenderExportDataWithMasterEffectsApplied){
this._finalRenderExportDataWithMasterEffectsApplied = finalRenderExportDataWithMasterEffectsApplied;
},

_updateFinalRenderedBufferWithMasterEffectsAppliedCreated: function(finalRenderBufferWithMasterEffectsApplied){
this._finalRenderBufferWithMasterEffectsApplied = finalRenderBufferWithMasterEffectsApplied;
},

_getFinalRenderedDataWithMasterEffectsApplied: function(){
return this._finalRenderExportDataWithMasterEffectsApplied;
},

_clearFinalRenderedBufferWithMasterEffectsAppliedCreated:function(){
delete this._finalRenderBufferWithMasterEffectsApplied;
},


_getFinalRenderedBufferWithMasterEffectsApplied: function(){
return this._finalRenderBufferWithMasterEffectsApplied;
},

_getLatestRenderedData : function(){
return this._finalRenderExportDataWithMasterEffectsApplied ? this._finalRenderExportDataWithMasterEffectsApplied: this._finalRenderExportData;
},

_getLatestRenderedBuffer : function(){
return this._finalRenderBufferWithMasterEffectsApplied ? this._finalRenderBufferWithMasterEffectsApplied: this._finalRenderBuffer;
},


_applyDefaultMasterAudioFiltersToFinalRender :function(finalRenderedAudioBuffer, applyMasterProcessing){
if(applyMasterProcessing){
    return AudioBufferHelperOld.getInstance().applyFiltersToAudioBuffer(finalRenderedAudioBuffer, this._sequencer.getSequencerSettings().getMasterDefaultFiltersArray());
}else{
    return RSVP.Promise.resolve(finalRenderedAudioBuffer);
}
},

_cleanupAfterFinalBufferCreated : function(){
delete this._nodeCidToAudioBuffer;
delete this._nodeCidToAudioBufferRenderingPromise;
this._nodeCidToAudioBuffer = {};
this._nodeCidToAudioBufferRenderingPromise =  {};
return RSVP.Promise.resolve();
},

playPauseRenderedBuffer: function(){
if(this.isRenderedBufferPlaying()){
    this.stopPlayingRenderedBuffer();
}else{
    this.playRenderedBuffer();
}
},

isRenderedBufferPlaying: function(){
return this.get("renderedBufferIsPlaying");
},


stopPlayingRenderedBuffer : function(bufferSourceFinishPlaying){
if(this._audioBufferSourceNodeToPlay){
    this._audioBufferSourceNodeToPlay.stop();
    this._audioBufferSourceNodeToPlay = null;
}
if(this._timerIdToTrackPlaybackProgress){
    clearInterval(this._timerIdToTrackPlaybackProgress);
    this._timerIdToTrackPlaybackProgress = null;
}

this._updateCurrentlyPlayingSequencerNode(null);
this.set("renderedBufferIsPlaying", false);
this._sequencer.setRenderedBufferPlaying(false);
if(bufferSourceFinishPlaying){
    this._sequencer.resetPlaybackPositionOnAudioPlaybackCompleted();
}
return RSVP.Promise.resolve(true);
},

_getAudioPositionAndIndexForSequencerNode : function(sequencerNode, indexToStartFrom){
if(sequencerNode){
    if(!indexToStartFrom){
        indexToStartFrom = 0;
    }
    for(let i =0;  i < this._arrayOfAudioPositionToSequencerNodePair.length; i++){
        const index = (indexToStartFrom + i)% this._arrayOfAudioPositionToSequencerNodePair.length;
        const pair = this._arrayOfAudioPositionToSequencerNodePair[index];
        const audioPosition = pair[0];
        const node = pair[1];
        if(sequencerNode === node){
            return {"index": index, "audioPosition": audioPosition};
        }
    }
}
return null;
},

getSequencerNodeAtPositionInMilliseconds: function(positionInMilliseconds){
for(let i =0;  i < this._arrayOfAudioPositionToSequencerNodePair.length; i++){
    const pair = this._arrayOfAudioPositionToSequencerNodePair[i];
    const audioPosition = pair[0];
    const node = pair[1];
    if(audioPosition.getStartTime() <= positionInMilliseconds && positionInMilliseconds <= audioPosition.getEndTime() ){
        return node;
    }
}

return null;
},

isPlaying : function(){
return this.get("renderedBufferIsPlaying");
},

playRenderedBuffer : function(sequencerNodeToStartFrom, stopAfterMilliseconds, nodeToStopPlayAt, onAudioErrorPlayingHandler){
this.stopPlayingRenderedBuffer();
this._createAndPlayAudioBufferSourceNode(sequencerNodeToStartFrom, stopAfterMilliseconds, nodeToStopPlayAt, onAudioErrorPlayingHandler);
return this._audioBufferSourceNodeToPlay;
},


getFinalRenderDurationInMilliseconds : function(){
return this._finalRenderDurationInMilliseconds;
},


getPlaybackProgressInMilliseconds : function(){
return this._audioBufferSourceNodeToPlay ? AudioBufferHelperOld.getInstance().getPlaybackTimeForSourceNode(this._audioBufferSourceNodeToPlay): 0;
},

getPlaybackAudioContextCurrentTime : function(){
return this._audioBufferSourceNodeToPlay ? AudioBufferHelperOld.getInstance().getAudioContextCurrentTimeForSourceNode(this._audioBufferSourceNodeToPlay): 0;
},

canPlayThrough : function(){
return this._audioBufferSourceNodeToPlay ? AudioBufferHelperOld.getInstance().canPlayThrough(this._audioBufferSourceNodeToPlay): false;
},

getRenderedAudioBlob: function(renderWavFile){
if(DO_RENDER_FILE_FOR_PLAYBACK){
    const retrieveAudioBufferPromise = this._isFinalRenderedBufferWithMasterEffectsAppliedCreated()? RSVP.Promise.resolve(this._getFinalRenderedBufferWithMasterEffectsApplied()): this.renderSequencer(true);
    return retrieveAudioBufferPromise.then((function(b){
        return AudioBufferHelperOld.getInstance().export(b, DEFAULT_MIME_TYPE_FOR_AUDIO_RENDER);
    }).bind(this)).then((function(result){
        return result.blob;
    }).bind(this));
}else{
    let promiseGetRenderedBlob = null;
    if(this._isFinalRenderedDataWithMasterEffectsAppliedCreated()){
        promiseGetRenderedBlob = RSVP.Promise.resolve(this._getFinalRenderedDataWithMasterEffectsApplied().blob);
    }else{
        promiseGetRenderedBlob = this.renderSequencer(true).then((function(){
            return this._getFinalRenderedDataWithMasterEffectsApplied().blob;
        }).bind(this))
    }
    return promiseGetRenderedBlob;
}
},

getRenderedAudioBlobClip: function(renderWavFile, applyMasterProcessing, sequencerNodeToStartFrom, sequencerNodeToEndOn){
this._sequencer.setRenderingBeingCreated(true);
return this._renderSequencerAudioBuffer().then((function(finalRenderedAudioBuffer){
    if(applyMasterProcessing){
        return this._applyDefaultMasterAudioFiltersToFinalRender(finalRenderedAudioBuffer, true);
    }else{
        return finalRenderedAudioBuffer;
    }
}).bind(this)).then((function(finalRenderedBufferWithMasterEffectsAppliedOrNot){
    const finalRenderedBufferWithMasterEffectsApplied = finalRenderedBufferWithMasterEffectsAppliedOrNot;
    if(sequencerNodeToStartFrom && sequencerNodeToEndOn){
        const startAudioPositionAndIndex = this._getAudioPositionAndIndexForSequencerNode(sequencerNodeToStartFrom);
        const endAudioPositionAndIndex = this._getAudioPositionAndIndexForSequencerNode(sequencerNodeToEndOn);
        if(startAudioPositionAndIndex && endAudioPositionAndIndex){
            const audioPositionInMillisecondsToStartFrom  =  startAudioPositionAndIndex.audioPosition.getStartTime();
            const audioPositionInMillisecondsToEndFrom  = endAudioPositionAndIndex.audioPosition.getEndTime();
            return  AudioBufferHelperOld.getInstance().getAudioBufferSliceFromBuffer(finalRenderedBufferWithMasterEffectsApplied, audioPositionInMillisecondsToStartFrom, audioPositionInMillisecondsToEndFrom);
        }else{
            return finalRenderedBufferWithMasterEffectsApplied;
        }
    }else{
        return finalRenderedBufferWithMasterEffectsApplied;
    }
}).bind(this)).then((function(clippedFinalRenderedBufferWithMasterEffectsApplied){
    if(clippedFinalRenderedBufferWithMasterEffectsApplied){
        return  AudioBufferHelperOld.getInstance().export(clippedFinalRenderedBufferWithMasterEffectsApplied, renderWavFile?'audio/wav': null);
    }
}).bind(this)).then((function(renderedAudioData){
    this._sequencer.setRenderingBeingCreated(false);
    if(renderedAudioData){
        return renderedAudioData.blob;
    }
}).bind(this)).catch((function(error){
    this._sequencer.setRenderingBeingCreated(false);
    throw error;
}).bind(this));
},


_renderSequencerAudioBuffer : function(){
this._reinitialize();
const startTimeOfLoadingAllMasters =  new Date().getTime();
let startTimeCreateAudioBuffers =  null;
let startTimeConcatenateAudioBuffers =  null;
return this._loadAllMasterAudioBuffersInAudioBufferHelper().then((function(){
    console.log("Loading all masters duration "+(new Date().getTime() - startTimeOfLoadingAllMasters));
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackTiming("Loading all masters duration for sequencer renderer",   ( new Date().getTime() - startTimeOfLoadingAllMasters)) ;
    }
    startTimeCreateAudioBuffers =  new Date().getTime();
    return this._createAudioBufferForEachSequencerNode();	
}).bind(this)).then((function(){
    console.log("Creating audio buffers duration "+(new Date().getTime() - startTimeCreateAudioBuffers));
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackTiming("Creating audio buffers duration for sequencer renderer",   (new Date().getTime() - startTimeCreateAudioBuffers)) ;
    }
    startTimeConcatenateAudioBuffers =  new Date().getTime();
    return this._concatenateAllAudioBuffersToCreateOne();
}).bind(this)).then((function(result){
    console.log("concatenating audio buffers duration "+(new Date().getTime() - startTimeConcatenateAudioBuffers));
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackTiming("concatenating audio buffers duration for sequencer renderer",   (new Date().getTime() - startTimeConcatenateAudioBuffers)) ;
    }
    return result;
}).bind(this));
},


renderSequencer : function(applyMasterProcessing){
this._sequencer.setRenderingBeingCreated(true);
//this._reinitialize();
this._clearLatestRenderedBufferCreated();
this._clearFinalRenderedBufferWithMasterEffectsAppliedCreated();
let finalAudioBuffer = null;
let finalAudioBufferWithMasterEffects = null;
/*return this._loadAllMasterAudioBuffersInAudioBufferHelper().then((function(){
    return this._createAudioBufferForEachSequencerNode();	
}).bind(this)).then((function(){
    return this._concatenateAllAudioBuffersToCreateOne();
}).bind(this)).then((function(finalRenderedAudioBuffer){*/
let startTimeExporting = null;
    return this._renderSequencerAudioBuffer().then((function(finalRenderedAudioBuffer){
        finalAudioBuffer = finalRenderedAudioBuffer;
        this._updateFinalRenderDurationInMilliseconds(AudioBufferHelperOld.getInstance().getMaxAudioBufferDuration(finalRenderedAudioBuffer) * 1000);
        this._cleanupAfterFinalBufferCreated();
        startTimeExporting = new Date().getTime();
        if(!DO_RENDER_FILE_FOR_PLAYBACK){
            return AudioBufferHelperOld.getInstance().export(finalRenderedAudioBuffer, DEFAULT_MIME_TYPE_FOR_AUDIO_RENDER);
        }else{
            this._updateLatestRenderedBufferCreated(finalRenderedAudioBuffer);
        }
    }).bind(this)).then((function(finalRenderExportData){
        console.log("exporting audio buffers duration "+(new Date().getTime() - startTimeExporting));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackTiming("exporting audio buffers duration for sequencer renderer",   (new Date().getTime() - startTimeExporting)) ;
        }
        if(!DO_RENDER_FILE_FOR_PLAYBACK){
            this._updateLatestRenderedDataCreated(finalRenderExportData);
        }
        return this._applyDefaultMasterAudioFiltersToFinalRender(finalAudioBuffer, applyMasterProcessing);
    }).bind(this)).then((function(finalRenderedBufferWithMasterEffectsAppliedOrNot){
        if(applyMasterProcessing){
            finalAudioBufferWithMasterEffects = finalRenderedBufferWithMasterEffectsAppliedOrNot;
            this._updateFinalRenderWithMasterEffectsDurationInMilliseconds(AudioBufferHelperOld.getInstance().getMaxAudioBufferDuration(finalRenderedBufferWithMasterEffectsAppliedOrNot) * 1000);
            if(!DO_RENDER_FILE_FOR_PLAYBACK){
                return AudioBufferHelperOld.getInstance().export(finalAudioBufferWithMasterEffects, DEFAULT_MIME_TYPE_FOR_AUDIO_RENDER);
            }else{
                this._updateFinalRenderedBufferWithMasterEffectsAppliedCreated(finalRenderedBufferWithMasterEffectsAppliedOrNot);
            }
        }
    }).bind(this)).then((function(finalRenderWithMasterEffectsExportData){
        if(applyMasterProcessing && finalRenderWithMasterEffectsExportData){
            if(!DO_RENDER_FILE_FOR_PLAYBACK){
                this._updateFinalRenderedDataWithMasterEffectsAppliedCreated(finalRenderWithMasterEffectsExportData);
            }
        }
        this._sequencer.setRenderingBeingCreated(false);
    }).bind(this)).catch((function(error){
        this._sequencer.setRenderingBeingCreated(false);
        throw error;
    }).bind(this));
},


});


export default SequencerRenderer; 