

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerNodeSchedulingInfo from "models/audioEditor/SequencerNodeSchedulingInfo";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const COMPARE_AUDIO_FILTERS = true;

const MergedSequencerNodeSchedulingInfo =  SequencerNodeSchedulingInfo.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerNodeSchedulingInfo.apply(this, [attributes, options]);
        this.set("nodeSchedulingInfoMergedArray",[]);

    },

    _getNodeSchedulingInfoMergedArray : function(){
        return this.get("nodeSchedulingInfoMergedArray")
    },

    canBeMerged : function(nodeSchedulingInfo){
        if(this._getNodeSchedulingInfoMergedArray().length === 0){
            return true;
        }

        if(nodeSchedulingInfo.getAudioBuffer() !== this.getAudioBuffer()){
            return false;
        }

        /*if(this._getNodeSchedulingInfoMergedArray().length > 0 && Math.abs(nodeSchedulingInfo.getRelativeStartTime()) !== 0){
            return false;
        }*/
        

        /*if(nodeSchedulingInfo.getSequencerNodeReference().isThereEditBoundaryAtBeginning()){
            return false;
        }*/

        if(this.getEndTimeOffsetForSourceBuffer() + this.getRelativeEndTime() !== nodeSchedulingInfo.getStartTimeOffsetForSourceBuffer() + nodeSchedulingInfo.getRelativeStartTime()){
            return false;
        }

        if(nodeSchedulingInfo.isSequencerNodeReferenceHasVideo() !== this.isSequencerNodeReferenceHasVideo()){
            return false;
        }

        if(nodeSchedulingInfo.getSequencerNodeReferenceVideoWidth() !== this.getSequencerNodeReferenceVideoWidth()){
            return false;
        }

        if(nodeSchedulingInfo.getSequencerNodeReferenceVideoHeight() !== this.getSequencerNodeReferenceVideoHeight()){
            return false;
        }

        if(COMPARE_AUDIO_FILTERS){
            const definedUsersAudioFilters =  this.getAllUserDefinedAudioFilters();
            const otherNodeDefinedUsersAudioFilters = nodeSchedulingInfo.getAllUserDefinedAudioFilters();
            if(definedUsersAudioFilters.length !== otherNodeDefinedUsersAudioFilters.length){
                return false;
            }else{
                for(let i =0; i < definedUsersAudioFilters.length; i++){
                    const mergedNodeFilter = definedUsersAudioFilters[i];
                    const otherNodeFilter = otherNodeDefinedUsersAudioFilters[i];
                    if(!mergedNodeFilter.isEqual(otherNodeFilter)){
                        return false;
                    }
                }	
            }
        }
        return true;
    },

    contains: function(sequencerNode){
        for(let i=0; i < this._getNodeSchedulingInfoMergedArray().length; i++){
            const nodeSchedulingInfo = this._getNodeSchedulingInfoMergedArray()[i];
            if(nodeSchedulingInfo.getSequencerNodeReference() === sequencerNode){
                return true;
            }
        }
        return false;
    },

    getSchedulingNodeToBePlayed : function(sequencerNodeToStartFrom){
        if(!sequencerNodeToStartFrom){
            return this._getNodeSchedulingInfoMergedArray();
        }
        let i = 0;
        let nodeWasFound = false;
        for( i=0; i < this._getNodeSchedulingInfoMergedArray().length; i++){
            const nodeSchedulingInfo = this._getNodeSchedulingInfoMergedArray()[i];
            if(nodeSchedulingInfo.getSequencerNodeReference() === sequencerNodeToStartFrom){
                nodeWasFound = true;
                break;
            }
        }
        if(nodeWasFound){
            return this._getNodeSchedulingInfoMergedArray().slice(i);
        }
        return null;
    },



    merge : function(nodeSchedulingInfo, audioContext){
        if(Utils.getInstance().isWordSequencerNodeInstance(nodeSchedulingInfo.getSequencerNodeReference())  || Utils.getInstance().isAudioEventSequencerNodeInstance(nodeSchedulingInfo.getSequencerNodeReference()) || Utils.getInstance().isPauseSequencerNodeInstance(nodeSchedulingInfo.getSequencerNodeReference()) || Utils.getInstance().isDeletedSequencerNodeInstance(nodeSchedulingInfo.getSequencerNodeReference())){
            if(this.canBeMerged(nodeSchedulingInfo)){
                if(!this.getAudioNode()){
                    const audioNode =  audioContext.createBufferSource();
                    audioNode.buffer = nodeSchedulingInfo.getAudioBuffer();
                    audioNode.loop = nodeSchedulingInfo.getAudioNode().loop;
                    audioNode.loopStart = nodeSchedulingInfo.getAudioNode().loopStart;
                    audioNode.loopEnd = nodeSchedulingInfo.getAudioNode().loopEnd;
                    this.setAudioNode(audioNode);
                    this.setSequencerNodeReference(nodeSchedulingInfo.getSequencerNodeReference());
                    this.setRelativeStartTime(nodeSchedulingInfo.getRelativeStartTime());
                    this.setRelativeEndTime(nodeSchedulingInfo.getRelativeEndTime());
                    this.setStartTimeOffset(nodeSchedulingInfo.getStartTimeOffset());
                    this.setStartTimeOffsetForSourceBuffer(nodeSchedulingInfo.getStartTimeOffsetForSourceBuffer());
                    this.setEndTimeOffsetForSourceBuffer(nodeSchedulingInfo.getEndTimeOffsetForSourceBuffer());
                    this.setDuration(nodeSchedulingInfo.getDuration());
                    this.setAudioBufferPlaybackDuration(nodeSchedulingInfo.getAudioBufferPlaybackDuration());
                    this.setStartTimeOffsetLoopForSourceBuffer(nodeSchedulingInfo.getStartTimeOffsetLoopForSourceBuffer());
                    this._getNodeSchedulingInfoMergedArray().push(nodeSchedulingInfo);
                }else{
                    this.setRelativeEndTime(nodeSchedulingInfo.getRelativeEndTime());
                    this.setEndTimeOffsetForSourceBuffer(nodeSchedulingInfo.getEndTimeOffsetForSourceBuffer());
                    this.setDuration(this.getDuration() + nodeSchedulingInfo.getDuration());
                    this.setAudioBufferPlaybackDuration(this.getDuration() + nodeSchedulingInfo.getDuration());
                    this._getNodeSchedulingInfoMergedArray().push(nodeSchedulingInfo);
                }
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }

    },


});

export default MergedSequencerNodeSchedulingInfo; 