import { DesignSystem, ElementDesignSystem, ElementDesignSystemConfig, Position } from "../DesignSystem";
import { ClassicSpeaker } from "./ClassicSpeaker/ClassicSpeaker";
import { ModernSpeaker } from "./ModernSpeaker/ModernSpeaker";

export interface SpeakerDesignSystemConfig extends Pick<ElementDesignSystemConfig, 'animation' | 'position'> {
    firstName: string;
    lastName: string;
    title: string;
    company: string;
    position: Position;
}

export class SpeakerDesignSystem extends ElementDesignSystem implements SpeakerDesignSystemConfig {
    public firstName: string;
    public lastName: string;
    public title: string;
    public company: string;
    public position: Position;

    constructor(
        config: SpeakerDesignSystemConfig,
        designSystem: DesignSystem
    ) {
        super({...config, designSystem: designSystem, affectedBySafeZone: true});
        this.firstName = config.firstName;
        this.lastName = config.lastName;
        this.title = config.title;
        this.company = config.company;
        this.position = config.position;
    }

    protected renderChild() {
        switch (this.designSystem.style) {
            case "modern":
                return (
                    <ModernSpeaker {...this} />
                );
            case "classic":
                return (
                    <ClassicSpeaker {...this} />
                );
            default:
                console.error("Invalid Style");
                return (
                    <div>
                        <h1>Invalid Style</h1>
                    </div>
                );
        }
    }
}