import { Flex, Upload, Select, InputNumber, Slider } from "antd";
import { PositionSelector } from "./PositionSelector/PositionSelector";
import { uploadButton } from "../UploadButton/UploadButton";
import { SetStateAction } from "react";
import { Theme } from "../../VideoEditor";
import { Position } from "../../../remotion/Components/DesignSystem";

const LOGO_SIZES = Object.freeze({
    SMALL: {
        value: 0.1,
        label: "Small",
    },
    MEDIUM: {
        value: 0.15,
        label: "Medium",
    },

    LARGE: {
        value: 0.2,
        label: "Large",
    },
    EXTRA_LARGE: {
        value: 0.25,
        label: "Extra large",
    },
});


const OpacityController = ({ selectedOpacity, setSelectedOpacity }: { selectedOpacity: number, setSelectedOpacity: (opacity: number) => void }) => {
    const onChange = (value: SetStateAction<number>) => {
        if (typeof value === 'number' && !isNaN(value)) {
            setSelectedOpacity(value);
        }
    };

    return (
        <div className="flex flex-row">
            <div style={{ flex: "1 1 auto" }}>
                <Slider
                    min={1}
                    max={100}
                    onChange={onChange}
                    value={typeof selectedOpacity === "number" ? selectedOpacity : 0}
                />
            </div>
            <div style={{ flex: "0 0 60px" }}>
                <InputNumber
                    min={1}
                    max={100}
                    style={{
                        width: "60px",
                        marginLeft: "8px",
                    }}
                    value={selectedOpacity} // Pass raw number here
                    onChange={onChange} // Update raw number in state
                    formatter={(value: any) => `${value}%`.replace("%%", "%")} // Ensure only one '%'
                    parser={(value: any) => parseInt(value.replace("%", ""), 10)} // Remove '%' and parse as number
                />
            </div>
        </div>
    );
};

export const LogoComponent = ({ selectedTheme, setSelectedTheme }: { selectedTheme: Theme, setSelectedTheme: (theme: Theme) => void }) => {

    return (
        <div className="flex flex-col">
            <div className="flex flex-row justify-between my-2">
                <div>Choose logo:</div>

                <Flex gap="middle" wrap>
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        fileList={[{
                            uid: "1",
                            name: "image.png",
                            status: "done",
                            url: selectedTheme.watermarkDesignConfig?.imageURL,
                        }]}
                        action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                        maxCount={1}
                    >
                        {uploadButton}
                    </Upload>
                </Flex>
            </div>
            {
                selectedTheme.watermarkDesignConfig && (
                    <>
                        <div className="flex flex-row justify-between my-2">
                            <div>Position:</div>
                            <PositionSelector selectedPosition={selectedTheme.watermarkDesignConfig.position} setSelectedPosition={(newPos) => setSelectedTheme({ ...selectedTheme, watermarkDesignConfig: { ...selectedTheme.watermarkDesignConfig!, position: newPos as Position } })} />
                        </div>
                        <div className="flex flex-row items-center my-2">
                            <div style={{ flex: "0 0 auto" }}>Logo size:</div>
                            <Select
                                className="w-full ml-2"
                                options={Object.values(LOGO_SIZES)}
                                value={selectedTheme.watermarkDesignConfig.scale}
                                onChange={(newScale) => setSelectedTheme({ ...selectedTheme, watermarkDesignConfig: { ...selectedTheme.watermarkDesignConfig!, scale: newScale as number } })}
                            />
                        </div>
                        <div className="flex flex-col justify-between my-2">
                            <div>Opacity:</div>
                            <OpacityController selectedOpacity={selectedTheme.watermarkDesignConfig.opacity} setSelectedOpacity={(newOpacity) => setSelectedTheme({ ...selectedTheme, watermarkDesignConfig: { ...selectedTheme.watermarkDesignConfig!, opacity: newOpacity } })} />
                        </div>
                    </>
                )
            }
        </div>
    );
};