import $ from 'jquery';
import _, { times } from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import SpeakerInfo from './SpeakerInfo';
import RSVP from "rsvp";
import ti18n from 'i18n!nls/Sequenceri18n';

const MERGE_PUCNTUATION_WITH_WORD_NODE = false;
const CREATE_PUNCTUATION_NODE = true;

const SHOW_ONE_GAP_NODE_BETWEEN_WORDS = false;
const SPEAKER_LABEL_PREFIX ="spk_";
const TranscribedAudio =  Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        this._transcriptJsonURL = attributes.transcriptJsonURL;
        this._audioUrl = attributes.audioUrl;
        this._audioBufferDurationInMilliseconds = attributes.audioBufferDurationInMilliseconds;
        this._audioSegmentsArray = [];
        this._audioSegmentsArrayWithStartTimeEndTimeTracked = [];
        this._audioSegmentsStartTimeEndTimeStringArray = [];
        this._wordAudioSegmentsArray = [];
        this._wordAudioSegmentsStartTimeEndTimeArray = [];
        this._processed = false;
        this._totalDurationInMilliseconds = 0;
        this._speakerInfoArray = [];
        this._languageCode =  attributes.languageCode;
        if(attributes.speakerInfoSerializedDataArray){
            this._speakerInfoArray =  attributes.speakerInfoSerializedDataArray.map((serializedData) =>{
                const speakerInfo = new SpeakerInfo();
                speakerInfo.restoreFromSerializedData(serializedData);
                return speakerInfo;
            })
        }
        Backbone.Model.apply(this, [attributes, options]);
    },

    getTranscriptionId: function(){
        return this.get("transcriptionId");
    },

    getSerializedData: function(){
        const stateToReturn  = this.toJSON();
        return stateToReturn;
    },

    getHasVideo : function(){
        return this.get("hasVideo");
    },

    getVideoWidth : function(){
        return this.get("videoWidth");
    },

    getVideoHeight : function(){
        return this.get("videoHeight");
    },

    canGenerateSpeakerLabelIfDuplicate: function(){
        return !!this.get("generateSpeakerLabelIfDuplicate");
    },

    generateSpeakerLabelIfDuplicate : function(speakerId, speakerLabel){
        if(this.canGenerateSpeakerLabelIfDuplicate()){
            return this.get("generateSpeakerLabelIfDuplicate")(speakerId, speakerLabel)
        }
        return speakerLabel;
    },

    getSpeakerInfoWithLabel : function(speakerLabel){
        for(let i =0; i < this._speakerInfoArray.length;i++ ){
            const speakerInfo = this._speakerInfoArray[i];
            if(speakerInfo.getSpeakerLabel() === speakerLabel){
                return speakerInfo;
            }
        }
        return null;
    },

    getSpeakerInfoWithId : function(speakerId){
        for(let i =0; i < this._speakerInfoArray.length;i++ ){
            const speakerInfo = this._speakerInfoArray[i];
            if(speakerInfo.getSpeakerId() === speakerId){
                return speakerInfo;
            }
        }
        return null;
    },
    

    getAllSpeakerInfo : function(){
        return this._speakerInfoArray;
    },

    getTranscriptJsonURL: function(){
        return this._transcriptJsonURL;
    },


    loadAndProcessTranscription : function(additionalSpeakerInfoArray){
        if(this._processed){
            return RSVP.Promise.resolve();
        }
        return Utils.getInstance().fetchJson(this._transcriptJsonURL).then((function(transcriptJSON){
            this._transcriptJSON = transcriptJSON;
            this._procressTranscript(additionalSpeakerInfoArray);
            this._processed = true;
        }).bind(this))
    },

    getAudioSegmentArray : function(){
        return this._audioSegmentsArray;
    },

    getInitialStartTimeEndTimeString : function(audioSegment){
        return this.getInitialStartTimeEndTimeStringFromValue(audioSegment.getInitialStartTime(), audioSegment.getInitialEndTime());
    },

    getInitialStartTimeEndTimeStringFromValue : function(initialStartTime, initialEndTime){
        return initialStartTime+" - "+initialEndTime;
    },

    addEndPunctionNodeIfNotPresent : function(items){
        const lastItem = items[items.length -1];
        if(!this.isPunctuationItem(lastItem)){
            items.push(this._createDummyAmazonPunctuationItem("."));
        }
    },

    getLanguageCode : function(){
        return this.get("languageCode");
    },

    _createDummyAmazonPunctuationItem : function(content){
        const dummyAmazonPunctuationItem = {
            alternatives: [
            {
                confidence: "0.0",
                content: content
            }
            ],
            type: "punctuation"
        };
        return dummyAmazonPunctuationItem;
    },

    _procressTranscript : function(additionalSpeakerInfoArray){
        if(this._transcriptJSON && this._transcriptJSON.results && this._transcriptJSON.results.items){
            let lastAudioSegmentProcessed =  null;
            let lastItemProcessedEndTime;
            this.addEndPunctionNodeIfNotPresent(this._transcriptJSON.results.items);
            for(let i  = 0; i < this._transcriptJSON.results.items.length; i++){
                const item =  this._transcriptJSON.results.items[i];
                if(!MERGE_PUCNTUATION_WITH_WORD_NODE && this.isPunctuationItem(item)){
                    this._mergeLastPronunciationAudioSegementWithPunctuation(item)
                }
                if(this.isPunctuationItem(item) &&  !CREATE_PUNCTUATION_NODE){
                    continue;
                }
                const currentItemStartTime =  item.start_time? Utils.getInstance().getMilliNumberOfString(item.start_time) : (lastItemProcessedEndTime? lastItemProcessedEndTime: 0);
                lastItemProcessedEndTime = lastAudioSegmentProcessed? lastAudioSegmentProcessed.getEndTime() : 0;
                const lastPauseAudioSegmentCreated = this._createExplicitPauseAudioSegmentsFromItem(currentItemStartTime , lastItemProcessedEndTime);
                if(lastPauseAudioSegmentCreated){
                    lastItemProcessedEndTime = lastPauseAudioSegmentCreated.getEndTime();
                    lastAudioSegmentProcessed = lastPauseAudioSegmentCreated;
                }
                const nonExplicitPauseAudioSegmentCreated = this._createNonExplicitPauseAudioSegmentsFromItem(currentItemStartTime , lastItemProcessedEndTime, item);
                if(nonExplicitPauseAudioSegmentCreated){
                    lastItemProcessedEndTime = nonExplicitPauseAudioSegmentCreated.getEndTime();
                    lastAudioSegmentProcessed = nonExplicitPauseAudioSegmentCreated;
                }
                const audioSegmentCreated = this._createAudioSegmentFromNonPauseItem(item,currentItemStartTime, additionalSpeakerInfoArray);
                if(audioSegmentCreated){
                    lastItemProcessedEndTime = audioSegmentCreated.getEndTime();
                    lastAudioSegmentProcessed = audioSegmentCreated;
                }

            }
            this._createPausesThatAreNotAtTheEndOfTranscriptionIfApplicable(lastItemProcessedEndTime, lastAudioSegmentProcessed, additionalSpeakerInfoArray);
        }
        
    },

    getAudioSegmentMatchingStartAndEndTime : function(audioSegmentJson){
        return this._audioSegmentsStartTimeEndTimeStringArray[this.getInitialStartTimeEndTimeStringFromValue(audioSegmentJson.initialStartTime, audioSegmentJson.initialEndTime)];
    },

    _createExplicitPauseAudioSegmentsFromItem : function(currentItemStartTime, lastItemProcessedEndTime){
        let pauseAudioSegment = null;
        if( currentItemStartTime - lastItemProcessedEndTime > 0 ){
            if(SHOW_ONE_GAP_NODE_BETWEEN_WORDS){
                pauseAudioSegment = this._createPause(lastItemProcessedEndTime, currentItemStartTime);
                if(pauseAudioSegment && pauseAudioSegment.getDuration() > 0){
                    this._audioSegmentsArray.push(pauseAudioSegment);
                    if(pauseAudioSegment.getDuration() > 0){
                        this._audioSegmentsArrayWithStartTimeEndTimeTracked.push(pauseAudioSegment);
                        this._audioSegmentsStartTimeEndTimeStringArray.push(this.getInitialStartTimeEndTimeString(pauseAudioSegment));
                    }
                }
                return pauseAudioSegment;
            }else{
                const numberOfExplicitelyDisplayedPausesAudioSegmentToCreate = Math.floor((currentItemStartTime - lastItemProcessedEndTime)/ Utils.getInstance().getPauseDurationInMilliSecondsThatShouldBeExplicitlyDisplayed());
                for(let ii = 0; ii < numberOfExplicitelyDisplayedPausesAudioSegmentToCreate; ii++){
                    pauseAudioSegment = this._createPause(lastItemProcessedEndTime, lastItemProcessedEndTime + Utils.getInstance().getPauseDurationInMilliSecondsThatShouldBeExplicitlyDisplayed());
                    if(pauseAudioSegment && pauseAudioSegment.getDuration() > 0){
                        this._audioSegmentsArray.push(pauseAudioSegment);
                        if(pauseAudioSegment.getDuration() > 0){
                            this._audioSegmentsArrayWithStartTimeEndTimeTracked.push(pauseAudioSegment);
                            this._audioSegmentsStartTimeEndTimeStringArray.push(this.getInitialStartTimeEndTimeString(pauseAudioSegment));
                        }
                        lastItemProcessedEndTime = pauseAudioSegment.getEndTime();
                    }
                }
            }

        }
        return  pauseAudioSegment;
    },

    _createNonExplicitPauseAudioSegmentsFromItem : function(currentItemStartTime, lastItemProcessedEndTime, item){
        let pauseAudioSegment = null;
        if(currentItemStartTime - lastItemProcessedEndTime  > 0 || (currentItemStartTime - lastItemProcessedEndTime == 0 && (!item || item.type === "pronunciation"))){
            pauseAudioSegment = this._createPause(lastItemProcessedEndTime, currentItemStartTime);
            if(pauseAudioSegment && pauseAudioSegment.getDuration() > 0){
                this._audioSegmentsArray.push(pauseAudioSegment);
                if(pauseAudioSegment.getDuration() > 0){
                    this._audioSegmentsArrayWithStartTimeEndTimeTracked.push(pauseAudioSegment);
                    this._audioSegmentsStartTimeEndTimeStringArray.push(this.getInitialStartTimeEndTimeString(pauseAudioSegment));
                }
                lastItemProcessedEndTime = pauseAudioSegment.getEndTime();
            }
        }
        return  pauseAudioSegment;
    },

    _createAudioSegmentFromNonPauseItem : function(item, currentItemStartTime, additionalSpeakerInfoArray){
        let lastItemProcessedEndTime = null;
        let lastAudioSegmentProcessed = null;
        const audioSegment = this._createAudioSegmentFromTranscriptItem(item, currentItemStartTime, additionalSpeakerInfoArray);
        if(audioSegment.getDuration() === 0){
            return null;
        }
        if(Utils.getInstance().isWordAudioSegmentInstance(audioSegment)){
            this._wordAudioSegmentsArray.push(audioSegment);
            this._wordAudioSegmentsStartTimeEndTimeArray.push(this.getInitialStartTimeEndTimeString(audioSegment));
        }
        if(audioSegment){
            this._audioSegmentsArray.push(audioSegment);
            if(audioSegment.getDuration() > 0){
                this._audioSegmentsArrayWithStartTimeEndTimeTracked.push(audioSegment);
                this._audioSegmentsStartTimeEndTimeStringArray.push(this.getInitialStartTimeEndTimeString(audioSegment));
            }
            lastItemProcessedEndTime = audioSegment.getEndTime();
            lastAudioSegmentProcessed = audioSegment;
        }
        if(audioSegment.getEndTime()){
            this._totalDurationInMilliseconds = audioSegment.getEndTime();
        }
        return audioSegment;
    },

    _createPausesThatAreNotAtTheEndOfTranscriptionIfApplicable : function(lastItemProcessedEndTime, lastAudioSegmentProcessed, additionalSpeakerInfoArray){
        if(this._audioBufferDurationInMilliseconds > 0 && lastItemProcessedEndTime< this._audioBufferDurationInMilliseconds ){

            const lastPauseAudioSegmentCreated = this._createExplicitPauseAudioSegmentsFromItem(this._audioBufferDurationInMilliseconds  , lastItemProcessedEndTime);
            if(lastPauseAudioSegmentCreated){
                lastItemProcessedEndTime = lastPauseAudioSegmentCreated.getEndTime();
                lastAudioSegmentProcessed = lastPauseAudioSegmentCreated;
            }
            const nonExplicitPauseAudioSegmentCreated = this._createNonExplicitPauseAudioSegmentsFromItem(this._audioBufferDurationInMilliseconds  , lastItemProcessedEndTime);
            if(nonExplicitPauseAudioSegmentCreated){
                lastItemProcessedEndTime = nonExplicitPauseAudioSegmentCreated.getEndTime();
                lastAudioSegmentProcessed = nonExplicitPauseAudioSegmentCreated;
            }

            const dummyAmazonPunctuationItem = this._createDummyAmazonPunctuationItem(".");
            this._transcriptJSON.results.items.push(dummyAmazonPunctuationItem);
            const audioSegmentCreated = this._createAudioSegmentFromNonPauseItem(dummyAmazonPunctuationItem,lastItemProcessedEndTime , additionalSpeakerInfoArray);
            if(audioSegmentCreated){
                lastItemProcessedEndTime = audioSegmentCreated.getEndTime();
                lastAudioSegmentProcessed = audioSegmentCreated;
            }
        }

    },

    getTotalDurationInMilliseconds: function(){
        return this._totalDurationInMilliseconds;
    },

        /*areWordAudioSegmentTheClosestToEachOther : function(audioSegmentOne, audioSegmentTwo){
            if(audioSegmentOne.getAudioUrl() !== audioSegmentTwo.getAudioUrl()){
                return false;
            }
            const indexOfSegOne = this._wordAudioSegmentsStartTimeEndTimeArray.indexOf(this.getInitialStartTimeEndTimeString(audioSegmentOne));//this._wordAudioSegmentsArray.indexOf(audioSegmentOne);
            const indexOfSegTwo = this._wordAudioSegmentsStartTimeEndTimeArray.indexOf(this.getInitialStartTimeEndTimeString(audioSegmentTwo));//this._wordAudioSegmentsArray.indexOf(audioSegmentTwo);
            if(indexOfSegOne > indexOfSegTwo){
                const temp = indexOfSegTwo;
                indexOfSegTwo = indexOfSegOne;
                indexOfSegOne = temp;
            }
            if(indexOfSegOne == -1 || indexOfSegTwo == -1 ){
                return false;
            }
            //const lowerIndex = indexOfSegOne < indexOfSegTwo? indexOfSegOne: indexOfSegTwo;
            //const biggerIndex = indexOfSegOne < indexOfSegTwo? indexOfSegTwo: indexOfSegOne;
            if(indexOfSegOne + 1 == indexOfSegTwo){
                //next to each other
                return true;
            }else{
                return false;
            }
        },*/

        /*areAudioSegmentTheClosestToEachOtherOld : function(audioSegmentOne, audioSegmentTwo){
            if(audioSegmentOne.getAudioUrl() !== audioSegmentTwo.getAudioUrl()){
                return false;
            }
            if(audioSegmentOne.getDuration() === 0 || audioSegmentTwo.getDuration() === 0){
                return true;
            }
            const indexOfSegOne = this._audioSegmentsStartTimeEndTimeStringArray.indexOf(this.getInitialStartTimeEndTimeString(audioSegmentOne));//this._audioSegmentsArray.indexOf(audioSegmentOne);
            const indexOfSegTwo = this._audioSegmentsStartTimeEndTimeStringArray.indexOf(this.getInitialStartTimeEndTimeString(audioSegmentTwo));//this._audioSegmentsArray.indexOf(audioSegmentTwo);
            if(indexOfSegOne > indexOfSegTwo){
                const temp = indexOfSegTwo;
                indexOfSegTwo = indexOfSegOne;
                indexOfSegOne = temp;
            }
            if(indexOfSegOne == -1 || indexOfSegTwo == -1 ){
                return false;
            }

            //const lowerIndex = indexOfSegOne < indexOfSegTwo? indexOfSegOne: indexOfSegTwo;
            //const biggerIndex = indexOfSegOne < indexOfSegTwo? indexOfSegTwo: indexOfSegOne;
            if(indexOfSegOne + 1 == indexOfSegTwo){
                //next to each other
                return true;
            }else{
                if(indexOfSegOne + 1 < indexOfSegTwo){
                    isThereANodeBetweenTheTwoWithNonNullDuration = false;
                    for(let i = indexOfSegOne+1; i < indexOfSegTwo; i++){
                        const audioSegmentBetween = this._audioSegmentsArrayWithStartTimeEndTimeTracked[i];
                        if(audioSegmentBetween.getDuration() > 0){
                            isThereANodeBetweenTheTwoWithNonNullDuration = true;
                            break;
                        }
                    }
                    return isThereANodeBetweenTheTwoWithNonNullDuration? false: true;
                }
                return false;
            }
        },*/

    areAudioSegmentTheClosestToEachOther : function(audioSegmentOne, audioSegmentTwo){
        if(audioSegmentOne.getAudioUrl() !== audioSegmentTwo.getAudioUrl()){
            return false;
        }
        if(audioSegmentOne.getDuration() === 0 || audioSegmentTwo.getDuration() === 0){
            return true;
        }
        if(!isNaN(audioSegmentOne.getEndTime()) && !isNaN(audioSegmentTwo.getStartTime()) && audioSegmentOne.getEndTime() === audioSegmentTwo.getStartTime()){
            return true;
        }else{
            return false;
        }
    },


    isPunctuationItem : function(item){
        return item && item.type === "punctuation";
    },

    _mergeLastPronunciationAudioSegementWithPunctuation : function(itemPunctuation){
        const lastPronunciationAudioSegment = this._getLastPronunciationAudioSegmentCreated();
        if(lastPronunciationAudioSegment){
            lastPronunciationAudioSegment.setContent(lastPronunciationAudioSegment.getContent() + this._getItemContent(itemPunctuation));
        }else{
                //if there is no pronunciation segement previously created then ignore
        }
    },

    _getLastPronunciationAudioSegmentCreated : function(){
        for(let i = this._audioSegmentsArray.length -1; i>=0; i--){
            const audioSegment = this._audioSegmentsArray[i];
            if(Utils.getInstance().isWordAudioSegmentInstance(audioSegment)){
                return audioSegment;
            }
        }
        return null;
    },

    _createPause : function(startTime, endTime, confidence){
        return Utils.getInstance().getPauseAudioSegment(this._audioUrl, startTime, endTime, confidence, startTime, endTime, this);
    },

    _createAudioSegmentFromTranscriptItem : function(item, defaultStartTime, additionalSpeakerInfoArray){
        if(item && item.type === "pronunciation"){
            return this._createWordAudioSegmentFromPronunciationTranscriptItem(item, additionalSpeakerInfoArray);
        }
        if(item && item.type === "audio_event"){
            return this._createAudioEventAudioSegmentFromPronunciationTranscriptItem(item, additionalSpeakerInfoArray);
        }
        if(item && item.type === "punctuation"){
            return this._createPunctuationAudioSegmentFromPunctuationTranscriptItem(item, defaultStartTime);
        }

        console.error("unsupported transcription item type. Item type:"+item.type);
        return this._createUnsupportedAudioSegmentFromPUnsupportedTranscriptItem(item, defaultStartTime);

    },

    _getSpeakerWithLabelFromSpeakerInfoArray: function(additionalSpeakerInfoArray, speakerLabel){
        if(additionalSpeakerInfoArray && additionalSpeakerInfoArray.length){
            for(let i =0; i < additionalSpeakerInfoArray.length; i++){
                const speakerInfo = additionalSpeakerInfoArray[i];
                if(speakerInfo.getSpeakerLabel() === speakerLabel){
                    return speakerInfo;
                }
            }
        }
        return null;
    },

    _getSpeakerInfoForWordItem :function(item, additionalSpeakerInfoArray){
        if(item.speaker_label){
            const additionalSpeakerInfo = this._getSpeakerWithLabelFromSpeakerInfoArray(additionalSpeakerInfoArray, item.speaker_label);
            if(additionalSpeakerInfo){
                return additionalSpeakerInfo;
            }
            const speakerId = item.speaker_label + this.getTranscriptionId();
            let speakerLabel = item.speaker_label;
            if(speakerLabel && speakerLabel.startsWith(SPEAKER_LABEL_PREFIX)){
                speakerLabel = speakerLabel.replace(SPEAKER_LABEL_PREFIX,window.getI18n(ti18n, "SPEAKER")+" ")
            }
            
            speakerLabel = this.generateSpeakerLabelIfDuplicate(speakerId, speakerLabel);
            
            const existingSpeakerInfo = this.getSpeakerInfoWithId(speakerId);
            if(existingSpeakerInfo){
                return existingSpeakerInfo;
            }else{
                const speakerInfo =  Utils.getInstance().getSpeakerInfo(speakerId, speakerLabel);
                this._speakerInfoArray.push(speakerInfo);
                return speakerInfo;
            }
        }else{
            return null;
        }
    },


    _createWordAudioSegmentFromPronunciationTranscriptItem : function(item, additionalSpeakerInfoArray){
        const startTime = Utils.getInstance().getMilliNumberOfString(item.start_time);
        const endTime  = Utils.getInstance().getMilliNumberOfString(item.end_time);
        const speakerInfo = this._getSpeakerInfoForWordItem(item, additionalSpeakerInfoArray);
        return Utils.getInstance().getWordAudioSegment(this._audioUrl, startTime,
            endTime,
            this._getItemContent(item),
            this._getItemConfidence(item), this, 
            startTime,
            endTime,
            this._getItemContent(item),
            speakerInfo);
    },

    _createAudioEventAudioSegmentFromPronunciationTranscriptItem : function(item, additionalSpeakerInfoArray){
        const startTime = Utils.getInstance().getMilliNumberOfString(item.start_time);
        const endTime  = Utils.getInstance().getMilliNumberOfString(item.end_time);
        const speakerInfo = this._getSpeakerInfoForWordItem(item, additionalSpeakerInfoArray);
        return Utils.getInstance().getAudioEventAudioSegment(this._audioUrl, startTime,
            endTime,
            this._getItemContent(item),
            this._getItemConfidence(item), this, 
            startTime,
            endTime,
            this._getItemContent(item),
            speakerInfo);
    },

    _createPunctuationAudioSegmentFromPunctuationTranscriptItem : function(item, defaultStartTimeinMilliseconds){
        const startTime = defaultStartTimeinMilliseconds? defaultStartTimeinMilliseconds: 0;
        const endTime  = defaultStartTimeinMilliseconds? defaultStartTimeinMilliseconds: 0;
        return Utils.getInstance().getPunctuationAudioSegment(this._audioUrl,
            startTime,
            endTime,
            this._getItemContent(item),
            this._getItemConfidence(item), this,
            startTime,
            endTime,
            this._getItemContent(item));
    },

    _getItemContent : function(item){
        return item.alternatives && item.alternatives.length > 0? item.alternatives[0].content : null;
    },

    _getItemConfidence : function(item){
        return item.alternatives && item.alternatives.length > 0? item.alternatives[0].confidence : null;
    },

    _createUnsupportedAudioSegmentFromPUnsupportedTranscriptItem : function(item, defaultStartTime){
        const startTime = item.start_time? Utils.getInstance().getMilliNumberOfString(item.start_time): defaultStartTime;
        const endTime  = item.end_time? Utils.getInstance().getMilliNumberOfString(item.end_time): defaultStartTime;
        return Utils.getInstance().getUnsupportedAudioSegment(this._audioUrl,
            startTime,
            endTime,
            this._getItemContent(item),
            this._getItemConfidence(item), this, startTime, endTime, this._getItemContent(item));
    },




});

export default TranscribedAudio; 