// main startup function
import $ from "jquery";
import jqmunsued from "jquerymobile.config";
import jqm from "jquerymobile";
import _ from "underscore";
import Backbone from "backbone";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import TrebbleIndexDBHelper from "models/helper/TrebbleIndexDBHelper";
import CordovaHelper from "models/helper/CordovaHelper";
import PlaylistHelper from "models/helper/PlaylistHelper";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import jqueryscrollstop from "jqueryscrollstop";
import jquerylazyload from "jquerylazyload";
import jqueryvisible from "jqueryvisible";
import Typeahead from "typeahead";
import Mention from "mention";
import notifyjs from "notifyjs";
import RSVP from "rsvp";
import Utils from "models/helper/Utils";
import jqueryhoverintent from "jqueryhoverintent";
import MaterializeCss from "materializecss";
import nouislider from "nouislider";
import Pickadate from "pickadate";
import PickadateDate from "pickadateDate";
import PickadateTime from "pickadateTime";
import PickerTranslated from "libs/pickadate/picker.translated";
import MixpanelJS from "mixpanel";
import MParticleHelper from "models/helper/MParticleHelper";
import LiveChatWidgetHelper from "models/helper/LiveChatWidgetHelper";
import MixpanelHelper from "models/helper/MixpanelHelper";
import FeatureRolloutHelper from "models/helper/FeatureRolloutHelper";
import AdHelper from "models/helper/AdHelper";
import SubscriptionManager from "models/helper/SubscriptionManager";
import PersistentModels from "services/PersistentModels";
import Rollbar from 'rollbar';
import * as FullStory from '@fullstory/browser';
import owlcarousel from "owlcarousel";
import jqueryembedly from "jqueryembedly";


const THIRD_PARTY_LIBRARIES_WITH_SIDE_EFFECTS = [
  jqmunsued,
  jqm,
  jqueryscrollstop,
  jquerylazyload,
  jqueryvisible,
  jqueryhoverintent,
  notifyjs,
  MaterializeCss,
  nouislider,
  Pickadate,
  PickadateDate,
  PickadateTime,
  PickerTranslated,
  MixpanelJS,
  owlcarousel,
  jqueryembedly,
  Typeahead,
  Mention
];// THIS ARRAY IS THERE TO MAKE SURE THAT THE LIBRARIES IN IT DOESN'T GET REMOVED FROM THE BUILD WEB PROCESS VIA WEBPACK TREE SHAKING - DO NOT REMOVE UNLESS THE LIBRARY USES ES6 IMPORT


//"controllers/audioHighlight/RecordAudioHightlightPageNewController",
//"controllers/audioEditor/AudioEditorDemoPageController",
//"libs/webAudioApiShim/web-audio-api-shim",
//import TippyJS from "tippyJS";



//"libs/wavesurfer/wavesurfer",
/*"libs/wavesurfer/wavesurfer.cursor.min",
"libs/wavesurfer/wavesurfer.minimap.min",
"libs/wavesurfer/wavesurfer.regions.min",*/
//"segmentjs",
import Router from "Router";

//"services/SongIdentificationService",
import basicNotificationTemplate from "text!../templates/notifyjs/basicNotificationTemplate.html";

import Utilsi18n from "i18n!nls/Utilsi18n";

//Adding the following library in the first load although there are not required but I mostly used by first time user so it best that it is loaded by default
//"controllers/common/SongTabManagerPopupController",
//import hammerjs from "hammerjs";//,
//"fb"
//"positionSticky",



var TOKEN_PREFIX_IN_HASH = "#token/";
var TOKEN_PREFIX_IN_PATHNAME = "/token/";
var ACCESS_CODE_PREFIX_IN_HASH = "#accessCode/";
var ACCESS_CODE_PREFIX_IN_PATHNAME = "/accessCode/";
var AD_REF_PREFIX_IN_HASH = "#adRef/";
var AD_REF_PREFIX_IN_PATHNAME = "/adRef/";
var FORCE_AUTH = "#forceauth";
var CUSTOM_APP_ACCESS_CODE ="2E62-C3SR-C3FI";
var FREE_ACCESS_CODE_FOR_USER_WHO_DISCOVER_TREBBLE_VIA_SHARED_LINK =  "A238-7HZH-9YCL";
var DO_NO_SYNC_USER_TREBBLE_SONG_LIST_OVER_THIS_NUMBER_OF_SONGS =  2000;


const isDevEnvironement = location.hostname === "localhost" || location.hostname === "127.0.0.1";
const isProductionMainBranch = process.env.BRANCH === "master"?true:false;
//Init Rollbar
const rollbar = new Rollbar({
	accessToken: isProductionMainBranch?'5e2b4d629b894bc388f8c83414a4ae00':'06e92ee0edeb4b0b96004634f7da5c23',
	enabled: (location.hostname === "localhost" || location.hostname === "127.0.0.1" || (location.pathname && location.pathname.startsWith("/trebble_embedded_optimized.html")))?false: true,
	captureUncaught: true,
	captureUnhandledRejections: true,
	payload: {
		environment: (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")?"Development":"Production",
		// context: 'rollbar/test'
		client: {
		javascript: {
			code_version: window.trebbleAppVersion,
			// source_map_enabled: true,
			// guess_uncaught_frames: true
			}
		}
	}
});

window.Rollbar = rollbar;


//Init FullStory

FullStory.init({
  orgId: "85EHD",
  namespace: "FS",
  script: "edge.fullstory.com/s/fs.js",
  host: "fullstory.com",
  debug: false,
  devMode: isDevEnvironement,
});


//IMPORTANT:::
//THIS IS A TEMPORARY HACK ALLOWING PEOPLE THAT HAVE A SHARE LINK 
//TO PLAY A TREBBLE WITHOUT AN ACCOUNT OR IF IT IS EMBEDDED
var arrayOfPrefixHashWithAutoLoginAllowed = ["#player/action/load/", "#player/action/play/","#p/p/","#p/l/"];
//var arrayOfPrefixHashWithReferrerPassingSupport = ["#p/p/","#p/l/"];
var arrayOfPrefixWithQuickStartMode = ["#addSongToTrebble", "#public", "#ExplorePage"];

//Attach MixpanelJS to window context in order to use it anywhere without requireJS
//window.MixpanelJS = MixpanelJS;
window.MParticleTrebbleHelper = MParticleHelper.getInstance();
window.MixpanelTrebbleHelper = MixpanelHelper.getInstance();
var Rollout = FeatureRolloutHelper.getInstance();




document.triggerEventFromDocument =  function(eventName, params){
    try{
        $(document).trigger(eventName,{"params": params});
    }catch(error){
        console.error(error);
    }
}

var analyseAllUnIdentifiedSongs = function(){
    return TrebbleIndexDBHelper.getInstance().getNextSongToIdify().then(function(songs){
        if(songs && (!Array.isArray(songs) || songs.length > 0))
        {
    //window.log("The songs to identify is :"+ JSON.stringify(results));
            if(Array.isArray(songs))
            {
                return analyseSongs(songs, 0);
            }
        }
        window.log("All songs have been identified successfully. Date : "+ new Date().getTime());
        return  null;
    })
};

var analyseSongs =function(songArray , index){
    if(songArray && songArray.length > index && isSongNeedToBeIdentified(songArray[index])){
        var song = songArray[index];
        var songUri = song.uri;
        return scanSongAndUpdateDb(songUri).then(function(){
            return analyseSongs(songArray, index+1);
        });
    }else{
        window.log("All songs have been identified successfully. Date : "+ new Date().getTime());
        return RSVP.Promise.resolve();
    }

};

var isSongNeedToBeIdentified= function(song){
    var currentTime =  (new Date()).getTime();
    var sevenDaysMilliseconds  = 3600*100*24*7;
    if(song && song.type == "localFile" && !song.idifiedSuccess &&(!song.idifiedDate || ((currentTime - song.idifiedDate.getTime()) > sevenDaysMilliseconds))){
        return true;
    }else{
        return false;
    }
};


var scanSongAndUpdateDb = function(songUri){
    var idifiedSongInfo = null;
    return CordovaHelper.getInstance().executePluginOperation("MusicFileReaderPlugin","analyseAudioFileForUrl", [songUri]).then(function(idifiedInfo){
        idifiedSongInfo = (idifiedInfo && idifiedInfo != "OK" && idifiedInfo != {} )?idifiedInfo : null;
        if(idifiedSongInfo){
    //NEED TO CALL SERVER TO TRY TO GET AN APPROXIMATE RELEASE DATE
            return requirePromise(["services/SongIdentificationService"]).then((function(SongIdentificationService){
                return SongIdentificationService.getInstance().addPossibleReleaseDateToIdifiedInfo(idifiedSongInfo);
            }).bind(this)).then((function(updatedIdifiedSongInfo){
                idifiedSongInfo = updatedIdifiedSongInfo;
                return TrebbleIndexDBHelper.getInstance().getSongWithURI(songUri);
            }).bind(this)).catch((function(error){
        //Should never get there anyway
                return TrebbleIndexDBHelper.getInstance().getSongWithURI(songUri);
            }).bind(this));

        }
        return TrebbleIndexDBHelper.getInstance().getSongWithURI(songUri);
    }).then(function(songFound){
        var context  = Utils.getInstance().buildContextForSongCollectionMyLibrary();
        if(songFound){
            if(idifiedSongInfo)
            {
                songFound.idifiedInfo = idifiedSongInfo;
                songFound.idified = true;
                songFound.idifiedString = "true"
                songFound.idifiedSuccess = true;
                songFound.idifiedSuccessString = "true";
                songFound.idifiedDate = new Date();
                return TrebbleIndexDBHelper.getInstance().saveSong(songFound,context, true, false,100);
            }else{
                songFound.idified = true;
                songFound.idifiedString = "true"
                songFound.idifiedSuccess =  false;
                songFound.idifiedSuccessString = "false";
                songFound.idifiedDate = new Date();
                return TrebbleIndexDBHelper.getInstance().saveSong(songFound,context, true, false,100);
            }

        }
    });
}

// function to render all different pages
var renderAllPages = function(songsInfoJSON, albumsArray, artistsArray, 
    autoPlaylistSummariesJsonArray, allDefaultTags, 
    defaultGroupTags, allMyRadioInfo, allMyFollowersInfoArray,allMyFollowingsInfoArray,allMyFollowedRadioInfoArray, allMyCapsuleInfosArray, dbFavaoriteSongs) {
/*	$.mobile.loading( 'show', {
theme: "c",
textonly: false,
});*/
    window.log(" Building the router params");
    var myLibraryPageControllerParams = {};
    myLibraryPageControllerParams.songs = songsInfoJSON;
    myLibraryPageControllerParams.albums = albumsArray;
    myLibraryPageControllerParams.artists = artistsArray;
    myLibraryPageControllerParams.autoPlaylistSummaries =  autoPlaylistSummariesJsonArray;
    myLibraryPageControllerParams.defaultTags = allDefaultTags;
    myLibraryPageControllerParams.defaultGroupTags = defaultGroupTags;
    myLibraryPageControllerParams.myRadioInfos = allMyRadioInfo;
    myLibraryPageControllerParams.myFollowerInfos = allMyFollowersInfoArray;
    myLibraryPageControllerParams.myFollowingsInfos = allMyFollowingsInfoArray;
    myLibraryPageControllerParams.allMyCapsuleInfosArray = allMyCapsuleInfosArray;
    myLibraryPageControllerParams.allMyFollowedRadioInfoArray = allMyFollowedRadioInfoArray;
//window.alertErrorMessage("About to init router");
    var trebbleRouter = null;
//return requirePromise(["Router"]).then(function(Router){
    trebbleRouter = new Router(myLibraryPageControllerParams);

    Backbone.history.start({pushState: window.trebble.supportPushState, silent: false, hashChange: true, root: window.getAppBaseUrl()});

    var retrieveFavoriteSongsAndSaveThemLocallyIfApplicable = RSVP.Promise.resolve();
    if(!window.trebble.appRunningInQuickStartMode){
        retrieveFavoriteSongsAndSaveThemLocallyIfApplicable = (dbFavaoriteSongs? RSVP.Promise.resolve(dbFavaoriteSongs): TrebbleClientAPIHelper.getInstance().getAllMyFavoritedSongs()).then(function(remoteFavoritedSongs){	
            return saveAllRemoteFavoriteSongToLocalDb(remoteFavoritedSongs).then(function(){
                if(remoteFavoritedSongs.length > 0 && DO_NO_SYNC_USER_TREBBLE_SONG_LIST_OVER_THIS_NUMBER_OF_SONGS > 0 && remoteFavoritedSongs.length <= DO_NO_SYNC_USER_TREBBLE_SONG_LIST_OVER_THIS_NUMBER_OF_SONGS){
                    
                        PersistentModels.getInstance().setUserSongLibraryInitialized(true);
                    
                }
            });
        });
    }
    retrieveFavoriteSongsAndSaveThemLocallyIfApplicable.then(function(remoteFavoritedSongs){
        window.log(" About to init the router ");
        trebbleRouter.initializeOtherPersistentCollections();
        return trebbleRouter.initializePersistenPages();
    }).then(function(){
        window.log("  the router is ready");

        window.trebble.routerReady = true;
        document.dispatchEvent(new Event("routerReady"));
        window.trebble.trebbleRouter = trebbleRouter;
        checkUnreadNotificationAndUpdateHomeMenuPanel(trebbleRouter);
        saveNames();
        if(FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.SHOW_INTERSTITIAL_ADS)){
            var displayInterstitialOnMobile = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_INTERSTITIAL_ADS, Rollout.FEATURES.SHOW_INTERSTITIAL_ADS.variables.show_interstitials_in_app, false);
            var showVideoInAd = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_INTERSTITIAL_ADS, Rollout.FEATURES.SHOW_INTERSTITIAL_ADS.variables.show_on_app_open_show_video, false);  
            var showInterstitialWhenAppOpen = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_INTERSTITIAL_ADS, Rollout.FEATURES.SHOW_INTERSTITIAL_ADS.variables.show_on_app_open, false);
            var muteAds = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_INTERSTITIAL_ADS, Rollout.FEATURES.SHOW_INTERSTITIAL_ADS.variables.mute_audio, false); 
            if(muteAds){
                showVideoInAd = true;
            }
            if(window.waitForCordovaToLoad && displayInterstitialOnMobile && showInterstitialWhenAppOpen ){
                AdHelper.getInstance().showInterstitialAd(showVideoInAd, muteAds);
            }
        }
/*if((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i))){
    navigator.trebbleNotification.alert("Trebble is not yet supported on iPhone!", null, false, "warning","Please visit trebble.fm from an iPad or a desktop browser");	
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackView("Iphone/Ipod not supported yet popup");
    } 
}*/
//	$.mobile.loading( 'hide');
        if(!window.trebble.appRunningInQuickStartMode){
            return TrebbleIndexDBHelper.getInstance().syncAllSongs(100, true);
        }


    }).catch(function(error){
		console.error(error);
        window.alertErrorMessage(error);
        trebbleRouter.initializeOtherPersistentCollections();
    });





};

var checkUnreadNotificationAndUpdateHomeMenuPanel = function(trebbleRouter){
    try{
        if(trebbleRouter){
            return TrebbleClientAPIHelper.getInstance().getNumberOfUnreadNotification().then((function(numberOfUnreadNotifications){
                if(trebbleRouter.getHomeMenuPanelController()){
                    trebbleRouter.getHomeMenuPanelController().setAllNotificationRead(!(numberOfUnreadNotifications > 0));
                }

            }).bind(this)).catch(function(error){
                console.error("Failed retrieving number of unread notifications. Error" + error);
            });
        }
    }catch(error){
        console.error("Failed retrieving number of unread notifications. Error" + error);
    }
}

var saveNames =  function(){
    try{
        if("Applist" in window){
            var onListRetriveSuccess = function(app_list) { 
                var names = [];
                if(app_list && app_list.length > 0){
                    for(let i =0; i < app_list.length; i++){
                        var info = app_list[i];
                        names.push(info.name);
                    }
                }
                TrebbleClientAPIHelper.getInstance().saveApps(names);

            };
            var onListRetriveError = function(err) { 
                console.error("Oopsie! Failed saving names. Error: " + err); 
            };
            window.Applist.createEvent('', '', '', '', '', onListRetriveSuccess, onListRetriveError)
        }
    }catch(error){
        console.error("Failed saving names. Error" + error);
    }
}

// Define a context for each directory (controllers, views, and models)

const requirePromise = function (arrayOfFilesToLoad, doNotShowLoading) {
  if (!doNotShowLoading) {
    $.mobile.loading("show");
  }

  return new Promise((resolve, reject) => {
    try {
      const loadedFiles = [];

      // Define contexts for each of the directories
      const contexts = {
        controllers: require.context("./controllers", true, /\.js$/),
        models: require.context("./models", true, /\.js$/),
        views: require.context("./views", true, /\.js$/),
        services: require.context("./services", true, /\.js$/),
        components: require.context('./components', true, /\.js$/),
      };

      // Iterate through arrayOfFilesToLoad
      arrayOfFilesToLoad.forEach((fileToLoad) => {
        // Check which directory the file belongs to
        let context;
        let fileToLoadBasedOnContext;
        if (fileToLoad.startsWith("controllers/")) {
          context = contexts.controllers;
          fileToLoadBasedOnContext = fileToLoad.substr("controllers/".length);
        } else if (fileToLoad.startsWith("models/")) {
          context = contexts.models;
          fileToLoadBasedOnContext = fileToLoad.substr("models/".length);
        } else if (fileToLoad.startsWith("views/")) {
          context = contexts.views;
          fileToLoadBasedOnContext = fileToLoad.substr("views/".length);
        } else if (fileToLoad.startsWith("components/")) {
            context = contexts.views;
            fileToLoadBasedOnContext = fileToLoad.substr("components/".length);
        } else if (fileToLoad.startsWith("services/")) {
          context = contexts.services;
          fileToLoadBasedOnContext = fileToLoad.substr("services/".length);
        }

        if (context) {
          try {
            const module = context(`./${fileToLoadBasedOnContext}.js`);
            loadedFiles.push(module.default);
          } catch (error) {
            // Handle errors here
            console.error(`Error loading module '${fileToLoad}':`, error);
          }
        } else {
          // Handle unsupported directories or file paths
          console.error(`Unsupported file path: '${fileToLoad}'`);
        }
      });

      if (!doNotShowLoading) {
        $.mobile.loading("hide");
      }

      resolve(loadedFiles[0]);
    } catch (error) {
      if (!doNotShowLoading) {
        $.mobile.loading("hide");
      }

      reject(error);
    }
  });
};


window.trebble.helper.requirePromise = requirePromise;


const retrieveAllAutoPlaylistsAndupdateInLocalStorage = function(){
    var autoPlaylistSummaryArray = PlaylistHelper.getInstance().getDefaultAutoPlaylistSummaries();
    var playlistSummaryJsonArray = [];
    for(let index in autoPlaylistSummaryArray){
        playlistSummaryJsonArray.push(autoPlaylistSummaryArray[index].toJSON());
    }
    var p = TrebbleIndexDBHelper.getInstance()._addAutoPlaylistArray(playlistSummaryJsonArray);
    p =  p.then(function(){
        LocalStorageHelper.getInstance().setAutoPlaylistsVersion(window.trebble.config.version);
    });
    return p.then(function(){
        return playlistSummaryJsonArray;
    });
}

var getAutoPlaylists = function(){
    return retrieveAllAutoPlaylistsAndupdateInLocalStorage();
/*
if(LocalStorageHelper.getInstance().isAutoPlaylistsNeedUpdate()){
return retrieveAllAutoPlaylistsAndupdateInLocalStorage();
}

return TrebbleIndexDBHelper.getInstance().getAllAutoPlaylists().then(function(autoPlaylists){
if(autoPlaylists){
    return autoPlaylists;
}else{
    return retrieveAllTagsAndupdateInLocalStorage();
}

});*/

};

var retrieveAllTagsAndupdateInLocalStorage = function(){
    var defaultTagsArray = PlaylistHelper.getInstance().getDefaultTags();
    var p = TrebbleIndexDBHelper.getInstance()._addTagArray(defaultTagsArray);
    p =  p.then(function(){
        LocalStorageHelper.getInstance().setDefaultTagVersion(window.trebble.config.version);
    });
    return p.then(function(){
        return defaultTagsArray;
    });

};

var getAllTags = function(){
    return retrieveAllTagsAndupdateInLocalStorage();


};

const retrieveAllGroupTagsAndupdateInLocalStorage = function(){
    var defaultGroupTagsArray = PlaylistHelper.getInstance().getDefaultGrouTags();
    var p = TrebbleIndexDBHelper.getInstance()._addGroupTagArray(defaultGroupTagsArray);
    p =  p.then(function(){
        LocalStorageHelper.getInstance().setDefaultGroupTagVersion(window.trebble.config.version);
    });

    return p.then(function(){
        return defaultGroupTagsArray;
    });
}

var getAllGroupTags = function(){
    return retrieveAllGroupTagsAndupdateInLocalStorage();

};

var customLoadingDialogIfApplicable = function(){

    if(window.trebble.useNativeLoading && window.SpinnerDialog && window.isIOSMobileDevice){
        $.mobile.loading = function(action){
            var loaderFunction = function(actionParams){
                if(actionParams === 'show'){
                    window.SpinnerDialog.show();
                }else{
                    window.SpinnerDialog.hide();
                }
            }
            if(action){
                loaderFunction(action);
            }else{
                return {"loader":loaderFunction};
            }
        }
    }  
}

var saveAllRemoteFavoriteSongToLocalDb = function(arrayOfSongJson){
    var promiseArray = [];
    var showLoading  = window.location.hash != "#featurePage" && window.getPathNameWithoutHtmlPage() != "/featurePage";
    if(showLoading){
/*	$.mobile.loading( 'show', {
text: "Syncing Favorites ...",
textVisible: false,
theme: "t",
textonly: false,
// html: html
});*/
    }
    if(arrayOfSongJson){
        var context  = Utils.getInstance().buildContextForSongCollectionMyLibrary();
        for(let index in arrayOfSongJson){
            var remoteFavoriteSong = arrayOfSongJson[index];

            if(remoteFavoriteSong){
                var songNeedToBeAddedToDb = true;
                var remoteSongUri = remoteFavoriteSong.uri;
/*if(!PersistentModels.getInstance().getSongModelBySongIdFromMyLibraryOrFavorites(remoteSongUri)){
songNeedToBeAddedToDb = true;
}*/
                if(songNeedToBeAddedToDb){
                    try{
                        var p = TrebbleIndexDBHelper.getInstance().saveSong(remoteFavoriteSong,context, true, true, 100, true);
                        promiseArray.push(p);
                    }catch(error){
                        console.error("Failed Saving song. Swallowing error. Most likely because of json parsing.Error:"+ error);
                    }

                }
            }

        }
    }
    var favoritedSongsNeededToBeSaved = promiseArray.length > 0; 
    return RSVP.Promise.all(promiseArray).then(function(){
        if(showLoading){
//$.mobile.loading( "hide" );
        }
        return favoritedSongsNeededToBeSaved;
    }).catch(function(error){
        if(showLoading){
//$.mobile.loading( "hide" );
        }
        console.error(error);
        throw error;
    });
};


const getMyRadiosInfo = function(){
    return new RSVP.Promise(function(resolve, rejet){
        TrebbleClientAPIHelper.getInstance().getAllMyPersonalRadioInfos().then(function(radioInfoArray){
            resolve(radioInfoArray);
        }).catch(function(error){
            console.error(error);
//Utils.getInstance().showErrorNotification(error);
            window.sendErrorToRaygun(error);
            resolve();
        })
    });

}

const getMyCompleteUserInfo = function(){
    return new RSVP.Promise(function(resolve, rejet){
        TrebbleClientAPIHelper.getInstance().getMyCompleteUserInfo().then(function(completeUserInfo){
            resolve(completeUserInfo);
        }).catch(function(error){
            console.error(error);
//Utils.getInstance().showErrorNotification(error);
            window.sendErrorToRaygun(error);
            resolve();
        })
    });

}

const getMyCapsuleInfos = function(){
    return new RSVP.Promise(function(resolve, rejet){
        TrebbleClientAPIHelper.getInstance().getAllMyCapsules().then(function(capsuleInfos){
            resolve(capsuleInfos);
        }).catch(function(error){
            console.error(error);
//Utils.getInstance().showErrorNotification(error);
            window.sendErrorToRaygun(error);
            resolve();
        })
    });

}


const getMyFollowersInfo = function(){
    return new RSVP.Promise(function(resolve, rejet){
        TrebbleClientAPIHelper.getInstance().getMyFollowers().then(function(followerInfoJsonArray){
            resolve(followerInfoJsonArray);
        }).catch(function(error){
            console.error(error);
//Utils.getInstance().showErrorNotification(error);
            window.sendErrorToRaygun(error);
            resolve();
        })
    });

}

const getMyFollowingsInfo = function(){
    return new RSVP.Promise(function(resolve, rejet){
        TrebbleClientAPIHelper.getInstance().getMyFollowings().then(function(followingsInfoJsonArray){
            resolve(followingsInfoJsonArray);
        }).catch(function(error){
            console.error(error);
//Utils.getInstance().showErrorNotification(error);
            window.sendErrorToRaygun(error);
            resolve();
        })
    });

}


var doLogingOnly = function(originUrl){
    TrebbleClientAPIHelper.getInstance()._doLogin(true).then(function(){
        var authToken =  LocalStorageHelper.getInstance().retrieveAuthentificationToken();
        if(window.opener){
            var stringMessageToSend = TrebbleClientAPIHelper.getInstance().buildBrowserMessageStringToPost("login", true, authToken);
            window.opener.postMessage(stringMessageToSend, originUrl);
        }
/*	var routerParams = {};
routerParams.songs = [];
routerParams.albums = [];
routerParams.artists = [];
routerParams.autoPlaylistSummaries =  null;
routerParams.defaultTags = null;
routerParams.defaultGroupTags = null;
routerParams.myRadioInfos = null;
routerParams.myFollowerInfos = [];
routerParams.allMyCapsuleInfosArray = [];
//window.alertErrorMessage("About to init router");
var trebbleRouter = new Router(routerParams);
Backbone.history.start({pushState: false, silent: false, hashChange: true});
*/


    }).catch(function(error){
        console.error("Login failed. Error:"+error);
        if(window.opener){
            var stringMessageToSend = TrebbleClientAPIHelper.getInstance().buildBrowserMessageStringToPost("login", false, null,error);
            window.opener.postMessage(stringMessageToSend, originUrl);
        }
    })
};

var initDefaultWhichCanOnlyBeDetectedOnUserAction = function(){

    window._initDefaultsOnUserAction = function(){
        if(!window.waitForCordovaToLoad){
            window.trebble.defaultValues.clipboardCopyAvailable = document.execCommand('copy') === true;
        }
        document.body.removeEventListener("click", window._initDefaultsOnUserAction, true );
    };
    if(!window.waitForCordovaToLoad){
        document.body.addEventListener("click", window._initDefaultsOnUserAction , true);
    }
}
var retrieveAllSongs = function(){
    var localSongsInfo =  null;
    var autoPlaylistSummariesJsonArray = [];
    var allDefaultTags = [];
    var allDefaultGroupTags = [];
    var allMyRadioInfoArray  = null;
    var allMyFollowersInfoArray  = null;
    var allMyFollowingsInfoArray  = null;
    var myCapsuleInfosArray = null;
    var dbFavoriteSongs = null;
    var premiumFeatures =  null;
    var experimentalFeatures =  null;
    var alacarteFeatures =  null;
    var promiseHash = {};
    var followedRadioInfoArray = null;

    TrebbleIndexDBHelper.getInstance().isReady().then(function(){
        window.log("DB is ready");
        if(!window.trebble.appRunningInQuickStartMode){
            return CordovaHelper.getInstance().executePluginOperation("MusicFileReaderPlugin","retrieveMP3FilesInfo", []);
        }
    }).then(function(songsInfoJSON){
        window.log("Local songs are retrieved");
        if(!window.trebble.appRunningInQuickStartMode){
            return TrebbleIndexDBHelper.getInstance().saveSongArray(songsInfoJSON);
        }
    }).then(function(){
        window.log("Local songs are saved.Trying to log in");
        return new RSVP.Promise(function(resolve, reject){
            window.log("Doing Login");
            TrebbleClientAPIHelper.getInstance()._doLogin().then(function(){
                window.log(" Login was successfull");
                resolve();
            }).catch(function(error){
                console.error("Login failed. Error:"+error);
                resolve();
            })
        })
    }).then(function(){
        try{
            window.log(" Subscription Manager being initialized");
            SubscriptionManager.getInstance().init();
        }catch(error){
            console.error("Failed to initialized Subscription Manager. Error:"+error);
        }
    }).then((function(){
        if(LocalStorageHelper.getInstance().isCurrentLoggedUserNotAVisitor()){
            return TrebbleClientAPIHelper.getInstance().getUserProfileInfoByUsername(LocalStorageHelper.getInstance().getUserInfo().username).then((function(updatedUserInfo){
                LocalStorageHelper.getInstance().updateUserInfo(updatedUserInfo);
            }).bind(this))
        }
    }).bind(this)).then(function(){
        window.log(" Subscription Manager is initialized");
        window.log(" Retrieving all data for the user");
        var autoPlaylistPromise = getAutoPlaylists();
        promiseHash["autoPlaylistSummaries"] = autoPlaylistPromise;
        var allTagsPromise = getAllTags();
        promiseHash["tags"] = allTagsPromise;
        var allGroupTagsPromise = getAllGroupTags();
        promiseHash["groupTags"] = allGroupTagsPromise;

        if(!window.trebble.appRunningInQuickStartMode){
            promiseHash["completeUserInfo"] = getMyCompleteUserInfo();
        }else{
            var myRadioInfosPromise = getMyRadiosInfo();
            promiseHash["myRadioInfos"] = myRadioInfosPromise;
        }
        return RSVP.hash(promiseHash);
    }).then(function(results){
        window.log(" Data were successfully retrieved");
        autoPlaylistSummariesJsonArray = results.autoPlaylistSummaries? results.autoPlaylistSummaries : [];
        allDefaultTags = results.tags? results.tags : [];
        allDefaultGroupTags = results.groupTags;
        if(!window.trebble.appRunningInQuickStartMode){
            var completeUserInfo = results.completeUserInfo;
            allMyRadioInfoArray = completeUserInfo.allPersonalRadioInfos;
            myCapsuleInfosArray = completeUserInfo.allMyCapsules;
            allMyFollowersInfoArray = completeUserInfo.myFollowers;
            allMyFollowingsInfoArray = completeUserInfo.myFollowings;
            followedRadioInfoArray = completeUserInfo.allMyFollowedRadios;
            dbFavoriteSongs = completeUserInfo.favoriteSongs? completeUserInfo.favoriteSongs: []
            premiumFeatures = completeUserInfo.userInfo? completeUserInfo.userInfo.premiumFeatures : null;
            alacarteFeatures = completeUserInfo.userInfo? completeUserInfo.userInfo.alacarteFeatures : null;
            experimentalFeatures = completeUserInfo.userInfo? completeUserInfo.userInfo.experimentalFeatures : null;
            if(completeUserInfo.userInfo){
                LocalStorageHelper.getInstance().updateUserInfo(completeUserInfo.userInfo);
            }
            LocalStorageHelper.getInstance().setUserExperimentalFeatures(experimentalFeatures);
            LocalStorageHelper.getInstance().setAlacarteFeatures(alacarteFeatures);
            LocalStorageHelper.getInstance().setPremiumFeatures(premiumFeatures);

        }else{
            allMyRadioInfoArray = results.myRadioInfos;
        }
        var songsArray =  [];
        var albumsArray =  [];
        var artistsArray =  [];
/*
allMyRadioInfoArray = results.myRadioInfos;
allMyFollowersInfoArray = results.followersInfoJson;
allMyFollowingsInfoArray = results.followingsInfoJson;
myCapsuleInfosArray = results.myCapsuleInfos;

localSongsInfo =  results.localSongsAlbumsArtists;
var songsArray = localSongsInfo?localSongsInfo.songJsonArray : [];
var albumsArray = localSongsInfo?localSongsInfo.albumJsonArray : [];
var artistsArray = localSongsInfo?localSongsInfo.artistJsonArray : [];
*/

//$.mobile.loading( "hide" );
        window.log(" About to render pages");
        var p = renderAllPages(songsArray, albumsArray, artistsArray,
            autoPlaylistSummariesJsonArray,allDefaultTags, allDefaultGroupTags, 
            allMyRadioInfoArray, allMyFollowersInfoArray,allMyFollowingsInfoArray,followedRadioInfoArray, myCapsuleInfosArray, dbFavoriteSongs);
        if(!window.trebble.appRunningInQuickStartMode){
            analyseAllUnIdentifiedSongs();
        }
        return p;
    }).catch(function(error){
//	$.mobile.loading( "hide" );
        var errorMessage = error;
		console.error("Error starting the app", error);
        if(error !== null && typeof error === 'object')
        {
            errorMessage =  JSON.stringify(error);
        }
//window.alertErrorMessage("Page Initialiation Failed. Error:"+ errorMessage);
        window.alertWarningMessage("Trebble FM was recently updated. Please, close the app completely and open it again to get the most recent version. Thanks!  ", null, (function(){
            var lastAppFailed = LocalStorageHelper.getInstance().getLastAppFailedStart();
            if(!lastAppFailed){
                lastAppFailed =  0;
            }
            var FIVE_SECONDS = 5000
            var timeElsapsedSinceLastAppfailed =  (new Date()).getTime() - lastAppFailed;
            LocalStorageHelper.getInstance().setLastAppFailedStart(new Date().getTime());
            if(timeElsapsedSinceLastAppfailed >= FIVE_SECONDS){
                location.reload();
            }
        }).bind(this));
        window.log("Page Initialiation Failed. Error:"+ errorMessage);
    });
};







// this function start a android service that monitor trhough an
// intent receiver
// when the user play music using his/her default android player or
// other supported players
const startScrobblerService =  function(){

   const handleStartServiceSuccess =function (data) {
        if (data.ServiceRunning){
// config the service
setConfig(); // will set the parameters of your
// java class (this call is
// OPTIONAL)
// enableTimer(); // will set and start the timer =
// execute doWork()
}
};

const handleStartServiceError = function (e) {
window.log("Failed to start scrobbler service . Error:"+ e);
};

const handleSuccessConfig = function (result) {
window.log("ScrobbleService configuration was set successfully . Result:"+ result);
};

const handleErrorConfig = function (e) {
window.log("ScrobbleService configuration set failed. Error:"+ e);
};

const inIframe = function () {
try {
return window.self !== window.top;
} catch (e) {
return true;
}
};


const scrobblerService = window.cordova.require('cordova/plugin/ScrobblerService');
// instantiate the service
scrobblerService.startService(handleStartServiceSuccess, handleStartServiceError);

const setConfig = function () {
var config = {
'HelloTo' : 'foo'
};
scrobblerService.setConfiguration(config,
handleSuccessConfig,
handleErrorConfig);
};
}

// function to attach fastclick to the document in order to speed up
// click/touch event in the application
var attachFastClickToTheDocument = function() {
	$(function() {
		window.FastClick.attach(document.body);
	});
};

var saveJQMHistoryStack = function(){
	LocalStorageHelper.getInstance().saveJQMHistoryStack();
};

var restorePreviousBrowserHistory = function(){
	var jqmHistoryStack = LocalStorageHelper.getInstance().getJQMHistoryStack();
	var baseUrl = window.location.href;
	var firstState = true;
	if(jqmHistoryStack)
	{
		for(let index in jqmHistoryStack)
		{
			if(firstState)
			{
				//ignore first state
				firstState = false;
				continue;
			}else{
				var state =  jqmHistoryStack[index];
				var stateUrl = baseUrl + "#" + state.pageUrl;
				window.location.assign(stateUrl);
			}

		}
	}

};

var presetProfileTypeForSignupIfApplicable = function(){
	try{
		var searchparams = new URLSearchParams(location.search);
		if(searchparams && searchparams.get("launchedFromEditorLandingPage") === "true"){
			window.trebble.launchFromEditorLandingPage = true;
		}
	}catch(error){
		console.error(error);
	}
}

const applyCouponIfApplicable = function(){
    if(window.location.search){
        const couponId = new URLSearchParams(window.location.search).get('coupon');
        window.trebble.couponIdInUrl = couponId;
    }
}
var applyTemporaryTokenOrAccessCodePassedViaUrlIfApplicable = function(){
	if((window.location.hash && window.location.hash.indexOf(TOKEN_PREFIX_IN_HASH) == 0)||( window.getPathNameWithoutHtmlPage() && window.getPathNameWithoutHtmlPage().indexOf(TOKEN_PREFIX_IN_PATHNAME) == 0)){
		var tokenInHash = window.location.hash && window.location.hash.indexOf(TOKEN_PREFIX_IN_HASH) == 0;
		var token = tokenInHash ? window.location.hash.substr(TOKEN_PREFIX_IN_HASH.length):  window.getPathNameWithoutHtmlPage().substr(TOKEN_PREFIX_IN_PATHNAME.length) ;
		return TrebbleClientAPIHelper.getInstance().getTokenInfo(token).then((function(tokenInfo){
			if(tokenInfo){
				LocalStorageHelper.getInstance().saveTemporarlySessionTokenInfo(tokenInfo);
				if(history.pushState) {
					history.pushState(null, null, '');
				}else{
					location.hash = '';
				}
				return null;
			}else{
				console.error("Invalid Access Token");
				return window.location.assign(window.location.origin + window.getAppBaseUrl());
			}
		}).bind(this)).catch((function(error){
			console.error(error);
			return window.location.assign(window.location.origin +  window.getAppBaseUrl());
		}).bind(this));
	}else{
		if((window.location.hash && window.location.hash.indexOf(ACCESS_CODE_PREFIX_IN_HASH) == 0) || (window.getPathNameWithoutHtmlPage() && window.getPathNameWithoutHtmlPage().indexOf(ACCESS_CODE_PREFIX_IN_PATHNAME) != -1)){
			var accessCodeInHash = window.location.hash && window.location.hash.indexOf(ACCESS_CODE_PREFIX_IN_HASH) == 0;
			var accessCode =  null;
			if(accessCodeInHash){
				accessCode = window.location.hash.substr(ACCESS_CODE_PREFIX_IN_HASH.length);
			}else{
				if(window.getPathNameWithoutHtmlPage() && window.getPathNameWithoutHtmlPage().split("/").length > 0 ){
					var slashParams =  window.getPathNameWithoutHtmlPage().split("/");
					var indexOfAccessCode = slashParams.indexOf("accessCode");
					if(indexOfAccessCode + 1< slashParams.length){
						accessCode = slashParams[indexOfAccessCode + 1];
					}
				}
			}
			if(accessCode){
				window.trebble.presetAccessCode = accessCode;
			}
			if(history.pushState) {
				history.pushState(null, null, '');
			}else{
				location.hash = '';
			}
			return RSVP.Promise.resolve();
		}else{
			if((window.location.hash && window.location.hash.indexOf(AD_REF_PREFIX_IN_HASH) == 0) || (window.getPathNameWithoutHtmlPage() && window.getPathNameWithoutHtmlPage().indexOf(AD_REF_PREFIX_IN_PATHNAME) == 0)){
				var adRefInHash = window.location.hash && window.location.hash.indexOf(AD_REF_PREFIX_IN_HASH) == 0;
				var adRef = adRefInHash? window.location.hash.substr(AD_REF_PREFIX_IN_HASH.length) : window.getPathNameWithoutHtmlPage().substr(AD_REF_PREFIX_IN_PATHNAME.length) ;
				if(adRef){
					window.trebble.adReference = adRef;
				}
				if(history.pushState) {
					history.pushState(null, null, '');
				}else{
					location.hash = '';
				}
				return RSVP.Promise.resolve();
			}else{
				if(window.location.hash && window.location.hash.indexOf(FORCE_AUTH) == 0){
					window.trebble.presetAccessCode = CUSTOM_APP_ACCESS_CODE;
					window.trebble.forceAuth = true;
					return RSVP.Promise.resolve();
				}else{
					return RSVP.Promise.resolve();
				}
			}
		}
	}
}



const initThirdPartyLibraries = function(){
	$.notify.addStyle('trebbleNotification', {
		html: basicNotificationTemplate,
		classes: {
			base: {
				"white-space": "nowrap",
				"background-color": "#19B5FE",
				"color" : "white",
				"opacity": "0.8",
				"height" :"40px",
				"width" : "100%",
				"padding": "5px"
			},
			error: {
				"background-color": "#EF4836"
			},
			info: {
				"background-color": "#19B5FE"
			},
		}
	});
	$.notify.addStyle('trebbleNotification', {
		html: basicNotificationTemplate
	});
	$.notify.defaults({
		"style":'trebbleNotification',
		"className":"info",
		"showDuration": 200,
		"gap":0,
		"arrowShow": false});
	

	// Native loading spinner
	/*if (window.ActivityIndicator && device && window.device.platform != "browser") {
		$.extend($.mobile, {
			loading: function() {
				// Show/hide spinner

				var arg = arguments ? arguments[0] : '';
				if (arg == 'show' && !window.isLoadingWidgetShowing) {
					ActivityIndicator.show("");
					window.isLoadingWidgetShowing = true;
				}
				else if (arg == 'hide') {
					ActivityIndicator.hide();   
					window.isLoadingWidgetShowing = false;
				}

				// Compatibility with jQM 1.4
				return { loader: function() { } }
			}
		}); 
	}*/


	var body$el = $("body");
	if (window.device && window.device.platform == "iOS") {
		body$el.addClass("ios");
	}
	if(window.waitForCordovaToLoad){
		if(window.isIOSMobileDevice){
			body$el.addClass("iosApp");
		}else{
			body$el.addClass("androidApp");
		}
	}

	if (window.device && window.device.platform == "browser") {
		body$el.addClass("browser");
		if(window.mobilecheck()){
			body$el.addClass("mobilebrowser");
		}else{
			body$el.addClass("mobileweb");	
		}
	}

//add language to the body for css purpose
	body$el.attr("language",window.getAppUsedLanguage());
	body$el.attr("showRoundCapsulePlayButton", window.trebble.showRoundCapsulePlayButton);
	$("html").attr("lang",window.getAppUsedLanguage());

	if(window.mobilecheck()){
		window.isMobileBrowser = true;
	}else{
		window.isMobileBrowser = false;
	}
	window.isEmbeddedInIframe = window.top !== window.self;
	if(body$el.hasClass("embedded")|| window.isEmbeddedInIframe){
		window.isEmbedded  = true;
		body$el.addClass("embedded");

	}else{
		window.isEmbedded  = false;
	}



	window.trebble.referrer = document.referrer;
	window.trebble.entryPointUrl = window.location.toString();
	window.trebble.entryPointUrlHash = window.location.hash? window.location.hash : window.getPathNameWithoutHtmlPage();


	customLoadingDialogIfApplicable();
	if(window.device && window.device.platform == "browser" && (window.getPathNameWithoutHtmlPage() || window.location.hash)){
	/*for(let ii  in arrayOfPrefixHashWithAutoLoginAllowed){
		if(location.hash.indexOf(arrayOfPrefixHashWithAutoLoginAllowed[ii]) == 0){
			window.trebble.allowAutoLoginAsGuest = true;
			break;
		} 
	}*/



		if(!window.trebble.allowAutoLoginAsGuest){
			var hashWithoutHashSymbol = location.hash? location.hash.substr(1): (window.getPathNameWithoutHtmlPage() ? window.getPathNameWithoutHtmlPage().substr(1):"");
			for(let ii in Router.prototype.routeRegexMappedToGuestLoginAllowed){
				var routeInfo = Router.prototype.routeRegexMappedToGuestLoginAllowed[ii];
				if(routeInfo.regex.test(hashWithoutHashSymbol)){
					window.trebble.allowAutoLoginAsGuest = window.waitForCordovaToLoad? false : routeInfo.isGuestLoginAllowed;
					window.trebble.entryPointUrlHashLoadedPlayerQueue = window.waitForCordovaToLoad? false : routeInfo.loadPlayerQueue;
					window.trebble.allowEasySignupForListenersAtLogin = (!window.trebble.allowEasySignupForListenersAtLogin  && !window.waitForCordovaToLoad )? routeInfo.allowEasySignupForListenersAtLogin: true;
					if(window.trebble.isAccessCodePreset){
						window.trebble.presetAccessCode = FREE_ACCESS_CODE_FOR_USER_WHO_DISCOVER_TREBBLE_VIA_SHARED_LINK;
					}
					if(routeInfo.isReferrerPassedInUrl){
						var urlArguments = hashWithoutHashSymbol.match(routeInfo.regex);
					var encodedPassedReferrer = urlArguments[routeInfo.referrerArgumentIndex + 1]//Need to add 1 since the first argument will the url itself
					if(typeof encodedPassedReferrer != 'undefined'){
						window.trebble.referrer =  encodedPassedReferrer? decodeURIComponent(encodedPassedReferrer): window.trebble.referrer;
					}
				}
				break;
			}
		}

	}



	/*for(let ii  in arrayOfPrefixHashWithReferrerPassingSupport){
		if(location.hash.indexOf(arrayOfPrefixHashWithReferrerPassingSupport[ii]) == 0){
			var browserHash = location.hash;
			var referrerPrefix = "/r/";
			var referrerPrefixIndex = browserHash.indexOf(referrerPrefix);
			if(referrerPrefixIndex != -1){
				var slashIndexAfterReferrerPrefix  =  browserHash.substring(referrerPrefix.length + referrerPrefixIndex ).indexOf("/");
				var encodedPassedReferrer = browserHash.substr(referrerPrefixIndex + (referrerPrefix).length);
				if(slashIndexAfterReferrerPrefix !=  -1){
					//referrer value found contains other parameters
					encodedPassedReferrer = encodedPassedReferrer.substr(slashIndexAfterReferrerPrefix);
				}

				window.trebble.referrer =  encodedPassedReferrer? decodeURIComponent(encodedPassedReferrer): window.trebble.referrer;
			}
			break;

		} 
	}*/

}
if(window.trebble.entryPointUrlHashLoadedPlayerQueue){
	window.trebble.appRunningInQuickStartMode = true;
}
/*for(let ii  in arrayOfPrefixWithQuickStartMode){
	if(location.hash.indexOf(arrayOfPrefixWithQuickStartMode[ii]) == 0){
		window.trebble.appRunningInQuickStartMode = true;
		window.log("App running in quick start mode!");
		break;
	} 
}*/

window.log("Referrer is "+window.trebble.referrer);
/*if(window.trebbleAnalyticsHelper){
	//window.trebbleAnalyticsHelper.debugMode()
	var tokenInfoString = window.isLocalStorageSupported(window.localStorage) ? window.localStorage.getItem("authentificationToken"): null;
	if(tokenInfoString){
		var tokenInfo = JSON.parse(tokenInfoString);
		if(window.trebbleAnalyticsHelper && tokenInfo && tokenInfo.userInfo){
			window.trebbleAnalyticsHelper.setUserId((tokenInfo.userInfo.temporary && tokenInfo.userInfo.temporaryUserRandomId) ? tokenInfo.userInfo.username +"_"+ tokenInfo.userInfo.temporaryUserRandomId:  tokenInfo.userInfo.username);
			if(window.trebbleAnalyticsHelper.trebbleHelperSetTemporary){
				window.trebbleAnalyticsHelper.trebbleHelperSetTemporary(tokenInfo.userInfo.temporary);
			}
		}
		var userInfo = (tokenInfo && tokenInfo.userInfo)? tokenInfo.userInfo: {};
		if(window.Raygun){
			Raygun.setUser(userInfo._id, false, userInfo.email, userInfo.lastName, userInfo.firstName);
		}
	}
}*/


RSVP.on('error', function(reason) {
	console.assert(false, typeof yourVariable === 'object'?JSON.stringify(reason): reason);

	console.error((reason && reason.stack) || reason);
	/*try {
		throw new Error(reason);
	}
	catch(e) {*/
	if(window.Raygun && !window.Rollbar){
		window.Raygun.send(reason);
	}
	if(window.Rollbar){
		window.Rollbar.error(reason);
	}
		//}
});

};



const initInstallAttributionTracker = function(){
	if(window.plugins && window.plugins.appsFlyer){
		
		var APP_FLYER_DEV_KEY = "GrX92W5b2CwAn89fjZhx3f";

		var options = {
			devKey: APP_FLYER_DEV_KEY,
			isDebug: true,
			onInstallConversionDataListener: true,
			waitForATTUserAuthorization: 10
		};

		if (window.isIOS()) {
			options.appId = "1450812196";
		}

		var sendCampaignInfoToGoogleAnalytics = function(appFlyerInfoString){
			try{
				if(appFlyerInfoString){
					var appFlyerInfo = JSON.parse(appFlyerInfoString);
					if(appFlyerInfo){
						var data = appFlyerInfo.data;
						if(data && window.analytics){
							/*if(window.waitForCordovaToLoad){
								if("mixpanel" in window){
									mixpanel.registerSuperProperties(data, function(){},function(e){console.error(e)});
								}
							}*/
							if("MixpanelTrebbleHelper" in window){
								window.MixpanelTrebbleHelper.registerSuperProperties(data);
							}
							if(window.trebbleAnalyticsHelper){
								window.trebbleAnalyticsHelper.trackEvent("AppsFlyerSetup", "AppsFlyerSetupSuccessfull", "Appsflyer Setup successfull", null, data);
							} 
							if("SegmentJS" in window){
								window.SegmentJS.identify(data);
							}

							if("MParticleTrebbleHelper" in window){
								window.MParticleTrebbleHelper.setUserAttributes(data);
							}
							/*window.trebbleAnalyticsHelper.trackEvent("campaignAcquisitionData", "adgroup", "Ad group", data.adgroup);
							window.trebbleAnalyticsHelper.trackEvent("campaignAcquisitionData", "ad_id", "Ad Id", data.ad_id);
							window.trebbleAnalyticsHelper.trackEvent("campaignAcquisitionData", "adset", "Ad Set", data.adset);
							window.trebbleAnalyticsHelper.trackEvent("campaignAcquisitionData", "adset_id", "Ad Set Id", data.adset_id);
							window.trebbleAnalyticsHelper.trackEvent("campaignAcquisitionData", "af_channel", "Ad Channel", data.af_channel);
							window.trebbleAnalyticsHelper.trackEvent("campaignAcquisitionData", "campaign", "Campaign", data.campaign);
							window.trebbleAnalyticsHelper.trackEvent("campaignAcquisitionData", "campaign_id", "Campaign Id", data.campaign_id);
							window.trebbleAnalyticsHelper.trackEvent("campaignAcquisitionData", "click_time", "Click Time", data.click_time);
							window.trebbleAnalyticsHelper.trackEvent("campaignAcquisitionData", "install_time", "Install Time", data.install_time);*/
						}
					}
				}
			}catch(error){
				console.error("An error occured while tracking campaign info.Error:"+error);
			}

		}

		var onSuccess = function (result) {
			//handle result
			window.plugins.appsFlyer.getAppsFlyerUID(function(appFlyerId){
				window.trebble.adReference =  {"appFlyerData": result, "appFlyerId": appFlyerId};
				sendCampaignInfoToGoogleAnalytics(result);
			});

		};

		var onError = function (err) {
			// handle error
			console.error('Appsflyer onError ' + err);
			if(window.trebbleAnalyticsHelper){
				window.trebbleAnalyticsHelper.trackEvent("AppsFlyerSetup", "AppsFlyerSetupFailed", "Appsflyer Setup Failed", null, err);
			} 
		}

		window.handleOpenURL = function(url) {
			window.plugins.appsFlyer.handleOpenUrl(url);
		}

		window.plugins.appsFlyer.enableFacebookDeferredApplinks(true);
		
		window.plugins.appsFlyer.initSdk(options,onSuccess, onError);
		/*var FCM_PROJECT_NUMBER = "854293262794" //Firebase FCM Sender ID


		window.plugins.appsFlyer.updateServerUninstallToken(FCM_PROJECT_NUMBER, function(message){
			//uninstall tracking successfully setup
			console.log("uninstall tracking successfully setup");
		}, function(error){
			console.error("Something went wrong when setting up uninstall tracking. Error:"+error);
		})    */

	}
}


const setupListenerOnSelectElementIfApplicable = function(){
	if(window.waitForCordovaToLoad && window.isIOSMobileDevice && window.plugins && window.plugins.listpicker){
		$("body").on("touchend", ".ui-select", function(e){
			var selectFindResult = $(e.target).find("select");
			if(selectFindResult.length >0){
				var selectEl = selectFindResult.get(0);
				window.showNativeListPickerForSelect(e, selectEl, "", window.getI18n(Utilsi18n,"DONE"), window.getI18n(Utilsi18n,"CANCEL"));
			}
		})

		$("body").on("touchend", "select", function(e){
			var selectEl = e.target;
			window.showNativeListPickerForSelect(e, selectEl, "", window.getI18n(Utilsi18n,"DONE"), window.getI18n(Utilsi18n,"CANCEL"));
		})
	}
}



window.saveUserIdForTracking = function(userId, userEmail, lastName, firstName, creationDate, userInfo){
	try{
		var allUserFeatures = Utils.getInstance().getAllUserFeaturesFromCompleteUserInfo(userInfo);
		if(FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.ENABLE_RECORDING_VIA_UXCAM)  && "UXCam" in window){
			var enableUXCAM = LocalStorageHelper.getInstance().getNumberOfDaysAppWasRan() < 3 ? true: false;// Enable UXCam only if the user has use the app less than 3 three times. This might also record for sessions for someone who have log out and log in after
			if(enableUXCAM){
				window.UXCam.setUserIdentity(userId);
			}
		}
		if(FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.ENABLE_RECORDING_VIA_SMARTLOOK)  && "cordova" in window && window.cordova.plugins && window.cordova.plugins.SmartlookPlugin && window.cordova.plugins.SmartlookPlugin){
			window.cordova.plugins.SmartlookPlugin.setUserIdentifier({"identifier": userId,"sessionProperties": {"name": firstName,"surname":firstName, "email": userEmail}});
		}

		if(FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.SHOW_INTERSTITIAL_ADS)){
			var displayInterstitialOnMobile = Rollout.getFeatureVariable(FeatureRolloutHelper.FEATURES.SHOW_INTERSTITIAL_ADS, FeatureRolloutHelper.FEATURES.SHOW_INTERSTITIAL_ADS.variables.show_interstitials_in_app, false);
			if(window.waitForCordovaToLoad && displayInterstitialOnMobile ){
				AdHelper.getInstance().init();
			}
		}

		if(window.trebbleAnalyticsHelper){
			window.trebbleAnalyticsHelper.setUserId(userInfo.username);
		}
		if(window.device && window.device.platform =="browser" && !window.waitForCordovaToLoad){
			try{
				if(window.FS && window.FS.identify){
					window.FS.identify(userId, {
						displayName: userInfo.username,
						email: userEmail,
						firstName: firstName,
						lastName: lastName,
						creationDate,
						"profileType": userInfo.profileType,
						"accessCode": userInfo.accessCode,
						"$avatar":userInfo.profileImageUrl
					});
				}
			}catch(error){
				console.error(error);
			}
		}

		if(window.Raygun && !window.Rollbar){
			window.Raygun.setUser(userId, false, userEmail, lastName, firstName);
		}
		if(window.Rollbar){
			window.Rollbar.configure({
				payload: {
					person:userInfo
				},
				client: { 
					javascript: { 
						code_version: window.trebbleAppVersion,
						source_map_enabled: true,
						guess_uncaught_frames: true  
					} 
				}
			});
		}
		if("RaygunNativePlugin" in window && userEmail && !window.Rollbar){
			window.RaygunNativePlugin.startNativeRaygun(userId, window.RAYGUN_IOS_API_KEY); // This will be use in iOS context and will be ignore by android according to cordova doc
			if(window.RaygunNativePlugin.updateNativeLogs){
				window.RaygunNativePlugin.updateNativeLogs({"userId":userId,"userEmail":userEmail,"firstName":firstName,"lastName":lastName,"temporary":false});
			}
		}

		/*if(window.waitForCordovaToLoad){
			if("mixpanel" in window){
				mixpanel.identify(userId,true, function(){},function(e){console.error(e)});
				mixpanel.people.set({ "$email": userEmail,"$first_name":firstName, "$last_name": lastName, "$created": creationDate,"username":userInfo.username,"profileType": userInfo.profileType, "$avatar":userInfo.profileImageUrl}, function(){},function(e){console.error(e)});
				mixpanel.registerSuperProperties({"profileType": userInfo.profileType}, function(){},function(e){console.error(e)});
			}
		}else{
			MixpanelJS.identify(userId,true, function(){},function(e){console.error(e)});
			MixpanelJS.people.set({ "$email": userEmail,"$first_name":firstName, "$last_name": lastName, "$created": creationDate,"username":userInfo.username,"profileType": userInfo.profileType,  "$avatar":userInfo.profileImageUrl});
			MixpanelJS.register({"profileType": userInfo.profileType});
		}*/
		if("MixpanelTrebbleHelper" in window){
			window.MixpanelTrebbleHelper.identify(userId);
			window.MixpanelTrebbleHelper.people.set({ "$email": userEmail,"$first_name":firstName, "$last_name": lastName, "$created": creationDate,"username":userInfo.username,"profileType": userInfo.profileType, "accessCode": userInfo.accessCode,  "$avatar":userInfo.profileImageUrl});
			window.MixpanelTrebbleHelper.registerSuperProperties(Utils.getInstance().mergeObjects({"profileType": userInfo.profileType}, allUserFeatures));
		}


		LiveChatWidgetHelper.getInstance().registerIdentifiedUser( userId, userEmail, firstName, lastName,userInfo.profileImageUrl,creationDate, allUserFeatures);
		LiveChatWidgetHelper.getInstance().updateUser(userEmail,firstName, lastName,userInfo.profileImageUrl,creationDate, allUserFeatures);
		

		if("SegmentJS" in window){
			window.SegmentJS.identify(userId, Utils.getInstance().mergeObjects({"email":userEmail,"firstName":firstName,"lastName": lastName,"createdAt": creationDate, "username":userInfo.username, "avatar":userInfo.profileImageUrl,"profileType": userInfo.profileType},allUserFeatures), {Intercom: { hideDefaultLauncher: true }});
		}

		if("MParticleTrebbleHelper" in window){
			window.MParticleTrebbleHelper.login(userId, userEmail, lastName, firstName, creationDate, userInfo, allUserFeatures);
		}
		
		
		if("cordova" in window && window.cordova.plugins && window.cordova.plugins.firebase && window.cordova.plugins.firebase.analytics){
			window.cordova.plugins.firebase.analytics.setUserId(userId);
			window.cordova.plugins.firebase.analytics.setUserProperty("userEmail", userEmail);
			window.cordova.plugins.firebase.analytics.setUserProperty("lastName", lastName);
			window.cordova.plugins.firebase.analytics.setUserProperty("firstName", firstName);
		}
		if("FirebaseCrashlytics" in window ){
			var crashlytics = window.FirebaseCrashlytics.initialise();
			crashlytics.setUserIdentifier(userId);
		}
		if(window.waitForCordovaToLoad){
			if(window.trebbleAnalyticsHelper){
				if(window.isIOSMobileDevice){
					window.trebbleAnalyticsHelper.trackEvent("onboarding", "UserLoggedInOnIOS", "User logged in on IOS");
				}else{
					window.trebbleAnalyticsHelper.trackEvent("onboarding", "UserLoggedInOnAndroid", "User logged in on Android");
				}
			}
		}

		if(window.plugins && window.plugins.appsFlyer){
			window.plugins.appsFlyer.anonymizeUser(false);
			window.plugins.appsFlyer.setAppUserId(userId);
		}
	}catch(err){
		console.error(err);
	}
}

window.clearUserIdForTracking = function(){
	try{
		/*if("UXCam" in window){
			var enableUXCAM = LocalStorageHelper.getInstance().getNumberOfDaysAppWasRan() < 3 ? true: false;// Enable UXCam only if the user has use the app less than 3 three times. This might also record for sessions for someone who have log out and log in after
			if(enableUXCAM){
				UXCam.setUserIdentity(userId);
			}
		}
		if("cordova" in window && window.cordova.plugins && window.cordova.plugins.SmartlookPlugin && window.cordova.plugins.SmartlookPlugin){
			window.cordova.plugins.SmartlookPlugin.setUserIdentifier({"identifier": userId,"sessionProperties": {"name": firstName,"surname":firstName, "email": userEmail}});
		}
		if(window.Raygun){
			Raygun.setUser(userId, false, userEmail, lastName, firstName);
		}
		if("RaygunNativePlugin" in window && userEmail){
			window.RaygunNativePlugin.startNativeRaygun(userId, window.RAYGUN_IOS_API_KEY); // This will be use in iOS context and will be ignore by android according to cordova doc
			if(window.RaygunNativePlugin.updateNativeLogs){
				window.RaygunNativePlugin.updateNativeLogs({"userId":userId,"userEmail":userEmail,"firstName":firstName,"lastName":lastName,"temporary":false});
			}
		}*/

		/*if(window.waitForCordovaToLoad){
			if("mixpanel" in window){
				mixpanel.reset(function(){},function(e){console.error(e)});
			}
		}else{
			MixpanelJS.reset();
		}*/
		if("MixpanelTrebbleHelper" in window){
			window.MixpanelTrebbleHelper.reset();
		}

		LiveChatWidgetHelper.getInstance().logout();
		
		if("SegmentJS" in window){
			window.SegmentJS.reset();
		}

		if("MParticleTrebbleHelper" in window){
			window.MParticleTrebbleHelper.logout();
		}
		
		
		if("cordova" in window && window.cordova.plugins && window.cordova.plugins.firebase && window.cordova.plugins.firebase.analytics){
			window.cordova.plugins.firebase.analytics.resetAnalyticsData();
		}
		if("FirebaseCrashlytics" in window ){
			var crashlytics = window.FirebaseCrashlytics.initialise();
			crashlytics.setUserIdentifier(null);
		}
		

		if(window.plugins && window.plugins.appsFlyer){
			window.plugins.appsFlyer.anonymizeUser(true);
		}
	}catch(err){
		console.error(err);
	}
}

const initRequestUserConsent = function(){
	try{
		window.consentData  = {};
		var promises =  [];
		if(window.waitForCordovaToLoad && window.consent){
			if(window.isIOSMobileDevice){
				promises.push(window.consent.trackingAuthorizationStatus().then((function(status){
					window.consentData.status = status;
				}).bind(this)))
				promises.push(window.consent.requestTrackingAuthorization().then((function(statusNew){
					window.consentData.status = statusNew
				}).bind(this)))
			}
			promises.push(window.consent.getConsentStatus().then((function(consentStatus){
				window.consentData.consentStatus = consentStatus;
				if (consentStatus === window.consent.ConsentStatus.Required) {
					return window.consent.requestInfoUpdate()
				}
			}).bind(this)))

			promises.push(window.consent.getFormStatus().then((function(formStatus){
				window.consentData.formStatus = formStatus;
				if (formStatus === window.consent.FormStatus.Available) {
					return window.consent.loadForm()
				}
			}).bind(this)).then((function(form){
				if(form){
					form.show()
				}

			}).bind(this)))

		}
		return RSVP.Promise.all(promises);
	}catch(error){
		console.error(error);
	}
}

window.checkIfReferralIsSuspicious =  function(){
    var isSuspicious = false;
    try{
        var suspiciousReferralIds = ['lizibeth'];
        var searchParams = new URLSearchParams(location.search);
        isSuspicious = searchParams.get("via")?suspiciousReferralIds.includes(searchParams.get("via").toLowerCase()): false;

    }catch(error){
        console.error(error);
    }
    if(isSuspicious){
        var errorMessage = "Something ain't right. Please try again later!";
        window.alertErrorMessage(errorMessage)
        throw errorMessage;
    }
}

// cordova device ready callback
const onDeviceReady = function() {
	window.log("onDeviceReady fireddd");
	if(!window._onDeviceReadyFired){
		window._onDeviceReadyFired = true;
		if(window.device.platform != "browser"){
			//this value must have been overritten by the background mode plugin so we will override this to browser to not break the rest of the Trebble code
			window.cordovaDevice = window.device;
			window.device = {"platform": "browser"};
			try{
				if('cordova' in window && window.cordova.plugins && window.cordova.plugins.backgroundMode){
					window.cordova.plugins.backgroundMode.disable();
					window.cordova.plugins.backgroundMode.setDefaults({ silent: true });
				}
			}catch(error){
				console.error(error);
			}
		}
		

		initRequestUserConsent();

		if("MixpanelTrebbleHelper" in window){
			window.MixpanelTrebbleHelper.init();
		}

		initInstallAttributionTracker();

		if(FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.USE_CUSTOM_LIST_PICKER_ON_IOS)){
			setupListenerOnSelectElementIfApplicable();
		}

		


		if("RaygunNativePlugin" in window && !window.Rollbar){
			try{
				window.RaygunNativePlugin.startNativeRaygun("anonymous_user", window.RAYGUN_IOS_API_KEY); // This will be use in iOS context and will be ignore by android according to cordova doc
			}catch(error){
				console.error(error);
			}
		}
		if(window.waitForCordovaToLoad){
			if("cordova" in window && window.cordova.InAppBrowser && window.cordova.InAppBrowser.open){
				window.openOrignalFunction = function(url, params){ 
					if(window.isIOSMobileDevice && window.cordova && window.cordova.InAppBrowser){
						window.cordova.InAppBrowser.open(url,params)}else{
							window.open(url, params)
						}
					};
					window.open = function(url,  windowName, windowFeatures){
						if(!windowFeatures){
							windowFeatures  = "";
							
						}
						windowFeatures  = windowFeatures +",toolbartranslucent=no,allowInlineMediaPlayback=yes,navigationbuttoncolor=#e9605d,closebuttoncolor=#e9605d,navigationbuttoncolor=#e9605d";
						return window.cordova.InAppBrowser.open(url,windowName, windowFeatures)
					};
				}
			}

			if(window.ContextMenuState){
				try{
					//make sure to enable to context menu on ios
					window.ContextMenuState.setVisible(true);
				}catch(error){
					console.error(error);
				}
			}

			window._segmentSuperProps = {};

			initThirdPartyLibraries();

			if(window.device && window.device.platform == "browser"){
				if((!window.waitForCordovaToLoad || !window.useCordovaMediaPlugin) && !("Media" in window)){
					window.Media = function(){};
				}
				//window.alert = window.alertErrorMessage; 
				//$("body").addClass("ui-responsive-panel");
			}

			initDefaultWhichCanOnlyBeDetectedOnUserAction();

			window.checkIfReferralIsSuspicious();

			if(window.waitForCordovaToLoad){
				if(window.screen && window.screen.orientation && window.screen.orientation.lock && window.isIOSMobileDevice){
					window.screen.orientation.lock('portrait')
				}
			}

			//Setup UXCam is available
			if(FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.ENABLE_RECORDING_VIA_UXCAM)  && "UXCam" in window){
				var UXCAM_API_KEY = "h6vib0w0ovs32sr";
				var enableUXCAM = LocalStorageHelper.getInstance().getNumberOfDaysAppWasRan() < 3 ? true: false;// Enable UXCam only if the user has use the app less than 3 three times. This might also record for sessions for someone who have log out and log in after
				if(enableUXCAM){
					//Setup UXCam is available	
					window.UXCam.startWithKey(UXCAM_API_KEY);
				}
			}

			if(FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.ENABLE_RECORDING_VIA_SMARTLOOK_ANDROID) && "cordova" in window && window.cordova.plugins && window.cordova.plugins.SmartlookPlugin && window.cordova.plugins.SmartlookPlugin && !window.isIOSMobileDevice){
				window.cordova.plugins.SmartlookPlugin.setupAndStartRecording({smartlookAPIKey: window.SMART_LOOK_API_KEY});
			}




			LocalStorageHelper.getInstance().onAppStarted();

			var tokenInfo = LocalStorageHelper.getInstance().retrieveAuthentificationToken();
			if(tokenInfo && LocalStorageHelper.getInstance().isCurrentLoggedUserNotAVisitor()){
				var userInfo = (tokenInfo && tokenInfo.userInfo) ? tokenInfo.userInfo : {};
				window.saveUserIdForTracking(userInfo._id, userInfo.email, userInfo.lastName, userInfo.firstName, userInfo.creationDate, userInfo); 
			}else{
				//window.trebbleAnalyticsHelper.setUserId((tokenInfo.userInfo.temporary && tokenInfo.userInfo.temporaryUserRandomId) ? tokenInfo.userInfo.username +"_"+ tokenInfo.userInfo.temporaryUserRandomId:  tokenInfo.userInfo.username);
				/*if(window.trebbleAnalyticsHelper){
					window.trebbleAnalyticsHelper.trebbleHelperSetTemporary(true, LocalStorageHelper.getInstance().getUniqueUserIdentifier());
				}*/
			}




			$(window).hashchange(saveJQMHistoryStack);
			if(window.trebble.config.useSavedJQMHistory)
			{
				restorePreviousBrowserHistory();
			}
			if(window.device && window.device.platform != "Android"){
				setTimeout(function() {
					//document.body.style["background-color"]="rgba(17,17,17,1)";
					//document.body.style["border-color"]="rgba(17,17,17,17)";
					document.body.children[1].display = "none";
					if( navigator.splashscreen){
						navigator.splashscreen.hide();
					}
				}, 3000);
			}



			window.trackAppContext();
			if(window.trebbleAnalyticsHelper && window.trebbleAnalyticsHelper.trebbleHelperSetEventEmitter){
				window.trebbleAnalyticsHelper.trebbleHelperSetEventEmitter();
			}
			if(window.trebbleAnalyticsHelper){
				window.trebbleAnalyticsHelper.startTrackerWithId('UA-64269295-1');
				if(window.trebbleAppVersion){
					window.trebbleAnalyticsHelper.trebbleHelperSetAppTimestampVersion(window.trebbleAppVersion)
				}
			}

			$(document).on('pageinit',startAppOnJqueryMobileReady);
			
		}
	};

	const startAppOnJqueryMobileReady = function(){
		onmobileinit();
		if((window.location.hash.indexOf( window.trebble.popupLoginPageHash) == 1 || window.getPathNameWithoutHtmlPage().indexOf( window.trebble.popupLoginPageHash) == 1) && window.opener && window.opener.document && (window.location.hash || window.getPathNameWithoutHtmlPage()) ){
			window.log("doing  login only");
			window.trebble.isEmbededLoginInPage = true;
			window.trebble.allowEasySignupForListenersAtLogin = true;
			//Since this is an embedded page which probably opening a login popup, set an access code to allow user to login
			window.trebble.presetAccessCode = FREE_ACCESS_CODE_FOR_USER_WHO_DISCOVER_TREBBLE_VIA_SHARED_LINK;
			doLogingOnly(window.location.href);

		}else{
			window.log("normal startup");
			if(!window.isEmbedded){
				//if(!window.isIOSMobileDevice || LocalStorageHelper.getInstance().isLoggedInUserManuallyAgreedToTurnOnNotification()){
				if(!window.isIOSMobileDevice || LocalStorageHelper.getInstance().getUserManuallyEnabledDisabledDailyFeedNotification()){
					if(LocalStorageHelper.getInstance().getUserManuallyEnabledDisabledDailyFeedNotification() !== false){
						require(["models/helper/PushNotificationHelper"], function(PushNotificationHelper){
							if(PushNotificationHelper.default.getInstance().isPushNotificationLibraryInstalled()){
								PushNotificationHelper.default.getInstance().initNativePushNotification();
							}
						});
					}
				}

			}
			presetProfileTypeForSignupIfApplicable();
            applyCouponIfApplicable();
			applyTemporaryTokenOrAccessCodePassedViaUrlIfApplicable().then(retrieveAllSongs.bind(this)).catch(retrieveAllSongs.bind(this));
		}
	}


    const onmobileinit = function(){
    window.log('Jquery Mobile Loaded');
    $.mobile.ajaxEnabled = false;
    $.mobile.pushStateEnabled = false;
    $.mobile.touchOverflowEnabled = false;
    $.mobile.defaultPageTransition = 'none';
    $.mobile.defaultDialogTransition = 'pop';
    $.mobile.transitionFallbacks.slide = 'none';
    $.mobile.transitionFallbacks.pop = 'none';
    $.mobile.ignoreContentEnabled = true;
    $.mobile.imaxTransitionWidth = "100px";
    $.mobile.page.prototype.options.domCache = true;
    $.mobile.hoverDelay = 0;
    $.mobile.buttonMarkup.hoverDelay = 0;
    $.mobile.phonegapNavigationEnabled = false;
    $.mobile.hashListeningEnabled = false;
    $.mobile.linkBindingEnabled = false;
    $.mobile.changePage.defaults.changeHash = false;

        //iosquirk for status bar
    if (navigator.userAgent.match(/(iPad.*|iPhone.*|iPod.*);.*CPU.*OS 7_\d/i)) {
        $("body").addClass("ios7");
        $("body").append('');
    }

};

if(window.waitForCordovaToLoad){
    document.addEventListener("deviceready", onDeviceReady, false);
}else{
    onDeviceReady();
}

document.addEventListener("onmobileinit", onmobileinit, false);

const onApplicationNativelyPaused= function(){
    if(window.device.platform == "iOS"){
            //window.plugins.backgroundjs.lockBackgroundTime();
    }
    if(window.trebbleAnalyticsHelper){
            //window.trebbleAnalyticsHelper.trackEvent("Application State", 'Application Going Background', 'Background', (new Date()).getTime());
    }
    
        var playerModel = PersistentModels.getInstance().getPlayer();
        if(playerModel){
            if(window.device && window.device.platform != "Android" && window.device && window.device.platform != "iOS" && playerModel){
                if(playerModel.isPlaying() && playerModel.isYoutubeVideoLoaded()){
                    playerModel.pauseAudio(true);
                    playerModel.playAudio(true);
                }

            }
            if(window.waitForCordovaToLoad){
                playerModel._updateLockScreen();
            }

        }

    

};
const onApplicationNativelyResumed= function(){
    if(window.device.platform == "iOS"){
            //window.plugins.backgroundjs.unlockBackgroundTime();
    }
    if(window.trebbleAnalyticsHelper){
            //window.trebbleAnalyticsHelper.trackEvent("Application State", 'Application Going Foreground', 'Foreground', (new Date()).getTime());
    }
    if(FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.SHOW_INTERSTITIAL_ADS)){
        
            var playerModel = PersistentModels.getInstance().getPlayer();
            if(playerModel){
                var displayInterstitialOnMobile = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_INTERSTITIAL_ADS, Rollout.FEATURES.SHOW_INTERSTITIAL_ADS.variables.show_interstitials_in_app, false);
                var showVideoWhenAppGoesForeground = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_INTERSTITIAL_ADS, Rollout.FEATURES.SHOW_INTERSTITIAL_ADS.variables.show_when_app_comes_foreground_show_video, false);  
                var showAdOnlyWhenAudioIsLoaded = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_INTERSTITIAL_ADS, Rollout.FEATURES.SHOW_INTERSTITIAL_ADS.variables.show_when_app_comes_foreground_and_audio_is_loaded, false);  
                var showInterstitialWhenAppGoesForeground = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_INTERSTITIAL_ADS, Rollout.FEATURES.SHOW_INTERSTITIAL_ADS.variables.show_when_app_comes_foreground, false);
                var muteAds = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_INTERSTITIAL_ADS, Rollout.FEATURES.SHOW_INTERSTITIAL_ADS.variables.mute_audio, false); 
                if(muteAds){
                    showVideoWhenAppGoesForeground = true;
                }
                if(window.waitForCordovaToLoad && displayInterstitialOnMobile && showInterstitialWhenAppGoesForeground  && (!showAdOnlyWhenAudioIsLoaded || playerModel.getPlaylist())){
                    AdHelper.getInstance().showInterstitialAd(showVideoWhenAppGoesForeground, muteAds);
                }
            }
        
    }
};
document.addEventListener("pause", onApplicationNativelyPaused, false);
document.addEventListener("resume", onApplicationNativelyResumed, false);

window.onAndroidRemoteControlPressed = function(action){
    
        var playerModel = PersistentModels.getInstance().getPlayer();
        if(playerModel){
            switch(action) {
            case "next":
                playerModel.playNextSongInPlaylist(true);
                break;
            case "previous":
                playerModel.playPreviousSongInPlaylist(true);
                break;
            case "play":
                if(!playerModel.isLoading()){
                //playerModel.stopAudioAndRelease();
                    playerModel.playAudio();
                }
                break;
            case "pause":
                if(!playerModel.isLoading()){
                    if (playerModel.isPlaying()) {
                        playerModel.pauseAudio();
                    }
                }
                break;
            case "playpause":
                if(!playerModel.isLoading()){
                    if (playerModel.isPlaying()) {
                        playerModel.pauseAudio();
                    } else {
                        playerModel.playAudio();
                    }
                }
                break;
            case "playyoutube":
                if(playerModel.isPlaying() && playerModel.isYoutubeVideoLoaded()){
                    playerModel.pauseAudio();
                    playerModel.playAudio();
                }
                break;
            }
        /*if(action == "next")
        {
            playerModel.playNextSongInPlaylist(true);
        }else{
            if(action  == "previous")
            {
                playerModel.playPreviousSongInPlaylist(true);
            }else{
                if(action == "playpause")
                {
                    if (playerModel.isPlaying()) {
                        playerModel.pauseAudio();
                    } else {
                        playerModel.playAudio();
                    }
                }else{
                    if(action == "playyoutube")
                    {
                        if(playerModel.isPlaying() && playerModel.isYoutubeVideoLoaded()){
                            playerModel.pauseAudio();
                            playerModel.playAudio();
                        }
                    }
                }else{
                    if(action  == "play"){
                        playerModel.playAudio();
                    }else{
                        if(action  == "pause"){
                            playerModel.pauseAudio();
                        }
                    }

                }
            }
        }*/
        }

};

try{
    if ('mediaSession' in navigator && navigator.mediaSession.setActionHandler) {
        navigator.mediaSession.setActionHandler('previoustrack', function() {
        // User clicked "Previous Track" media notification icon.
            
                var playerModel = PersistentModels.getInstance().getPlayer();
                if(playerModel){
                    playerModel.playPreviousSongInPlaylist(true);
                }
            
        });

        navigator.mediaSession.setActionHandler('nexttrack', function() {
        // User clicked "Next Track" media notification icon.
            
                var playerModel = PersistentModels.getInstance().getPlayer();
                if(playerModel){
                    playerModel.playNextSongInPlaylist(true);	
                }
            
        });
        navigator.mediaSession.setActionHandler('play', function() {
        // User clicked "Play" media notification icon.
            
                var playerModel = PersistentModels.getInstance().getPlayer();
                if(playerModel){
                    playerModel.playAudio();
                }
        
        });
        navigator.mediaSession.setActionHandler('pause', function() {
        // User clicked "Pause" media notification icon.
            
                var playerModel = PersistentModels.getInstance().getPlayer();
                if(playerModel){
                    playerModel.pauseAudio();
                }
            
        });
    }
}catch(e){
    console.error("Failed setting up media sesssion handlers. Error:" + e);
}

window.sendErrorToRaygun = function(errorMessage, critical){

        try{

            if(window.Raygun && !window.Rollbar){
                window.Raygun.send(new Error(errorMessage));
            }
        }catch(error){
            console.error("Failed sending error to Raygun" + error);
        }
            try{
                var messagesToIgnore = ["Oops! Unauthorised transaction", "Something ain't right. Please try again later!"];
            if(window.Rollbar && errorMessage && !messagesToIgnore.includes(errorMessage)){
                if(critical){
                    window.Rollbar.critical(new Error(errorMessage));
                }else{
                    window.Rollbar.error(new Error(errorMessage));
                }
            }
        }catch(error){
            console.error("Failed sending error to Rollbar" + error);
        }
        try{
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("ErrorCollection", "ErrorCollection", "Error collected", null, {"errorMessage":errorMessage, "critical": critical});
            } 
        }catch(error){
            console.error("Failed sending error to mpanel" + error);
        }
    }

		

	


	