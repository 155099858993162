import { Button, Modal, Select } from "antd";
import { ReactElement, JSXElementConstructor, ReactNode, ReactPortal, useState } from "react";
import { getEnumFromValue } from "../ThemeEditor";
import { VideoCanvas } from "../../../remotion/Components/DesignSystem";
import { Theme } from "../../VideoEditor";
import { FocusRegion } from "../../../remotion/Components/VideoSegmentMultiFocus/VideoSegmentMultiFocus";
import VideoFocusRegionSelector from "./VideoFocusRegionSelector";
import sampleVideoData from '../../../remotion/video_data_to_render.json';
import { defaultFocusRegions } from "./DefaultLayouts";

const LAYOUT_CONFIG = Object.freeze({
    DEFAULT: { value: "default", label: "Default" },
    SQUARE: { value: 'square', label: 'Square' },
    PORTRAIT: { value: 'portrait', label: 'Portrait' },
    LANDSCAPE: { value: 'landscape', label: 'Landscape' },
    SPLIT: { value: "2-split", label: "2-Split" },
    SCREENSHARE: { value: "screenshare", label: "Screenshare" },
    GAMEPLAY: { value: "gameplay", label: "Gameplay" },
    THREE: { value: "3-split", label: "Three speakers" },
    FOUR: { value: "4-split", label: "Four speakers" },
});

const ASPECT_RATIOS = Object.freeze({
    SQUARE: {
        value: "square",
        label: "1:1",
        suitable_platforms: ["LinkedIn", "X", "Instagram"],
    },
    PORTRAIT: {
        value: "portrait",
        label: "9:16",
        suitable_platforms: ["Shorts", "Reels", "Tiktok"],
    },
    LANDSCAPE: {
        value: "landscape",
        label: "16:9",
        suitable_platforms: ["Youtube", "Spotify"],
    },
});

const ASPECT_RATIOS_VALUES_TO_RESOLUTIONS = (value: string): VideoCanvas => {
    switch (value) {
        case ASPECT_RATIOS.SQUARE.value:
            return { aspectRatio: "1:1", width: 1080, height: 1080, safeZone: { top: 100, bottom: 100, left: 100, right: 100 } };
        case ASPECT_RATIOS.PORTRAIT.value:
            return { aspectRatio: "9:16", width: 1080, height: 1920, safeZone: { top: 100, bottom: 100, left: 100, right: 100 } };
        case ASPECT_RATIOS.LANDSCAPE.value:
            return { aspectRatio: "16:9", width: 1920, height: 1080, safeZone: { top: 100, bottom: 100, left: 100, right: 100 } };
        default:
            return { aspectRatio: "16:9", width: 1920, height: 1080, safeZone: { top: 100, bottom: 100, left: 100, right: 100 } };
    }
}

const RESOLUTIONS_TO_ASPECT_RATIOS_VALUES = (resolution: VideoCanvas): string => {
    if (resolution.width === 1080 && resolution.height === 1080) {
        return ASPECT_RATIOS.SQUARE.value;
    } else if (resolution.width === 1080 && resolution.height === 1920) {
        return ASPECT_RATIOS.PORTRAIT.value;
    } else if (resolution.width === 1920 && resolution.height === 1080) {
        return ASPECT_RATIOS.LANDSCAPE.value;
    } else {
        return ASPECT_RATIOS.LANDSCAPE.value;
    }
}

export const LayoutComponent = ({ selectedTheme, setSelectedTheme, videoRenderData, inCloudRenderingEnvironment }: { selectedTheme: Theme, setSelectedTheme: (theme: Theme) => void, videoRenderData: typeof sampleVideoData , inCloudRenderingEnvironment:boolean}) => {
    // State to manage the modal visibility and the currently edited focus region
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentFocusRegionIndex, setCurrentFocusRegionIndex] = useState<number | null>(null);

    const onLayoutChange = (value: string) => {
        if (value == 'default' || value == '2-split' || value == '3-split' || value == '4-split' || value == 'screenshare' || value == 'gameplay' || value == 'square' || value == 'portrait' || value == 'landscape') {
            const newFraming = defaultFocusRegions(value, selectedTheme.designSystem.canvas.aspectRatio)
            setSelectedTheme({ ...selectedTheme, videoConfig: { ...selectedTheme.videoConfig, framing: newFraming } });
            return 
        }
    }

    const handleEditFocusRegion = (index: number) => {
        setCurrentFocusRegionIndex(index);
        setIsModalVisible(true);
    }

    const handleFocusRegionChange = (updatedRegion: FocusRegion<any>) => {
        if (currentFocusRegionIndex !== null) {
            const updatedFocusRegions = [...selectedTheme.videoConfig.framing[selectedTheme.designSystem.canvas.aspectRatio].focusRegions];
            updatedFocusRegions[currentFocusRegionIndex] = updatedRegion;

            setSelectedTheme({
                ...selectedTheme,
                videoConfig: {
                    ...selectedTheme.videoConfig,
                    framing: {
                        ...selectedTheme.videoConfig.framing,
                        [selectedTheme.designSystem.canvas.aspectRatio]: {
                            ...selectedTheme.videoConfig.framing[selectedTheme.designSystem.canvas.aspectRatio],
                            focusRegions: updatedFocusRegions as any,
                        }
                    }
                }
            });
        }
    }

    return (
        <div>
            <div>
                <div className="mb-1">Aspect ratio:</div>
                <Select
                    className="w-full"
                    value={RESOLUTIONS_TO_ASPECT_RATIOS_VALUES(selectedTheme.designSystem.canvas)}
                    onChange={(value) => { setSelectedTheme({ ...selectedTheme, designSystem: { ...selectedTheme.designSystem, canvas: ASPECT_RATIOS_VALUES_TO_RESOLUTIONS(value) } }) }}
                    optionRender={(option: { data: { label: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; suitable_platforms: any[]; }; }) => (
                        <div className="flex flex-col">
                            <div>{option.data.label}</div>
                            <div className="opacity-50">
                                {option.data.suitable_platforms.join(", ")}
                            </div>
                        </div>
                    )}
                    labelRender={({ value }: { value: any }) => {
                        const selectedRatio = getEnumFromValue(value, ASPECT_RATIOS) as any;

                        return (
                            <div className="flex flex-row">
                                {selectedRatio.label}
                                <span className="ml-1 opacity-50">
                                    {" - " + selectedRatio.suitable_platforms.join(", ")}
                                </span>
                            </div>
                        );
                    }}
                    options={Object.values(ASPECT_RATIOS)}
                />

                <div className="mb-1">Layout:</div>

                <Select
                    className="w-full"
                    value={selectedTheme.videoConfig.framing.name}
                    onChange={(value) => onLayoutChange(value)}
                    optionRender={(option: { data: { label: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; }; }) => (
                        <div className="flex flex-col">
                            <div>{option.data.label}</div>
                        </div>
                    )}
                    labelRender={({ value }: { value: any }) => {
                        const selectedConfig = getEnumFromValue(value, LAYOUT_CONFIG) as any;

                        return (
                            <div className="flex flex-row">{selectedConfig.label}</div>
                        );
                    }}
                    options={Object.values(LAYOUT_CONFIG)}
                />
            </div>
            {selectedTheme.videoConfig.framing[selectedTheme.designSystem.canvas.aspectRatio].focusRegions.length > 0 && (
                <>
                    <div className="mb-1">Camera Frame:</div>
                    <div className="flex flex-col gap-5">
                        {selectedTheme.videoConfig.framing[selectedTheme.designSystem.canvas.aspectRatio].focusRegions.map((focusRegion, index) => {
                            return (
                                <div key={index} className="flex flex-col gap-2">
                                    <div className="flex flex-col">
                                        <div className="text-sm">Focus Region {index + 1}</div>
                                        <div className="text-xs opacity-50">Aspect ratio: {focusRegion.aspectRatio}</div>
                                    </div>
                                    <Button onClick={() => handleEditFocusRegion(index)}>Edit focus region</Button>
                                </div>
                            );
                        })}
                    </div>
                </>
            )}

            {currentFocusRegionIndex !== null && (
                <Modal
                    title={`Edit Focus Region ${currentFocusRegionIndex + 1}`}
                    open={isModalVisible}
                    onCancel={() => setIsModalVisible(false)}
                    footer={null}
                    width={800}
                >
                    <VideoFocusRegionSelector
                        videoUrl={inCloudRenderingEnvironment? videoRenderData[0]?.sequencerNodeReference?.audioSegment?.audioUrl: videoRenderData[0].cachedBlobUrl}
                        focusRegion={selectedTheme.videoConfig.framing[selectedTheme.designSystem.canvas.aspectRatio].focusRegions[currentFocusRegionIndex]}
                        onFocusRegionChange={handleFocusRegionChange}
                    />
                </Modal>
            )}
        </div>
    );
};