import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Song from "models/Song";
import Album from "models/Album";
import Artist from "models/Artist";
import Capsule from "models/Capsule";
import JourneyOutlineItem from "models/JourneyOutlineItem";
import Comment from "models/Comment";
import User from "models/User";
import CustomNotificationPayload from "models/CustomNotificationPayload";

import PlaylistSummary from "models/PlaylistSummary";
import JourneySummary from "models/JourneySummary";
import TagGroup from "collections/TagGroup";
import ListItemModel from "models/ListItemModel";
import UserReview from "models/UserReview";
import CordovaHelper from "models/helper/CordovaHelper";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import PauseAudioSegment from "models/audioEditor/segments/PauseAudioSegment";
import WordAudioSegment from "models/audioEditor/segments/WordAudioSegment";
import AudioEventAudioSegment from "models/audioEditor/segments/AudioEventAudioSegment";
import DeletedAudioSegment from "models/audioEditor/segments/DeletedAudioSegment";
import CloudAudioSegment from "models/audioEditor/segments/CloudAudioSegment";
import CloudVideoSegment from "models/audioEditor/segments/CloudVideoSegment";
import PunctuationAudioSegment from "models/audioEditor/segments/PunctuationAudioSegment";
import UnsupportedAudioSegment from "models/audioEditor/segments/UnsupportedAudioSegment";
import MusicAudioSegment from "models/audioEditor/segments/MusicAudioSegment";
import DeletedSequencerNode from "models/audioEditor/sequencerNodes/DeletedSequencerNode";
import PauseSequencerNode from "models/audioEditor/sequencerNodes/PauseSequencerNode";
import PunctuationSequencerNode from "models/audioEditor/sequencerNodes/PunctuationSequencerNode";
import UnsupportedAudioSequencerNode from "models/audioEditor/sequencerNodes/UnsupportedAudioSequencerNode";
import WordSequencerNode from "models/audioEditor/sequencerNodes/WordSequencerNode";
import AudioEventSequencerNode from "models/audioEditor/sequencerNodes/AudioEventSequencerNode";
import WrapSequencerNode from "models/audioEditor/sequencerNodes/WrapSequencerNode";
import StartMusicWrapSequencerNode from "models/audioEditor/sequencerNodes/StartMusicWrapSequencerNode";
import EndMusicWrapSequencerNode from "models/audioEditor/sequencerNodes/EndMusicWrapSequencerNode";
import StartWrapSequencerNode from "models/audioEditor/sequencerNodes/StartWrapSequencerNode";
import EndWrapSequencerNode from "models/audioEditor/sequencerNodes/EndWrapSequencerNode";
import AudioSequencerNode from "models/audioEditor/sequencerNodes/AudioSequencerNode";
import VideoSequencerNode from "models/audioEditor/sequencerNodes/VideoSequencerNode";
import NonRetainedDeletedSequencerNode from "models/audioEditor/sequencerNodes/NonRetainedDeletedSequencerNode";
import SpeakerInfo from "models/audioEditor/SpeakerInfo";
import ti18n from "i18n!nls/Utilsi18n";
import WeightedList from "libs/js-weighted-list/js-weighted-list-async";
import { tz as momentTimezone } from "moment-timezone";
import Hammer from "hammerjs";
import swal from "sweetalert";
import Swiper from "swiper";
import BottomSheet from "BottomSheet";
import ColorThief from "ColorThief";
import Vibrant from "vibrantjs";
import TippyJS, { createSingleton, sticky } from "tippy.js";

//"libs/bottomSheet/BottomSheet",
import JSConfetti from "confettiJS";

import TrebbeSpecificListeningInstructionsTemplate from "text!../../../templates/common/TrebbeSpecificListeningInstructions.html";
import TrebbeSpecificListeningInstructionsi18n from "i18n!nls/TrebbeSpecificListeningInstructionsi18n";
import tlds from "models/helper/TLDS";
import moment from "moment";
import SequencerContextMenuDefaultTemplate from "text!../../../templates/audioEditor/SequencerContextMenuDefaultTemplate.html";

/*"notifyjs",
    "text!../../../templates/common/NotificationPreviewAlertTemplate.html",*/ import RSVP from "rsvp";
import { instanceOf } from "prop-types";

const RESIZE_IMAGE_SERVER_URL = window.trebble.usedProtocolPrefix + "desolate-ravine-6401.herokuapp.com";
const RESIZE_IMAGE_SERVER_URL_VIA_CLOUDINARY =
  window.trebble.usedProtocolPrefix + "res.cloudinary.com/trebble-fm/image/fetch";
const USE_CLOUDINARY_FOR_IMAGE_RESIZING = true;
const CLOUDINARY_BLUR_VALUE = 300;
const RESIZE_SERVER_HASH = "fa1b770ea8f6bf98198ba0036b18fc66abd13655";
const SOUNDCLOUD_URI_PREFIX = "soundcloud";
const OTHER_SOURCE_URI_PREFIX = "othersource";
const SPOTIFY_URI_PREFIX = "spotify";
const EPIDEMIC_URI_PREFIX = "epidemic";
const YOUTUBE_URI_PREFIX = "youtube";
const CAPSULE_URI_PREFIX = "tempcapsule";
const SONG_URI_PREFIX = "tempsong";
const JINGLE_URI_PREFIX = "tempjingle";
const GREATER_URI_PREFIX = "tempgreater";
const INTRO_URI_PREFIX = "tempintro";
const OUTRO_URI_PREFIX = "tempoutro";
const EARCON_URI_PREFIX = "tempearcon";
const TREBBLE_GENERATED_SPEECH_URI_PREFIX = "temptrebblegeneratedspeech";

const TREBBLE_APPFLYER_ONELINK_ID = "B90F";

const URI_DELIMITER = ":";

const UNDEFINED_GENRE_NAME = "Undefined Genre";
const UNDEFINED_GENRE_ID = "tagId:undefinedGenreId";
const UNDEFINED_GENRE_VALUE = "Undefined Genre";
const GERNRE_TAG_GROUP_ID = "tagGroupId:Genre";
const GERNRE_TAG_GROUP_NAME = "Genre";
const GERNRE_TAG_GROUP_VALUE = "Genre";

const GENRE_TAG_GROUP_EXCLUSIF = false;
const ERA_TAG_GROUP_ID = "tagGroupId:Era";
const ERA_TAG_GROUP_NAME = "Year";
const ERA_TAG_GROUP_VALUE = "Year";

const NOTIFICATION_DISPLAY_TIME_IN_MILLI = 6000;

const JOURNEY_NAME_MAX_NAME_LENGTH = 100;
const JOURNEY_GOAL_MAX_NAME_LENGTH = 100;
const JOURNEY_DESC_MAX_NAME_LENGTH = 400;

//const ERA_TAG_GROUP_EXCLUSIF = false;
const UNDEFINED_ERA_NAME = "Undefined Era";
const UNDEFINED_ERA_ID = "tagId:undefinedEraId";
const UNDEFINED_ERA_VALUE = "Undefined Era";
const SONG_COLLECTION_CONTEXTS = {
  GLOBAL_SEARCH: "globalSearch",
  MY_LIBRARY: "myLibrary",
  AUTO_RADIO: "autoRadio",
  LOCAL_AUTO_RADIO: "localAutoRadio",
  USER_CUSTOM: "userCustom",
  ALBUM: "album",
  ARTIST: "artist",
  MY_SUBSCRIPTIONS: "mySubscriptions",
  MY_JOURNEYS: "myJourneys",
  CAPSULE_FEED: "capsuleFeed",
  CATEGORY_SHORTCAST_RADIO: "categoryShortcastRadio",
  RADIO: "radio",
  SIMPLE_CAPSULE_SET: "simpleCaspuleSet",
  COMMENT_ON_CAPSULE: "commentOnCapsule",
  SIMPLE_SONG_SET: "simpleSongSet",
  SOUNDCLOUD: "soundcloud",
  YOUTUBE: "youtube",
  AUTO_SONG_RADIO: "autoSongRadio",
  FOLLOWING_RADIO_DETAIL: "followingRadioDetail",
  MY_RADIO_DETAIL: "myRadioDetail",
  MY_AUTO_RADIO_DETAIL: "myAutoRadioDetail",
  EXPLORE_RADIO_DETAIL: "exploreRadioDetail",
  PUSH_NOTIFICATION: "pushNotification",
  MY_CAPSULES_PAGE: "myCapsulePage",
  USER_PAGE: "userDetailsPage",
  OVER_THE_AIR: "overTheAir",
  CAPSULE_CREATION: "capsuleCreation",
  TREBBLE_APP_EXTENSION: "trebbleAppExtension",
  HELPER_PAGE: "helperPage",
  HOMESCREEN: "homescreen",
  NEWSLETTER: "newsletter",
  AUTO_PLAY_TREBBLE_SUGGESTION_FROM_CAPSULE: "autoPlayTrebbleSuggestionFromCapsule",
  AUTO_PLAY_TREBBLE_SUGGESTION_FROM_SONG: "autoPlayTrebbleSuggestionFromSong",
  TREBBLE_PLUGIN: "trebblePlugin",
};

const RADIO_TYPE = {
  DEFAULT: "default",
  ACTIVITY: "activity",
  MOOD: "mood",
  GENRE: "genre",
  CUSTOM: "custom",
  JOURNEY: "journey",
};

const TITLE_ARTIST_SEPARATORS = [" -- ", "--", " - ", " – ", " — ", "-", "–", "—", ":", "|", "///"];

const LIBRARY_FILTER_OPTIONS = {
  ON_DEVICE: window.getI18n(ti18n, "ON_DEVICE"),
  IN_CLOUD: window.getI18n(ti18n, "IN_CLOUD"),
  WITH_CAPSULE: window.getI18n(ti18n, "WITH_CAPSULE"),
  WITHOUT_CAPSULE: window.getI18n(ti18n, "WITHOUT_CAPSULE"),
  GRADED: window.getI18n(ti18n, "GRADED"),
  UNGRADED: window.getI18n(ti18n, "UNGRADED"),
};
const LIRBARY_SORTING_OPTIONS = {
  TITLE_ASCENDING: window.getI18n(ti18n, "TITLE_ASCENDING"),
  TITLE_DESCENDING: window.getI18n(ti18n, "TITLE_DESCENDING"),
  RECENTLY_ADDED: window.getI18n(ti18n, "RECENTLY_ADDED"),
  FIRST_ADDED: window.getI18n(ti18n, "FIRST_ADDED"),
};
const SKIP_TRACK_REASONS = {
  TIRED_OF_TRACK: window.getI18n(ti18n, "TIRED_OF_TRACK"),
  NOT_IN_MOOD_FOR_TRACK: window.getI18n(ti18n, "NOT_IN_MOOD_FOR_TRACK"),
  DONT_LIKE_TRACK: window.getI18n(ti18n, "DONT_LIKE_TRACK"),
  DONT_LIKE_ARTIST: window.getI18n(ti18n, "DONT_LIKE_ARTIST"),
  WRONG_LINK_PLAYING: window.getI18n(ti18n, "WRONG_LINK_PLAYING"),
};
const CAPSULE_LIFE_SPAN_LABEL_TO_VALUE_IN_DAYS = {};
CAPSULE_LIFE_SPAN_LABEL_TO_VALUE_IN_DAYS[window.getI18n(ti18n, "TWENTY_FOUR_HOURS")] = 1;
CAPSULE_LIFE_SPAN_LABEL_TO_VALUE_IN_DAYS[window.getI18n(ti18n, "FOURTY_EIGHT_HOURS")] = 2;
CAPSULE_LIFE_SPAN_LABEL_TO_VALUE_IN_DAYS[window.getI18n(ti18n, "SEVENTHY_TWO_HOURS")] = 2;
CAPSULE_LIFE_SPAN_LABEL_TO_VALUE_IN_DAYS[window.getI18n(ti18n, "SEVEN_DAYS")] = 7;
CAPSULE_LIFE_SPAN_LABEL_TO_VALUE_IN_DAYS[window.getI18n(ti18n, "TWENTY_EIGHT_DAYS")] = 28;
CAPSULE_LIFE_SPAN_LABEL_TO_VALUE_IN_DAYS[window.getI18n(ti18n, "ALWAYS_ON_AIR")] = null;

const MAX_NUMBER_OF_REJECTION_TO_RATE_APP = RolloutHelper.getInstance().getFeatureVariable(
  RolloutHelper.FEATURES.PROMPT_USER_TO_RATE_APP,
  RolloutHelper.FEATURES.PROMPT_USER_TO_RATE_APP.variables.max_number_of_rejection_to_rate_app,
  2,
);
const MAX_NUMBER_OF_SUGGESTION_SENT_FOR_APP_IMPROVEMENT = RolloutHelper.getInstance().getFeatureVariable(
  RolloutHelper.FEATURES.PROMPT_USER_TO_RATE_APP,
  RolloutHelper.FEATURES.PROMPT_USER_TO_RATE_APP.variables.max_number_of_times_to_suggest_user_to_give_app_improvements,
  2,
);
const MIN_NUMBER_OF_DAYS_CAPSULE_FEED_PLAY_TO_REQUEST_RATING = RolloutHelper.getInstance().getFeatureVariable(
  RolloutHelper.FEATURES.PROMPT_USER_TO_RATE_APP,
  RolloutHelper.FEATURES.PROMPT_USER_TO_RATE_APP.variables.min_number_of_times_feed_needs_to_play_to_for_rating,
  3,
);
const MIN_NUMBER_OF_DAYS_BETWEEN_TO_RATE_APP_REQUEST = RolloutHelper.getInstance().getFeatureVariable(
  RolloutHelper.FEATURES.PROMPT_USER_TO_RATE_APP,
  RolloutHelper.FEATURES.PROMPT_USER_TO_RATE_APP.variables.min_number_of_days_between_app_rating_requests,
  10,
);
const ALWAYS_USE_MEDIA_RECORDERJS = RolloutHelper.getInstance().getFeatureVariable(
  RolloutHelper.FEATURES.CAPSULE_RECORDING_UI_DEFAULT_CONFIG,
  RolloutHelper.FEATURES.CAPSULE_RECORDING_UI_DEFAULT_CONFIG.variables.always_use_media_recordjs,
);

const TREBBLE_TRANSCRIPITON_JOB_STATUS_TO_I18N_KEY = {
  INITIALIZED: "TRANSCRIPTION_JOB_STATUS_INITIALIZED",
  COMPLETED: "TRANSCRIPTION_JOB_STATUS_COMPLETED",
  FAILED: "TRANSCRIPTION_JOB_STATUS_FAILED",
  IN_PROGRESS: "TRANSCRIPTION_JOB_STATUS_IIN_PROGRESS",
};
//const APPFLYER_INVITE_URL_EXAMPLE ="https://app.appsflyer.com/fm.trebble?pid=af_app_invites&af_referrer_customer_id=5cda3980c0f68c00134a8099&af_referrer_customer_id=573cfeb2f538f40a0042a37a&c=activeUserReferralCampaign&af_referrer_uid=1573013104613-2516630287186108914&af_channel=gmail&af_siteid=fm.trebble&username=monsieur_ninja"

const PROFILE_TYPES = {
  LISTENER: "listener",
  CREATOR: "creator",
  JOURNEY_CREATOR: "journey_creator",
  SHORTCAST_CREATOR: "shortcast_creator",
};

const NOTIFICATION_TYPES = {
  SONG_FROM_TREBBLE_ADDED_BY_LISTENER: "SongFromTrebbleAddedFromListener",
  SONG_FROM_TREBBLE_LIVE_LIKED_BY_LISTENER: "SongFromTrebbleLiveLikedFromListener",
  DAILY_CAPSULE_FEED_IS_READY: "dailyCapsuleFeedIsReady",
  USER_MENTIONED_IN_CAPSULE: "UserMentionedInCapsule",
  REPLY_TO_CAPSULE: "replyToCapsule",
  CAPSULE_REPLY_WHILE_LISTENING_TO_TREBBLE: "capsuleReplyWhileListeningToTrebble",
  CAPSULE_REPLY_WHILE_LISTENING_TO_CAPSULE_SIMPLE_SET: "capsuleReplyWhileListeningToCapsuleSimpleSet",
  CAPSULE_PROCESSING_COMPLETED: "CapusleProcessingCompleted",
  CAPSULE_PROCESSING_FAILED: "CapusleProcessingFailed",
  COMMENT_REPLY: "CommentReply",
  COMMENT_IN_A_SONG_WHILE_LISTENING_TO_TREBBLE: "commentInASongWhileListeningToTrebble",
  COMMENT_ON_A_CAPSULE: "commentOnACapsule",
  COMMENT_IN_A_SONG_WHILE_LISTENING_TO_CAPSULE_SIMPLE_SET: "commentInASongWhileListeningToCapsuleSimpleSet",
  COMMENT_IN_A_SONG_WHILE_LISTENING_TO_SONG_SIMPLE_SET: "commentInASongWhileListeningToSongSimpleSet",
  MENTIONED_IN_COMMENT: "mentionedInComment",
  COMMENT_WAS_LIKED: "commentWasLiked",
  NEW_PLAY_RECORD_REACH_FOR_TREBBLE: "newPlayRecordReachForTrebble",
  USER_STARTED_FOLLOWING_YOU: "UserStartedFollowingYou",
  USER_STARTED_FOLLOWING_YOUR_TREBBLE: "UserStartedFollowingYourTrebble",
  PURCHASE_OF_JOURNEY_COMPLETED_AND_PAID: "purchaseOfJourneyCompletedAndPaid",
  PURCHASE_OF_JOURNEY_FULLFILLED: "purchaseOfJourneyFullfilled",
  PURCHASE_OF_JOURNEY_PAYMENT_FAILED: "purchaseOfJourneyPaymentFailed",
  TRANSCRIPTION_JOB_COMPLETED: "transcriptionJobCompleted",
  TRANSCRIPTION_JOB_FAILED: "transcriptionJobFailed",
  CREATOR_SUBSCRIPTION_STATUS_CHANGED: "creatorSubscriptionStatusChanged",
  CREATOR_SUBSCRIPTION_DELETED: "creatorSubscriptionDeleted",
  SPEECH_ENHANCEMENT_JOB_COMPLETED:"speechEnhancementJobCompleted",
	SPEECH_ENHANCEMENT_JOB_FAILED:"speechEnhancementJobFailed",
  "VIDEO_RENDERING_JOB_COMPLETED":"videoRenderingJobCompleted",
	"VIDEO_RENDERING_JOB_FAILED":"videoRenderingJobFailed",
	"CAPSULE_VIDEO_PROCESSING_COMPLETED":"capsuleVideoProcessingCompleted",
	"CAPSULE_VIDEO_PROCESSING_FAILED":"capsuleVideoProcessingFailed",
	"AI_EDIT_SUGGESTIONS_REQUEST_COMPLETED":"aiEditSuggestionsRequestCompleted",
	"AI_EDIT_SUGGESTIONS_REQUEST_FAILED":"aiEditSuggestionsRequestFailed",
};

const TREBBLE_JOB_STATUS = {
  INITIALIZED: "INITIALIZED",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  IN_PROGRESS: "IN_PROGRESS",
};

const ID_GROUP_DELIMITER_FOR_LLM_MEDIA_TRANSCRIPTION = "|";

const TranscriptionDeletableType = Object.freeze({
  FILLER_WORDS: 'FILLER_WORDS',
  FILLER_PHRASES: 'FILLER_PHRASES',
  FALSE_STARTS_SELF_CORRECTIONS: 'FALSE_STARTS_SELF_CORRECTIONS',
  REPETITIONS: 'REPETITIONS',
  RAMBLINGS: 'RAMBLINGS',
  OFF_TOPIC_TANGENTS: 'OFF_TOPIC_TANGENTS',
  NON_VERBAL_UTTERANCES: 'NON_VERBAL_UTTERANCES',
  REDUNDANT_AFFIRMATIONS: 'REDUNDANT_AFFIRMATIONS',
  POLITE_INTERRUPTIONS_BACKCHANNELING: 'POLITE_INTERRUPTIONS_BACKCHANNELING',
  TECHNICAL_ISSUES_ACKNOWLEDGMENTS: 'TECHNICAL_ISSUES_ACKNOWLEDGMENTS',
  META_COMMENTS_RECORDING_PROCESS: 'META_COMMENTS_RECORDING_PROCESS',
  SIDE_CONVERSATIONS_OFF_MIC_COMMENTS: 'SIDE_CONVERSATIONS_OFF_MIC_COMMENTS',
  STUTTERS_SPEECH_DISFLUENCIES: 'STUTTERS_SPEECH_DISFLUENCIES',
  EXPLICIT_CONTENT_PROFANITY: 'EXPLICIT_CONTENT_PROFANITY',
  AUDIENCE_INTERACTIONS_NOT_RELEVANT: 'AUDIENCE_INTERACTIONS_NOT_RELEVANT',
  REPEATED_CONTENT_TECHNICAL_ISSUES: 'REPEATED_CONTENT_TECHNICAL_ISSUES',
  LONG_WINDED_EXPLANATIONS: 'LONG_WINDED_EXPLANATIONS',
  SELF_PROMOTIONAL_CONTENT: 'SELF_PROMOTIONAL_CONTENT',
  TIME_CONSTRAINTS_SCHEDULING: 'TIME_CONSTRAINTS_SCHEDULING',
  OFFENSIVE_INAPPROPRIATE_JOKES: 'OFFENSIVE_INAPPROPRIATE_JOKES',
  REDUNDANT_UNNECESSARY_QUESTIONS: 'REDUNDANT_UNNECESSARY_QUESTIONS',
  //Consolidated types
  VERBAL_FILLERS_AND_DISFLUENCIES: "VERBAL_FILLERS_AND_DISFLUENCIES",
  VERBAL_FILLERS_AND_DISFLUENCIES_AND_REPARANDUM: "VERBAL_FILLERS_AND_DISFLUENCIES_AND_REPARANDUM",
  REDUNDANCIES_AND_REPETITIONS: "REDUNDANCIES_AND_REPETITIONS",
  OFF_TOPIC_AND_UNNECESSARY_CONTENT: "OFF_TOPIC_AND_UNNECESSARY_CONTENT",
  NON_VERBAL_AND_BACKCHANNELING: "NON_VERBAL_AND_BACKCHANNELING",
  TECHNICAL_AND_PROCEDURAL_COMMENTS: "TECHNICAL_AND_PROCEDURAL_COMMENTS",
  IRRELEVANT_INTERACTIONS: "IRRELEVANT_INTERACTIONS",
  INAPPROPRIATE_CONTENT: "INAPPROPRIATE_CONTENT",

  SPEECH_DISFLUENCIES:"SPEECH_DISFLUENCIES",
  REPETITIVE_CONTENT:"REPETITIVE_CONTENT",
  UNNECESSARY_CONTENT:"UNNECESSARY_CONTENT"
});

const SEARCH_RESULT_CONFIDENCE_LEVELS =  Object.freeze({
  LOW:"low",
  MEDIUM:"medium",
  HIGH:"high"
})

const MEDIA_TRANSCRIPTION_SEARCH_VALIDATION_LEVELS = Object.freeze({
  STRONG_ACCEPT:'Strong Accept',
  ACCEPT:'Accept', 
  CONSIDER_REVISING:'Consider Revising', 
  REJECT:'Reject'
});

const LLM_SEARCH_PROVIDER =  Object.freeze({
		OPENAI: 'openai',
		ANTHROPIC: 'anthropic',
		COHERE: 'cohere',
		BEDROCK: 'bedrock',
		GOOGLE_GENERATIVE_AI: 'google_generative_ai',
		AZURE_OPENAI :'azure_openai',
		DEEPSEEK: 'deepseek',
		DEEPSEEK_R1_AZURE: 'deepseek_r1_azure',
		DEEPSEEK_V3_AZURE: 'deepseek_v3_azure'
	});


const TREBBLE_NAME_REGEX_VALIDATION = /^[a-zA-Zà-ÿ0-9 &'-.]*[a-zA-Zà-ÿ0-9][a-zA-Zà-ÿ0-9 &'-.]*$/;
const USERNAME_REGEX_VALIDATION = /^[a-z0-9_.-]{3,30}$/;
const EMAIL_REGEX_VALIDATION =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PASSWORD_REGEX_VALIDATION = /^.{6,20}$/;
const MAX_NUMBER_OF_CHAR_FOR_USERNAME = 30;
const MIN_NUMBER_OF_CHAR_FOR_USERNAME = 3;

const PLAYER_HISTORY_ACTION_CONTEXT_ENVIRONMENT_TYPES = {
  WEB: "WEB",
  MOBILE: "MOBILE",
  ALEXA: "ALEXA",
  AMAZON_FLASH_BRIEFING: "AMAZON_FLASH_BRIEFING",
  GOOGLE_NEWS_FEED: "GOOGLE_NEWS_FEED",
  SAMSUNG_BIXBY: "SAMSUNG_BIXBY",
  APPLE_SIRI: "APPLE_SIRI",
  PODCAST: "PODCAST",
  GOOGLE_ASSISTANT: "GOOGLE_ASSISTANT",
  EMAIL_NEWSLETTER: "EMAIL_NEWSLETTER",
};

const CAPSULE_STATUS = {
  ALL: "ALL",
  ON_AIR: "ON_AIR",
  DRAFTS: "DRAFTS",
  SCHEDULED: "SCHEDULED" /*,"RECEIVED":"RECEIVED"*/,
};

const RADIO_VISIBILITY = {
  PUBLIC: "PUBLIC",
  /*"PRIVATE":"PRIVATE",*/ UNLISTED: "UNLISTED",
};

const MAX_DURATION_OF_NON_RETAINED_DELETED_SEQUENCER_NODE_IN_MILLISEC = 1 * 60 * 1000 //1min
const DEFAULT_LANGUAGE_CODE = "en-US";
const GOOGLE_CLOUD_LANGUAGES_INFO_ARRAY = [
  {
    languageCode: "af-ZA",
    languageLocalizedName: "Afrikaans (Suid-Afrika)",
    languageEnglishName: "Afrikaans (South Africa)",
  },
  {
    languageCode: "am-ET",
    languageLocalizedName: "አማርኛ (ኢትዮጵያ)",
    languageEnglishName: "Amharic (Ethiopia)",
  },
  {
    languageCode: "hy-AM",
    languageLocalizedName: "Հայ (Հայաստան)",
    languageEnglishName: "Armenian (Armenia)",
  },
  {
    languageCode: "az-AZ",
    languageLocalizedName: "Azərbaycan (Azərbaycan)",
    languageEnglishName: "Azerbaijani (Azerbaijan)",
  },
  {
    languageCode: "id-ID",
    languageLocalizedName: "Bahasa Indonesia (Indonesia)",
    languageEnglishName: "Indonesian (Indonesia)",
  },
  {
    languageCode: "ms-MY",
    languageLocalizedName: "Bahasa Melayu (Malaysia)",
    languageEnglishName: "Malay (Malaysia)",
  },
  {
    languageCode: "bn-BD",
    languageLocalizedName: "বাংলা (বাংলাদেশ)",
    languageEnglishName: "Bengali (Bangladesh)",
  },
  {
    languageCode: "bn-IN",
    languageLocalizedName: "বাংলা (ভারত)",
    languageEnglishName: "Bengali (India)",
  },
  {
    languageCode: "ca-ES",
    languageLocalizedName: "Català (Espanya)",
    languageEnglishName: "Catalan (Spain)",
  },
  {
    languageCode: "cs-CZ",
    languageLocalizedName: "Čeština (Česká republika)",
    languageEnglishName: "Czech (Czech Republic)",
  },
  {
    languageCode: "da-DK",
    languageLocalizedName: "Dansk (Danmark)",
    languageEnglishName: "Danish (Denmark)",
  },
  {
    languageCode: "de-DE",
    languageLocalizedName: "Deutsch (Deutschland)",
    languageEnglishName: "German (Germany)",
  },
  {
    languageCode: "en-AU",
    languageLocalizedName: "English (Australia)",
    languageEnglishName: "English (Australia)",
  },
  {
    languageCode: "en-CA",
    languageLocalizedName: "English (Canada)",
    languageEnglishName: "English (Canada)",
  },
  {
    languageCode: "en-GH",
    languageLocalizedName: "English (Ghana)",
    languageEnglishName: "English (Ghana)",
  },
  {
    languageCode: "en-GB",
    languageLocalizedName: "English (Great Britain)",
    languageEnglishName: "English (United Kingdom)",
  },
  {
    languageCode: "en-IN",
    languageLocalizedName: "English (India)",
    languageEnglishName: "English (India)",
  },
  {
    languageCode: "en-IE",
    languageLocalizedName: "English (Ireland)",
    languageEnglishName: "English (Ireland)",
  },
  {
    languageCode: "en-KE",
    languageLocalizedName: "English (Kenya)",
    languageEnglishName: "English (Kenya)",
  },
  {
    languageCode: "en-NZ",
    languageLocalizedName: "English (New Zealand)",
    languageEnglishName: "English (New Zealand)",
  },
  {
    languageCode: "en-NG",
    languageLocalizedName: "English (Nigeria)",
    languageEnglishName: "English (Nigeria)",
  },
  {
    languageCode: "en-PH",
    languageLocalizedName: "English (Philippines)",
    languageEnglishName: "English (Philippines)",
  },
  {
    languageCode: "en-ZA",
    languageLocalizedName: "English (South Africa)",
    languageEnglishName: "English (South Africa)",
  },
  {
    languageCode: "en-TZ",
    languageLocalizedName: "English (Tanzania)",
    languageEnglishName: "English (Tanzania)",
  },
  {
    languageCode: "en-US",
    languageLocalizedName: "English (United States)",
    languageEnglishName: "English (United States)",
  },
  {
    languageCode: "es-AR",
    languageLocalizedName: "Español (Argentina)",
    languageEnglishName: "Spanish (Argentina)",
  },
  {
    languageCode: "es-BO",
    languageLocalizedName: "Español (Bolivia)",
    languageEnglishName: "Spanish (Bolivia)",
  },
  {
    languageCode: "es-CL",
    languageLocalizedName: "Español (Chile)",
    languageEnglishName: "Spanish (Chile)",
  },
  {
    languageCode: "es-CO",
    languageLocalizedName: "Español (Colombia)",
    languageEnglishName: "Spanish (Colombia)",
  },
  {
    languageCode: "es-CR",
    languageLocalizedName: "Español (Costa Rica)",
    languageEnglishName: "Spanish (Costa Rica)",
  },
  {
    languageCode: "es-EC",
    languageLocalizedName: "Español (Ecuador)",
    languageEnglishName: "Spanish (Ecuador)",
  },
  {
    languageCode: "es-SV",
    languageLocalizedName: "Español (El Salvador)",
    languageEnglishName: "Spanish (El Salvador)",
  },
  {
    languageCode: "es-ES",
    languageLocalizedName: "Español (España)",
    languageEnglishName: "Spanish (Spain)",
  },
  {
    languageCode: "es-US",
    languageLocalizedName: "Español (Estados Unidos)",
    languageEnglishName: "Spanish (United States)",
  },
  {
    languageCode: "es-GT",
    languageLocalizedName: "Español (Guatemala)",
    languageEnglishName: "Spanish (Guatemala)",
  },
  {
    languageCode: "es-HN",
    languageLocalizedName: "Español (Honduras)",
    languageEnglishName: "Spanish (Honduras)",
  },
  {
    languageCode: "es-MX",
    languageLocalizedName: "Español (México)",
    languageEnglishName: "Spanish (Mexico)",
  },
  {
    languageCode: "es-NI",
    languageLocalizedName: "Español (Nicaragua)",
    languageEnglishName: "Spanish (Nicaragua)",
  },
  {
    languageCode: "es-PA",
    languageLocalizedName: "Español (Panamá)",
    languageEnglishName: "Spanish (Panama)",
  },
  {
    languageCode: "es-PY",
    languageLocalizedName: "Español (Paraguay)",
    languageEnglishName: "Spanish (Paraguay)",
  },
  {
    languageCode: "es-PE",
    languageLocalizedName: "Español (Perú)",
    languageEnglishName: "Spanish (Peru)",
  },
  {
    languageCode: "es-PR",
    languageLocalizedName: "Español (Puerto Rico)",
    languageEnglishName: "Spanish (Puerto Rico)",
  },
  {
    languageCode: "es-DO",
    languageLocalizedName: "Español (República Dominicana)",
    languageEnglishName: "Spanish (Dominican Republic)",
  },
  {
    languageCode: "es-UY",
    languageLocalizedName: "Español (Uruguay)",
    languageEnglishName: "Spanish (Uruguay)",
  },
  {
    languageCode: "es-VE",
    languageLocalizedName: "Español (Venezuela)",
    languageEnglishName: "Spanish (Venezuela)",
  },
  {
    languageCode: "eu-ES",
    languageLocalizedName: "Euskara (Espainia)",
    languageEnglishName: "Basque (Spain)",
  },
  {
    languageCode: "fil-PH",
    languageLocalizedName: "Filipino (Pilipinas)",
    languageEnglishName: "Filipino (Philippines)",
  },
  {
    languageCode: "fr-CA",
    languageLocalizedName: "Français (Canada)",
    languageEnglishName: "French (Canada)",
  },
  {
    languageCode: "fr-FR",
    languageLocalizedName: "Français (France)",
    languageEnglishName: "French (France)",
  },
  {
    languageCode: "gl-ES",
    languageLocalizedName: "Galego (España)",
    languageEnglishName: "Galician (Spain)",
  },
  {
    languageCode: "ka-GE",
    languageLocalizedName: "ქართული (საქართველო)",
    languageEnglishName: "Georgian (Georgia)",
  },
  {
    languageCode: "gu-IN",
    languageLocalizedName: "ગુજરાતી (ભારત)",
    languageEnglishName: "Gujarati (India)",
  },
  {
    languageCode: "hr-HR",
    languageLocalizedName: "Hrvatski (Hrvatska)",
    languageEnglishName: "Croatian (Croatia)",
  },
  {
    languageCode: "zu-ZA",
    languageLocalizedName: "IsiZulu (Ningizimu Afrika)",
    languageEnglishName: "Zulu (South Africa)",
  },
  {
    languageCode: "is-IS",
    languageLocalizedName: "Íslenska (Ísland)",
    languageEnglishName: "Icelandic (Iceland)",
  },
  {
    languageCode: "it-IT",
    languageLocalizedName: "Italiano (Italia)",
    languageEnglishName: "Italian (Italy)",
  },
  {
    languageCode: "jv-ID",
    languageLocalizedName: "Jawa (Indonesia)",
    languageEnglishName: "Javanese (Indonesia)",
  },
  {
    languageCode: "kn-IN",
    languageLocalizedName: "ಕನ್ನಡ (ಭಾರತ)",
    languageEnglishName: "Kannada (India)",
  },
  {
    languageCode: "km-KH",
    languageLocalizedName: "ភាសាខ្មែរ (កម្ពុជា)",
    languageEnglishName: "Khmer (Cambodia)",
  },
  {
    languageCode: "lo-LA",
    languageLocalizedName: "ລາວ (ລາວ)",
    languageEnglishName: "Lao (Laos)",
  },
  {
    languageCode: "lv-LV",
    languageLocalizedName: "Latviešu (latviešu)",
    languageEnglishName: "Latvian (Latvia)",
  },
  {
    languageCode: "lt-LT",
    languageLocalizedName: "Lietuvių (Lietuva)",
    languageEnglishName: "Lithuanian (Lithuania)",
  },
  {
    languageCode: "hu-HU",
    languageLocalizedName: "Magyar (Magyarország)",
    languageEnglishName: "Hungarian (Hungary)",
  },
  {
    languageCode: "ml-IN",
    languageLocalizedName: "മലയാളം (ഇന്ത്യ)",
    languageEnglishName: "Malayalam (India)",
  },
  {
    languageCode: "mr-IN",
    languageLocalizedName: "मराठी (भारत)",
    languageEnglishName: "Marathi (India)",
  },
  {
    languageCode: "nl-NL",
    languageLocalizedName: "Nederlands (Nederland)",
    languageEnglishName: "Dutch (Netherlands)",
  },
  {
    languageCode: "ne-NP",
    languageLocalizedName: "नेपाली (नेपाल)",
    languageEnglishName: "Nepali (Nepal)",
  },
  {
    languageCode: "nb-NO",
    languageLocalizedName: "Norsk bokmål (Norge)",
    languageEnglishName: "Norwegian Bokmål (Norway)",
  },
  {
    languageCode: "pl-PL",
    languageLocalizedName: "Polski (Polska)",
    languageEnglishName: "Polish (Poland)",
  },
  {
    languageCode: "pt-BR",
    languageLocalizedName: "Português (Brasil)",
    languageEnglishName: "Portuguese (Brazil)",
  },
  {
    languageCode: "pt-PT",
    languageLocalizedName: "Português (Portugal)",
    languageEnglishName: "Portuguese (Portugal)",
  },
  {
    languageCode: "ro-RO",
    languageLocalizedName: "Română (România)",
    languageEnglishName: "Romanian (Romania)",
  },
  {
    languageCode: "si-LK",
    languageLocalizedName: "සිංහල (ශ්රී ලංකාව)",
    languageEnglishName: "Sinhala (Sri Lanka)",
  },
  {
    languageCode: "sk-SK",
    languageLocalizedName: "Slovenčina (Slovensko)",
    languageEnglishName: "Slovak (Slovakia)",
  },
  {
    languageCode: "sl-SI",
    languageLocalizedName: "Slovenščina (Slovenija)",
    languageEnglishName: "Slovenian (Slovenia)",
  },
  {
    languageCode: "su-ID",
    languageLocalizedName: "Urang (Indonesia)",
    languageEnglishName: "Sundanese (Indonesia)",
  },
  {
    languageCode: "sw-TZ",
    languageLocalizedName: "Swahili (Tanzania)",
    languageEnglishName: "Swahili (Tanzania)",
  },
  {
    languageCode: "sw-KE",
    languageLocalizedName: "Swahili (Kenya)",
    languageEnglishName: "Swahili (Kenya)",
  },
  {
    languageCode: "fi-FI",
    languageLocalizedName: "Suomi (Suomi)",
    languageEnglishName: "Finnish (Finland)",
  },
  {
    languageCode: "sv-SE",
    languageLocalizedName: "Svenska (Sverige)",
    languageEnglishName: "Swedish (Sweden)",
  },
  {
    languageCode: "ta-IN",
    languageLocalizedName: "தமிழ் (இந்தியா)",
    languageEnglishName: "Tamil (India)",
  },
  {
    languageCode: "ta-SG",
    languageLocalizedName: "தமிழ் (சிங்கப்பூர்)",
    languageEnglishName: "Tamil (Singapore)",
  },
  {
    languageCode: "ta-LK",
    languageLocalizedName: "தமிழ் (இலங்கை)",
    languageEnglishName: "Tamil (Sri Lanka)",
  },
  {
    languageCode: "ta-MY",
    languageLocalizedName: "தமிழ் (மலேசியா)",
    languageEnglishName: "Tamil (Malaysia)",
  },
  {
    languageCode: "te-IN",
    languageLocalizedName: "తెలుగు (భారతదేశం)",
    languageEnglishName: "Telugu (India)",
  },
  {
    languageCode: "vi-VN",
    languageLocalizedName: "Tiếng Việt (Việt Nam)",
    languageEnglishName: "Vietnamese (Vietnam)",
  },
  {
    languageCode: "tr-TR",
    languageLocalizedName: "Türkçe (Türkiye)",
    languageEnglishName: "Turkish (Turkey)",
  },
  {
    languageCode: "ur-PK",
    languageLocalizedName: '<bdo dir="rtl">اردو (پاکستان)</bdo>',
    languageEnglishName: "Urdu (Pakistan)",
  },
  {
    languageCode: "ur-IN",
    languageLocalizedName: '<bdo dir="rtl">اردو (بھارت)</bdo>',
    languageEnglishName: "Urdu (India)",
  },
  {
    languageCode: "el-GR",
    languageLocalizedName: "Ελληνικά (Ελλάδα)",
    languageEnglishName: "Greek (Greece)",
  },
  {
    languageCode: "bg-BG",
    languageLocalizedName: "Български (България)",
    languageEnglishName: "Bulgarian (Bulgaria)",
  },
  {
    languageCode: "ru-RU",
    languageLocalizedName: "Русский (Россия)",
    languageEnglishName: "Russian (Russia)",
  },
  {
    languageCode: "sr-RS",
    languageLocalizedName: "Српски (Србија)",
    languageEnglishName: "Serbian (Serbia)",
  },
  {
    languageCode: "uk-UA",
    languageLocalizedName: "Українська (Україна)",
    languageEnglishName: "Ukrainian (Ukraine)",
  },
  {
    languageCode: "he-IL",
    languageLocalizedName: '<bdo dir="rtl">עברית (ישראל)</bdo>',
    languageEnglishName: "Hebrew (Israel)",
  },
  {
    languageCode: "ar-IL",
    languageLocalizedName: '<bdo dir="rtl">العربية (إسرائيل)</bdo>',
    languageEnglishName: "Arabic (Israel)",
  },
  {
    languageCode: "ar-JO",
    languageLocalizedName: '<bdo dir="rtl">العربية (الأردن)</bdo>',
    languageEnglishName: "Arabic (Jordan)",
  },
  {
    languageCode: "ar-AE",
    languageLocalizedName: '<bdo dir="rtl">العربية (الإمارات)</bdo>',
    languageEnglishName: "Arabic (United Arab Emirates)",
  },
  {
    languageCode: "ar-BH",
    languageLocalizedName: '<bdo dir="rtl">العربية (البحرين)</bdo>',
    languageEnglishName: "Arabic (Bahrain)",
  },
  {
    languageCode: "ar-DZ",
    languageLocalizedName: '<bdo dir="rtl">العربية (الجزائر)</bdo>',
    languageEnglishName: "Arabic (Algeria)",
  },
  {
    languageCode: "ar-SA",
    languageLocalizedName: '<bdo dir="rtl">العربية (السعودية)</bdo>',
    languageEnglishName: "Arabic (Saudi Arabia)",
  },
  {
    languageCode: "ar-IQ",
    languageLocalizedName: '<bdo dir="rtl">العربية (العراق)</bdo>',
    languageEnglishName: "Arabic (Iraq)",
  },
  {
    languageCode: "ar-KW",
    languageLocalizedName: '<bdo dir="rtl">العربية (الكويت)</bdo>',
    languageEnglishName: "Arabic (Kuwait)",
  },
  {
    languageCode: "ar-MA",
    languageLocalizedName: '<bdo dir="rtl">العربية (المغرب)</bdo>',
    languageEnglishName: "Arabic (Morocco)",
  },
  {
    languageCode: "ar-TN",
    languageLocalizedName: '<bdo dir="rtl">العربية (تونس)</bdo>',
    languageEnglishName: "Arabic (Tunisia)",
  },
  {
    languageCode: "ar-OM",
    languageLocalizedName: '<bdo dir="rtl">العربية (عُمان)</bdo>',
    languageEnglishName: "Arabic (Oman)",
  },
  {
    languageCode: "ar-PS",
    languageLocalizedName: '<bdo dir="rtl">العربية (فلسطين)</bdo>',
    languageEnglishName: "Arabic (State of Palestine)",
  },
  {
    languageCode: "ar-QA",
    languageLocalizedName: '<bdo dir="rtl">العربية (قطر)</bdo>',
    languageEnglishName: "Arabic (Qatar)",
  },
  {
    languageCode: "ar-LB",
    languageLocalizedName: '<bdo dir="rtl">العربية (لبنان)</bdo>',
    languageEnglishName: "Arabic (Lebanon)",
  },
  {
    languageCode: "ar-EG",
    languageLocalizedName: '<bdo dir="rtl">العربية (مصر)</bdo>',
    languageEnglishName: "Arabic (Egypt)",
  },
  {
    languageCode: "fa-IR",
    languageLocalizedName: '<bdo dir="rtl">فارسی (ایران)</bdo>',
    languageEnglishName: "Persian (Iran)",
  },
  {
    languageCode: "hi-IN",
    languageLocalizedName: "हिन्दी (भारत)",
    languageEnglishName: "Hindi (India)",
  },
  {
    languageCode: "th-TH",
    languageLocalizedName: "ไทย (ประเทศไทย)",
    languageEnglishName: "Thai (Thailand)",
  },
  {
    languageCode: "ko-KR",
    languageLocalizedName: "한국어 (대한민국)",
    languageEnglishName: "Korean (South Korea)",
  },
  {
    languageCode: "cmn-Hant-TW",
    languageLocalizedName: "國語 (台灣)",
    languageEnglishName: "Chinese, Mandarin (Traditional, Taiwan)",
  },
  {
    languageCode: "yue-Hant-HK",
    languageLocalizedName: "廣東話 (香港)",
    languageEnglishName: "Chinese, Cantonese (Traditional, Hong Kong)",
  },
  {
    languageCode: "ja-JP",
    languageLocalizedName: "日本語（日本）",
    languageEnglishName: "Japanese (Japan)",
  },
  {
    languageCode: "cmn-Hans-HK",
    languageLocalizedName: "普通話 (香港)",
    languageEnglishName: "Chinese, Mandarin (Simplified, Hong Kong)",
  },
  {
    languageCode: "cmn-Hans-CN",
    languageLocalizedName: "普通话 (中国大陆)",
    languageEnglishName: "Chinese, Mandarin (Simplified, China)",
  },
];
const SUPPORTED_TRANSCRIBE_LANGUAGE_CODE_TO_LANGUAGE_LABEL = {
  "af-ZA": "Afrikaans",
  "ar-AE": "Arabic, Gulf",
  "ar-SA": "Arabic, Modern Standard",
  "zh-CN": "Chinese, Simplified",
  "zh-TW": "Chinese, Traditional",
  "da-DK": "Danish",
  "nl-NL": "Dutch",
  "en-AU": "English, Australian",
  "en-GB": "English, British",
  "en-IN": "English, Indian",
  "en-IE": "English, Irish",
  "en-NZ": "English, New Zealand",
  "en-AB": "English, Scottish",
  "en-ZA": "English, South African",
  "en-US": "English, US",
  "en-WL": "English, Welsh",
  "fr-FR": "French",
  "fr-CA": "French, Canadian",
  "fa-IR": "Farsi",
  "de-DE": "German",
  "de-CH": "German, Swiss",
  "he-IL": "Hebrew",
  "hi-IN": "Hindi, Indian",
  "id-ID": "Indonesian",
  "it-IT": "Italian",
  "ja-JP": "Japanese",
  "ko-KR": "Korean",
  "ms-MY": "Malay",
  no: "Norwegian",
  "pl-PL": "Polish",
  "pt-PT": "Portuguese",
  "pt-BR": "Portuguese, Brazilian",
  "ru-RU": "Russian",
  "es-ES": "Spanish",
  "es-US": "Spanish, US",
  "sv-SE": "Swedish",
  "ta-IN": "Tamil",
  "te-IN": "Telugu",
  "th-TH": "Thai",
  "tr-TR": "Turkish",
  "uk-UK": "Ukrainian",

  hy: "Armenian",
  az: "Azerbaijani",
  be: "Belarusian",
  bs: "Bosnian",
  bg: "Bulgarian",
  ca: "Catalan",
  hr: "Croatian",
  cs: "Czech",
  et: "Estonian",
  fi: "Finnish",
  gl: "Galician",
  el: "Greek",
  hu: "Hungarian",
  is: "Icelandic",
  kn: "Kannada",
  kk: "Kazakh",
  lv: "Latvian",
  lt: "Lithuanian",
  mk: "Macedonian",
  mr: "Marathi",
  mi: "Maori",
  ne: "Nepali",
  fa: "Persian",
  ro: "Romanian",
  sr: "Serbian",
  sk: "Slovak",
  sl: "Slovenian",
  sw: "Swahili",
  tl: "Tagalog",
  ur: "Urdu",
  vi: "Vietnamese",

  la: "Latin",
  ml: "Malayalam",
  cy: "Welsh",
  bn: "Bengali",
  br: "Breton",
  eu: "Basque",
  mn: "Mongolian",
  sq: "Albanian",
  pa: "Punjabi",
  si: "Sinhala",
  km: "Khmer",
  sn: "Shona",
  yo: "Yoruba",
  so: "Somali",
  oc: "Occitan",
  ka: "Georgian",
  tg: "Tajik",
  sd: "Sindhi",
  gu: "Gujarati",
  am: "Amharic",
  yi: "Yiddish",
  lo: "Lao",
  uz: "Uzbek",
  fo: "Faroese",
  ht: "Haitian Creole",
  ps: "Pashto",
  tk: "Turkmen",
  nn: "Norwegian Nynorsk",
  mt: "Maltese",
  sa: "Sanskrit",
  lb: "Luxembourgish",
  my: "Burmese",
  bo: "Tibetan",
  mg: "Malagasy",
  as: "Assamese",
  tt: "Tatar",
  haw: "Hawaiian",
  ln: "Lingala",
  ha: "Hausa",
  ba: "Bashkir",
  jw: "Javanese",
  su: "Sundanese"
};

const DEEPGRAM_SUPPORTED_AWS_LANGUAGE_CODE_TO_DEEPGRAM_LANGUAGE_CODE = {
  //"af-ZA":"Afrikaans",
  //"ar-AE":"Arabic, Gulf",
  //"ar-SA":"Arabic, Modern Standard",
  "zh-CN": "zh-CN", //"Chinese, Simplified",
  "zh-TW": "zh-TW", //"Chinese, Traditional",
  "da-DK": "da",
  "nl-NL": "nl", //"Dutch",
  "en-AU": "en-AU", //"English, Australian",
  "en-GB": "en-GB", //"English, British",
  "en-IN": "en-IN", //"English, Indian",
  //"en-IE":"English, Irish",
  "en-NZ": "en-NZ", //"English, New Zealand",
  //"en-AB":"English, Scottish",
  //"en-ZA":"English, South African",
  "en-US": "en-US", //"English, US",
  //"en-WL":"English, Welsh",
  "fr-FR": "fr", //"French",
  "fr-CA": "fr-CA", //"French, Canadian",
  //"fa-IR":"Farsi",
  "de-DE": "de", //"German",
  //"de-CH":"German, Swiss",
  //"he-IL":"Hebrew",
  "hi-IN": "hi", //"Hindi, Indian",
  "id-ID": "id", //"Indonesian",
  "it-IT": "it", //"Italian",
  "ja-JP": "ja", //"Japanese",
  "ko-KR": "ko", //"Korean",
  //"ms-MY":"Malay",
  no: "no",
  "pl-PL": "pl",
  "pt-PT": "pt-PT", //"Portuguese",
  "pt-BR": "pt-BR", //"Portuguese, Brazilian",
  "ru-RU": "ru", //"Russian",
  "es-ES": "es", //"Spanish",
  "es-US": "es", //"Spanish, US",
  "sv-SE": "sv",
  "ta-IN": "ta",
  //"te-IN":"Telugu",
  //"th-TH":"Thai",
  "tr-TR": "tr", //"Turkish",
  "uk-UA": "uk",
};

/*const DEEPGRAM_SUPPORTED_LANGUAGE_CODE_TO_DEEPGRAM_LANGUAGE_LABEL = {
	//"af-ZA":"Afrikaans",
	//"ar-AE":"Arabic, Gulf",
	//"ar-SA":"Arabic, Modern Standard",
	"zh-CN": "Chinese, Simplified",
	"zh-TW": "Chinese, Traditional",
	"da":"Danish",
	"nl": "Dutch",
	"en-AU": "English, Australian",
	"en-GB": "English, British",
	"en-IN": "English, Indian",
	//"en-IE":"English, Irish",
	"en-NZ": "English, New Zealand",
	//"en-AB":"English, Scottish",
	//"en-ZA":"English, South African",
	"en-US": "English, US",
	//"en-WL":"English, Welsh",
	"fr": "French",
	"fr-CA": "French, Canadian",
	//"fa-IR":"Farsi",
	"de": "German",
	//"de-CH":"German, Swiss",
	//"he-IL":"Hebrew",
	"hi": "Hindi, Indian",
	"hi-Latn": "Hindi, Latin Script",
	"id": "Indonesian",
	"it": "Italian",
	"ja": "Japanese",
	"ko": "Korean",
	//"ms-MY":"Malay",
	"no": "Norwegian",
	"pl": "Polish",
	"pt": "Portuguese",
	"pt-PT": "Portuguese, Portugal",
	"pt-BR": "Portuguese, Brazilian",
	"ru": "Russian",
	"es": "Spanish",
	"ta-IN":"Tamil",
	//"te-IN":"Telugu",
	//"th-TH":"Thai",
	"sv":"Swedish",
	"tr":"Turkish",
	"uk":"Ukrainian",
}*/
const GOOGLE_CLOUD_LANGUAGE_COUDE_TO_GOOGLE_CLOUD_LANGUAGES_INFO_MAP = (function () {
  const mapToReturn = {};
  for (let i = 0; i < GOOGLE_CLOUD_LANGUAGES_INFO_ARRAY.length; i++) {
    const languageInfo = GOOGLE_CLOUD_LANGUAGES_INFO_ARRAY[i];
    mapToReturn[languageInfo.languageCode] = languageInfo;
  }
  return mapToReturn;
})();

const TREBBLE_CLOUNDINARY_URL_PREFIX = "https://res.cloudinary.com/trebble-fm/image/upload/";
const TREBBLE_MUSIC_CATEGORY_ID = "Music";
const TREBBLE_CAPSULE_CATEGORY_ID = "Capsule";
//const TREBBLE_CAPSULE_FEED_CATEGORY_ID = "CapsuleFeed";
const DEFAULT_TREBBLE_CATEGORY_ID = "Me";
const LOCAL_CATEGORY_ID = "Local";
const CAPSULE_CATEGORIES = [
  "Arts",
  "Astrology",
  "Weather",
  DEFAULT_TREBBLE_CATEGORY_ID,
  TREBBLE_MUSIC_CATEGORY_ID,
  "News",
  LOCAL_CATEGORY_ID,
  "Cooking",
  "Beauty & Fashion",
  "Comedy",
  "Entertainment",
  "Kids & Family",
  "Sex & Relationships",
  "Money & Investements",
  "Business",
  "Motivation & Inspiration",
  "Personal Growth",
  "Politics",
  "Health",
  "Lifestyle",
  "Sports",
  "Tips",
  "Religion & Spirituality",
  "Culture",
  "Education",
  "Game & Hobbies",
  "Science & Medicine",
  "Technology",
  "NonProfits & Activism",
];
CAPSULE_CATEGORIES.sort();
const POSSIBLE_JOURNEY_COST_IN_USD = [];

const MIN_COST_JOURNEY_IN_USD = RolloutHelper.getInstance().getFeatureVariable(
  RolloutHelper.FEATURES.JOURNEY_PURCHASE_CONFIG,
  RolloutHelper.FEATURES.JOURNEY_PURCHASE_CONFIG.variables.min_cost_of_journey_in_usd,
  9.99,
);
const COST_INCREMENT = RolloutHelper.getInstance().getFeatureVariable(
  RolloutHelper.FEATURES.JOURNEY_PURCHASE_CONFIG,
  RolloutHelper.FEATURES.JOURNEY_PURCHASE_CONFIG.variables.cost_increment_in_usd,
  5,
);
const MAX_COST_JOURNEY_IN_USD = RolloutHelper.getInstance().getFeatureVariable(
  RolloutHelper.FEATURES.JOURNEY_PURCHASE_CONFIG,
  RolloutHelper.FEATURES.JOURNEY_PURCHASE_CONFIG.variables.max_cost_of_journey_in_usd,
  199.99,
);
let min_cost_of_journey_added_to_list = MIN_COST_JOURNEY_IN_USD;

while (min_cost_of_journey_added_to_list <= MAX_COST_JOURNEY_IN_USD) {
  POSSIBLE_JOURNEY_COST_IN_USD.push(min_cost_of_journey_added_to_list);
  min_cost_of_journey_added_to_list = parseFloat((min_cost_of_journey_added_to_list + COST_INCREMENT).toFixed(2));
}
const DEFAULT_SELECTED_CAPSULE_CATEGORY_ID = "Me";
const USE_VIBRANT_JS = true;
const DEFAULT_NUMBER_OF_SONGS_REQUIRED_TO_PLAY_A_TREBBLE = 1;
const DUMMY_CUSTOM_TREBBLE_URL = "claimyoururl";
const MAKE_TOKEN_VALID_IF_TOKEN_EXIST = true;
//THE FOLLOWING FLAG COULD BE USE FOR PERFORMANCE REASONS
const DISABLE_IMAGE_BLUR_BACKGROUND = false;

//const SUPPORTED_VIDEO_FILE_EXTENSIONS = ["mp4", "mov"];

const EXPLICITELY_DISPLAYED_PAUSE_DURATION_IN_MILLI_SECONDS_IN_AUDIO_EDITOR = 250;

const AUDIO_TYPES = {
  SONG: "SONG",
  CAPSULE: "CAPSULE",
  JINGLE: "JINGLE",
  GREATER: "GREATER",
  INTRO: "INTRO",
  OUTRO: "OUTRO",
  EARCON: "EARCON",
  TREBBLE_GENERATED_SPEECH: "TREBBLE_GENERATED_SPEECH",
  OTHER: "OTHER",
};

const TREBBLE_AUDIO_TYPES = {
  CAPSULE: "Capsule",
  GREETER: "Greeter",
  INTRO: "Intro",
  OUTRO: "Outro",
  GENERATED_SPEECH: "GeneratedSpeech",
  EARCON: "EARCON",
  MUSIC: "Music",
  TREBBLE_DEFAULT_BACKGROUND_MUSIC: "TrebbleDefaultBackgroundMusic",
  USER_UPLOADED_BACKGROUND_MUSIC: "UserUploadedBackgroundMusic",
  BIG_BANK_SOUND: "bigBankSound",
  USER_UPLOADED_AUDIO_RECORDING: "UserUploadedAudioRecording",
  MY_INSTANT_SOUND: "myInstantSound",
  FREESOUND_SOUND: "freesoundSound",
  FREESOUND_FROM_CLEAR_VOICE: "freesoundFromClearVoice",
  EPIDEMIC_SOUND: "epidemicSound",
};

const DEVICE_TYPE = { ANDROID: "Android", IOS: "iOS", BROWSER: "browser" };

const getResizeImageUrl = function (imageUrl, width, height, albumArtImages) {
  if (USE_CLOUDINARY_FOR_IMAGE_RESIZING) {
    return getResizeImageUrlViaCloudinary(imageUrl, width, height, albumArtImages);
  } else {
    const imageFileName = "tempImg_" + new Date().getTime() + ".jpg";
    return imageUrl && imageUrl.indexOf(RESIZE_IMAGE_SERVER_URL) == -1
      ? RESIZE_IMAGE_SERVER_URL +
          "/resize/" +
          RESIZE_SERVER_HASH +
          "/" +
          imageFileName +
          "?url=" +
          encodeURIComponent(imageUrl) +
          "&size=" +
          width +
          "x" +
          height
      : imageUrl;
  }
};
const getResizeImageUrlViaCloudinary = function (imageUrl, width, height, albumArtImages) {
  if (imageUrl && imageUrl.indexOf(TREBBLE_CLOUNDINARY_URL_PREFIX) == 0) {
    //cloudinary trebble image
    let resize_url_query = "";
    if (height > 100) {
      if (height <= 300) {
        width = 300;
        height = 300;
      } else {
        if (height <= 640) {
          width = 640;
          height = 640;
        } else {
          //keep size as is
        }
      }
      resize_url_query = "c_fit,h_" + height + ",w_" + width + "/";
    } else {
      if (height <= 64) {
        width = 64;
        height = 64;
      } else {
        width = 100;
        height = 100;
      }
      resize_url_query = "c_fit,h_" + height + ",w_" + width + "/";
    }
    return imageUrl.replace(TREBBLE_CLOUNDINARY_URL_PREFIX, TREBBLE_CLOUNDINARY_URL_PREFIX + resize_url_query);
  } else {
    //not a cloudinary trebble image
    if (albumArtImages) {
      //const imageAlreadyAvailable = false;
      if (height <= 67) {
        if (albumArtImages["mini"]) {
          return albumArtImages["mini"].url;
        }
        if (albumArtImages["thumbnail"]) {
          return albumArtImages["thumbnail"].url;
        }
      }
      if (height <= 100) {
        if (albumArtImages["thumbnail"]) {
          return albumArtImages["thumbnail"].url;
        }
      }
      if (height <= 300) {
        if (albumArtImages["medium"]) {
          return albumArtImages["medium"].url;
        }
      }

      if (height <= 640) {
        if (albumArtImages["large"]) {
          return albumArtImages["large"].url;
        }
      }
    }
    //couldn't appropriate url so use default cloudinary fetching mechanism
    if (imageUrl && imageUrl.indexOf("-t500x500") != -1 && imageUrl.indexOf("i1.sndcdn.com") != -1) {
      // try to find appropriate url since url is similar to soundcloudurl
      if (height <= 67) {
        return imageUrl.replace("-t500x500", "-t67x67");
      }
      if (height <= 100) {
        return imageUrl.replace("-t500x500", "-large");
      }
      if (height <= 300) {
        return imageUrl.replace("-t500x500", "-t300x300");
      }
      return imageUrl;
    } else {
      return imageUrl && imageUrl.indexOf(RESIZE_IMAGE_SERVER_URL_VIA_CLOUDINARY) == -1
        ? RESIZE_IMAGE_SERVER_URL_VIA_CLOUDINARY +
            "/c_fit,h_" +
            height +
            ",w_" +
            width +
            "/" +
            encodeURIComponent(imageUrl)
        : imageUrl;
    }
  }
};

const getBlurImageUrlViaCloudinary = function (imageUrl) {
  return imageUrl && imageUrl.indexOf(RESIZE_IMAGE_SERVER_URL_VIA_CLOUDINARY) == -1
    ? RESIZE_IMAGE_SERVER_URL_VIA_CLOUDINARY +
        "/c_fit,e_blur:" +
        CLOUDINARY_BLUR_VALUE +
        "/" +
        encodeURIComponent(imageUrl)
    : imageUrl;
};

window.trebble.globalHelpers.getResizeImageUrl = getResizeImageUrl;
window.trebble.globalHelpers.getBlurImageUrl = getBlurImageUrlViaCloudinary;

const Utils = Backbone.Model.extend({
  initialize: function () {
    if ($.notify) {
      $.notify.addStyle("pushNotificationPreview", {
        html: "<div  data-notify-html='customTemplate'/>",
      });
      $.notifyLibraryInitialized = true;
    }
    this._setupAppVisilityFunctionOrchestrator();
  },

  is: function (type, obj) {
    const clas = Object.prototype.toString.call(obj).slice(8, -1);
    return obj !== undefined && obj !== null && clas === type;
  },

  _setupAppVisilityFunctionOrchestrator: function () {
    this._functionsToExecuteOnAppInForeground = [];
    this._trebbleAppIsInForeground = true;
    const whenAppInBackground = function () {
      this._trebbleAppIsInForeground = false;
    }.bind(this);
    const whenAppInForeground = function () {
      this._trebbleAppIsInForeground = true;
      if (this._functionsToExecuteOnAppInForeground && this._functionsToExecuteOnAppInForeground.length > 0) {
        for (let i = 0; i < this._functionsToExecuteOnAppInForeground.length; i++) {
          try {
            const functionToExecute = this._functionsToExecuteOnAppInForeground[i];
            if (typeof functionToExecute === "function") {
              functionToExecute();
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
      this._functionsToExecuteOnAppInForeground = [];
    }.bind(this);
    document.addEventListener("pause", whenAppInBackground, false);
    document.addEventListener("resume", whenAppInForeground, false);
  },

  executeFunctionWhenAppGoesInForeground: function (functionToExecute) {
    if (typeof functionToExecute === "function") {
      if (!this._functionsToExecuteOnAppInForeground) {
        this._functionsToExecuteOnAppInForeground = [];
      }
      this._functionsToExecuteOnAppInForeground.push(functionToExecute);
    }
  },

  getRandomNumber: function (lowerNumber, higherNumber) {
    return Math.floor(Math.random() * higherNumber) + lowerNumber;
  },

  getRadioIconFont: function () {
    return "<i class='pe-7s-signal'></i>";
  },

  getEraTagGroupId: function () {
    return ERA_TAG_GROUP_ID;
  },

  getGenreTagGroupId: function () {
    return GERNRE_TAG_GROUP_ID;
  },

  getSongLibraryFilterOptions: function () {
    return LIBRARY_FILTER_OPTIONS;
  },

  getSongLibrarySortOptions: function () {
    return LIRBARY_SORTING_OPTIONS;
  },

  getSkipTrackReasonsKeyValueObj: function () {
    return SKIP_TRACK_REASONS;
  },
  //make sure to call this method when the page has been enhanced by jquery mobile
  /*	initJqueryLazyLoadingPluginOnPage : function(page$el){
		  const scrollContainer$el = page$el.find(".infiniteListOuterContainer");
		  return this.initJqueryLazyLoadingPluginOnElem(scrollContainer$el);
	  },
  
	  initJqueryLazyLoadingPluginOnElem : function(scrollContainer$el, customEvent, imageContainer$el, imageSelector){
		  return $("img.lazy").lazyload({
			  effect : "fadeIn",
			  container: scrollContainer$el,
			  placeholder : "window.getResourceURLAccordingToLocationProtocol(/img/videoLoading.png)",
			  skip_invisible : true,
			  event : customEvent?customEvent:"scrollstop"
		  });
  },
  */
  initJqueryLazyLoadingPluginOnElemNew: function (imageContainer$el, imageSelector, scrollContainer$el, customEvent) {
    return imageContainer$el.find(imageSelector).lazyload({
      effect: "fadeIn",
      container: scrollContainer$el,
      placeholder: window.getResourceURLAccordingToLocationProtocol("/img/videoLoading.png"),
      skip_invisible: true,
      imageContainer$el: imageContainer$el,
      imageContainerSelector: imageSelector,
      removeLazyClassAfterLoading: true,
      event: customEvent ? customEvent : "scrollstop",
    });
  },
  isSafari: function () {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  },

  getAudioRecordedFileExtention: function () {
    let supportedMimeType = null;
    let extension = null;
    if (ALWAYS_USE_MEDIA_RECORDERJS) {
      return "wav";
    }
    if ("MediaRecorder" in window) {
      if (window.isIOSMobileDevice) {
        supportedMimeType = "audio/wav";
        extension = "wav";
      } else {
        if (MediaRecorder.isTypeSupported("audio/wav")) {
          supportedMimeType = "audio/wav";
          extension = "wav";
        } else {
          if (MediaRecorder.isTypeSupported("audio/webm")) {
            supportedMimeType = "audio/webm";
            extension = "webm";
          } else {
            if (MediaRecorder.isTypeSupported("audio/webm;codecs=opus")) {
              supportedMimeType = "audio/webm;codecs=opus";
              extension = "webm";
            } else {
              if (MediaRecorder.isTypeSupported("audio/ogg;codecs=opus")) {
                supportedMimeType = "audio/ogg;codecs=opus";
                extension = "ogg";
              }
            }
          }
        }
      }
    }
    if (!supportedMimeType) {
      supportedMimeType = "audio/wav";
      extension = "wav";
    }
    return extension;
  },

  isTokenValid: function () {
    const tokenInfo = LocalStorageHelper.getInstance().retrieveAuthentificationToken();
    if (tokenInfo && tokenInfo.token) {
      if (!MAKE_TOKEN_VALID_IF_TOKEN_EXIST && new Date().getTime() >= tokenInfo.tokenExpirationTimestamp) {
        LocalStorageHelper.getInstance().logoutUserAndClearInfo();
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  },

  getJourneyNameMaxLength: function () {
    return JOURNEY_NAME_MAX_NAME_LENGTH;
  },
  getJourneyGoalMaxLength: function () {
    return JOURNEY_GOAL_MAX_NAME_LENGTH;
  },

  getJourneyDescriptionMaxLength: function () {
    return JOURNEY_DESC_MAX_NAME_LENGTH;
  },
  isBackgroundBlurImageDisabled: function () {
    return DISABLE_IMAGE_BLUR_BACKGROUND;
  },

  replaceAll: function (findStr, replaceStr, str) {
    return str.replace(new RegExp(findStr.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&"), "g"), replaceStr);
  },

  roundNumberTo: function (numberToRound, numberOfDecimals) {
    return (
      Math.round((numberToRound + Number.EPSILON) * Math.pow(10, numberOfDecimals)) / Math.pow(10, numberOfDecimals)
    );
  },

  getDevicePlatform: function () {
    if (window.waitForCordovaToLoad) {
      return window.isIOSMobileDevice ? DEVICE_TYPE.IOS : DEVICE_TYPE.ANDROID;
    } else {
      return DEVICE_TYPE.BROWSER;
    }
  },

  applyPrecision: function (n, precision) {
    return Number.parseFloat(n.toFixed(precision));
  },

  isTrebbleAppInForeground: function () {
    return this._trebbleAppIsInForeground;
  },

  setTimeoutAndShowAsSoonAsAppGoesInForeground: function (functionToCall, timer) {
    setTimeout(
      function () {
        if (this.isTrebbleAppInForeground()) {
          functionToCall();
        } else {
          this.executeFunctionWhenAppGoesInForeground(functionToCall);
        }
      }.bind(this),
      timer,
    );
  },

  getTrebbleAudioType: function () {
    return TREBBLE_AUDIO_TYPES;
  },

  getCapsuleStatuses: function () {
    return CAPSULE_STATUS;
  },

  getJourneyVisibility: function () {
    return RADIO_VISIBILITY;
  },

  getCapsuleStatusIdToLabel: function () {
    const idToLabel = {};
    for (let propKey in CAPSULE_STATUS) {
      const statusId = CAPSULE_STATUS[propKey];
      idToLabel[statusId] = window.getI18n(ti18n, statusId);
    }
    return idToLabel;
  },

  getJourneyVisibilityIdToLabel: function () {
    const idToLabel = {};
    for (let propKey in RADIO_VISIBILITY) {
      const visibilityTypeId = RADIO_VISIBILITY[propKey];
      idToLabel[visibilityTypeId] = window.getI18n(ti18n, visibilityTypeId);
    }
    return idToLabel;
  },

  getRadioType: function () {
    return RADIO_TYPE;
  },

  isJourneyRadio: function (radioDoc) {
    return radioDoc.type == RADIO_TYPE.JOURNEY;
  },

  getElementRelativePositionFromParent: function (elm) {
    if (elm && elm.parentNode) {
      const pPos = elm.parentNode.getBoundingClientRect(), // parent pos
        cPos = elm.getBoundingClientRect(), // target pos
        pos = {};

      (pos.top = cPos.top - pPos.top + elm.parentNode.scrollTop),
        (pos.right = cPos.right - pPos.right),
        (pos.bottom = cPos.bottom - pPos.bottom),
        (pos.left = cPos.left - pPos.left);

      return pos;
    } else {
      return null;
    }
  },

  fadeMediaTo: function (mediaToFade, dur, startVolume, toVol, increment) {
    return new RSVP.Promise(
      function (resolve, reject) {
        dur = dur || 1000;
        toVol = toVol || 0;
        const increase = startVolume < toVol;
        toVol = this.applyPrecision(toVol, 2);
        startVolume = this.applyPrecision(startVolume, 2);

        let s = mediaToFade,
          k = startVolume,
          t = dur / Math.abs((k - toVol) / increment),
          i = setInterval(function () {
            try {
              k = k > toVol ? k - increment : k + increment;
              k = Number.parseFloat(k.toFixed(2));
              if (k < 0) {
                k = 0;
              }
              s.setVolume(k);
              s._volumeValue = k;
              if ((increase && k >= toVol) || (!increase && k <= toVol)) {
                clearInterval(i);
                i = null;
                resolve();
              }
            } catch (error) {
              reject(error);
            }
          }, t);
      }.bind(this),
    );
  },

  isAudioAnnouncementsDisabledLocallyForUser: function () {
    return LocalStorageHelper.getInstance().isAudioAnnouncementsManuallyChanged()
      ? LocalStorageHelper.getInstance().isAudioAnnouncementsDisabled()
      : RolloutHelper.getInstance().getFeatureVariable(
          RolloutHelper.FEATURES.CONFIGURE_AUDIO_ANNOUNCEMENTS,
          RolloutHelper.FEATURES.CONFIGURE_AUDIO_ANNOUNCEMENTS.variables.audio_announcement_turn_off_by_default,
        );
  },

  isAcceptedEvaluation : function(evaluation, accepetedValidationLevels){
    if(!evaluation){
      return true;
    }
    if(evaluation && !evaluation.isValid){
      return false
    }
    if(evaluation && evaluation.recommendation){
      return accepetedValidationLevels && accepetedValidationLevels.length > 0? accepetedValidationLevels.includes(evaluation.recommendation) :this.getDefaultAllowedValidationLevelArray().includes(evaluation.recommendation);
    }
    return true
  },

  getMediaTranscriptionAISearchResultConfidenceLevels: function(){
    return SEARCH_RESULT_CONFIDENCE_LEVELS;
  },

  getMediaTranscriptionAISearchResultValidationLevels: function(){
    return MEDIA_TRANSCRIPTION_SEARCH_VALIDATION_LEVELS;
  },

   getDefaultAllowedValidationLevelArray : function(){
    return [MEDIA_TRANSCRIPTION_SEARCH_VALIDATION_LEVELS.STRONG_ACCEPT, MEDIA_TRANSCRIPTION_SEARCH_VALIDATION_LEVELS.ACCEPT];
  },

  getSearchLLMProviders : function(){
    return LLM_SEARCH_PROVIDER;
  },


  getDefaultSearchLLMProvider : function(){
    return this.getSearchLLMProviders().DEEPSEEK_R1_AZURE;
  },


  getDefaultAllowedConfidenceLevelArray : function(){
    return [SEARCH_RESULT_CONFIDENCE_LEVELS.HIGH];
  },

  getMediaTranscriptionAISearchQueryPresets : function(){
    return TranscriptionDeletableType;
  },

  getDefaultSetOfAISearchQueryPresets : function(){
    return [ TranscriptionDeletableType.OFF_TOPIC_AND_UNNECESSARY_CONTENT,TranscriptionDeletableType.VERBAL_FILLERS_AND_DISFLUENCIES,  TranscriptionDeletableType.REDUNDANCIES_AND_REPETITIONS];
  },

  removeWordIdsFromTranscription: function(text){
    return text ? text.replace(/\[\[c?\d+\]\]/g, '').replace(/\s+/g, ' ').trim() : "";
  },

  getHtmlContent: function (url) {
    return new RSVP.Promise(function (resolve, reject) {
      try {
        $.get(url, function (content) {
          resolve(content);
        }).fail(function (error) {
          reject(error);
        });
      } catch (error) {
        reject(error);
      }
    });
  },

  isInterntetConnectionIsAvailable: function () {
    return this.isOverWifi() || this.isOverDataPlan() || (window.device && window.device.platform == "browser");
  },

  getAudioTypes: function () {
    return AUDIO_TYPES;
  },

  isAppRatingAvailable: function () {
    return !!window.AppRate;
  },

  getAudioIdFromAudioRefSummary: function (audioType, audioRefSummary) {
    if (audioType == AUDIO_TYPES.CAPSULE && audioRefSummary && audioRefSummary.capsuleRefUID) {
      return audioRefSummary.capsuleRefUID;
    }
    if (audioType == AUDIO_TYPES.SONG && audioRefSummary && audioRefSummary.songRefUID) {
      return audioRefSummary.songRefUID;
    }
    if (audioType == AUDIO_TYPES.JINGLE && audioRefSummary && audioRefSummary.jingleRefUID) {
      return audioRefSummary.jingleRefUID;
    }
    if (audioType == AUDIO_TYPES.GREATER && audioRefSummary && audioRefSummary.greaterRefUID) {
      return audioRefSummary.greaterRefUID;
    }
    return null;
  },

  getMilliNumberOfString: function (aNumberFloatInString) {
    const floatNumber = parseFloat(aNumberFloatInString);
    return Math.floor(floatNumber * 1000);
  },

  isDialogToRateAppShouldBeDisplayed: function () {
    if (
      RolloutHelper.getInstance().isFeatureEnabled(RolloutHelper.FEATURES.PROMPT_USER_TO_RATE_APP) &&
      !LocalStorageHelper.getInstance().isUserRatedTheApp() &&
      LocalStorageHelper.getInstance().getNumberOfTimesUserRefuseToRateTheApp() < MAX_NUMBER_OF_REJECTION_TO_RATE_APP &&
      LocalStorageHelper.getInstance().getNumberOfTimesUserSentSuggestionToImproveTheApp() <
        MAX_NUMBER_OF_SUGGESTION_SENT_FOR_APP_IMPROVEMENT
    ) {
      if (
        LocalStorageHelper.getInstance().getNumberOfDaysCapsuleFeedWasPlayed() <
        MIN_NUMBER_OF_DAYS_CAPSULE_FEED_PLAY_TO_REQUEST_RATING
      ) {
        //if(3 < MIN_NUMBER_OF_DAYS_CAPSULE_FEED_PLAY_TO_REQUEST_RATING){
        return false;
      } else {
        if (!LocalStorageHelper.getInstance().getLastTimeUserWasPromptedToRateTheApp()) {
          //User was never prompted
          return true;
        } else {
          const lastTimeDate = new Date(LocalStorageHelper.getInstance().getLastTimeUserWasPromptedToRateTheApp());
          const numberOfDaysSinceLastTimeUserWasPrompted = Math.round(
            (new Date() - lastTimeDate) / (1000 * 60 * 60 * 24),
          );
          if (numberOfDaysSinceLastTimeUserWasPrompted >= MIN_NUMBER_OF_DAYS_BETWEEN_TO_RATE_APP_REQUEST) {
            return true;
          } else {
            return false;
          }
        }
      }
    } else {
      return false;
    }
  },

  isSongInstance: function (obj) {
    return obj instanceof Song;
  },

  isCommentInstance: function (obj) {
    return obj instanceof Comment;
  },

  isCapsuleInstance: function (obj) {
    return obj instanceof Capsule;
  },

  isUserInstance: function (obj) {
    return obj instanceof User;
  },

  getProfileTypes: function () {
    return PROFILE_TYPES;
  },

  addEllipsisIfApplicable: function (text, maxNumberOfCharater) {
    if (text.length > maxNumberOfCharater) {
      return text.substr(0, maxNumberOfCharater) + "...";
    } else {
      return text;
    }
  },

  wordCount: function (str) {
    return str.split(" ").filter(function (n) {
      return n != "";
    }).length;
  },

  getString: function (object) {
    if (object === null) {
      return "";
    }
    if (object === undefined) {
      return "undefined";
    }
    if (typeof object === "string" || object instanceof String) {
      return object;
    }
    if (Array.isArray(object)) {
      return object.toString();
    }
    if (object && typeof object === "object" && object.constructor === Object) {
      return JSON.stringify(object);
    }
    {
      return "";
    }
  },

  artistInfoCanBeDisplayedForThisAudioUri: function (audioUri) {
    const uri = audioUri;
    const isSongACapsule = this.isCapsuleUri(uri);
    /*const isSongAJingle = this.isJingleUri(uri);
			const isSongAGreater = this.isGreaterUri(uri);
			const isSongAIntro = this.isIntroUri(uri);
			const isSongAOutro = this.isOutroUri(uri);
			const isSongAEarcon = this.isEarconUri(uri);
			const isTrebbleGeneratedSpeechAudio = this.isTrebbleGeneratedSpeechUri(uri);*/

    //return !isSongAEarcon && !isSongAOutro && !isSongAIntro && !isSongAGreater && !isSongAJingle && !isTrebbleGeneratedSpeechAudio;
    return isSongACapsule;
  },

  getTranscripionJobStatusI18nFromValue: function (transcriptionStatusValue) {
    return window.getI18n(ti18n, TREBBLE_TRANSCRIPITON_JOB_STATUS_TO_I18N_KEY[transcriptionStatusValue]);
  },

  commentsCanBeDisplayedForThisAudioUri: function (audioUri) {
    const uri = audioUri;
    const isSongACapsule = this.isCapsuleUri(uri);
    /*const isSongAJingle = this.isJingleUri(uri);
			const isSongAGreater = this.isGreaterUri(uri);
			const isSongAIntro = this.isIntroUri(uri);
			const isSongAOutro = this.isOutroUri(uri);
			const isSongAEarcon = this.isEarconUri(uri);
			const isTrebbleGeneratedSpeechAudio = this.isTrebbleGeneratedSpeechUri(uri);*/

    //return !isSongAEarcon && !isSongAOutro && !isSongAIntro && !isSongAGreater && !isSongAJingle && !isTrebbleGeneratedSpeechAudio;
    return isSongACapsule;
  },

  artistInfoCanBeDisplayedForThisAudio: function (songInfo) {
    return this.artistInfoCanBeDisplayedForThisAudioUri(songInfo.get("uri"));
  },

  commentsCanBeDisplayedForThisAudio: function (songInfo) {
    return this.commentsCanBeDisplayedForThisAudioUri(songInfo.get("uri"));
  },

  isSharedArrayBufferSupported: function () {
    try {
      if ("SharedArrayBuffer" in window) {
        const sab = new SharedArrayBuffer(1024);
        if (sab === undefined) throw new Error("not supported");
        return true;
      }
    } catch (e) {
      return false;
    }
  },

  encodeQueryData: function (data) {
    const ret = [];
    for (let d in data) ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
    return ret.join("&");
  },

  getParameterByName: function (name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  },

  encode_utf8: function (s) {
    //return unescape(encodeURIComponent(s));
    return encodeURIComponent(s);
  },

  decode_utf8: function (s) {
    // return decodeURIComponent(escape(s));
    return decodeURIComponent(s);
  },

  getUserLoginCredentials: function () {
    //should show a popup to user instead
    const userCred = {};
    const username = LocalStorageHelper.getInstance().retrieveUsername() || window.device.platform + window.device.uuid;
    const password = LocalStorageHelper.getInstance().retrieveUserPassword() || window.device.uuid;
    userCred.username = username;
    userCred.password = password;
    return RSVP.Promise.resolve(userCred);
  },

  requireNotificationTemplate: function () {
    $.mobile.loading("show");
    return new RSVP.Promise(function (resolve, reject) {
      try {
        require(["text!../../../templates/common/NotificationPreviewAlertTemplate.html"], function (loadedFiled) {
          $.mobile.loading("hide");
          resolve(loadedFiled);
        });
      } catch (error) {
        $.mobile.loading("hide");
        reject(error);
      }
    });
  },

  requireFileUploader: function () {
    $.mobile.loading("show");
    return new RSVP.Promise(function (resolve, reject) {
      try {
        require(["models/helper/FileUploadHelper"], function (loadedFiled) {
          $.mobile.loading("hide");
          resolve(loadedFiled);
        });
      } catch (error) {
        $.mobile.loading("hide");
        reject(error);
      }
    });
  },

  /*require : function(arrayOfFilesToLoad){
		  $.mobile.loading("show");
		  return new RSVP.Promise(function(resolve, reject){
			  try{
				  require(arrayOfFilesToLoad,function(loadedFiled){
					  $.mobile.loading("hide");
					  resolve(loadedFiled);
  	
				  });
			  }catch(error){
				  $.mobile.loading("hide");
				  reject(error);
			  }
		  })
	  },*/

  getCapsuleLifeSpanLabelToValueInDays: function () {
    return CAPSULE_LIFE_SPAN_LABEL_TO_VALUE_IN_DAYS;
  },

  showSweetAlertDialog: function (callbackToShowDialog, intervallToMonitor) {
    setTimeout(
      function () {
        if ($(document).find(".sweet-alert.showSweetAlert.visible").length == 0) {
          callbackToShowDialog();
        } else {
          this.showSweetAlertDialog(callbackToShowDialog, intervallToMonitor);
        }
      }.bind(this),
      intervallToMonitor,
    );
  },

  getDefaultNumberOfSongsRequiredToPlayATrebble: function () {
    return DEFAULT_NUMBER_OF_SONGS_REQUIRED_TO_PLAY_A_TREBBLE;
  },

  getDefaultTrebbleSquareLogoURL: function () {
    return "https://web.trebble.fm/trebbleLogoSquare1024x1024.jpg";
  },

  getHtmlTrebbleListeningInstructions: function (trebbleName, isSubscribed, hideIntroText) {
    return this.getHtmlTrebbleListeningInstructionsNew(
      trebbleName,
      isSubscribed,
      false,
      true,
      true,
      false,
      hideIntroText,
    );
  },

  getHtmlTrebbleListeningInstructionsNew: function (
    trebbleName,
    isSubscribed,
    showTextOnly,
    showChatBubbles,
    showAlexaEnablingInstruction,
    showAssistantName,
    hideIntroText,
  ) {
    const templateOption = {
      trebbleName: trebbleName,
      isSubscribed: isSubscribed,
      showTextOnly: showTextOnly,
      showChatBubbles: showChatBubbles,
      showAlexaEnablingInstruction: showAlexaEnablingInstruction,
      showAssistantName: showAssistantName,
      hideIntroText: hideIntroText,
      ti18n: TrebbeSpecificListeningInstructionsi18n,
    };
    return _.template(TrebbeSpecificListeningInstructionsTemplate)(templateOption);
  },

  canReferralLinkBeGenerated: function () {
    return window.waitForCordovaToLoad && window.plugins && window.plugins.appsFlyer;
  },

  isLanguageSupportedByDeepgram: function (languageCode) {
    return !!DEEPGRAM_SUPPORTED_AWS_LANGUAGE_CODE_TO_DEEPGRAM_LANGUAGE_CODE[languageCode];
  },

  getDeepgramLanguageCodeFromAwsLanguageCode: function (awsLanguageCode) {
    return DEEPGRAM_SUPPORTED_AWS_LANGUAGE_CODE_TO_DEEPGRAM_LANGUAGE_CODE[awsLanguageCode];
  },

  addNewlineAfterNSentences: function (inputString, n, addDoubleNewLines) {
    // Split the input string into individual sentences
    const sentences = inputString.match(/[^.!?]+[.!?]+/g);

    // Add a new line after every "n" sentences
    let result = "";
    for (let i = 0; i < sentences.length; i++) {
      result += sentences[i] + " ";
      if ((i + 1) % n === 0) {
        if (addDoubleNewLines) {
          result += "\n\n";
        } else {
          result += "\n";
        }
      }
    }

    return result.trim();
  },

  generateInviteLink: function (userId, channel, campaign, userParams) {
    if (window.plugins && window.plugins.appsFlyer) {
      window.plugins.appsFlyer.setAppInviteOneLinkID(TREBBLE_APPFLYER_ONELINK_ID);
      return new RSVP.Promise(function (resolve, reject) {
        const inviteOptions = {
          channel: channel,
          campaign: campaign,
          customerID: userId,
          userParams: userParams,
        };

        const onInviteLinkSuccess = function (link) {
          resolve(link); // Handle Generated Link Here
        };

        function onInviteLinkError(err) {
          reject(err);
        }
        window.plugins.appsFlyer.generateInviteLink(inviteOptions, onInviteLinkSuccess, onInviteLinkError);
      });
    } else {
      return RSVP.Promise.resolve(null);
    }
  },


  getFilenameWithExtension : (url) => {
    if (url) {
      var m = url.toString().match(/\/([^/?#]+)[^/]*$/);
      if (m && m.length > 1) {
        return m[1];
      }
    }
    return "";
  },
  
  getFilenameWithoutExtension : (url) => {
    if (url) {
      var m = url.toString().match(/\/([^/?#]+)[^/]*$/);
      if (m && m.length > 1) {
        var fullFilename = m[1];
        var indexOfLastDot = fullFilename.lastIndexOf(".");
        return indexOfLastDot !== -1 ? fullFilename.substring(0, indexOfLastDot) : fullFilename;
      }
    }
    return "";
  },

  getTrebbleSongJsonFromSongInfo: function (
    songTitle,
    artistName,
    album,
    coverArt,
    duration,
    explicit,
    uri,
    publishedAtTimestamp,
    extraData,
  ) {
    if (!songTitle) {
      throw "Song title is mandatory";
    }
    if (!artistName) {
      throw "Artist name is mandatory";
    }
    if (!album) {
      album = songTitle;
    }
    const songJson = {};
    songJson.title = songTitle;
    songJson.artist = artistName; //scTrackInfo.title;
    songJson.explicit = explicit ? explicit : false;
    songJson.artistId = songJson.artist;
    songJson.album = album ? album : songJson.title;
    songJson.albumId = OTHER_SOURCE_URI_PREFIX + URI_DELIMITER + album;
    songJson.dateAdded = new Date().getTime();
    songJson.dateModified = new Date().getTime();
    songJson.publishedAt = publishedAtTimestamp ? new Date(publishedAtTimestamp) : null;
    //	songJson.genre = scTrackInfo.genre;
    songJson.uri = uri ? uri : OTHER_SOURCE_URI_PREFIX + URI_DELIMITER + songTitle; //SOUNDCLOUD_URI_PREFIX +URI_DELIMITER + scTrackInfo.id;
    songJson.type = "remote";
    songJson.uriType = OTHER_SOURCE_URI_PREFIX;
    songJson.idified = false;
    songJson.idifiedString = "false";
    songJson.idifiedSuccess = false;
    songJson.idifiedSuccessString = "false";
    songJson.favorite = false;
    songJson.favoriteString = "false";
    songJson.duration = duration ? duration : 0;
    songJson.extraData = extraData;
    if (coverArt) {
      songJson.albumArtUrl = coverArt;
      songJson.albumArtImages = this.getTrebbleAlbumImagesFromImageUrl(coverArt);
    }
    return songJson;
  },

  findSeparator: function (str, separatorsArray) {
    if (str === null || str.length === 0) {
      return null;
    }

    for (const i in separatorsArray) {
      const sep = separatorsArray[i];
      const index = str.indexOf(sep);
      if (index > -1) {
        return { index: index, length: sep.length, separator: sep };
      }
    }

    return null;
  },

  createBottomSheet: function (el, onBottomSheetOpened, onBottomSheetClosed, transparentTheme) {
    return new BottomSheet(el, onBottomSheetOpened, onBottomSheetClosed, transparentTheme);
  },

  getTrebbleMusicCategoryId: function () {
    return TREBBLE_MUSIC_CATEGORY_ID;
  },

  getTrebbleCapsuleCategoryId: function () {
    return TREBBLE_CAPSULE_CATEGORY_ID;
  },

  getTrebbleCapsuleFeedCategoryId: function () {
    return TREBBLE_CAPSULE_CATEGORY_ID;
  },

  getDefaultTrebbleCategoryId: function () {
    return DEFAULT_TREBBLE_CATEGORY_ID;
  },

  getTrebbleNameRegexValidation: function () {
    return TREBBLE_NAME_REGEX_VALIDATION;
  },

  getUsernameRegexValidation: function () {
    return USERNAME_REGEX_VALIDATION;
  },

  getEmailRegexValidation: function () {
    return EMAIL_REGEX_VALIDATION;
  },

  getUserPasswordRegexValidation: function () {
    return PASSWORD_REGEX_VALIDATION;
  },

  getMaxNumberOfCharForUsername: function () {
    return MAX_NUMBER_OF_CHAR_FOR_USERNAME;
  },

  getMinNumberOfCharForUsername: function () {
    return MIN_NUMBER_OF_CHAR_FOR_USERNAME;
  },

  getAudioDevicesAvailable: function () {
    if (navigator && navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      return navigator.mediaDevices.enumerateDevices().then(
        function (devices) {
          if (devices) {
            return devices.filter(function (device) {
              return device.kind === "audioinput" ? device : null;
            });
          }
        }.bind(this),
      );
    } else {
      return RSVP.Promise.resovle();
    }
  },

  getUniqueDevideId: function (silentlySwallowErrors) {
    if (window.plugins && window.plugins.uniqueDeviceID) {
      return new RSVP.Promise(
        function (resolve, reject) {
          window.plugins.uniqueDeviceID.get(resolve, function (error) {
            if (silentlySwallowErrors) {
              console.error(error);
              resolve(null);
            } else {
              reject(error);
            }
          });
        }.bind(this),
      );
    } else {
      console.error("unique device id plugin is not available");
      return RSVP.Promise.resolve(null);
    }
  },

  getTrebblesSongJsonFromSoundCloudTrackInfo: function (scTrackInfo) {
    const songJson = {};
    let separator = " - ";
    let soundcloudTitle = scTrackInfo.title;
    let soundcloudTitleWithoutBracket = soundcloudTitle;
    soundcloudTitle = soundcloudTitle.replace("(Snippet)", "");
    soundcloudTitle = soundcloudTitle.replace("(Official Video)", "");
    soundcloudTitle = soundcloudTitle.replace("(Official Audio)", "");
    soundcloudTitle = soundcloudTitle.replace("(Unreleased)", "");
    soundcloudTitle = soundcloudTitle.replace("(CDQ)", "");
    soundcloudTitle = soundcloudTitle.replace("(Audio)", "");
    soundcloudTitle = soundcloudTitle.replace("(audio)", "");
    soundcloudTitleWithoutBracket = soundcloudTitle.replace(/ *\([^)]*\) */g, ""); // remove text inside brakets
    let separatorPositionInfo = this.findSeparator(soundcloudTitleWithoutBracket, TITLE_ARTIST_SEPARATORS);
    separatorPositionInfo =
      separatorPositionInfo && separatorPositionInfo.index != -1
        ? this.findSeparator(soundcloudTitle, TITLE_ARTIST_SEPARATORS)
        : null;
    const dashPositionInTitle = separatorPositionInfo ? separatorPositionInfo.index : -1;
    separator = separatorPositionInfo ? separatorPositionInfo.separator : null;

    if (dashPositionInTitle > -1) {
      songJson.title = soundcloudTitle.substring(dashPositionInTitle + separator.length);
      if (songJson.title) {
        songJson.title.trim();
      }
      songJson.artist = soundcloudTitle.substring(0, dashPositionInTitle);
      if (songJson.artist) {
        songJson.artist.trim();
      }
    } else {
      songJson.title = soundcloudTitle;
      songJson.artist = scTrackInfo.user.username; //scTrackInfo.title;
    }
    songJson.explicit = false;
    songJson.artistId = songJson.artist;
    songJson.album = songJson.title;
    songJson.albumId = SOUNDCLOUD_URI_PREFIX + URI_DELIMITER + scTrackInfo.id;
    songJson.dateAdded = new Date().getTime();
    songJson.dateModified = new Date().getTime();
    songJson.publishedAt = scTrackInfo.created_at ? new Date(scTrackInfo.created_at) : null;
    //	songJson.genre = scTrackInfo.genre;
    songJson.uri = "soundcloud:" + scTrackInfo.id; //SOUNDCLOUD_URI_PREFIX +URI_DELIMITER + scTrackInfo.id;
    songJson.type = "remote";
    songJson.uriType = SOUNDCLOUD_URI_PREFIX;
    songJson.idified = false;
    songJson.idifiedString = "false";
    songJson.idifiedSuccess = false;
    songJson.idifiedSuccessString = "false";
    songJson.favorite = false;
    songJson.favoriteString = "false";
    songJson.duration = scTrackInfo.duration;
    songJson.externalPageUrl = scTrackInfo.permalink_url;
    if (scTrackInfo.artwork_url) {
      songJson.albumArtUrl = scTrackInfo.artwork_url.replace("-large", "-t500x500");
      songJson.albumArtImages = this.getTrebbleAlbumImagesFromSoundcloudImageUrl(songJson.albumArtUrl);
    }
    return songJson;
  },

  getMediaMetadata: async function(fileOrUrl, failOnError) {
    const isVideo = await this.isVideoFile(fileOrUrl, failOnError);
    if(isVideo){
      const videoMetadata =  await this.getVideoMetadata(fileOrUrl, failOnError);
      return {...videoMetadata, isVideo}
    }else{
      return {isVideo, duration: await this.getAudioDuration(fileOrUrl)}
    }
},

  getAudioDuration: function (fileOrUrl) {
    return new RSVP.Promise(
      function (resolve, reject) {
        const audioEl = document.createElement("audio");
        audioEl.addEventListener(
          "loadedmetadata",
          (function () {
            if(isFinite(audioEl.duration)){
              resolve(audioEl.duration);
            }else{
              this.getAudioDurationFallback(fileOrUrl).then(resolve);
            }
          }).bind(this),
          false,
        );
        audioEl.addEventListener(
          "error",
          function () {
            reject("Failed loading audio");
          },
          false,
        );
        audioEl.addEventListener(
          "abort",
          function () {
            reject("Failed loading audio");
          },
          false,
        );
        if (fileOrUrl instanceof File) {
          audioEl.src = URL.createObjectURL(fileOrUrl);
        } else {
          audioEl.src = fileOrUrl; // Assume it's a URL
        }
      }.bind(this),
    );
  },

  getAudioDurationFallback: function(url) {
    return new Promise((resolve, reject) => {
        const audioEl = document.createElement('audio');

        audioEl.addEventListener('durationchange', () => {
            const duration = audioEl.duration;
            if (isFinite(duration) && duration > 0) {
                resolve(duration);
            }
        });

        audioEl.addEventListener('error', () => {
            reject('Failed to get duration');
        });

        audioEl.addEventListener('abort', () => {
            reject('Failed to get duration');
        });

        // Manipulate playback to force metadata loading
        audioEl.currentTime = 24 * 60 * 60; // Fake large time to force metadata load
        audioEl.volume = 0; // No need for sound
        audioEl.play().catch(() => {}); // Handle potential play errors

        audioEl.src = url;
        audioEl.load();
    });
},

  getFormattedTimeDurationForDisplayInPlayer: function (trackPositionInSec) {
    if (trackPositionInSec >= 0) {
      const trackPositionInSecRounded = Math.floor(trackPositionInSec);
      const minutes = Math.floor(trackPositionInSecRounded / 60);
      const seconds = trackPositionInSecRounded - minutes * 60;
      let currentpositionString = "";
      currentpositionString = currentpositionString + minutes + ":";
      if (seconds < 10) {
        currentpositionString = currentpositionString + "0" + seconds;
      } else {
        currentpositionString = currentpositionString + seconds;
      }
      return currentpositionString;
    } else {
      return "-:--";
    }
  },

  addTapAndPressHandler: function (
    element,
    pressTreshold,
    tapHandler,
    pressHandler,
    hoverTreshold /*, hoverHandler, hoverOutHandler*/,
  ) {
    if (!pressTreshold) {
      pressTreshold = 251;
    }
    if (!hoverTreshold) {
      hoverTreshold = 1000;
    }
    const mc = new Hammer.Manager(element);
    const tapRecognizer = new Hammer.Tap();
    const pressRecognizer = new Hammer.Press({ time: pressTreshold });
    mc.add(tapRecognizer);
    mc.add(pressRecognizer);
    if (tapHandler) {
      mc.on("tap", tapHandler);
    }
    if (pressHandler) {
      mc.on("press", pressHandler);
    }
    /*if(hoverHandler ){
				$(element).hoverIntent({
					over: hoverHandler,
					out: function(){},
					sensitivity: 20,
					interval : hoverTreshold,
					//timeout : hoverTreshold * 2,
				});
			}*/
  },
  isPageDisplayed: function (page$El) {
    return $.mobile.activePage[0] === page$El[0];
  },
  isJQMPopupOpen: function () {
    return $.mobile.popup.active && $.mobile.popup.active.element[0];
  },

  isJQMPopupWithIdOpen: function (popupId) {
    return $.mobile.popup.active && $.mobile.popup.active.element[0] && $.mobile.popup.active.element[0].id === popupId;
  },

  escapeRegExp: function (string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  },

  showDialogToCollectInput: function (
    onConfirmButtonClicked,
    onCancelButtonClicked,
    isInputValueValidFunction,
    headerMessage,
    subHeaderMessage,
    cancelButtonLabel,
    confirmButtonLabel,
    inputPlaceholder,
    defaultValue,
  ) {
    const unButtonCliked = function (result) {
      const buttonIndex = result.buttonIndex;
      const inputValue = result.input1;
      setTimeout(function () {
        if (buttonIndex == 1) {
          onConfirmButtonClicked(inputValue);
        } else {
          onCancelButtonClicked();
        }
      }, 500);
    }.bind(this);
    window.navigator.trebbleNotification.prompt(
      subHeaderMessage,
      unButtonCliked,
      headerMessage,
      [confirmButtonLabel, cancelButtonLabel],
      "",
      true,
      inputPlaceholder,
      defaultValue,
    );
    const okButton$el = $(".sweet-alert button.confirm");
    okButton$el.addClass("show_orginal_text");
    okButton$el.prop("disabled", true);
    const input$el = $(".sweet-alert input");
    const onInputValueChange = function () {
      if (isInputValueValidFunction(input$el.get(0).value)) {
        okButton$el.prop("disabled", false);
      } else {
        okButton$el.prop("disabled", true);
      }
    }.bind(this);
    input$el.on(" keyup input", onInputValueChange);
  },

  closeCurrentJQMPopup: function () {
    if (this.isJQMPopupOpen()) {
      const p = new RSVP.Promise(
        function (resolve, reject) {
          try {
            $.mobile.popup.active.element.on(
              "popupafterclose",
              function () {
                resolve();
              }.bind(this),
            );
          } catch (err) {
            reject(err);
          }
        }.bind(this),
      );
      $.mobile.popup.active.close();
      return p;
    } else {
      return RSVP.Promise.resolve();
    }
  },

  listenOnWindowClose: function (openWindowRef, onWindowCloseCallback) {
    if (openWindowRef) {
      if (window.waitForCordovaToLoad) {
        openWindowRef.addEventListener("exit", function (event) {
          onWindowCloseCallback(event.url);
        });
      } else {
        const timer = setInterval(function () {
          if (openWindowRef.closed) {
            clearInterval(timer);
            onWindowCloseCallback(openWindowRef.location.href);
          }
        }, 1000);
      }
    }
  },

  isValidEndOfSentence: function(str) {
    const regex = /[.!?…'"”’)]$/;
    if(str){
      return regex.test(str.trim());
    }else{
      return false;
    }
  },

  downloadURI: async function (uri, name, progressReportFunction) {
    const chunkSize = 1024 * 512; // Adjust the chunk size as needed
    let alreadyDownloadedChunk = 0;

    try {
      const response = await fetch(uri);
      const contentLength = parseInt(response.headers.get("Content-Length"), 10);
      const totalChunks = Math.ceil(contentLength / chunkSize);

      const reader = response.body.getReader();
      const concatenatedChunks = [];

      while (true) {
        const { value, done } = await reader.read();

        if (done) {
          // Download complete
          if (progressReportFunction) {
            progressReportFunction(1);
          }
          break;
        }

        const currentChunk = value.length / chunkSize;
        alreadyDownloadedChunk += currentChunk;

        // Update progress
        const progress = alreadyDownloadedChunk / totalChunks;
        if (progressReportFunction) {
          progressReportFunction(progress);
        }

        // Concatenate the chunk
        concatenatedChunks.push(value);
      }

      // Create a Blob from the concatenated array
      const blob = new Blob(concatenatedChunks, {
        type: response.headers.get("Content-Type"),
      });

      // Rest of your code for creating a download link
      let fileExtension = this.getFileExtenstionForMimeType(blob.type);
      if (!fileExtension) {
        fileExtension = this.getFileExtensionFromUrl(uri);
      }

      const fileName = this.getFileExtensionFromUrl(name) ? name : name + (fileExtension ? "." + fileExtension : "");

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.rel = "noopener";
      a.target = "_blank";

      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  },

  downloadFile: function (blob, nameWithExtension) {
    //const fileExtension = this.getFileExtenstionForMimeType(blob.type);

    const mimeTypeFromFileExtension = this.getMimeTypeForFileExtenstion(nameWithExtension);
    if (mimeTypeFromFileExtension) {
      blob = blob.slice(0, blob.size, mimeTypeFromFileExtension);
    }
    const fileName = nameWithExtension;

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.rel = "noopener";
    a.target = "_blank";

    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  },

  createFileWithNewName: function (originalFile, newName) {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  },

  getFileExtensionFromUrl: function (filepath) {
    const regex = /(?:\.([^.]+))?(?=$|\/)/;
    const match = filepath.match(regex);
    if (match && match.length > 1) {
      return match[1];
    } else {
      return null;
    }
  },

  getMimeTypeFromUrl: function(filepath){
    return this.getMimeTypeForFileExtenstion(this.getFileExtensionFromUrl(filepath));
  },

  getNotificationTypes: function () {
    return NOTIFICATION_TYPES;
  },

  getTrebbleJobStatus: function () {
    return TREBBLE_JOB_STATUS;
  },

  getCapsuleExpirationDate: function (capsuleJsonOrModel, radioIdsToGetExpirationDateFor) {
    return this.getCapsuleDataForRadioIds(capsuleJsonOrModel, radioIdsToGetExpirationDateFor, "expirationDate");
  },

  getCapsuleAddedDate: function (capsuleJsonOrModel, radioIdsToGetExpirationDateFor) {
    return this.getCapsuleDataForRadioIds(capsuleJsonOrModel, radioIdsToGetExpirationDateFor, "addedDate");
  },

  getCapsuleDataForRadioIds: function (capsuleJsonOrModel, radioIdsToGetDataFor, dataName) {
    if (capsuleJsonOrModel) {
      if (this.isCapsuleInstance(capsuleJsonOrModel)) {
        const capsuleModel = capsuleJsonOrModel;
        return capsuleModel.getDataForRadioIds(radioIdsToGetDataFor, dataName);
      } else {
        const airingOnRadiosInfo = capsuleJsonOrModel.airingOnRadios;
        if (airingOnRadiosInfo) {
          for (let i = 0; i < airingOnRadiosInfo.length; i++) {
            const radioInfo = airingOnRadiosInfo[i];
            if (radioInfo) {
              const radioId = radioInfo.radioUID;
              if (radioIdsToGetDataFor.indexOf(radioId) !== -1) {
                return radioInfo[dataName];
              }
            }
          }
        }
      }
    }
    return null;
  },

  addHttpsToURLIfApplicable: function (url) {
    return url && url.indexOf("http") != 0 ? "https://" + url : url;
  },

  getTrebblesSongJsonFromYoutubeVideoInfo: function (ytVideoInfo, doNotParseVideoTitleToExtractSongTitle) {
    const songJson = {};
    let separator = " - ";
    let youtubeVideoTitle = ytVideoInfo.snippet.title;
    let youtubeVideoTitleWithoutBracket = youtubeVideoTitle;
    if (!doNotParseVideoTitleToExtractSongTitle) {
      youtubeVideoTitle = youtubeVideoTitle.replace("(Snippet)", "");
      youtubeVideoTitle = youtubeVideoTitle.replace("(Official Video)", "");
      youtubeVideoTitle = youtubeVideoTitle.replace("(Official Audio)", "");
      youtubeVideoTitle = youtubeVideoTitle.replace("(Unreleased)", "");
      youtubeVideoTitle = youtubeVideoTitle.replace("(CDQ)", "");
      youtubeVideoTitle = youtubeVideoTitle.replace("(Audio)", "");
      youtubeVideoTitle = youtubeVideoTitle.replace("(audio)", "");
      youtubeVideoTitleWithoutBracket = youtubeVideoTitle.replace(/ *\([^)]*\) */g, ""); // remove text inside brakets
    }
    let separatorPositionInfo = this.findSeparator(youtubeVideoTitleWithoutBracket, TITLE_ARTIST_SEPARATORS);
    separatorPositionInfo =
      separatorPositionInfo && separatorPositionInfo.index != -1
        ? this.findSeparator(youtubeVideoTitle, TITLE_ARTIST_SEPARATORS)
        : null;
    const dashPositionInTitle = separatorPositionInfo ? separatorPositionInfo.index : -1;
    separator = separatorPositionInfo ? separatorPositionInfo.separator : null;
    if (!doNotParseVideoTitleToExtractSongTitle) {
      if (dashPositionInTitle > -1) {
        songJson.title = youtubeVideoTitle.substring(dashPositionInTitle + separator.length);
        if (songJson.title) {
          songJson.title.trim();
        }
        songJson.artist = youtubeVideoTitle.substring(0, dashPositionInTitle);
        if (songJson.artist) {
          songJson.artist.trim();
        }
      } else {
        songJson.title = youtubeVideoTitle;
        songJson.artist = youtubeVideoTitle;
      }
    } else {
      songJson.title = youtubeVideoTitle;
      songJson.artist = ytVideoInfo.snippet.channelTitle;
    }
    songJson.explicit = false;
    songJson.artistId = songJson.artist;
    songJson.album = songJson.title;
    songJson.albumId = YOUTUBE_URI_PREFIX + URI_DELIMITER + ytVideoInfo.id;
    songJson.dateAdded = new Date().getTime();
    songJson.dateModified = new Date().getTime();
    songJson.publishedAt = ytVideoInfo.snippet.publishedAt;
    songJson.uri = "youtube:" + ytVideoInfo.id; //YOUTUBE_URI_PREFIX + URI_DELIMITER  + ytVideoInfo.id;
    songJson.type = "remote";
    songJson.uriType = YOUTUBE_URI_PREFIX;
    songJson.idified = false;
    songJson.idifiedString = "false";
    songJson.idifiedSuccess = false;
    songJson.idifiedSuccessString = "false";
    songJson.favorite = false;
    songJson.favoriteString = "false";
    songJson.duration = null;
    songJson.externalPageUrl = "https://www.youtube.com/watch?v=" + ytVideoInfo.id;
    if (ytVideoInfo.snippet.thumbnails) {
      if (ytVideoInfo.snippet.thumbnails["medium"]) {
        songJson.albumArtUrl = ytVideoInfo.snippet.thumbnails["medium"].url;
        songJson.albumArtImages = this.getTrebbleAlbumImagesFromYoutubethumbnailsInfo(ytVideoInfo.snippet.thumbnails);
      }
    }
    return songJson;
  },

  showConfetti: function () {
    const confettiCanvas = document.getElementById("confettiCanvas");
    if (!confettiCanvas) {
      $("body").append("<canvas id='confettiCanvas' ></canvas>");
    }
    document.getElementById("confettiCanvas").width = window.innerWidth;
    document.getElementById("confettiCanvas").height = window.innerHeight;
    const jsConfetti = new JSConfetti({ canvas: confettiCanvas });
    jsConfetti.addConfetti({
      confettiColors: ["#ffbe0b", "#fb5607", "#ff006e", "#8338ec", "#3a86ff"],
      confettiRadius: 5,
      confettiNumber: 300,
    });
  },

  showSorryMessage : function(title, subtitle){
    const buttonLabels = [window.getI18n(ti18n, "OK")];
    const customImages  = ["sorry1.webp","sorry2.gif","sorry3.gif","sorry4.gif","sorry5.gif","sorry6.gif","sorry7.gif","sorry8.gif"];
    const randomImage = customImages[Math.floor(Math.random() * customImages.length)];
    const customImageUrl = (window.waitForCordovaToLoad?"":"/") + "css/images/"+ randomImage;
    const customSize = window.getDocumentClienWidth() <= 450 ?"200x80":"400x138";
    
    navigator.trebbleNotification.confirm(title, function(){}, "", buttonLabels,null , null, subtitle, false, customImageUrl,customSize);

  },

  isUrl: function (str) {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.:~+()]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i",
    ); // fragment locator
    return pattern.test(str);
  },

  getUrlRegex: function (options) {
    const protocol = `(?:(?:[a-z]+:)?//)${options.strict ? "" : "?"}`;
    const auth = "(?:\\S+(?::\\S*)?@)?";
    const host = "(?:(?:[a-z\\u00a1-\\uffff0-9][-_]*)*[a-z\\u00a1-\\uffff0-9]+)";
    const domain = "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*";
    const tld = `(?:\\.${
      options.strict ? "(?:[a-z\\u00a1-\\uffff]{2,})" : `(?:${tlds.sort((a, b) => b.length - a.length).join("|")})`
    })\\.?`;
    const port = "(?::\\d{2,5})?";
    const path = '(?:[/?#][^\\s"]*)?';
    const regex = `(?:${protocol}|www\\.)${auth}(?:localhost|${host}${domain}${tld})${port}${path}`;

    return options.exact ? new RegExp(`(?:^${regex}$)`, "i") : new RegExp(regex, "ig");
  },

  getSongJsonForBackendFromSongModel: function (songModel) {
    if (songModel) {
      const songJson = songModel.toJSON();
      const albumJson = songJson.albumJson;
      songJson.albumJson = null;
      const songJsonWithoutAlbumRef = JSON.parse(JSON.stringify(songJson));
      songJson.albumJson = albumJson;
      return songJsonWithoutAlbumRef;
    } else {
      return null;
    }
  },

  toTitleCase: function (str) {
    if (!str) {
      str = "";
    }
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },

  getDummyCustomURL: function () {
    return DUMMY_CUSTOM_TREBBLE_URL;
  },

  fetchJson: function (jsonURL) {
    return fetch(jsonURL).then(function (response) {
      return response.json();
    });
  },

  showInfoNotification: function (message, customStyle) {
    this._showNotification(message, customStyle, "info");
  },

 
  showTrebbleReadyNotification: function (message) {
    if ($.notify) {
      $.notify(message, {
        position: "top",
        className: "trebbleready",
        autoHideDelay: 5000,
      });
    }
  },

  getCreatorFirstPaidTierName: function () {
    return RolloutHelper.getInstance().getFeatureVariable(
      RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION,
      "pro_tier_name_" + window.getAppUsedLanguage(),
      window.getI18n(ti18n, "PAID_CREATOR_FIRST_TIER_NAME"),
    );
  },

  getCreatorFirstPaidTierCostInUSDPerMonth: function (billedYearly) {
    if (billedYearly) {
      return RolloutHelper.getInstance().getFeatureVariable(
        RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION,
        RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION.variables.pro_tier_cost_usd_billed_yearly,
        7.99,
      );
    } else {
      return RolloutHelper.getInstance().getFeatureVariable(
        RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION,
        RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION.variables.pro_tier_cost_usd_billed_monthly,
        9.99,
      );
    }
  },

  getCreatorSecondPaidTierName: function () {
    return RolloutHelper.getInstance().getFeatureVariable(
      RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION,
      "pro_plus_tier_name_" + window.getAppUsedLanguage(),
      window.getI18n(ti18n, "PAID_CREATOR_SECOND_TIER_NAME"),
    );
  },

  getCreatorSecondPaidTierCostInUSDPerMonth: function (billedYearly) {
    if (billedYearly) {
      return RolloutHelper.getInstance().getFeatureVariable(
        RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION,
        RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION.variables
          .pro_plus_tier_cost_usd_billed_yearly,
        19.99,
      );
    } else {
      return RolloutHelper.getInstance().getFeatureVariable(
        RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION,
        RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION.variables
          .pro_plus_tier_cost_usd_billed_monthly,
        25.99,
      );
    }
  },

  getCreatorFreePlanName: function () {
    return window.getI18n(ti18n, "FREE_CREATOR_PLAN_NAME");
  },

  showPushNotification: function (customPayloadModel, customStyle, onNotificationTappedHandler) {
    //const pageHeaderElementFound = $(".ui-page-active > [data-role=header]");
    this.requireNotificationTemplate(["text!../../../templates/common/NotificationPreviewAlertTemplate.html"])
      .then(
        function (NotificationPreviewAlertTemplate) {
          //const message = "";
          //const className = "notification";
          const compiledHtmlTemplate = _.template(NotificationPreviewAlertTemplate);
          const notificationElId = "notificationEl_" + customPayloadModel.getNotificationId();
          const rawHtmlString = compiledHtmlTemplate({
            model: customPayloadModel,
            elementId: notificationElId,
          });
          //if(pageHeaderElementFound.size() == 0){
          if ($.notify) {
            if (!$.notifyLibraryInitialized) {
              $.notify.addStyle("pushNotificationPreview", {
                html: "<div  data-notify-html='customTemplate'/>",
              });
              $.notifyLibraryInitialized = true;
            }
            if (window.device && window.device.platform == "browser") {
              $.notify(
                { customTemplate: rawHtmlString },
                {
                  style: "pushNotificationPreview",
                  position: "top",
                  autoHideDelay: NOTIFICATION_DISPLAY_TIME_IN_MILLI,
                },
              );
            } else {
              $.notify(
                { customTemplate: rawHtmlString },
                {
                  style: "pushNotificationPreview",
                  position: "top",
                  autoHideDelay: NOTIFICATION_DISPLAY_TIME_IN_MILLI,
                },
              );
            }
          }
          /*}else{
						  pageHeaderElementFound.first().notify({"customTemplate":rawHtmlString},{"style":"pushNotificationPreview","position":"bottom","autoHideDelay": 70000});
					  }*/
          const onNotificationClick = function () {
            onNotificationTappedHandler(this.customPayloadModel);
            $("#" + notificationElId).hide();
          }.bind({
            customPayloadModel: customPayloadModel,
            notificationElId: notificationElId,
          });
          $("#" + notificationElId).one("click", onNotificationClick);
        }.bind(this),
      )
      .catch(
        function (error) {
          console.error("Error loading Push notification template.Error" + error);
          window.alertErrorMessage("Something went wrong when displaying notification");
        }.bind(this),
      );
  },

  getGreetings: function () {
    const m = moment();
    let g = null; //return g

    if (!m || !m.isValid()) {
      return window.getI18n(ti18n, "HELLO");
    } //if we can't find a valid or filled moment, we return.

    const split_afternoon = 12; //24hr time to split the afternoon
    const split_evening = 17; //24hr time to split the evening
    const currentHour = parseFloat(m.format("HH"));

    if (currentHour >= split_afternoon && currentHour <= split_evening) {
      g = window.getI18n(ti18n, "GOOD_AFTERNOON");
    } else if (currentHour >= split_evening) {
      g = window.getI18n(ti18n, "GOOD_EVENING");
    } else {
      g = window.getI18n(ti18n, "GOOD_MORNING");
    }

    return g;
  },

  showErrorNotification: function (message, customStyle) {
    this._showNotification(message, customStyle, "error");
  },

  showWarningNotification: function (message, customStyle) {
    this._showNotification(message, customStyle, "warning");
  },

  getSongCollectionContexts: function () {
    return JSON.parse(JSON.stringify(SONG_COLLECTION_CONTEXTS));
  },

  flattenObject: function (objectToFlatten, prefix, ignoreNullValues) {
    if (!prefix) {
      prefix = "";
    } else {
      prefix = prefix + "_";
    }
    const flattenedObj = {};
    for (let key in objectToFlatten) {
      let value = objectToFlatten[key];
      if (typeof value == "object") {
        const flattendAttributeObj = this.flattenObject(value, key, ignoreNullValues);
        for (let flattedKey in flattendAttributeObj) {
          const flattendAttributeValue = flattendAttributeObj[flattedKey];
          if (flattendAttributeValue || (!flattendAttributeValue && !ignoreNullValues)) {
            flattenedObj[prefix + flattedKey] = flattendAttributeValue;
          }
        }
      } else {
        if (value || (!value && !ignoreNullValues)) {
          flattenedObj[prefix + key] = value;
        }
      }
    }
    return flattenedObj;
  },

  buildContextForSongCollectionMyLibrary: function (searchKeyWork) {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.MY_LIBRARY;
    context.searchKeyWord = searchKeyWork;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForSongCollectionGlobalSearch: function (searchKeyWork) {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.GLOBAL_SEARCH;
    context.searchKeyWord = searchKeyWork;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForSongCollectionMyLocalAutoRadio: function (autoRadioId) {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.LOCAL_AUTO_RADIO;
    context.id = autoRadioId;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForMySubscriptions: function () {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.MY_SUBSCRIPTIONS;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForMyJourneys: function () {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.MY_JOURNEYS;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForSongCollectionAutoRadio: function (autoRadioId) {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.AUTO_RADIO;
    context.id = autoRadioId;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForSongCollectionAutoSongRadio: function (songUri) {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.AUTO_SONG_RADIO;
    context.id = songUri;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForAutoplayTrebbleSuggestionFromCapsule: function (capsuleId) {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.AUTO_PLAY_TREBBLE_SUGGESTION_FROM_CAPSULE;
    context.id = capsuleId;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForAutoplayTrebbleSuggestionFromSong: function (songId) {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.AUTO_PLAY_TREBBLE_SUGGESTION_FROM_SONG;
    context.id = songId;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForSongCollectionOverTheAir: function () {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.OVER_THE_AIR;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForCapsuleCreation: function () {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.CAPSULE_CREATION;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForTrebbleAppExtension: function () {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.TREBBLE_APP_EXTENSION;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForHelpPage: function () {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.HELPER_PAGE;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForHomeScreen: function () {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.HOMESCREEN;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForEmailNewsletter: function () {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.NEWSLETTER;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForTrebbleBrowserPlugin: function (pageUrl) {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.TREBBLE_PLUGIN;
    context.pageUrl = pageUrl;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  isFloat: function (n) {
    return n === +n && n !== (n | 0);
  },

  isInteger: function (n) {
    return n === +n && n === (n | 0);
  },

  isContextEnvironmentAmazonAlexa: function (context) {
    return context && context.environment == PLAYER_HISTORY_ACTION_CONTEXT_ENVIRONMENT_TYPES.ALEXA;
  },

  isContextEnvironmentGoogleAssistant: function (context) {
    return context && context.environment == PLAYER_HISTORY_ACTION_CONTEXT_ENVIRONMENT_TYPES.GOOGLE_ASSISTANT;
  },

  isContextEnvironmentSamsungBixby: function (context) {
    return context && context.environment == PLAYER_HISTORY_ACTION_CONTEXT_ENVIRONMENT_TYPES.SAMSUNG_BIXBY;
  },

  isContextEnvironmentAppleSiri: function (context) {
    return context && context.environment == PLAYER_HISTORY_ACTION_CONTEXT_ENVIRONMENT_TYPES.APPLE_SIRI;
  },

  getEmailNewsletterEnvironmentContext: function () {
    return PLAYER_HISTORY_ACTION_CONTEXT_ENVIRONMENT_TYPES.EMAIL_NEWSLETTER;
  },

  buildContextForUserCapsuleFeed: function (userId) {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.CAPSULE_FEED;
    context.id = userId;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForCategoryShortcatRadio: function (categorId) {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.CATEGORY_SHORTCAST_RADIO;
    context.id = categorId;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForSpecificTrebbleInCapsuleFeed: function (radioId, capsuleFeedContext) {
    const context = this.buildContextForSongCollectionUserRadio(radioId);
    context.capsuleFeedContext = capsuleFeedContext;
    return context;
  },

  buildContextForSongCollectionUserRadio: function (userRadioId) {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.RADIO;
    context.id = userRadioId;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForSimpleCapsuleSet: function (capsuleId) {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.SIMPLE_CAPSULE_SET;
    context.id = capsuleId;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  isContextTypeSimpleCapsuleSet: function (context) {
    return context && context.type == SONG_COLLECTION_CONTEXTS.SIMPLE_CAPSULE_SET ? true : false;
  },

  buildContextForCommentOnCapsule: function (capsuleId) {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.COMMENT_ON_CAPSULE;
    context.id = capsuleId;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  isContextTypeCommentOnCapsule: function (context) {
    return context && context.type == SONG_COLLECTION_CONTEXTS.SIMPLE_CAPSULE_SET ? true : false;
  },

  buildContextForSimpleSongSet: function (songId) {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.SIMPLE_SONG_SET;
    context.id = songId;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  isContextTypeSimpleSongSet: function (context) {
    return context && context.type == SONG_COLLECTION_CONTEXTS.SIMPLE_SONG_SET ? true : false;
  },

  isContextTypeCapsuleFeed: function (context) {
    return context && context.type == SONG_COLLECTION_CONTEXTS.CAPSULE_FEED ? true : false;
  },

  isContextTypeCategoryShortcastRadio: function (context) {
    return context && context.type == SONG_COLLECTION_CONTEXTS.CATEGORY_SHORTCAST_RADIO ? true : false;
  },

  getDefaultPromiseErrorHandler: function (hideProgressBar) {
    return function (error) {
      if (hideProgressBar) {
        $.mobile.loading("hide");
      }
      console.error(error);
      window.alertErrorMessage(error);
    };
  },

  buildContextForFollowingRadioDetail: function (userRadioId) {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.FOLLOWING_RADIO_DETAIL;
    context.id = userRadioId;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForMyRadioDetail: function (userRadioId) {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.MY_RADIO_DETAIL;
    context.id = userRadioId;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForMyAutoRadioDetail: function (userRadioId) {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.MY_AUTO_RADIO_DETAIL;
    context.id = userRadioId;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForExploreRadioDetail: function (userRadioId) {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.EXPLORE_RADIO_DETAIL;
    context.id = userRadioId;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForPushNotification: function () {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.PUSH_NOTIFICATION;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForMyCapsulePage: function () {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.MY_CAPSULES_PAGE;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForSongCollectionAlbum: function (albumId) {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.ALBUM;
    context.id = albumId;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForSongCollectionArtist: function (artistId) {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.ARTIST;
    context.id = artistId;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForUserDetailsPage: function (userId) {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.USER_PAGE;
    context.id = userId;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForSongCollectionUserCustom: function () {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.USER_CUSTOM;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForSongCollectionFromSoundcloudApp: function () {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.SOUNDCLOUD;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildContextForSongCollectionFromYoutubeApp: function () {
    const context = {};
    context.type = SONG_COLLECTION_CONTEXTS.YOUTUBE;
    context.environment = this.getCurrentEnvironmentContext();
    context.environmentDetails = this.getCurrentEnvironmentContextDetails();
    return context;
  },

  buildInfoBoxParams: function (
    infoBoxClassName,
    iconClass,
    actionButtonIconClass,
    message,
    helpMessage,
    actionButtonLabel,
    actionButtonHandlerFunction,
  ) {
    const params = {};
    params.infoBoxClassName = infoBoxClassName ? infoBoxClassName : "";
    params.iconClass = iconClass ? iconClass : "pe-7s-search";
    params.actionButtonIconClass = actionButtonIconClass ? actionButtonIconClass : "";
    params.message = message ? message : "";
    params.helpMessage = helpMessage ? helpMessage : "";
    params.actionButtonLabel = actionButtonLabel ? actionButtonLabel : null;
    params.actionButtonHandlerFunction = actionButtonHandlerFunction ? actionButtonHandlerFunction : null;
    return params;
  },

  showLongToastMessage: function (message) {
    if (window.device.platform != "browser") {
      if (window.device.platform == "iOS") {
        window.plugins.toast.showLongBottom(message);
      } else {
        window.plugins.toast.showLongBottom(message);
      }
    } else {
      if ($.notify) {
        if (this.isJQMPopupOpen()) {
          $.notify(message, {
            position: "top",
            className: "toast-light",
            autoHideDelay: NOTIFICATION_DISPLAY_TIME_IN_MILLI,
          });
        } else {
          $.notify(message, {
            position: "top",
            className: "toast",
            autoHideDelay: NOTIFICATION_DISPLAY_TIME_IN_MILLI,
          });
        }
      }
    }
  },

  showShortToastMessage: function (message) {
    if (window.device.platform != "browser") {
      if (window.device.platform == "iOS") {
        window.plugins.toast.showShortBottom(message);
      } else {
        window.plugins.toast.showShortBottom(message);
      }
    } else {
      if ($.notify) {
        if (this.isJQMPopupOpen()) {
          $.notify(message, {
            position: "top",
            className: "toast-light",
            autoHideDelay: NOTIFICATION_DISPLAY_TIME_IN_MILLI,
          });
        } else {
          $.notify(message, {
            position: "top",
            className: "toast",
            autoHideDelay: NOTIFICATION_DISPLAY_TIME_IN_MILLI,
          });
        }
      }
    }
  },

  _getContrastYIQ: function (color) {
    const r = color[0],
      g = color[1],
      b = color[2];

    const yiq = (r * 299 + g * 587 + b * 114) / 1000;

    // return (yiq >= 128) ? 'light' : 'dark';
    return yiq;
  },

  _getDefaultColor: function (yiq) {
    return yiq >= 128 ? [0, 0, 0] : [255, 255, 255];
  },

  _inverseColors: function (color, palette) {
    const yiq = this._getContrastYIQ(color);
    let colors = [],
      primaryColor,
      secondaryColor;

    for (let i = 0; i < palette.length; i++) {
      if (Math.abs(this._getContrastYIQ(palette[i]) - yiq) > 80) {
        colors.push(palette[i]);
      }
    }

    primaryColor = colors[0] ? colors[0] : this._getDefaultColor(yiq);
    secondaryColor = colors[1] ? colors[1] : this._getDefaultColor(yiq);

    return [primaryColor, secondaryColor];
  },

  shouldUserLightColorText: function (red, green, blue) {
    return red * 0.299 + green * 0.587 + blue * 0.114 <= 186;
  },

  /**
   * Converts an RGB color value to HSL. Conversion formula
   * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
   * Assumes r, g, and b are contained in the set [0, 255] and
   * returns h, s, and l in the set [0, 1].
   *
   * @param   Number  r       The red color value
   * @param   Number  g       The green color value
   * @param   Number  b       The blue color value
   * @return  Array           The HSL representation
   */
  rgbToHsl: function (r, g, b) {
    (r /= 255), (g /= 255), (b /= 255);
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max == min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    return [h, s, l];
  },

  /**
   * Converts an HSL color value to RGB. Conversion formula
   * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
   * Assumes h, s, and l are contained in the set [0, 1] and
   * returns r, g, and b in the set [0, 255].
   *
   * @param   Number  h       The hue
   * @param   Number  s       The saturation
   * @param   Number  l       The lightness
   * @return  Array           The RGB representation
   */
  hslToRgb: function (h, s, l) {
    let r, g, b;

    if (s == 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = function (p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return [r * 255, g * 255, b * 255];
  },

  increase_brightness: function (red, green, blue, percent) {
    const r = red; //parseInt(rgbcode.slice(1, 3), 16),
    const g = green; //parseInt(rgbcode.slice(3, 5), 16),
    const b = blue; //parseInt(rgbcode.slice(5, 7), 16),
    const HSL = this.rgbToHsl(r, g, b);
    let newBrightness = HSL[2] + HSL[2] * (percent / 100),
      RGB;

    RGB = this.hslToRgb(HSL[0], HSL[1], newBrightness);
    /*rgbcode = '#'
			+ convertToTwoDigitHexCodeFromDecimal(RGB[0])
			+ convertToTwoDigitHexCodeFromDecimal(RGB[1])
			+ convertToTwoDigitHexCodeFromDecimal(RGB[2]);*/
    RGB[0] = Math.floor(RGB[0]);
    RGB[1] = Math.floor(RGB[1]);
    RGB[2] = Math.floor(RGB[2]);
    return RGB;
  },

  convertToTwoDigitHexCodeFromDecimal: function (decimal) {
    let code = Math.round(decimal).toString(16);

    code.length > 1 || (code = "0" + code);
    return code;
  },

  decreaseColorLightnessSuperiorTo: function (rgbColorArray, yiqValueToCompareTo, lightnessPercentageToApply) {
    const yiq = this._getContrastYIQ(rgbColorArray);
    //const hsl = this.rgbToHsl(rgbColorArray[0], rgbColorArray[1], rgbColorArray[2]);
    if (yiq >= yiqValueToCompareTo) {
      return this.shadeRGBColor(rgbColorArray, lightnessPercentageToApply); //this.increase_brightness(rgbColorArray[0], rgbColorArray[1], rgbColorArray[2], -0.8);
    }
    return rgbColorArray;
  },

  getFirstColorWithLightnessInferiorTo: function (colors, yiqValueToCompareTo) {
    for (let index in colors) {
      const currentColor = colors[index];
      const yiq = this._getContrastYIQ(currentColor);
      //const hsl = this.rgbToHsl(currentColor[0], currentColor[1], currentColor[2]);
      if (yiq < yiqValueToCompareTo) {
        return currentColor;
      }
    }
    return colors[0];
  },

  getFilterListItemModel: function (optionId) {
    if (optionId) {
      return new ListItemModel({
        itemId: optionId,
        itemLabel: LIBRARY_FILTER_OPTIONS[optionId],
      });
    } else {
      return null;
    }
  },

  getImageBase64FromUrl: function (imageUrl) {
    return CordovaHelper.getInstance()
      .executePluginOperation("MusicFileReaderPlugin", "retrieveBase64FromUrl", [imageUrl])
      .then(
        function (imageBase64Info) {
          if (imageBase64Info && imageBase64Info.base64) {
            return imageBase64Info.base64;
          } else {
            return null;
          }
        }.bind(this),
      );
  },

  shouldExecuteIOSQuirkFoRetrievingColorFromImage: function () {
    if (window.device.platform == "iOS") {
      return false;
    } else {
      return false;
    }
  },

  getMostPredominantColorFromCloudinaryImageInfo: function (imageInfo) {
    return imageInfo && imageInfo.colors && imageInfo.colors.length > 0 ? imageInfo.colors[0][0] : null;
  },

  processLargeArrayAsync: function (array, fn, maxTimePerChunk, context) {
    return new RSVP.Promise(
      function (resolve, reject) {
        try {
          context = context || window;
          maxTimePerChunk = maxTimePerChunk || 200;
          let index = 0;

          const now = function () {
            return new Date().getTime();
          };

          const doChunk = function () {
            try {
              const startTime = now();
              while (index < array.length && now() - startTime <= maxTimePerChunk) {
                // callback called with args (value, index, array)
                fn.call(context, array[index], index, array);
                ++index;
              }
              if (index < array.length) {
                // set Timeout for async iteration
                setTimeout(doChunk, 1);
              }
            } catch (error) {
              reject(error);
            }
          };

          doChunk();
        } catch (error) {
          reject(error);
        }
      }.bind(this),
    );
  },

  getRGBArrayFromRGBString: function (rgb) {
    if (rgb) {
      return rgb
        .substring(4, rgb.length - 1)
        .replace(/ /g, "")
        .split(",");
    } else {
      return null;
    }
  },

  shadeRGBColor: function (color, percent) {
    //color example:[159,193,209], Percent minimum is 0 and maximum is 1 or -1 with 1 making it lighter and -1 making it darker
    const f = color,
      t = percent < 0 ? 0 : 255,
      p = percent < 0 ? percent * -1 : percent,
      R = f[0],
      G = f[1],
      B = f[2];
    return [Math.round((t - R) * p) + R, Math.round((t - G) * p) + G, Math.round((t - B) * p) + B];
  },

  blendRGBColors: function (c0, c1, p) {
    //color example:[159,193,209] , Percent minimum is 0 and maximum is 1 or -1 with 1 making it lighter and -1 making it darker
    const f = c0,
      t = c1,
      R = f[0],
      G = f[1],
      B = f[2];
    return [Math.round((t[0] - R) * p) + R, Math.round((t[1] - G) * p) + G, Math.round((t[2] - B) * p) + B];
  },

  getCurrentEnvironmentContext: function () {
    return window.trebble.isMobileContext
      ? PLAYER_HISTORY_ACTION_CONTEXT_ENVIRONMENT_TYPES.MOBILE
      : PLAYER_HISTORY_ACTION_CONTEXT_ENVIRONMENT_TYPES.WEB;
  },

  getCurrentEnvironmentContextDetails: function () {
    const environmentDetails = {};
    if (window.waitForCordovaToLoad) {
      environmentDetails.nativePlatform = this.getDevicePlatform();
    }
    environmentDetails.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return environmentDetails;
  },

  formatNumber: function (num, digits) {
    const si = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  },

  isVideoFile: async function (fileOrUrl, failOnError) {
    return new RSVP.Promise(function (res, rej) {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = function () {
        res(!!(video.videoHeight && video.videoWidth));
        video.src = null;
      };
      video.onerror = function (error) {
        if (failOnError) {
          rej(error);
        } else {
          res(false);
        }
      };
      if (fileOrUrl instanceof File) {
        video.src = URL.createObjectURL(fileOrUrl);
      } else {
        video.src = fileOrUrl;
      }
    });
  },

   getThumbnail: async function(video, width, height, backgroundColor = 'black') {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
  
      canvas.width = width;
      canvas.height = height;
  
      video.crossOrigin = 'anonymous'; // Ensure the crossOrigin attribute is set
      video.currentTime = video.duration / 2; // Capture a frame from the middle of the video
  
      video.onseeked = () => {
        try {
          // Draw the background color
          context.fillStyle = backgroundColor;
          context.fillRect(0, 0, width, height);
  
          const videoAspectRatio = video.videoWidth / video.videoHeight;
          const canvasAspectRatio = width / height;
  
          let drawWidth, drawHeight, offsetX, offsetY;
  
          if (videoAspectRatio > canvasAspectRatio) {
            // Video is wider relative to its height
            drawWidth = width;
            drawHeight = width / videoAspectRatio;
            offsetX = 0;
            offsetY = (height - drawHeight) / 2;
          } else {
            // Video is taller relative to its width
            drawHeight = height;
            drawWidth = height * videoAspectRatio;
            offsetX = (width - drawWidth) / 2;
            offsetY = 0;
          }
  
          // Draw the video frame
          context.drawImage(video, offsetX, offsetY, drawWidth, drawHeight);
  
          // Convert canvas to Data URL
          const thumbnailDataUrl = canvas.toDataURL('image/jpeg');
          canvas.remove();
          resolve(thumbnailDataUrl);
        } catch (e) {
          reject(e);
        }
      };
  
      video.onerror = (error) => reject(error);
    });
  },

  getVideoMetadata : async function (fileOrUrl, failOnError) {
    return new RSVP.Promise((function (res, rej) {
      const video = document.createElement("video");
      video.crossOrigin = 'anonymous'; 
      video.preload = "metadata";
      video.onloadedmetadata = async  () =>{
        const thumbnailWidth = 256; // Set desired thumbnail width
        const thumbnailHeight = 144; // Set desired thumbnail height
        const thumbnail = await this.getThumbnail(video, thumbnailWidth, thumbnailHeight);
        
        res({
          height: video.videoHeight,
          width: video.videoWidth,
          duration: video.duration,
          thumbnail: thumbnail,
          thumbnailWidth,
          thumbnailHeight
      });
        video.src = null;
      };
      video.onerror = function (error) {
        if (failOnError) {
          rej(error);
        } else {
          res(null);
        }
      };
      if (fileOrUrl instanceof File) {
        video.src = URL.createObjectURL(fileOrUrl);
      } else {
        video.src = fileOrUrl;
      }
    }).bind(this));
  },

  formatPlaybackPositionToString: function (playbackPositionInSec, showDecimals) {
    if (playbackPositionInSec >= 0) {
      const playbackPositionInSecRounded = Math.floor(playbackPositionInSec);
      const minutes = Math.floor(playbackPositionInSecRounded / 60);
      const seconds = playbackPositionInSecRounded - minutes * 60;
      const secondsWithDecimalString = (playbackPositionInSec - minutes * 60).toFixed(2);
      let currentpositionString = "";
      currentpositionString = currentpositionString + minutes + ":";
      if (seconds < 10) {
        currentpositionString = showDecimals
          ? currentpositionString + "0" + secondsWithDecimalString
          : currentpositionString + "0" + seconds;
      } else {
        currentpositionString = showDecimals
          ? currentpositionString + secondsWithDecimalString
          : currentpositionString + seconds;
      }
      return currentpositionString;
    } else {
      return "-:--";
    }
  },

  getMatchingColorByImageUrl: function (imageUrl, albumArtImages) {
    const defaultResult = {};
    defaultResult.backgroundColor = "#111111";
    defaultResult.textColor1 = "rgba(255,255,255,1)";
    defaultResult.textColor2 = "rgba(255,255,255,0.5)";
    defaultResult.backgroundColorArray = [11, 11, 11];
    defaultResult.shouldUserLightColorText = true;
    const dotNotUseColorThief = true;
    let colorThief;
    if (DISABLE_IMAGE_BLUR_BACKGROUND) {
      return RSVP.Promise.resolve(defaultResult);
    }
    if (imageUrl) {
      return new RSVP.Promise(
        function (resolve) {
          let result;
          if (!dotNotUseColorThief) {
            colorThief = new ColorThief();
          }
          const image = new Image(300, 300);
          if (imageUrl && imageUrl.indexOf("data:image") != 0) {
            image.crossOrigin = "Anonymous";
            if (window.device && window.device.platform == "browser") {
              imageUrl = window.trebble.globalHelpers.getResizeImageUrl(
                imageUrl,
                window.getDocumentClienWidth(),
                window.getDocumentClienHeight(),
                albumArtImages,
              );
            }
          }
          image.onload = function () {
            try {
              let backgroundColor = null;
              if (USE_VIBRANT_JS || dotNotUseColorThief) {
                const vibrant = new Vibrant(image);
                const swatches = vibrant.swatches();
                const vibrantSwatch = swatches["Vibrant"];
                const darkVibrantSwatch = swatches["DarkVibrant"];

                if (!backgroundColor && darkVibrantSwatch && darkVibrantSwatch.getRgb()) {
                  backgroundColor = darkVibrantSwatch.getRgb(); //this.getRGBArrayFromRGBString(darkVibrantSwatch.getRgb());
                }
                if (!backgroundColor && vibrantSwatch && vibrantSwatch.getRgb()) {
                  backgroundColor = vibrantSwatch.getRgb(); //this.getRGBArrayFromRGBString(vibrantSwatch.getRgb());
                }
                if (!backgroundColor) {
                  for (const swatch in swatches) {
                    if (Object.prototype.hasOwnProperty.call(swatches, swatch) && swatches[swatch]) {
                      backgroundColor = swatches[swatch].getRgb(); //this.getRGBArrayFromRGBString(swatches[swatch].getRgb());
                      break;
                    }
                  }
                }
                if (!backgroundColor && !dotNotUseColorThief) {
                  backgroundColor = colorThief.getColor(image);
                }
              } else {
                backgroundColor = colorThief.getColor(image);
              }
              const palette = !dotNotUseColorThief ? colorThief.getPalette(image, 8) : null;
              if (!USE_VIBRANT_JS) {
                backgroundColor = this.getFirstColorWithLightnessInferiorTo(palette, 60);
              }

              result = {};
              if (/*palette && palette.length > 0 &&*/ backgroundColor && backgroundColor.length > 0) {
                backgroundColor = [
                  Math.round(backgroundColor[0]),
                  Math.round(backgroundColor[1]),
                  Math.round(backgroundColor[2]),
                ];
                backgroundColor = this.decreaseColorLightnessSuperiorTo(backgroundColor, 60, -0.6);
                result.backgroundColor = "rgb(" + backgroundColor.join(",") + ")";

                result.backgroundColorArray = backgroundColor;
                result.shouldUserLightColorText = this.shouldUserLightColorText(
                  backgroundColor[0],
                  backgroundColor[1],
                  backgroundColor[2],
                );
                if (palette && palette.length > 0) {
                  const textColor = this._inverseColors(palette[0], palette);
                  result.textColor1 = "rgba(" + textColor[0].join(",") + ",1)";
                  result.textColor2 = "rgba(" + textColor[1].join(",") + ",0.5)";
                }
              } else {
                result = defaultResult;
              }

              resolve(result);
            } catch (error) {
              console.error(error);
              resolve(defaultResult);
            }
          }.bind(this);
          if (this.shouldExecuteIOSQuirkFoRetrievingColorFromImage() && imageUrl && imageUrl.indexOf("http") == 0) {
            this.getImageBase64FromUrl(imageUrl)
              .then(
                function (imageBase64) {
                  if (imageBase64) {
                    image.src = "data:image/png;base64," + imageBase64;
                  } else {
                    console.error("no base64 data for image!");
                    resolve(defaultResult);
                  }
                }.bind(this),
              )
              .catch(
                function (error) {
                  console.error(error);
                  resolve(defaultResult);
                }.bind(this),
              );
          } else {
            image.src = imageUrl;
          }
        }.bind(this),
      );
    } else {
      return RSVP.Promise.resolve(defaultResult);
    }
  },
  getUserToPickAPicture: function () {
    return new RSVP.Promise(
      function (resolve, reject) {
        const onSuccess = function (imageLocalUrl) {
          resolve(imageLocalUrl);
        };
        const onFailure = function (error) {
          reject(error);
        };

        navigator.camera.getPicture(onSuccess, onFailure, {
          quality: 50,
          destinationType: window.Camera.DestinationType.FILE_URI,
          sourceType: window.Camera.PictureSourceType.PHOTOLIBRARY,
        });
      }.bind(this),
    );
  },

  confirmAction: function (actionName, message, functionNameToExecuteOnConfirmation) {
    const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
    const confirmCallback = function (buttonIndex) {
      if (buttonIndex == 2) {
        setTimeout(function () {
          functionNameToExecuteOnConfirmation();
        }, 100);
      }
    }.bind(this);
    navigator.trebbleNotification.confirm(message, confirmCallback, actionName, buttonLabels);
  },

  getConfirmDialogDeferredHandlers: function (resolve, reject, context) {
    const onOperationSuccessfullyExecuted = function (result) {
      try {
        if (window.swal) {
          //Make sure the Swal is available and close all the currently opened popups
          swal.close();
        }
      } catch (e) {
        console.error("Failed closing currently opened popups.Error:" + e);
      }
      resolve(result);
    }.bind(context);
    const onOperationFailedExecute = function (err) {
      try {
        if (window.swal) {
          //Make sure the Swal is available and close all the currently opened popups
          swal.close();
        }
      } catch (e) {
        console.error("Failed closing currently opened popups.Error:" + e);
      }
      reject(err);
    }.bind(context);
    return {
      resolve: onOperationSuccessfullyExecuted,
      reject: onOperationFailedExecute,
    };
  },

  _showNotification: function (message, customStyle, className) {
    if ($.notify) {
      //const pageHeaderElementFound = $(".ui-page-active > [data-role=header]");
      //if(pageHeaderElementFound.size() == 0){
      if (customStyle) {
        $.notify(message, {
          style: customStyle,
          position: "top",
          className: className,
          autoHideDelay: NOTIFICATION_DISPLAY_TIME_IN_MILLI,
        });
      } else {
        if (window.device && window.device.platform == "browser") {
          $.notify(message, {
            position: "top",
            className: className,
            autoHideDelay: NOTIFICATION_DISPLAY_TIME_IN_MILLI,
          });
        } else {
          $.notify(message, {
            position: "top",
            className: className,
            autoHideDelay: NOTIFICATION_DISPLAY_TIME_IN_MILLI,
          });
        }
      }
      /*}else{
					  if(customStyle){
						  pageHeaderElementFound.first().notify(message,{"style":customStyle,"position":"bottom", "className": className});
					  }else{
						  pageHeaderElementFound.first().notify(message, {"position":"bottom", "className": className});
					  }
				  }*/
    }
  },

  getModelFromSongJson: function (songJson, options) {
    if (songJson) {
      return new Song(songJson, options);
    } else {
      return null;
    }
  },

  getModelFromCapsuleJson: function (capsuleJson, options) {
    if (capsuleJson) {
      return new Capsule(capsuleJson, options);
    } else {
      return null;
    }
  },

  getPauseDurationInMilliSecondsThatShouldBeExplicitlyDisplayed: function () {
    return EXPLICITELY_DISPLAYED_PAUSE_DURATION_IN_MILLI_SECONDS_IN_AUDIO_EDITOR;
  },

  doesSelectedNodesContainOrphanWrapNode: function (startSequencerNodeToDelete, endSequencerNodeToDelete) {
    const startOrphanWrapNodeToMoveArray = [];
    const endOrphanWrapNodeToMoveArray = [];
    let nodeToReplace = startSequencerNodeToDelete;
    while (nodeToReplace) {
      if (nodeToReplace instanceof StartWrapSequencerNode) {
        startOrphanWrapNodeToMoveArray.push(nodeToReplace);
      } else {
        if (nodeToReplace instanceof EndWrapSequencerNode) {
          const matchingStartNode = nodeToReplace.getStartWrapSequencerNode();
          const indexOfMatchingStartNode = startOrphanWrapNodeToMoveArray.indexOf(matchingStartNode);
          if (indexOfMatchingStartNode == -1) {
            endOrphanWrapNodeToMoveArray.push(nodeToReplace);
          } else {
            startOrphanWrapNodeToMoveArray.splice(indexOfMatchingStartNode, 1);
          }
        }
      }
      if (nodeToReplace == endSequencerNodeToDelete) {
        nodeToReplace = null;
      } else {
        nodeToReplace = nodeToReplace.getNext();
      }
    }
    const numberOfOrphanWrapNodes = startOrphanWrapNodeToMoveArray.length + endOrphanWrapNodeToMoveArray.length;

    return numberOfOrphanWrapNodes > 0;
  },

  getPauseAudioSegment: function (
    audioUrl,
    startTime,
    endTime,
    confidence,
    initialStartTime,
    initialEndTime,
    transcribedAudioInstance,
  ) {
    const pauseAudioSegment = new PauseAudioSegment({
      audioUrl: audioUrl,
      startTime: startTime,
      endTime: endTime,
      confidence: confidence,
      initialStartTime: initialStartTime,
      initialEndTime: initialEndTime,
      transcribedAudioInstance: transcribedAudioInstance,
    });

    pauseAudioSegment.setExplicitlyDisplayed(
      endTime - startTime >= EXPLICITELY_DISPLAYED_PAUSE_DURATION_IN_MILLI_SECONDS_IN_AUDIO_EDITOR,
    );
    return pauseAudioSegment;
  },

  isPauseAudioSegmentInstance: function (audioSegment) {
    return audioSegment instanceof PauseAudioSegment;
  },

  isPauseSequencerNodeInstance: function (sequencerNode) {
    return sequencerNode instanceof PauseSequencerNode;
  },


  getDeletedAudioSegment: function () {
    return new DeletedAudioSegment();
  },

  isDeletedAudioSegmentInstance: function (audioSegment) {
    return audioSegment instanceof DeletedAudioSegment;
  },

  isDeletedSequencerNodeInstance: function (sequencerNode) {
    return sequencerNode instanceof DeletedSequencerNode;
  },

  isNonRetainedDeletedSequencerNode: function(sequencerNode){
    return sequencerNode instanceof NonRetainedDeletedSequencerNode;
  },

  createNonRetainedDeletedSequencerNode : function(audioUrl){
    const toReturn = new NonRetainedDeletedSequencerNode({});
    toReturn.setDeletedAudioUrlReference(audioUrl);
    return toReturn;
  },

  canCreateNonRetainedDeletedNodeAfter :function(sequencerNode){
    const nextNode = sequencerNode.getNext();
    const nodeIsAWordOrPauseOrAudioEvent = sequencerNode && (this.isWordSequencerNodeInstance(sequencerNode) || this.isPauseSequencerNodeInstance(sequencerNode) || this.isAudioEventSequencerNodeInstance(sequencerNode))
    const nextNodeIsAWordOrPauseOrAudioEvent = nextNode && (this.isWordSequencerNodeInstance(nextNode) || this.isPauseSequencerNodeInstance(nextNode) || this.isAudioEventSequencerNodeInstance(nextNode))
    return nodeIsAWordOrPauseOrAudioEvent && nextNodeIsAWordOrPauseOrAudioEvent && nextNode &&  !nextNode.isThereContinuityOnLeft() && this.areNodesConsecutiveAndSameFile(sequencerNode, nextNode, MAX_DURATION_OF_NON_RETAINED_DELETED_SEQUENCER_NODE_IN_MILLISEC)
  },

  canDeletedNodeBeBridge :function(deletedSequencerNode){   
    const previousNode = deletedSequencerNode.getPreviousVisibleSequencerNode();
    const nextNode = deletedSequencerNode.getNextVisibleSequencerNode();
    const previousNodeIsAWordOrPauseOrAudioEvent = previousNode && (this.isWordSequencerNodeInstance(previousNode) || this.isPauseSequencerNodeInstance(previousNode) || this.isAudioEventSequencerNodeInstance(previousNode))
    const nextNodeIsAWordOrPauseOrAudioEvent = nextNode && (this.isWordSequencerNodeInstance(nextNode) || this.isPauseSequencerNodeInstance(nextNode) || this.isAudioEventSequencerNodeInstance(nextNode))
    return previousNodeIsAWordOrPauseOrAudioEvent && nextNodeIsAWordOrPauseOrAudioEvent && this.areNodesConsecutiveAndSameFile(previousNode, nextNode) && !nextNode.isThereContinuityOnLeft()
  },

  getAudioUrlToBridgeGapForDeletedNodeIfApplicable: function(deletedSequencerNode){
    if(this.isNonRetainedDeletedSequencerNode(deletedSequencerNode)){
      return deletedSequencerNode.getDeletedAudioUrlReference();
    }
    if(this.canDeletedNodeBeBridge(deletedSequencerNode)){
      const previousSequencerNode = deletedSequencerNode.getPreviousVisibleSequencerNode();
      const nextSequencerNode = deletedSequencerNode.getNextVisibleSequencerNode();
      const nodeToGetAudioUrlFrom = previousSequencerNode && previousSequencerNode.getAudioSegment()? previousSequencerNode: nextSequencerNode;
      return nodeToGetAudioUrlFrom.getAudioSegment().getAudioUrl();
    }
    return null;

  },

  convertTimeInSrtFormat : function (timeInMilliseconds, asVtt) {
    const padString = function  (string, length) {
      return (new Array(length + 1).join('0') + string).slice(-length)
    }
    let seconds = timeInMilliseconds / 1000
    let hours = 0
    let minutes = 0
    hours = Math.floor(seconds / 3600)
    seconds = seconds - hours * 3600
    minutes = Math.floor(seconds / 60)
    seconds = (seconds - minutes * 60).toFixed(3)
    const response =
      padString(hours, 2) +
      ':' +
      padString(minutes, 2) +
      ':' +
      (asVtt
        ? padString(seconds, 6)
        : padString(seconds, 6).replace('.', ','))
    return response
  },

  areNodesConsecutiveAndSameFile : function(previousNode, nextNode, maxGapDurationInMillisec){
    if(!maxGapDurationInMillisec){
      maxGapDurationInMillisec = MAX_DURATION_OF_NON_RETAINED_DELETED_SEQUENCER_NODE_IN_MILLISEC;
    }
    if(previousNode && nextNode && previousNode.getAudioSegment().getTranscribedAudioInstance() === nextNode.getAudioSegment().getTranscribedAudioInstance() && previousNode.getAudioSegment().getEndTime() < nextNode.getAudioSegment().getStartTime()){
      if(!maxGapDurationInMillisec || maxGapDurationInMillisec > nextNode.getAudioSegment().getStartTime() - previousNode.getAudioSegment().getEndTime()){
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
  },

  createSpeakerInfoFromSerializedData: function (serializedData) {
    const speakerInfo = new SpeakerInfo();
    speakerInfo.restoreFromSerializedData(serializedData);
    return speakerInfo;
  },

  getSpeakerInfo: function (speakerId, speakerLabel) {
    const speakerInfo = new SpeakerInfo();
    speakerInfo.setInitialSpeakerLabel(speakerLabel);
    speakerInfo.setSpeakerId(speakerId);
    return speakerInfo;
  },

   writeCsv: function(data) {
    const headers = Object.keys(data[0]);
    const csv = [headers.join(',')].concat(
      data.map(row => 
        headers.map(header => {
          const cell = row[header];
          // Check if the cell is undefined or null
          if (cell === undefined || cell === null) {
            return '""'; // Output empty string in CSV
          }
          // Check if the cell is an array and convert it to a JSON string if it is
          if (Array.isArray(cell)) {
            return `"${JSON.stringify(cell).replace(/"/g, '""')}"`; // Escape double quotes
          }
          // Convert other types to string and escape double quotes
          return `"${cell.toString().replace(/"/g, '""')}"`;
        }).join(',')
      )
    ).join('\n');
    return csv;
  },

  createContextMenu: function (menuInfoArray, menuElTrigger, appendToEl, placement, transparentTheme) {
    const compliledContextMenuDefaultTemplate = _.template(SequencerContextMenuDefaultTemplate);
    const contextMenuContent = compliledContextMenuDefaultTemplate({
      menuInfoArray,
    });
    if(window.isMobileBrowser){
      //const bottomSheet = Utils.getInstance().createBottomSheet(this._sequencerNodeInspectorSectionController.getView().$el.get(0), resolve, this._onPopupClosed.bind(this));
      const bottomSheet = this.createBottomSheet(contextMenuContent, function(){}, function(){}, transparentTheme);
      bottomSheet.activate();
      const bottomSheet$el = $(bottomSheet.el);
      menuInfoArray.map((menuInfo) => {
        if (menuInfo.id && menuInfo.onClick) {
          bottomSheet$el.on("click", `#${menuInfo.id}`, menuInfo.onClick);
        }
      });
      bottomSheet.dateCreated = new Date();
      bottomSheet.destroy = function(){
        bottomSheet.deactivate();
      }
      
      return bottomSheet;
    }else{
        const contextMenuCreated = TippyJS(menuElTrigger, {
          allowHTML: true,
          arrow: true,
          placement: placement || "auto",
          animation: null,
          appendTo: appendToEl,
          popperOptions: { strategy: "fixed"},
          showOnCreate: true,
          content: contextMenuContent,
          interactive: true,
          ignoreAttributes: true,
          theme: "light",
          onHidden: function (instance) {
            instance.destroy();
          },
          trigger: "click",
          hideOnClick: false,
          sticky: true,
          plugins: [sticky],
        });
        const contextMenu$el = $(contextMenuCreated.popper);
        menuInfoArray.map((menuInfo) => {
          if (menuInfo.id && menuInfo.onClick) {
            contextMenu$el.on("click", `#${menuInfo.id}`, menuInfo.onClick);
          }
        });
        contextMenuCreated.dateCreated = new Date();
        return contextMenuCreated;
    }
  },

  createMenuInfoItem: function (id, label, onClick, icon) {
    return { id, label, icon, onClick };
  },

  getWordAudioSegment: function (
    audioUrl,
    startTime,
    endTime,
    content,
    confidence,
    transcribedAudioInstance,
    initialStartTime,
    initialEndTime,
    initialContent,
    speakerInfo,
  ) {
    return new WordAudioSegment({
      audioUrl: audioUrl,
      startTime: startTime,
      endTime: endTime,
      content: content,
      confidence: confidence,
      transcribedAudioInstance: transcribedAudioInstance,
      initialStartTime: initialStartTime,
      initialEndTime: initialEndTime,
      initialContent: initialContent,
      speakerInfo: speakerInfo,
    });
  },

  getAudioEventAudioSegment: function (
    audioUrl,
    startTime,
    endTime,
    content,
    confidence,
    transcribedAudioInstance,
    initialStartTime,
    initialEndTime,
    initialContent,
    speakerInfo,
  ) {
    return new AudioEventAudioSegment({
      audioUrl: audioUrl,
      startTime: startTime,
      endTime: endTime,
      content: content,
      confidence: confidence,
      transcribedAudioInstance: transcribedAudioInstance,
      initialStartTime: initialStartTime,
      initialEndTime: initialEndTime,
      initialContent: initialContent,
      speakerInfo: speakerInfo,
    });
  },

  getSerializedData: function (obj) {
    let serializedData = obj;
    if (obj && typeof propValue === "object") {
      serializedData = {};
      let jsonObj = null;
      if (obj.constructor && obj.constructor.name === "constructor") {
        //if backbone object
        if (obj.getSerializedData) {
          serializedData = obj.getSerializedData();
        } else {
          jsonObj = obj.toJSON();
          for (let propKey in jsonObj) {
            const propValue = jsonObj[propKey];
            serializedData[propKey] = this.getSerializedData(propValue);
          }
        }
      } else {
        serializedData = JSON.parse(obj.toString());
      }
    }
    return serializedData;
  },

  isWordAudioSegmentInstance: function (audioSegment) {
    return audioSegment instanceof WordAudioSegment;
  },

  isAudioEventAudioSegmentInstance: function (audioSegment) {
    return audioSegment instanceof AudioEventAudioSegment;
  },

  isWordSequencerNodeInstance: function (sequencerNode) {
    return sequencerNode instanceof WordSequencerNode;
  },

  isAudioEventSequencerNodeInstance: function (sequencerNode) {
    return sequencerNode instanceof AudioEventSequencerNode;
  },

  isStartMusicWrapSequencerNodeInstance: function (sequencerNode) {
    return sequencerNode instanceof StartMusicWrapSequencerNode;
  },

  isEndMusicWrapSequencerNodeInstance: function (sequencerNode) {
    return sequencerNode instanceof EndMusicWrapSequencerNode;
  },

  isWrapSequencerNodeInstance: function (sequencerNode) {
    return sequencerNode instanceof WrapSequencerNode;
  },

  isAudioSequencerNodeInstance: function (sequencerNode) {
    return sequencerNode instanceof AudioSequencerNode;
  },

  isVideoSequencerNodeInstance: function (sequencerNode) {
    return sequencerNode instanceof VideoSequencerNode;
  },

  isSpeakerInfoInstance: function (obj) {
    return obj instanceof SpeakerInfo;
  },

  cloneSequencerNodeArray: function () {
    /*const clones =  [];
			const originalSequencerNodeToCloneMap = {};*/
  },

  getPunctuationAudioSegment: function (
    audioUrl,
    startTime,
    endTime,
    content,
    confidence,
    transcribedAudioInstance,
    initialStartTime,
    initialEndTime,
    initialContent,
  ) {
    return new PunctuationAudioSegment({
      audioUrl: audioUrl,
      startTime: startTime,
      endTime: endTime,
      content: content,
      confidence: confidence,
      transcribedAudioInstance: transcribedAudioInstance,
      initialStartTime: initialStartTime,
      initialEndTime: initialEndTime,
      initialContent: initialContent,
    });
  },

  isPunctuationAudioSegmentInstance: function (audioSegment) {
    return audioSegment instanceof PunctuationAudioSegment;
  },

  isPunctuationSequencerNodeInstance: function (sequencerNode) {
    return sequencerNode instanceof PunctuationSequencerNode;
  },

  getUnsupportedAudioSegment: function (
    audioUrl,
    startTime,
    endTime,
    content,
    confidence,
    transcribedAudioInstance,
    initialStartTime,
    initialEndTime,
    initialContent,
  ) {
    return new UnsupportedAudioSegment({
      audioUrl: audioUrl,
      startTime: startTime,
      endTime: endTime,
      content: content,
      confidence: confidence,
      transcribedAudioInstance: transcribedAudioInstance,
      initialStartTime: initialStartTime,
      initialEndTime: initialEndTime,
      initialContent: initialContent,
    });
  },

  isUnsupportedAudioSegmentInstance: function (audioSegment) {
    return audioSegment instanceof UnsupportedAudioSegment;
  },

  getMusicAudioSegment: function (audioUrl, startTime, endTime, initialStartTime, initialEndTime) {
    return new MusicAudioSegment({
      audioUrl: audioUrl,
      startTime: startTime,
      endTime: endTime,
      initialStartTime: initialStartTime,
      initialEndTime: initialEndTime,
    });
  },

  isAncestor: function (parentElement, childElement) {
    let currentElement = childElement;
    while (currentElement !== null) {
      if (currentElement === parentElement) {
        return true;
      }
      currentElement = currentElement.parentElement;
    }
    return false;
  },

  isMusicAudioSegmentInstance: function (audioSegment) {
    return audioSegment instanceof MusicAudioSegment;
  },

  getCloudAudioSegment: function (audioUrl, startTime, endTime, initialStartTime, initialEndTime) {
    return new CloudAudioSegment({
      audioUrl: audioUrl,
      startTime: startTime,
      endTime: endTime,
      initialStartTime: initialStartTime,
      initialEndTime: initialEndTime,
    });
  },

  isCloudAudioSegmentInstance: function (audioSegment) {
    return audioSegment instanceof CloudAudioSegment;
  },

  getCloudVideoSegment: function (videoUrl, startTime, endTime, initialStartTime, initialEndTime, width, height) {
    return new CloudVideoSegment({
      width, height,
      audioUrl: videoUrl,
      videoUrl: videoUrl,
      startTime: startTime,
      endTime: endTime,
      initialStartTime: initialStartTime,
      initialEndTime: initialEndTime,
    });
  },

  isCloudVideoSegmentInstance: function (audioSegment) {
    return audioSegment instanceof CloudVideoSegment;
  },

  isUnsupportedAudioSequencerNodeInstance: function (sequencerNode) {
    return sequencerNode instanceof UnsupportedAudioSequencerNode;
  },

  getNextWordSequencerNode: function (sequencerNode) {
    let nextNode = sequencerNode.getNext();
    let nextWordSequencerNode = null;
    while (!nextWordSequencerNode && nextNode) {
      if (this.isWordSequencerNodeInstance(nextNode)) {
        nextWordSequencerNode = nextNode;
      } else {
        nextNode = nextNode.getNext();
      }
    }
    return nextWordSequencerNode;
  },

  getSequencerSearchResultItemDescription: function (searchResultArray) {
    const MAX_NUMBER_OF_WORDS_PER_RESULT_DESC = 20;
    const firstWordNode = searchResultArray[0];
    const lastWordNode = searchResultArray[searchResultArray.length - 1];
    let numberOfWordsHiglighted = 0;
    let desc = searchResultArray.reduce((acc, searchResult)=>{
        if(searchResult.getContent() && numberOfWordsHiglighted < MAX_NUMBER_OF_WORDS_PER_RESULT_DESC){
            acc = acc? acc  + " "+ searchResult.getContent().trim() : searchResult.getContent().trim();
            numberOfWordsHiglighted = numberOfWordsHiglighted + 1;
        }
        return acc;
    },  "")
    if(numberOfWordsHiglighted >= MAX_NUMBER_OF_WORDS_PER_RESULT_DESC){
        return `...<span class="bg-yellow-300 text-black whitespace-break-spaces">${desc}</span>...`;
    }else{
        let preText = "";
        const maxNumberOfWordPre = Math.round((MAX_NUMBER_OF_WORDS_PER_RESULT_DESC - numberOfWordsHiglighted)/2);
        let previousWordNode = this.getPreviousWordSequencerNode(firstWordNode);
        while(previousWordNode && preText.split(" ").length < maxNumberOfWordPre){
            if(previousWordNode.getContent()){
                preText = previousWordNode.getContent() + " " + preText;
            }
            previousWordNode = this.getPreviousWordSequencerNode(previousWordNode);
        }
        const numberOfWordsInPreText = preText.split(" ").length;
        if(previousWordNode && preText){
            preText = "..." + preText;
        }

        const maxNumberOfWordPost = MAX_NUMBER_OF_WORDS_PER_RESULT_DESC - numberOfWordsInPreText - numberOfWordsHiglighted;
        let postText = "";
        if(maxNumberOfWordPost > 0){
          
            let nextWordNode = this.getNextWordSequencerNode(lastWordNode);
            while(nextWordNode && postText.split(" ").length < maxNumberOfWordPost){
                if(nextWordNode.getContent()){
                    postText = postText + " " + nextWordNode.getContent();
                }
                nextWordNode = this.getNextWordSequencerNode(nextWordNode);
            }
            if(nextWordNode && postText){
                postText = postText + "...";
            }
        }
        return `${preText} <span class="bg-yellow-300 text-black whitespace-break-spaces">${desc}</span> ${postText}`;
    }
},

getSummaryDescriptionOfSequencerNodeArray : function(arrayOfSequencerNode){
  const sequencerNodeIds = [];
  let mediaTranscription = "";
  for(let currentNodeBeingAnalyzed of arrayOfSequencerNode){
    sequencerNodeIds.push(currentNodeBeingAnalyzed.cid);
    const nodeContent = currentNodeBeingAnalyzed?.getAudioSegment()?.getContent()?.trim();
    if(nodeContent){
      mediaTranscription =  mediaTranscription +  ` ${nodeContent}`;
    }
  }

  return {mediaTranscription, sequencerNodeIds}
},

getSummaryDescriptionOfSequencerNodes : function(firstSequencerNode, lastSequencerNode){
  const sequencerNodeIds = [];
  let mediaTranscription = "";
  let currentNodeBeingAnalyzed = firstSequencerNode;
  while(currentNodeBeingAnalyzed){
    sequencerNodeIds.push(currentNodeBeingAnalyzed.cid);
    if(lastSequencerNode && currentNodeBeingAnalyzed === lastSequencerNode){
      currentNodeBeingAnalyzed = null;
    }
    const nodeContent = currentNodeBeingAnalyzed?.getAudioSegment()?.getContent()?.trim();
    if(nodeContent){
      mediaTranscription =  mediaTranscription +  ` ${nodeContent}`;
    }
    currentNodeBeingAnalyzed = currentNodeBeingAnalyzed.getNext();
  }

  return {mediaTranscription, sequencerNodeIds}
},

flattenIdsFromVerbatimTranscriptionWithIds : function(idList, filterValidIds = false){
  if (!Array.isArray(idList)) {
    throw new Error("Input must be an array");
}

return idList.flatMap(idString => {
    if (typeof idString !== "string") return [];

    let ids = idString.split("|").map(id => id.trim());

    return filterValidIds 
        ? ids.filter(id => /^[a-zA-Z0-9]+$/.test(id)) // Keep only alphanumeric IDs
        : ids;
});
},

getVerbatimTranscriptionWithIds : function(firstSequencerNode, lastSequencerNode){
  let mediaTranscription = "";
  let blockNumber  = -1;
  let currentSpeakerLabel = undefined;
  const maxNumberOfWordsPerBlock = 150;
  let pauseAccumulated = undefined;
  let arrayOfPauseIdsAccumalated = [];
  let currentNodeBeingAnalyzed = firstSequencerNode;
  let currentNumberOfElementInBlock =  0;
  let minimumPauseInMillisecondsToDisplay =  500;
  const writePauseToMediaTranscriptionIfApplicableAndClearCounter = function(){
    if(pauseAccumulated !== undefined && pauseAccumulated >=  minimumPauseInMillisecondsToDisplay ){
      mediaTranscription =  mediaTranscription+  ` (pause=${Number.parseFloat(pauseAccumulated/1000).toFixed(2)}s)[[${arrayOfPauseIdsAccumalated.join(ID_GROUP_DELIMITER_FOR_LLM_MEDIA_TRANSCRIPTION)}]]`;
    }
    pauseAccumulated =  undefined;
    arrayOfPauseIdsAccumalated = [];
  }
  while(currentNodeBeingAnalyzed){
      if(this.isPauseSequencerNodeInstance(currentNodeBeingAnalyzed)){
        if(currentNodeBeingAnalyzed.getAudioSegment()?.getDuration() > 0){
            if(pauseAccumulated ===  undefined){
                pauseAccumulated  = 0;
            }
            pauseAccumulated = pauseAccumulated + currentNodeBeingAnalyzed.getAudioSegment()?.getDuration();
            arrayOfPauseIdsAccumalated.push(currentNodeBeingAnalyzed.cid);
        }
      }else if(this.isWordSequencerNodeInstance(currentNodeBeingAnalyzed)){
        const speakerInfo = currentNodeBeingAnalyzed.getSpeakerInfo();
        if((!speakerInfo && currentSpeakerLabel === undefined) || (currentNumberOfElementInBlock + 1 > maxNumberOfWordsPerBlock) || (speakerInfo && speakerInfo.getSpeakerLabel() && speakerInfo.getSpeakerLabel()  !== currentSpeakerLabel)){
            //no speaker set or reach the max number of Change of speaker
            currentSpeakerLabel = speakerInfo?.getSpeakerLabel()?speakerInfo.getSpeakerLabel():"Unknown";
            blockNumber =  blockNumber + 1;
            mediaTranscription = mediaTranscription + `\n[Block ${blockNumber}|${currentSpeakerLabel}]\n `; 
            
            currentNumberOfElementInBlock =  0;
        }
        currentNumberOfElementInBlock =  currentNumberOfElementInBlock + 1;
        writePauseToMediaTranscriptionIfApplicableAndClearCounter();
        mediaTranscription =  mediaTranscription+  ` ${currentNodeBeingAnalyzed.getAudioSegment().getContent().trim()}[[${currentNodeBeingAnalyzed.cid}]]`;
      }else if(currentNodeBeingAnalyzed.getAudioSegment()?.get("type") === "audio_event"){
        currentNumberOfElementInBlock =  currentNumberOfElementInBlock + 1;
        writePauseToMediaTranscriptionIfApplicableAndClearCounter()
        mediaTranscription =  mediaTranscription+  ` ${currentNodeBeingAnalyzed.getAudioSegment().getContent().trim()}[[${currentNodeBeingAnalyzed.cid}]]`;
      }else{
        writePauseToMediaTranscriptionIfApplicableAndClearCounter(); 
      }
      if(lastSequencerNode && currentNodeBeingAnalyzed === lastSequencerNode){
        currentNodeBeingAnalyzed = null;
      }
      currentNodeBeingAnalyzed = currentNodeBeingAnalyzed.getNext();
      
  }
  return mediaTranscription;
},

  getPreviousWordSequencerNode: function (sequencerNode) {
    let previousNode = sequencerNode.getPrevious();
    let previousWordSequencerNode = null;
    while (!previousWordSequencerNode && previousNode) {
      if (this.isWordSequencerNodeInstance(previousNode)) {
        previousWordSequencerNode = previousNode;
      } else {
        previousNode = previousNode.getPrevious();
      }
    }
    return previousWordSequencerNode;
  },

  getSequencerNodesToUpdateContinuityVisualCue: function (arrayOfSequencerNodes) {
    const nodeArray = [];
    const lastNode = arrayOfSequencerNodes[arrayOfSequencerNodes.length - 1];
    const firstNode = arrayOfSequencerNodes[0];
    if (firstNode) {
      nodeArray.push(firstNode);
      if (firstNode) {
        if (firstNode.getPrevious()) {
          nodeArray.push(firstNode.getPrevious());
        }
        const visibleNodePreviousToFirstNode = firstNode.getPreviousVisibleSequencerNode();
        if (visibleNodePreviousToFirstNode) {
          nodeArray.push(visibleNodePreviousToFirstNode);
        }
      }
      const previousWordSequencerNode = this.getPreviousWordSequencerNode(firstNode);
      if (previousWordSequencerNode) {
        nodeArray.push(previousWordSequencerNode);
      }
    }
    if (lastNode) {
      nodeArray.push(lastNode);
      if (lastNode) {
        if (lastNode.getNext()) {
          nodeArray.push(lastNode.getNext());
        }
        const visibleNodeNextToLastNode = lastNode.getNextVisibleSequencerNode();
        if (visibleNodeNextToLastNode) {
          nodeArray.push(visibleNodeNextToLastNode);
        }
      }
      const nextWordSequencerNode = this.getNextWordSequencerNode(lastNode);
      if (nextWordSequencerNode) {
        nodeArray.push(nextWordSequencerNode);
      }
    }
    return nodeArray;
  },

  setupTranslationForPickadateWidget: function () {
    const appUsedLanguage = window.getAppUsedLanguage();
    return new RSVP.Promise(function (resolve, reject) {
      try {
        require(["libs/pickadate/translations/" + appUsedLanguage + "_" + appUsedLanguage.toUpperCase()], function () {
          resolve();
        });
      } catch (error) {
        reject(error);
      }
    });
  },

  getJourneyOutlineItemFromCapsuleJson: function (
    dayNumber,
    locked,
    radioId,
    wasCompletelyPlayed,
    allowedToPlay,
    capsuleJson,
    canBeEdited,
    isPartOfPaidJourney,
    options,
  ) {
    if (capsuleJson) {
      const journeyOutlineItem = new JourneyOutlineItem(capsuleJson, options);
      journeyOutlineItem.setDayNumber(dayNumber);
      journeyOutlineItem.setLocked(locked);
      journeyOutlineItem.setRadioId(radioId);
      journeyOutlineItem.setCompleted(wasCompletelyPlayed);
      journeyOutlineItem.setAllowedToPlay(allowedToPlay);
      journeyOutlineItem.setCanBeEdited(canBeEdited);
      journeyOutlineItem.setIsPartOfPaidJourney(isPartOfPaidJourney);
      return journeyOutlineItem;
    } else {
      return null;
    }
  },

  getModelFromCommentJson: function (commentJson, options) {
    if (commentJson) {
      return new Comment(commentJson, options);
    } else {
      return null;
    }
  },

  getListItemModel: function (itemId, itemLabel, additionalData) {
    if (itemId && itemLabel) {
      return new ListItemModel({
        itemId: itemId,
        itemLabel: itemLabel,
        additionalData: additionalData,
      });
    } else {
      return null;
    }
  },

  getModelFromUserReviewJson: function (userReviewJson, options) {
    if (userReviewJson) {
      return new UserReview(userReviewJson, options);
    } else {
      return null;
    }
  },

  getModelFromUserJson: function (userJson, options) {
    if (userJson) {
      return new User(userJson, options);
    } else {
      return null;
    }
  },

  getDummyModelFromUserJson: function () {
    return new User();
  },

  getModelFromCustomNotificationPayload: function (customNotificationPayloadJson, options) {
    if (customNotificationPayloadJson) {
      return new CustomNotificationPayload(customNotificationPayloadJson, options);
    } else {
      return null;
    }
  },

  getLocalCategoryId: function () {
    return LOCAL_CATEGORY_ID;
  },

  isOverWifi: function () {
    return navigator.connection.type == window.Connection.WIFI;
  },

  isOverDataPlan: function () {
    return (
      navigator.connection.type == window.Connection.CELL ||
      navigator.connection.type == window.Connection.CELL_2G ||
      navigator.connection.type == window.Connection.CELL_3G ||
      navigator.connection.type == window.Connection.CELL_4G ||
      navigator.connection.type == window.Connection.ETHERNET
    );
  },

  buildTagGroupArrayFromSongArray: function (songJsonAray) {
    const genreTags = [];
    const eraTags = [];
    const genreTagIds = [];
    const eraTagIds = [];
    for (let index in songJsonAray) {
      const songJson = songJsonAray[index];
      const genreTag = this.buildGenreTagJsonFromSongJson(songJson);
      const eraTag = this.buildEraTagJsonFromSongJson(songJson);
      if (genreTag && genreTagIds.indexOf(genreTag.id) == -1) {
        genreTags.push(genreTag);
        genreTagIds.push(genreTag.id);
      }
      if (eraTag && eraTagIds.indexOf(eraTag.id) == -1) {
        eraTags.push(eraTag);
        eraTagIds.push(eraTag.id);
      }
    }
    const allTags = [];
    const genreGroup = new TagGroup(genreTags);
    genreGroup.setExclusive(GENRE_TAG_GROUP_EXCLUSIF);
    genreGroup.setId(GERNRE_TAG_GROUP_ID);
    genreGroup.setLabel({ EN: GERNRE_TAG_GROUP_NAME });
    genreGroup.setValue(GERNRE_TAG_GROUP_VALUE);
    allTags.push(genreGroup);
    const eraGroup = new TagGroup(eraTags);
    eraGroup.setExclusive(GENRE_TAG_GROUP_EXCLUSIF);
    eraGroup.setId(ERA_TAG_GROUP_ID);
    eraGroup.setLabel({ EN: ERA_TAG_GROUP_NAME });
    eraGroup.setValue(ERA_TAG_GROUP_VALUE);
    allTags.push(eraGroup);
    return allTags;
  },

  buildTagGroupFromtagGroupIdToTagsMap: function (tagGroupIdToTagsMap, tagGroupIdToTagGrouInfo) {
    const tagGroupArray = [];
    if (tagGroupIdToTagsMap) {
      for (let tagGroupId in tagGroupIdToTagsMap) {
        const tagsForGroupId = tagGroupIdToTagsMap[tagGroupId];
        if (tagsForGroupId && tagsForGroupId.length > 0) {
          const tagGroup = new TagGroup(tagsForGroupId);
          const tagGroupInfo = tagGroupIdToTagGrouInfo[tagGroupId];
          tagGroup.setId(tagGroupInfo.id);
          tagGroup.setValue(tagGroupInfo.value);
          tagGroup.setLabel(tagGroupInfo.label);
          tagGroupArray.push(tagGroup);
        }
      }
    }
    return tagGroupArray;
  },

  buildGenreTagJsonFromSongJson: function (songJson) {
    let genreTagJson = null;
    const selected = true;
    const tagGroupId = GERNRE_TAG_GROUP_ID;
    if (songJson && songJson.idifiedTopGenreId) {
      const tagId = songJson.idifiedTopGenreId;
      const tagValue = songJson.idifiedTopGenre;
      const tagLabel = { EN: songJson.idifiedTopGenre };
      genreTagJson = this.buildTag(tagId, tagValue, tagLabel, tagGroupId, selected);
    } else {
      genreTagJson = this.buildTag(
        UNDEFINED_GENRE_ID,
        UNDEFINED_GENRE_VALUE,
        { EN: UNDEFINED_GENRE_NAME },
        tagGroupId,
        selected,
      );
    }
    return genreTagJson;
  },

  buildEraTagJsonFromSongJson: function (songJson) {
    let eraTagJson = null;
    const selected = true;
    let tagGroupId;
    if (songJson && songJson.generatedReleaseYear) {
      const tagId = songJson.generatedReleaseYear;
      const tagValue = songJson.generatedReleaseYear;
      const tagLabel = { EN: songJson.generatedReleaseYear };
      tagGroupId = ERA_TAG_GROUP_ID;

      eraTagJson = this.buildTag(tagId, tagValue, tagLabel, tagGroupId, selected);
    } else {
      eraTagJson = this.buildTag(
        UNDEFINED_ERA_ID,
        UNDEFINED_ERA_VALUE,
        { EN: UNDEFINED_ERA_NAME },
        tagGroupId,
        selected,
      );
    }
    return eraTagJson;
  },

  applySelectedTags: function (songPlaylist, tagGroupsArray) {
    let genreTagGroup = null;
    let noGenreAllowed = false;
    let eraTagGroup = null;
    let noEraAllowed = false;
    let arrayFilterSongs = [];
    for (let index in tagGroupsArray) {
      const tagGroup = tagGroupsArray[index];
      if (tagGroup && tagGroup.getId() == GERNRE_TAG_GROUP_ID) {
        genreTagGroup = tagGroup;
      }
      if (tagGroup && tagGroup.getId() == ERA_TAG_GROUP_ID) {
        eraTagGroup = tagGroup;
      }
    }
    const genreFilterValues = this._getSelectedTagValues(genreTagGroup);
    if (genreFilterValues && genreFilterValues.indexOf(UNDEFINED_GENRE_VALUE) > -1) {
      noGenreAllowed = true;
    }
    const eraFilterValues = this._getSelectedTagValues(eraTagGroup);
    if (eraFilterValues && eraFilterValues.indexOf(UNDEFINED_ERA_VALUE) > -1) {
      noEraAllowed = true;
    }
    for (let index = 0; index < songPlaylist.length; index++) {
      const song = songPlaylist.at(index);
      const songJson = song.toJSON();
      const respectGenreFilter = this._isPropertyValueInArray(
        songJson,
        "idifiedTopGenre",
        genreFilterValues,
        noGenreAllowed,
      );
      const respectEraFilter = this._isPropertyValueInArray(
        songJson,
        "generatedReleaseYear",
        eraFilterValues,
        noEraAllowed,
      );
      if (respectEraFilter && respectGenreFilter) {
        arrayFilterSongs.push(song);
      }
    }
    return arrayFilterSongs;
  },

  _isPropertyValueInArray: function (obj, property, arrayValue, noValueAllowed) {
    if (obj && property && arrayValue) {
      return arrayValue.indexOf(obj[property]) > -1;
    } else {
      if (noValueAllowed && !obj[property]) {
        return true;
      }
      return false;
    }
  },

  applyAnimationCssClass: function (element$el, cssClass, animationDuration) {
    return new RSVP.Promise(function (resolve, reject) {
      try {
        if (animationDuration) {
          element$el.css("animation-duration", animationDuration);
        }
        element$el.one("animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd", function () {
          $(this).removeClass(cssClass);
          element$el.css("animation-duration", "");
          resolve();
        });
        element$el.addClass(cssClass);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  },

  buildCarousel: function (containerSelector, carousel$elArray, customParams) {
    const containerSelector$el = $(document).find(containerSelector);
    const swiperContainerId = "carouselContainer" + new Date().getTime();
    containerSelector$el.append(
      "<div id='" +
        swiperContainerId +
        "' class='swiper-container'><div class='swiper-wrapper'> </div> <div class='swiper-pagination'></div>" +
        this._buildNavigationButtons() +
        "</div>",
    );
    const carouselContainer$elWrapper = containerSelector$el.find(".swiper-wrapper");
    for (let index in carousel$elArray) {
      const carousel$el = carousel$elArray[index];
      const carouselItemId = "carouselItem_" + new Date().getTime();
      const carouselItem$elWrapper = $(this._buildCarouselItemHtml(carouselItemId));
      carouselItem$elWrapper.find(".carouselItem").append(carousel$el);
      carouselContainer$elWrapper.append(carouselItem$elWrapper);
    }
    const swiperParams = {
      speed: window.isMobileBrowser ? 200 : 300,
      longSwipesRatio: window.isMobileBrowser ? 0.2 : 0.4,
      watchSlidesProgress: true,
      preloadImages: false,
      watchSlidesVisibility: true,
      slidesPerView: 1,
      centeredSlides: true,
      paginationHide: false,
      slideToClickedSlide: true,
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazy: {
        loadPrevNext: true,
      },
      pagination: containerSelector$el.find(".swiper-pagination").get(0),
      paginationClickable: true,
    };
    if (window.device && window.device.platform == "browser") {
      //swiperParams.nextButton = '.swiper-button-next';
      //swiperParams.prevButton = '.swiper-button-prev';
      swiperParams.keyboardControl = true;
      swiperParams.mousewheelControl = false;
      swiperParams.mousewheel = {
        enabled: true,
      };
      swiperParams.keyboard = {
        enabled: true,
        onlyInViewport: false,
      };
    }
    if (customParams) {
      for (let key in customParams) {
        swiperParams[key] = customParams[key];
      }
    }
    const carouselObj = new Swiper(containerSelector$el.find("#" + swiperContainerId).get(0), swiperParams);
    return carouselObj;
  },

  _buildCarouselItemHtml: function (chartId) {
    return (
      "<div class='slideItemWrapper swiper-slide' style='width:100%;'><div class='carouselItem' id='" +
      chartId +
      "' ></div></div>"
    );
  },

  _buildNavigationButtons: function () {
    if (window.device && window.device.platform != "browser") {
      return "";
    } else {
      return ""; // '<div class="swiper-button-next ion-ios7-arrow-right"></div> <div class="swiper-button-prev ion-ios7-arrow-left"></div>';
    }
  },

  setJQMSelectFieldValue: function (select$el, value) {
    select$el.val(value).attr("selected", true).siblings("option").removeAttr("selected");
    select$el.selectmenu().selectmenu("refresh", true);
  },

  sortTrebbleAudioModelArray: function (trebbleAudioModelArray) {
    if (trebbleAudioModelArray) {
      trebbleAudioModelArray.sort(function (trebbleAudioA, trebbleAudioB) {
        let audioTitleA = "";
        let audioTitleB = "";
        if (trebbleAudioA && trebbleAudioA.getTitle()) {
          audioTitleA = trebbleAudioA.getTitle();
        }
        if (trebbleAudioB && trebbleAudioB.getTitle()) {
          audioTitleB = trebbleAudioB.getTitle();
        }
        return audioTitleA.localeCompare(audioTitleB);
      });
    }
    return trebbleAudioModelArray;
  },

  getCapsuleCategoryListItemArray: function () {
    const categoryListItemArray = [];
    for (let index in CAPSULE_CATEGORIES) {
      const category = CAPSULE_CATEGORIES[index];
      categoryListItemArray.push(this.getListItemModel(category, window.getI18n(ti18n, category)));
    }
    return categoryListItemArray;
  },

  getPossibleJourneyCostInUSDListItemArray: function () {
    const journeyCostInUSDListItemArray = [];
    for (let index in POSSIBLE_JOURNEY_COST_IN_USD) {
      const cost_in_usd = POSSIBLE_JOURNEY_COST_IN_USD[index];
      journeyCostInUSDListItemArray.push(this.getListItemModel(cost_in_usd, cost_in_usd + " USD"));
    }
    return journeyCostInUSDListItemArray;
  },

  getBackgroundMusicCategoryLabel: function (backgroundMusicCategoryId) {
    const categoryLabel = window.getI18n(ti18n, backgroundMusicCategoryId);
    if (categoryLabel) {
      return categoryLabel;
    } else {
      return backgroundMusicCategoryId;
    }
  },

  getLanguageListItemArray: function () {
    const languageListItemArray = [];
    for (let languageCode in GOOGLE_CLOUD_LANGUAGE_COUDE_TO_GOOGLE_CLOUD_LANGUAGES_INFO_MAP) {
      const languageInfo = GOOGLE_CLOUD_LANGUAGE_COUDE_TO_GOOGLE_CLOUD_LANGUAGES_INFO_MAP[languageCode];
      languageListItemArray.push(this.getListItemModel(languageCode, languageInfo.languageLocalizedName));
    }
    return languageListItemArray;
  },

  getJourneyVisibiltyListItemArray: function () {
    const journeyVisibiltyListItemArray = [];
    for (let visibilityTypeId in RADIO_VISIBILITY) {
      const visibilityTypeLabel = window.getI18n(ti18n, visibilityTypeId);
      journeyVisibiltyListItemArray.push(this.getListItemModel(visibilityTypeId, visibilityTypeLabel));
    }
    return journeyVisibiltyListItemArray;
  },

  getLanguageCodeToLanguageInfoMap: function () {
    return GOOGLE_CLOUD_LANGUAGE_COUDE_TO_GOOGLE_CLOUD_LANGUAGES_INFO_MAP;
  },

  getGenericLanguageListItemForSpecificLanguage: function (languageCode, languageLocalizedName) {
    const languageListModel = this.getListItemModel(languageCode, languageLocalizedName);
    const genericLanguageListItemArray = this.getGenericLanguagesFromListItemArray([languageListModel], true);
    return genericLanguageListItemArray && genericLanguageListItemArray.length > 0
      ? genericLanguageListItemArray[0]
      : null;
  },

  getGenericLanguagesFromListItemArray: function (listItemArray, groupLanguageEvenIfThereIsOnlyOne) {
    const genericLanguageIdToNumberOfLanguagesMap = {};
    const genericLanguageIdToLanguageLabelMap = {};
    for (let i = 0; i < listItemArray.length; i++) {
      let languageItem = listItemArray[i];
      let genericLanguageId = this.getGenericLanguageIdFromLanguageId(languageItem.getId());
      let genericLanguageLabel = this.getGenericLanguageLabelFromLanguageLabel(languageItem.getLabel());
      if (!genericLanguageIdToNumberOfLanguagesMap[genericLanguageId]) {
        genericLanguageIdToNumberOfLanguagesMap[genericLanguageId] = 1;
        genericLanguageIdToLanguageLabelMap[genericLanguageId] =
          genericLanguageLabel + " (" + window.getI18n(ti18n, "ALL") + ")";
      } else {
        genericLanguageIdToNumberOfLanguagesMap[genericLanguageId] =
          genericLanguageIdToNumberOfLanguagesMap[genericLanguageId] + 1;
      }
    }
    const genericListItemArray = [];
    for (let genericLanguageId in genericLanguageIdToNumberOfLanguagesMap) {
      let numberOfLanguages = genericLanguageIdToNumberOfLanguagesMap[genericLanguageId];
      if (groupLanguageEvenIfThereIsOnlyOne || numberOfLanguages > 1) {
        genericListItemArray.push(
          new ListItemModel({
            itemId: genericLanguageId,
            itemLabel: genericLanguageIdToLanguageLabelMap[genericLanguageId],
          }),
        );
      }
    }
    return genericListItemArray;
  },

  getGenericLanguageIdFromLanguageId: function (languageId) {
    return languageId.substr(0, languageId.indexOf("-"));
  },

  getGenericLanguageLabelFromLanguageLabel: function (languageLabel) {
    return languageLabel.substr(0, languageLabel.indexOf("(")).trim();
  },

  getDefaultLanguageCode: function () {
    return DEFAULT_LANGUAGE_CODE;
  },

  isBrowserLanguageAvailableInLanguageList: function () {
    return GOOGLE_CLOUD_LANGUAGE_COUDE_TO_GOOGLE_CLOUD_LANGUAGES_INFO_MAP[navigator.language] ? true : false;
  },

  mergeObjects: function (obj1, obj2) {
    const obj3 = {};
    for (let attrname in obj1) {
      obj3[attrname] = obj1[attrname];
    }
    for (let attrname in obj2) {
      obj3[attrname] = obj2[attrname];
    }
    return obj3;
  },

  getAllUserFeaturesFromCompleteUserInfo: function (userInfo) {
    if (userInfo) {
      const alacarteFeatures = userInfo.alacarteFeatures;
      const premiumFeatures = userInfo.premiumFeatures;
      const experimentalFeatures = userInfo.experimentalFeatures;
      const enabledFeatures = RolloutHelper.getInstance().getFeaturesEnabledMap();
      let allUserFeatures = null;
      if (userInfo) {
        allUserFeatures = {};
        allUserFeatures.accessCode = userInfo.accessCode;
        allUserFeatures.creationDate = userInfo.creationDate;
        allUserFeatures.profileType = userInfo.profileType;
        if (alacarteFeatures) {
          for (let key in alacarteFeatures) {
            allUserFeatures[key] = alacarteFeatures[key];
          }
        }
        if (premiumFeatures) {
          for (let key in premiumFeatures) {
            allUserFeatures[key] = premiumFeatures[key];
          }
        }
        if (experimentalFeatures) {
          for (let key in experimentalFeatures) {
            allUserFeatures[key] = experimentalFeatures[key];
          }
        }
        if (enabledFeatures) {
          for (let key in enabledFeatures) {
            allUserFeatures[key] = enabledFeatures[key];
          }
        }
      }
      return allUserFeatures;
    } else {
      return null;
    }
  },

  getTimezoneListItemArray: function () {
    const timezoneListItemArray = [];
    const timezoneNames = momentTimezone.names();
    for (let index in timezoneNames) {
      const timezone = timezoneNames[index];
      timezoneListItemArray.push(this.getListItemModel(timezone, timezone));
    }
    return timezoneListItemArray;
  },

  getSupportedTranscribedLanguage: function () {
    const languageListItemArray = [];

    for (let languageId in SUPPORTED_TRANSCRIBE_LANGUAGE_CODE_TO_LANGUAGE_LABEL) {
      const languageLabel = SUPPORTED_TRANSCRIBE_LANGUAGE_CODE_TO_LANGUAGE_LABEL[languageId];
      languageListItemArray.push(this.getListItemModel(languageId, languageLabel));
    }
    return languageListItemArray;
  },

  getLanguageLabelByLanguageCode: function (languageCode) {
    return SUPPORTED_TRANSCRIBE_LANGUAGE_CODE_TO_LANGUAGE_LABEL[languageCode];
  },

  getDefaultSelectedCapsuleCategoryId: function () {
    return DEFAULT_SELECTED_CAPSULE_CATEGORY_ID;
  },

  _getSelectedTagValues: function (tagGroup) {
    const selectedTagValues = [];
    if (tagGroup) {
      for (let index = 0; index < tagGroup.length; index++) {
        const tag = tagGroup.at(index);
        if (tag.get("selected")) {
          selectedTagValues.push(tag.get("value"));
        }
      }
    }
    return selectedTagValues;
  },

  buildTag: function (id, value, label, tagGroupId, selected) {
    const tag = {};
    tag.id = id;
    tag.tagGroupId;
    tag.label = label;
    tag.value = value;
    tag.selected = selected;
    return tag;
  },

  isInternetConnectionAvailable: function () {
    return navigator.connection.type != window.Connection.NONE;
  },

  getModelFromAlbumJson: function (albumJson) {
    if (albumJson) {
      return new Album(albumJson);
    } else {
      return null;
    }
  },

  getModelFromArtistJson: function (artistJson) {
    if (artistJson) {
      return new Artist(artistJson);
    } else {
      return null;
    }
  },

  fallbackCopyTextToClipboard: function (text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      const msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  },

  copyTextToClipboard: function (text) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      },
    );
  },

  cloneJson: function (obj) {
    let copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.cloneJson(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (const attr in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, attr)) {
          copy[attr] = this.cloneJson(obj[attr]);
        }
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  },

  buildTextToDisplayFromSocialInfoJson: function (socialSongInfoJson) {
    let text = "";
    let agoTimeReadableText = "";
    let usernameText = "";

    if (socialSongInfoJson) {
      if (socialSongInfoJson.time) {
        //const agoTimeInMilliseconds = (new Date()).getTime() - (new Date( socialSongInfoJson.time)).getTime();
        agoTimeReadableText = moment(socialSongInfoJson.time).fromNow(); //this.convertMillisecondsInReadableTime(agoTimeInMilliseconds);
      }

      if (socialSongInfoJson.user) {
        usernameText = this.getHtmlStringForHighlight(socialSongInfoJson.user.username);
      }

      if (socialSongInfoJson.reason) {
        if (
          socialSongInfoJson.reason == "SONG_ADDED_TO_LIBRARY" ||
          socialSongInfoJson.reason == "FIRST_SYNC_SONG_ADDED_TO_LIBRARY"
        ) {
          text = "Song was added";
          if (agoTimeReadableText != "") {
            text = text + " " + agoTimeReadableText; //+ " ago";
          }
          if (usernameText != "") {
            text = text + " by " + usernameText + "";
          }
        }
        if (socialSongInfoJson.reason == "SONG_INFO_IN_LIBRARY_UPDATED") {
          if (usernameText != "") {
            text = "" + usernameText + " updated information of this song";
            if (agoTimeReadableText != "") {
              text = text + " " + agoTimeReadableText; //+ " ago";
            }
          }
        }

        if (socialSongInfoJson.reason == "PLAY") {
          if (usernameText != "") {
            text = "" + usernameText + " played this song";
            if (agoTimeReadableText != "") {
              text = text + " " + agoTimeReadableText; //+ " ago";
            }
          }
        }

        if (socialSongInfoJson.reason == "LOOP") {
          if (usernameText != "") {
            text = "" + usernameText + " played this song on loop";
            if (agoTimeReadableText != "") {
              text = text + " " + agoTimeReadableText; //+ " ago";
            }
          }
        }
      }
    }
    return text;
  },
  getHtmlStringForHighlight: function (textToHightlight) {
    return "<span class='highlight'>@" + textToHightlight + "</span>";
  },

  stripHTMLTags: function (strInputCode) {
    return strInputCode.replace(/<\/?[^>]+(>|$)/g, "");
  },

  convertMillisecondsInReadableTime: function (milliseconds) {
    let x = Math.floor(milliseconds / 1000);
    const seconds = Math.floor(x % 60);
    x /= 60;
    const minutes = Math.floor(x % 60);
    x /= 60;
    const hours = Math.floor(x % 24);
    x /= 24;
    const days = Math.floor(x);
    if (days && days != 0) {
      if (days == 1) {
        return days.toString() + " day";
      } else {
        return days.toString() + " days";
      }
    }
    if (hours && hours != 0) {
      if (hours == 1) {
        return hours.toString() + " hour";
      } else {
        return hours.toString() + " hours";
      }
    }
    if (minutes && minutes != 0) {
      if (minutes == 1) {
        return minutes.toString() + " minute";
      } else {
        return minutes.toString() + " minutes";
      }
    }
    if (seconds && seconds != 0) {
      if (seconds == 1) {
        return seconds.toString() + " second";
      } else {
        return seconds.toString() + " seconds";
      }
    }
    return "0 second";
  },

  convertMillisecondsToTimeString: function (milliseconds) {
    const seconds = Math.floor(milliseconds / 1000);

    const hours = Math.floor(seconds / 3600);
    const remainingSecondsAfterHours = seconds % 3600;

    const minutes = Math.floor(remainingSecondsAfterHours / 60);
    const remainingSeconds = remainingSecondsAfterHours % 60;

    let result = "";

    if (hours > 0) {
      result += `${hours}h`;
    }

    if (minutes > 0) {
      result += `${minutes}m`;
    }

    if (remainingSeconds > 0 || result === "") {
      result += `${remainingSeconds}s`;
    }

    return result || "0s"; // Default to '0s' if duration is 0.
  },

  generateRandomId: function () {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9
    // characters
    // after the decimal.
    return "_" + Math.random().toString(36).substr(2, 9);
  },

  formatDateInAReadableFomat: function (inputDate) {
    const currentDate = moment();
    const twentyFourHoursAgo = moment().subtract(24, "hours");
    if (moment(inputDate).isAfter(twentyFourHoursAgo)) {
      return moment(inputDate).format("h:mm A"); // Show time
    } else if (moment(inputDate).isSame(currentDate, "year")) {
      return moment(inputDate).format("MMMM D"); // Show month and date
    } else {
      return moment(inputDate).format("MM/DD/YYYY"); // Show month, year, and date
    }
  },

  isLocalMediaFileUri: function (uri) {
    if (!uri) {
      return false;
    } else {
      if (typeof uri === "string" && (uri.indexOf("/") == 0 || uri.indexOf("ipod-library://") == 0)) {
        return true;
      } else {
        return false;
      }
    }
  },

  isHttpOrHttpsUrl: function (uri) {
    if (!uri) {
      return false;
    } else {
      if ((typeof uri === "string" && uri.indexOf("http://") == 0) || uri.indexOf("https://") == 0) {
        return true;
      } else {
        return false;
      }
    }
  },

  isSoundcloudUri: function (uri) {
    if (!uri) {
      return false;
    } else {
      if (typeof uri === "string" && uri.indexOf(SOUNDCLOUD_URI_PREFIX) == 0) {
        return true;
      } else {
        return false;
      }
    }
  },

  isSpotifyUri: function (uri) {
    if (!uri) {
      return false;
    } else {
      if (typeof uri === "string" && uri.indexOf(SPOTIFY_URI_PREFIX) == 0) {
        return true;
      } else {
        return false;
      }
    }
  },

  isEpidemicUri: function (uri) {
    if (!uri) {
      return false;
    } else {
      if (typeof uri === "string" && uri.indexOf(EPIDEMIC_URI_PREFIX) == 0) {
        return true;
      } else {
        return false;
      }
    }
  },

  isCapsuleUri: function (uri) {
    return this._isUriStartingWithPrefix(uri, CAPSULE_URI_PREFIX);
  },

  isServerCapsuleObjectWithId: function (capsuleServerObj, id) {
    if (capsuleServerObj && capsuleServerObj._hiddedDBData) {
      return capsuleServerObj._hiddedDBData["capsuleRefUID"] == id;
    }
    return false;
  },

  isAudioObjHasCapsuleRef: function (audioObj) {
    if (audioObj && audioObj._hiddedDBData) {
      return !!audioObj._hiddedDBData["capsuleRefUID"];
    }
    return false;
  },

  getCapsuleReferenceFromAudioObj: function (audioObj) {
    if (audioObj && audioObj._hiddedDBData) {
      return audioObj._hiddedDBData["capsuleRefUID"];
    }
    return null;
  },

  isServerSongObjectWithId: function (capsuleServerObj, id) {
    if (capsuleServerObj && capsuleServerObj._hiddedDBData) {
      return capsuleServerObj._hiddedDBData["songRefUID"] == id;
    }
    return false;
  },

  isAudioObjHasSongRef: function (audioObj) {
    if (audioObj && audioObj._hiddedDBData) {
      return !!audioObj._hiddedDBData["songRefUID"];
    }
    return false;
  },

  getSongReferenceFromAudioObj: function (audioObj) {
    if (audioObj && audioObj._hiddedDBData) {
      return audioObj._hiddedDBData["songRefUID"];
    }
    return null;
  },

  canCommentsBeRetrieveFromAudioObjJson: function (audioObjJson) {
    return this.isCapsuleUri(audioObjJson.uri) && this.getCapsuleReferenceFromAudioObj(audioObjJson); // || (this.isSongUri(audioObjJson.uri) && this.getSongReferenceFromAudioObj(audioObjJson));
  },

  canAudioInfoBeRetrieveFromAudioObjJson: function (audioObjJson) {
    return this.isCapsuleUri(audioObjJson.uri) && this.getCapsuleReferenceFromAudioObj(audioObjJson); //|| (this.isSongUri(audioObjJson.uri) && this.getSongReferenceFromAudioObj(audioObjJson));
  },

  isSongUri: function (uri) {
    return this._isUriStartingWithPrefix(uri, SONG_URI_PREFIX);
  },

  _isUriStartingWithPrefix: function (uri, prefix) {
    if (!uri) {
      return false;
    } else {
      if (typeof uri === "string" && uri.indexOf(prefix) == 0) {
        return true;
      } else {
        return false;
      }
    }
  },

  isJingleUri: function (uri) {
    return this._isUriStartingWithPrefix(uri, JINGLE_URI_PREFIX);
  },

  isGreaterUri: function (uri) {
    return this._isUriStartingWithPrefix(uri, GREATER_URI_PREFIX);
  },

  isIntroUri: function (uri) {
    return this._isUriStartingWithPrefix(uri, INTRO_URI_PREFIX);
  },

  isOutroUri: function (uri) {
    return this._isUriStartingWithPrefix(uri, OUTRO_URI_PREFIX);
  },

  isEarconUri: function (uri) {
    return this._isUriStartingWithPrefix(uri, EARCON_URI_PREFIX);
  },

  isTrebbleGeneratedSpeechUri: function (uri) {
    return this._isUriStartingWithPrefix(uri, TREBBLE_GENERATED_SPEECH_URI_PREFIX);
  },

  getSoundcloudTrackUriFromTrebbleSongUri: function (uri) {
    if (uri) {
      const soundcloudPrefixAndDelimiter = SOUNDCLOUD_URI_PREFIX + URI_DELIMITER;
      return uri.substring(soundcloudPrefixAndDelimiter.length);
    } else {
      return null;
    }
  },

  getEpidemicTrackIdFromTrebbleSongUri: function (uri) {
    if (uri) {
      const epidemicPrefixAndDelimiter = EPIDEMIC_URI_PREFIX + URI_DELIMITER;
      return uri.substring(epidemicPrefixAndDelimiter.length);
    } else {
      return null;
    }
  },

  getTrebbleSongUriFromEpidemicTrackId: function (trackId) {
    return EPIDEMIC_URI_PREFIX + URI_DELIMITER + trackId;
  },

  isYoutubeUri: function (uri) {
    if (!uri) {
      return false;
    } else {
      if (typeof uri === "string" && uri.indexOf(YOUTUBE_URI_PREFIX) == 0) {
        return true;
      } else {
        return false;
      }
    }
  },

  getYoutubeTrackUriFromTrebbleSongUri: function (uri) {
    if (uri) {
      const youtubePrefixAndDelimiter = YOUTUBE_URI_PREFIX + URI_DELIMITER;
      return uri.substring(youtubePrefixAndDelimiter.length);
    } else {
      return null;
    }
  },

  isSuggestedUriAvaliable: function (songModel) {
    if (songModel && songModel.get("suggestedUri")) {
      return true;
    } else {
      return false;
    }
  },

  setSuggestedUri: function (songModel, suggestedUri) {
    if (songModel && suggestedUri) {
      songModel.set("suggestedUri", suggestedUri, { silent: true });
    }
  },

  getSuggestedUri: function (songModel) {
    if (songModel) {
      return songModel.get("suggestedUri");
    }
  },

  setSuggestedExternalSongPage: function (songModel, suggestedSongPageUrl) {
    if (songModel && suggestedSongPageUrl) {
      songModel.set("suggestedExternalSongPage", suggestedSongPageUrl, {
        silent: true,
      });
    }
  },

  getSuggestedExternalSongPage: function (songModel) {
    if (songModel) {
      return songModel.get("suggestedExternalSongPage");
    }
  },

  isPrefetchedVideoUrlAvaliable: function (songModel) {
    if (songModel && songModel.get("prefetchedDirectVideoUrl")) {
      const timestamp = songModel.get("prefetchedDirectVideoUrlTimestamp");
      if (
        timestamp &&
        new Date().getTime() - timestamp < window.trebble.config.prefetchNextSongUrlExpirationTimeInMilli
      ) {
        return true;
      } else {
        songModel.set("prefetchedDirectVideoUrl", null, { silent: true });
        songModel.set("prefetchedDirectVideoUrlTimestamp", null, {
          silent: true,
        });
        return false;
      }
    } else {
      return false;
    }
  },

  setPrefetchedVideoUrl: function (songModel, prefetchedDirectVideoUrl) {
    if (songModel && prefetchedDirectVideoUrl) {
      songModel.set("prefetchedDirectVideoUrl", prefetchedDirectVideoUrl, {
        silent: true,
      });
      songModel.set("prefetchedDirectVideoUrlTimestamp", new Date().getTime(), {
        silent: true,
      });
    }
  },

  getPrefetchedVideoUrl: function (songModel) {
    if (songModel && songModel.get("prefetchedDirectVideoUrl")) {
      const timestamp = songModel.get("prefetchedDirectVideoUrlTimestamp");
      if (
        timestamp &&
        new Date().getTime() - timestamp < window.trebble.config.prefetchNextSongUrlExpirationTimeInMilli
      ) {
        return songModel.get("prefetchedDirectVideoUrl");
      } else {
        songModel.set("prefetchedDirectVideoUrl", null, { silent: true });
        songModel.set("prefetchedDirectVideoUrlTimestamp", null, {
          silent: true,
        });
        return null;
      }
    } else {
      return null;
    }
  },

  triggerShortVibration: function () {
    if (navigator && navigator.vibrate) {
      navigator.vibrate(100);
    }
  },

  getUrlRegularExpression: function (opts) {
    opts = Object.assign({ strict: true }, opts);

    const protocol = `(?:(?:[a-z]+:)?//)${opts.strict ? "" : "?"}`;
    const auth = "(?:\\S+(?::\\S*)?@)?";

    const host = "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)";
    const domain = "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*";

    const port = "(?::\\d{2,5})?";
    const path = '(?:[/?#][^\\s"]*)?';
    const regex = `(?:${protocol}|www\\.)${auth}(?:localhost|${host}${domain})${port}${path}`;

    return opts.exact ? new RegExp(`(?:^${regex}$)`, "i") : new RegExp(regex, "ig");
  },

  getUrlsFromString: function (text) {
    text = text ? text : "";
    //const expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    const expression = this.getUrlRegularExpression({}); ///(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/;
    //const expression =   /((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)(#[\w-]+)*/i
    const urlPattern = new RegExp(expression);
    const matches = text.match(urlPattern);
    //return matches && matches.length > 0?matches.slice(0,1): null;
    return matches;
  },

  replaceUrlsByLinksInString: function (text) {
    const urls = this.getUrlsFromString(text);
    if (urls && urls.length > 0) {
      for (let i = 0; i < urls.length; i++) {
        const url = urls[i];
        text = text.replace(
          url,
          "<a href='" + this.addHttpsToURLIfApplicable(url) + "' target='_blank'>" + url + "</a>",
        );
      }
    }
    return text;
  },

  showListPicker: function (title, keyToLabelOption, selectedValue, okButton, cancelButton) {
    const config = {};
    config.title = title;
    config.items = keyToLabelOption;
    config.selectedValue = selectedValue;
    config.doneButtonLabel = okButton;
    config.cancelButtonLabel = cancelButton;
    return new RSVP.Promise(function (resolve, reject) {
      window.plugins.listpicker.showPicker(
        config,
        function (selectedItem) {
          resolve(selectedItem);
        },
        function (error) {
          if (error && error != "Error") {
            reject(error);
          } else {
            resolve();
          }
        },
      );
    });
  },

  showErrorPopup: function (error, title, buttonName) {
    title = title ? title : "Error";
    const errorMessage = typeof error === "string" ? error : error.message;
    buttonName = buttonName ? buttonName : "OK";
    return new RSVP.Promise(function (resolve) {
      window.alertErrorMessage(errorMessage, resolve, title, buttonName);
    });
  },

  getErrorMessageFromObject: function (error) {
    if (error) {
      if (typeof error === "string") {
        return error;
      } else {
        if (error && error.message && typeof error.message === "string") {
          return error.message;
        }
      }
    }
    return null;
  },

  addSongsToGenreArray: function (songsArray, genreNameToSongFlatMap, levelToGenreDetailsMap, genreIdToGenreInfo) {
    if (!genreNameToSongFlatMap) {
      genreNameToSongFlatMap = {};
    }

    if (!levelToGenreDetailsMap) {
      levelToGenreDetailsMap = {};
    }

    if (songsArray) {
      for (let index in songsArray) {
        const song = songsArray[index];
        this.addSongToGenreArray(song, genreNameToSongFlatMap, levelToGenreDetailsMap, genreIdToGenreInfo);
      }
    }

    const results = {};
    results.genreNameToSongFlatMap = genreNameToSongFlatMap;
    results.levelToGenreDetailsMap = levelToGenreDetailsMap;
    return results;
  },

  addSongToGenreArray: function (song, genreNameToSongFlatMap, levelToGenreDetailsMap, genreIdToGenreInfo) {
    if (song) {
      let genreHierachyLevel, existingSameLevelGenreArray;
      if (!song.idifiedGenreArray || song.idifiedGenreArray.length == 0) {
        let undefinedGenre = genreNameToSongFlatMap[UNDEFINED_GENRE_NAME];
        if (!undefinedGenre) {
          undefinedGenre = {};
          undefinedGenre.genre = UNDEFINED_GENRE_NAME;
          undefinedGenre.genreId = UNDEFINED_GENRE_ID;
          undefinedGenre.subGenresArray = [];
          //undefinedGenre.numberOfSubGenres = 0;
          undefinedGenre.songs = [];
          undefinedGenre.hierachyLevel = 0;
          undefinedGenre.parentGenreId = null;
          genreNameToSongFlatMap[UNDEFINED_GENRE_NAME] = undefinedGenre;
          genreIdToGenreInfo[UNDEFINED_GENRE_ID] = undefinedGenre;

          genreHierachyLevel = undefinedGenre.hierachyLevel;
          existingSameLevelGenreArray = levelToGenreDetailsMap[genreHierachyLevel];
          if (!existingSameLevelGenreArray) {
            existingSameLevelGenreArray = [];
            levelToGenreDetailsMap[genreHierachyLevel] = existingSameLevelGenreArray;
          }
          existingSameLevelGenreArray.push(undefinedGenre);
        }
        undefinedGenre.songs.push(song);
      } else {
        const songGenreArrayCopy = song.idifiedGenreArray.slice(0);
        //order genre from top to bottom
        songGenreArrayCopy.reverse();
        let parentGenre = null;
        for (let index in songGenreArrayCopy) {
          const songGenre = songGenreArrayCopy[index];
          const genreName = songGenre.genre;
          const existingGenreInfo = genreNameToSongFlatMap[genreName];
          if (existingGenreInfo) {
            existingGenreInfo.songs.push(song);
            parentGenre = existingGenreInfo;
          } else {
            const newDetailedHierarchicalGenreInfo = this._buildDetailedHierachicalGenreInfo(songGenre, parentGenre);
            newDetailedHierarchicalGenreInfo.songs.push(song);
            if (parentGenre) {
              parentGenre.subGenresArray.push(newDetailedHierarchicalGenreInfo);
              //parentGenre.numberOfSubGenres = parentGenre.numberOfSubGenres + 1;
            }
            genreNameToSongFlatMap[newDetailedHierarchicalGenreInfo.genre] = newDetailedHierarchicalGenreInfo;
            genreIdToGenreInfo[newDetailedHierarchicalGenreInfo.genreId] = newDetailedHierarchicalGenreInfo;
            parentGenre = newDetailedHierarchicalGenreInfo;
            genreHierachyLevel = newDetailedHierarchicalGenreInfo.hierachyLevel;
            existingSameLevelGenreArray = levelToGenreDetailsMap[genreHierachyLevel];
            if (!existingSameLevelGenreArray) {
              existingSameLevelGenreArray = [];
              levelToGenreDetailsMap[genreHierachyLevel] = existingSameLevelGenreArray;
            }
            existingSameLevelGenreArray.push(newDetailedHierarchicalGenreInfo);
          }
        }
      }
    }
  },

  getGenreIdsForSongJson: function (songJson) {
    if (songJson) {
      if (!songJson.idifiedGenreArray || songJson.idifiedGenreArray.length == 0) {
        return [UNDEFINED_GENRE_ID];
      } else {
        const genreIds = [];
        const songGenreArrayCopy = songJson.idifiedGenreArray.slice(0);
        //order genre from top to bottom
        songGenreArrayCopy.reverse();
        for (let index in songGenreArrayCopy) {
          const songGenre = songGenreArrayCopy[index];
          const genreId = songGenre.genreId;
          genreIds.push(genreId);
        }
        return genreIds;
      }
    }
  },

  _buildDetailedHierachicalGenreInfo: function (genreResumedInfo, parentGenre) {
    const detailedHierchicalGenreInfo = JSON.parse(JSON.stringify(genreResumedInfo));
    detailedHierchicalGenreInfo.subGenresArray = [];
    detailedHierchicalGenreInfo.songs = [];
    if (parentGenre) {
      detailedHierchicalGenreInfo.hierachyLevel = parentGenre.hierachyLevel + 1;
      detailedHierchicalGenreInfo.parentGenreId = parentGenre.genreId;
    } else {
      //detailedHierchicalGenreInfo.numberOfSubGenres = 0;
      detailedHierchicalGenreInfo.hierachyLevel = 0;
      detailedHierchicalGenreInfo.parentGenreId = null;
    }
    return detailedHierchicalGenreInfo;
  },

  getRandomSongArray: function (levelToGenreDetailsMap, numberOfSongsWanted) {
    const topGenreLevelArray = levelToGenreDetailsMap[0];
    return this._getRandomSongArrayRecursive(topGenreLevelArray, numberOfSongsWanted);
  },

  capitalizeFirstLetter: function (toCapitalize) {
    if (toCapitalize) {
      return toCapitalize.charAt(0).toUpperCase() + toCapitalize.slice(1);
    } else {
      return null;
    }
  },

  endsWith: function (str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  },

  buildPlaylistSummary: function (
    id,
    name,
    description,
    loaderKey,
    author,
    categoryId,
    cityId,
    artistSample,
    lastUpdateDateString,
    audioPreviewUrl,
    coverArtUrl,
    coverArtUrlBlur,
    coverArtUrlInfo,
    coverArtUrlBlurInfo,
    coverArtBase64,
    isFollowing,
    hasPurchased,
    canBeFollowed,
    canBeEdited,
    numberOfSongs,
    numberOfFollowers,
    numberOfPurchasers,
    totalNumberOfPlays,
    ownerNumberOfPlays,
    playSuccessRate,
    numberOfSongsFavorited,
    numberOfCapsules,
    numberOfSongsComments,
    genreIdToAudioPreviewUrl,
    customURL,
    timezone,
    language,
    isPaid,
    costInUSD,
  ) {
    const playlistSummary = {};
    playlistSummary.name = name;
    playlistSummary.description = description;
    playlistSummary.loaderKey = loaderKey;
    playlistSummary.author = author;
    playlistSummary.artistSample = artistSample;
    playlistSummary.categoryId = categoryId;
    playlistSummary.cityId = cityId;
    playlistSummary.lastUpdateDateString = lastUpdateDateString;
    playlistSummary.id = id;
    playlistSummary.audioPreviewUrl = audioPreviewUrl;
    playlistSummary.coverArtUrl = coverArtUrl;
    playlistSummary.coverArtUrlBlur = coverArtUrlBlur;
    playlistSummary.coverArtUrlInfo = coverArtUrlInfo;
    playlistSummary.coverArtUrlBlurInfo = coverArtUrlBlurInfo;
    playlistSummary.coverArtBase64 = coverArtBase64;
    playlistSummary.isFollowing = isFollowing;
    playlistSummary.hasPurchased = hasPurchased;
    playlistSummary.canBeFollowed = canBeFollowed;
    playlistSummary.canBeEdited = canBeEdited;
    playlistSummary.numberOfSongs = numberOfSongs;
    playlistSummary.numberOfFollowers = numberOfFollowers;
    playlistSummary.numberOfPurchasers = numberOfPurchasers;
    playlistSummary.totalNumberOfPlays = totalNumberOfPlays;
    playlistSummary.ownerNumberOfPlays = ownerNumberOfPlays;
    playlistSummary.playSuccessRate = playSuccessRate;
    playlistSummary.numberOfSongsFavorited = numberOfSongsFavorited;
    playlistSummary.numberOfCapsules = numberOfCapsules;
    playlistSummary.numberOfSongsComments = numberOfSongsComments;
    playlistSummary.genreIdToAudioPreviewUrl = genreIdToAudioPreviewUrl;
    playlistSummary.customURL = customURL;
    playlistSummary.timezone = timezone;
    playlistSummary.language = language;
    playlistSummary.isPaid = isPaid;
    playlistSummary.costInUSD = costInUSD;

    return this.buildPlaylistSummaryFromJson(playlistSummary);
  },

  buildJourneySummary: function (
    id,
    name,
    goal,
    isPaid,
    costInUSD,
    description,
    loaderKey,
    author,
    categoryId,
    cityId,
    artistSample,
    lastUpdateDateString,
    audioPreviewUrl,
    coverArtUrl,
    coverArtUrlBlur,
    coverArtUrlInfo,
    coverArtUrlBlurInfo,
    coverArtBase64,
    isFollowing,
    hasPurchased,
    canBeFollowed,
    canBeEdited,
    numberOfSongs,
    numberOfFollowers,
    numberOfPurchasers,
    totalNumberOfPlays,
    ownerNumberOfPlays,
    playSuccessRate,
    numberOfSongsFavorited,
    numberOfCapsules,
    numberOfSongsComments,
    genreIdToAudioPreviewUrl,
    customURL,
    timezone,
    language,
    journeyLengthInDays,
    averageRatings,
  ) {
    const playlistSummary = {};
    playlistSummary.name = name;
    playlistSummary.description = description;
    playlistSummary.goal = goal;
    playlistSummary.isPaid = isPaid;
    playlistSummary.costInUSD = costInUSD;
    playlistSummary.journeyLengthInDays = journeyLengthInDays;
    playlistSummary.loaderKey = loaderKey;
    playlistSummary.author = author;
    playlistSummary.artistSample = artistSample;
    playlistSummary.categoryId = categoryId;
    playlistSummary.cityId = cityId;
    playlistSummary.lastUpdateDateString = lastUpdateDateString;
    playlistSummary.id = id;
    playlistSummary.audioPreviewUrl = audioPreviewUrl;
    playlistSummary.coverArtUrl = coverArtUrl;
    playlistSummary.coverArtUrlBlur = coverArtUrlBlur;
    playlistSummary.coverArtUrlInfo = coverArtUrlInfo;
    playlistSummary.coverArtUrlBlurInfo = coverArtUrlBlurInfo;
    playlistSummary.coverArtBase64 = coverArtBase64;
    playlistSummary.isFollowing = isFollowing;
    playlistSummary.hasPurchased = hasPurchased;
    playlistSummary.canBeFollowed = canBeFollowed;
    playlistSummary.canBeEdited = canBeEdited;
    playlistSummary.numberOfSongs = numberOfSongs;
    playlistSummary.numberOfFollowers = numberOfFollowers;
    playlistSummary.numberOfPurchasers = numberOfPurchasers;
    playlistSummary.totalNumberOfPlays = totalNumberOfPlays;
    playlistSummary.ownerNumberOfPlays = ownerNumberOfPlays;
    playlistSummary.playSuccessRate = playSuccessRate;
    playlistSummary.numberOfSongsFavorited = numberOfSongsFavorited;
    playlistSummary.numberOfCapsules = numberOfCapsules;
    playlistSummary.numberOfSongsComments = numberOfSongsComments;
    playlistSummary.genreIdToAudioPreviewUrl = genreIdToAudioPreviewUrl;
    playlistSummary.customURL = customURL;
    playlistSummary.timezone = timezone;
    playlistSummary.language = language;
    playlistSummary.averageRatings = averageRatings;
    return this.buildJourneySummaryFromJson(playlistSummary);
  },

  buildPlaylistSummaryFromJson: function (playlistSummaryJson) {
    return new PlaylistSummary(playlistSummaryJson);
  },

  buildJourneySummaryFromJson: function (journeySummaryJson) {
    return new JourneySummary(journeySummaryJson);
  },

  getTestServerEndpoint: function () {
    return RolloutHelper.getFeatureVariable(
      RolloutHelper.FEATURES.CONFIRGURE_SERVER_ENDPOINT,
      RolloutHelper.FEATURES.CONFIRGURE_SERVER_ENDPOINT.variables.server_endpoint,
    );
  },

  buildPlaylistSummaryFromCapsuleInfoJson: function (capsuleInfoJson) {
    if (capsuleInfoJson) {
      const capsuleObj = this.getModelFromCapsuleJson(capsuleInfoJson);
      return this.buildPlaylistSummary(
        capsuleObj.getCapsuleId(),
        "",
        capsuleObj.getText(),
        capsuleObj.getCapsuleId(),
        capsuleObj.getCreatorInfoJson().username,
        this.getTrebbleCapsuleCategoryId(),
        null,
        capsuleObj.getCreatorInfoJson().username,
        moment(capsuleObj.getLastUpdateDate()),
        capsuleObj.getAudioUrl(),
        capsuleObj.getCreatorInfoJson().profileImageUrl,
        capsuleObj.getCreatorInfoJson().profileImageUrlBlur,
        capsuleObj.getCreatorInfoJson().profileImageUrlInfo,
        capsuleObj.getCreatorInfoJson().profileImageUrlBlurInfo,
        null,
        false,
        false,
        false,
        false,
        2,
        0,
        0,
        capsuleObj.getNumberOfPlays(),
        capsuleObj.getNumberOfPlays(),
        capsuleObj.getPlaySuccessRate(),
        0,
        1 + capsuleObj.getNumberOfCapsuleReplies(),
        capsuleObj.getNumberOfComments(),
        null,
        null,
        null,
        null,
        null,
        null,
      );
    } else {
      return null;
    }
  },

  buildPlaylistSummaryFromSongInfoJson: function (songInfoJson) {
    if (songInfoJson) {
      const songObj = this.getModelFromSongJson(songInfoJson);
      return this.buildPlaylistSummary(
        songObj.getTrebbleSongId(),
        "",
        null,
        songObj.getTrebbleSongId(),
        null,
        this.getTrebbleMusicCategoryId(),
        null,
        null,
        null,
        null,
        songObj.getAlbumArt(),
        null,
        null,
        null,
        false,
        false,
        false,
        false,
        1,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      );
    } else {
      return null;
    }
  },

  buildMyCapsuleFeedRadioSummary: function (userId) {
    if (userId) {
      return this.buildPlaylistSummary(
        userId,
        window.getI18n(ti18n, "MY_CAPSULE_FEED"),
        null,
        userId,
        null,
        this.getTrebbleCapsuleFeedCategoryId(),
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        false,
        false,
        false,
        false,
        1,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      );
    } else {
      return null;
    }
  },

  buildCategoryShortcastRadioSummary: function (categoryId) {
    if (categoryId) {
      const titlePrefix = RolloutHelper.getInstance().getFeatureVariable(
        RolloutHelper.FEATURES.CREATE_SHORTCAST_CATEGORY_RADIO,
        "playlist_title_prefix_" + window.getAppUsedLanguage(),
        window.getI18n(ti18n, "CATEGORY_RADIO_TITLE_PREFIX"),
      );
      const titleSufix = RolloutHelper.getInstance().getFeatureVariable(
        RolloutHelper.FEATURES.CREATE_SHORTCAST_CATEGORY_RADIO,
        "playlist_title_sufix_" + window.getAppUsedLanguage(),
        window.getI18n(ti18n, "CATEGORY_RADIO_TITLE_SUFIX"),
      );
      const categoryLabel = window.getI18n(ti18n, categoryId) ? window.getI18n(ti18n, categoryId) : categoryId;
      const playlistTitle =
        (titlePrefix ? titlePrefix + " " : "") + categoryLabel + (titleSufix ? " " + titleSufix : "");
      return this.buildPlaylistSummary(
        categoryId,
        playlistTitle,
        null,
        categoryId,
        null,
        categoryId,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        false,
        false,
        false,
        1,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      );
    } else {
      return null;
    }
  },

  _getRandomSongArrayRecursive: function (topGenreLevelArray, numberOfSongsWanted) {
    let randomSongArray = [];
    let totalNumberOfSongs = 0;
    const weightedGenreMap = [];
    const wl = new WeightedList(weightedGenreMap);
    for (let index in topGenreLevelArray) {
      const topLevelGenre = topGenreLevelArray[index];
      totalNumberOfSongs = totalNumberOfSongs + topLevelGenre.songs.length;
      wl.push([topLevelGenre.genreId, topLevelGenre.songs.length, topLevelGenre]);
    }

    const topGenreLevelArrayCopy = topGenreLevelArray.slice(0);

    while (topGenreLevelArrayCopy.length > 0 && randomSongArray.length < numberOfSongsWanted) {
      const numberOfSongsLeftToChoose = numberOfSongsWanted - randomSongArray.length;
      let numberOfRandomSongsToPickedForGenre = null;
      let randomGenre = null;
      if (topGenreLevelArrayCopy.length > 1) {
        const randomPick = wl.peek();
        randomGenre = randomPick.length > 0 ? randomPick[0].data : null;
        const randomGenreIndex = topGenreLevelArrayCopy.indexOf(randomGenre);
        //const randomGenreIndex = Utils.getInstance().getRandomNumber(0, topGenreLevelArrayCopy.length);
        //const randomGenre = topGenreLevelArrayCopy[randomGenreIndex];
        numberOfRandomSongsToPickedForGenre = Math.floor(
          (numberOfSongsLeftToChoose * randomGenre.songs.length) / totalNumberOfSongs,
        );
        if (numberOfRandomSongsToPickedForGenre == 0 && numberOfSongsLeftToChoose > 0) {
          //if  number of songs tp pick is zero is just means that this genre has less popular for the user
          numberOfRandomSongsToPickedForGenre = 1;
          window.log(
            "this genre " +
              randomGenre +
              "has less change to be chosen with " +
              randomGenre.songs.length +
              "songs  over" +
              totalNumberOfSongs +
              " songs",
          );
        }
        if (randomGenre.subGenresArray.length == 0) {
          randomSongArray = randomSongArray.concat(
            this.getRandomSongArrayFromSongArray(randomGenre.songs, numberOfRandomSongsToPickedForGenre),
          );
        } else {
          randomSongArray = randomSongArray.concat(
            this._getRandomSongArrayRecursive(randomGenre.subGenresArray, numberOfRandomSongsToPickedForGenre),
          );
        }
        topGenreLevelArrayCopy.splice(randomGenreIndex, 1);
      } else {
        numberOfRandomSongsToPickedForGenre = numberOfSongsLeftToChoose;
        randomGenre = topGenreLevelArrayCopy[0];
        randomSongArray = randomSongArray.concat(
          this.getRandomSongArrayFromSongArray(randomGenre.songs, numberOfRandomSongsToPickedForGenre),
        );
        topGenreLevelArrayCopy.splice(0, 1);
      }
    }
    return randomSongArray;
  },

  getRandomSongArrayFromSongArray: function (songArray, numberOfSongsWanted) {
    let randomSongArray = [];
    if (numberOfSongsWanted > songArray.length) {
      randomSongArray = songArray;
    } else {
      const songArrayCopy = songArray.slice(0);
      while (randomSongArray.length < numberOfSongsWanted) {
        const randomItemIndex = this.getRandomNumber(0, songArrayCopy.length);
        const randomSong = songArrayCopy[randomItemIndex];
        songArrayCopy.splice(randomItemIndex, 1);
        randomSongArray.push(randomSong);
      }
    }
    return randomSongArray;
  },

  getCovertArtFromJsonAlbum: function (albumJson, saveToSqlLiteDBIfImageDownload) {
    let albumId = albumJson.albumId;
    if (albumJson.doNotUseIdifiedUrl == true) {
      albumId = albumJson.preferredAlbumIdForCoverArt;
    }
    const p = CordovaHelper.getInstance().executePluginOperation(
      "MusicFileReaderPlugin",
      "getAlbumArtInfoFromImageStore",
      [albumId],
    );
    //const p = ImageStoreHelper.getInstance().getAlbumImageByImageId(albumId);
    return p.then(
      function (albumImageInfo) {
        if (albumImageInfo) {
          const albumArtBase64Stored = albumImageInfo.originalBase64Image;
          const result = {};
          result.albumArtBase64RegularSize = albumImageInfo.originalBase64Image;
          result.albumArtBase64BlurScreenSize = albumImageInfo.originalBlurBase64Image;
          if (albumArtBase64Stored) {
            return result;
          } else {
            //try to retrieve from native
            this._retrieveScaledCoverArts(albumJson, saveToSqlLiteDBIfImageDownload);
          }
        } else {
          this._retrieveScaledCoverArts(albumJson, saveToSqlLiteDBIfImageDownload);
        }
      }.bind(this),
    );
  },

  getFileExtenstionForMimeType: function (mimeType) {
    const mimeTypeTofileExtenstion = {
      "image/jpeg": "jpg",
      "image/png": "png",
      "image/bmp": "bmp",
      "audio/ogg": "oga",
      "audio/wav": "wav",
      "audio/mpeg": "mp3",
      "audio/mp3": "mp3",
      "audio/mp4": "mp4",
      "video/mp4": "mp4",
      "video/webm": "webm",
      "video/mpeg": "mpeg",
      "video/ogg": "ogg",
      "audio/aac": "aac",
      "application/json":"json",
      "text/plain":"txt",
      "text/csv":"csv"
    };

    return mimeTypeTofileExtenstion[mimeType];
  },

  getMimeTypeForFileExtenstion: function (fileExtension) {
    const fileExtenstionToMimeType = {
      jpg: "image/jpeg",
      jpeg: "image/jpeg",
      png: "image/png",
      bmp: "image/bmp",
      oga: "audio/ogg",
      wav: "audio/wav",
      mp3: "audio/mpeg",
      mp4: "video/mp4",
      webm: "video/webm",
      mpeg: "video/mpeg",
      ogg: "video/ogg",
      aac: "audio/aac",
      txt: "text/plain",
      json: "application/json",
      csv: "text/csv"
    };

    return fileExtenstionToMimeType[fileExtension];
  },

  _retrieveScaledCoverArts: function (albumJson, saveToSqlLiteDBIfImageDownload) {
    let mp3AlbumId = null;
    if (window.device.platform == "iOS") {
      mp3AlbumId = albumJson.albumId ? albumJson.albumId.toString() : "";
    } else {
      mp3AlbumId = parseInt(albumJson.albumId);
    }
    const screenWidth = window.getDocumentClienWidth();
    const screenHeight = window.getDocumentClienHeight();
    const regularCovertArtWidth = window.getDocumentClienWidth() - 10;
    const regularCovertArtHeight = window.getDocumentClienHeight() - 220;
    const thumbnailWidth = 64;
    const thumbnailHeight = 64;
    const basicOrIdifiedCoverArtUrl = albumJson.albumArtURL;
    const albumArtImages = albumJson.albumArtImages;

    const paramsArray = [
      mp3AlbumId,
      screenWidth,
      screenHeight,
      regularCovertArtWidth,
      regularCovertArtHeight,
      thumbnailWidth,
      thumbnailHeight,
      basicOrIdifiedCoverArtUrl,
      albumArtImages,
    ];
    return CordovaHelper.getInstance()
      .executePluginOperation("MusicFileReaderPlugin", "retrieveScaledCoverArtImages", paramsArray)
      .then(
        function (scaledCovertArts) {
          if (scaledCovertArts && scaledCovertArts.isIdifedCoverArt && saveToSqlLiteDBIfImageDownload) {
            CordovaHelper.getInstance().executePluginOperation(
              "MusicFileReaderPlugin",
              "saveAlbumArtInfoToImageStore",
              [
                this.albumJson.albumId,
                basicOrIdifiedCoverArtUrl,
                scaledCovertArts.albumArtBase64RegularSize,
                scaledCovertArts.albumArtBase64BlurScreenSize,
              ],
            );
          }
          return scaledCovertArts;
        }.bind({ albumJson: albumJson }),
      )
      .catch(function (error) {
        window.log(error);
        window.alertErrorMessage(JSON.stringify(error));
      });
  },

  addVolatileCounterToElement: function (element, iconClassName, counter) {
    //return RSVP.Promise(function(resolve, reject){
    //try{
    const element$el = $(element);
    if (element$el.is(":visible")) {
      const volatileCounter$el = $(
        "<span class=' volatile-counter'>" + counter + "<span class='" + iconClassName + "'></span></span>",
      );
      volatileCounter$el.on("webkitAnimationEnd oanimationend msAnimationEnd animationend", function () {
        $(this).remove();
        // resolve();
      });
      element$el.append(volatileCounter$el);
      volatileCounter$el.addClass("fadeOutUp animated");
    }
    //}catch(reject);
    //	});
  },

  getAlbumArtImagesFromSpotifyAlbumImages: function (spotifyAlbumImages) {
    if (spotifyAlbumImages && spotifyAlbumImages.length > 0) {
      let miniImage = null; //64X64 and under
      let thumbnailImage = null; //over 64X64 and  100x100(inclusive)
      let mediumImage = null; //over 100x100 and under 300x300(inclusive)
      let largeImage = null; //over 300x300 and under 640x640(inclusive)
      let originalImage = null;

      for (let index in spotifyAlbumImages) {
        const spotifyImage = spotifyAlbumImages[index];
        if (spotifyImage.width == 64) {
          miniImage = spotifyImage;
          thumbnailImage = spotifyImage;
        }
        if (spotifyImage.width == 300) {
          mediumImage = spotifyImage;
        }
        if (spotifyImage.width == 640) {
          largeImage = spotifyImage;
          originalImage = spotifyImage;
        }
      }
      return this.getTrebbleAlbumImages(miniImage, thumbnailImage, mediumImage, largeImage, originalImage);
    } else {
      return null;
    }
  },

  /*getTrebbleAlbumImagesFromImageUrl : function(imageUrl){
		  return {"mini": null, "thumbnail": null, "medium" : null, "large": null, "original": imageUrl}
	  },*/

  getTrebbleAlbumImages: function (miniImage, thumbnailImage, mediumImage, largeImage, originalImage) {
    return {
      mini: miniImage,
      thumbnail: thumbnailImage,
      medium: mediumImage,
      large: largeImage,
      original: originalImage,
    };
  },

  getTrebbleAlbumImagesFromSoundcloudImageUrl: function (imageUrl) {
    const sizeKeywords = ["t500x500", "crop", "t300x300", "large", "t67x67", "badge", "small", "tiny", "mini"];
    let usedKeyword = null;
    for (let index in sizeKeywords) {
      if (imageUrl.indexOf(sizeKeywords[index]) != -1) {
        usedKeyword = sizeKeywords[index];
        break;
      }
    }
    if (usedKeyword) {
      const miniImage = this.buildImageInfo(imageUrl.replace(usedKeyword, "t67x67"), 67, 67); //64X64 and under
      const thumbnailImage = this.buildImageInfo(imageUrl.replace(usedKeyword, "large"), 100, 100); //over 64X64 and  100x100(inclusive)
      const mediumImage = this.buildImageInfo(imageUrl.replace(usedKeyword, "t300x300"), 300, 300); //over 100x100 and under 300x300(inclusive)
      const largeImage = this.buildImageInfo(imageUrl.replace(usedKeyword, "t500x500"), 500, 500); //over 300x300 and under 640x640(inclusive)
      const originalImage = this.buildImageInfo(imageUrl.replace(usedKeyword, "t500x500"), 500, 500);
      return this.getTrebbleAlbumImages(miniImage, thumbnailImage, mediumImage, largeImage, originalImage);
    } else {
      return null;
    }
  },
  getTrebbleAlbumImagesFromImageUrl: function (imageUrl) {
    if (imageUrl) {
      const miniImage = this.buildImageInfo(imageUrl, 67, 67); //64X64 and under
      const thumbnailImage = this.buildImageInfo(imageUrl, 100, 100); //over 64X64 and  100x100(inclusive)
      const mediumImage = this.buildImageInfo(imageUrl, 300, 300); //over 100x100 and under 300x300(inclusive)
      const largeImage = this.buildImageInfo(imageUrl, 500, 500); //over 300x300 and under 640x640(inclusive)
      const originalImage = this.buildImageInfo(imageUrl, 500, 500);
      return this.getTrebbleAlbumImages(miniImage, thumbnailImage, mediumImage, largeImage, originalImage);
    } else {
      return null;
    }
  },

  getTrebbleAlbumImagesFromYoutubethumbnailsInfo: function (thumbnailsInfo) {
    if (thumbnailsInfo) {
      const miniImage = this.buildImageInfo(thumbnailsInfo["default"].url, 120, 90); //64X64 and under
      const thumbnailImage = this.buildImageInfo(thumbnailsInfo["default"].url, 120, 90); //over 64X64 and  100x100(inclusive)
      const mediumImage = this.buildImageInfo(thumbnailsInfo["medium"].url, 320, 180); //over 100x100 and under 300x300(inclusive)
      const largeImage = this.buildImageInfo(thumbnailsInfo["high"].url, 480, 360); //over 300x300 and under 640x640(inclusive)
      const originalImage = this.buildImageInfo(thumbnailsInfo["high"].url, 480, 360);
      return this.getTrebbleAlbumImages(miniImage, thumbnailImage, mediumImage, largeImage, originalImage);
    } else {
      return null;
    }
  },

  buildImageInfo: function (url, width, height) {
    return { url: url, width: width, height: height };
  },

  getResizeImageUrl: function (imageUrl, width, height, albumArtImages) {
    return window.trebble.globalHelpers.getResizeImageUrl(imageUrl, width, height, albumArtImages);
  },

  retrieveImageBlurryFromUrl: function (imageUrl, darkShadeLevel, blurLevel) {
    const screenWidth = window.getDocumentClienWidth();
    const screenHeight = window.getDocumentClienHeight();
    const regularCovertArtWidth = window.getDocumentClienWidth() - 10;
    const regularCovertArtHeight = window.getDocumentClienHeight() - 220;
    const thumbnailWidth = 64;
    const thumbnailHeight = 64;
    const paramsArray = [
      -1,
      screenWidth,
      screenHeight,
      regularCovertArtWidth,
      regularCovertArtHeight,
      thumbnailWidth,
      thumbnailHeight,
      imageUrl,
      darkShadeLevel,
      blurLevel,
    ];
    return CordovaHelper.getInstance()
      .executePluginOperation("MusicFileReaderPlugin", "retrieveScaledCoverArtImages", paramsArray)
      .then(
        function (scaledCovertArts) {
          return scaledCovertArts;
        }.bind(this),
      );
  },

  getReadyForDisplayBase64Image: function (base64Image) {
    return new RSVP.Promise(function (resolve) {
      const imageSrcData = "data:image/png;base64," + base64Image;
      const tempImg = new Image();
      tempImg.onload = function () {
        resolve(this.tempImg.src);
      }.bind({ tempImg: tempImg });
      tempImg.src = imageSrcData;
    });
  },

  preloadImageUsingUrl: function (imageUrl) {
    return new RSVP.Promise(function (resolve, reject) {
      const tempImg = new Image();
      tempImg.onload = function () {
        resolve(this.imageUrl);
      }.bind({ tempImg: tempImg, imageUrl: imageUrl });
      tempImg.onerror = function () {
        reject(window.getI18n(ti18n, "FAILED_LOADING_IMAGE"));
        window.sendErrorToRaygun(window.getI18n(ti18n, "FAILED_LOADING_IMAGE") + ". Url:" + imageUrl);
      }.bind({ tempImg: tempImg, imageUrl: imageUrl });
      tempImg.src = imageUrl;
    });
  },
});

const UtilsInstance = new Utils();
window.trebble.globalHelpers.Utils = UtilsInstance;

export default {
  getInstance: function () {
    return UtilsInstance;
  },
};
