import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerRendererNew from "models/audioEditor/SequencerRendererImpl";
import SequencerRendererOld from "models/audioEditor/SequencerRenderer";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import RSVP from "rsvp";


const Rollout = RolloutHelper.getInstance();

const ONLY_RENDER_IF_NECESSARY =  Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.render_audio_only_if_necessary, true);
const USE_NEW_RENDERING_ENGINE =  Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.use_new_audio_rendering_engine, true);
const SequencerRenderer = USE_NEW_RENDERING_ENGINE? SequencerRendererNew: SequencerRendererOld;
const SequencerRendererHelper =  Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);
        this._sequencerCidToRenderingInProgress  = {};
        this._sequencerCidToLatestSequencerRenderer = {};
        this._latestSequencerRenderer =  null;
    },

    _getLatestSequencerRenderer : function(sequencer){
        return this._sequencerCidToLatestSequencerRenderer[sequencer.getId()];
    },

    _deleteLatestSequencerRenderer: function(sequencer){
        delete this._sequencerCidToLatestSequencerRenderer[sequencer.getId()];
    },


    isRenderingInProgress : function(sequencer){
        return !!this._sequencerCidToRenderingInProgress[sequencer.getId()];
    },

    _getRenderingInProgressPromiseIfApplicable : function(sequencer){
        return this.isRenderingInProgress(sequencer)? this._sequencerCidToRenderingInProgress[sequencer.getId()]: Promise.resolve();
    },


    playLastestRenderedBuffer: function(sequencer, sequencerNodeToStartFrom, stopAfterMilliseconds, nodeToStopPlayAt, onAudioErrorPlayingHandler, videoContext, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible){
        if(this._getLatestSequencerRenderer(sequencer) && !sequencer.isTherePendingRenderingRequest()){
            this._getLatestSequencerRenderer(sequencer).playRenderedBuffer(sequencerNodeToStartFrom,stopAfterMilliseconds, nodeToStopPlayAt, onAudioErrorPlayingHandler, videoContext, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
        }
    },

    getPrepareVideoDataForClipCreation: async function(sequencer, sequencerNodeToStartFrom, stopAfterMilliseconds, nodeToStopPlayAt, onAudioErrorPlayingHandler, videoContext, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible){
        return new RSVP.Promise((function(resolve, reject){
            const customRenderFunctionToExecute = function(sequencerRenderer){
                sequencerRenderer.getPrepareVideoDataForClipCreation(sequencerNodeToStartFrom,stopAfterMilliseconds, nodeToStopPlayAt, onAudioErrorPlayingHandler, videoContext, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible).then(resolve).catch(reject);
            }
            return this._renderSequencer(sequencer, false, customRenderFunctionToExecute, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
        }).bind(this))
    },

    stopPlayingLatestRenderedBuffer: function(sequencer){
        if(this._getLatestSequencerRenderer(sequencer)){
            this._getLatestSequencerRenderer(sequencer).stopPlayingRenderedBuffer();
        }
        return Promise.resolve(true);
    },

    _destroyLatestSequencerRenderer : function(sequencer){
        const renderer = this._getLatestSequencerRenderer(sequencer);
        if(renderer){
            this.stopPlayingLatestRenderedBuffer(sequencer);
            this.stopListening(renderer);
            renderer.stopListening();
            this._deleteLatestSequencerRenderer(sequencer);
            this.stopListening(sequencer);	
        }
    },

    getRenderedAudioBlob: function(sequencer, applyMasterProcessing, renderWavFile, asVideo, progressReportFunction, alreadyRenderedAudioWAVBlob, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible){
        return this._renderSequencer(sequencer, applyMasterProcessing, null, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible).then((function(){
            return this._getLatestSequencerRenderer(sequencer).getRenderedAudioBlob(renderWavFile, asVideo, progressReportFunction, alreadyRenderedAudioWAVBlob, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
        }).bind(this));
    },
    
    _onSequencerCleared : function(sequencer){
        if(sequencer){
            this._destroyLatestSequencerRenderer(sequencer);
        }
    },

    _createNewSequencerRenderer : function(sequencer){
        this._destroyLatestSequencerRenderer(sequencer);
        const latestSequencerRenderer = new SequencerRenderer({"sequencer": sequencer});
        this._sequencerCidToLatestSequencerRenderer[sequencer.getId()] = latestSequencerRenderer;
        this.listenTo(sequencer, "sequencerBeingCleared", this._onSequencerCleared.bind(this));
    },

    isPlaying : function(sequencer){
        return  this._getLatestSequencerRenderer(sequencer) && this._getLatestSequencerRenderer(sequencer).isPlaying();
    },

    playPause : function(sequencer, sequencerNodeToStartFrom, applyMasterProcessing, stopAfterMilliseconds, nodeToStopPlayAt, onAudioErrorPlayingHandler, videoContext, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible){
        const isPlaying = this._getLatestSequencerRenderer(sequencer) && this._getLatestSequencerRenderer(sequencer).isPlaying();
        if(isPlaying){
            return this.stopPlayingLatestRenderedBuffer(sequencer);
        }else{
            return this._renderSequencerForPlaybackIfNecessary(sequencer, applyMasterProcessing, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible).then((function(){
                this.playLastestRenderedBuffer(sequencer, sequencerNodeToStartFrom, stopAfterMilliseconds, nodeToStopPlayAt, onAudioErrorPlayingHandler, videoContext, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
            }).bind(this));
        }
    },

    playFromTimeInMilliseconds : function(sequencer, timeToStartFromInMilliseconds, applyMasterProcessing, onAudioErrorPlayingHandler, videoContext, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible){
        this.stopPlayingLatestRenderedBuffer(sequencer);
        return this._renderSequencerForPlaybackIfNecessary(sequencer, applyMasterProcessing, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible).then((function(){
            const sequencerNodeToStartFrom = this._getLatestSequencerRenderer(sequencer) && this._getLatestSequencerRenderer(sequencer).getSequencerNodeAtPositionInMilliseconds(timeToStartFromInMilliseconds);
            this.playLastestRenderedBuffer(sequencer, sequencerNodeToStartFrom, null, null, onAudioErrorPlayingHandler, videoContext, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
        }).bind(this));

    },

    renderAndPlay : function(sequencer, sequencerNodeToStartFrom, applyMasterProcessing, stopAfterMilliseconds, nodeToStopPlayAt, onAudioErrorPlayingHandler, videoContext, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible){
        this.stopPlayingLatestRenderedBuffer(sequencer);
        return this._renderSequencerForPlaybackIfNecessary(sequencer, applyMasterProcessing, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible).then((function(){
            this.playLastestRenderedBuffer(sequencer, sequencerNodeToStartFrom, stopAfterMilliseconds, nodeToStopPlayAt, onAudioErrorPlayingHandler, videoContext, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
        }).bind(this));
    },

    _renderSequencerForPlaybackIfNecessary : function(sequencer, applyMasterProcessing, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible){
        if(!ONLY_RENDER_IF_NECESSARY || shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible){
            return this._renderSequencer(sequencer, applyMasterProcessing, null, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
        }else{
            if(sequencer.isRerenderRequiredOnNexPlayback()){
                return this._renderSequencer(sequencer, applyMasterProcessing, null, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
            }else{
                if(applyMasterProcessing){
                    if(this._getLatestSequencerRenderer(sequencer).isFinalRenderedBufferWithMasterEffectsAppliedCreated()){
                        return RSVP.Promise.resolve();
                    }else{
                        return this._renderSequencer(sequencer, applyMasterProcessing, null, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
                    }
                }else{
                    if(this._getLatestSequencerRenderer(sequencer).isLatestRenderedBufferCreated()){
                        return RSVP.Promise.resolve();
                    }else{
                        return this._renderSequencer(sequencer, applyMasterProcessing, null, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
                    }
                }
            }
        }
        return this._renderSequencer(sequencer, applyMasterProcessing, null, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
    },

    getRenderedAudioBlobClip: function(sequencer, applyMasterProcessing, renderWavFile, sequencerNodeToStartFrom, sequencerNodeToEndOn, asVideo, progressReportFunction, alreadyRenderedAudioWAVBlob, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible){
        return new RSVP.Promise((function(resolve, reject){
            const customRenderFunctionToExecute = function(sequencerRenderer){
                sequencerRenderer.getRenderedAudioBlobClip(renderWavFile,applyMasterProcessing, sequencerNodeToStartFrom, sequencerNodeToEndOn, asVideo, progressReportFunction, alreadyRenderedAudioWAVBlob, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible).then(resolve).catch(reject);
            }
            return this._renderSequencer(sequencer, applyMasterProcessing, customRenderFunctionToExecute, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
        }).bind(this))
    },

    getSrtText : function(sequencer, sequencerNodeToStartFrom, sequencerNodeToEndOn, progressReportFunction, asVtt, doNotIncludeSpeaker, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible){
        return new RSVP.Promise((function(resolve, reject){
            const customRenderFunctionToExecute = function(sequencerRenderer){
                sequencerRenderer.getSrtText(sequencerNodeToStartFrom, sequencerNodeToEndOn, progressReportFunction, asVtt, doNotIncludeSpeaker, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible).then(resolve).catch(reject);
            }
            return this._renderSequencer(sequencer, false, customRenderFunctionToExecute, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
        }).bind(this))
    },

    getTranscriptionAndEditsInfo : function(sequencer, sequencerNodeToStartFrom, sequencerNodeToEndOn, progressReportFunction, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible){
        return new RSVP.Promise((function(resolve, reject){
            const customRenderFunctionToExecute = function(sequencerRenderer){
                sequencerRenderer.getTranscriptionAndEditsInfo(sequencerNodeToStartFrom, sequencerNodeToEndOn, progressReportFunction, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible).then(resolve).catch(reject);
            }
            return this._renderSequencer(sequencer, false, customRenderFunctionToExecute, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
        }).bind(this))
    },

    createFFMPEGParamsToRenderVideoByContatenation : function(sequencer, applyMasterProcessing, sequencerNodeToStartFrom,sequencerNodeToEndOn, audioUrl, srtFileUrl, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible){
        return new RSVP.Promise((function(resolve, reject){
            const customRenderFunctionToExecute = function(sequencerRenderer){
                sequencerRenderer.createFFMPEGParamsToRenderVideoByContatenation(sequencerNodeToStartFrom,sequencerNodeToEndOn, audioUrl, srtFileUrl, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible).then(resolve).catch(reject);
            }
            return this._renderSequencer(sequencer, applyMasterProcessing, customRenderFunctionToExecute, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
        }).bind(this))
    },

    createFFMPEGParamsByContatenationForServerRendering : function(sequencer, applyMasterProcessing, sequencerNodeToStartFrom,sequencerNodeToEndOn, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible, maxDurationInMilliseconds, audioUrl){
        return new RSVP.Promise((function(resolve, reject){
            const customRenderFunctionToExecute = async function(sequencerRenderer){
                sequencerRenderer.createFFMPEGParamsByContatenationForServerRendering(sequencerNodeToStartFrom,sequencerNodeToEndOn, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible, maxDurationInMilliseconds, audioUrl).then(resolve).catch(reject);
            }
            return this._renderSequencer(sequencer, applyMasterProcessing, customRenderFunctionToExecute, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
        }).bind(this))
    },


    _renderSequencer :function(sequencer, applyMasterProcessing, customRenderFunctionToExecute, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible){
        sequencer.increaseNumberOfRenderingRequestPending();
        return this._getRenderingInProgressPromiseIfApplicable(sequencer).then((function(){
            this._createNewSequencerRenderer(sequencer);
            this.trigger("sequencerRenderingStarted");
            if(customRenderFunctionToExecute){
                return customRenderFunctionToExecute(this._getLatestSequencerRenderer(sequencer))
            }else{
                return this._getLatestSequencerRenderer(sequencer).renderSequencer(applyMasterProcessing, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
            }
        }).bind(this)).then((function(){
            sequencer.decreaseNumberOfRenderingRequestPending();
            this.trigger("sequencerRenderingCompleted");
        }).bind(this)).catch((function(error){
            this.trigger("sequencerRenderingFailed",error);
            sequencer.decreaseNumberOfRenderingRequestPending();
            throw error;
        }).bind(this))
    },

    getDuration : function(sequencer){
        return this._getLatestSequencerRenderer(sequencer) ? this._getLatestSequencerRenderer(sequencer).getFinalRenderDurationInMilliseconds(): 0;
    },

    getPlaybackProgress: function(sequencer){
        return this._getLatestSequencerRenderer(sequencer) ? this._getLatestSequencerRenderer(sequencer).getPlaybackProgressInMilliseconds(): 0;
    },

    getPlaybackAudioContextCurrentTime: function(sequencer){
        return this._getLatestSequencerRenderer(sequencer) ? this._getLatestSequencerRenderer(sequencer).getPlaybackAudioContextCurrentTime(): 0;
    },

    canPlayThrough: function(sequencer){
        return this._getLatestSequencerRenderer(sequencer) ? this._getLatestSequencerRenderer(sequencer).canPlayThrough(): false;
    },


});


const sequencerRendererHelperInstance = new SequencerRendererHelper();

export default {
	getInstance : function() {
		return sequencerRendererHelperInstance;
	}
}; 