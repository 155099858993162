import _ from 'underscore';
import $ from 'jquery';
import React from 'react';
import OptionPopupView from "views/common/OptionPopupView";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import { createRoot } from "react-dom/client";
import { VideoEditor } from '../../components/clipCreator/Components/VideoEditor.tsx';


const Rollout = RolloutHelper.getInstance();

const ClipCreatorView = OptionPopupView.extend({

    "pageName": "Create Clip",

    initialize: function(options) {
        const viewOptions = {};

        const contentView = {};
        this._videoDataForClipCreation = options.videoDataForClipCreation;
        this._renderVideoClip = options.renderVideoClip;
        this.clipCreatorContainer$el = $('<div class="clip_creator_content"></div>');
        this.clipCreatorContainer$el.css("width","calc(100% - 100px)");
        contentView.$el = this.clipCreatorContainer$el;
        viewOptions.contentView = contentView;
        viewOptions.contentPosition = "text-not-white";
        viewOptions.addContentAfterEnhancement = true;
        viewOptions.displayTitle = false;
        viewOptions.titleToDisplay = ""

        viewOptions.id = "ClipCreatorPopup";
        OptionPopupView.prototype.initialize.call(this, viewOptions);
    },



    afterRendering: function() {

    },

    onPopupClosed: function() {
        if (this._clipCreaotrWidgetRoot) {
            this._clipCreaotrWidgetRoot.unmount();
            this._clipCreaotrWidgetRoot = null;
        }
    }, 

    onGenerateClipButtonClicked: async function(dataToRenderJson){
        //this.trigger('renderVideoClip', dataToRenderJson);
        await this._renderVideoClip(dataToRenderJson);
    },

    addClipCreatorWidget :function(){
        const root = createRoot(this.clipCreatorContainer$el.get(0));
        const videoTierResolution = this._videoDataForClipCreation.videoTierResolution;
        const startTimeOffset = this._videoDataForClipCreation[0].getStartTimeOffset() - this._videoDataForClipCreation[0].getRelativeStartTime();
        let videoRenderedData =   this._videoDataForClipCreation.map((t) =>{ 
            t = t.toJSON();
            t.sequencerNodeReference = t.sequencerNodeReference.getSerializedData();
            t.nodeSchedulingInfoMergedArray = t.nodeSchedulingInfoMergedArray.map((d) => {
               d = d.toJSON();
               d.sequencerNodeReference = d.sequencerNodeReference.getSerializedData();
               return d;
            });
            return t;
        }).filter((d)=>{
            return d?.sequencerNodeReference?.audioSegment?.transcribedAudioInstance?.hasVideo 
        });


        root.render(<VideoEditor videoRenderData={videoRenderedData} durationInMs={this._videoDataForClipCreation.totalPlaybackDurationInMilliseconds} framePerSec={30} firstScheduledNode={this._videoDataForClipCreation[0]} startTimeOffset={startTimeOffset} onGenerateClipButtonClicked={this.onGenerateClipButtonClicked.bind(this)}  videoTierResolution={videoTierResolution}/>);
        this._clipCreaotrWidgetRoot = root;
    },

    onAfterPageShow: function() {
        this.addClipCreatorWidget()
    },  

    afterRender: function() {
        OptionPopupView.prototype.afterRender.call(this);
        
    },



});

export default ClipCreatorView;
