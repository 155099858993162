export const PositionSelector = ({ selectedPosition, setSelectedPosition }: { selectedPosition: string, setSelectedPosition: (position: string) => void }) => {
  // Inline styles
  const styles = {
    container: {
      textAlign: "center",
      color: "white",
      fontFamily: "Arial, sans-serif",
    },
    label: {
      fontSize: "20px",
      marginBottom: "10px",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "repeat(3, 10px)",
      gridTemplateRows: "repeat(3, 10px)",
      gap: "10px",
      justifyContent: "center",
      alignItems: "center",
    },
    cell: {
      width: "10px",
      height: "10px",
      border: "2px solid #444",
      borderRadius: "50%",
      backgroundColor: "#444",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "transform 0.3s ease, background-color 0.3s ease",
    },
    "cell:hover": {
      transform: "scale(1.2)", // Slight increase when hovering
    },
    activeCell: {
      backgroundColor: "white",
      transform: "scale(1.5)", // Makes the selected cell larger
    },
  };

  return (
    <div style={styles.grid}>
      {[
        "top left",
        "top center",
        "top right",
        "center left",
        "center center",
        "center right",
        "bottom left",
        "bottom center",
        "bottom right",
      ].map((position) => (
        <div
          key={position}
          style={{
            ...styles.cell,
            ...(selectedPosition === position ? styles.activeCell : {}),
          }}
          onClick={() => setSelectedPosition(position)}
          onMouseEnter={(e) => {
            if (selectedPosition !== position) {
              (e.target as any).style.transform = "scale(1.2)"; // Slight increase on hover
            }
          }}
          onMouseLeave={(e) => {
            if (selectedPosition !== position) {
              (e.target as any).style.transform = "scale(1)"; // Reset scale
            }
          }}
        ></div>
      ))}
    </div>
  );
};