import React from "react";
import ConfigProvider from "./common/ConfigProvider";
import PropTypes from "prop-types";
import useBackboneModel from "./helper/useBackboneModel";
import { useEffect, useState, useRef , forwardRef} from 'react';
import ti18n from 'i18n!nls/Sequenceri18n';
import SequencerSearchWidget from "../models/audioEditor/SequencerSearchWidget";
import useDefaultTrebbleAntDTokenConfig from "./helper/useDefaultTrebbleAntDTokenConfig";
import Tooltip from "./common/Tooltip";
import Utils from "../models/helper/Utils";
import SequencerEditBoundariesClipDownloadUtils from "../models/audioEditor/SequencerEditBoundariesClipDownloadUtils";

const SEARCH_TYPE = SequencerSearchWidget.SEARCH_TYPE;
const EditorSearchContextPanel = (props) =>
{
    const themeConfig = useDefaultTrebbleAntDTokenConfig({useEditorTheme: true});
    const searchWidgetData = useBackboneModel(props.searchWidgetModel, (model) => {
        model.toJSON()
        return {
            searchRequestId: model.getSearchRequestId(),
            searchResultIndexInFocus: model.getIndexOfNodeInFocus(),
            numberOfSearchNodesFound: model.getNumberOfSearchNodesFound(),
            arrayOfSequencerNodeInFocus : model.getSequencerNodesCurrentlyInFocus(),
            canMoveToPreviousResult:model.canMoveToPreviousResult(),
            canMoveToNextResult:model.canMoveToNextResult(),
            searchType: model.getSearchType(),
            searchParams: model.getSearchParams()
        }
    });
    const [timerForInputChange, setTimerForInputChange] = useState(null);

    const buttonRef = useRef();

    const [hasResults, setHasResults] = useState(searchWidgetData.arrayOfSequencerNodeInFocus && searchWidgetData.arrayOfSequencerNodeInFocus.length > 0);
    const [displayRefreshButton, setDisplayRefreshButton] = useState(false);

    useEffect(() => {
        setHasResults(searchWidgetData.arrayOfSequencerNodeInFocus && searchWidgetData.arrayOfSequencerNodeInFocus.length > 0);
    }, [searchWidgetData.arrayOfSequencerNodeInFocus]);


    const onInputValueChange = (e) => {
        if(searchWidgetData.searchType === SEARCH_TYPE.SEMANTIC_SEARCH){
            if(e.target.value.length > 5){
                if (timerForInputChange) {
                    clearTimeout(timerForInputChange);
                }
                const timeoutId = setTimeout(()=>{
                    props.searchWidgetModel.applySemanticTextSearch(e.target.value);
                }, 3000);
                setTimerForInputChange(timeoutId);
            }
        }else{
            props.searchWidgetModel.applyTextSearch(e.target.value);
        }        
    }

    const searchInputRef = useRef(null);

    const onApplyAllButtonClick = async() => {
        if(searchWidgetData.searchType == SEARCH_TYPE.EDIT_BOUNDARIES){
            return await SequencerEditBoundariesClipDownloadUtils.getInstance().downloadAllClipWithEditBoundaries(props.sequencer, props.searchWidgetModel);
        }
        if(props.onApplyAllButtonClick){
            props.onApplyAllButtonClick();
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("sequencer", 'searchResultApplyActionOnAllResults', 'search result apply action on all results',{  searchParams: searchWidgetData.searchParams, searchType: searchWidgetData.searchType, searchRequestId: searchWidgetData.searchRequestId});
            }
        }else{
            props.sequencer.deleteAllSearchResults();
            props.onPanelCloseButtonClick();
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("sequencer", 'searchResultDeleteAllResults', 'search result delete all results',{  searchParams: searchWidgetData.searchParams, searchType: searchWidgetData.searchType, searchRequestId: searchWidgetData.searchRequestId});
            }
        }
    }

    const resfreshButtonClick = async() => {
        if(searchWidgetData.searchType == SEARCH_TYPE.AI_EDIT_SUGGESTIONS){
            await props.sequencer.getSearchWidget().applyAIEditSuggestions(searchWidgetData.searchParams, true, false);
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("sequencer", 'searchResultManualRefresh', 'search result manual refresh',{  searchParams: searchWidgetData.searchParams, searchType: searchWidgetData.searchType, searchRequestId: searchWidgetData.searchRequestId});
            }
        }
        
    }

   

    useEffect(() =>
    {
        if(props.panelOpened && searchInputRef && searchInputRef.current){
            searchInputRef.current.focus();
        }
    }, [props.panelOpened, searchWidgetData.searchType]);

    const [searchPanelTitle, setSearchPanelTitle] = useState(window.getI18n(ti18n,"SEARCH")?.toUpperCase());

    const applyAllButtonLabel = props.applyAllActionButtonLabel || ( searchWidgetData.searchType == SEARCH_TYPE.EDIT_BOUNDARIES ? window.getI18n(ti18n, "DOWNLOAD_ALL"): window.getI18n(ti18n, "REMOVE_ALL"))

    useEffect(() =>
    {
        switch(searchWidgetData.searchType){
            case SEARCH_TYPE.TEXT_SEARCH:
                //setSearchPanelTitle("SEARCH");
                setDisplayRefreshButton(false);
                break;
            case SEARCH_TYPE.SEMANTIC_SEARCH:
                //setSearchPanelTitle("SMART_SEARCH");
                setDisplayRefreshButton(false);
                break;
            case SEARCH_TYPE.FILLER_WORDS:
                //setSearchPanelTitle("FILLER WORD SEARCH");
                setDisplayRefreshButton(false);
                break;
            case SEARCH_TYPE.EDIT_BOUNDARIES:
                //setSearchPanelTitle("EDIT BOUNDARIES SEARCH");
                setDisplayRefreshButton(false);
                break;
            case SEARCH_TYPE.SILENCES:
                //setSearchPanelTitle("SILENCE SEARCH");
                setDisplayRefreshButton(false);
                break;
            case SEARCH_TYPE.AI_EDIT_SUGGESTIONS:
                //setSearchPanelTitle("AI EDIT SUGGESTIONS");
                setDisplayRefreshButton(true);
                break;
            case SEARCH_TYPE.DISFLUENCY_SEARCH:
                //setSearchPanelTitle("SPEECH ANOMALIES SEARCH");
                setDisplayRefreshButton(false);
                break;
        }
    }, [searchWidgetData.searchType]);

    const [resultFoundText, setResultFoundText] = useState(window.getI18n(ti18n,"RESULT_FOUND"));

    useEffect(() =>
    {
        switch(searchWidgetData.searchType){
            case SEARCH_TYPE.TEXT_SEARCH:
                setResultFoundText("");
                break;
            case SEARCH_TYPE.SEMANTIC_SEARCH:
                setResultFoundText("");
                break;
            case SEARCH_TYPE.FILLER_WORDS:
                setResultFoundText(window.getI18n(ti18n, "FILLER_WORDS"));
                break;
            case SEARCH_TYPE.EDIT_BOUNDARIES:
                setResultFoundText(window.getI18n(ti18n, "EDIT_BOUNDARIES"));
                break;
            case SEARCH_TYPE.DISFLUENCY_SEARCH:
                setResultFoundText(window.getI18n(ti18n, "DISFLUENCIES"));
                break;
            case SEARCH_TYPE.SILENCES:
                setResultFoundText(`${searchWidgetData.searchParams && searchWidgetData.searchParams.maxSilenceInMilliseconds? window.getI18n(ti18n, "SILENCES_LONGER_THAN") + " "+ searchWidgetData.searchParams.maxSilenceInMilliseconds/1000+"s": window.getI18n(ti18n, "SILENCES_FOUND")}`);
                break;
            case SEARCH_TYPE.AI_EDIT_SUGGESTIONS:
                setResultFoundText(window.getI18n(ti18n, "ALL_UNNECESSARY_CONTENT"));
                break;
        }
    }, [searchWidgetData.searchType, searchWidgetData.searchParams]);

    const [inputPlaceholder, setInputPlaceholder] = useState(window.getI18n(ti18n, "SEARCH_FOR_A_WORD"));

    useEffect(() => {
        if(searchWidgetData.searchType === SEARCH_TYPE.SEMANTIC_SEARCH){
            setInputPlaceholder(window.getI18n(ti18n, "WHAT_ARE_YOU_LOOKING_FOR"));
        }else{
            setInputPlaceholder(window.getI18n(ti18n, "SEARCH_FOR_A_WORD"));
        }
    }, [searchWidgetData.searchType]);
 

    return (
        <ConfigProvider theme={ themeConfig }>
            <div className="flex flex-col box-border p-2 search_context_panel">
                <div className="flex flex-row">
                    <b className="flex flex-col">
                        {props.title || searchPanelTitle}
                    </b>
                </div>
                 <div className="flex flex-row items-center">
                    <div className="flex flex-col flex-auto items-start w-full">
                        <div className="w-full text-left flex">{ searchWidgetData.searchType === SEARCH_TYPE.TEXT_SEARCH || searchWidgetData.searchType === SEARCH_TYPE.SEMANTIC_SEARCH ?   <input ref={searchInputRef} className="search_input mousetrap" onChange={(e)=>onInputValueChange(e)} placeholder={ inputPlaceholder}></input>: <span className="py-2">{resultFoundText}</span> } {displayRefreshButton &&  <div className="flex flex-row justify-end"><button className={` button_with_label `} onClick={resfreshButtonClick}  ><label>{window.getI18n(ti18n, "REFRESH")}</label></button></div>}</div>
                        
                    </div>
                </div>
                <b className="py-2 text-left">{props.searchDisplayTitle}</b>
                <div className="flex flex-row justify-center items-center">
                    <div className="flex flex-col">
                        <div>{hasResults? `${searchWidgetData.searchResultIndexInFocus + 1} of ${ searchWidgetData.numberOfSearchNodesFound }`: "0 of 0"}</div>
                    </div>
                    <div className="flex flex-col">
                        <Tooltip title={window.getI18n(ti18n,"PREVIOUS_RESULT")} placement="bottom" align="center">
                            <button onClick={()=>props.searchWidgetModel.moveToPreviousFoundNode()}  disabled={!searchWidgetData.canMoveToPreviousResult} className={`${!searchWidgetData.canMoveToPreviousResult && "disabled"}`}>
                                <span className="icon ion-ios7-arrow-thin-up text-3xl"></span>
                            </button>
                        </Tooltip>
                    </div>
                    <div className="flex flex-col">
                        <Tooltip title={window.getI18n(ti18n,"NEXT_RESULT")} placement="bottom" align="center">
                            <button onClick={()=>props.searchWidgetModel.moveToNextFoundNode()} disabled={!searchWidgetData.canMoveToNextResult} className={`${!searchWidgetData.canMoveToNextResult && "disabled"}`}>
                                <span className="icon ion-ios7-arrow-thin-down text-3xl"></span>
                            </button>
                        </Tooltip>
                    </div>
                    <div className="flex flex-col">
                        <button disabled={!hasResults} className={`${!hasResults && "disabled"} button_with_label `} onClick={onApplyAllButtonClick}  ><label>{applyAllButtonLabel}</label></button>
                    </div>
                </div>
            </div>
        </ConfigProvider>
    );
};
EditorSearchContextPanel.propTypes = {
    title: PropTypes.string,
    showSearchInput: PropTypes.bool,
    searchDisplayTitle: PropTypes.string.isRequired,
    searchWidgetModel: PropTypes.object,
    onPanelCloseButtonClick: PropTypes.func,
    resfreshButtonClick: PropTypes.func,
    panelOpened: PropTypes.bool,
    resultCursor: PropTypes.number,
    totalNumberOfResults: PropTypes.number,
    applyActionButtonLabel: PropTypes.string,
    resfreshButtonLabel: PropTypes.string,
    applyAllActionButtonLabel : PropTypes.string,
    onApplyButtonClick: PropTypes.func,
    onApplyAllButtonClick: PropTypes.func,
    locale: PropTypes.object,
    sequencer:PropTypes.object
};


export default EditorSearchContextPanel;