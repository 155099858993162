import { useVideoConfig, OffthreadVideo, Audio } from "remotion";
import { VideoSegmentMultiFocusDesignSystemConfig } from "../../VideoSegmentMultiFocus";

export const DefaultFocusVideoSegment: React.FC<VideoSegmentMultiFocusDesignSystemConfig> = ({ startTimestampMS, endTimestampMS, videoURL, spacing: padding, borderRadius, border, audioURL }) => {
    const { width, height, fps } = useVideoConfig();

    const paddingSize = padding ? Math.sqrt(width * height) * 0.05 : 0;
    const borderThickness = border ? Math.sqrt(width * height) / 150 : 0;

    console.log(audioURL);
    return (
        <div
            style={{
                borderRadius: borderRadius,
                overflow: 'hidden',
                border: border ? `${borderThickness}px solid black` : `none`,
                margin: paddingSize
            }}
        >
            <OffthreadVideo
                src={videoURL}
                startFrom={startTimestampMS / 1000 * fps}
                endAt={endTimestampMS / 1000 * fps}
                style={{
                    objectFit: 'scale-down',
                    transformOrigin: 'center center',
                }}
                muted={audioURL == undefined ? false : true}
            />
            {audioURL && <Audio src={audioURL} />}
        </div>
    )
}