import { useRef } from "react";
import { useVideoConfig, OffthreadVideo, Audio } from "remotion";
import { VideoSegmentMultiFocusDesignSystemConfig } from "../../VideoSegmentMultiFocus";
import { onVideoFrame } from "../DefaultVideoSegmentMultiFocus";

const ThreeSplit16x9: React.FC<VideoSegmentMultiFocusDesignSystemConfig> = ({ startTimestampMS, endTimestampMS, videoURL, spacing, borderRadius, border, framing, designSystem, audioURL }) => {
    const { width, height, fps } = useVideoConfig();

    // We simply display three vertical strips side by side, each 16:27
    const canvasRefs = [useRef<HTMLCanvasElement>(null), useRef<HTMLCanvasElement>(null), useRef<HTMLCanvasElement>(null)];

    const paddingSize = spacing ? Math.sqrt(width * height) * 0.05 : 0;
    const borderThickness = spacing ? Math.sqrt(width * height) / 150 : 0;

    const totalWidth = (width - paddingSize * 2 - borderThickness * 2) / 3;
    const totalHeight = height - paddingSize * 2 - borderThickness * 2;

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                alignContent: 'space-evenly',
                width: '100%',
                height: '100%',
            }}
        >
            <OffthreadVideo
                style={{ opacity: 0, display: 'none' }}
                src={videoURL}
                startFrom={startTimestampMS / 1000 * fps}
                endAt={endTimestampMS / 1000 * fps}
                onVideoFrame={(frame) => onVideoFrame(frame, framing["16:9"].focusRegions, canvasRefs)}
                muted={audioURL == undefined ? false : true}
            />
            {audioURL && <Audio src={audioURL} />}
            {framing["16:9"].focusRegions.map((_, index) => (
                <canvas
                    key={index}
                    ref={canvasRefs[index]}
                    width={totalWidth}
                    height={totalHeight}
                    style={{
                        border: border ? `${borderThickness}px solid black` : `none`,
                        borderRadius: borderRadius,
                        aspectRatio: '16 / 27',
                    }}
                />
            ))}
        </div>
    );
}

const ThreeSplit9x16: React.FC<VideoSegmentMultiFocusDesignSystemConfig> = ({ startTimestampMS, endTimestampMS, videoURL, spacing, borderRadius, border, framing, designSystem, audioURL }) => {
    const { width, height, fps } = useVideoConfig();

    // We will display a 9:8 on the top, then two 9:16 videos below side by side
    const canvasRefs = [useRef<HTMLCanvasElement>(null), useRef<HTMLCanvasElement>(null), useRef<HTMLCanvasElement>(null)];

    const paddingSize = spacing ? Math.sqrt(width * height) * 0.05 : 0;
    const borderThickness = spacing ? Math.sqrt(width * height) / 150 : 0;

    const totalHeight = (height - paddingSize * 2 - borderThickness * 2) / 2;

    const topVideoWidth = totalHeight * (9 / 8);
    const bottomVideoWidth = totalHeight * (9 / 16);

    // Use CSS grid to display the first canvas on top, then the other two side by side below
    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: 'auto auto',
                gridTemplateRows: 'auto auto',
                justifyContent: 'space-evenly',
                alignContent: 'space-evenly',
                alignItems: 'center',
                justifyItems: 'center',
                width: '100%',
                height: '100%',
            }}
        >
            <OffthreadVideo
                style={{ opacity: 0, display: 'none' }}
                src={videoURL}
                startFrom={startTimestampMS / 1000 * fps}
                endAt={endTimestampMS / 1000 * fps}
                onVideoFrame={(frame) => onVideoFrame(frame, framing["9:16"].focusRegions, canvasRefs)}
                muted={audioURL == undefined ? false : true}
            />
            {audioURL && <Audio src={audioURL} />}
            <canvas ref={canvasRefs[0]} width={topVideoWidth} height={totalHeight} style={{
                // Should take up the full width
                gridColumn: 'span 2',
                border: border ? `${borderThickness}px solid black` : `none`,
                borderRadius: borderRadius,
                aspectRatio: '9 / 8',
            }} />
            <canvas ref={canvasRefs[1]} width={bottomVideoWidth} height={totalHeight} style={{
                border: border ? `${borderThickness}px solid black` : `none`,
                borderRadius: borderRadius,
                aspectRatio: '9 / 16',
            }} />
            <canvas ref={canvasRefs[2]} width={bottomVideoWidth} height={totalHeight} style={{
                border: border ? `${borderThickness}px solid black` : `none`,
                borderRadius: borderRadius,
                aspectRatio: '9 / 16',
            }} />
        </div>
    );
}

const ThreeSplit1x1: React.FC<VideoSegmentMultiFocusDesignSystemConfig> = ({ startTimestampMS, endTimestampMS, videoURL, spacing, borderRadius, border, framing, designSystem, audioURL }) => {
    const { width, height, fps } = useVideoConfig();

    // We will display a 2:1 on the top, then two 1:1 videos below side by side
    const canvasRefs = [useRef<HTMLCanvasElement>(null), useRef<HTMLCanvasElement>(null), useRef<HTMLCanvasElement>(null)];

    const paddingSize = spacing ? Math.sqrt(width * height) * 0.05 : 0;
    const borderThickness = spacing ? Math.sqrt(width * height) / 150 : 0;

    const totalHeight = (height - paddingSize * 2 - borderThickness * 2) / 2;

    const topVideoWidth = totalHeight * (2 / 1);
    const bottomVideoWidth = totalHeight * (1 / 1);

    // Use CSS grid to display the first canvas on top, then the other two side by side below
    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: 'auto auto',
                gridTemplateRows: 'auto auto',
                justifyContent: 'space-evenly',
                alignContent: 'space-evenly',
                alignItems: 'center',
                justifyItems: 'center',
                width: '100%',
                height: '100%',
            }}
        >
            <OffthreadVideo
                style={{ opacity: 0, display: 'none' }}
                src={videoURL}
                startFrom={startTimestampMS / 1000 * fps}
                endAt={endTimestampMS / 1000 * fps}
                onVideoFrame={(frame) => onVideoFrame(frame, framing["1:1"].focusRegions, canvasRefs)}
                muted={audioURL == undefined ? false : true}
            />
            {audioURL && <Audio src={audioURL} />}
            <canvas ref={canvasRefs[0]} width={topVideoWidth} height={totalHeight} style={{
                // Should take up the full width
                gridColumn: 'span 2',
                border: border ? `${borderThickness}px solid black` : `none`,
                borderRadius: borderRadius,
                aspectRatio: '2 / 1',
            }} />
            <canvas ref={canvasRefs[1]} width={bottomVideoWidth} height={totalHeight} style={{
                border: border ? `${borderThickness}px solid black` : `none`,
                borderRadius: borderRadius,
                aspectRatio: '1 / 1',
            }} />
            <canvas ref={canvasRefs[2]} width={bottomVideoWidth} height={totalHeight} style={{
                border: border ? `${borderThickness}px solid black` : `none`,
                borderRadius: borderRadius,
                aspectRatio: '1 / 1',
            }} />
        </div>
    );
}


export const ThreeSplitFocusVideoSegment: React.FC<VideoSegmentMultiFocusDesignSystemConfig> = (props) => {
    switch (props.designSystem.canvas.aspectRatio) {
        case "16:9":
            return <ThreeSplit16x9 {...props} />
        case "9:16":
            return <ThreeSplit9x16 {...props} />
        case "1:1":
            return <ThreeSplit1x1 {...props} />
        default:
            throw new Error(`Unsupported aspect ratio: ${props.designSystem.canvas.aspectRatio}`);
    }
}