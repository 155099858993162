import dataScheme from "./video_data_to_render.json";
import { Composition, CalculateMetadataFunction } from "remotion";
import { DesignSystem } from "./Components/DesignSystem";
import TrebbleClipCreatorInput, { TrebbleClipCreatorInputConfig } from "./TrebbleClipCreator";
import { FrameDemoProps } from "./FramedDemo";
import videoDemoData from './video_data_to_render_demo.json';

// Each <Composition> is an entry in the sidebar!

// List of video configurations for different social media platforms
// Normal Video 1080p
const videoSettings: FrameDemoProps = {
  frameURL: "",
  canvas: {
    aspectRatio: "16:9",
    width: 1920,
    height: 1080,
    safeZone: {
      top: 100,
      bottom: 100,
      left: 100,
      right: 100,
    },
  },
};

// // Tiktok
// const tiktokSettings: FrameDemoProps = {
//   frameURL: staticFile('tiktokFrame.png'),
//   canvas: {
//     width: 887,
//     height: 1920,
//     safeZone: {
//       top: 200,
//       bottom: 450,
//       left: 60,
//       right: 120,
//     },
//   },
// };

// // Instagram Reel
// const instagramReelSettings: FrameDemoProps = {
//   frameURL: "",
//   canvas: {
//     width: 1090,
//     height: 1920,
//     safeZone: {
//       top: 220,
//       bottom: 420,
//       left: 60,
//       right: 170,
//     },
//   },
// };

// // Youtube Short
// const youtubeShortSettings: FrameDemoProps = {
//   frameURL: "",
//   canvas: {
//     width: 1080,
//     height: 1920,
//     safeZone: {
//       top: 140,
//       bottom: 270,
//       left: 70,
//       right: 190,
//     },
//   },
// };



// Default video config for the demo
const designSystemConfig: DesignSystem = {
  style: "classic",
  primaryColor: "#0521A1",
  secondaryColor: "#42f554",
  accentColor: "#f5425d",
  titleFont: "Times New Roman",
  bodyFont: "Times New Roman",
  logoURL: "https://images.squarespace-cdn.com/content/v1/54c81a6fe4b0652cb365f97d/1535192418838-95VVAQV7U1R2P4DR2HVC/rsz_1rsz_1trebblelogofullred.png?format=1500w",
  canvas: videoSettings.canvas
}

const AnimationTimings = {
  speaker: {
    fromTime: 0,
    // toTime: 1300,
    delay: 0,
  },
  watermark: {
    fromTime: 0,
    // toTime: 5000,
    delay: 15,
  },
};

export const trebbleClipCreatorInputDemo: TrebbleClipCreatorInputConfig = {
  videoRenderData: dataScheme,
  audioUrl: "",
  designSystem: designSystemConfig,
  captionConfig: {
    font: "tinos",
    useDesignSystemColor: false,
    mainColor: "#ff8800",
    highlightColor: "#f5f511",
    milisecondGrouping: 1000,
    fontSizeRatio:'medium',
    position: "bottom center",
    outline: "none",
    background: "none",
    textAnimation: "none",
    borderRadius: 0,
    captionCase: "standard",
    hidePunctuation: false,
    showOneWordOnly: false,
    maxLines: 'one_line'
  },
  backgroundDesignConfig: {
    imageURL: "https://images6.alphacoders.com/376/thumb-1920-376931.jpg",
    color: "#000000",
    format: "image"
  },
  videoConfig: {
    borderRadius: 30,
    padding: true,
    border: true,
    scale: 1,
    framing: {
      name: "default",
      "1:1": {
        focusRegions: []
      },
      "9:16": {
        focusRegions: []
      },
      "16:9": {
        focusRegions: []
      }
    }
  },
  watermarkDesignConfig: {
    animation: AnimationTimings.watermark,
    imageURL: "https://images.squarespace-cdn.com/content/v1/54c81a6fe4b0652cb365f97d/1535192418838-95VVAQV7U1R2P4DR2HVC/rsz_1rsz_1trebblelogofullred.png?format=1500w",
    opacity: 100,
    scale: 0.15,
    position: 'top right'
  },
  speakerDesignConfig: {
    animation: AnimationTimings.speaker,
    firstName: "John",
    lastName: "Doe",
    title: "Founder",
    company: "Trebble",
    position: "bottom left"
  }
}

export const RemotionRoot: React.FC = () => {

  const calculateMetadata: CalculateMetadataFunction<TrebbleClipCreatorInputConfig> = ({props, defaultProps, abortSignal}) => {
    return {
      // Change the metadata
      durationInFrames: Math.floor(props.durationInMs/props.framePerSec),
      fps: props.framePerSec,
      width: props.designSystem.canvas.width,
      height: props.designSystem.canvas.height,
      // or transform some props
      props,
      // or add per-composition default codec
      defaultCodec: 'h264',
    };
  };

  return (
    <>
      <Composition
        id="TrebbleClipCreatorComposition"
        component={TrebbleClipCreatorInput}
        durationInFrames={10}
        fps={30}
        width={trebbleClipCreatorInputDemo.designSystem.canvas.width}
        height={trebbleClipCreatorInputDemo.designSystem.canvas.height}
        defaultProps={videoDemoData}
        calculateMetadata={calculateMetadata}
      />
    </>
  );
};