import { DesignSystem, ElementDesignSystem } from "../DesignSystem";
import { DefaultBackground } from "./DefaultBackground/DefaultBackground";

export interface BackgroundDesignSystemConfig {
    imageURL: string,
    color: string,
    format: 'image' | 'color',
}

export class BackgroundDesignSystem extends ElementDesignSystem implements BackgroundDesignSystemConfig {
    public imageURL: string;
    public color: string;
    public format: 'image' | 'color';

    constructor(
        public config: BackgroundDesignSystemConfig,
        designSystem: DesignSystem
    ) {
        super({ designSystem: designSystem, position: 'center center', animation: undefined, affectedBySafeZone: false });
        this.imageURL = config.imageURL;
        this.color = config.color;
        this.format = config.format;
    }

    protected renderChild() {
        return (
            <DefaultBackground {...this} />
        )
    }

    protected positionToCSS(): React.CSSProperties {
        return {
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
        }
    }
}