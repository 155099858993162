import _ from 'underscore';
import AudioSegment from "models/audioEditor/segments/AudioSegment";

const CLASSNAME = "AudioEventAudioSegment";
const AudioEventAudioSegment =  AudioSegment.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        AudioSegment.apply(this, [attributes, options]);
        this.set("type", AudioEventAudioSegment.CLASSNAME);
    },

    canBeAssociactedWithAnySpeaker: function(){
        return false;
    },

    needToBeAssociactedWithASpeaker : function(){
        return true;
    },

    clone: function(){
        return new AudioEventAudioSegment(this.toJSON());
    },


});

AudioEventAudioSegment.CLASSNAME = CLASSNAME;
export default AudioEventAudioSegment; 