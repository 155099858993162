import { LayoutOutlined, BgColorsOutlined, FileTextOutlined, PictureOutlined, NumberOutlined, AntDesignOutlined } from "@ant-design/icons";
import { Card, Segmented } from "antd";
import { ReactNode, useState } from "react";
import { BackgroundComponent } from "./BackgroundConfig/BackgroundConfig";
import { CaptionsComponent } from "./CaptionConfig/CaptionConfig";
import { ColorComponent } from "./ColorConfig/ColorConfig";
import { FrameComponent } from "./FrameConfig/FrameConfig";
import { LayoutComponent } from "./LayoutConfig/LayoutConfig";
import { LogoComponent } from "./LogoConfig/LogoConfig";
import { Theme } from "../VideoEditor";
import sampleVideoData from '../../remotion/video_data_to_render.json';

export const getEnumFromValue = (value: any, ENUMS: Readonly<{ SQUARE: { value: string; label: string; suitable_platforms: string[]; }; PORTRAIT: { value: string; label: string; suitable_platforms: string[]; }; LANDSCAPE: { value: string; label: string; suitable_platforms: string[]; }; }> | Readonly<{ TREBBLE: { label: string; value: string; mainColor: string; complementaryColor: string; }; OCEAN: { label: string; value: string; mainColor: string; complementaryColor: string; }; PURPLE: { label: string; value: string; mainColor: string; complementaryColor: string; }; FOREST: { label: string; value: string; mainColor: string; complementaryColor: string; }; CHERRY: { label: string; value: string; mainColor: string; complementaryColor: string; }; CUSTOM: { label: string; value: string; mainColor: string; complementaryColor: string; }; }> | Readonly<{ DEFAULT: { value: string; label: string; }; SPLIT: { value: string; label: string; }; SCREENSHARE: { value: string; label: string; }; GAMEPLAY: { value: string; label: string; }; THREE: { value: string; label: string; }; FOUR: { value: string; label: string; }; }> | { [s: string]: unknown; } | ArrayLike<unknown>) => {
    return Object.values(ENUMS).find((e: any) => e.value === value);
};

const createNavigationMenu = (label: string | number | boolean | JSX.Element | Iterable<ReactNode> | null | undefined, value: string, icon: string | number | boolean | JSX.Element | Iterable<ReactNode> | null | undefined) => {
    return {
        labelString: label,
        value,
        label: (
            <div style={{}}>
                {icon}
                <div className="text-xs">{label}</div>
            </div>
        ),
    };
};

export const THEME_NAVIGATION_MENUS = Object.freeze({
    LAYOUT: createNavigationMenu("Layout", "layout", <LayoutOutlined />),
    COLORS: createNavigationMenu("Colors", "colors", <BgColorsOutlined />),
    CAPTIONS: createNavigationMenu("Captions", "captions", <FileTextOutlined />),
    BACKGROUND: createNavigationMenu(
        <div className="mb-2">
            <div style={{ lineHeight: "12px" }}>Back-</div>
            <div style={{ lineHeight: "12px" }}>ground</div>
        </div>,
        "background",
        <PictureOutlined />
    ),
    FRAME: createNavigationMenu("Frame", "frame", <NumberOutlined />),
    LOGO: createNavigationMenu("Logo", "logo", <AntDesignOutlined />),
});



export const ThemeNavigationComponent = ({ selectedTheme, setSelectedTheme, videoRenderData }: { selectedTheme: Theme, setSelectedTheme: (theme: Theme) => void, videoRenderData: typeof sampleVideoData }) => {
    const [viewSelected, setViewSelected] = useState(
        THEME_NAVIGATION_MENUS.LAYOUT.value
    );

    return (
        <div className="flex flex-row h-[530px]" >
            <div style={{ width: "70px" }} className="py-2">
                <Segmented
                    vertical={true}
                    value={viewSelected}
                    defaultValue={THEME_NAVIGATION_MENUS.LAYOUT.value}
                    onChange={setViewSelected}
                    className="m-0 p-0"
                    block={true}
                    options={Object.values(THEME_NAVIGATION_MENUS)}
                />
            </div>
            <div className="flex flex-row w-full overflow-auto">
                <div className="ml-2 w-full ">
                    {viewSelected === THEME_NAVIGATION_MENUS.LAYOUT.value && (
                        <Card size="small" className="my-2">
                            <LayoutComponent selectedTheme={selectedTheme} setSelectedTheme={setSelectedTheme} videoRenderData={videoRenderData} />
                        </Card>
                    )}
                    {viewSelected === THEME_NAVIGATION_MENUS.COLORS.value && (
                        <ColorComponent selectedTheme={selectedTheme} setSelectedTheme={setSelectedTheme} />
                    )}
                    {viewSelected === THEME_NAVIGATION_MENUS.CAPTIONS.value && (
                        <CaptionsComponent selectedTheme={selectedTheme} setSelectedTheme={setSelectedTheme} />
                    )}
                    {viewSelected === THEME_NAVIGATION_MENUS.BACKGROUND.value && (
                        <Card size="small" className="my-2">
                            <BackgroundComponent selectedTheme={selectedTheme} setSelectedTheme={setSelectedTheme} />
                        </Card>
                    )}
                    {viewSelected === THEME_NAVIGATION_MENUS.FRAME.value && (
                        <Card size="small" className="my-2">
                            <FrameComponent selectedTheme={selectedTheme} setSelectedTheme={setSelectedTheme} />
                        </Card>
                    )}
                    {viewSelected === THEME_NAVIGATION_MENUS.LOGO.value && (
                        <Card size="small" className="my-2">
                            <LogoComponent selectedTheme={selectedTheme} setSelectedTheme={setSelectedTheme} />
                        </Card>
                    )}
                </div>
            </div>
        </div>
    );
};