import { FramingOptions } from "../../../remotion/Components/VideoSegmentMultiFocus/VideoSegmentMultiFocus";
import { Theme } from "../../VideoEditor";

export function defaultFocusRegions<T extends FramingOptions['name']>(layoutName: T, canvasAspectRatio: Theme['designSystem']['canvas']['aspectRatio']): FramingOptions {
    // For demo purposes, have fixed values for focus regions per layout
    if (layoutName === "default") {
        return {
            name: layoutName,
            "1:1": {
                focusRegions: []
            },
            "9:16": {
                focusRegions: []
            },
            "16:9": {
                focusRegions: []
            }
        }
    } else if (layoutName === "2-split") {
        return {
            name: layoutName,
            "1:1": {
                focusRegions: [
                    {
                        x: 0 / 1920,
                        y: 200 / 1080,
                        width: 960 / 1920,
                        height: 480 / 1080,
                        aspectRatio: "2:1"
                    },
                    {
                        x: 960 / 1920,
                        y: 200 / 1080,
                        width: 960 / 1920,
                        height: 480 / 1080,
                        aspectRatio: "2:1"
                    }
                ]
            },
            "9:16": {
                focusRegions: [
                    {
                        x: 25 / 1920,
                        y: 140 / 1080,
                        width: 900 / 1920,
                        height: 800 / 1080,
                        aspectRatio: "9:8"
                    },
                    {
                        x: 986 / 1920,
                        y: 140 / 1080,
                        width: 900 / 1920,
                        height: 800 / 1080,
                        aspectRatio: "9:8"
                    }
                ]
            },
            "16:9": {
                focusRegions: [
                    {
                        x: 70 / 1920,
                        y: 90 / 1080,
                        width: 800 / 1920,
                        height: 900 / 1080,
                        aspectRatio: "8:9"
                    },
                    {
                        x: 1045 / 1920,
                        y: 90 / 1080,
                        width: 800 / 1920,
                        height: 900 / 1080,
                        aspectRatio: "8:9"
                    }
                ]
            }
        }
    } else if (layoutName === "3-split") {
        return {
            name: layoutName,
            "1:1": {
                focusRegions: [
                    {
                        x: 0 / 1920,
                        y: 200 / 1080,
                        width: 960 / 1920,
                        height: 480 / 1080,
                        aspectRatio: "2:1"
                    },
                    {
                        x: 144 / 1920,
                        y: 144 / 1080,
                        width: 658 / 1920,
                        height: 658 / 1080,
                        aspectRatio: "1:1"
                    },
                    {
                        x: 1124 / 1920,
                        y: 139 / 1080,
                        width: 658 / 1920,
                        height: 658 / 1080,
                        aspectRatio: "1:1"
                    },
                ]
            },
            "9:16": {
                focusRegions: [
                    {
                        x: 25 / 1920,
                        y: 140 / 1080,
                        width: 900 / 1920,
                        height: 800 / 1080,
                        aspectRatio: "9:8"
                    },
                    {
                        x: 158 / 1920,
                        y: 28 / 1080,
                        width: 576 / 1920,
                        height: 1024 / 1080,
                        aspectRatio: "9:16"
                    },
                    {
                        x: 1203 / 1920,
                        y: 28 / 1080,
                        width: 576 / 1920,
                        height: 1024 / 1080,
                        aspectRatio: "9:16"
                    }
                ]
            },
            "16:9": {
                focusRegions: [
                    {
                        x: 0 / 1920,
                        y: 0 / 1080,
                        width: 640 / 1920,
                        height: 1080 / 1080,
                        aspectRatio: "16:27"
                    },
                    {
                        x: 640 / 1920,
                        y: 0 / 1080,
                        width: 640 / 1920,
                        height: 1080 / 1080,
                        aspectRatio: "16:27"
                    },
                    {
                        x: 1280 / 1920,
                        y: 0 / 1080,
                        width: 640 / 1920,
                        height: 1080 / 1080,
                        aspectRatio: "16:27"
                    }
                ]
            }
        }
    } else if (layoutName === "4-split") {
        return {
            name: layoutName,
            "1:1": {
                focusRegions: [
                    {
                        x: 144 / 1920,
                        y: 144 / 1080,
                        width: 658 / 1920,
                        height: 658 / 1080,
                        aspectRatio: "1:1"
                    },
                    {
                        x: 1124 / 1920,
                        y: 139 / 1080,
                        width: 658 / 1920,
                        height: 658 / 1080,
                        aspectRatio: "1:1"
                    },
                    {
                        x: 144 / 1920,
                        y: 144 / 1080,
                        width: 658 / 1920,
                        height: 658 / 1080,
                        aspectRatio: "1:1"
                    },
                    {
                        x: 1124 / 1920,
                        y: 139 / 1080,
                        width: 658 / 1920,
                        height: 658 / 1080,
                        aspectRatio: "1:1"
                    }
                ]
            },
            "9:16": {

                focusRegions: [
                    {
                        x: 158 / 1920,
                        y: 28 / 1080,
                        width: 576 / 1920,
                        height: 1024 / 1080,
                        aspectRatio: "9:16"
                    },
                    {
                        x: 1203 / 1920,
                        y: 28 / 1080,
                        width: 576 / 1920,
                        height: 1024 / 1080,
                        aspectRatio: "9:16"
                    },
                    {
                        x: 158 / 1920,
                        y: 28 / 1080,
                        width: 576 / 1920,
                        height: 1024 / 1080,
                        aspectRatio: "9:16"
                    },
                    {
                        x: 1203 / 1920,
                        y: 28 / 1080,
                        width: 576 / 1920,
                        height: 1024 / 1080,
                        aspectRatio: "9:16"
                    }
                ]
            },
            "16:9": {

                focusRegions: [
                    {
                        x: 0 / 1920,
                        y: 208 / 1080,
                        width: 960 / 1920,
                        height: 540 / 1080,
                        aspectRatio: "16:9"
                    },
                    {
                        x: 960 / 1920,
                        y: 208 / 1080,
                        width: 960 / 1920,
                        height: 540 / 1080,
                        aspectRatio: "16:9"
                    },
                    {
                        x: 0 / 1920,
                        y: 208 / 1080,
                        width: 960 / 1920,
                        height: 540 / 1080,
                        aspectRatio: "16:9"
                    },
                    {
                        x: 960 / 1920,
                        y: 208 / 1080,
                        width: 960 / 1920,
                        height: 540 / 1080,
                        aspectRatio: "16:9"
                    },
                ]
            }
        }
    } else if (layoutName === "screenshare") {
        return {
            name: layoutName,
            "1:1": {
                focusRegions: [
                    {
                        x: 144 / 1920,
                        y: 144 / 1080,
                        width: 658 / 1920,
                        height: 658 / 1080,
                        aspectRatio: "1:1"
                    },
                    {
                        x: 0,
                        y: 0,
                        width: 1920 / 1920,
                        height: 1080 / 1080,
                        aspectRatio: "16:9"
                    }
                ]
            },
            "9:16": {

                focusRegions: [
                    {
                        x: 144 / 1920,
                        y: 144 / 1080,
                        width: 658 / 1920,
                        height: 658 / 1080,
                        aspectRatio: "1:1"
                    },
                    {
                        x: 0,
                        y: 0,
                        width: 1920 / 1920,
                        height: 1080 / 1080,
                        aspectRatio: "16:9"
                    }
                ]
            },
            "16:9": {

                focusRegions: [
                    {
                        x: 144 / 1920,
                        y: 144 / 1080,
                        width: 658 / 1920,
                        height: 658 / 1080,
                        aspectRatio: "1:1"
                    },
                    {
                        x: 0,
                        y: 0,
                        width: 1920 / 1920,
                        height: 1080 / 1080,
                        aspectRatio: "16:9"
                    }
                ]
            }
        }
    } else if (layoutName === 'gameplay') {
        return {
            name: layoutName,
            "1:1": {

                focusRegions: [
                    {
                        x: 0 / 1920,
                        y: 358 / 1080,
                        width: 960 / 1920,
                        height: 240 / 1080,
                        aspectRatio: "4:1"
                    },
                    {
                        x: 960 / 1920,
                        y: 138 / 1080,
                        width: 960 / 1920,
                        height: 720 / 1080,
                        aspectRatio: "4:3"
                    }
                ]
            },
            "9:16": {
                focusRegions: [
                    {
                        x: 0 / 1920,
                        y: 222 / 1080,
                        width: 960 / 1920,
                        height: 512 / 1080,
                        aspectRatio: "15:8"
                    },
                    {
                        x: 1022 / 1920,
                        y: 8 / 1080,
                        width: 855 / 1920,
                        height: 1064 / 1080,
                        aspectRatio: "45:56"
                    }
                ]
            },
            "16:9": {
                focusRegions: [
                    {
                        x: 960 / 1920,
                        y: 138 / 1080,
                        width: 960 / 1920,
                        height: 720 / 1080,
                        aspectRatio: "4:3"
                    },
                    {
                        x: 1300 / 1920,
                        y: 0 / 1080,
                        width: 480 / 1920,
                        height: 1080 / 1080,
                        aspectRatio: "4:9"
                    }
                ]
            }
        }
    } else if (layoutName === 'square') {
        return {
            name: layoutName,
            "1:1": {
                focusRegions: [
                    {
                        x: 144 / 1920,
                        y: 144 / 1080,
                        width: 658 / 1920,
                        height: 658 / 1080,
                        aspectRatio: "1:1"
                    },
                ]
            },
            "9:16": {
                focusRegions: [
                    {
                        x: 144 / 1920,
                        y: 144 / 1080,
                        width: 658 / 1920,
                        height: 658 / 1080,
                        aspectRatio: "1:1"
                    },
                ]
            },
            "16:9": {
                focusRegions: [
                    {
                        x: 144 / 1920,
                        y: 144 / 1080,
                        width: 658 / 1920,
                        height: 658 / 1080,
                        aspectRatio: "1:1"
                    },
                ]
            }
        }
    } else if (layoutName === 'portrait') {
        return {
            name: layoutName,
            "1:1": {
                focusRegions: [
                    {
                        x: 158 / 1920,
                        y: 28 / 1080,
                        width: 576 / 1920,
                        height: 1024 / 1080,
                        aspectRatio: "9:16"
                    },
                ]
            },
            "9:16": {
                focusRegions: [
                    {
                        x: 158 / 1920,
                        y: 28 / 1080,
                        width: 576 / 1920,
                        height: 1024 / 1080,
                        aspectRatio: "9:16"
                    },
                ]
            },
            "16:9": {
                focusRegions: [
                    {
                        x: 158 / 1920,
                        y: 28 / 1080,
                        width: 576 / 1920,
                        height: 1024 / 1080,
                        aspectRatio: "9:16"
                    },
                ]
            }
        }
    } else if (layoutName === 'landscape') {
        return {
            name: layoutName,
            "1:1": {
                focusRegions: [
                    {
                        x: 0,
                        y: 0,
                        width: 1920 / 1920,
                        height: 1080 / 1080,
                        aspectRatio: "16:9"
                    }
                ]
            },
            "9:16": {
                focusRegions: [
                    {
                        x: 0,
                        y: 0,
                        width: 1920 / 1920,
                        height: 1080 / 1080,
                        aspectRatio: "16:9"
                    }
                ]
            },
            "16:9": {
                focusRegions: [
                    {
                        x: 0,
                        y: 0,
                        width: 1920 / 1920,
                        height: 1080 / 1080,
                        aspectRatio: "16:9"
                    }
                ]
            }
        }
    } else {
        throw new Error(`Unsupported layout: ${layoutName}`);
    }
}