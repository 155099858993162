import React from "react";
import EditorSearchCommentItem from "./EditorSearchCommentItem";
import EditorSearchContextPanel from "./EditorSearchContextPanel";
import PropTypes from "prop-types";
import useBackboneModel from "./helper/useBackboneModel";
import { useEffect, useState, useRef } from 'react';
import Utils from "../models/helper/Utils";
import { Virtuoso } from 'react-virtuoso';
import { useWindowSize } from "@uidotdev/usehooks";
import ti18n from 'i18n!nls/Sequenceri18n';
import Empty from "./common/Empty";

const EditorSearchPanel = (props) =>
{

    const size = useWindowSize();
    const SEARCH_CONTEXT_WINDOW_HEIGHT = 132;
    const SEARCH_QUERY_PRESET_I18N_PREFIX = "AI_SEARCH_QUERY_PRESET_";
    const AI_SEARCH_RESULT_CONFIDENCE_LEVEL_PREFIX = "AI_SEARCH_RESULT_CONFIDENCE_LEVEL_";
    const getSearchQueryPresetTypeLabel = (searchPresetEnumValue)=>{
       return window.getI18n(ti18n, SEARCH_QUERY_PRESET_I18N_PREFIX+ searchPresetEnumValue);
    }

    const getSearchResultConfidenceLevelLabel = (confidenceLevelEnumValue)=>{
        return window.getI18n(ti18n, AI_SEARCH_RESULT_CONFIDENCE_LEVEL_PREFIX+ confidenceLevelEnumValue.toUpperCase());
     }

    const virtualListRef = useRef(null);
    const searchWidgetData = useBackboneModel(props.searchWidgetModel, (model) =>
    {
        return {
            searchRequestId: model.getSearchRequestId(),
            searchResultIndexInFocus: model.getIndexOfNodeInFocus(),
            numberOfSearchNodesFound: model.getNumberOfSearchNodesFound(),
            arrayOfSequencerNodeInFocus: model.getSequencerNodesCurrentlyInFocus(),
            canMoveToPreviousResult: model.canMoveToPreviousResult(),
            canMoveToNextResult: model.canMoveToNextResult(),
            allArraysOfNodesMatchingResults: model.getAllArraysOfNodesMatchingResults(),
            allArraysOfNodesToHiglights: model.getAllArraysOfNodesToHiglights(),
            allArraysOfAdditionalMatchingResults: model.getAllArraysOfAdditionalMatchingResults(),
            searchParams: model.getSearchParams(),
            searchType: model.getSearchType(),
            searchOn: model.isSearchOn()
        }
    });

    const [ hasResults, setHasResults ] = useState(searchWidgetData.arrayOfSequencerNodeInFocus && searchWidgetData.arrayOfSequencerNodeInFocus.length > 0);

    useEffect(() =>
    {
        setHasResults(searchWidgetData.arrayOfSequencerNodeInFocus && searchWidgetData.arrayOfSequencerNodeInFocus.length > 0);
    }, [ searchWidgetData ]);


    const [ searchResultsInfo, setSearchResultsInfo ] = useState([]);

    const [ visibleRange, setVisibleRange ] = useState({
        startIndex: 0,
        endIndex: 0,
    })

    useEffect(() =>
    {
        if (searchWidgetData.allArraysOfNodesMatchingResults && searchWidgetData.allArraysOfNodesMatchingResults.length > 0)
        {
            setSearchResultsInfo(searchWidgetData.allArraysOfNodesMatchingResults.map((searchResultArray, index) =>
            {
                return { 
                    searchRequestId: searchWidgetData.searchRequestId,
                    description: Utils.getInstance().getSequencerSearchResultItemDescription(searchResultArray),
                    uid: searchWidgetData.allArraysOfAdditionalMatchingResults.length > 0 ?searchWidgetData.allArraysOfAdditionalMatchingResults[index].uid: null,
                    reason: searchWidgetData.allArraysOfAdditionalMatchingResults.length > 0 ? searchWidgetData.allArraysOfAdditionalMatchingResults[index].reason : null,
                    type: searchWidgetData.allArraysOfAdditionalMatchingResults.length > 0 ? getSearchQueryPresetTypeLabel(searchWidgetData.allArraysOfAdditionalMatchingResults[index].type) : null,
                    confidence: searchWidgetData.allArraysOfAdditionalMatchingResults.length > 0 ? getSearchResultConfidenceLevelLabel(searchWidgetData.allArraysOfAdditionalMatchingResults[index].confidence) : null,
                    searchResultArray, sequencer: props.sequencer, index: index };
            }));
        } else
        {
            setSearchResultsInfo([]);
        }
    }, [ searchWidgetData]);

    useEffect(() =>
    {
        if (searchWidgetData.searchResultIndexInFocus < visibleRange.startIndex || searchWidgetData.searchResultIndexInFocus > visibleRange.endIndex)
        {
            virtualListRef.current.scrollToIndex({
                index: searchWidgetData.searchResultIndexInFocus
            });
        }
    }, [ searchWidgetData ]);


    const CloseButton = () =>
    {
        return <button onClick={ props.onPanelCloseButtonClick } data-tippy-content={`
        <div class='tippy_tooltip_content'>
            <div class='tooltip_message'>${window.getI18n(ti18n, "CLOSE")}</div>
            <span class='keyboard_shortcut'>
                <span class='key_button'>escape</span>
            </span>
        </div>
        `} className="hint--bottom absolute right-0 top-0"><span className="icon pe-7s-close text-3xl"></span></button>
    }
    const [ panelOpened, setPanelOpened ] = useState(false);

    useEffect(() =>
    {
        if (props.onPanelOpen)
        {
            props.onPanelOpen();
            setPanelOpened(true);
        }
    }, []);



    return (
        <div className="editor-search-browse-panel flex flex-col p-2 h-full w-full max-w-sm relative" style={ { width: "310px" } }>

            <CloseButton />

            <EditorSearchContextPanel { ...props } panelOpened={ panelOpened } className="flex-initial fixed" />
            <div className="flex flex-col overflow-auto flex-auto flex-auto add_gradient_at_bottom">
                { !hasResults && <div className="flex flex-col items-center justify-center h-full">{searchWidgetData.searchParams && <Empty useEditorTheme={true} description={window.getI18n(ti18n, "NO_RESULT_FOUND")}/>}</div> }
                <Virtuoso
                    ref={ virtualListRef }
                    rangeChanged={ setVisibleRange }
                    className={ !hasResults ? "hidden" : "" }
                    style={ { height: props.listHeightOffset ? size.height - props.listHeightOffset - SEARCH_CONTEXT_WINDOW_HEIGHT : size.height } }
                    totalCount={ searchResultsInfo.length }
                    itemContent={ (index) =>
                    {
                        const aResultInfo = searchResultsInfo[ index ];
                        return <div className="px-2 py-1" key={ index }><EditorSearchCommentItem searchType={searchWidgetData.searchType} selected={ searchWidgetData.searchResultIndexInFocus === index } { ...aResultInfo } onApplyButtonClick={ () => { 
                            props.sequencer.deleteSelectedNodes(aResultInfo.searchResultArray[ 0 ], aResultInfo.searchResultArray[ aResultInfo.searchResultArray.length - 1 ]) 
                        } 
                        } onClick={ () => { props.searchWidgetModel.moveToNextFoundNode(true, index) } } /></div>
                    } }
                />

            </div>
        </div>
    )
}

EditorSearchPanel.propTypes = {
    searchDisplayTitle: PropTypes.string.isRequired,
    resultCursor: PropTypes.number,
    totalNumberOfResults: PropTypes.number,
    applyAllActionButtonLabel: PropTypes.string,
    listHeight: PropTypes.number,
    listHeightOffset: PropTypes.number,
    searchWidgetModel: PropTypes.object,
    sequencer: PropTypes.object,
    comments: PropTypes.array,
    onPanelOpen: PropTypes.func,
    onPanelCloseButtonClick: PropTypes.func,
}


export default EditorSearchPanel;