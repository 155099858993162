export const SplitCircle = ({
    color1 = "#6B46C1",
    color2 = "#2D3748",
    size = "w-32 h-32",
  }) => (
    <svg viewBox="0 0 100 100" className={size}>
      <defs>
        <clipPath id="left-half">
          <path d="M0 0 L50 0 L50 100 L0 100 Z" />
        </clipPath>
        <clipPath id="right-half">
          <path d="M50 0 L100 0 L100 100 L50 100 Z" />
        </clipPath>
      </defs>
      <circle cx="50" cy="50" r="50" fill={color1} clipPath="url(#left-half)" />
      <circle cx="50" cy="50" r="50" fill={color2} clipPath="url(#right-half)" />
    </svg>
  );