import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import ClipCreatorView from "views/clipCreator/ClipCreatorPopupView";
import FileUploadHelper from "models/helper/FileUploadHelper";
import ti18n from "i18n!nls/ClipCreatori18n";
import RSVP from "rsvp";

const Rollout = RolloutHelper.getInstance();
const useTemporaryLocation = false;


const ClipCreatorPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this._videoDataForClipCreation = options.videoDataForClipCreation;
        this._relatedProjectId = options.relatedProjectId;
        this._deleteEverythingExceptSelectionAndGetProjectIdFunction = options.deleteEverythingExceptSelectionAndGetProjectIdFunction;
        this._createAudioAndSaveInCloudFunction = options.createAudioAndSaveInCloudFunction;
    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    onPopupClosed : function(){

    },


    onClipCreationTriggered : function(){
        this.view.close();
    },

    _onRenderClipButtonClicked : async function(dataToRenderJson ){
        // Sanitize the data to remove circular references
        const sanitizedData = JSON.parse(JSON.stringify(dataToRenderJson));
        sanitizedData.inCloudRenderingEnvironment = true
        const {presignedUrl: presignedUrlForClipVideoRenderingData, fileLocationUrl: fileLocationUrlForClipVideoRendereingData} = useTemporaryLocation? await TrebbleClientAPIHelper.getInstance().getPresignedUrlForUploadingTemporaryAudioOrVideoFile("application/json") : await TrebbleClientAPIHelper.getInstance().getPresignedUrlForUploadingRemotionClipRenderingInputData();
        await FileUploadHelper.getInstance().uploadJSONToS3UsignPreSignedUrl(sanitizedData,presignedUrlForClipVideoRenderingData);
        const projectId = await this._deleteEverythingExceptSelectionAndGetProjectIdFunction();
        const cloudAudioUrl = await this._createAudioAndSaveInCloudFunction();
        await TrebbleClientAPIHelper.getInstance().startClipVideoRenderingInCloud(projectId, cloudAudioUrl, null, useTemporaryLocation, sanitizedData, fileLocationUrlForClipVideoRendereingData, this._relatedProjectId);
        this.view.close();
        window.alertSuccessMessage(window.getI18n(ti18n,"YOUR_VIDEO_IS_BEING_PROCESSED"),window.getI18n(ti18n, "WE_WILL_NOTIFY_WHEN_YOUR_VIDEO_IS_READY_TO_DOWNLOAD"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("audioEditor", "start video rendering in the cloud", "start video rendering in the cloud");
        }
    },

    
    buildModelAndRenderView : function() {
        const promises = [];

        
        return RSVP.Promise.all(promises).then((function(){
            const params = {videoDataForClipCreation:this._videoDataForClipCreation, renderVideoClip: this._onRenderClipButtonClicked.bind(this)};
            
            this.view = new ClipCreatorView(params);
            this.listenTo(this.view, "closed", this.onPopupClosed);
            this.listenTo(this.view, "renderVideoClip", this._onRenderClipButtonClicked);

            this.view.render();

        }).bind(this))


    }

});

export default ClipCreatorPopupController;