import { CaptionPage as CaptionPageType } from "../CaptionGrouper";
import { fitText } from "@remotion/layout-utils";
import { useCurrentFrame, useVideoConfig } from "remotion";
import { Position } from "../../DesignSystem";

interface CaptionPageProps {
    page: CaptionPageType;
    fontFamily: string;
    currentSpokenWordColor: string;
    maxFontSize: number;
    position: Position;
}

export const ModernCaptionPage: React.FC<CaptionPageProps> = ({ page, fontFamily, currentSpokenWordColor, maxFontSize }) => {
    const { fps, width } = useVideoConfig();
    const frame = useCurrentFrame();
    const timeInMs = frame / fps * 1000;

    const fittedText = fitText({
        fontFamily: fontFamily,
        text: page.text,
        withinWidth: width * 0.9,
    });

    const fontSize = Math.min(maxFontSize, fittedText.fontSize);

    return (
        <span
            style={{
                fontSize,
                color: "white",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 10,
                paddingBottom: 10,
                fontFamily,
                borderRadius: 25,
            }}
        >
            {page.tokens.map((t) => {
                const startRelativeToSequence = t.fromMs - page.startMs;
                const endRelativeToSequence = t.toMs - page.startMs;

                const active =
                    startRelativeToSequence <= timeInMs &&
                    endRelativeToSequence > timeInMs;

                return (
                    <span
                        key={t.fromMs}
                        style={{
                            display: "inline",
                            whiteSpace: "pre",
                            textDecoration: active ? `underline ${currentSpokenWordColor}` : "none",
                            textDecorationThickness: active ? "20px" : "0px",
                            textDecorationSkipInk: "none",
                            color: "white",
                        }}
                    >
                        {t.text}
                    </span>
                );
            })}
        </span>
    );
}