import { Sequence, useVideoConfig } from "remotion";
import { VideoMultiFocusDesignSystem } from "../VideoMultiFocus";
import { VideoSegmentMultiFocusDesignSystem } from "../../VideoSegmentMultiFocus/VideoSegmentMultiFocus";

export const DefaultVideoMultiFocusSeries: React.FC<VideoMultiFocusDesignSystem> = ({ designSystem, videoRenderData, scale, borderRadius, padding, border, framing, firstScheduledNode, startTimeOffset, inCloudRenderingEnvironment, audioURL }) => {
    const { fps } = useVideoConfig();

    return (
        <>
            {
                videoRenderData.map((segment, index) => {
                    const videoSegmentElementDualFocus = new VideoSegmentMultiFocusDesignSystem({
                        designSystem: designSystem,
                        startTimestampMS: segment.startTimeOffsetForSourceBuffer - segment.relativeStartTime ,
                        endTimestampMS: segment.startTimeOffsetForSourceBuffer - segment.relativeStartTime + segment.duration + segment.relativeEndTime,
                        videoURL: inCloudRenderingEnvironment? segment?.sequencerNodeReference?.audioSegment?.audioUrl : segment.cachedBlobUrl,
                        borderRadius: borderRadius,
                        spacing: padding,
                        border: border,
                        framing: framing,
                        audioURL: audioURL
                    })

                    return (
                        <Sequence
                            key={index}
                            from={(segment.startTimeOffset - segment.relativeStartTime - startTimeOffset )/ 1000 * fps }
                            durationInFrames={(segment.duration + segment.relativeEndTime)/ 1000 * fps}
                            premountFor={200}
                        >
                            {videoSegmentElementDualFocus.render()}
                        </Sequence>
                    )
                })
            }
        </>
    );
}