import { ColorPicker, Flex, Upload, Segmented, Card, Modal, UploadFile } from "antd";
import { CheckCircleTwoTone, PlusOutlined } from "@ant-design/icons";
import { Theme } from "../../VideoEditor";

const BACKGROUND_STYLES = Object.freeze({
    COLOR: {
        value: "color",
        label: "Color",
    },
    IMAGE: {
        value: "image",
        label: "Image",
    },
});


export const BackgroundComponent = ({ selectedTheme, setSelectedTheme }: { selectedTheme: Theme, setSelectedTheme: (theme: Theme) => void }) => {
    const fileList: UploadFile[] = [];
    for (let i = 0; i < 7; i++) {
        fileList.push({
            uid: i.toString(),
            name: `image-${i}.png`,
            status: "done",
            url: `https://picsum.photos/id/${i + 10}/1920/1080/?blur=10`,
        });
    }
    fileList.push({
        uid: "7",
        name: "blue.png",
        status: "done",
        url: "https://images6.alphacoders.com/376/thumb-1920-376931.jpg",
    });

    const ImageSelectionComponent = ({ selectedImageURL }: { selectedImageURL: string }) => {
        return (
            <div className="p-4">
                <Flex vertical gap="small">
                    {fileList.map((image, index) => (
                        <Card
                            key={image.uid}
                            hoverable
                            cover={<img src={image.url} alt={`Image ${index + 1}`} />}
                            onClick={() => setSelectedTheme({ ...selectedTheme, backgroundDesignConfig: { ...selectedTheme.backgroundDesignConfig, imageURL: image.url!, format: 'image' } })}
                            styles={{ body: { padding: 0 } }}
                            className={`${selectedImageURL === image.url ? "ant-card-selected" : ""
                                }`}
                        >
                            {selectedImageURL === image.url && (
                                <CheckCircleTwoTone
                                    twoToneColor="#52c41a"
                                    style={{ position: "absolute", top: 8, right: 8, fontSize: 24 }}
                                />
                            )}
                        </Card>
                    ))}
                    <Card
                        hoverable
                        onClick={undefined}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px dashed #d9d9d9",
                        }}
                    >
                        <PlusOutlined style={{ fontSize: 24 }} />
                    </Card>
                </Flex>

                <Modal
                    title="Upload Image"
                    open={false}
                    onCancel={undefined}
                    footer={null}
                >
                    <Upload
                        accept="image/*"
                        showUploadList={false}
                        customRequest={undefined}
                    >
                        <div className="p-4 border border-dashed rounded-lg text-center">
                            <p>Click or drag file to this area to upload</p>
                        </div>
                    </Upload>
                </Modal>
            </div>
        );
    };
    return (
        <div className="flex flex-col">
            <div>Background style:</div>

            <Segmented
                options={Object.values(BACKGROUND_STYLES)}
                value={selectedTheme.backgroundDesignConfig.format}
                onChange={(value) => setSelectedTheme({ ...selectedTheme, backgroundDesignConfig: { ...selectedTheme.backgroundDesignConfig, format: value as 'image' | 'color' } })}
            />

            {selectedTheme.backgroundDesignConfig.format === 'color' && (
                <div>
                    <div>Background color:</div>
                    <ColorPicker
                        value={selectedTheme.backgroundDesignConfig.color}
                        onChange={(color) => setSelectedTheme({ ...selectedTheme, backgroundDesignConfig: { ...selectedTheme.backgroundDesignConfig, color: color.toHexString(), format: 'color' } })}
                    />
                </div>
            )}
            {selectedTheme.backgroundDesignConfig.format === 'image' && (
                <ImageSelectionComponent selectedImageURL={selectedTheme.backgroundDesignConfig.imageURL} />
            )}
        </div>
    );
};