import { DesignSystem, ElementDesignSystem } from "../DesignSystem";
import dataScheme from "../../video_data_to_render.json";
import { DefaultVideoMultiFocusSeries } from "./DefaultVideoMultiFocus/DefaultVideoMultiFocusSeries";
import { FramingOptions } from "../VideoSegmentMultiFocus/VideoSegmentMultiFocus";

export interface VideoMultiFocusDesignSystemConfig {
    borderRadius: number,
    padding: boolean,
    border: boolean,
    scale: number,
    framing: FramingOptions,
    audioURL?: string,
}

export class VideoMultiFocusDesignSystem extends ElementDesignSystem implements VideoMultiFocusDesignSystemConfig {
    public videoRenderData: typeof dataScheme;
    public startTimeOffset: number;
    public firstScheduledNode : any;
    public borderRadius: number;
    public padding: boolean;
    public border: boolean;
    public scale: number;
    public framing: FramingOptions;
    public audioURL?: string;

    constructor(
        config: VideoMultiFocusDesignSystemConfig,
        designSystem: DesignSystem,
        videoRenderData: typeof dataScheme,
        startTimeOffset: number,
        inCloudRenderingEnvironment:boolean,
        firstScheduledNode : any
    ) {
        super({...config, designSystem: designSystem, position: 'center center', animation: undefined, affectedBySafeZone: false, inCloudRenderingEnvironment});
        this.videoRenderData = videoRenderData;
        this.borderRadius = config.borderRadius;
        this.padding = config.padding;
        this.border = config.border;
        this.scale = config.scale;
        this.framing = config.framing;
        this.startTimeOffset = startTimeOffset;
        this.firstScheduledNode = firstScheduledNode;
        this.audioURL = config.audioURL;
    }

    protected renderChild() {
        return (
            <DefaultVideoMultiFocusSeries {...this} />
        );
    }

}