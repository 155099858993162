import { CaptionPage as CaptionPageType } from "../CaptionGrouper";
import { fitText } from "@remotion/layout-utils";
import { useCurrentFrame, useVideoConfig, interpolate, spring } from "remotion";
import { Position, VideoCanvas, getSafeZonedWidth } from "../../DesignSystem";
import { loadFont as tinos } from "@remotion/google-fonts/Tinos";
import { loadFont as roboto } from "@remotion/google-fonts/Roboto";
import { loadFont as lato } from "@remotion/google-fonts/Lato";

interface CaptionPageProps {
    page: CaptionPageType;
    fontFamily: 'tinos' | 'roboto' | 'lato';
    primaryColor: string
    currentSpokenWordColor: string;
    maxFontSize: number;
    position: Position;
    canvas: VideoCanvas;
    outline: 'none' | 'glow' | 'shadow' | '3d-shadow' | 'stroke' | '3d-stroke';
    background: 'none' | 'white'|'colored' | 'translucent';
    textAnimation: 'none' | 'highlight' | 'boxed' | 'scale' | 'pop'| 'karaoke'| 'reveal'| 'reveal_up'|'reveal_scale'|'reveal_box'|'karaoke_scale'|'karaoke_box';
    borderRadius: number;
    captionCase: 'standard' | 'uppercase';
    hidePunctuation: boolean;
    containerPadding?: {
        x: number; // Horizontal padding in pixels
        y: number; // Vertical padding in pixels
    };
    boxPadding?: {
        x: number; // Horizontal padding in pixels
        y: number; // Vertical padding in pixels
    };
}

const fontMap = {
    'tinos': tinos().fontFamily,
    'roboto': roboto().fontFamily,
    'lato': lato().fontFamily
}

const backgroundToCSS = (background: CaptionPageProps["background"], primaryColor: CaptionPageProps["primaryColor"]) => {
    switch (background) {
        case 'none':
            return 'transparent';
        case 'white':
            return 'rgba(255, 255, 255, 1)';
        case 'colored':
            return primaryColor;
        case 'translucent':
            return 'rgba(0, 0, 0, 0.7)';
    }
}

// Update the contrast calculation function to favor white text
const getContrastColor = (hexColor: string): string => {
    hexColor = colorNameToHex(hexColor);
    // Convert hex to RGB
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
    
    // Calculate luminance using the formula for relative luminance in sRGB space
    // This follows the W3C accessibility guidelines
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    
    // Use a higher threshold to favor white text
    // Only use black text for very light colors (luminance > 0.7)
    return luminance > 0.7 ? 'black' : 'white';
};

const isTextWithOutline =(outline: CaptionPageProps["outline"]):boolean =>{
    return outline === '3d-shadow'|| outline === 'stroke' || outline === '3d-stroke';
}

function colorNameToHex(color: string): string {
    const colors: Record<string, string> = {
        black: "#000000",
        white: "#FFFFFF",
        red: "#FF0000",
        green: "#008000",
        blue: "#0000FF",
        yellow: "#FFFF00",
        cyan: "#00FFFF",
        magenta: "#FF00FF",
        gray: "#808080",
        orange: "#FFA500",
        purple: "#800080",
        pink: "#FFC0CB",
        brown: "#A52A2A"
    };
    return colors[color.toLowerCase()] || color; // Return hex if found, otherwise assume it's already a hex value
}

// Function to convert RGB to luminance
function rgbToLuminance(r: number, g: number, b: number): number {
    const a = [r / 255, g / 255, b / 255];
    for (let i = 0; i < 3; i++) {
        if (a[i] <= 0.03928) {
            a[i] /= 12.92;
        } else {
            a[i] = Math.pow((a[i] + 0.055) / 1.055, 2.4);
        }
    }
    return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
}

// Function to calculate contrast ratio
function calculateContrastRatio(luminance1: number, luminance2: number): number {
    const light = Math.max(luminance1, luminance2);
    const dark = Math.min(luminance1, luminance2);
    return (light + 0.05) / (dark + 0.05);
}

// Function to check contrast and return result as JSON
function checkContrast(fixedColor: string, changeableColor: string): {
    sufficientContrast: boolean,
    replacementColor: string
} {
     // Convert color name to hex if needed
     fixedColor = colorNameToHex(fixedColor);
     changeableColor = colorNameToHex(changeableColor);
    // Convert hex color to RGB
    function hexToRgb(hex: string): [number, number, number] {
        let r: number, g: number, b: number;
        if (hex.startsWith("#")) {
            hex = hex.slice(1);
        }
        r = parseInt(hex.substring(0, 2), 16);
        g = parseInt(hex.substring(2, 4), 16);
        b = parseInt(hex.substring(4, 6), 16);
        return [r, g, b];
    }

    // Convert hex colors to RGB
    const [fixedR, fixedG, fixedB] = hexToRgb(fixedColor);
    const [changeableR, changeableG, changeableB] = hexToRgb(changeableColor);

    // Calculate luminance for both colors
    const luminanceFixed = rgbToLuminance(fixedR, fixedG, fixedB);
    const luminanceChangeable = rgbToLuminance(changeableR, changeableG, changeableB);

    // Calculate contrast ratio
    const contrast = calculateContrastRatio(luminanceFixed, luminanceChangeable);

    // Check if the contrast is sufficient
    if (contrast < 4.5) {
        // Calculate contrast with black (#000000) and white (#FFFFFF)
        const luminanceBlack = rgbToLuminance(0, 0, 0);
        const luminanceWhite = rgbToLuminance(255, 255, 255);
        const contrastWithBlack = calculateContrastRatio(luminanceFixed, luminanceBlack);
        const contrastWithWhite = calculateContrastRatio(luminanceFixed, luminanceWhite);

        // Return the JSON object with replacement color
        return {
            sufficientContrast: false,
            replacementColor: contrastWithBlack > contrastWithWhite ? "black" : "white"
        };
    }

    // If the contrast is sufficient, return JSON with sufficient contrast
    return {
        sufficientContrast: true,
        replacementColor: "black"
    };
}

const isTextAnimationWithBox = (textAnimation: CaptionPageProps["textAnimation"]): boolean =>{
    return textAnimation === 'boxed' || textAnimation === 'karaoke_box' || textAnimation === 'reveal_box';
}

const isTextAnimationWithTextHighlightColor = (textAnimation: CaptionPageProps["textAnimation"]): boolean =>{
    return !isTextAnimationWithBox(textAnimation) && textAnimation !== 'none';
}


const getOutlineAndTextColorForStyle = (
    outline: CaptionPageProps["outline"],
    textAnimation: CaptionPageProps["textAnimation"],
    active: boolean,
    background: CaptionPageProps["background"],
    currentSpokenWordColor: string,
    primaryColor: string
): { outlineColor: string|undefined; textColor: string } => {
    const hasOutline =  isTextWithOutline(outline);
    

    let backgroundColor = background === 'none'? undefined:( background === "translucent"?'black':(background === 'white'?'white': primaryColor));
    let outlineColor = hasOutline?primaryColor: undefined;
    if(outlineColor && backgroundColor){
        if(outlineColor === backgroundColor){
            outlineColor = getContrastColor(backgroundColor);
        }
        const backgroundToOutlineContrastAnalysis = checkContrast(backgroundColor, outlineColor);
        if(!backgroundToOutlineContrastAnalysis.sufficientContrast){
            outlineColor = backgroundToOutlineContrastAnalysis.replacementColor
        }
    }
    let textColor = primaryColor;
    if(textColor === backgroundColor && backgroundColor=== primaryColor){
        textColor = getContrastColor(backgroundColor);
        if(outlineColor && outlineColor === textColor){
            //outlineColor = "black";
            textColor = getContrastColor(outlineColor)
        }
    }
    if(textColor === outlineColor){
        textColor = getContrastColor(outlineColor);
    }
    if(!active){
        return {outlineColor, textColor};
    }else{
        if(isTextAnimationWithBox(textAnimation)){
            if(!outlineColor){
                const highlightToTextColorContrastAnalysis = checkContrast(currentSpokenWordColor, textColor);
                if(!highlightToTextColorContrastAnalysis.sufficientContrast){
                    textColor = highlightToTextColorContrastAnalysis.replacementColor;
                }
            }
            return {outlineColor, textColor};
        }
        if(isTextAnimationWithTextHighlightColor(textAnimation)){
            textColor = currentSpokenWordColor
            if(outlineColor){
                const outlinetToTextColorContrastAnalysis = checkContrast(outlineColor, textColor);
                if(!outlinetToTextColorContrastAnalysis.sufficientContrast){
                    outlineColor = currentSpokenWordColor;
                    textColor = getContrastColor(outlineColor);
                }
            }
        }
        return {outlineColor, textColor};
    }

}


export const ClassicCaptionPage: React.FC<CaptionPageProps> = ({ page, fontFamily, canvas, primaryColor, currentSpokenWordColor, maxFontSize, outline, background, textAnimation, borderRadius, captionCase, hidePunctuation, containerPadding = { x: 0.1, y: 0.05 }, boxPadding = { x: 0, y: 0 } }) => {
    const { fps, width } = useVideoConfig();
    const safeZonedWidth = getSafeZonedWidth(width, canvas)
    const frame = useCurrentFrame();
    const timeInMs = frame / fps * 1000;




    const processText = (text: string): string => {
        let processedText = text.trim(); // Add trim to remove any trailing spaces
        if (hidePunctuation) {
            processedText = processedText.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
        }
        if (captionCase === 'uppercase') {
            processedText = processedText.toUpperCase();
        }
        return processedText;
    }

    const fittedText = fitText({
        fontFamily: fontMap[fontFamily],
        text: processText(page.text),
        withinWidth: safeZonedWidth * 0.9,
    });

    const fontSize = Math.min(maxFontSize, fittedText.fontSize);

    // Calculate actual padding in pixels based on fontSize
    const actualBoxPadding = {
        x: fontSize * boxPadding.x,
        y: fontSize * boxPadding.y
    };

    const actualContainerPadding = {
        x: fontSize * containerPadding.x,
        y: fontSize * containerPadding.y
    };

    // Convert borderRadius to be fontSize-based
    const actualBorderRadius = fontSize * borderRadius;

    // Calculate the total width including spaces
    const getTotalWidth = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) return 0;

        context.font = `${fontSize}px ${fontFamily}`;
        // Add a small space at the end to prevent cutoff
        const fullText = page.tokens.map(t => processText(t.text)).join(' ') + ' ';
        return context.measureText(fullText).width;
    };

    const totalWidth = getTotalWidth();
    
    // Calculate extra space needed for text effects - only for x-axis
    const effectsPadding = (() => {
        if (outline === '3d-shadow' || outline === '3d-stroke') {
            return fontSize * 0.15;
        } else if (outline === 'stroke') {
            return fontSize * 0.1;
        } else if (outline === 'shadow' || outline === 'glow') {
            return fontSize * 0.08; // Increased from 0.05 for glow/shadow
        }
        return 0;
    })();

    // Only add base padding if we have container padding - only for x-axis
    const basePadding = containerPadding.x === 0
        ? (outline === 'none' ? 0 : fontSize * 0.05) // Increased from 0.03
        : fontSize * 0.1; // Increased from 0.08

    const svgPadding = basePadding + effectsPadding;
    const containerWidth = totalWidth + 
        (actualContainerPadding.x * 2) + // Container padding
        (svgPadding * 2) + // Effects padding
        (fontSize * 0.1); // Increased from 0.03 to prevent cutoff



    
    // Calculate SVG height more precisely with tighter dimensions
    const svgHeight = (() => {
        // Base height calculation - reduce base height for all styles
        let height = fontSize * 1.15; // Reduced from 1.2
        
        // Add extra space for 3D effects - make all styles tighter
        if (outline === '3d-stroke') {
            height = fontSize * 1.25; // Already optimized
        } else if (outline === '3d-shadow') {
            height = fontSize * 1.2; // Reduced from 1.3 to make tighter
        } else if (outline === 'shadow') {
            height = fontSize * 1.15; // Reduced from 1.2 to make tighter
        } else if (outline === 'stroke') {
            height = fontSize * 1.15; // Reduced from 1.2 to make tighter
        } else if (outline === 'glow') {
            height = fontSize * 1.15; // Reduced from 1.2 to make tighter
        } else {
            // For 'none' outline
            height = fontSize * 1.1; // Even tighter
        }
        
        // Add container padding
        height += (actualContainerPadding.y * 2);
        
        return height;
    })();

    // Define the getWordPositions function
    const getWordPositions = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) return [];

        context.font = `${fontSize}px ${fontFamily}`;
        
        const words = page.tokens.map(t => processText(t.text));
        const spaceWidth = context.measureText(' ').width;
        
        // Calculate total width including spaces between words
        const totalTextWidth = words.reduce((acc, word, i) => {
            const wordWidth = context.measureText(word).width;
            return acc + wordWidth + (i > 0 ? spaceWidth : 0);
        }, 0);
        
        // Center the text block within the container
        const startX = (containerWidth - totalTextWidth) / 2;
        
        let currentX = startX;
        const positions = page.tokens.map((token, index) => {
            if (index > 0) {
                currentX += spaceWidth;
            }

            const text = processText(token.text);
            const width = context.measureText(text).width;
            const position = {
                x: currentX,
                width: width
            };
            currentX += width;

            return position;
        });

        return positions;
    };

    // First, let's improve the centerY calculation in the existing code
    const centerY = (() => {
        // Base center position
        let center = svgHeight / 2;
        
        // Adjust vertical position for different outline styles
        if (outline === '3d-stroke') {
            center = svgHeight / 2 - fontSize * 0.02; // Already optimized
        } else if (outline === '3d-shadow') {
            center = svgHeight / 2 - fontSize * 0.02; // Reduced from 0.05 for less upward shift
        }
        
        return center;
    })();


    // Calculate positions once at the top level
    const positions = getWordPositions();

    // Update the getKaraokeOpacity function to use a consistent value
    const getKaraokeOpacity = (tokenStartMs: number, pageStartMs: number, currentTimeMs: number) => {
        const isUnspoken = tokenStartMs - pageStartMs > currentTimeMs;
        // Use a consistent opacity value of 0.3 for all unspoken words
        if (isUnspoken) {
            return 0.3;
        }
        return 1;
    };

    // Add this helper function near the other helper functions
    const getRevealProgress = (tokenStartMs: number, tokenEndMs: number, pageStartMs: number, currentTimeMs: number) => {
        // Not started yet
        if (tokenStartMs - pageStartMs > currentTimeMs) {
            return 0;
        }
        // Already finished
        if (tokenEndMs - pageStartMs <= currentTimeMs) {
            return 1;
        }
        // In progress
        const duration = tokenEndMs - tokenStartMs;
        const progress = (currentTimeMs - (tokenStartMs - pageStartMs)) / duration;
        return Math.max(0, Math.min(1, progress));
    };

    // Helper function to render text tokens consistently
    const renderTokens = (layerType: 'glow' | 'main' | 'other') => {
        return page.tokens.map((t, index) => {
            const startRelativeToSequence = t.fromMs - page.startMs;
            const endRelativeToSequence = t.toMs - page.startMs;
            const active = startRelativeToSequence <= timeInMs && endRelativeToSequence > timeInMs;

            // Calculate base transforms
            const wordWidth = positions[index].width;
            const wordX = positions[index].x;
            const wordCenterX = wordX + (wordWidth / 2);

            // Calculate scale transform
            const scale = (active && (
                textAnimation === 'scale' || 
                textAnimation === 'reveal_scale' || 
                (textAnimation === 'karaoke_scale' && getKaraokeOpacity(t.fromMs, page.startMs, timeInMs) === 1)
            ))
                ? spring({
                    frame: frame - Math.floor(startRelativeToSequence / (1000 / fps)),
                    fps,
                    from: 1,
                    to: 1.05,
                    durationInFrames: 20,  // Increased from 15 for smoother transition
                    config: {
                        damping: 20,      // Increased from 15 for smoother ending
                        stiffness: 100,   // Reduced from 120 for gentler movement
                        mass: 0.6         // Reduced from 0.8 for quicker response
                    }
                })
                : 1;

            const scaleTransform = scale !== 1
                ? `translate(${wordCenterX} ${centerY}) scale(${scale}) translate(${-wordCenterX} ${-centerY})`
                : '';

            // Calculate reveal transform
            let revealTransform = '';
            let opacity = 1;
            if (textAnimation === 'reveal' || textAnimation === 'reveal_scale' || textAnimation === 'reveal_box' || textAnimation === 'reveal_up') {
                const revealProgress = getRevealProgress(t.fromMs, t.toMs, page.startMs, timeInMs);
                
                if (revealProgress === 0) {
                    // For reveal_up, move down instead of left (starting position)
                    revealTransform = textAnimation === 'reveal_up' 
                        ? `translate(0, ${fontSize * 0.3})` 
                        : `translate(-${fontSize * 0.3}, 0)`;
                    opacity = 0;
                } else {
                    const slideAmount = spring({
                        frame: frame - Math.floor((t.fromMs - page.startMs) / (1000 / fps)),
                        fps,
                        from: 0,
                        to: 1,
                        durationInFrames: 12,
                        config: {
                            damping: 15,
                            stiffness: 200,
                            mass: 0.6
                        }
                    });

                    // For reveal_up, interpolate vertical movement with same distance
                    if (textAnimation === 'reveal_up') {
                        const slideY = interpolate(slideAmount, [0, 1], [fontSize * 0.3, 0]);
                        revealTransform = `translate(0, ${slideY})`;
                    } else {
                        const slideX = interpolate(slideAmount, [0, 1], [-fontSize * 0.3, 0]);
                        revealTransform = `translate(${slideX}, 0)`;
                    }
                    opacity = 1;
                }
            }

            // Update the combinedTransform to include reveal_up
            const combinedTransform = [
                scaleTransform, 
                (textAnimation === 'reveal' || 
                 textAnimation === 'reveal_scale' || 
                 textAnimation === 'reveal_box' || 
                 textAnimation === 'reveal_up') ? revealTransform : '',
            ].filter(Boolean).join(' ');

            // Calculate the karaoke opacity early
            const karaokeOpacity = (textAnimation === 'karaoke' || 
                textAnimation === 'karaoke_box' || 
                textAnimation === 'karaoke_scale') 
                ? getKaraokeOpacity(t.fromMs, page.startMs, timeInMs) 
                : 1;

            // Combine karaoke opacity with reveal opacity
            const finalOpacity = karaokeOpacity * opacity;
            

            // Then fix the 3D stroke implementation
            if (outline === '3d-stroke') {
                if (layerType === 'other') {
                    if (opacity === 0) return null;
   
                    // Use the helper function to determine outline color
                    const outlineColor = getOutlineAndTextColorForStyle(outline,textAnimation,active,background,currentSpokenWordColor,primaryColor).outlineColor;
                    
                    
                    // Calculate base opacity for unspoken words in karaoke mode
                    const karaokeBaseOpacity = (textAnimation === 'karaoke' || 
                        textAnimation === 'karaoke_box' || 
                        textAnimation === 'karaoke_scale') 
                        ? getKaraokeOpacity(t.fromMs, page.startMs, timeInMs) 
                        : 1;
                    
                    // Calculate final base opacity including karaoke effect
                    // Reduce the opacity further for 3D stroke to match other effects
                    const baseOpacity = 0.15; // Reduced from 0.3 to 0.15 for 3D stroke
                    const finalBaseOpacity = karaokeBaseOpacity === 1 ? 1 : baseOpacity;
                    
                    return [
                        // Create multiple layers for the 3D depth effect - extending down and right
                        ...Array.from({ length: 4 }).map((_, i) => {
                            // Calculate offset based on layer index - move down and right
                            const offset = (fontSize * 0.06 / 4) * (i + 1);
                            return (
                                <text
                                    key={`3d-stroke-layer-${i}-${t.fromMs}-${index}`}
                                    x={positions[index].x + offset * 0.6}
                                    y={centerY + offset}
                                    dominantBaseline="middle"
                                    style={{ fontSize, fontFamily }}
                                    fill="none"
                                    stroke={outlineColor}
                                    strokeWidth={fontSize * 0.18}
                                    strokeLinejoin="round"
                                    strokeLinecap="round"
                                    opacity={finalBaseOpacity}
                                    transform={combinedTransform}
                                >
                                    {processText(t.text)}
                                </text>
                            );
                        })
                    ];
                } else if (layerType === 'main') {
                    // Keep the main text white and crisp
                    let textOpacity = 1;
                    if (textAnimation === 'karaoke' || textAnimation === 'karaoke_box' || textAnimation === 'karaoke_scale') {
                        textOpacity = karaokeOpacity === 1 ? 1 : 0.3;
                    } else if (textAnimation === 'reveal' || textAnimation === 'reveal_scale' || 
                              textAnimation === 'reveal_up' || textAnimation === 'reveal_box') {
                        textOpacity = opacity;
                    }
                    


                    // Determine text color based on contrast with primaryColor
                    const textColor = getOutlineAndTextColorForStyle(outline,textAnimation,active,background,currentSpokenWordColor,primaryColor).textColor;
                    
                    return (
                        <text
                            key={`text-${t.fromMs}-${index}`}
                            x={positions[index].x}
                            y={centerY}
                            fill={textColor}
                            opacity={textOpacity}
                            dominantBaseline="middle"
                            style={{ fontSize, fontFamily }}
                            transform={combinedTransform}
                        >
                            {processText(t.text)}
                        </text>
                    );
                }
                return null;
            }

            // For regular shadow effect
            if (outline === 'shadow' && layerType === 'other') {
                if (opacity === 0) return null;
                return [
                    <text
                        key={`shadow1-${t.fromMs}-${index}`}
                        x={positions[index].x}
                        y={centerY}
                        dominantBaseline="middle"
                        style={{ fontSize, fontFamily }}
                        fill="black"
                        transform={`${combinedTransform} translate(1.5,1.5)`} // Reduced from 2,2
                        filter="blur(3px)" // Reduced from 4px
                        opacity={finalOpacity * 0.15}
                        stroke="black"
                        strokeWidth={fontSize * 0.015} // Reduced from 0.02
                    >
                        {processText(t.text)}
                    </text>,
                    <text
                        key={`shadow2-${t.fromMs}-${index}`}
                        x={positions[index].x}
                        y={centerY}
                        dominantBaseline="middle"
                        style={{ fontSize, fontFamily }}
                        fill="black"
                        transform={`${combinedTransform} translate(0.5,0.5)`} // Reduced from 0.7,0.7
                        filter="blur(1.5px)" // Reduced from 2px
                        opacity={finalOpacity * 0.2}
                        stroke="black"
                        strokeWidth={fontSize * 0.004} // Reduced from 0.005
                    >
                        {processText(t.text)}
                    </text>,
                    <text
                        key={`shadow3-${t.fromMs}-${index}`}
                        x={positions[index].x}
                        y={centerY}
                        dominantBaseline="middle"
                        style={{ fontSize, fontFamily }}
                        fill="black"
                        transform={`${combinedTransform} translate(0.3,0.3)`} // Reduced from 0.4,0.4
                        filter="blur(0.8px)" // Reduced from 1px
                        opacity={finalOpacity * 0.25}
                        stroke="black"
                        strokeWidth={fontSize * 0.001} // Reduced from 0.002
                    >
                        {processText(t.text)}
                    </text>
                ];
            }

            // For glow effect
            if (outline === 'glow' && layerType === 'glow') {
                if (opacity === 0) return null;
                return [
                    <text
                        key={`glow1-${t.fromMs}-${index}`}
                        x={positions[index].x}
                        y={centerY}
                        dominantBaseline="middle"
                        style={{ fontSize, fontFamily }}
                        fill="rgba(255,255,255,0.3)"
                        transform={`${combinedTransform} translate(3,3)`} // Reduced from 4,4
                        filter="blur(10px)" // Reduced from 12px
                        opacity={finalOpacity * 0.3}
                    >
                        {processText(t.text)}
                    </text>,
                    <text
                        key={`glow2-${t.fromMs}-${index}`}
                        x={positions[index].x}
                        y={centerY}
                        dominantBaseline="middle"
                        style={{ fontSize, fontFamily }}
                        fill="rgba(255,255,255,0.4)"
                        transform={`${combinedTransform} translate(1.5,1.5)`} // Reduced from 2,2
                        filter="blur(5px)" // Reduced from 6px
                        opacity={finalOpacity * 0.4}
                    >
                        {processText(t.text)}
                    </text>,
                    <text
                        key={`glow3-${t.fromMs}-${index}`}
                        x={positions[index].x}
                        y={centerY}
                        dominantBaseline="middle"
                        style={{ fontSize, fontFamily }}
                        fill="rgba(255,255,255,0.5)"
                        transform={`${combinedTransform} translate(1,1)`}
                        filter="blur(3px)"
                        opacity={finalOpacity * 0.5}
                    >
                        {processText(t.text)}
                    </text>
                ];
            }

            // For 3D shadow effect
            if (outline === '3d-shadow' && layerType === 'other') {
                if (opacity === 0) return null;
                
                // Use the helper function to determine outline color
                const outlineColor = getOutlineAndTextColorForStyle(outline,textAnimation,active,background,currentSpokenWordColor,primaryColor).outlineColor;
                
                // Calculate base opacity for unspoken words in karaoke mode
                const karaokeBaseOpacity = (textAnimation === 'karaoke' || 
                    textAnimation === 'karaoke_box' || 
                    textAnimation === 'karaoke_scale') 
                    ? getKaraokeOpacity(t.fromMs, page.startMs, timeInMs) 
                    : 1;
                
                // Calculate final base opacity including karaoke effect
                const baseOpacity = 0.3; // Standard opacity for unspoken words
                const finalBaseOpacity = karaokeBaseOpacity === 1 ? 1 : baseOpacity;
                
                // Adjust shadow layers to work with the new centerY
                return Array.from({ length: 4 }).map((_, i) => { // Reduced from 6 to 4 layers
                    const offset = (fontSize * 0.06 / 4) * (i + 1); // Reduced from 0.1 to 0.06
                    const baseLayerOpacity = 0.5 + ((i + 1) / 4) * 0.5;
                    const finalLayerOpacity = baseLayerOpacity * finalOpacity * finalBaseOpacity;

                    return (
                        <text
                            key={`shadow-layer-${i}-${t.fromMs}-${index}`}
                            x={positions[index].x}
                            y={centerY + offset}
                            dominantBaseline="middle"
                            style={{ fontSize, fontFamily }}
                            fill={outlineColor}
                            stroke={outlineColor}
                            strokeWidth={fontSize * 0.06} // Reduced from 0.08 * 0.8
                            strokeLinejoin="round"
                            strokeLinecap="round"
                            opacity={finalLayerOpacity}
                            transform={combinedTransform}
                        >
                            {processText(t.text)}
                        </text>
                    );
                });
            }

            // For stroke outline
            if ((outline === 'stroke' || outline === '3d-shadow') && layerType === 'other') {
                if (opacity === 0) return null;
                
                // Use the helper function to determine outline color
                const outlineColor = getOutlineAndTextColorForStyle(outline,textAnimation,active,background,currentSpokenWordColor,primaryColor).outlineColor;
                
                const karaokeBaseOpacity = textAnimation === 'karaoke' ? 
                    getKaraokeOpacity(t.fromMs, page.startMs, timeInMs) : 1;
                
                return (
                    <text
                        key={`stroke-${t.fromMs}-${index}`}
                        x={positions[index].x}
                        y={centerY}
                        dominantBaseline="middle"
                        style={{ fontSize, fontFamily }}
                        stroke={outlineColor}
                        strokeWidth={fontSize * 0.12}
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        fill="none"
                        opacity={finalOpacity * karaokeBaseOpacity}
                        transform={combinedTransform}
                    >
                        {processText(t.text)}
                    </text>
                );
            }

            // Main text layer (for non-3d-stroke outlines)
            if (layerType === 'main') {
                let textOpacity = 1;

                // Calculate text opacity based on animation type
                if (textAnimation === 'karaoke' || 
                    textAnimation === 'karaoke_box' || 
                    textAnimation === 'karaoke_scale') {
                    textOpacity = karaokeOpacity === 1 ? 1 : 0.3;
                } else if (textAnimation === 'reveal' || 
                          textAnimation === 'reveal_scale' || 
                          textAnimation === 'reveal_up' || 
                          textAnimation === 'reveal_box') {
                    textOpacity = opacity;
                }

                // Get the text color using our helper function
                const fill = getOutlineAndTextColorForStyle(outline,textAnimation,active,background,currentSpokenWordColor,primaryColor).textColor /*getTextColorForStyle(
                    outline,
                    textAnimation,
                    active,
                    isBoxAnimation,
                    background,
                    currentSpokenWordColor,
                    primaryColor
                )*/;

                return (
                    <text
                        key={`text-${t.fromMs}-${index}`}
                        x={positions[index].x}
                        y={centerY}
                        fill={fill}
                        opacity={textOpacity}
                        dominantBaseline="middle"
                        style={{ fontSize, fontFamily }}
                        transform={combinedTransform}
                    >
                        {processText(t.text)}
                    </text>
                );
            }

            return null;
        }).flat(); // Flatten the array to handle the 3D shadow layers
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        }}>
            <div style={{
                display: 'inline-block',
                backgroundColor: background !== 'none' ? backgroundToCSS(background, primaryColor) : 'transparent',
                borderRadius: actualBorderRadius,
                paddingLeft: background !== 'none' ? actualContainerPadding.x : 0,
                paddingRight: background !== 'none' ? actualContainerPadding.x : 0,
                paddingTop: background !== 'none' ? actualContainerPadding.y : 0,
                paddingBottom: background !== 'none' ? actualContainerPadding.y : 0,
                overflow: 'hidden',
                // Add these properties to ensure proper centering
                textAlign: 'center',
                whiteSpace: 'nowrap'
            }}>
                <svg 
                    style={{
                        width: 'auto',
                        height: 'auto',
                        display: 'block',
                    }}
                    width={containerWidth}
                    height={svgHeight}
                    viewBox={`0 0 ${containerWidth} ${svgHeight}`}
                    preserveAspectRatio="xMidYMid meet"
                >
                    {/* 1. Background boxes */}
                    {(textAnimation === 'boxed' || textAnimation === 'reveal_box' || textAnimation === 'karaoke_box') && page.tokens.map((t, index) => {
                        const startRelativeToSequence = t.fromMs - page.startMs;
                        const endRelativeToSequence = t.toMs - page.startMs;
                        const active = startRelativeToSequence <= timeInMs && endRelativeToSequence > timeInMs;

                        const revealProgress = textAnimation === 'reveal_box' ? 
                            getRevealProgress(t.fromMs, t.toMs, page.startMs, timeInMs) : 1;
                        
                        const karaokeOpacity = textAnimation === 'karaoke_box' ?
                            getKaraokeOpacity(t.fromMs, page.startMs, timeInMs) : 1;

                        if (active && (
                            textAnimation === 'boxed' || 
                            (textAnimation === 'reveal_box' && revealProgress > 0) ||
                            (textAnimation === 'karaoke_box' && karaokeOpacity === 1)
                        )) {
                            const wordWidth = positions[index].width;
                            
                            // Calculate additional padding needed for different outline types
                            const outlinePadding = (() => {
                                if (outline === '3d-shadow') {
                                    return fontSize * 0.15; // Extra padding for 3D shadow
                                } else if (outline === '3d-stroke') {
                                    return fontSize * 0.18; // Extra padding for 3D stroke
                                } else if (outline === 'stroke') {
                                    return fontSize * 0.12; // Extra padding for stroke
                                } else if (outline === 'shadow' || outline === 'glow') {
                                    return fontSize * 0.08; // Extra padding for shadow/glow
                                }
                                return 0; // No extra padding for 'none' outline
                            })();
                            
                            // Adjust box height and vertical position based on outline type
                            let boxHeight, boxY;
                            
                            if (outline === '3d-shadow') {
                                // For 3D shadow effects - make even tighter but account for shadow depth
                                boxHeight = fontSize * 1.15 + (actualBoxPadding.y * 2); // Increased from 1.0
                                boxY = centerY - (fontSize * 0.55) - actualBoxPadding.y; // Adjusted
                            } else if (outline === '3d-stroke') {
                                // For 3D stroke - account for stroke width
                                boxHeight = fontSize * 1.2 + (actualBoxPadding.y * 2); // Increased from 1.25
                                boxY = centerY - (fontSize * 0.58) - actualBoxPadding.y; // Adjusted
                            } else if (outline === 'stroke') {
                                // For stroke - account for stroke width
                                boxHeight = fontSize * 1.1 + (actualBoxPadding.y * 2); // Increased from 1.05
                                boxY = centerY - (fontSize * 0.55) - actualBoxPadding.y; // Adjusted
                            } else {
                                // For shadow, glow, and none - make tighter
                                boxHeight = fontSize * 1.10 + (actualBoxPadding.y * 2);
                                boxY = centerY - (fontSize * 0.55) - actualBoxPadding.y;
                            }
                            
                            // Add extra horizontal padding for the box based on outline type
                            const totalBoxPadding = {
                                x: actualBoxPadding.x + outlinePadding,
                                y: actualBoxPadding.y
                            };
                            
                            let boxTransform = '';
                            let scaleAmount = 1;

                            // Add scale animation for all box-related animations
                            scaleAmount = spring({
                                frame: frame - Math.floor((t.fromMs - page.startMs) / (1000 / fps)),
                                fps,
                                from: 0.8,  // Start from 80% size
                                to: 1,      // End at full size
                                durationInFrames: 10,
                                config: {
                                    damping: 12,
                                    stiffness: 180,
                                    mass: 0.5
                                }
                            });

                            // Calculate the base scaling transform
                            const boxCenterX = positions[index].x + wordWidth/2;
                            const boxCenterY = centerY;
                            const scaleTransform = `translate(${boxCenterX}, ${boxCenterY}) scale(${scaleAmount}) translate(${-boxCenterX}, ${-boxCenterY})`;

                            if (textAnimation === 'reveal_box') {
                                // Combine slide and scale animations
                                const slideX = interpolate(
                                    spring({
                                        frame: frame - Math.floor((t.fromMs - page.startMs) / (1000 / fps)),
                                        fps,
                                        from: 0,
                                        to: 1,
                                        durationInFrames: 12,
                                        config: {
                                            damping: 15,
                                            stiffness: 200,
                                            mass: 0.6
                                        }
                                    }),
                                    [0, 1],
                                    [-fontSize * 0.3, 0]
                                );
                                boxTransform = `${scaleTransform} translate(${slideX}, 0)`;
                            } else {
                                // For both 'boxed' and 'karaoke_box'
                                boxTransform = scaleTransform;
                            }

                            return (
                                <rect
                                    key={`box-${t.fromMs}`}
                                    x={positions[index].x - totalBoxPadding.x}
                                    y={boxY}
                                    width={wordWidth + (totalBoxPadding.x * 2)}
                                    height={boxHeight}
                                    fill={currentSpokenWordColor}
                                    rx={actualBorderRadius}
                                    ry={actualBorderRadius}
                                    transform={boxTransform}
                                    opacity={textAnimation === 'karaoke_box' ? karaokeOpacity : revealProgress}
                                />
                            );
                        }
                        return null;
                    })}

                    {/* 2. Shadow effect */}
                    {outline === 'shadow' && renderTokens('other')}

                    {/* 3. 3D Shadow layers */}
                    {outline === '3d-stroke' && renderTokens('other')}

                    {/* 3. 3D Shadow layers */}
                    {outline === '3d-shadow' && renderTokens('other')}

                    {/* 4. Stroke layer */}
                    {(outline === 'stroke') && renderTokens('other')}

                    {/* 5. Glow effect */}
                    {outline === 'glow' && renderTokens('glow')}

                    {/* 6. Main text - Always last */}
                    {renderTokens('main')}
                </svg>
            </div>
        </div>
    );
}