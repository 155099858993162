import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import SequencerNodeView from "views/audioEditor/sequencerNodeView/SequencerNodeView";
import SequencerAudioEventNodeTemplate from 'text!../../../../templates/audioEditor/sequencerNodeView/SequencerAudioEventNodeTemplate.html';
import ti18n from 'i18n!nls/Sequenceri18n';
import RSVP from 'rsvp';


const SequencerAudioEventNodeView = SequencerNodeView.extend({



    constructor : function(attributes, options) {
        SequencerNodeView.apply(this, [attributes, options]);
        this.compiledTemlate =  _.template(SequencerAudioEventNodeTemplate);
        this.listenTo(this.model,"change:audioSegment",this.onAudioSegmentChange);
    },

    onAudioSegmentChange: function(){
        this.$el.find(".content").html(" "+ _.escape(this.model.getAudioSegment().getContent()) +" ");
    },
    
    events : _.extend({
        
    }, SequencerNodeView.prototype.events),


    render : function() {

        this.$el.html(this.compiledTemlate({"model": this.model, "_":_}));
        this.setElement(this.$el.find("span").first());
        this.afterRendering();

        return this;
    }

});
export default SequencerAudioEventNodeView;