import { ElementDesignSystem, ElementDesignSystemConfig } from "../DesignSystem";
import { DefaultVideoSegmentMultiFocus } from "./DefaultVideoSegmentMultiFocus/DefaultVideoSegmentMultiFocus";

export interface FocusRegion<AspectRatio extends `${number}:${number}`> {
    /** 
     * X position (percentage of video width from left)
     * 
     * Example: If cropping from 200 pixels from a 1920 width video
     * you would pass 200 / 1920
     */
    x: number;
    /**
     * Y position (percentage of video height from top)
     * 
     * Example: If cropping from 200 pixels from a 1080 height video
     * you would pass 200 / 1080
     */
    y: number;
    /**
     * Width of the region (percentage of video width)
     * 
     * Example: If cropping regions width is 100 pixels from a 1920 width video
     * you would pass 100 / 1920
     */
    width: number;
    /**
     * Height of the region (percentage of video height)
     * 
     * Example: If cropping regions height is 100 pixels from a 1080 height video
     * you would pass 100 / 1080
     */
    height: number; // Height of the region (percentage of video height)
    /**
     * Aspect ratio of the focus region in "width:height" format.
     * Note that this is not enforced in runtime, only for documentation purposes
     */
    aspectRatio: AspectRatio;
}

export type FramingOptions = {
    /**
     * The default layout is a video with no focus regions
     */
    name: "default",
    "1:1": {
        focusRegions: []
    }
    "9:16": {
        focusRegions: []
    }
    "16:9": {
        focusRegions: []
    }
} | {
    /**
     * A split layout is a video that is split into 2 focus regions
     * Each focus region should have a 1:1 aspect ratio
     */
    name: "2-split",
    "1:1": {
        focusRegions: [FocusRegion<"2:1">, FocusRegion<"2:1">]
    }
    "9:16": {
        focusRegions: [FocusRegion<"9:8">, FocusRegion<"9:8">]
    }
    "16:9": {
        focusRegions: [FocusRegion<"8:9">, FocusRegion<"8:9">]
    }
} | {
    /**
     * A split layout is a video that is split into 3 focus regions
     * Each focus region should have a 1:1 aspect ratio
     */
    name: "3-split",
    "1:1": {
        focusRegions: [FocusRegion<"2:1">, FocusRegion<"1:1">, FocusRegion<"1:1">]
    }
    "9:16": {
        focusRegions: [FocusRegion<"9:8">, FocusRegion<"9:16">, FocusRegion<"9:16">]
    }
    "16:9": {
        focusRegions: [FocusRegion<"16:27">, FocusRegion<"16:27">, FocusRegion<"16:27">]
    }
} | {
    /**
     * A split layout is a video that is split into 4 focus regions
     * Each focus region should have a 1:1 aspect ratio
     */
    name: "4-split",
    "1:1": {
        focusRegions: [FocusRegion<"1:1">, FocusRegion<"1:1">, FocusRegion<"1:1">, FocusRegion<"1:1">]
    }
    "9:16": {
        focusRegions: [FocusRegion<"9:16">, FocusRegion<"9:16">, FocusRegion<"9:16">, FocusRegion<"9:16">]
    }
    "16:9": {
        focusRegions: [FocusRegion<"16:9">, FocusRegion<"16:9">, FocusRegion<"16:9">, FocusRegion<"16:9">]
    }
} | {
    /**
     * A screenshare layout is a video that is split into 2 focus regions
     * The first focus region is a camera feed and the second focus region is a screenshare
     * The camera feed must have as aspect region of 1:1
     * The screenshare region must have an aspect ratio of 16:9
    */
    name: "screenshare",
    "1:1": {
        focusRegions: [FocusRegion<"1:1">, FocusRegion<"16:9">]
    }
    "9:16": {
        focusRegions: [FocusRegion<"1:1">, FocusRegion<"16:9">]
    }
    "16:9": {
        focusRegions: [FocusRegion<"1:1">, FocusRegion<"16:9">]
    }
} | {
    /**
     * A gameplay layout is a video that is split into 2 focus regions
     * The first focus region should be the main video feed and the second focus region is a gameplay feed
     * The main video feed must have as aspect region of 16:9
     * The gameplay region must have an aspect ratio of 54:67
     * Once combined, the layout will have a 9:16 aspect ratio
    */
    name: "gameplay",
    "1:1": {
        focusRegions: [FocusRegion<"4:1">, FocusRegion<"4:3">]
    }
    "9:16": {
        focusRegions: [FocusRegion<"15:8">, FocusRegion<"45:56">]
    }
    "16:9": {
        focusRegions: [FocusRegion<"4:3">, FocusRegion<"4:9">]
    }
} | {
    /**
     * A square layout is simply just a 1:1 video
     */
    name: "square",
    "1:1": {
        focusRegions: [FocusRegion<"1:1">]
    }
    "9:16": {
        focusRegions: [FocusRegion<"1:1">]
    }
    "16:9": {
        focusRegions: [FocusRegion<"1:1">]
    }
} | {
    /**
     * A portrait layout is simply just a 9:16 video
     */
    name: "portrait",
    "1:1": {
        focusRegions: [FocusRegion<"9:16">]
    }
    "9:16": {
        focusRegions: [FocusRegion<"9:16">]
    }
    "16:9": {
        focusRegions: [FocusRegion<"9:16">]
    }
} | {
    /**
     * A landscape layout is simply just a 16:9 video
     */
    name: "landscape",
    "1:1": {
        focusRegions: [FocusRegion<"16:9">]
    }
    "9:16": {
        focusRegions: [FocusRegion<"16:9">]
    }
    "16:9": {
        focusRegions: [FocusRegion<"16:9">]
    }
}

export interface VideoSegmentMultiFocusDesignSystemConfig extends Pick<ElementDesignSystemConfig, 'designSystem'> {
    /** Start time to use for video segment in miliseconds */
    startTimestampMS: number;
    /** End time of use for video segment in miliseconds */
    endTimestampMS: number;
    /** URL of video segment */
    videoURL: string;
    /** Border Radius */
    borderRadius: number;
    /** Indicates whether padding & border should be shown */
    spacing: boolean;
    /** Border */
    border: boolean;
    /** Focus regions */
    framing: FramingOptions;
    /** Custom audio to override video audio */
    audioURL?: string;
}

export class VideoSegmentMultiFocusDesignSystem extends ElementDesignSystem implements VideoSegmentMultiFocusDesignSystemConfig {
    public startTimestampMS: number;
    public endTimestampMS: number;
    public videoURL: string;
    public borderRadius: number;
    public spacing: boolean;
    public border: boolean;
    public framing: FramingOptions;
    public audioURL?: string;

    constructor(
        config: VideoSegmentMultiFocusDesignSystemConfig
    ) {
        super({ ...config, position: 'center center', animation: undefined, affectedBySafeZone: false });
        this.startTimestampMS = config.startTimestampMS;
        this.endTimestampMS = config.endTimestampMS;
        this.videoURL = config.videoURL;
        this.borderRadius = config.borderRadius;
        this.spacing = config.spacing;
        this.border = config.border;
        this.framing = config.framing;
        this.audioURL = config.audioURL;
    }

    renderChild() {
        return (
            <DefaultVideoSegmentMultiFocus {...this} />
        );
    }
}