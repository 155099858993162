import { SaveOutlined, MoreOutlined } from "@ant-design/icons";
import { Dropdown, Button, Select, Modal, Form, Input } from "antd";
import { Theme } from "../VideoEditor";
import { useEffect, useState } from "react";


export const SaveThemeButton = ({
    onSaveMenuClicked,
    onSaveAsMenuClicked,
    className,
}: { onSaveMenuClicked: any, onSaveAsMenuClicked: any, className: any }) => {
    const items = [
        {
            key: "1",
            label: (
                <a
                    onClick={onSaveMenuClicked}
                >
                    Save
                </a>
            ),
        },
        {
            key: "2",
            label: (
                <a
                    onClick={onSaveAsMenuClicked}
                >
                    Save as...
                </a>
            ),
        },
    ];
    return (
        <Dropdown
            trigger={["click"]}
            className={className}
            menu={{
                items,
            }}
            placement="bottom"
        >
            <Button icon={<SaveOutlined />} />
        </Dropdown>
    );
};

export const MoreActionThemeButton = ({
    onResetMenuClicked,
    onRenameMenuClicked,
    onDeleMenuClicked,
    className,
}: { onResetMenuClicked: any, onRenameMenuClicked: any, onDeleMenuClicked: any, className: any }) => {
    const items = [
        {
            key: "1",
            label: (
                <a
                    onClick={onResetMenuClicked}
                >
                    Reset
                </a>
            ),
        },
        {
            key: "2",
            label: (
                <a
                    target="_blank"
                    onClick={onRenameMenuClicked}
                    rel="noopener noreferrer"
                    href="#"
                >
                    Rename
                </a>
            ),
        },
        {
            key: "3",
            label: (
                <a
                    target="_blank"
                    onClick={onDeleMenuClicked}
                    rel="noopener noreferrer"
                    href="#"
                    style={{ color: "red" }}
                >
                    Delete
                </a>
            ),
        },
    ];
    return (
        <Dropdown
            trigger={["click"]}
            className={className}
            menu={{
                items,
            }}
            placement="bottom"
        >
            <Button icon={<MoreOutlined />} />
        </Dropdown>
    );
};

export const ThemeSelector = ({ savedThemes, setSavedThemes, selectedTheme, setSelectedTheme }: {
    savedThemes: Theme[],
    setSavedThemes: React.Dispatch<React.SetStateAction<Theme[]>>,
    selectedTheme: Theme,
    setSelectedTheme: (theme: Theme) => void
}) => {
    const [isSaveAsModalVisible, setIsSaveAsModalVisible] = useState(false);
    const [form] = Form.useForm();

    const checkIfThemeChanged = (inMemoryTheme: Theme, savedThemes: Theme[]) => {
        // For demo purposes, we retreive the theme from local storage with the same ID
        // and compare the passed in theme with the one from local storage
        const themeFromStorage = savedThemes.find((t) => t.themeID === inMemoryTheme.themeID);
        if (themeFromStorage === undefined) {
            return false;
        }

        return JSON.stringify(inMemoryTheme) !== JSON.stringify(themeFromStorage);
    }

    const themeWithID = (themeID: string) => savedThemes.find((theme) => theme.themeID === themeID)!;
    const hasThemeChanged = checkIfThemeChanged(selectedTheme, savedThemes);

    const onSaveMenuClicked = () => {
        setSavedThemes((themes) => [...themes.filter((t) => t.themeID !== selectedTheme.themeID), selectedTheme]);
    }

    const handleSaveAsOk = async () => {
        try {
            const values = await form.validateFields();
            let newTheme: Theme = {
                ...selectedTheme,
                themeID: `theme-${Date.now()}`, // Generate a new unique ID
                themeName: values.themeName,
            };

            // Remove default configuration if it exists
            if (newTheme.defaultConfiguration) {
                delete newTheme.defaultConfiguration;
            }
            
            setSavedThemes(themes => [...themes, newTheme]);
            setSelectedTheme(newTheme);
            setIsSaveAsModalVisible(false);
            form.resetFields();
        } catch (error) {
            console.error('Validation failed:', error);
        }
    };

    const handleSaveAsCancel = () => {
        setIsSaveAsModalVisible(false);
        form.resetFields();
    };


    const onResetMenuClicked = () => {
        const themeWithDefault = selectedTheme.defaultConfiguration;
        if (themeWithDefault === undefined) {
            return;
        }

        setSavedThemes((themes) => [...themes.filter((t) => t.themeID !== selectedTheme.themeID), { ...themeWithDefault, themeID: selectedTheme.themeID, themeName: selectedTheme.themeName, defaultConfiguration: themeWithDefault }]);
    }

    // Whenever savedThemes changes, check if the selected theme has changed and update the memory copy
    useEffect(() => {
        if (hasThemeChanged) {
            setSelectedTheme(themeWithID(selectedTheme.themeID));
        }
    }, [savedThemes])

    return (
        <>
            <div className="mb-1">Theme: {hasThemeChanged ? '(Unsaved)' : ""}</div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <Select style={{ flex: "1 1 auto" }} value={selectedTheme?.themeID} onChange={(themeID) => setSelectedTheme(themeWithID(themeID))}>
                    {savedThemes.map((theme) => (
                        <Select.Option key={theme.themeID} value={theme.themeID}>
                            {theme.themeName}
                        </Select.Option>
                    ))}
                </Select>
                <SaveThemeButton className="mx-1" onSaveMenuClicked={onSaveMenuClicked} onSaveAsMenuClicked={() => setIsSaveAsModalVisible(true)} />
                <MoreActionThemeButton onResetMenuClicked={onResetMenuClicked} onRenameMenuClicked={undefined} onDeleMenuClicked={undefined} className={undefined} />
            </div>
            <Modal
                title="Save Theme As"
                open={isSaveAsModalVisible}
                onOk={handleSaveAsOk}
                onCancel={handleSaveAsCancel}
            >
                <Form form={form}>
                    <Form.Item
                        name="themeName"
                        label="Theme Name"
                        rules={[
                            { required: true, message: 'Please input the theme name!' },
                            { min: 3, message: 'Theme name must be at least 3 characters long' }
                        ]}
                    >
                        <Input placeholder="Enter new theme name" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}