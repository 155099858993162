import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import popupTemplate from 'text!../../../templates/audioEditor/SequencerAutomatedOperationsContextMenuTemplate.html';
import ti18n from "i18n!nls/Sequenceri18n";

const SequencerAutomatedOperationsOptionContentView = Backbone.View.extend({


    initialize : function(options) {
        
        this.compiledTemlate =  _.template(popupTemplate);
        this.model = options? options.model : null;
        this._templateParams = {"model" : this.model, "ti18n": ti18n };
    },
    
    events : {
        "click #deleteFillerWordsBtn:not(.disabled)": "_onDeleteFillerWordsButtonClicked",
        "click #findFillerWordsBtn:not(.disabled)": "_onFindFillerWordsButtonClicked",
        "click #findEditBoundariesBtn:not(.disabled)": "_onFindEditBoundariesButtonClicked",
        "click #trimSilencesBtn:not(.disabled)": "_onTrimSilencesButtonClicked",
        "click #findSilencesBtn:not(.disabled)": "_onFindSilencesButtonClicked",
        "click #findWithAiBtn:not(.disabled)": "_onFindWithAiButtonClicked",
        "click #displayAISuggestionsBtn:not(.disabled)": "_onDisplayAIEditSuggestionsButtonClicked",
        "click #findDisfluenciesBtn:not(.disabled)": "_onFindDisfluenciesBtnButtonClicked",
        "click #configureMagicEnhancerBtn:not(.disabled)": "_onConfigureMagicSoundEnhacerButtonClicked",
    },

    canBeDestroyed : function(event){
        if(event && event.srcElement && this._soundEnhancerSettingsPopupContoller && this._soundEnhancerSettingsPopupContoller.getView() && this._soundEnhancerSettingsPopupContoller.getView().$el){
            return !($.contains(this._soundEnhancerSettingsPopupContoller.getView().$el.get(0), event.srcElement));
        }
        if(event && event.srcElement && this._trimSilencesMenuContoller && this._trimSilencesMenuContoller.getView() && this._trimSilencesMenuContoller.getView().$el){
            return !($.contains(this._trimSilencesMenuContoller.getView().$el.get(0), event.srcElement));
        }
        return true;
    },

    setTrimSilencesMenuContoller: function(trimSilencesMenuController){
        this._trimSilencesMenuContoller = trimSilencesMenuController;
    },

    setSoundEnhancerSettingsPopupContoller : function(soundEnhancerSettingsPopupContoller){
        this._soundEnhancerSettingsPopupContoller = soundEnhancerSettingsPopupContoller;
    },


    _onDeleteFillerWordsButtonClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("deleteFillerWords");
        }
    },

    _onFindFillerWordsButtonClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("findFillerWords");
        }
    },

    _onFindEditBoundariesButtonClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("findEditBoundaries");
        }
    },

    _onTrimSilencesButtonClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("trimSilences");
        }
    },

    _onFindSilencesButtonClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("findSilences");
        }
    },

    _onFindWithAiButtonClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("findWithAi");
        }
    },

    _onDisplayAIEditSuggestionsButtonClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("displayAIEditSuggestions");
        }
    },

    _onFindDisfluenciesBtnButtonClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("findDisfluencies");
        }
    },

    isNoiseCancellationOn : function(){
        return this.noiseCancellationSwitchInput$el.prop('checked');
    },

    isMagicSoundEnhancerOn : function(){
        return this.magicSoundEnhancerSwitchInput$el.prop('checked');
    },


    _onConfigureMagicSoundEnhacerButtonClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("configureMagicSoundEnhancer");
        }
    },

    getSoundEnhancerMenu$el: function(){
        return this.configureMagicEnhancerBtn$el;
    },

    getTrimSilencesMenu$el: function(){
        return this.trimSilencesBtn$el;
    },

    getFindSilencesMenu$el: function(){
        return this.findSilencesBtn$el;
    },


    setNoiseCancellationOn : function(noiseCancellationOn){
        this.noiseCancellationSwitchInput$el.prop('checked', noiseCancellationOn);
    },

    setMagicSoundEnhancerOn : function(magicSoundEnhancerOn){
        this.magicSoundEnhancerSwitchInput$el.prop('checked', magicSoundEnhancerOn);
    },

    initSwitches : function(){
        this.setNoiseCancellationOn(this.model.getSequencerSettings().isNoiseCancellationOn());
        this.setMagicSoundEnhancerOn(this.model.getSequencerSettings().isMagicSoundEnhancerOn());
        this.noiseCancellationSwitchInput$el.on("click", (function(event){
            this.trigger("noiseCancellationChange", this.isNoiseCancellationOn());
            document.scrollingElement.scrollTop = 0;// fix a bug where the scroll position change when the last switch is toggled
        }).bind(this));
        this.magicSoundEnhancerSwitchInput$el.on("click", (function(event){
            this.trigger("magicSoundEnhancerChange", this.isMagicSoundEnhancerOn());
            document.scrollingElement.scrollTop = 0;// fix a bug where the scroll position change when the last switch is toggled
        }).bind(this));
    },

    
    getModel: function(){
        return this.model;
    },

    render : function() {
        
        this.$el.html(this.compiledTemlate(this._templateParams));
        this.setElement(this.$el.find("ul").first());
        this.configureMagicEnhancerBtn$el = this.$el.find("#configureMagicEnhancerBtn");
        this.trimSilencesBtn$el = this.$el.find("#trimSilencesBtn");
        this.findSilencesBtn$el = this.$el.find("#findSilencesBtn");
        this.findDisfluenciesBtn$el = this.$el.find("#findDisfluenciesBtn");
        this.noiseCancellationSwitchInput$el =  this.$el.find(".noiseCancellationBtn_switch  input");
        this.magicSoundEnhancerSwitchInput$el =  this.$el.find(".magicSoundEnhancerBtn_switch  input");
        this.initSwitches();
        return this;
    }

    
    

});
export default SequencerAutomatedOperationsOptionContentView;