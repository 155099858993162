import { SpeakerDesignSystem } from "../Speaker";

export const ClassicSpeaker: React.FC<SpeakerDesignSystem> = ({ firstName, lastName, title, company, designSystem: { titleFont, bodyFont }, position }) => {
    const containerStyle: React.CSSProperties = {
        display: "flex",
        alignItems: "flex-start",
        width: "fit-content",
    };

    const redBarStyle: React.CSSProperties = {
        backgroundColor: "red",
        width: 15,
        height: "100%",
    };

    const textContainerStyle: React.CSSProperties = {
        display: "flex",
        flexDirection: "column",
    };

    const nameStyle: React.CSSProperties = {
        fontFamily: titleFont,
        fontSize: 40,
        fontWeight: "bold",
        backgroundColor: "white",
        color: "black",
        padding: "4px 8px",
        width: "fit-content",
    };

    const titleStyle: React.CSSProperties = {
        fontFamily: bodyFont,
        fontSize: 30,
        fontWeight: "bold",
        backgroundColor: "black",
        color: "white",
        padding: "4px 8px",
        width: "fit-content",
    };

    const companyStyle: React.CSSProperties = {
        fontFamily: bodyFont,
        fontSize: 25,
        color: "white",
        backgroundColor: "black",
        padding: "4px 8px",
        width: "fit-content",
    };

    return (
        <div style={containerStyle}>
            <div style={redBarStyle}></div>
            <div style={textContainerStyle}>
                <div style={nameStyle}>{firstName} {lastName}</div>
                <div style={titleStyle}>{title}</div>
                <div style={companyStyle}>{company}</div>
            </div>
        </div>
    );
};