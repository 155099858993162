import React from "react";
import ConfigProvider from "./common/ConfigProvider";
import PropTypes from "prop-types";
import Avatar from "./common/Avatar";
import Card from "./common/Card";
import moment from 'moment';
import ti18n from 'i18n!nls/Sequenceri18n';
import SequencerSearchWidget from "../models/audioEditor/SequencerSearchWidget";
import SequencerRendererUtils  from "models/audioEditor/SequencerRendererUtils"
import useDefaultTrebbleAntDTokenConfig from "./helper/useDefaultTrebbleAntDTokenConfig";
import Tooltip from "./common/Tooltip";
import Utils from "../models/helper/Utils";

const SEARCH_TYPE = SequencerSearchWidget.SEARCH_TYPE;
const EditorSearchCommentItem = (props) =>
{
    const themeConfig = useDefaultTrebbleAntDTokenConfig({useEditorTheme: true});
    const onApplyButtonClick = ()=>{
        if(props.onApplyButtonClick){
            props.onApplyButtonClick()
        }
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("sequencer", 'searchResultMainActionApplied', 'search result main action applied', getPropsForTracking(props));
        }
    }

    const getPropsForTracking = (props)=>{
        const searchResultArraySummary = Utils.getInstance().getSummaryDescriptionOfSequencerNodeArray(props.searchResultArray);
        
        return {
            ...searchResultArraySummary, 
            uid: props.uid,
            commenterName: props.commenterName,
            searchType: props.searchType,
            typeLabel: props.typeLabel,
            description: props.description,
            type: props.description,
            confidence: props.confidence,
            reason: props.reason,
            index: props.index,
            searchRequestId: props.searchRequestId,
        };
    }

    const onPlayWithSelectionButtonClick= ()=>{
        if(props.onPlayWithSelectionButtonClick){
            props.onPlayWithSelectionButtonClick()
        }else{
            SequencerRendererUtils.getInstance().playWithSelectionButtonClick(props.sequencer, props.searchResultArray[0], props.searchResultArray[props.searchResultArray.length-1], SEARCH_TYPE.EDIT_BOUNDARIES === props.searchType ,false, getNumberOfWordsBeforeAndAfterToPlay());
        }
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("sequencer", 'searchResultPlayWithSelection', 'search result play with selection', getPropsForTracking(props));
        }
    }

    const onPlayWithoutSelectionButtonClick= ()=>{
        if(props.onPlayWithoutSelectionButtonClick){
            props.onPlayWithoutSelectionButtonClick()
        }else{
            SequencerRendererUtils.getInstance().playWithoutSelectionButtonClick(props.sequencer, props.searchResultArray[0], props.searchResultArray[props.searchResultArray.length-1], SEARCH_TYPE.EDIT_BOUNDARIES === props.searchType, getNumberOfWordsBeforeAndAfterToPlay());
        }
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("sequencer", 'searchResultPlayWithoutSelection', 'search result play without selection',getPropsForTracking(props));
        }
    }

    const onPlayWithSelectionAndReconstructGapsButtonClick = ()=>{
        if(props.onPlayWithSelectionButtonClick){
            props.onPlayWithSelectionButtonClick()
        }else{
            SequencerRendererUtils.getInstance().playWithSelectionButtonClick(props.sequencer, props.searchResultArray[0], props.searchResultArray[props.searchResultArray.length-1], SEARCH_TYPE.EDIT_BOUNDARIES === props.searchType , true, getNumberOfWordsBeforeAndAfterToPlay());
        }
    }

    const getNumberOfWordsBeforeAndAfterToPlay = ()=>{
        if(SEARCH_TYPE.EDIT_BOUNDARIES === props.searchType){
            return 10;
        }
        if(SEARCH_TYPE.SEMANTIC_SEARCH === props.searchType){
            return 10;
        }
        if(SEARCH_TYPE.AI_EDIT_SUGGESTIONS === props.searchType){
            return 10;
        }
        return 4;
    }

    const onDismissButtonClick = ()=>{
        props.sequencer.getSearchWidget().dismissCurrentResultInFocus();
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("sequencer", 'searchResultDismissed', 'search result dismissed',getPropsForTracking(props));
        }
    }

    

    return (
        <ConfigProvider theme={ themeConfig }>
            <Card useEditorTheme={true} size="small" hoverable={false} style={{outlineStyle:props.selected?"solid":"none"}} className={`search-result-item-card ${themeConfig.inDarkMode?"":"border-neutral-300"} bg-transparent ${props.selected? `outline-2 ${themeConfig.inDarkMode?'':'bg-white'} outline-offset-2 outline-solid ${themeConfig.inDarkMode?"outline-primary":"outline-black"} `: `hover:bg-neutral-200 hover:border-neutral-300 ${themeConfig.inDarkMode? "hover:text-slate-700": ""}`} hover:cursor-pointer `} onClick={props.onClick}>
            <div className="flex flex-col">
                {props.commenterName && <div className="flex flex-row">
                    <div className="flex flex-col">
                        <Avatar/>
                    </div>
                    <div className="flex flex-col">
                        {props.commenterName}
                    </div>
                    {props.publishedDateTimestap && <div className="flex flex-col">
                        {moment(new Date(props.publishedDateTimestap)).fromNow()}
                    </div>}
                </div>}
                {props.typeLabel && <div className="flex flex-row">
                    <div className="flex flex-col">
                        <b>Type: {props.typeLabel}</b>
                    </div>
                </div>}
                {props.description && <div className="flex flex-row">
                    <div className="flex flex-col">
                        <span className="text-start" dangerouslySetInnerHTML={{__html:props.description}}></span>
                    </div>
                </div>}
                {(SEARCH_TYPE.SEMANTIC_SEARCH == props.searchType || SEARCH_TYPE.AI_EDIT_SUGGESTIONS == props.searchType) && props.selected && props.reason && <div className="flex flex-row text-left">
                    <div className="">
                        {props.type && <><b>Type: </b><span className="bold" dangerouslySetInnerHTML={ { __html: props.type } }></span><br/></>}
                        {props.confidence && <><b>Confidence: </b><span className="bold" dangerouslySetInnerHTML={ { __html: props.confidence } }></span><br/></>}
                        <b>Reason: </b><span className="italic" dangerouslySetInnerHTML={{__html:Utils.getInstance().removeWordIdsFromTranscription(props.reason)}}></span>
                    </div>
                </div>}
               {props.selected && <div className="flex flex-row justify-center">
                    {(SEARCH_TYPE.EDIT_BOUNDARIES == props.searchType)  && <div className="flex flex-col">
                        <Tooltip title={window.getI18n(ti18n,"PLAY_WITH_STRIKETHROUGH_CONTENT")} placement="bottom" align="center">
                        <button  onClick={onPlayWithSelectionAndReconstructGapsButtonClick}  className="hint--bottom hover:bg-primary-light text-primary" disabled=""><span className="icon pe-7s-play text-3xl relative"><span className=" icon strikethrough-icon menu_icon text-sm right-0 absolute bottom-0">S</span></span></button>
                        </Tooltip>
                    </div>}
                    {!(SEARCH_TYPE.EDIT_BOUNDARIES == props.searchType)  && <div className="flex flex-col">
                        <Tooltip title={window.getI18n(ti18n,"PLAY_BUT_SELECTED_HIGHLIGHT")} placement="bottom" align="center">
                        <button  onClick={onPlayWithoutSelectionButtonClick}  className="hint--bottom hover:bg-primary-light text-primary" disabled=""><span className="icon pe-7s-play text-3xl relative"><span className=" icon soundwave-next_1 text-sm right-0 absolute bottom-0 "></span></span></button>
                        </Tooltip>
                    </div>}
                    <div className="flex flex-col">
                        <Tooltip title={window.getI18n(ti18n,"PLAY_SELECTED_HIGHLIGHT")} placement="bottom" align="center">
                        <button onClick={onPlayWithSelectionButtonClick} className="hint--bottom hover:bg-primary-light text-primary" disabled=""><span className="icon pe-7s-play text-3xl relative"><span className=" icon soundwave-highlight text-sm right-0 absolute bottom-0"></span></span></button>
                        </Tooltip>
                    </div>
                    <div className="flex flex-col">
                        <button className={` button_with_label secondary border-2 border-black bg-gray`}  onClick={onDismissButtonClick}><label>{props.dismissActionButtonLabel || window.getI18n(ti18n, "DISMISS")}</label></button>
                    </div>
                    
                    {!(SEARCH_TYPE.EDIT_BOUNDARIES == props.searchType)  && <div className="flex flex-col">
                        <button className={` button_with_label border-2 border-black`}  onClick={onApplyButtonClick}><label>{props.applyActionButtonLabel || window.getI18n(ti18n, "REMOVE")}</label></button>
                    </div>}

                   
                </div>}
                { false && <div className="flex flex-row justify-center flex-auto mt-2">
                    <div className="flex flex-col flex-auto">
                        <button id="approveBtn" className="button_with_label" disabled=""><span className="icon pe-7s-check" ></span><label>Approve</label></button>
                    </div>
                    
                </div>}
                {false && <div className="flex flex-row flex-auto">
                    <div className="flex flex-col flex-auto">
                        <button id="updateBtn" className="button_with_label" disabled=""><span className="icon pe-7s-refresh-2"></span><label>Request Changes</label></button>
                    </div>
                    <div className="flex flex-col flex-auto">
                        <button id="rejectBtn" className="button_with_label" disabled=""><span className="icon pe-7s-close-circle"></span><label>Reject</label></button>
                    </div>
                </div>}
            </div>
            </Card>
        </ConfigProvider>
    );
};
EditorSearchCommentItem.propTypes = {
    uid: PropTypes.string,
    commenterName: PropTypes.string,
    searchType: PropTypes.string,
    publishedDateTimestap: PropTypes.number,
    typeLabel: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    confidence: PropTypes.string,
    reason: PropTypes.string,
    applyActionButtonLabel: PropTypes.string,
    dismissActionButtonLabel: PropTypes.string,
    index: PropTypes.number,
    sequencer: PropTypes.object,
    onDismissButtonClick: PropTypes.func,
    onApplyButtonClick: PropTypes.func,
    onPlayWithSelectionButtonClick: PropTypes.func,
    onPlayWithoutSelectionButtonClick: PropTypes.func,
    searchResultArray: PropTypes.array,
    searchRequestId: PropTypes.string,
    locale: PropTypes.object,
    selected: PropTypes.bool,
    onClick: PropTypes.func
};


export default EditorSearchCommentItem;