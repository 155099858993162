import { SpeakerDesignSystem } from "../Speaker";

export const ModernSpeaker: React.FC<SpeakerDesignSystem> = ({ firstName, lastName, title, company, designSystem: { titleFont, bodyFont } }) => {
    const speakerStyle: React.CSSProperties = {
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        color: "white",
        padding: 20,
        borderRadius: 15,
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
    }

    return (
        <div style={speakerStyle}>
            <div style={{ fontFamily: bodyFont, fontSize: 25 }}>{title}</div>
            <div style={{ fontFamily: bodyFont, fontSize: 25, color: "gray" }}>{company}</div>
            <div style={{ fontFamily: titleFont, fontSize: 50 }}>{firstName} {lastName}</div>
        </div>
    )
};