import { Img, useVideoConfig } from "remotion";
import { WatermarkDesignSystem } from "../Watermark";

export const DefaultWatermark: React.FC<WatermarkDesignSystem> = ({ imageURL, opacity, scale, designSystem }) => {
    const { width: canvasWidth, height: canvasHeight } = useVideoConfig();

    const imgStyle: React.CSSProperties = {
        opacity: opacity / 100,
        objectFit: 'contain',
    }

    const diagonalSize = Math.sqrt(canvasWidth * canvasWidth + canvasHeight * canvasHeight);
    imgStyle.width = diagonalSize * scale;
    // Ensure width is not larger than the canvas width
    imgStyle.width = Math.min(imgStyle.width, canvasWidth);

    return (
        <Img
            src={imageURL}
            style={imgStyle}
        />
    );
}