import React, { useState, useRef, useEffect } from 'react';
import {
    Card,
    Tooltip
} from 'antd';
import {
    ExpandOutlined,
    BorderBottomOutlined,
    ArrowsAltOutlined
} from '@ant-design/icons';

interface FocusRegion {
    x: number;
    y: number;
    width: number;
    height: number;
    aspectRatio: string;
}

interface VideoFocusRegionProps {
    videoUrl: string;
    focusRegion: FocusRegion;
    onFocusRegionChange: (region: FocusRegion) => void;
}

const VideoFocusRegionSelector: React.FC<VideoFocusRegionProps> = ({
    videoUrl,
    focusRegion,
    onFocusRegionChange
}) => {
    const [isDragging, setIsDragging] = useState(false);
    // State to track the initial mouse offset when dragging starts
    const [dragOffset, setDragOffset] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
    const [isResizing, setIsResizing] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    // Parse aspect ratio
    const [aspectWidth, aspectHeight] = focusRegion.aspectRatio.split(':').map(Number);
    const aspectRatio = aspectWidth / aspectHeight;

    const handleMouseDown = (e: React.MouseEvent, mode: 'move' | 'resize') => {
        e.stopPropagation();
        if (!containerRef.current) return;

        const container = containerRef.current;
        const containerRect = container.getBoundingClientRect();

        if (mode === 'move') {
            // Calculate the mouse position relative to the focus region
            const relativeX = (e.clientX - containerRect.left) / containerRect.width - focusRegion.x;
            const relativeY = (e.clientY - containerRect.top) / containerRect.height - focusRegion.y;

            setDragOffset({ x: relativeX, y: relativeY });
            setIsDragging(true);
        } else {
            setIsResizing(true);
        }
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (!containerRef.current || (!isDragging && !isResizing)) return;

        const container = containerRef.current;
        const containerRect = container.getBoundingClientRect();

        if (isDragging) {
            // Calculate new x and y positions, maintaining the initial mouse offset
            const newX = Math.max(0, Math.min(
                (e.clientX - containerRect.left) / containerRect.width - dragOffset.x,
                1 - focusRegion.width
            ));
            const newY = Math.max(0, Math.min(
                (e.clientY - containerRect.top) / containerRect.height - dragOffset.y,
                1 - focusRegion.height
            ));

            const updatedRegion = {
                ...focusRegion,
                x: newX,
                y: newY
            };

            onFocusRegionChange(updatedRegion);
        }

        if (isResizing) {
            // Calculate new width and height while maintaining aspect ratio
            // Ensure smallest width is 0.1
            const newWidth = Math.max(0.1, Math.min(
                (e.clientX - containerRect.left) / containerRect.width - focusRegion.x,
                1 - focusRegion.x
            ));
            const containerWidth = containerRect.width;
            const containerHeight = containerRect.height;
            const containerAspectRatio = containerWidth / containerHeight;
            let newHeight = (newWidth / aspectRatio) * containerAspectRatio;

            // Ensure the new dimensions don't exceed container
            if (focusRegion.y + newHeight <= 1) {
                const updatedRegion = {
                    ...focusRegion,
                    width: newWidth,
                    height: newHeight
                };

                onFocusRegionChange(updatedRegion);
            }
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        setIsResizing(false);
    };

    useEffect(() => {
        if (isDragging || isResizing) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
            return () => {
                window.removeEventListener('mousemove', handleMouseMove);
                window.removeEventListener('mouseup', handleMouseUp);
            };
        }
    }, [isDragging, isResizing, dragOffset]);

    return (
        <Card
            title="Video Focus Region Selector"
            extra={
                <Tooltip title="Drag to move, resize bottom-right handle">
                    <ExpandOutlined />
                </Tooltip>
            }
            className="w-full max-w-2xl mx-auto"
        >
            <div
                ref={containerRef}
                className="relative w-full aspect-video bg-gray-100 rounded-md overflow-hidden"
            >
                <video
                    ref={videoRef}
                    src={videoUrl}
                    className="absolute inset-0 w-full h-full object-cover"
                    controls
                />

                <div
                    className="absolute border-2 border-primary cursor-move"
                    style={{
                        left: `${focusRegion.x * 100}%`,
                        top: `${focusRegion.y * 100}%`,
                        width: `${focusRegion.width * 100}%`,
                        height: `${focusRegion.height * 100}%`,
                        backgroundColor: 'rgba(22, 119, 255, 0.2)'
                    }}
                    onMouseDown={(e) => handleMouseDown(e, 'move')}
                >
                    {/* Move handle */}
                    <div
                        className="absolute top-0 left-0 w-full h-full cursor-move"
                        title="Drag to move"
                    >
                        <BorderBottomOutlined className="absolute top-2 right-2 text-primary" />
                    </div>

                    {/* Resize handle */}
                    <div
                        className="absolute bottom-0 right-0 w-6 h-6 bg-primary cursor-se-resize flex items-center justify-center"
                        onMouseDown={(e) => handleMouseDown(e, 'resize')}
                        style={{
                            transform: 'translate(50%, 50%)',
                            pointerEvents: 'auto'
                        }}
                    >
                        <ArrowsAltOutlined className="text-black text-lg rotate-90" />
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default VideoFocusRegionSelector;