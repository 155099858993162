import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import StripeHelper from "models/helper/StripeHelper";
import StripeInvoiceView from "views/common/StripeInvoiceView";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from "i18n!nls/UpgradeCreatorSubscriptionWidgeti18n";
import UpgradeCreatorSubscriptionWidgetTemplate from 'text!../../../templates/upgradeCreatorSubscription/UpgradeCreatorSubscriptionWidgetTemplate.html';
import RSVP from 'rsvp';
import moment from 'moment';

const Rollout = RolloutHelper.getInstance();
const FIRST_TIER_COST_BILLED_MONTHLY = RolloutHelper.getInstance().getFeatureVariable(RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION, RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION.variables.pro_tier_cost_usd_billed_monthly, 9.99);
const FIRST_TIER_COST_BILLED_YEARLY = RolloutHelper.getInstance().getFeatureVariable(RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION, RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION.variables.pro_tier_cost_usd_billed_yearly, 7.99);
const SECOND_TIER_COST_BILLED_MONTHLY = RolloutHelper.getInstance().getFeatureVariable(RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION, RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION.variables.pro_plus_tier_cost_usd_billed_monthly, 25.99);
const SECOND_TIER_COST_BILLED_YEARLY = RolloutHelper.getInstance().getFeatureVariable(RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION, RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION.variables.pro_plus_tier_cost_usd_billed_yearly, 19.99);
const FIRST_TIER_MINUTES = RolloutHelper.getInstance().getFeatureVariable(RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION, RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION.variables.minutes_for_pro_tier, 300);
const SECOND_TIER_MINUTES = RolloutHelper.getInstance().getFeatureVariable(RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION, RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION.variables.minutes_for_pro_plus_tier, 1200);
const FREE_MINUTES =   RolloutHelper.getInstance().getFeatureVariable(RolloutHelper.FEATURES.SHOW_WARNING_WITH_NUMBER_OF_FREE_HOURS_LEFT_OF_TRANSCRIPTION, RolloutHelper.FEATURES.SHOW_WARNING_WITH_NUMBER_OF_FREE_HOURS_LEFT_OF_TRANSCRIPTION.variables.number_of_free_minutes_of_transcription, 30);
const USE_CAPTCHA_VALIDATION = true;
const CAPTCHA_SITE_KEY = "6LfInWknAAAAACtY26KHdXIImyAdvLZHq_MCt-Nz";

const UpgradeCreatorSubscriptionWidgetView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(UpgradeCreatorSubscriptionWidgetTemplate);
        this._couponInfo =  options.couponInfo;
    },
    
    events : {
        
    },

    _initStripeElement: function(){
        
    },

    setUserSubscribedToProSubscritionMonthly: function(){
        this.creator_plans_listing_container$el.attr("current_subscribed_tier","pro_tier_monthly");
    },

    setUserSubscribedToProPlusSubscritionMonthly: function(){
        this.creator_plans_listing_container$el.attr("current_subscribed_tier","pro_plus_tier_monthly");
    },

    setUserSubscribedToProSubscritionYearly: function(){
        this.creator_plans_listing_container$el.attr("current_subscribed_tier","pro_tier_yearly");
    },

    setUserSubscribedToProPlusSubscritionYearly: function(){
        this.creator_plans_listing_container$el.attr("current_subscribed_tier","pro_plus_tier_yearly");
    },

    setUserSubscribedToCreatorEnterpriseSubscritionMonthly: function(){
        this.creator_plans_listing_container$el.attr("current_subscribed_tier","enterprise_tier_monthly");
    },

    setUserSubscribedToCreatorEnterpriseSubscritionYearly: function(){
        this.creator_plans_listing_container$el.attr("current_subscribed_tier","enterprise_tier_yearly");
    },

    setUserSubscribedHasFreeSubscription: function(){
        this.creator_plans_listing_container$el.attr("current_subscribed_tier","free_tier");
    },

    setSwitchYearlBillingValue : function(yearlyBilling){
        this.$el.find(".switch.yearly_billing_switch  input").prop('checked', yearlyBilling);
        this.creator_plans_listing_container$el.attr("yearly_billing_selected",yearlyBilling?"true":"false");
    },

    getYearlyBillingSwitchValue : function(){
        return this.$el.find(".switch.yearly_billing_switch  input").prop('checked');
    },

    showPaymentSection : function(planSelectedToChange){
        this.$el.attr("displayed_section","payment_section");
        let planName  = null;
        if(planSelectedToChange === StripeHelper.getInstance().getAvailableCreatorPlanTypes().PRO_PLAN_MONTHLY || planSelectedToChange === StripeHelper.getInstance().getAvailableCreatorPlanTypes().PRO_PLAN_YEARLY){
            planName = Utils.getInstance().getCreatorFirstPaidTierName();
        }
        if(planSelectedToChange === StripeHelper.getInstance().getAvailableCreatorPlanTypes().PRO_PLUS_PLAN_MONTHLY || planSelectedToChange === StripeHelper.getInstance().getAvailableCreatorPlanTypes().PRO_PLUS_PLAN_YEARLY){
            planName = Utils.getInstance().getCreatorSecondPaidTierName();
        }
        
        this.$el.find(".payment_section .header_section .header_title").html("Upgrade to Trebble "+planName);
    },

    showPaymentChangeSection : function(show){
        this.$el.attr("display_update_payment_section",show?"true": "false");
    },

    showPlanListingSection : function(){
        this.$el.attr("displayed_section","plan_listing_section");
    },

    setPaymentUsingExistingPaymentMehtod: function(useExistingPaymentMethod){
        if(useExistingPaymentMethod){
            this.$el.attr("pay_using_existing_payment_method","true");
        }else{
            this.$el.attr("pay_using_existing_payment_method","false");
        }
    },

    setDefaultPaymentMethodInfo : function(displayablePaymentMethodInfo){
        this.$el.find(".default_payment_method .itemDescription").html(displayablePaymentMethodInfo);
    },

    onYearlyBillingSwitchClicked: function(){
        const yearlyBilling = this.getYearlyBillingSwitchValue();
        this.trigger("onYearlyBillingChange", yearlyBilling);
        this.creator_plans_listing_container$el.attr("yearly_billing_selected",yearlyBilling?"true":"false");
    },

    setContentLoading : function(isLoading){
        if(isLoading){
            this.$el.attr("is_content_loading", "true");
        }else{
            this.$el.attr("is_content_loading", "false");
        }
    },

    onSubscribeToProPlanButtonClicked : function(){
        this.trigger("initiatePaymentOrChangeToForProPlan",  this.getYearlyBillingSwitchValue());
    },

    onSubscribeToProPlusPlanButtonClicked : function(){
        this.trigger("initiatePaymentOrChangeToForProPlusPlan",  this.getYearlyBillingSwitchValue());
    },
    
    setNextBillingDateOrCancelDateOnProPlan: function( nextBillingDate, subscriptionCanceledEndDate){
        this._updateNextBillingDateOrCancelDate(this.$el.find(".pro_tiers_info .plan_renewal_or_end_date"), nextBillingDate, subscriptionCanceledEndDate);
    },

    clearNextBillingDateOrCancelDateOnProPlan: function(){
        this.$el.find(".pro_tiers_info .plan_renewal_or_end_date").html("");
    },

    setNextBillingDateOrCancelDateOnProPlusPlan: function( nextBillingDate, subscriptionCanceledEndDate){
        this._updateNextBillingDateOrCancelDate(this.$el.find(".pro_plus_tiers_info .plan_renewal_or_end_date"), nextBillingDate, subscriptionCanceledEndDate);
    },

    clearNextBillingDateOrCancelDateOnProPlusPlan: function( nextBillingDate, subscriptionCanceledEndDate){
        this.$el.find(".pro_plus_tiers_info .plan_renewal_or_end_date").html("");
    },

    _updateNextBillingDateOrCancelDate: function(planRenewalOrCanceledDate$el, nextBillingDate, subscriptionCanceledEndDate){
        if(nextBillingDate){
            planRenewalOrCanceledDate$el.html(window.getI18n(ti18n, "YOUR_PLAN_RENEWS_ON") +" "+moment(nextBillingDate).format("MMMM Do YYYY"));
        }else{
            if(subscriptionCanceledEndDate){
                planRenewalOrCanceledDate$el.html(window.getI18n(ti18n, "YOUR_PLAN_WILL_BE_CANCELLED_ON") +" "+moment(subscriptionCanceledEndDate).format("MMMM Do YYYY"))
            }else{
                planRenewalOrCanceledDate$el.html("");

            }
        }
    },

    updateInvoiceDetails : function(stripeInvoiceInfo){
        const stripeInvoiceView = new StripeInvoiceView({"model": stripeInvoiceInfo});
        stripeInvoiceView.render();
        this.$el.find(".invoice_placeholder").html(stripeInvoiceView.$el.html());
    },

    getPaymentElement$el : function(){
        return this.paymentElement$el;
    },

    getPaymentChangeSection$el: function(){
        return this.$el.find(".changePaymentMethodSection")
    },

    setReplacePaymentMethodWidgetView : function(replacePaymentMethodWidgetView){
        this.getPaymentChangeSection$el().html("");
        if(replacePaymentMethodWidgetView){
            this.getPaymentChangeSection$el().append(replacePaymentMethodWidgetView.$el);
        }
    },

    onCompletePaymentButtonClicked : function(){
        this.trigger("completePayment");
    },

    displayNoPaymentError: function(){
        this._displayPaymentError(false);
    },

    _displayPaymentError: function(displayError, errorMessage){
        this.$el.attr("display_payment_error", displayError?"true": "false");
        this.$el.find("#payment-error-message").html(Utils.getInstance().getErrorMessageFromObject(errorMessage));
    },

    displayPaymentError: function(errorMessage){
        this._displayPaymentError(true, errorMessage);
    },

    onConfirmChangePlanButtonClicked : function(){
        this.trigger("confirmChangePlan");
    },

    onChangePaymentMethodButtonClicked : function(){
        this.trigger("showChangePaymentMethod");
    },

    onBackButtonClicked: function(){
        this.trigger("showPlansList");
        this.showPlanListingSection();
    },

    onCaptchaSuccessfull : function(captchaToken){
        this.enableDisableCompletePaymentButton(true);
        this.trigger("captchaSuccess", captchaToken);	
    },

    onCaptchaExpired : function(){
        this.enableDisableCompletePaymentButton(false);
        this.trigger("captchaExpired");
    },

    enableDisableCompletePaymentButton :function(enable){
        if(enable){
            this.$el.find(".complete_subscription_payment_button").removeClass("disabled")
            this.$el.find(".complete_subscription_payment_button").prop( "disabled", false );
        }else{
            this.$el.find(".complete_subscription_payment_button").addClass("disabled")
            this.$el.find(".complete_subscription_payment_button").prop( "disabled", true );
        }
    },

    onCaptchaError : function(){
        
    },

    getCaptchaReponse : function(){
        if(USE_CAPTCHA_VALIDATION){
            return (window.googleCaptchaLoaded && window.grecaptcha)? !!window.grecaptcha.getResponse(this._captchaWidgetId): null;
        }else{
            return true;
        }
    },

    _getCaptchaWidgetId : function(){
        return this._captchaWidgetId;
    },

    _initCaptcha : function(){
        if(USE_CAPTCHA_VALIDATION && window.googleCaptchaLoaded && window.grecaptcha){
            this._captchaWidgetId = window.grecaptcha.render(this.captchaContainer$el.get(0), {
              'sitekey' : CAPTCHA_SITE_KEY,
              'callback' : this.onCaptchaSuccessfull.bind(this),
              'expired-callback	' : this.onCaptchaExpired.bind(this),
              //'error-callback' : this.onCaptchaError.bind(this),
              'theme' : 'light'
            });
            this.enableDisableCompletePaymentButton(false);
        }
    },

    render : function() {
        const templateParams = {};
        templateParams.ti18n = ti18n;
        templateParams.FIRST_TIER_COST_BILLED_MONTHLY = FIRST_TIER_COST_BILLED_MONTHLY;
        templateParams.FIRST_TIER_COST_BILLED_YEARLY = FIRST_TIER_COST_BILLED_YEARLY;
        templateParams.SECOND_TIER_COST_BILLED_MONTHLY = SECOND_TIER_COST_BILLED_MONTHLY;
        templateParams.SECOND_TIER_COST_BILLED_YEARLY = SECOND_TIER_COST_BILLED_YEARLY;
        templateParams.FIRST_TIER_MINUTES = FIRST_TIER_MINUTES;
        templateParams.SECOND_TIER_MINUTES = SECOND_TIER_MINUTES;
        templateParams.FREE_MINUTES = FREE_MINUTES;
        templateParams.discountPercentage = this._couponInfo && this._couponInfo.valid ? this._couponInfo.percent_off/100 : 0;
        templateParams.FIRST_TIER_PLAN_NAME = Utils.getInstance().getCreatorFirstPaidTierName();
        templateParams.SECOND_TIER_PLAN_NAME = Utils.getInstance().getCreatorSecondPaidTierName();
        this.$el.html(this.compiledTemlate(templateParams));
        this.setElement(this.$el.find("div").first());
        this.creator_plans_listing_container$el = this.$el.find(".creator_plans_listing_container");
        this.captchaContainer$el =  this.$el.find("#google-captcha-element");
        this.$el.find(".switch.yearly_billing_switch  input").on("click",this.onYearlyBillingSwitchClicked.bind(this));
        this.$el.find(".pro_tiers_info .buy_subscription_button").click(this.onSubscribeToProPlanButtonClicked.bind(this));
        this.$el.find(".pro_plus_tiers_info .buy_subscription_button").click(this.onSubscribeToProPlusPlanButtonClicked.bind(this));
        this.paymentElement$el =  this.$el.find(".payment_section #payment-element");
        this.$el.find(".complete_subscription_payment_button:not(.disabled)").click(this.onCompletePaymentButtonClicked.bind(this));
        this.$el.find(".confirm_change_plan_btn").click(this.onConfirmChangePlanButtonClicked.bind(this));
        this.$el.find(".back_to_plan_listing_btn").click(this.onBackButtonClicked.bind(this))
        this.$el.find("#updatePaymentMethodBtn").click(this.onChangePaymentMethodButtonClicked.bind(this));
        this._initCaptcha();
        
        //this._initStripeElement();
        return this;
    }

});
export default UpgradeCreatorSubscriptionWidgetView;