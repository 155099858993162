import { Sequence, useVideoConfig } from "remotion";
import { Caption } from "@remotion/captions";
import { ModernCaptionPage } from "./ModernCaptionPage";
import { createGroupedCaptions } from "../CaptionGrouper";
import { useMemo } from "react";
import { CaptionDesignSystem } from "../Caption";
import { getMaxFontSizeFromFontSizeRatio } from "../../DesignSystem";

export const ModernCaptionSeries: React.FC<CaptionDesignSystem> = ({ videoRenderData, startTimeOffset, milisecondGrouping, designSystem: { accentColor, titleFont, canvas}, fontSizeRatio,  position: captionPosition }) => {
    const { fps, width,height } = useVideoConfig();
    const maxFontSize = getMaxFontSizeFromFontSizeRatio(fontSizeRatio, width, height, canvas);

    const captions: Caption[] = useMemo(() => {
        return videoRenderData.flatMap((segment) => {
            return segment.nodeSchedulingInfoMergedArray.flatMap((schedulingNodeInfo: any) => {
                const sequencerNodeRef = schedulingNodeInfo.sequencerNodeReference;
                const captionSegment  = sequencerNodeRef.audioSegment;
                const nodeDuration  = schedulingNodeInfo.duration? schedulingNodeInfo.duration - schedulingNodeInfo.relativeStartTime + schedulingNodeInfo.relativeEndTime: schedulingNodeInfo.endTimeOffsetForSourceBuffer - schedulingNodeInfo.startTimeOffsetForSourceBuffer - schedulingNodeInfo.relativeStartTime + schedulingNodeInfo.relativeEndTime;
                if (captionSegment.type === 'WordAudioSegment' && captionSegment.content) {
                    //const deltaFromSequenceTimeAndVideoTime = segment.startTimeOffsetForSourceBuffer - segment.startTimeOffset;
                    return {
                        text: captionSegment.content,
                        startMs: schedulingNodeInfo.startTimeOffset - startTimeOffset,
                        endMs: schedulingNodeInfo.startTimeOffset - startTimeOffset + nodeDuration,
                        timestampMs: nodeDuration,
                        confidence: Number(captionSegment.confidence),
                    }
                }
                return [];
            })
        });
    }, [videoRenderData, startTimeOffset]);

    const { pages } = useMemo(() => {
        return createGroupedCaptions({
            combineTokensWithinMilliseconds: milisecondGrouping,
            captions,
        });
    }, [milisecondGrouping, captions]);

    return (
        <>
            {pages.map((page, index) => {
                const nextPage = pages[index + 1] ?? null;
                const subtitleStartFrame = (page.startMs / 1000) * fps;
                const subtitleEndFrame = Math.min(
                    nextPage ? (nextPage.startMs / 1000) * fps : Infinity,
                    subtitleStartFrame + milisecondGrouping,
                );
                const durationInFrames = subtitleEndFrame - subtitleStartFrame;
                if (durationInFrames <= 0) {
                    return null;
                }

                return (
                    <Sequence
                        key={index}
                        from={subtitleStartFrame}
                        durationInFrames={durationInFrames}
                        layout="none"
                    >
                        <ModernCaptionPage page={page} fontFamily={titleFont} currentSpokenWordColor={accentColor} maxFontSize={maxFontSize} position={captionPosition} />;
                    </Sequence>
                );
            })}
        </>
    );
};