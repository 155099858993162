import { DesignSystem, ElementDesignSystem, ElementDesignSystemConfig } from "../DesignSystem";
import { DefaultWatermark } from "./DefaultWatermark/DefaultWatermark";

export interface WatermarkDesignSystemConfig extends Pick<ElementDesignSystemConfig, 'position' | 'animation'> {
    imageURL: string;
    opacity: number;
    scale: number;
}

export class WatermarkDesignSystem extends ElementDesignSystem implements WatermarkDesignSystemConfig {
    public imageURL: string;
    public opacity: number;
    public scale: number;

    constructor(
        config: WatermarkDesignSystemConfig,
        designSystem: DesignSystem,
        inCloudRenderingEnvironment:boolean
    ) {
        super({ ...config, designSystem: designSystem, affectedBySafeZone: true, inCloudRenderingEnvironment});
        this.imageURL = config.imageURL;
        this.opacity = config.opacity;
        this.scale = config.scale;
    }

    protected renderChild() {
        return (
            <DefaultWatermark {...this} />
        );
    }
}