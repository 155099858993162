import { useRef } from "react";
import { useVideoConfig, OffthreadVideo, Audio } from "remotion";
import { VideoSegmentMultiFocusDesignSystemConfig } from "../../VideoSegmentMultiFocus";
import { onVideoFrame } from "../DefaultVideoSegmentMultiFocus";

const FourSplit16x9: React.FC<VideoSegmentMultiFocusDesignSystemConfig> = ({ startTimestampMS, endTimestampMS, videoURL, spacing, borderRadius, border, framing, designSystem, audioURL }) => {
    const { width, height, fps } = useVideoConfig();

    // We will display four 16:9 videos in a grid
    const canvasRefs = [useRef<HTMLCanvasElement>(null), useRef<HTMLCanvasElement>(null), useRef<HTMLCanvasElement>(null), useRef<HTMLCanvasElement>(null)];

    const paddingSize = spacing ? Math.sqrt(width * height) * 0.05 : 0;
    const borderThickness = spacing ? Math.sqrt(width * height) / 150 : 0;

    const totalHeight = (height - paddingSize * 2 - borderThickness * 2) / 2;
    const totalWidth = totalHeight * 16 / 9;

    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: 'auto auto',
                gridTemplateRows: 'auto auto',
                width: '100%',
                height: '100%',
                justifyContent: 'space-evenly',
                alignContent: 'space-evenly',
                justifyItems: 'center',
                alignItems: 'center',
            }}
        >
            <OffthreadVideo
                style={{ opacity: 0, display: 'none' }}
                src={videoURL}
                startFrom={startTimestampMS / 1000 * fps}
                endAt={endTimestampMS / 1000 * fps}
                onVideoFrame={(frame) => onVideoFrame(frame, framing["16:9"].focusRegions, canvasRefs)}
                muted={audioURL == undefined ? false : true}
            />
            {audioURL && <Audio src={audioURL} />}
            {framing["16:9"].focusRegions.map((_, index) => (
                <canvas
                    key={index}
                    ref={canvasRefs[index]}
                    width={totalWidth}
                    height={totalHeight}
                    style={{
                        border: border ? `${borderThickness}px solid black` : `none`,
                        borderRadius: borderRadius,
                        aspectRatio: '16 / 9',
                    }}
                />
            ))}
        </div>
    );
}

const FourSplit9x16: React.FC<VideoSegmentMultiFocusDesignSystemConfig> = ({ startTimestampMS, endTimestampMS, videoURL, spacing, borderRadius, border, framing, designSystem, audioURL }) => {
    const { width, height, fps } = useVideoConfig();

    // We will display four 9:16 videos in a grid
    const canvasRefs = [useRef<HTMLCanvasElement>(null), useRef<HTMLCanvasElement>(null), useRef<HTMLCanvasElement>(null), useRef<HTMLCanvasElement>(null)];

    const paddingSize = spacing ? Math.sqrt(width * height) * 0.05 : 0;
    const borderThickness = spacing ? Math.sqrt(width * height) / 150 : 0;

    const totalHeight = (height - paddingSize * 2 - borderThickness * 2) / 2;
    const totalWidth = totalHeight * 9 / 16;

    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: 'auto auto',
                gridTemplateRows: 'auto auto',
                width: '100%',
                height: '100%',
                justifyContent: 'space-evenly',
                alignContent: 'space-evenly',
                justifyItems: 'center',
                alignItems: 'center',
            }}
        >
            <OffthreadVideo
                style={{ opacity: 0, display: 'none' }}
                src={videoURL}
                startFrom={startTimestampMS / 1000 * fps}
                endAt={endTimestampMS / 1000 * fps}
                onVideoFrame={(frame) => onVideoFrame(frame, framing["9:16"].focusRegions, canvasRefs)}
                muted={audioURL == undefined ? false : true}
            />
            {audioURL && <Audio src={audioURL} />}
            {framing["9:16"].focusRegions.map((_, index) => (
                <canvas
                    key={index}
                    ref={canvasRefs[index]}
                    width={totalWidth}
                    height={totalHeight}
                    style={{
                        border: border ? `${borderThickness}px solid black` : `none`,
                        borderRadius: borderRadius,
                        aspectRatio: '9 / 16',
                    }}
                />
            ))}
        </div>
    );
}

const FourSplit1x1: React.FC<VideoSegmentMultiFocusDesignSystemConfig> = ({ startTimestampMS, endTimestampMS, videoURL, spacing, borderRadius, border, framing, designSystem, audioURL }) => {
    const { width, height, fps } = useVideoConfig();

    // We will display four 1:1 videos in a grid
    const canvasRefs = [useRef<HTMLCanvasElement>(null), useRef<HTMLCanvasElement>(null), useRef<HTMLCanvasElement>(null), useRef<HTMLCanvasElement>(null)];

    const paddingSize = spacing ? Math.sqrt(width * height) * 0.05 : 0;
    const borderThickness = spacing ? Math.sqrt(width * height) / 150 : 0;

    const totalHeight = (height - paddingSize * 2 - borderThickness * 2) / 2;
    const totalWidth = totalHeight * (1 / 1);

    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: 'auto auto',
                gridTemplateRows: 'auto auto',
                width: '100%',
                height: '100%',
                justifyContent: 'space-evenly',
                alignContent: 'space-evenly',
                justifyItems: 'center',
                alignItems: 'center',
            }}
        >
            <OffthreadVideo
                style={{ opacity: 0, display: 'none' }}
                src={videoURL}
                startFrom={startTimestampMS / 1000 * fps}
                endAt={endTimestampMS / 1000 * fps}
                onVideoFrame={(frame) => onVideoFrame(frame, framing["1:1"].focusRegions, canvasRefs)}
                muted={audioURL == undefined ? false : true}
            />
            {audioURL && <Audio src={audioURL} />}
            {framing["1:1"].focusRegions.map((_, index) => (
                <canvas
                    key={index}
                    ref={canvasRefs[index]}
                    width={totalWidth}
                    height={totalHeight}
                    style={{
                        border: border ? `${borderThickness}px solid black` : `none`,
                        borderRadius: borderRadius,
                        aspectRatio: '1 / 1',
                    }}
                />
            ))}
        </div>
    );
}

export const FourSplitFocusVideoSegment: React.FC<VideoSegmentMultiFocusDesignSystemConfig> = (props) => {
    switch (props.designSystem.canvas.aspectRatio) {
        case "16:9":
            return <FourSplit16x9 {...props} />
        case "9:16":
            return <FourSplit9x16 {...props} />
        case "1:1":
            return <FourSplit1x1 {...props} />
        default:
            throw new Error(`Unsupported aspect ratio: ${props.designSystem.canvas.aspectRatio}`);
    }
}