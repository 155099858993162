import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import SequencerLibrary from "models/audioEditor/SequencerLibrary";
import AudioSegmentFactory from "models/audioEditor/segments/AudioSegmentFactory";
import AudioFilterFactory from "models/audioEditor/filters/AudioFilterFactory";
import SequencerNode from "models/audioEditor/sequencerNodes/SequencerNode";
import DeletedSequencerNode from "models/audioEditor/sequencerNodes/DeletedSequencerNode";
import PauseSequencerNode from "models/audioEditor/sequencerNodes/PauseSequencerNode";
import PunctuationSequencerNode from "models/audioEditor/sequencerNodes/PunctuationSequencerNode";
import UnsupportedAudioSequencerNode from "models/audioEditor/sequencerNodes/UnsupportedAudioSequencerNode";
import WordSequencerNode from "models/audioEditor/sequencerNodes/WordSequencerNode";
import WrapSequencerNode from "models/audioEditor/sequencerNodes/WrapSequencerNode";
import ReplacementSequencerNode from "models/audioEditor/sequencerNodes/ReplacementSequencerNode";
import StartMusicWrapSequencerNode from "models/audioEditor/sequencerNodes/StartMusicWrapSequencerNode";
import EndMusicWrapSequencerNode from "models/audioEditor/sequencerNodes/EndMusicWrapSequencerNode";
import StartWrapSequencerNode from "models/audioEditor/sequencerNodes/StartWrapSequencerNode";
import EndWrapSequencerNode from "models/audioEditor/sequencerNodes/EndWrapSequencerNode";
import AudioSequencerNode from "models/audioEditor/sequencerNodes/AudioSequencerNode";
import VideoSequencerNode from "models/audioEditor/sequencerNodes/VideoSequencerNode";
import AudioEventSequencerNode from "models/audioEditor/sequencerNodes/AudioEventSequencerNode";
import NonRetainedDeletedSequencerNode from "models/audioEditor/sequencerNodes/NonRetainedDeletedSequencerNode";
import RSVP from "rsvp";


const sequencerNodeContructors = [SequencerNode,
    DeletedSequencerNode,
    PauseSequencerNode,
    PunctuationSequencerNode,
    UnsupportedAudioSequencerNode,
    WordSequencerNode,
    WrapSequencerNode,
    ReplacementSequencerNode,
    StartMusicWrapSequencerNode,
    EndMusicWrapSequencerNode,
    StartWrapSequencerNode,
    EndWrapSequencerNode,
    AudioSequencerNode,
    VideoSequencerNode,
    AudioEventSequencerNode,
    NonRetainedDeletedSequencerNode];
const SequencerNodeFactory =  Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        this._projectIdToNodeCidToSequencerNodeMap = {};
       
    },

    _addNodeToProjectIdMap : function(projectId, sequencerNode, cloneNodeCidFromSerializeData, clonedCidToOriginalCid){
        let projectNodeCidToSequencerNodeMap = this._projectIdToNodeCidToSequencerNodeMap[projectId];
        if(!projectNodeCidToSequencerNodeMap){
            projectNodeCidToSequencerNodeMap  = {};
            this._projectIdToNodeCidToSequencerNodeMap[projectId] = projectNodeCidToSequencerNodeMap;
        }
        const originalCid = clonedCidToOriginalCid? clonedCidToOriginalCid[cloneNodeCidFromSerializeData]: cloneNodeCidFromSerializeData;
        projectNodeCidToSequencerNodeMap[originalCid] = sequencerNode;
    },

    getSequencerNodeUsingNodeCidFromProject : function(nodeCid, projectId, sequencer){
        let projectNodeCidToSequencerNodeMap  = this._projectIdToNodeCidToSequencerNodeMap[projectId];
        if(!projectNodeCidToSequencerNodeMap){
            //project was not loaded in sequencer so default to sequencerr reference
            return sequencer.getSequencerNodeByNodeCid(nodeCid);
        }else{
            return projectNodeCidToSequencerNodeMap[nodeCid];
        }
    },

    createSequencerNodesFromNodeCidToSerializedDataMap : function(cloneCidToSequencerNodeSerializedData, sequencer, projectId, clonedCidToOriginalCid){
        const promises = [];
        const nodeCidToSequencerNode = {};
        for(let nodeCid in cloneCidToSequencerNodeSerializedData){
            const sequencerNodeSerializedData = cloneCidToSequencerNodeSerializedData[nodeCid]; 
            for(let i =0; i < sequencerNodeContructors.length; i++){
                const contructorClass = sequencerNodeContructors[i];
                if(sequencerNodeSerializedData.type === contructorClass.CLASSNAME){
                    const sequencerNodeInstance = new contructorClass({sequencer});
                    nodeCidToSequencerNode[nodeCid] = sequencerNodeInstance;
                    this._addNodeToProjectIdMap(projectId, sequencerNodeInstance, nodeCid, clonedCidToOriginalCid);
                    promises.push(sequencerNodeInstance.restoreFromSerializedData(sequencerNodeSerializedData, AudioSegmentFactory.getInstance(), AudioFilterFactory.getInstance(), sequencer.getAdditionalSpeakerInforArray()));
                    break;
                }
            }
        }

        return RSVP.Promise.all(promises).then(function(){
            for(let nodeCid in nodeCidToSequencerNode){
                const sequencerNode = nodeCidToSequencerNode[nodeCid];
                const serializedData = cloneCidToSequencerNodeSerializedData[nodeCid];
                sequencerNode.afterAllInstancesCreatedFromSerializedData(serializedData, nodeCidToSequencerNode);
                sequencerNode.setSequencer(sequencer);
            }
            return nodeCidToSequencerNode;
        })
    },




    


});


const sequencerNodeFactoryInstance = new SequencerNodeFactory();

export default {
    getInstance : function() {
        return sequencerNodeFactoryInstance;
    }
}; 