import { DesignSystem } from "./Components/DesignSystem";
import { CaptionDesignSystem, CaptionDesignSystemConfig } from "./Components/Captions/Caption";
import { BackgroundDesignSystem, BackgroundDesignSystemConfig } from "./Components/Background/Background";
import { WatermarkDesignSystem, WatermarkDesignSystemConfig } from "./Components/Watermark/Watermark";
import { SpeakerDesignSystem, SpeakerDesignSystemConfig } from "./Components/Speaker/Speaker";
import { VideoMultiFocusDesignSystem, VideoMultiFocusDesignSystemConfig } from "./Components/VideoMultiFocus/VideoMultiFocus";
import { VideoDesignSystemConfig } from "./Components/Video/Video";
import dataScheme from "./video_data_to_render.json";
import React from "react";


export type TrebbleClipCreatorInputConfig = {
  durationInMs:number;
  framePerSec: number;
  designSystem: DesignSystem;
  audioUrl: string,
  captionConfig: CaptionDesignSystemConfig;
  backgroundDesignConfig: BackgroundDesignSystemConfig;
  watermarkDesignConfig?: WatermarkDesignSystemConfig;
  speakerDesignConfig?: SpeakerDesignSystemConfig;
  videoConfig: VideoMultiFocusDesignSystemConfig;
  videoRenderData: typeof dataScheme;
  startTimeOffset: number;
  firstScheduledNode : any;
  inCloudRenderingEnvironment: boolean;
}

export type VideoConfig = {
  type: "singleFocus",
  config: VideoDesignSystemConfig
} | {
  type: "dualFocus",
  config: VideoMultiFocusDesignSystemConfig
}

export const TrebbleClipCreatorInput: React.FC<TrebbleClipCreatorInputConfig> = (config) => {
  // MODERN DESIGN
  // const selectedDesign = new DesignSystem(
  //   "modern",
  //   "#f5425d",
  //   "#42f554",
  //   "#42f5f5",
  //   "Helvetica",
  //   "Helvetica",
  //   "https://images.squarespace-cdn.com/content/v1/54c81a6fe4b0652cb365f97d/1535192418838-95VVAQV7U1R2P4DR2HVC/rsz_1rsz_1trebblelogofullred.png?format=1500w"
  // );


  const captionDesign = config.captionConfig ? new CaptionDesignSystem(config.captionConfig, config.designSystem, config.videoRenderData, config.startTimeOffset, config.firstScheduledNode) : null;

  const backgroundDesignSystem = new BackgroundDesignSystem(config.backgroundDesignConfig, config.designSystem);

  const videoDesignSystem = new VideoMultiFocusDesignSystem(config.videoConfig, config.designSystem, config.videoRenderData, config.startTimeOffset, config.inCloudRenderingEnvironment, config.firstScheduledNode);

  const watermarkDesignSystem = config.watermarkDesignConfig ? new WatermarkDesignSystem(config.watermarkDesignConfig, config.designSystem, config.inCloudRenderingEnvironment) : null;

  const speakerDesignSystem = config.speakerDesignConfig ? new SpeakerDesignSystem(config.speakerDesignConfig, config.designSystem) : null;

  return (
    <div>
      {/* Blurred Background Image */}
      {backgroundDesignSystem.render()}

      {/* Main Video (Series of clips) */}
      {videoDesignSystem.render()}

      {/* Captions */}
      {captionDesign?.render()}

      {/* Logo */}
      {watermarkDesignSystem?.render()}

      {/* Speaker Info */}
      {speakerDesignSystem?.render()}
    </div>
  );
};


export default TrebbleClipCreatorInput;