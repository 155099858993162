import { useRef } from "react";
import { useVideoConfig, OffthreadVideo, Audio } from "remotion";
import { VideoSegmentMultiFocusDesignSystemConfig } from "../../VideoSegmentMultiFocus";
import { onVideoFrame } from "../DefaultVideoSegmentMultiFocus";

const SquareFocus: React.FC<VideoSegmentMultiFocusDesignSystemConfig> = ({ startTimestampMS, endTimestampMS, videoURL, spacing, borderRadius, border, framing, designSystem, audioURL }) => {
    const { width, height, fps } = useVideoConfig();

    // We will display a single 1:1 video
    const canvasRefs = [useRef<HTMLCanvasElement>(null)];

    const paddingSize = spacing ? Math.sqrt(width * height) * 0.05 : 0;
    const borderThickness = spacing ? Math.sqrt(width * height) / 150 : 0;

    const size = Math.min(width, height);

    const totalWidth = (size - paddingSize * 2 - borderThickness * 2);
    const totalHeight = totalWidth;

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                alignContent: 'space-evenly',
                width: '100%',
                height: '100%',
            }}
        >
            <OffthreadVideo
                style={{ opacity: 0, display: 'none' }}
                src={videoURL}
                startFrom={startTimestampMS / 1000 * fps}
                endAt={endTimestampMS / 1000 * fps}
                onVideoFrame={(frame) => onVideoFrame(frame, framing[designSystem.canvas.aspectRatio].focusRegions, canvasRefs)}
                muted={audioURL == undefined ? false : true}
            />
            {audioURL && <Audio src={audioURL} />}
            {framing[designSystem.canvas.aspectRatio].focusRegions.map((_, index) => (
                <canvas
                    key={index}
                    ref={canvasRefs[index]}
                    width={totalWidth}
                    height={totalHeight}
                    style={{
                        border: border ? `${borderThickness}px solid black` : `none`,
                        borderRadius: borderRadius,
                        aspectRatio: '1 / 1',
                    }}
                />
            ))}
        </div>
    );
}


export const SquareFocusVideoSegment: React.FC<VideoSegmentMultiFocusDesignSystemConfig> = (props) => {
    return (
        <SquareFocus {...props} />
    )
}