import React from 'react';
import { BackgroundDesignSystem } from '../Background';
import { Img } from 'remotion';

export const DefaultBackground: React.FC<BackgroundDesignSystem> = ({ imageURL, color, format }) => {
    if (format === 'color') {
        return (
            <div
                style={{
                    backgroundColor: color,
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                }} />
        );
    }

    return (
        <Img
            src = {imageURL}
            style={{
                objectFit: 'cover',
                position: 'absolute',
                width: '100%',
                height: '100%',
            }} />
    );
}