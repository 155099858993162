import { ReactNode } from "react";
import { AbsoluteFill, Sequence } from "remotion";
import { AnimatedWrapper } from "./AnimatedWrapper";

/** Encapsulates common design attributes for TrebbleFM components */
export interface DesignSystem {
    style: "modern" | "classic",
    primaryColor: string,
    secondaryColor: string,
    accentColor: string,
    titleFont: string,
    bodyFont: string,
    logoURL: string,
    canvas: VideoCanvas,
}

export interface VideoCanvas {
    aspectRatio: "16:9" | "9:16" | "1:1";
    width: number;
    height: number;
    safeZone: SafeZone;
}

/** Represents the safe zone within the canvas */
export interface SafeZone {
    top: number;
    bottom: number;
    left: number;
    right: number;
}

/** Encapsulates the 9 positions an element can be in */
export type Position = 'top left' | 'top center' | 'top right' | 'center left' | 'center center' | 'center right' | 'bottom left' | 'bottom center' | 'bottom right';

export type FontSizeRatio =  'small'| 'medium' |'large'|'extra_large';

export interface AnimationConfig {
    /** Absolute timeline time when element should appear with an animation */
    fromTime: number,
    /** Absolute timeline time when element should disappear with an animation */
    toTime?: number,
    /* Animation delay in milliseconds */
    delay: number,
}

export interface ElementDesignSystemConfig {
    designSystem: DesignSystem,
    position: Position,
    animation: AnimationConfig | undefined,
    affectedBySafeZone: Boolean,
    inCloudRenderingEnvironment:Boolean,
}

export const getSafeZonedWidth = ( width:number, canvas:VideoCanvas):number =>{
    return width - (canvas.safeZone.left + canvas.safeZone.right);
}
export const getSafeZonedHeight = ( height:number, canvas:VideoCanvas):number =>{
    return height - (canvas.safeZone.top + canvas.safeZone.bottom);
}

export const getMaxCharacterPerLine = (maxLineWidth:number, fontSize:number):number =>{
    return Math.floor(maxLineWidth/fontSize) ;
}

export const getMaxFontSizeFromFontSizeRatio = (fontSizeRation: FontSizeRatio, width:number, height:number, canvas:VideoCanvas):number =>{
    const safeZonedWidth = getSafeZonedWidth(width,canvas);
    const safeZonedHeight = getSafeZonedHeight(height,canvas);
    if(safeZonedWidth >  safeZonedHeight){
        switch (fontSizeRation){
            case 'small':
                return Math.floor(safeZonedWidth/30);
            case 'medium':
                return Math.floor(safeZonedWidth/20);
            case 'large':
                return Math.floor(safeZonedWidth/15);
            case 'extra_large':
                return Math.floor(safeZonedWidth/10);
            default:
                return Math.floor(safeZonedWidth/20);
        }
    }else{
        if(safeZonedWidth <  safeZonedHeight){
        switch (fontSizeRation){
            case 'small':
                return Math.floor(safeZonedWidth/14);
            case 'medium':
                return Math.floor(safeZonedWidth/10);
            case 'large':
                return Math.floor(safeZonedWidth/7);
            case 'extra_large':
                return Math.floor(safeZonedWidth/5);
            default:
                return Math.floor(safeZonedWidth/10);
        }
    }else{
        switch (fontSizeRation){
            case 'small':
                return Math.floor(safeZonedWidth/24);
            case 'medium':
                return Math.floor(safeZonedWidth/16);
            case 'large':
                return Math.floor(safeZonedWidth/10);
            case 'extra_large':
                return Math.floor(safeZonedWidth/8);
            default:
                return Math.floor(safeZonedWidth/16);
        }
    }
    }
}

/** Base class for all element-specific design systems */
export abstract class ElementDesignSystem implements ElementDesignSystemConfig {
    public designSystem: DesignSystem;
    public position: Position;
    public animation: AnimationConfig | undefined;
    public affectedBySafeZone: Boolean;
    public inCloudRenderingEnvironment: Boolean;

    constructor(config: ElementDesignSystemConfig) {
        this.designSystem = config.designSystem;
        this.position = config.position;
        this.animation = config.animation;
        this.affectedBySafeZone = config.affectedBySafeZone;
        this.inCloudRenderingEnvironment = config.inCloudRenderingEnvironment;
    }

    /** This method must return the specific component, which will be wrapped in layout and animation containers */
    protected abstract renderChild(): ReactNode

    /** 
     * This helper function returns CSS used for layout purposes.
     * It may be overrided by subclasses to provide custom layout logic.
     */
    protected positionToCSS(): React.CSSProperties {
        const { width: canvasWidth, height: canvasHeight } = this.designSystem.canvas;

        const baseStyle: React.CSSProperties = {
            display: 'flex',
            justifyContent: 'center', // default horizontal alignment
            alignItems: 'center',    // default vertical alignment
            maxHeight: canvasHeight,
            maxWidth: canvasWidth,
            padding: 0,
        };

        switch (this.position) {
            case 'top left':
                return { ...baseStyle, justifyContent: 'flex-start', alignItems: 'flex-start' };
            case 'top center':
                return { ...baseStyle, justifyContent: 'flex-start', alignItems: 'center' };
            case 'top right':
                return { ...baseStyle, justifyContent: 'flex-start', alignItems: 'flex-end' };
            case 'center left':
                return { ...baseStyle, justifyContent: 'center', alignItems: 'flex-start' };
            case 'center center':
                return baseStyle; // already centered by default
            case 'center right':
                return { ...baseStyle, justifyContent: 'center', alignItems: 'flex-end' };
            case 'bottom left':
                return { ...baseStyle, justifyContent: 'flex-end', alignItems: 'flex-start' };
            case 'bottom center':
                return { ...baseStyle, justifyContent: 'flex-end', alignItems: 'center' };
            case 'bottom right':
                return { ...baseStyle, justifyContent: 'flex-end', alignItems: 'flex-end' };
            default:
                return {};
        }
    };
    

    /** Renders the component to JSX */
    public render(): ReactNode {

        const { safeZone } = this.designSystem.canvas;
        const { width, height } = this.designSystem.canvas;

        const safeZoneStyle: React.CSSProperties = {
            position: 'absolute',
            top: this.affectedBySafeZone ? safeZone.top : 0,
            left: this.affectedBySafeZone ? safeZone.left : 0,
            width: this.affectedBySafeZone ? width - safeZone.left - safeZone.right : width,
            height: this.affectedBySafeZone ? height - safeZone.top - safeZone.bottom : height,
        };

        if (this.animation) {
            return (
                <AbsoluteFill>
                    <Sequence from={Number(this.animation.fromTime)} durationInFrames={this.animation.toTime != undefined ? Number(this.animation.toTime) - Number(this.animation.fromTime) : undefined}>
                        <AnimatedWrapper show={true} delay={Number(this.animation.delay)}>
                            <AbsoluteFill style={safeZoneStyle}>
                                <AbsoluteFill style={{ ...this.positionToCSS(), position: 'relative', overflow: 'hidden' }}>
                                    <div style={{ display: 'block', position: 'relative' }}>
                                        {this.renderChild()}
                                    </div>
                                </AbsoluteFill>
                            </AbsoluteFill>
                        </AnimatedWrapper>
                    </Sequence>
                </AbsoluteFill>
            )
        }

        return (
            <AbsoluteFill>
                <AbsoluteFill style={safeZoneStyle}>
                    <AbsoluteFill style={this.positionToCSS()}>
                        {this.renderChild()}
                    </AbsoluteFill>
                </AbsoluteFill>
            </AbsoluteFill>
        )
    }
}