import { Switch, Select } from "antd";
import { Theme } from "../../VideoEditor";

const BORDER_STYLES = Object.freeze({
    NO_BORDER: {
        value: "no_border",
        label: "No border",
    },
    SHADOW: {
        value: "shadow",
        label: "Shadow",
    },
    BORDER: {
        value: "border",
        label: "Border",
    },
    BORDER_WITH_SHADOW: {
        value: "border_with_shadow",
        label: "Border with shadow",
    },
    BORDER_WITH_3D_LEFT: {
        value: "border_with_3d_left",
        label: "Border with 3d effect left",
    },
    BORDER_WITH_3D_RIGHT: {
        value: "border_with_3d_right",
        label: "Border with 3d effect right",
    },
});

const BORDER_CORNER_RADIUS = Object.freeze({
    SQUARE: {
        value: 0,
        label: "Square",
    }, SMOOTH: {
        value: 10,
        label: "Smooth",
    },
    ROUNDED: {
        value: 30,
        label: "Rounded",
    },
});

export const FrameComponent = ({ selectedTheme, setSelectedTheme }: { selectedTheme: Theme, setSelectedTheme: (theme: Theme) => void }) => {
    return (
        <div className="flex flex-col">
            <div className="flex flex-row justify-between items-center">
                <div>Spacing</div>
                <Switch
                    size="small"
                    value={selectedTheme.videoConfig.padding}
                    onChange={(value) => setSelectedTheme({ ...selectedTheme, videoConfig: { ...selectedTheme.videoConfig, padding: value } })}
                />
            </div>

            <div>Border radius:</div>

            <Select
                options={Object.values(BORDER_CORNER_RADIUS)}
                value={selectedTheme.videoConfig.borderRadius}
                onChange={(value) => setSelectedTheme({ ...selectedTheme, videoConfig: { ...selectedTheme.videoConfig, borderRadius: value } })}
            />
            <div>Border style:</div>

            <Select
                options={Object.values(BORDER_STYLES)}
                defaultValue={BORDER_STYLES.NO_BORDER.value}
            />
        </div>
    );
};