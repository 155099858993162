import { AbsoluteFill, spring, useCurrentFrame, useVideoConfig } from "remotion";
import React from "react";

interface AnimatedWrapperProps {
    children: React.ReactNode;
    show: boolean;
    delay?: number;
    duration?: number;
}

export const AnimatedWrapper: React.FC<AnimatedWrapperProps> = ({
    children,
    show,
    delay = 0,
    duration = 30,
}) => {
    const frame = useCurrentFrame();
    const { fps } = useVideoConfig();

    const opacity = spring({
        frame: frame - delay,
        fps,
        from: 0,
        to: show ? 1 : 0,
        durationInFrames: duration,
    });

    const translateY = spring({
        frame: frame - delay,
        fps,
        from: 20,
        to: show ? 0 : 20,
        durationInFrames: duration,
    });

    return (
        <AbsoluteFill
            style={{
                opacity,
                transform: `translateY(${translateY}px)`,
            }}
        >
            {children}
        </AbsoluteFill>
    );
};