import { useRef } from "react";
import { useVideoConfig, OffthreadVideo, Audio } from "remotion";
import { VideoSegmentMultiFocusDesignSystemConfig } from "../../VideoSegmentMultiFocus";
import { onVideoFrame } from "../DefaultVideoSegmentMultiFocus";

const GameplayFocus1x1: React.FC<VideoSegmentMultiFocusDesignSystemConfig> = ({ startTimestampMS, endTimestampMS, videoURL, spacing, borderRadius, border, framing, designSystem, audioURL }) => {
    const { width, height, fps } = useVideoConfig();

    //  We will display a 4:1 video on top of a 4:3 video
    const canvasRefs = [useRef<HTMLCanvasElement>(null), useRef<HTMLCanvasElement>(null)];

    const paddingSize = spacing ? Math.sqrt(width * height) * 0.05 : 0;
    const borderThickness = spacing ? Math.sqrt(width * height) / 150 : 0;

    const totalWidth = width - paddingSize * 2 - borderThickness * 2;
    const topHeight = totalWidth * (1 / 4);
    const bottomHeight = totalWidth * (3 / 4);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                alignContent: 'space-evenly',
                width: '100%',
                height: '100%',
            }}
        >
            <OffthreadVideo
                style={{ opacity: 0, display: 'none' }}
                src={videoURL}
                startFrom={startTimestampMS / 1000 * fps}
                endAt={endTimestampMS / 1000 * fps}
                onVideoFrame={(frame) => onVideoFrame(frame, framing["1:1"].focusRegions, canvasRefs)}
                muted={audioURL == undefined ? false : true}
            />
            {audioURL && <Audio src={audioURL} />}
            {framing["1:1"].focusRegions.map((_, index) => (
                <canvas
                    key={index}
                    ref={canvasRefs[index]}
                    width={totalWidth}
                    height={index === 0 ? topHeight : bottomHeight}
                    style={{
                        border: border ? `${borderThickness}px solid black` : `none`,
                        borderRadius: borderRadius,
                        aspectRatio: index === 0 ? '4 / 1' : '4 / 3',
                    }}
                />
            ))}
        </div>
    );
}

const GameplayFocus9x16: React.FC<VideoSegmentMultiFocusDesignSystemConfig> = ({ startTimestampMS, endTimestampMS, videoURL, spacing, borderRadius, border, framing, designSystem, audioURL }) => {
    const { width, height, fps } = useVideoConfig();

    //  We will display a 15:8 video on top of a 45:56 video
    const canvasRefs = [useRef<HTMLCanvasElement>(null), useRef<HTMLCanvasElement>(null)];

    const paddingSize = spacing ? Math.sqrt(width * height) * 0.05 : 0;
    const borderThickness = spacing ? Math.sqrt(width * height) / 150 : 0;

    const totalWidth = width - paddingSize * 2 - borderThickness * 2;
    const topHeight = totalWidth * (8 / 15);
    const bottomHeight = totalWidth * (56 / 45);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                alignContent: 'space-evenly',
                width: '100%',
                height: '100%',
            }}
        >
            <OffthreadVideo
                style={{ opacity: 0, display: 'none' }}
                src={videoURL}
                startFrom={startTimestampMS / 1000 * fps}
                endAt={endTimestampMS / 1000 * fps}
                onVideoFrame={(frame) => onVideoFrame(frame, framing["9:16"].focusRegions, canvasRefs)}
                muted={audioURL == undefined ? false : true}
            />
            {audioURL && <Audio src={audioURL} />}
            {framing["9:16"].focusRegions.map((_, index) => (
                <canvas
                    key={index}
                    ref={canvasRefs[index]}
                    width={totalWidth}
                    height={index === 0 ? topHeight : bottomHeight}
                    style={{
                        border: border ? `${borderThickness}px solid black` : `none`,
                        borderRadius: borderRadius,
                        aspectRatio: index === 0 ? '15 / 8' : '45 / 56',
                    }}
                />
            ))}
        </div>
    );
}

const GameplayFocus16x9: React.FC<VideoSegmentMultiFocusDesignSystemConfig> = ({ startTimestampMS, endTimestampMS, videoURL, spacing, borderRadius, border, framing, designSystem, audioURL }) => {
    const { width, height, fps } = useVideoConfig();

    //  We will display a 4:3 video on the left and a 4:9 video on the right
    const canvasRefs = [useRef<HTMLCanvasElement>(null), useRef<HTMLCanvasElement>(null)];

    const paddingSize = spacing ? Math.sqrt(width * height) * 0.05 : 0;
    const borderThickness = spacing ? Math.sqrt(width * height) / 150 : 0;

    const totalHeight = height - paddingSize * 2 - borderThickness * 2;
    const leftWidth = totalHeight * (4 / 3);
    const rightWidth = totalHeight * (4 / 9);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                alignContent: 'space-evenly',
                width: '100%',
                height: '100%',
            }}
        >
            <OffthreadVideo
                style={{ opacity: 0, display: 'none' }}
                src={videoURL}
                startFrom={startTimestampMS / 1000 * fps}
                endAt={endTimestampMS / 1000 * fps}
                onVideoFrame={(frame) => onVideoFrame(frame, framing["16:9"].focusRegions, canvasRefs)}
                muted={audioURL == undefined ? false : true}
            />
            {audioURL && <Audio src={audioURL} />}
            {framing["16:9"].focusRegions.map((_, index) => (
                <canvas
                    key={index}
                    ref={canvasRefs[index]}
                    width={index === 0 ? leftWidth : rightWidth}
                    height={totalHeight}
                    style={{
                        border: border ? `${borderThickness}px solid black` : `none`,
                        borderRadius: borderRadius,
                        aspectRatio: index === 0 ? '4 / 3' : '4 / 9',
                    }}
                />
            ))}
        </div>
    );
}



export const GameplayFocusVideoSegment: React.FC<VideoSegmentMultiFocusDesignSystemConfig> = (props) => {
    switch (props.designSystem.canvas.aspectRatio) {
        case "16:9":
            return <GameplayFocus16x9 {...props} />
        case "9:16":
            return <GameplayFocus9x16 {...props} />
        case "1:1":
            return <GameplayFocus1x1 {...props} />
        default:
            throw new Error(`Unsupported aspect ratio: ${props.designSystem.canvas.aspectRatio}`);
    }
}