import dataScheme from "../../video_data_to_render.json"
import { DesignSystem, ElementDesignSystem, ElementDesignSystemConfig, FontSizeRatio } from "../DesignSystem";
import { ClassicCaptionSeries } from "./ClassicCaptions/ClassicCaptionSeries";
import { ModernCaptionSeries } from "./ModernCaptions/ModernCaptionSeries";

export interface CaptionDesignSystemConfig extends Pick<ElementDesignSystemConfig, 'position'> {
    milisecondGrouping: number,
    font: 'tinos' | 'roboto' | 'lato',
    useDesignSystemColor: boolean,
    mainColor: string,
    highlightColor: string,
    outline: 'none' | 'glow' | 'shadow' | '3d-shadow' | 'stroke'| '3d-stroke',
    fontSizeRatio: FontSizeRatio;
    background: 'none' | 'white'|'colored' | 'translucent',
    textAnimation: 'none' | 'highlight' | 'boxed' | 'scale'| 'pop'| 'karaoke' | 'reveal'| 'reveal_up'| 'reveal_scale'|'reveal_box'| 'karaoke_scale'|'karaoke_box'|'pop',
    borderRadius: number,
    captionCase: 'standard' | 'uppercase',
    hidePunctuation: boolean,
    showOneWordOnly: boolean,
    maxLines: "one_word" |"one_line" |"two_lines"| "three_lines",
}

export class CaptionDesignSystem extends ElementDesignSystem implements CaptionDesignSystemConfig {
    public font: 'tinos' | 'roboto' | 'lato';
    public useDesignSystemColor: boolean;
    public mainColor: string;
    public highlightColor: string;
    public outline: 'none' |  'glow' |'shadow' | '3d-shadow' | 'stroke'| '3d-stroke';
    public background: 'none' |'white'| 'colored' | 'translucent';
    public textAnimation: 'none' | 'highlight' | 'boxed' | 'scale'|'pop'| 'karaoke' | 'reveal'| 'reveal_up'| 'reveal_scale' |'reveal_box'|'karaoke_scale'|'karaoke_box'|'pop';
    public fontSizeRatio: FontSizeRatio;
    public borderRadius: number;
    public captionCase: 'standard' | 'uppercase';
    public hidePunctuation: boolean;
    public videoRenderData: typeof dataScheme;
    public startTimeOffset: number;
    public firstScheduledNode : any;
    public milisecondGrouping: number;
    public showOneWordOnly: boolean;
    public maxLines:"one_word" |"one_line" |"two_lines"| "three_lines";

    constructor(
        public config: CaptionDesignSystemConfig,
        designSystem: DesignSystem,
        videoRenderData: typeof dataScheme,
        startTimeOffset: number,
        firstScheduledNode : any
    ) {
        super({ designSystem: designSystem, position: config.position, animation: undefined, affectedBySafeZone: true });
        this.videoRenderData = videoRenderData;
        this.startTimeOffset = startTimeOffset;
        this.firstScheduledNode = firstScheduledNode;
        this.milisecondGrouping = config.milisecondGrouping;
        this.font = config.font;
        this.outline = config.outline;
        this.background = config.background;
        this.textAnimation = config.textAnimation;
        this.fontSizeRatio = config.fontSizeRatio;
        this.captionCase = config.captionCase;
        this.hidePunctuation = config.hidePunctuation;
        this.useDesignSystemColor = config.useDesignSystemColor;
        this.mainColor = config.mainColor;
        this.highlightColor = config.highlightColor;
        this.borderRadius = config.borderRadius;
        this.maxLines = config.maxLines;
        this.showOneWordOnly = this.maxLines === 'one_word';
    }

    protected getMaxLines(){
        switch(this.maxLines){
            case 'one_word':
                return 1;
            case 'one_line':
                return 1;
            case 'two_lines':
                return 2;
            case 'three_lines':
                return 3;
            default:
                return 1;
        }
    }

    protected renderChild() {
        switch (this.designSystem.style) {
            case "modern":
                return (
                    <ModernCaptionSeries {...this} />
                );
            case "classic":
                return (
                    <ClassicCaptionSeries {...this} maxLinesNumber={this.getMaxLines()} />
                );
            default:
                console.error("Invalid Style");
                return (
                    <div>
                        <h1>Invalid Style</h1>
                    </div>
                );
        }
    }
}