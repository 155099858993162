import { FocusRegion, VideoSegmentMultiFocusDesignSystemConfig } from "../VideoSegmentMultiFocus";
import { DefaultFocusVideoSegment } from "./FocusTypes/DefaultFocusVideoSegment";
import { TwoSplitFocusVideoSegment } from "./FocusTypes/TwoSplitFocusVideoSegment";
import { ThreeSplitFocusVideoSegment } from "./FocusTypes/ThreeSplitFocusVideoSegment";
import { ScreenshareFocusVideoSegment } from "./FocusTypes/ScreenshareFocusVideoSegment";
import { FourSplitFocusVideoSegment } from "./FocusTypes/FourSplitFocusVideoSegment";
import { GameplayFocusVideoSegment } from "./FocusTypes/GameplayFocusVideoSegment";
import { SquareFocusVideoSegment } from "./FocusTypes/SquareFocusVideoSegment";
import { LandscapeFocusVideoSegment } from "./FocusTypes/LandscapeFocusVideoSegment";
import { PortraitFocusVideoSegment } from "./FocusTypes/PortraitFocusVideoSegment";

export const onVideoFrame = (frame: CanvasImageSource, focusRegions: FocusRegion<any>[], canvasRefs: React.RefObject<HTMLCanvasElement>[]) => {
    focusRegions.forEach((region, index) => {
        const canvas = canvasRefs[index].current;
        if (!canvas) return;
        const context = canvas.getContext('2d');
        if (!context) return;

        // Force frame to be HTMLCanvasElement type
        frame = frame as HTMLVideoElement;

        // Convert region percentage to pixel values based on video frame size
        const xPixelValue = Math.floor(region.x * frame.videoWidth);
        const yPixelValue = Math.floor(region.y * frame.videoHeight);
        const widthPixelValue = Math.floor(region.width * frame.videoWidth);
        const heightPixelValue = Math.floor(region.height * frame.videoHeight);

        // Draw the cropped region onto the canvas
        context.clearRect(0, 0, canvas.width, canvas.height);

        context.drawImage(
            frame,
            xPixelValue,
            yPixelValue,
            widthPixelValue,
            heightPixelValue,
            0,
            0,
            canvas.width,
            canvas.height
        );
    });
};

export const DefaultVideoSegmentMultiFocus: React.FC<VideoSegmentMultiFocusDesignSystemConfig> = ({ startTimestampMS, endTimestampMS, videoURL, spacing: padding, borderRadius, border, framing, designSystem, audioURL }) => {
    if (framing.name == 'default') {
        return (
            <DefaultFocusVideoSegment
                startTimestampMS={startTimestampMS}
                endTimestampMS={endTimestampMS}
                videoURL={videoURL}
                spacing={padding}
                borderRadius={borderRadius}
                border={border}
                framing={framing}
                designSystem={designSystem}
                audioURL={audioURL}
            />
        );
    }

    if (framing.name == '2-split') {
        return (
            <TwoSplitFocusVideoSegment
                startTimestampMS={startTimestampMS}
                endTimestampMS={endTimestampMS}
                videoURL={videoURL}
                spacing={padding}
                borderRadius={borderRadius}
                border={border}
                framing={framing}
                designSystem={designSystem}
                audioURL={audioURL}
            />
        );
    }

    if (framing.name == '3-split') {
        return (
            <ThreeSplitFocusVideoSegment
                startTimestampMS={startTimestampMS}
                endTimestampMS={endTimestampMS}
                videoURL={videoURL}
                spacing={padding}
                borderRadius={borderRadius}
                border={border}
                framing={framing}
                designSystem={designSystem}
                audioURL={audioURL}
            />
        );
    }

    if (framing.name == '4-split') {
        return (
            <FourSplitFocusVideoSegment
                startTimestampMS={startTimestampMS}
                endTimestampMS={endTimestampMS}
                videoURL={videoURL}
                spacing={padding}
                borderRadius={borderRadius}
                border={border}
                framing={framing}
                designSystem={designSystem}
                audioURL={audioURL}
            />
        );
    }

    if (framing.name == 'screenshare') {
        return (
            <ScreenshareFocusVideoSegment
                startTimestampMS={startTimestampMS}
                endTimestampMS={endTimestampMS}
                videoURL={videoURL}
                spacing={padding}
                borderRadius={borderRadius}
                border={border}
                framing={framing}
                designSystem={designSystem}
                audioURL={audioURL}
            />
        );
    }

    if (framing.name == 'gameplay') {
        return (
            <GameplayFocusVideoSegment
                startTimestampMS={startTimestampMS}
                endTimestampMS={endTimestampMS}
                videoURL={videoURL}
                spacing={padding}
                borderRadius={borderRadius}
                border={border}
                framing={framing}
                designSystem={designSystem}
                audioURL={audioURL}
            />
        );
    }

    if (framing.name == 'square') {
        return (
            <SquareFocusVideoSegment
                startTimestampMS={startTimestampMS}
                endTimestampMS={endTimestampMS}
                videoURL={videoURL}
                spacing={padding}
                borderRadius={borderRadius}
                border={border}
                framing={framing}
                designSystem={designSystem}
                audioURL={audioURL}
            />
        )
    }

    if (framing.name == 'landscape') {
        return (
            <LandscapeFocusVideoSegment
                startTimestampMS={startTimestampMS}
                endTimestampMS={endTimestampMS}
                videoURL={videoURL}
                spacing={padding}
                borderRadius={borderRadius}
                border={border}
                framing={framing}
                designSystem={designSystem}
                audioURL={audioURL}
            />
        )
    }

    if (framing.name == 'portrait') {
        return (
            <PortraitFocusVideoSegment
                startTimestampMS={startTimestampMS}
                endTimestampMS={endTimestampMS}
                videoURL={videoURL}
                spacing={padding}
                borderRadius={borderRadius}
                border={border}
                framing={framing}
                designSystem={designSystem}
                audioURL={audioURL}
            />
        )
    }
};  