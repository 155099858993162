import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import SubscriptionManager from "models/helper/SubscriptionManager";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import StripeHelper from "models/helper/StripeHelper";
import ReplacePaymentForCreatorSubscriptionWidgetController from "controllers/upgradeCreatorSubscription/ReplacePaymentForCreatorSubscriptionWidgetController";
import RSVP from "rsvp";
import ti18n from "i18n!nls/UpgradeCreatorSubscriptionWidgeti18n";
import UpgradeCreatorSubscriptionWidgetView from "views/upgradeCreatorSubscription/UpgradeCreatorSubscriptionWidgetView";


const UpgradeCreatorSubscriptionWidgetController =  Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this._userCurrentPaidSubscriptionType =  null;
        this._planTypeSelectedToChangeTo = null;
        this._returnUrlOnPaymentUpgradeCompleted = options && options.returnUrlOnPaymentUpgradeCompleted?options.returnUrlOnPaymentUpgradeCompleted: null;
        this._returnUrlOnPaymentMethodUpdated = options && options.returnUrlOnPaymentMethodUpdated?options.returnUrlOnPaymentMethodUpdated: null;
        this.listenTo(SubscriptionManager.getInstance(),SubscriptionManager.getInstance().eventTypes.ON_CREATOR_SUBSCRIPTION_UPDATED, this.onUserCreatorSubscriptionChanged.bind(this));
    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },

    onUserCreatorSubscriptionChanged : function(subscriptionInfo){
        this.updateSubscriptionSelected(subscriptionInfo);
    },


    fetchSubscriptionInfoAndUpdate : function(){
        if(LocalStorageHelper.getInstance().isCurrentLoggedUserNotAVisitor()){
            this.view.setContentLoading(true);
            return SubscriptionManager.getInstance().getTrebbeCreatorSubscriptionUserIsSubscribedTo(true).then((function(subscriptionInfo){
                this.view.setContentLoading(false);
                this.updateSubscriptionSelected(subscriptionInfo);
                this.view.showPlanListingSection();
            }).bind(this));
        }else{
            this.updateSubscriptionSelected();
        }
    },

    updateSubscriptionSelected : function(subscriptionInfo){
        this._lastRetrievedSubscriptionInfo = subscriptionInfo;
        if(subscriptionInfo){
            this._userCurrentPaidSubscriptionType = StripeHelper.getInstance().getSubscriptionPlanType(subscriptionInfo);
            if(StripeHelper.getInstance().isSubscriptionActiveForCreatorPro(subscriptionInfo)){
                if(StripeHelper.getInstance().isSubscriptionActiveForCreatorProMonthly(subscriptionInfo)){
                    this.view.setUserSubscribedToProSubscritionMonthly();
                    this.view.setSwitchYearlBillingValue(false);
                }else{
                    this.view.setSwitchYearlBillingValue(true);
                    this.view.setUserSubscribedToProSubscritionYearly();
                }
            }
            if(StripeHelper.getInstance().isSubscriptionActiveForCreatorProPlus(subscriptionInfo)){
                if(StripeHelper.getInstance().isSubscriptionActiveForCreatorProPlusMonthly(subscriptionInfo)){
                    this.view.setUserSubscribedToProPlusSubscritionMonthly();
                    this.view.setSwitchYearlBillingValue(false);
                }else{
                    this.view.setSwitchYearlBillingValue(true);
                    this.view.setUserSubscribedToProPlusSubscritionYearly();
                }
            }
        }else{
            this._userCurrentPaidSubscriptionType = null;
            this.view.setUserSubscribedHasFreeSubscription();
        }
        this.updateNextBillingDateOrCancelDates();
    },

    updateNextBillingDateOrCancelDates : function(){
        const subscriptionInfo = this._lastRetrievedSubscriptionInfo;
        const yearlyBillingDisplayed = this.view.getYearlyBillingSwitchValue();
        if(StripeHelper.getInstance().isSubscriptionActiveForCreatorPro(subscriptionInfo)){
            if(StripeHelper.getInstance().isSubscriptionActiveForCreatorProMonthly(subscriptionInfo)){
                if(yearlyBillingDisplayed){
                    return this.view.clearNextBillingDateOrCancelDateOnProPlan();
                }else{
                    return this.view.setNextBillingDateOrCancelDateOnProPlan(StripeHelper.getInstance().getSubscriptionNextBillingdDate(subscriptionInfo), StripeHelper.getInstance().getSubscriptionCancelEndDate(subscriptionInfo));
                }
            }else{
                if(yearlyBillingDisplayed){
                    
                    return this.view.setNextBillingDateOrCancelDateOnProPlan(StripeHelper.getInstance().getSubscriptionNextBillingdDate(subscriptionInfo), StripeHelper.getInstance().getSubscriptionCancelEndDate(subscriptionInfo));
                }else{
                    return this.view.clearNextBillingDateOrCancelDateOnProPlan();
                }
                
            }

        }
        if(StripeHelper.getInstance().isSubscriptionActiveForCreatorProPlus(subscriptionInfo)){
            if(StripeHelper.getInstance().isSubscriptionActiveForCreatorProPlusMonthly(subscriptionInfo)){
                if(yearlyBillingDisplayed){
                    return this.view.clearNextBillingDateOrCancelDateOnProPlusPlan();
                }else{
                    return this.view.setNextBillingDateOrCancelDateOnProPlusPlan(StripeHelper.getInstance().getSubscriptionNextBillingdDate(subscriptionInfo), StripeHelper.getInstance().getSubscriptionCancelEndDate(subscriptionInfo));
                }
            }else{
                if(yearlyBillingDisplayed){
                    
                    return this.view.setNextBillingDateOrCancelDateOnProPlusPlan(StripeHelper.getInstance().getSubscriptionNextBillingdDate(subscriptionInfo), StripeHelper.getInstance().getSubscriptionCancelEndDate(subscriptionInfo));
                }else{
                    return this.view.clearNextBillingDateOrCancelDateOnProPlusPlan();
                }
            }

        }
    },
    
    onYearlyBillingSwitchChanged : function(){
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("upgradeCreatorSubscription", "SwitchToSeeYearlyOrMonthlyPlan", "Switch To See Yearly Or Monthly Plan");
        }
        this.updateNextBillingDateOrCancelDates();
    },

    initiatePaymentOrChangeToForProPlan: function(){
        this._planTypeSelectedToChangeTo = this.view.getYearlyBillingSwitchValue()? StripeHelper.getInstance().getAvailableCreatorPlanTypes().PRO_PLAN_MONTHLY: StripeHelper.getInstance().getAvailableCreatorPlanTypes().PRO_PLAN_YEARLY;
        if(this._userCurrentPaidSubscriptionType){
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("upgradeCreatorSubscription", "showUpgradeInvoiceToDifferentProPaidPlan", "show Upgrad Invoice To Different Pro Paid Plan");
            }
            this._showUpgradeInvoiceToDifferentPaidPlan(StripeHelper.getInstance().retriveUpcomingInvoiceForCreatorSubscriptionUpgradeToProPlan(this.view.getYearlyBillingSwitchValue(), this.getCouponId()));
        }else{
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("upgradeCreatorSubscription", "initiatePaymentForProPaidPlan", "initiate Payment For Pro Paid Plan");
            }
            this._initiatePaymentForPaidPlan(StripeHelper.getInstance().showPaymentFormForCreatorProSubscription(this.view.getPaymentElement$el().get(0), this.view.getYearlyBillingSwitchValue(), this.getCouponId()));
        }
    },

    _initiatePaymentForPaidPlan:  async function(stripeShowPaymentformPromise){
        this.view.setContentLoading(true);
        return stripeShowPaymentformPromise.then((function(results){
            this._stripeElementInstance =  results.stripeElements;
            this._stripePaymentElementInstance =  results.paymentElement;
            this._stripeInvoice =  results.invoice;
            this._stripePaymentElementInstance.on("ready", (function(){
                this.view.updateInvoiceDetails(this._stripeInvoice);
                this.view.showPaymentSection(this._planTypeSelectedToChangeTo);
                this.view.setPaymentUsingExistingPaymentMehtod(false);
                this.view.setContentLoading(false);
            }).bind(this))
            
        }).bind(this)).catch((function(error){
            window.alertErrorMessage(error);
            this.view.showPlanListingSection();
            this.view.setContentLoading(false);
        }).bind(this));

    },


    _showUpgradeInvoiceToDifferentPaidPlan : async function(retriveUpcomingInvoicePromise){
        this.view.setContentLoading(true);
        return retriveUpcomingInvoicePromise.then((function(invoice){
            this._stripeInvoice = invoice;
            this.view.updateInvoiceDetails(this._stripeInvoice);
            this.view.showPaymentSection(this._planTypeSelectedToChangeTo);
            this.view.setPaymentUsingExistingPaymentMehtod(true);
            this.view.setDefaultPaymentMethodInfo(this._lastRetrievedSubscriptionInfo.displayablePaymentMethod);
            this.view.setContentLoading(false);
        }).bind(this)).catch((function(error){
            window.alertErrorMessage(error);
            this.view.showPlanListingSection();
            this.view.setContentLoading(false);
        }).bind(this));
    },

    _completeChangeToDifferentPaidPlan : function(promiseChangeSubsciption){
        this.view.setContentLoading(true);
        return promiseChangeSubsciption.then((function(newSubscriptionInfo){
            this.view.setContentLoading(false);
            this.trigger("upgradeCompleted");
        }).bind(this)).catch((function(error){
            window.alertErrorMessage(error);
            this.view.setContentLoading(false);
        }).bind(this));
    },


    initiatePaymentOrChangeToForProPlusPlan: function(){
        this._planTypeSelectedToChangeTo = this.view.getYearlyBillingSwitchValue()? StripeHelper.getInstance().getAvailableCreatorPlanTypes().PRO_PLUS_PLAN_MONTHLY: StripeHelper.getInstance().getAvailableCreatorPlanTypes().PRO_PLUS_PLAN_YEARLY;
        if(this._userCurrentPaidSubscriptionType){
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("upgradeCreatorSubscription", "showUpgradeInvoiceToDifferentProPlusPaidPlan", "show Upgrad Invoice To Different Pro Plus Paid Plan");
            }
            this._showUpgradeInvoiceToDifferentPaidPlan(StripeHelper.getInstance().retriveUpcomingInvoiceForCreatorSubscriptionUpgradeToProPlusPlan(this.view.getYearlyBillingSwitchValue(),  this.getCouponId()));
        }else{
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("upgradeCreatorSubscription", "initiatePaymentForProPlusPaidPlan", "initiate Payment For Pro Plus Paid Plan");
            }
            this._initiatePaymentForPaidPlan(StripeHelper.getInstance().showPaymentFormForCreatorProPlusSubscription(this.view.getPaymentElement$el().get(0), this.view.getYearlyBillingSwitchValue(), this.getCouponId()));
        }
    },

    completePayment : function(){
        $.mobile.loading("show");
        if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("upgradeCreatorSubscription", "completeCreatorSubscriptionPayment", "complete Creator Subscription Payment");
            }
        if(!this.view.getCaptchaReponse()){
            $.mobile.loading("hide");
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("upgradeCreatorSubscription", "captchaFailedOnPaymentForm", "captcha failed on payment form");
            }
            return this.view.displayPaymentError(window.getI18n(ti18n, "PLEASE_VERIFY_THAT_YOU_ARE_NOT_A_ROBOT"));
        }
        return StripeHelper.getInstance().completePayment(this._stripeElementInstance, this._returnUrlOnPaymentUpgradeCompleted? this._returnUrlOnPaymentUpgradeCompleted: PersistentModels.getInstance().getRouter().getOnSubscriptionPaymentCompletedURL()).then((function(){
            $.mobile.loading("hide");
            this.view.displayNoPaymentError();
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            this.view.displayPaymentError(error);
        }).bind(this))
    },

    confirmChangePlan : function(){
        if(this._planTypeSelectedToChangeTo){
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("upgradeCreatorSubscription", "completeChangeToDifferentPaidPlan", "complete Change To Different Paid Plan");
            }
            if(this._planTypeSelectedToChangeTo === StripeHelper.getInstance().getAvailableCreatorPlanTypes().PRO_PLAN_MONTHLY ||this._planTypeSelectedToChangeTo === StripeHelper.getInstance().getAvailableCreatorPlanTypes().PRO_PLAN_YEARLY){
                return this._completeChangeToDifferentPaidPlan(this.view.getYearlyBillingSwitchValue()?SubscriptionManager.getInstance().changeSubscriptionToCreatorProYearly(this.getCouponId()): SubscriptionManager.getInstance().changeSubscriptionToCreatorPro(this.getCouponId()))
            }
            if(this._planTypeSelectedToChangeTo === StripeHelper.getInstance().getAvailableCreatorPlanTypes().PRO_PLUS_PLAN_MONTHLY ||this._planTypeSelectedToChangeTo === StripeHelper.getInstance().getAvailableCreatorPlanTypes().PRO_PLUS_PLAN_YEARLY){
                return this._completeChangeToDifferentPaidPlan(this.view.getYearlyBillingSwitchValue()?SubscriptionManager.getInstance().changeSubscriptionToCreatorProPlusYearly(this.getCouponId()): SubscriptionManager.getInstance().changeSubscriptionToCreatorProPlus(this.getCouponId()))
            }
        }
    },

    onPaymentUpdateCompleted: function(){
        this.view.showPaymentChangeSection(false);
        if(this._planTypeSelectedToChangeTo === StripeHelper.getInstance().getAvailableCreatorPlanTypes().PRO_PLAN_MONTHLY ||this._planTypeSelectedToChangeTo === StripeHelper.getInstance().getAvailableCreatorPlanTypes().PRO_PLAN_YEARLY){
            return this._showUpgradeInvoiceToDifferentPaidPlan(StripeHelper.getInstance().retriveUpcomingInvoiceForCreatorSubscriptionUpgradeToProPlan(this.view.getYearlyBillingSwitchValue(), this.getCouponId()));
        }
        if(this._planTypeSelectedToChangeTo === StripeHelper.getInstance().getAvailableCreatorPlanTypes().PRO_PLUS_PLAN_MONTHLY ||this._planTypeSelectedToChangeTo === StripeHelper.getInstance().getAvailableCreatorPlanTypes().PRO_PLUS_PLAN_YEARLY){
            return this._showUpgradeInvoiceToDifferentPaidPlan(StripeHelper.getInstance().retriveUpcomingInvoiceForCreatorSubscriptionUpgradeToProPlusPlan(this.view.getYearlyBillingSwitchValue(), this.getCouponId()));
        }
    },

    onPaymentUpdateCancelled: function(){
        this.view.showPaymentChangeSection(false);
    },

    _clearExistingReplacePaymentController : function(){
        if(this.replacePaymentForCreatorSubscriptionWidgetController){
            this.stopListening(this.replacePaymentForCreatorSubscriptionWidgetController);
            this.replacePaymentForCreatorSubscriptionWidgetController = null;
        }
    },

    _onChangePaymentFormReady :function(){
        if(this._planTypeSelectedToChangeTo){

        }
    },

    _initChangePaymentForm : function(){
        this._clearExistingReplacePaymentController();
        const params = {};
        params.returnUrlAfterSetupCompleted =  this._returnUrlOnPaymentMethodUpdated? this._returnUrlOnPaymentMethodUpdated: PersistentModels.getInstance().getRouter().getCompletePaymentSetupAndResumeCreatorPlanUpgradeURL();
        this.replacePaymentForCreatorSubscriptionWidgetController = new ReplacePaymentForCreatorSubscriptionWidgetController(params);
        this.listenTo(this.replacePaymentForCreatorSubscriptionWidgetController, "updateCompleted", this.onPaymentUpdateCompleted.bind(this));
        this.listenTo(this.replacePaymentForCreatorSubscriptionWidgetController, "cancelChange", this.onPaymentUpdateCancelled.bind(this));
        this.listenTo(this.replacePaymentForCreatorSubscriptionWidgetController, "formReady", this._onChangePaymentFormReady.bind(this));
        
        return this.replacePaymentForCreatorSubscriptionWidgetController.buildModelAndRenderView().then((function(){
            this.view.setReplacePaymentMethodWidgetView(this.replacePaymentForCreatorSubscriptionWidgetController.getView());
        }).bind(this));
    },

    showChangePaymentMethod : function(){
        $.mobile.loading("show");
        return this._initChangePaymentForm().then((function(){
            $.mobile.loading("hide");
            this.view.showPaymentChangeSection(true);
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
            this.view.showPaymentChangeSection(false);
        }).bind(this));
    },

    getCouponId : function(){
        return window.trebble.couponIdInUrl;
    }, 


    buildModelAndRenderView : async function() {
        const couponInfo = this.getCouponId()? await TrebbleClientAPIHelper.getInstance().getCouponInfo(this.getCouponId()): null;
        return new RSVP.Promise((function(resolve, reject) {
            try {

                const viewParams = {couponInfo};
                const upgradeCreatorSubscriptionWidgetView = new  UpgradeCreatorSubscriptionWidgetView(viewParams);
                upgradeCreatorSubscriptionWidgetView.render();
                this.view = upgradeCreatorSubscriptionWidgetView;
                this.view.setSwitchYearlBillingValue(true);
                this.view.showPlanListingSection();
                this.fetchSubscriptionInfoAndUpdate();
                this.listenTo(this.view, "onYearlyBillingChange", this.onYearlyBillingSwitchChanged.bind(this));
                this.listenTo(this.view, "initiatePaymentOrChangeToForProPlan", this.initiatePaymentOrChangeToForProPlan.bind(this));
                this.listenTo(this.view, "initiatePaymentOrChangeToForProPlusPlan", this.initiatePaymentOrChangeToForProPlusPlan.bind(this));
                this.listenTo(this.view, "completePayment", this.completePayment.bind(this));
                this.listenTo(this.view, "confirmChangePlan", this.confirmChangePlan.bind(this));
                this.listenTo(this.view, "showChangePaymentMethod", this.showChangePaymentMethod.bind(this));
                
                resolve(this);
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }
    

});
export default UpgradeCreatorSubscriptionWidgetController;