import { Card, Select, Radio, ColorPicker, Switch } from "antd";
import { Theme } from "../../VideoEditor";
import { Position } from "../../../remotion/Components/DesignSystem";
import { CaptionDesignSystemConfig } from "../../../remotion/Components/Captions/Caption";
import { getEnumFromValue } from "../ThemeEditor";

const CAPTION_POSITIONS = Object.freeze({
    TOP: {
        value: "top",
        label: "Top",
    },
    MIDDLE: {
        value: "center",
        label: "Middle",
    },
    BOTTOM: {
        value: "bottom",
        label: "Bottom",
    },
});

const FONT_NAMES = Object.freeze({
    TINOS: {
        value: "tinos",
        label: "Tinos",
    },
    ROBOTO: {
        value: "roboto",
        label: "Roboto",
    },
    LATO: {
        value: "lato",
        label: "Lato",
    },
});


const FONT_OUTLINE = Object.freeze({
    NONE: {
        value: "none",
        label: "None",
    },
    SHADOW: {
        value: "shadow",
        label: "Shadow",
    },
    GLOW: {
        value: "glow",
        label: "Glow",
    },
    STROKE: {
        value: "stroke",
        label: "Stroke",
    },
    "3D_SHADOW": {
        value: "3d-shadow",
        label: "3D Shadow",
    },
    "3D_STROKE":{
        value:'3d-stroke',
        label:"3D Stroke"
    }
});

const FONT_BACKGROUND_STYLE = Object.freeze({
    NONE: {
        value: "none",
        label: "None",
    },

    TRANSLUCENT: {
        value: "translucent",
        label: "Translucent",
    },
    WHITE_BACKGROUND: {
        value: "white",
        label: "White",
    },
    COLORED_BACKGROUND: {
        value: "colored",
        label: "Colored",
    },
});

const CAPTION_LINE_CONFIG = Object.freeze({
    ONE_WORD: {
        value: "one_word",
        label: "One word",
    },
    ONE_LINE: {
        value: "one_line",
        label: "One line",
    },
    TWO_LINE: {
        value: "two_lines",
        label: "Two lines",
    },
    THREE_LINE: {
        value: "three_lines",
        label: "Three lines",
    },
});


const CAPTION_ANIMATIONS = Object.freeze({
    NO_ANIMATION: {
        value: "none",
        label: "No animation",
    },
    HIGHLIGHT: {
        value: "highlight",
        label: "Highlight",
    },
    BOXED: {
        value: "boxed",
        label: "Boxed",
    },
    POP: {
        value: "pop",
        label: "Pop",
    },
    SCALE: {
        value: "scale",
        label: "Scale",
    },
    REVEAL: {
        value: "reveal",
        label: "Reveal",
    },
    REVEAL_UP: {
        value: "reveal_up",
        label: "Reveal up",
    },
    KARAOKE: {
        value: "karaoke",
        label: "Karaoke",
    },
    REVEAL_SCALE: {
        value: "reveal_scale",
        label: "Reveal scale",
    },
    REVEAL_BOX: {
        value: "reveal_box",
        label: "Reveal box",
    },
    KARAOKE_SCALE: {
        value: "karaoke_scale",
        label: "Karaoke scale",
    },
    KARAOKE_BOX: {
        value: "karaoke_box",
        label: "Karaoke box",
    },
});

const FONT_SIZES = Object.freeze({
    SMALL: {
        value: "small",
        label: "Small",
    },
    MEDIUM: {
        value: "medium",
        label: "Medium",
    },

    LARGE: {
        value: "large",
        label: "Large",
    },
    EXTRA_LARGE: {
        value: "extra_large",
        label: "Extra large",
    },
});

const CAPTION_ALIGNMENTS = Object.freeze({
    LEFT: { value: "left", label: "Left" },
    CENTER: { value: "center", label: "Center" },
    RIGHT: { value: "right", label: "Right" },
});

const BORDER_CORNER_RADIUS = Object.freeze({
    SQUARE: {
        value: 0,
        label: "Square",
    }, SMOOTH: {
        value: 0.10,
        label: "Smooth",
    },
    ROUNDED: {
        value: 0.30,
        label: "Rounded",
    },
});

const CAPTION_CASES = Object.freeze({
    STANDARD: {
        value: "standard",
        label: "Standard",
    },
    UPPERCASE: {
        value: "uppercase",
        label: "Uppercase",
    },
});

const DEFAULT_CAPTION_STYLES = Object.freeze({
    CLASSIC: {
        value: "classic",
        label: "Classic",
        styling: {
            milisecondGrouping: 1000,
            font: FONT_NAMES.TINOS.value,
            useDesignSystemColor: false,
            mainColor: "#780C28",
            highlightColor: "#CAE0BC",
            fontSizeRatio: FONT_SIZES.MEDIUM.value,
            outline: FONT_OUTLINE["3D_STROKE"].value,
            background: FONT_BACKGROUND_STYLE.WHITE_BACKGROUND.value,
            textAnimation: CAPTION_ANIMATIONS.HIGHLIGHT.value,
            borderRadius: BORDER_CORNER_RADIUS.SQUARE.value,
            captionCase: CAPTION_CASES.STANDARD.value,
            hidePunctuation: false,
            position: "bottom center",
        } as CaptionDesignSystemConfig,
    },
    MODERN: {
        value: "modern",
        label: "Modern",
        styling: {
            milisecondGrouping: 1000,
            font: FONT_NAMES.ROBOTO.value,
            useDesignSystemColor: false,
            mainColor: "#2F2F2F",
            highlightColor: "#FFD700",
            outline: FONT_OUTLINE.NONE.value,
            fontSizeRatio: FONT_SIZES.MEDIUM.value,
            background: FONT_BACKGROUND_STYLE.COLORED_BACKGROUND.value,
            textAnimation: CAPTION_ANIMATIONS.BOXED.value,
            borderRadius: BORDER_CORNER_RADIUS.SQUARE.value,
            captionCase: CAPTION_CASES.UPPERCASE.value,
            hidePunctuation: false,
            position: "bottom center",
        } as CaptionDesignSystemConfig,
    },
    CUSTOM: {
        value: "custom",
        label: "Custom",
        styling: undefined as unknown as CaptionDesignSystemConfig,
    }
});

const CaptionRendered = () => {
    return (
        <div
            className="h-36 w-full bg-slate-300 my-1 flex flex-col items-center justify-center "
            style={{ height: "100px", backgroundColor: "rgba(0,0,0,0.1)" }}
        >
            Lorem ipsum dolor sit amet
        </div>
    );
};

export const CaptionsComponent = ({ selectedTheme, setSelectedTheme }: { selectedTheme: Theme, setSelectedTheme: (theme: Theme) => void }) => {
    const getCaptionPresetLabelFromValues = (value: CaptionDesignSystemConfig) => {  
        const captionPreset = Object.values(DEFAULT_CAPTION_STYLES).find(
          (captionPreset) =>value == captionPreset.styling
        );
        return captionPreset?.value || "custom";
      }
    
    const onCaptionPresetStyleChange = (newStyle: any) => {
        if (newStyle === "custom") return;

        const selectedPresetStyle = getEnumFromValue(
            newStyle,
            DEFAULT_CAPTION_STYLES
        ) as typeof DEFAULT_CAPTION_STYLES[keyof typeof DEFAULT_CAPTION_STYLES];

        setSelectedTheme({ ...selectedTheme, captionConfig: selectedPresetStyle.styling });
    }


    return (
        <div className="flex flex-col">
            <Card size="small" className="my-2">
                <div>Style:</div>
                <Select
                    options={Object.values(DEFAULT_CAPTION_STYLES)}
                    value={getCaptionPresetLabelFromValues(selectedTheme.captionConfig)}
                    onChange={onCaptionPresetStyleChange}
                    className="w-full"
                />
                <CaptionRendered />
                {/*</Card> 
        <Card size="small" title="Style details" className="mt-1"> */}
                <div className="flex flex-col  my-2">
                    <div className="mb-1">Position:</div>
                    <Radio.Group
                        block
                        optionType="button"
                        buttonStyle="solid"
                        options={Object.values(CAPTION_POSITIONS)}
                        value={selectedTheme.captionConfig?.position.split(" ")[0]}
                        onChange={(newPos) => setSelectedTheme({ ...selectedTheme, captionConfig: { ...selectedTheme.captionConfig, position: selectedTheme.captionConfig.position.replace(/^\S+/, newPos.target.value) as Position } })}
                    />
                </div>
                <div className="flex flex-row items-center my-2">
                    <div>Font:</div>
                    <Select className="w-full ml-2"
                        options={Object.values(FONT_NAMES)}
                        value={selectedTheme.captionConfig?.font}
                        onChange={(newFont) => setSelectedTheme({ ...selectedTheme, captionConfig: { ...selectedTheme.captionConfig, font: newFont } })}
                    />
                </div>



                <div className="flex flex-row items-center my-2">
                    <div style={{ flex: "0 0 auto" }}>Outline:</div>
                    <Select
                        className="w-full ml-2"
                        options={Object.values(FONT_OUTLINE)}
                        value={selectedTheme.captionConfig?.outline}
                        onChange={(newOutline) => setSelectedTheme({ ...selectedTheme, captionConfig: { ...selectedTheme.captionConfig, outline: newOutline } })}
                    />
                </div>

                <div className="flex flex-row items-center my-2">
                    <div style={{ flex: "0 0 auto" }}>Background:</div>
                    <Select
                        className="w-full ml-2"
                        options={Object.values(FONT_BACKGROUND_STYLE)}
                        value={selectedTheme.captionConfig?.background}
                        onChange={(newBackground) => setSelectedTheme({ ...selectedTheme, captionConfig: { ...selectedTheme.captionConfig, background: newBackground } })}
                    />
                </div>

                <div className="flex flex-row items-center my-2">
                    <div style={{ flex: "0 0 auto" }}>Max lines:</div>
                    <Select
                        className="w-full ml-2"
                        options={Object.values(CAPTION_LINE_CONFIG)}
                        defaultValue={CAPTION_LINE_CONFIG.ONE_LINE.value}
                        onChange={(newLineConfig) => setSelectedTheme({ ...selectedTheme, captionConfig: { ...selectedTheme.captionConfig, maxLines: newLineConfig as CaptionDesignSystemConfig["maxLines"]  } })}
                    />
                </div>
                <div className="flex flex-row items-center my-2">
                    <div style={{ flex: "0 0 auto" }}>Animation:</div>
                    <Select
                        className="w-full ml-2"
                        options={Object.values(CAPTION_ANIMATIONS)}
                        value={selectedTheme.captionConfig?.textAnimation}
                        onChange={(newAnimation) => setSelectedTheme({ ...selectedTheme, captionConfig: { ...selectedTheme.captionConfig, textAnimation: newAnimation } })}
                    />
                </div>
                <div className="flex flex-row justify-between items-center my-2">
                    <div>Main color:</div>
                    <ColorPicker
                        value={selectedTheme.captionConfig?.mainColor}
                        onChange={(newColor) => setSelectedTheme({ ...selectedTheme, captionConfig: { ...selectedTheme.captionConfig, mainColor: newColor.toHexString() } })}

                    />
                </div>

                <div className="flex flex-row justify-between items-center my-2">
                    <div>Highlight color:</div>
                    <ColorPicker
                        value={selectedTheme.captionConfig?.highlightColor}
                        onChange={(newColor) => setSelectedTheme({ ...selectedTheme, captionConfig: { ...selectedTheme.captionConfig, highlightColor: newColor.toHexString() } })}
                    />
                </div>

                <div className="flex flex-row items-center my-2">
                    <div style={{ flex: "0 0 auto" }}>Font size:</div>
                    <Select
                        className="w-full ml-2"
                        options={Object.values(FONT_SIZES)}
                        value={selectedTheme.captionConfig?.fontSizeRatio}
                        onChange={(newSize) => setSelectedTheme({ ...selectedTheme, captionConfig: { ...selectedTheme.captionConfig, fontSizeRatio: newSize } })}
                    />
                </div>
                <div className="flex flex-row items-center my-2">
                    <div style={{ flex: "0 0 auto" }}>Alignment:</div>
                    <Select
                        className="w-full ml-2"
                        options={Object.values(CAPTION_ALIGNMENTS)}
                        value={selectedTheme.captionConfig?.position.split(" ")[1]}
                        onChange={(newAlignment) => setSelectedTheme({ ...selectedTheme, captionConfig: { ...selectedTheme.captionConfig, position: selectedTheme.captionConfig.position.replace(/\S+$/, newAlignment) as Position } })}
                    />
                </div>
                <div className="flex flex-row items-center my-2">
                    <div style={{ flex: "0 0 auto" }}>Corner Radius:</div>

                    <Select
                        className="w-full ml-2"
                        options={Object.values(BORDER_CORNER_RADIUS)}
                        value={selectedTheme.captionConfig?.borderRadius}
                        onChange={(newRadius) => setSelectedTheme({ ...selectedTheme, captionConfig: { ...selectedTheme.captionConfig, borderRadius: newRadius } })}
                    />
                </div>
                <div className="flex flex-col my-2">
                    <div style={{ flex: "0 0 auto" }} className="mb-1">Caption case:</div>

                    <Radio.Group

                        block
                        optionType="button"
                        buttonStyle="solid"
                        options={Object.values(CAPTION_CASES)}
                        value={selectedTheme.captionConfig?.captionCase}
                        onChange={(newCase) => setSelectedTheme({ ...selectedTheme, captionConfig: { ...selectedTheme.captionConfig, captionCase: newCase.target.value } })}
                    />
                </div>
                <div className="flex flex-row justify-between items-center">
                    <div>Hide punctuation:</div>
                    <Switch
                        size="small"
                        value={selectedTheme.captionConfig?.hidePunctuation}
                        onChange={(newVal) => setSelectedTheme({ ...selectedTheme, captionConfig: { ...selectedTheme.captionConfig, hidePunctuation: newVal } })}
                    />
                </div>
            </Card>
        </div>
    );
};