import { useMemo, useState } from "react";

import { ThemeSelector } from "./ThemeConfigurator/ThemeSelector";
import TrebbleClipCreatorInput, { TrebbleClipCreatorInputConfig } from "../remotion/TrebbleClipCreator";
import { trebbleClipCreatorInputDemo } from "../remotion/Root";

import sampleVideoData from '../remotion/video_data_to_render.json';
import { ThemeNavigationComponent } from "./ThemeConfigurator/ThemeEditor";
import { Player } from "@remotion/player";
import { useLocalStorage } from "usehooks-ts";
import {  Button} from "antd";
import ConfigProvider from "../../common/ConfigProvider";
import useDefaultTrebbleAntDTokenConfig from "../../helper/useDefaultTrebbleAntDTokenConfig";

/** A theme object is very similar to the Remotion Project Config
 *  However it excludes video rendering data
 */
export interface Theme extends Omit<TrebbleClipCreatorInputConfig, 'videoRenderData'> {
  themeID: string;
  themeName: string;
  /**
   * Default configuration for the theme
   * 
   * Only included in pre-built themes
   */
  defaultConfiguration?: Omit<TrebbleClipCreatorInputConfig, 'videoRenderData'>;
}


export const deleteTheme = async (theme: Theme, setSavedThemes: React.Dispatch<React.SetStateAction<Theme[]>>) => {
  // FAKE API CALL
  setSavedThemes((themes) => themes.filter((t) => t.themeID !== theme.themeID && t.defaultConfiguration === undefined));
}

const Configurator = ({ savedThemes, setSavedThemes, selectedTheme, setSelectedTheme, videoRenderData }: {
  savedThemes: Theme[],
  setSavedThemes: React.Dispatch<React.SetStateAction<Theme[]>>,
  selectedTheme: Theme,
  setSelectedTheme: (theme: Theme) => void,
  videoRenderData: typeof sampleVideoData
}) => {
 
  return (
    
      <div style={{ minWidth: "340px", maxWidth: "340px" , maxHeight: "600px"}} className="relative h-fit">
        <div
          className="border border-0 p-2 overflow-auto w-auto h-screen"
          style={{ scrollbarWidth: 'thin', height: "600px" }}
        >
          <ThemeSelector savedThemes={savedThemes} setSavedThemes={setSavedThemes} selectedTheme={selectedTheme} setSelectedTheme={setSelectedTheme} />
          <ThemeNavigationComponent selectedTheme={selectedTheme} setSelectedTheme={setSelectedTheme} videoRenderData={videoRenderData} />
        </div>
      </div>
  );
}

const VideoPreview = ({ videoRenderData, theme, durationInMs,framePerSec, startTimeOffset, firstScheduledNode, hideVideoPlayer, audioUrl }: { videoRenderData: typeof sampleVideoData, theme: Theme , durationInMs:number, framePerSec:number,  startTimeOffset: number,firstScheduledNode: any, hideVideoPlayer: boolean, audioUrl: string}) => {
  const trebbleClipCreatorInput = useMemo(() => ({
    ...theme,
    audioUrl,
    videoRenderData: videoRenderData,
    startTimeOffset, firstScheduledNode ,durationInMs,framePerSec
  }), [theme, videoRenderData, startTimeOffset, firstScheduledNode]);
  const themeConfig = useDefaultTrebbleAntDTokenConfig({useEditorTheme: true});
  return (
    <div
      style={{
        width: '100%',
        height: '500px',
        maxHeight: '100vh',
        backgroundColor:  themeConfig.token.colorBgElevated,
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        aspectRatio: `${trebbleClipCreatorInput.designSystem.canvas.width} / ${trebbleClipCreatorInput.designSystem.canvas.height}`
      }}
    >
      <div style={{
        maxHeight: '100%',
        maxWidth: '100%',
        aspectRatio: `${trebbleClipCreatorInput.designSystem.canvas.width} / ${trebbleClipCreatorInput.designSystem.canvas.height}`
      }}>
        {!hideVideoPlayer && <Player
          style={{ width: '100%' }}
          component={TrebbleClipCreatorInput}
          compositionHeight={trebbleClipCreatorInput.designSystem.canvas.height}
          compositionWidth={trebbleClipCreatorInput.designSystem.canvas.width}
          fps={framePerSec}

          durationInFrames={Math.floor(durationInMs/framePerSec)}
          inputProps={trebbleClipCreatorInput}
          controls
        />}
      </div>
    </div>
  );
}



export const VideoEditor = ({ videoRenderData, durationInMs, framePerSec, startTimeOffset, firstScheduledNode, audioUrl, onGenerateClipButtonClicked, videoTierResolution}: { 
  videoRenderData: typeof sampleVideoData,
  durationInMs: number, 
  framePerSec: number, 
  startTimeOffset: number,
  firstScheduledNode: any,
  audioUrl: string,
  videoTierResolution: string,
  onGenerateClipButtonClicked: Function
}) => {
  const [savedThemes, setSavedThemes] = useLocalStorage('themes', [{
    ...trebbleClipCreatorInputDemo,
    themeID: '1',
    themeName: 'Default Theme',
    defaultConfiguration: trebbleClipCreatorInputDemo
  }], {
    deserializer: (value) => JSON.parse(value) as Theme[],
    serializer: (value) => JSON.stringify(value),
  });
  const themeConfig = useDefaultTrebbleAntDTokenConfig({useEditorTheme: true});
  
  const [selectedTheme, setSelectedTheme] = useState<Theme>(savedThemes[0]);
  const [videoBeingGenerated, setVideoBeingGenerated] = useState<boolean>(false);

  const onCreateButtonClicked= async()=>{
    setVideoBeingGenerated(true);
    try{
      await onGenerateClipButtonClicked({
        ...selectedTheme,
        videoTierResolution,
        videoRenderData: videoRenderData,
        durationInMs, framePerSec,
        startTimeOffset, firstScheduledNode 
      });
      
      setVideoBeingGenerated(false);
    }catch(error){
      console.error(error);
      setVideoBeingGenerated(false);
    }
  }

  return (
    <ConfigProvider useEditorTheme={true} theme={ themeConfig }>
    <div className={`flex flex-row items-start w-full gap-4  max-h-screen`} style={{backgroundColor: themeConfig.token.colorBgElevated}}>
      <Configurator savedThemes={savedThemes} setSavedThemes={setSavedThemes} selectedTheme={selectedTheme} setSelectedTheme={setSelectedTheme} videoRenderData={videoRenderData} />
      <div className="flex flex-col w-full">
        <div className="flex flex-col items-end p-2">
            <Button type="primary" onClick={onCreateButtonClicked} loading={videoBeingGenerated}>{videoBeingGenerated?`Exporting...`:`Export`}</Button>
        </div>
      <VideoPreview videoRenderData={videoRenderData} audioUrl={audioUrl} theme={selectedTheme} durationInMs={durationInMs} framePerSec={framePerSec} startTimeOffset={startTimeOffset} firstScheduledNode={firstScheduledNode} hideVideoPlayer={videoBeingGenerated}/>
      </div>
    </div>
    </ConfigProvider>
  );
};