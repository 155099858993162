import { Caption } from "@remotion/captions";

export type CaptionToken = {
	text: string;
	fromMs: number;
	toMs: number;
};

export type CaptionPage = {
	text: string;
	startMs: number;
	tokens: CaptionToken[];
};

export type CreateGroupedCaptionsInput = {
	captions: Caption[];
	combineTokensWithinMilliseconds: number;
};

export type CreateGroupedCaptionsOutput = {
	pages: CaptionPage[];
};

export type CreateLineBasedCaptionsInput = {
	captions: Caption[];
	maxLines: number;
	maxWordsPerLine: number;
	maxCharsPerLine: number;
	spaceCharWidth?: number;
};

export type CreateLineBasedCaptionsWithProperTokensInput = {
	captions: Caption[];
	maxLines: number;
	maxWordsPerLine: number;
	maxCharsPerLine: number;
	spaceCharWidth?: number;
};

export function createGroupedCaptions({
	captions,
	combineTokensWithinMilliseconds,
}: CreateGroupedCaptionsInput): CreateGroupedCaptionsOutput {
	const groupedCaptions: CaptionPage[] = [];
	let currentText = '';
	let currentTokens: CaptionToken[] = [];
	let currentFrom = 0;
	let currentTo = 0;

	captions.forEach((item, index) => {
		const {text} = item;
		
		// Start a new page if the duration exceeds the threshold
		if (currentTo - currentFrom > combineTokensWithinMilliseconds) {
			if (currentText !== '') {
				groupedCaptions.push({
					text: currentText.trimStart(),
					startMs: currentFrom,
					tokens: currentTokens,
				});
			}

			// Start a new page
			currentText = text.trimStart() + ' ';
			currentTokens = [
				{text: currentText, fromMs: item.startMs, toMs: item.endMs},
			].filter((t) => t.text !== '');
			currentFrom = item.startMs;
			currentTo = item.endMs;
		} else {
			// Continuation or start of a new page
			if (currentText === '') {
				currentFrom = item.startMs;
			}

			currentText += text + ' ';
			currentText = currentText.trimStart();
			if (text.trim() !== '') {
				currentTokens.push({
					text: currentTokens.length === 0 ? currentText.trimStart() : text + ' ',
					fromMs: item.startMs,
					toMs: item.endMs,
				});
			}

			currentTo = item.endMs;
		}

		// Ensure the last page is added
		if (index === captions.length - 1 && currentText !== '') {
			groupedCaptions.push({
				text: currentText.trim(), // Trim the last space
				startMs: currentFrom,
				tokens: currentTokens,
			});
		}
	});

	return {pages: groupedCaptions};
}

export function createLineBasedCaptions({
	captions,
	maxLines,
	maxWordsPerLine,
	maxCharsPerLine,
	spaceCharWidth = 1,
}: CreateLineBasedCaptionsInput): CreateGroupedCaptionsOutput {
	const pages: CaptionPage[] = [];
	
	if (captions.length === 0) {
		return { pages };
	}

	// Sort captions by start time
	const sortedCaptions = [...captions].sort((a, b) => a.startMs - b.startMs);
	
	let currentPageCaptions: Caption[] = [];
	let currentPageStartMs = sortedCaptions[0].startMs;
	let currentLines = 0;
	let currentLineWords = 0;
	let currentLineChars = 0;
	let currentLineStartIndex = 0;
	
	for (let i = 0; i < sortedCaptions.length; i++) {
		const caption = sortedCaptions[i];
		const wordCount = caption.text.trim().split(/\s+/).length;
		const charCount = caption.text.trim().length;
		
		// Calculate the total character count including space
		const totalCharCount = charCount + (currentLineChars > 0 ? spaceCharWidth : 0);
		
		// Check if adding this word would exceed line limits
		if (currentLineWords + wordCount > maxWordsPerLine || 
			currentLineChars + totalCharCount > maxCharsPerLine) {
			// Start a new line
			currentLines++;
			currentLineWords = wordCount;
			currentLineChars = charCount;
			currentLineStartIndex = i;
		} else {
			// Add to current line
			currentLineWords += wordCount;
			currentLineChars += totalCharCount;
		}
		
		// Add caption to current page
		currentPageCaptions.push(caption);
		
		// Check if we need to start a new page
		if (currentLines >= maxLines && i < sortedCaptions.length - 1) {
			// Create the page with proper line breaks
			const page = createPageWithLineBreaks(
				currentPageCaptions, 
				currentPageStartMs, 
				maxWordsPerLine, 
				maxCharsPerLine,
				spaceCharWidth
			);
			
			pages.push(page);
			
			// Start a new page
			currentPageCaptions = [];
			currentPageStartMs = sortedCaptions[i + 1].startMs;
			currentLines = 0;
			currentLineWords = 0;
			currentLineChars = 0;
			currentLineStartIndex = 0;
		}
	}
	
	// Add the last page if there's anything left
	if (currentPageCaptions.length > 0) {
		const page = createPageWithLineBreaks(
			currentPageCaptions, 
			currentPageStartMs, 
			maxWordsPerLine, 
			maxCharsPerLine,
			spaceCharWidth
		);
		pages.push(page);
	}
	
	return { pages };
}

// Helper function to create a page with proper line breaks
function createPageWithLineBreaks(
	captions: Caption[], 
	startMs: number,
	maxWordsPerLine: number,
	maxCharsPerLine: number,
	spaceCharWidth: number
): CaptionPage {
	let text = '';
	const tokens: CaptionToken[] = [];
	let currentLineWords = 0;
	let currentLineChars = 0;
	
	captions.forEach((caption) => {
		const wordCount = caption.text.trim().split(/\s+/).length;
		const charCount = caption.text.trim().length;
		const totalCharCount = charCount + (currentLineChars > 0 ? spaceCharWidth : 0);
		
		// Check if we need a line break
		if ((currentLineWords + wordCount > maxWordsPerLine || 
			currentLineChars + totalCharCount > maxCharsPerLine) && 
			currentLineWords > 0) { // Only add line break if not at start of line
			text += '\n';
			currentLineWords = 0;
			currentLineChars = 0;
		}
		
		// Add the word with proper spacing
		const prefix = currentLineWords === 0 ? '' : ' ';
		text += prefix + caption.text.trim();
		
		// Create token with proper timing
		tokens.push({
			text: prefix + caption.text.trim(),
			fromMs: caption.startMs,
			toMs: caption.endMs || caption.startMs + 500 // Default duration if endMs not provided
		});
		
		// Update line counters
		currentLineWords += wordCount;
		currentLineChars += totalCharCount;
	});
	
	return {
		text: text.trim(),
		startMs,
		tokens
	};
}

export function createLineBasedCaptionsWithProperTokens({
	captions,
	maxLines,
	maxWordsPerLine,
	maxCharsPerLine,
	spaceCharWidth = 1,
}: CreateLineBasedCaptionsWithProperTokensInput): CreateGroupedCaptionsOutput {
	if (captions.length === 0) {
		return { pages: [] };
	}

	// Sort captions by start time
	const sortedCaptions = [...captions].sort((a, b) => a.startMs - b.startMs);
	
	// Group captions by time proximity
	const captionGroups: Caption[][] = [];
	let currentGroup: Caption[] = [sortedCaptions[0]];
	let currentGroupEndTime = sortedCaptions[0].endMs || sortedCaptions[0].startMs + 500;
	
	// Group captions that are close in time
	for (let i = 1; i < sortedCaptions.length; i++) {
		const caption = sortedCaptions[i];
		const timeDiff = caption.startMs - currentGroupEndTime;
		
		// If this caption starts soon after the previous one ends, add to current group
		if (timeDiff < 1000) { // 1 second threshold
			currentGroup.push(caption);
			currentGroupEndTime = Math.max(currentGroupEndTime, caption.endMs || caption.startMs + 500);
		} else {
			// Start a new group
			captionGroups.push(currentGroup);
			currentGroup = [caption];
			currentGroupEndTime = caption.endMs || caption.startMs + 500;
		}
	}
	
	// Add the last group
	if (currentGroup.length > 0) {
		captionGroups.push(currentGroup);
	}
	
	// Create pages from caption groups with proper line breaks and token distribution
	const pages: CaptionPage[] = [];
	
	captionGroups.forEach(group => {
		// Create text with line breaks based on word and character limits
		let text = '';
		const tokens: CaptionToken[] = [];
		let currentLineWords = 0;
		let currentLineChars = 0;
		let currentLineCount = 0;
		let pageStartMs = group[0].startMs; // Track the start time for each page
		let firstTokenInPage = true; // Track if this is the first token in the page
		
		group.forEach(caption => {
			const captionText = caption.text.trim();
			const wordCount = captionText.split(/\s+/).length;
			const charCount = captionText.length;
			const totalCharCount = charCount + (currentLineChars > 0 ? spaceCharWidth : 0);
			
			// Check if adding this caption would exceed line limits
			if ((currentLineWords + wordCount > maxWordsPerLine || 
				currentLineChars + totalCharCount > maxCharsPerLine) && 
				currentLineWords > 0) {
				
				// Add line break if not exceeding max lines
				if (currentLineCount < maxLines - 1) {
					text += '\n';
					currentLineCount++;
					currentLineWords = 0;
					currentLineChars = 0;
				} else {
					// Start a new page
					if (text.trim() !== '') {
						pages.push({
							text: text.trim(),
							startMs: pageStartMs,
							tokens: [...tokens]
						});
					}
					
					// Reset for new page
					text = '';
					tokens.length = 0;
					currentLineCount = 0;
					currentLineWords = 0;
					currentLineChars = 0;
					pageStartMs = caption.startMs; // Update start time for the new page
					firstTokenInPage = true;
				}
			}
			
			// Update page start time if this is the first token in the page
			if (firstTokenInPage) {
				pageStartMs = caption.startMs;
				firstTokenInPage = false;
			}
			
			// Add caption to current line
			const prefix = currentLineWords === 0 ? '' : ' ';
			text += prefix + captionText;
			
			// Add token with proper timing
			tokens.push({
				text: prefix + captionText,
				fromMs: caption.startMs,
				toMs: caption.endMs || caption.startMs + 500
			});
			
			// Update line counters
			currentLineWords += wordCount;
			currentLineChars += totalCharCount;
		});
		
		// Add the last page if there's anything left
		if (text.trim() !== '') {
			pages.push({
				text: text.trim(),
				startMs: pageStartMs,
				tokens
			});
		}
	});
	
	return { pages };
}