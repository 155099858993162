import { Select, ColorPicker, Card } from "antd";
import { getEnumFromValue } from "../ThemeEditor";
import { SplitCircle } from "./SplitCircle/SplitCircle";
import { Theme } from "../../VideoEditor";

const DEFAULT_COLOR_SETS = Object.freeze({
  TREBBLE: {
    label: "Trebble",
    value: "Trebble",
    primaryColor: "#e97d53",
    secondaryColor: "#ffe62c",
    accentColor: "#f0f0f0",
  },
  OCEAN: {
    label: "Ocean",
    value: "ocean",
    primaryColor: "#2563eb",
    secondaryColor: "#22d3ee",
    accentColor: "#052e7a",
  },
  PURPLE: {
    label: "Purple",
    value: "purple",
    primaryColor: "#7c3aed",
    secondaryColor: "#f0abfc",
    accentColor: "#2f0c4e",
  },
  FOREST: {
    label: "Forest",
    value: "forest",
    primaryColor: "#059669",
    secondaryColor: "#fbbf24",
    accentColor: "#047857",
  },
  CHERRY: {
    label: "Cherry",
    value: "cherry",
    primaryColor: "#dc2626",
    secondaryColor: "#6ee7b7",
    accentColor: "#991b1b",
  },
  CUSTOM: {
    label: "Custom",
    value: "custom",
    primaryColor: "#FFFFFF",
    secondaryColor: "#FFFFFF",
    accentColor: "#FFFFFF",
  },
});


const getColorSetLabelFromValues = (primaryColor: string, secondaryColor: string, accentColor: string) => {  
  const colorSet = Object.values(DEFAULT_COLOR_SETS).find(
    (colorSet) =>
      colorSet.primaryColor.toLowerCase() === primaryColor.toLowerCase() &&
      colorSet.secondaryColor.toLowerCase() === secondaryColor.toLowerCase() &&
      colorSet.accentColor.toLowerCase() === accentColor.toLowerCase()
  );
  return colorSet?.value || "custom";
}

export const ColorComponent = ({ selectedTheme, setSelectedTheme }: { selectedTheme: Theme, setSelectedTheme: (theme: Theme) => void }) => {
  const colorSetSelected = getColorSetLabelFromValues(selectedTheme.designSystem.primaryColor, selectedTheme.designSystem.secondaryColor, selectedTheme.designSystem.accentColor);

  const ColorOption = ({ color1, color2, label }: { color1: any, color2: any, label: any }) => {
    return (
      <div className="flex flex-row items-center">
        <div>
          <SplitCircle color1={color1} color2={color2} size="h4 w-4" />
        </div>
        <div className="opacity-50 px-2">{label}</div>
      </div>
    );
  };
  const ColorSetSelect = ({ onChange, value }: { onChange: any, value: any }) => {
    return (
      <Select
        value={value}
        options={Object.values(DEFAULT_COLOR_SETS)}
        onChange={onChange}
        optionRender={(option: { data: { primaryColor: string; secondaryColor: string; label: string; }; }) => (
          <ColorOption
            color1={option.data.primaryColor}
            color2={option.data.secondaryColor}
            label={option.data.label}
          />
        )}
        labelRender={({ value }: { value: any }) => {
          const selectedColorSet = getEnumFromValue(
            value,
            DEFAULT_COLOR_SETS
          ) as any;

          return (
            <ColorOption
              color1={selectedColorSet.primaryColor}
              color2={selectedColorSet.secondaryColor}
              label={selectedColorSet.label}
            />
          );
        }}
      />
    );
  };
  const onColorSetChange = (colorSetValue: any) => {
    const selectedColorSet = getEnumFromValue(
      colorSetValue,
      DEFAULT_COLOR_SETS
    ) as typeof DEFAULT_COLOR_SETS[keyof typeof DEFAULT_COLOR_SETS];
    setSelectedTheme({ ...selectedTheme, designSystem: { ...selectedTheme.designSystem, primaryColor: selectedColorSet.primaryColor, secondaryColor: selectedColorSet.secondaryColor, accentColor: selectedColorSet.accentColor } });
  };
  const CustomColorSetConfig = () => {
    return (
      <div className="flex flex-col">
        <div>Primary color:</div>
        <div className="opacity-50 text-sm">
          This color will be use as primary color by default for things like
          title fonts.
        </div>
        <ColorPicker
          value={selectedTheme.designSystem.primaryColor}
          onChangeComplete={(primaryColor) => setSelectedTheme({ ...selectedTheme, designSystem: { ...selectedTheme.designSystem, primaryColor: primaryColor.toHexString() } })}
          showText
        />
        <div>Secondary color:</div>
        <div className="opacity-50 text-sm">
          This color will be use as secondary color by default for things like body fonts.
        </div>
        <ColorPicker
          value={selectedTheme.designSystem.secondaryColor}
          onChangeComplete={(secondaryColor) => setSelectedTheme({ ...selectedTheme, designSystem: { ...selectedTheme.designSystem, secondaryColor: secondaryColor.toHexString() } })}
          showText
        />
        <div>Accent color:</div>
        <div className="opacity-50 text-sm">
          This color will be use as accent color by default for things like borders and captions.
        </div>
        <ColorPicker
          value={selectedTheme.designSystem.accentColor}
          onChangeComplete={(accentColor) => setSelectedTheme({ ...selectedTheme, designSystem: { ...selectedTheme.designSystem, accentColor: accentColor.toHexString() } })}
          showText
        />
      </div>
    );
  };
  return (
    <>
      <Card size="small" className="my-2">
        <div className="flex flex-col">
          <div>Color set:</div>
          <div className="opacity-50 text-sm">
            This color set will by default on captions, background color or
            any other element which need a main color or a complimentary
            color.
          </div>
          <ColorSetSelect
            onChange={onColorSetChange}
            value={colorSetSelected}
          />
        </div>
      </Card>
      {colorSetSelected === DEFAULT_COLOR_SETS.CUSTOM.value && (
        <Card size="small" className="my-2">
          <CustomColorSetConfig />
        </Card>
      )}
    </>
  );
};