import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SequencerView from "views/audioEditor/SequencerView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import FileUploadHelper from "models/helper/FileUploadHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import TrebbleAudio from "models/TrebbleAudio";
import TrebbleVideo from "models/TrebbleVideo";
import SequencerNodeCloner from "models/audioEditor/sequencerNodes/SequencerNodeCloner";
import SequencerNodeInspectorSectionController from "controllers/audioEditor/sequencerNodeInspectorSection/SequencerNodeInspectorSectionController";
import SequencerSettingsController from "controllers/audioEditor/SequencerSettingsController";
import SequencerNodeMoreOptionController from "controllers/audioEditor/SequencerNodeMoreOptionController";
import SoundEnhancerSettingsPopupContoller from "controllers/audioEditor/SoundEnhancerSettingsPopupContoller";
import SequencerAutomatedOperationsOptionController from "controllers/audioEditor/SequencerAutomatedOperationsOptionController";
import SequencerHelpContextOptionController from "controllers/audioEditor/SequencerHelpContextOptionController";
import SequencerViewConfigSettingsPopupContoller from "controllers/audioEditor/SequencerViewConfigSettingsPopupContoller";
import SequencerNodeUtils from 'models/audioEditor/SequencerNodeUtils';
import TrebbleNotificationManager from '../../components/common/NotificationManager';
import Utils from "models/helper/Utils";
import Sequencer from "models/audioEditor/Sequencer";
import ti18n from "i18n!nls/Sequenceri18n";
import RSVP from "rsvp";

const Rollout = RolloutHelper.getInstance();

const ALLOW_DUCKING_OF_BACKGROUND_MUSIC_IN_AUDIO_EDITOR = Rollout.isFeatureEnabled(Rollout.FEATURES.ALLOW_DUCKING_OF_BACKGROUND_MUSIC_IN_AUDIO_EDITOR, false);
const AUDIO_EDITOR_ONBOARDING = Rollout.isFeatureEnabled(Rollout.FEATURES.AUDIO_EDITOR_ONBOARDING, true);
const EDIT_VIDEO_FILES = Rollout.isFeatureEnabled(Rollout.FEATURES.EDIT_VIDEO_FILES, true);
const RENDER_VIDEO_IN_CLOUD = Rollout.isFeatureEnabled(Rollout.FEATURES.RENDER_VIDEO_IN_CLOUD, true);

const SequencerController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model =  new Sequencer();
        this._jqueryPageElWhereSequencerWillBeAdded$el  = null
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    setJqueryPageElWhereSequencerWillBeAdded : function(jqueryEl){
        this.view.setJqueryPageElWhereSequencerWillBeAdded(jqueryEl);
    },

    addTranscribeAudio : function(audioUrl, transcriptionJsonURL, transcriptionId, doNotAddToSequencer, isVideo, progressReportFunction, languageCode, width, height){
        
        if(this.view){
            this.view.setTranscribedAudioBeingAdded(true);
        }else{
            this._setTranscribedAudioBeingAddedWhenViewGetCreated = true;
        }
        return this.model.addTranscribeAudio(audioUrl, transcriptionJsonURL, transcriptionId, doNotAddToSequencer, isVideo, progressReportFunction, null, null, languageCode, width, height).then((function(){
            if(this._setTranscribedAudioBeingAddedWhenViewGetCreated){
                this._setTranscribedAudioBeingAddedWhenViewGetCreated = false;
            }
            if(this.view){
                this.view.setTranscribedAudioBeingAdded(false);
            }
            this.showOnboardingIfApplicable();
        }).bind(this));
    },

    onIgnoreActionTriggered : function(){
        const selectionInfo = this.view.getSelectionNode();
        if(selectionInfo){
            const startSequencerNodeModel = selectionInfo.startSequencerNodeModel;
            const endSequencerNodeModel = selectionInfo.endSequencerNodeModel;
            this.model.deleteSelectedNodes(startSequencerNodeModel, endSequencerNodeModel);
            this.view.unselectCurrentlySelectedSequencerNodes();
        }

    },
    
    setAndLoadProject: function(projectId, duplicateProject, progressReportFunction){
        return this.model.setAndLoadProject(projectId, duplicateProject, progressReportFunction);
    },

    getRenderedAudioBlob : function(renderWavFile, asVideo, progressReportFunction, alreadyRenderedAudioWAVBlob){
        return this.model.getRenderedAudioBlob(renderWavFile, asVideo, progressReportFunction, alreadyRenderedAudioWAVBlob);
    },

    createFFMPEGParamsToRenderVideoByContatenation : function(audioUrl, srtFileUrl){
        return this.model.createFFMPEGParamsToRenderVideoByContatenation(true, audioUrl, null, null, srtFileUrl);
    },

    createFFMPEGParamsByContatenationForServerRendering : function(sequencerNodeToStartFrom, sequencerNodeToEndOn, audioUrl , maxDurationInMilliseconds ){
        return this.model.createFFMPEGParamsByContatenationForServerRendering(true, sequencerNodeToStartFrom, sequencerNodeToEndOn, audioUrl, maxDurationInMilliseconds);
    },

    getSrtText : function(sequencerNodeToStartFrom, sequencerNodeToEndOn, progressReportFunction, asVtt, doNotIncludeSpeaker){
        return this.model.getSrtText(sequencerNodeToStartFrom, sequencerNodeToEndOn, progressReportFunction, asVtt, doNotIncludeSpeaker);
    },

    onWrapWithMusicActionTriggered : function(){
        const onBackgroundMusicSelected = this.wrapSelectionWithMusic.bind(this);
        return PersistentModels.getInstance().getRouter().showChooseMusicSoundAndUploadsPopup(onBackgroundMusicSelected);
    },

    getDurationInMilliseconds : function(){
        return this.model.getDurationInMilliseconds();
    },

    wrapSelectionWithMusic : function(trebbleAudioModel){
        if(trebbleAudioModel){
            const selectionInfo = this.view.getSelectionNode();
            if(selectionInfo){
                const startSequencerNodeToWrap = selectionInfo.startSequencerNodeModel;
                const endSequencerNodeToWrap = selectionInfo.endSequencerNodeModel;
                const introFadeInMilliseconds = 0;
                const outroFadeinMilliseconds = 0;
                const introPaddingInMilliseconds = 0;
                const outroPaddingInMilliseconds = 0;
                const loopToMatchWrapNodeDuration = false;
                const volume = ALLOW_DUCKING_OF_BACKGROUND_MUSIC_IN_AUDIO_EDITOR? 0.50:0.20;
                const ducking = ALLOW_DUCKING_OF_BACKGROUND_MUSIC_IN_AUDIO_EDITOR?true: false;
                this.model.wrapSelectedNodesWithMusic(trebbleAudioModel, introPaddingInMilliseconds, outroPaddingInMilliseconds, introFadeInMilliseconds,outroFadeinMilliseconds, volume, loopToMatchWrapNodeDuration, ducking,  startSequencerNodeToWrap, endSequencerNodeToWrap);
                this.view.unselectCurrentlySelectedSequencerNodes();
            }
        }
    },

    deleteAllExceptSelectedNodes: function(){
        const selectionInfo = this.view.getSelectionNode();
        if(selectionInfo){
            const startSequencerNodeToKeep = selectionInfo.startSequencerNodeModel;
            const endSequencerNodeToKeep = selectionInfo.endSequencerNodeModel;
            this.model.deleteAllExceptSelectedNodes(startSequencerNodeToKeep, endSequencerNodeToKeep);
            this.view.unselectCurrentlySelectedSequencerNodes();
        }
    },

    deleteAllExceptSelectedNodesAndSaveThenUndo: async function(startSequencerNodeToKeep, endSequencerNodeToKeep, operationNameWhichTriggerHistoryUpdate){
        const selectionInfo = this.view.getSelectionNode();
        startSequencerNodeToKeep = startSequencerNodeToKeep? startSequencerNodeToKeep:selectionInfo? selectionInfo.startSequencerNodeModel:null;
        endSequencerNodeToKeep = endSequencerNodeToKeep? endSequencerNodeToKeep:selectionInfo? selectionInfo.endSequencerNodeModel:null;
        if(startSequencerNodeToKeep && endSequencerNodeToKeep){
            this.model.deleteAllExceptSelectedNodes(startSequencerNodeToKeep, endSequencerNodeToKeep);
            await this.model.saveProject(true, operationNameWhichTriggerHistoryUpdate);
            const projectIdToReturn = this.model.getProjectId();
            this.model.getHistoryManagement().undo(true);
            return projectIdToReturn;
        }
        
    },


    onInsertAudioNodeTriggered : function(insertBefore){
        const selectionInfo = this.view.getSelectionNode();
        if(selectionInfo){
            const startSequencerNodeModel = selectionInfo.startSequencerNodeModel;
            const endSequencerNodeModel = selectionInfo.endSequencerNodeModel;
            if(startSequencerNodeModel || endSequencerNodeModel){
                //One node selected
                const onTrebbleAudioSelected = (function(trebbleAudio){
                    this.insertAudioMode(insertBefore? startSequencerNodeModel: endSequencerNodeModel , insertBefore, trebbleAudio);
                }).bind(this);
                return PersistentModels.getInstance().getRouter().showChooseMusicSoundAndUploadsPopup(onTrebbleAudioSelected, false, true);
            }
        }

    },

    onInsertVideoNodeTriggered: function(insertBefore){
        const selectionInfo = this.view.getSelectionNode();
        if(selectionInfo){
            const startSequencerNodeModel = selectionInfo.startSequencerNodeModel;
            const endSequencerNodeModel = selectionInfo.endSequencerNodeModel;
            if(startSequencerNodeModel || endSequencerNodeModel){
                //One node selected
                const onTrebbleVideoSelected = (function(trebbleVideo){
                    this.insertVideoMode(insertBefore? startSequencerNodeModel: endSequencerNodeModel , insertBefore, trebbleVideo);
                }).bind(this);
                return PersistentModels.getInstance().getRouter().showChooseUploadedVideosPopup(onTrebbleVideoSelected);
            }
        }

    },

    insertAudioMode : function(sequencerNodeModelTarget, insertBefore, trebbleAudio){
        if(sequencerNodeModelTarget && trebbleAudio){
            //One node selected
            const introFadeInMilliseconds = 0;
            const outroFadeinMilliseconds = 0;
            const introPaddingInMilliseconds = 0;
            const outroPaddingInMilliseconds = 0;
            const volume = 0.5;
            this.model.insertAudioNode(trebbleAudio, sequencerNodeModelTarget, insertBefore, introPaddingInMilliseconds, outroPaddingInMilliseconds,introFadeInMilliseconds,outroFadeinMilliseconds, volume);
            this.view.unselectCurrentlySelectedSequencerNodes();
        }

    },

    insertVideoMode : function(sequencerNodeModelTarget, insertBefore, trebbleVideo){
        if(sequencerNodeModelTarget && trebbleVideo){
            //One node selected
            const introFadeInMilliseconds = 0;
            const outroFadeinMilliseconds = 0;
            const introPaddingInMilliseconds = 0;
            const outroPaddingInMilliseconds = 0;
            const volume = 0.5;
            this.model.insertVideoNode(trebbleVideo, sequencerNodeModelTarget, insertBefore, introPaddingInMilliseconds, outroPaddingInMilliseconds,introFadeInMilliseconds,outroFadeinMilliseconds, volume);
            this.view.unselectCurrentlySelectedSequencerNodes();
        }

    },


    showSettingsForMusicWrapperTriggered : function(){
        return PersistentModels.getInstance().getRouter().showSequencerNodeInspectorSectionPopup(this.model, this.view.getSelectionNode());
    },


    onPlayFromTriggered : function(){
        const selectionInfo = this.view.getSelectionNode();
        if(selectionInfo){
            const nodeToPlayFrom = selectionInfo.startSequencerNodeModel;
            this.model.playPauseSequencerRenderingFromNode(nodeToPlayFrom, true);
            this.view.unselectCurrentlySelectedSequencerNodes();	
        }
    },

    transcribedSelectedMedia : async function(){
        const selectionInfo = this.view.getSelectionNode();
        if(selectionInfo){
            const sequencerNode = selectionInfo.startSequencerNodeModel;
            const notificationProgressUniqueId = new Date().getTime().toString();
            const onTranscriptionStarted = ()=>{
                TrebbleNotificationManager.createLoadingNotification(notificationProgressUniqueId, null, window.getI18n(ti18n, "TRANSCRIBING")+"...",{ duration: 0 ,darkTheme: false,  placement:"topRight"});
            };
            const onTranscriptionEnded= ()=>{
                
            }; 
            const onReplacementStarted = ()=>{
                TrebbleNotificationManager.createLoadingNotification(notificationProgressUniqueId, null, window.getI18n(ti18n, "ADDING_TRANSCRIPTION_TO_THE_EDITING_SCRIPT")+"...",{ duration: 0 ,darkTheme: false,  placement:"topRight"});
            }; 
            const onReplacementEnded= ()=>{
                TrebbleNotificationManager.destroyNotification(notificationProgressUniqueId);
            }; 
            const transcribeProxyFunction = async (languageCode)=>{
                this.view.unselectCurrentlySelectedSequencerNodes();
                //$.mobile.loading("show");
                await this.model.transcribeSequencerNode(sequencerNode, languageCode, onTranscriptionStarted, onTranscriptionEnded, onReplacementStarted, onReplacementEnded);
                //$.mobile.loading("hide");
            }
            await PersistentModels.getInstance().getRouter().showChooseLanguageForTranscriptionPopup(transcribeProxyFunction);
        }
    },

    getUserToRecord : async function(){
        return new Promise((resolve, reject)=>{
            try{
                this.view.setUnsafeToRemove(true);
                let blobsReturned = false;
                const onAllAudioRecorded = (arrayOfBlobRecorded)=>{
                    resolve(arrayOfBlobRecorded);
                    blobsReturned = true;
                    this.view.setUnsafeToRemove(false);
                }
                const onPageClosed = ()=>{
                    if(!blobsReturned){
                        resolve();
                    }
                    this.view.setUnsafeToRemove(false);
                }

                const onErrorOccured = (error)=>{
                    reject(error)
                    this.view.setUnsafeToRemove(false);
                }
                PersistentModels.getInstance().getRouter().showAudioRecorderPage(onAllAudioRecorded,onPageClosed,window.getI18n(ti18n,"RECORD_AUDIO"), true).catch(onErrorOccured);
            }catch(error){
                reject(error)
                this.view.setUnsafeToRemove(false);
            }
        })
        
    },

    recordAndInsert : async function(){
        const selectionInfo = this.view.getSelectionNode();
        if(selectionInfo){
            const sequencerNodeTargetToInsertBefore = selectionInfo.startSequencerNodeModel;
            const blobOfAudioRecorded =  await this.getUserToRecord();
            const audioOrVideoModelsToInsert = [];
            if(blobOfAudioRecorded && blobOfAudioRecorded.length > 0){
                for(let i =0; i < blobOfAudioRecorded.length;i++){
                    const blob =  blobOfAudioRecorded[i];
                    const isVideo =  await Utils.getInstance().isVideoFile(blob);
                    const actionToExecute =  async(progressReportFunction)=>{
                        return await FileUploadHelper.getInstance().showUploadDialogAndUploadFileToSoundLibrary(blob, null, true, isVideo, true, progressReportFunction);
                    }
                    const trebbleAudioOrVideJSON = await PersistentModels.getInstance().getRouter().executeFunctionAndShowProgressBarPopup (actionToExecute,`${window.getI18n(ti18n,"UPLOADING_YOUR_RECORDING")}...`);
                    if(isVideo){
                        audioOrVideoModelsToInsert.push(new TrebbleVideo(trebbleAudioOrVideJSON));
                    }else{
                        audioOrVideoModelsToInsert.push(new TrebbleAudio(trebbleAudioOrVideJSON));
                    }
                }
            }
            await this.model.insertAudioOrVideoNodesInBulk(sequencerNodeTargetToInsertBefore, true, audioOrVideoModelsToInsert);
        }   
    },


    deleteSequencerNode: async function(){
        const selectionInfo = this.view.getSelectionNode();
        if(selectionInfo){
            const sequencerNode = selectionInfo.startSequencerNodeModel;
            this.model.deleteSequencerNode(sequencerNode);
        }
    },

    onDeleteMusicWrapperTriggered: function(){
        const selectionInfo = this.view.getSelectionNode();
        if(selectionInfo){
            const startSequencerNodeModel = selectionInfo.startSequencerNodeModel;
            const endSequencerNodeModel = selectionInfo.endSequencerNodeModel;
            if(startSequencerNodeModel == endSequencerNodeModel){
                this.model.deleteMusicWrapNodeCreated(startSequencerNodeModel);
                this.view.unselectCurrentlySelectedSequencerNodes();
            }
        }
    },

    onRevertDeleteNodeCreated : function(deletedSequencerNode){
        if(deletedSequencerNode){
            this.model.revertDeletedNodeCreation(deletedSequencerNode);
        }
    },

    onUndoOperationTriggered: function(){
        if(this.model.getHistoryManagement().canUndoOperation()){
            this.model.getHistoryManagement().undo();
        }
    },

    onRedoOperationTriggered: function(){
        if(this.model.getHistoryManagement().canRedoOperation()){
            this.model.getHistoryManagement().redo();
        }

    },

    stopPlaybackIfApplicable : function(){
        return this.model.stopPlaybackIfApplicable();
    },

    playPauseSequencer : function(){
        return this.model.playPauseSequencerRendering();
    },

    playFromPositionInMilliseconds : function(timeInMilliseconds){
        this.model.playSequencerRenderingFromPositionInMilliseconds(timeInMilliseconds);
    },

    updatePlaybackPositionInMilliseconds : function(timeInMilliseconds){
        this.model.updatePlaybackPositionInMilliseconds(timeInMilliseconds);
    },

    deleteAllFillerWords : function(){
        this.model.deleteAllFillerWords();
    },


    trimSilences : function(maxSilenceInMilliseconds){
        this.model.trimSilences(maxSilenceInMilliseconds);
    },


    setHeightMargin : function(heightMargin){
        this.view.setHeightMargin(heightMargin);
    },

    onShowHelpTriggered : function(){
        //PersistentModels.getInstance().getRouter().showHelpAndFeedback(null, true);
        PersistentModels.getInstance().getRouter().showSequencerHelpContextPopup(this.model, this.view.getShowHelpMenuBtn$el(), SequencerHelpContextOptionController);
    },

    

    /*onDeleteAudioSequencerNodeTriggered : function(){
        const selectionInfo = this.view.getSelectionNode();
        if(selectionInfo){
            const startSequencerNodeModel = selectionInfo.startSequencerNodeModel;
            const endSequencerNodeModel = selectionInfo.endSequencerNodeModel;
            if(startSequencerNodeModel == endSequencerNodeModel){
                this.model.deleteAudioSequencerNodeCreated(startSequencerNodeModel);
                this.view.unselectCurrentlySelectedSequencerNodes();
            }
        }
    },*/

    getHasVideoSegment : function(){
        return this.model.getHasVideoSegment();
    },

    getProjectId: function(){
        return this.model.getProjectId();
    },	

    saveProject : function(){
        return this.model.saveProject();
    },


    uploadFileAndInsertBefore : async function(file, sequencerNodeTargetToInsertBefore){
        const isVideo =  await Utils.getInstance().isVideoFile(file);
        const actionToExecute =  async(progressReportFunction)=>{
            return await FileUploadHelper.getInstance().showUploadDialogAndUploadFileToSoundLibrary(file, null, true, isVideo, true, progressReportFunction);
        }
        const trebbleAudioOrVideJSON = await PersistentModels.getInstance().getRouter().executeFunctionAndShowProgressBarPopup (actionToExecute,`${window.getI18n(ti18n,"UPLOADING_YOUR_FILE")}...`);
        if(trebbleAudioOrVideJSON){
            if(isVideo){
                return this.insertVideoMode(sequencerNodeTargetToInsertBefore, true, new TrebbleVideo(trebbleAudioOrVideJSON));
            }else{
                return this.insertAudioMode(sequencerNodeTargetToInsertBefore, true, new TrebbleAudio(trebbleAudioOrVideJSON));
            }
        }  
    },

    uploadFileAndInsertAfter : async function(file, sequencerNodeTargetToInsertAfter){
        const isVideo =  await Utils.getInstance().isVideoFile(file);
        const actionToExecute =  async(progressReportFunction)=>{
            return await FileUploadHelper.getInstance().showUploadDialogAndUploadFileToSoundLibrary(file, null, true, isVideo, true, progressReportFunction);
        }
        const trebbleAudioOrVideJSON = await PersistentModels.getInstance().getRouter().executeFunctionAndShowProgressBarPopup (actionToExecute,`${window.getI18n(ti18n,"UPLOADING_YOUR_FILE")}...`);
        if(trebbleAudioOrVideJSON){
            if(isVideo){
                return this.insertAudioMode(sequencerNodeTargetToInsertAfter, false, new TrebbleVideo(trebbleAudioOrVideJSON));
            }else{
                return this.insertAudioMode(sequencerNodeTargetToInsertAfter, false, new TrebbleAudio(trebbleAudioOrVideJSON));
            }
        }
    },

    uploadFileAndInsertAtTheEnd : async function(file, sequencerNodeTargetToInsertBefore){
        const isVideo =  await Utils.getInstance().isVideoFile(file);
        const actionToExecute =  async(progressReportFunction)=>{
            return await FileUploadHelper.getInstance().showUploadDialogAndUploadFileToSoundLibrary(file, null, true, isVideo, true, progressReportFunction);
        }
        const trebbleAudioOrVideJSON = await PersistentModels.getInstance().getRouter().executeFunctionAndShowProgressBarPopup (actionToExecute,`${window.getI18n(ti18n,"UPLOADING_YOUR_FILE")}...`);
        if(trebbleAudioOrVideJSON){
            if(isVideo){
                return this.insertAudioMode(this.model.getLastNode(), false, new TrebbleVideo(trebbleAudioOrVideJSON));
            }else{
                return this.insertAudioMode(this.model.getLastNode(), false, new TrebbleAudio(trebbleAudioOrVideJSON));
            }
        }
    },

    uploadFileAndWrapSelection : async function(file){
        const isVideo =  await Utils.getInstance().isVideoFile(file);
        const actionToExecute =  async(progressReportFunction)=>{
            return await FileUploadHelper.getInstance().showUploadDialogAndUploadFileToSoundLibrary(file, null, true, isVideo, true, progressReportFunction);
        }
        const trebbleAudioOrVideJSON = await PersistentModels.getInstance().getRouter().executeFunctionAndShowProgressBarPopup (actionToExecute,`${window.getI18n(ti18n,"UPLOADING_YOUR_FILE")}...`);
        if(trebbleAudioOrVideJSON){
            if(isVideo){
                return this.wrapSelectionWithMusic(new TrebbleVideo(trebbleAudioOrVideJSON));
            }else{
                return this.wrapSelectionWithMusic(new TrebbleAudio(trebbleAudioOrVideJSON));
            }
        }
    },


    copySelectionToClipboard : function(){
        const currentSelectionInfo =  this.view.getSelectionNode();
        if(currentSelectionInfo && currentSelectionInfo.startSequencerNodeModel && currentSelectionInfo.endSequencerNodeModel){
            if(Utils.getInstance().doesSelectedNodesContainOrphanWrapNode(currentSelectionInfo.startSequencerNodeModel, currentSelectionInfo.endSequencerNodeModel)){
                return this.view.onCopySelectionCommandeInterruptedBecauseOfWrapOrphanNode();
            }else{
                const cloneStartNode = SequencerNodeCloner.getInstance().cloneLinkedSequencerNodes(currentSelectionInfo.startSequencerNodeModel, currentSelectionInfo.endSequencerNodeModel);
                let cloneEndNode = null;
                let nextNode = cloneStartNode;
                let reachLastNode = false;
                const sequencerNodeToAdd = currentSelectionInfo.startSequencerNodeModel;
                while(!reachLastNode){
                    if(nextNode.getNext()){
                        nextNode = nextNode.getNext()
                    }else{
                        reachLastNode = true;
                        cloneEndNode = nextNode;
                    }
                }
                return this.model.updateClipboard(cloneStartNode,cloneEndNode,  true);
            }
        }else{
            //Do nothing
        }
    },
    setNoiseCancellation: function(on, doNoRecordInHistory){
        this.model.setNoiseCancellation(on,doNoRecordInHistory);
    },

    setMagicSoundEnhancer: function(on, doNoRecordInHistory){
        this.model.setMagicSoundEnhancer(on,doNoRecordInHistory);
    },

    pasteClipboardBeforeSelection :function(){
        const currentSelectionInfo =  this.view.getSelectionNode();
        if(currentSelectionInfo && currentSelectionInfo.startSequencerNodeModel){
            return this.model.pasteClipboard(currentSelectionInfo.startSequencerNodeModel, true);
        }else{
            if(this.model.getNumberOfVisibleSequencerNodes() == 0){
                return this.model.pasteClipboard(null, true);
            }
            //Do nothing
        }
    },

    cutAndCopySelectionToClipboard : function(){
        const currentSelectionInfo =  this.view.getSelectionNode();
        if(currentSelectionInfo && currentSelectionInfo.startSequencerNodeModel && currentSelectionInfo.endSequencerNodeModel){
            if(Utils.getInstance().doesSelectedNodesContainOrphanWrapNode(currentSelectionInfo.startSequencerNodeModel, currentSelectionInfo.endSequencerNodeModel)){
                return this.view.onCopySelectionCommandeInterruptedBecauseOfWrapOrphanNode();
            }else{
                return this.model.cutAndCopyToClipboard(currentSelectionInfo.startSequencerNodeModel, currentSelectionInfo.endSequencerNodeModel);
            }
        }else{
            //Do nothing
        }
    },

    showContextMenuForCorrectingTranscriptsIfApplicable:function(){
        return this.view.showContextMenuForCorrectingTranscriptsIfApplicable();
    },

    showContextMenuForCorrectingTranscripts : function() {
        return this.view.showContextMenuForCorrectingTranscripts();
    },

    showMoreOptionMenu : function(moreButtonButton$el, onSubMenuClosed, popupPosition){
        const onMenuClose = (function(){
            this._moreOptionMenuController = null;
            if(onSubMenuClosed){
                onSubMenuClosed();
            }
        }).bind(this);
        const onMenuItemClicked = (function(){
            this.view._destroyContextMenuDisplayed();
        }).bind(this);
        this.view._destroyContextMenuDisplayed(true);
        return  PersistentModels.getInstance().getRouter().showSequencerNodeMoreOption(this, moreButtonButton$el, onMenuClose, onMenuItemClicked, popupPosition, SequencerNodeMoreOptionController).then((function(moreOptionMenuController){
            this._moreOptionMenuController = moreOptionMenuController
            this.view.setSubContextMenuView(this._moreOptionMenuController.getView());
        }).bind(this));
    },

    isClippingActionAvailable : function(){
        return this.view.isClippingActionAvailable();
    },

    isCreateClipActionAvailable : function(){
        return this.view.isCreateClipActionAvailable();
    },

    isCutActionAvailable : function(){
        return this.view.isCutActionAvailable();
    },

    isCopyActionAvailable : function(){
        return this.view.isCopyActionAvailable();
    },

    isPasteActionAvailable : function(){
        return this.view.isPasteActionAvailable();
    },

    isTranscriptCorrectionAvailable : function(){
        //return this.view.isTranscriptCorrectionAvailable();
        return true;
    },

    isDownloadClipAvailable : function(){
        return true;
    },

    isDeleteActionAvailable : function(){
        return this.view.isDeleteActionAvailable();
    },

    isWrapWithBackgroundMusicActionAvailable : function(){
        return this.view.isWrapWithBackgroundMusicActionAvailable();
    },

    isPlayFromHereActionAvailable : function(){
        return this.view.isPlayFromHereActionAvailable();
    },

    isInsertActionAvailable : function(){
        return this.view.isInsertActionAvailable();
    },

    isMoreOptionAvailable : function(){
        return this.view.isMoreOptionAvailable();
    },

    onContextMenuDestroyed : function(){
        if(this._moreOptionMenuController){
            this._moreOptionMenuController.close();
        }
    },



    onSearchTurnedOn : function(){
        this.model.setSearchOn(true);
    },

    onShowNextNodeFoundInSearch : function(){
        this.model.showNextSequencerNodeFoundInSearch();
    },

    onShowPreviousNodeFoundInSearch : function(){
        this.model.showPreviousSequencerNodeFoundInSearch();
    },

    clearSequencer: function(clearBlobCache){
        this.model.clearSequencer(clearBlobCache);
    },

    onExitCurrentMode : function(){
        if(this.model.isInSearchMode()){
            this.model.clearSearch();
        }
    },

    onSearchInputChange : function(){
        if(this.model.isInSearchMode()){
            this.model.applyTextSearch(this.view.getSearchInput());
        }
    },

    onTurnSearchPlaybackOn : function(){
        if(this.model.isInSearchMode()){
            this.model.setPlaybackOn(true);
        }
    },

    onTurnSearchPlaybackOff : function(){
        if(this.model.isInSearchMode()){
            this.model.setPlaybackOn(false);
        }
    },

    onPlayfromNode : function(nodeToPlayFrom, stopAfterMilliseconds, nodeToStopPlayAt, arrayOfSequencerNodesToIgnore){
        const shouldSequencerNodeBePlayedValidatorFunction = (sequencerNode, nodeSchedulingInfo)=>{
            if(arrayOfSequencerNodesToIgnore){
                return !arrayOfSequencerNodesToIgnore.includes(sequencerNode);
            }
            return true;
        }
        this.model.playPauseSequencerRenderingFromNode(nodeToPlayFrom, true, stopAfterMilliseconds, nodeToStopPlayAt, shouldSequencerNodeBePlayedValidatorFunction);
    },

    setAutoSaveOn : function(autoSaveOn){
        this.model.setAutoSaveOn(autoSaveOn);
    },
    
    
    startVideoRenderingInCloudForLaterDownload : async function(sequencerNodeToStartFrom, sequencerNodeToEndOn , audioUrlInCloud, capsuleId, useTemporaryLocation, showProgressDialog){

        let progressBarPopupController = null;
        let isVideoSegmentRendered =  false;
        const progressReportFunction = function(progress, operationInProgress){
            if(showProgressDialog){
                if(progressBarPopupController){
                    let defaultProgresMessageOnUpload = isVideoSegmentRendered? window.getI18n(ti18n, "RENDERING_VIDEO_FILE") : window.getI18n(ti18n, "RENDERING_AUDIO");
                    if(progress){
                        progressBarPopupController.setIndeterminate(false);
                        progressBarPopupController.setProgressMessage(operationInProgress?operationInProgress:defaultProgresMessageOnUpload);
                        progressBarPopupController.setProgressSubMessage(window.getI18n(ti18n, "THIS_MIGHT_TAKE_A_FEW_MINUTES")+ " "+ window.getI18n(ti18n, "DO_NOT_REFRESH")+ " "+window.getI18n(ti18n, "PLEASE_HOLD_ON_TIGHT"));
                        progressBarPopupController.setPercentageProgress(Math.round(progress)+ "%");
                    }else{
                        progressBarPopupController.setIndeterminate(true);
                        progressBarPopupController.setProgressMessage(operationInProgress?operationInProgress:defaultProgresMessageOnUpload);
                        progressBarPopupController.setProgressSubMessage(window.getI18n(ti18n, "THIS_MIGHT_TAKE_A_FEW_MINUTES")+ " "+ window.getI18n(ti18n, "DO_NOT_REFRESH")+ " "+window.getI18n(ti18n, "PLEASE_HOLD_ON_TIGHT"));
                    }
                }
            }
        }

        if(showProgressDialog){
            PersistentModels.getInstance().getRouter().showProgressBarPopupController().then(function(popupController){
                progressBarPopupController = popupController;
                progressReportFunction();

            })
        }

        if(!audioUrlInCloud){
            isVideoSegmentRendered = false;
            const audioRenderedBlob = await this.model.getRenderedAudioBlobClip(true, sequencerNodeToStartFrom, sequencerNodeToEndOn, false, progressReportFunction);
            isVideoSegmentRendered = true;
            const {presignedUrl: presignedUrlForAudio, fileLocationUrl: fileLocationUrlForAudio} = await TrebbleClientAPIHelper.getInstance().getPresignedUrlForUploadingTemporaryAudioOrVideoFile("audio/wav");
            const uploadPogressFunction = (p)=>{return progressReportFunction(p*100, window.getI18n(ti18n, "PREPARING_AUDIO"))};
            await FileUploadHelper.getInstance().uploadBlobToS3UsignPreSignedUrlWithProgress(audioRenderedBlob, presignedUrlForAudio, uploadPogressFunction);
            audioUrlInCloud = fileLocationUrlForAudio;

        }
        const ffmpegParamsInfoArrayToRunOnServer = await this.createFFMPEGParamsByContatenationForServerRendering(sequencerNodeToStartFrom, sequencerNodeToEndOn,audioUrlInCloud );
        if(ffmpegParamsInfoArrayToRunOnServer){
            const serverAudioUrl = audioUrlInCloud;
            if(progressReportFunction){
                progressReportFunction(null);
            }
            const srtFileContent =  await this.getSrtText(sequencerNodeToStartFrom, sequencerNodeToEndOn, null, null, false);
            const {presignedUrl: presignedUrlForFFMPGECommands, fileLocationUrl: fileLocationUrlForFFMPGECommands} = useTemporaryLocation? await TrebbleClientAPIHelper.getInstance().getPresignedUrlForUploadingTemporaryAudioOrVideoFile("application/json") : await TrebbleClientAPIHelper.getInstance().getPresignedUrlForUploadingFFMPEGCommands();
            await FileUploadHelper.getInstance().uploadJSONToS3UsignPreSignedUrl(ffmpegParamsInfoArrayToRunOnServer,presignedUrlForFFMPGECommands);
            const {presignedUrl: presignedUrlForSrt, fileLocationUrl: fileLocationUrlForSrt} = useTemporaryLocation? await TrebbleClientAPIHelper.getInstance().getPresignedUrlForUploadingTemporaryAudioOrVideoFile("text/plain") : await TrebbleClientAPIHelper.getInstance().getPresignedUrlForUploadingSrtFile();
            await FileUploadHelper.getInstance().uploadStringToS3UsignPreSignedUrl(srtFileContent,presignedUrlForSrt);
            await TrebbleClientAPIHelper.getInstance().startVideoRenderingInCloud(capsuleId, this.getProjectId(), serverAudioUrl, fileLocationUrlForSrt, useTemporaryLocation, fileLocationUrlForFFMPGECommands);
            if(progressBarPopupController){
                progressBarPopupController.close();
            }
            window.alertSuccessMessage(window.getI18n(ti18n,"YOUR_VIDEO_IS_BEING_PROCESSED"),window.getI18n(ti18n, "WE_WILL_NOTIFY_WHEN_YOUR_VIDEO_IS_READY_TO_DOWNLOAD"));
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("audioEditor", "start video rendering in the cloud", "start video rendering in the cloud");
            }
        }
    
    },

    closeAudioContext: async function(){
        return await this.getModel().closeAudioContext();
    },

    downloadSelectionAsClip: function(){
        const currentSelectionInfo =  this.view.getSelectionNode();
        if(currentSelectionInfo && currentSelectionInfo.startSequencerNodeModel && currentSelectionInfo.endSequencerNodeModel){
            const hasVideoSegment =  this.model.getHasVideoSegment() && window.chrome && EDIT_VIDEO_FILES;
            if(hasVideoSegment && RENDER_VIDEO_IN_CLOUD){
                return this.startVideoRenderingInCloudForLaterDownload(currentSelectionInfo.startSequencerNodeModel, currentSelectionInfo.endSequencerNodeModel , null, null, true, true);
            }
            let progressBarPopupController = null;
            const defaultProgresMessageOnUpload = hasVideoSegment? window.getI18n(ti18n, "RENDERING_VIDEO_FILE") : window.getI18n(ti18n, "RENDERING_AUDIO");
            const progressReportFunction = function(progress, operationInProgress){
                if(progressBarPopupController){
                    if(progress){
                        progressBarPopupController.setIndeterminate(false);
                        progressBarPopupController.setProgressMessage(operationInProgress?operationInProgress:defaultProgresMessageOnUpload);
                        progressBarPopupController.setProgressSubMessage(window.getI18n(ti18n, "THIS_MIGHT_TAKE_A_FEW_MINUTES")+ " "+ window.getI18n(ti18n, "DO_NOT_REFRESH")+ " "+window.getI18n(ti18n, "PLEASE_HOLD_ON_TIGHT"));
                        progressBarPopupController.setPercentageProgress(Math.round(progress)+ "%");
                    }else{
                        progressBarPopupController.setIndeterminate(true);
                        progressBarPopupController.setProgressMessage(operationInProgress?operationInProgress:defaultProgresMessageOnUpload);
                        progressBarPopupController.setProgressSubMessage(window.getI18n(ti18n, "THIS_MIGHT_TAKE_A_FEW_MINUTES")+ " "+ window.getI18n(ti18n, "DO_NOT_REFRESH")+ " "+window.getI18n(ti18n, "PLEASE_HOLD_ON_TIGHT"));
                    }
                }
            }

            PersistentModels.getInstance().getRouter().showProgressBarPopupController().then(function(popupController){
                progressBarPopupController = popupController;
                progressReportFunction();

            })
            return this.model.getRenderedAudioBlobClip(true, currentSelectionInfo.startSequencerNodeModel, currentSelectionInfo.endSequencerNodeModel, hasVideoSegment, progressReportFunction).then((function(renderedBlob){
                const nameWithExtension = hasVideoSegment?  (new Date().getTime() +".mp4"):(new Date().getTime() +".wav");
                Utils.getInstance().downloadFile(renderedBlob, nameWithExtension);
                if(progressBarPopupController){
                    progressBarPopupController.close();
                }
            }).bind(this)).catch((function(){
                if(progressBarPopupController){
                    progressBarPopupController.close();
                }
            }).bind(this))
        }else{
            //Do nothing
        }
    },

    saveAsClip: function(){
        const currentSelectionInfo =  this.view.getSelectionNode();
        let fileNameEnterredByUser =  null;
        if(currentSelectionInfo && currentSelectionInfo.startSequencerNodeModel && currentSelectionInfo.endSequencerNodeModel){
            const hasVideoSegment =  this.model.getHasVideoSegment();
            let progressBarPopupController = null;
            let projectId = null;
            const defaultProgresMessageOnUpload = hasVideoSegment? window.getI18n(ti18n, "RENDERING_VIDEO_FILE") : window.getI18n(ti18n, "RENDERING_AUDIO");
            const progressReportFunction = function(progress, operationInProgress){
                if(progressBarPopupController){
                    if(progress){
                        progressBarPopupController.setIndeterminate(false);
                        progressBarPopupController.setProgressMessage(operationInProgress?operationInProgress:defaultProgresMessageOnUpload);
                        progressBarPopupController.setProgressSubMessage(window.getI18n(ti18n, "THIS_MIGHT_TAKE_A_FEW_MINUTES")+ " "+ window.getI18n(ti18n, "DO_NOT_REFRESH")+ " "+window.getI18n(ti18n, "PLEASE_HOLD_ON_TIGHT"));
                        progressBarPopupController.setPercentageProgress(Math.round(progress)+ "%");
                    }else{
                        progressBarPopupController.setIndeterminate(true);
                        progressBarPopupController.setProgressMessage(operationInProgress?operationInProgress:defaultProgresMessageOnUpload);
                        progressBarPopupController.setProgressSubMessage(window.getI18n(ti18n, "THIS_MIGHT_TAKE_A_FEW_MINUTES")+ " "+ window.getI18n(ti18n, "DO_NOT_REFRESH")+ " "+window.getI18n(ti18n, "PLEASE_HOLD_ON_TIGHT"));
                    }
                }
            }

            return FileUploadHelper.getInstance().collectFileName().then((function(fileName){
                if(fileName){
                    fileNameEnterredByUser = fileName;
                    return PersistentModels.getInstance().getRouter().showProgressBarPopupController();
                }

            }).bind(this)).then((async function(popupController){
                if(popupController && fileNameEnterredByUser){
                    progressBarPopupController = popupController;
                    progressReportFunction();
                    projectId = await this.deleteAllExceptSelectedNodesAndSaveThenUndo(currentSelectionInfo.startSequencerNodeModel, currentSelectionInfo.endSequencerNodeModel, "Save as clip");
                }
                
                if(popupController && fileNameEnterredByUser){
                    return this.model.getRenderedAudioBlobClip(true, currentSelectionInfo.startSequencerNodeModel, currentSelectionInfo.endSequencerNodeModel, hasVideoSegment, progressReportFunction);
                }
            }).bind(this)).then((function(renderedBlob){
                if(progressBarPopupController){
                    progressBarPopupController.close();
                }
                if(renderedBlob && fileNameEnterredByUser){
                    const fileToUpload  = hasVideoSegment? new File([renderedBlob], new Date().getTime() +".mp4", {"type":"video/mp4"}):  new File([renderedBlob], new Date().getTime() +".mp3", {"type":"audio/mpeg"});
                    
                    const actionToExecute =  async(progressReportFunction)=>{
                        const trebbleAudioJSON =  await FileUploadHelper.getInstance().showUploadDialogAndUploadFileToSoundLibrary(fileToUpload, fileNameEnterredByUser, true, hasVideoSegment, true, progressReportFunction);
                        const trebbleAudioModel = new TrebbleAudio(trebbleAudioJSON);
                        await TrebbleClientAPIHelper.getInstance().createCapsuleFromProjectIdAndAudioUrl(fileNameEnterredByUser, projectId, trebbleAudioModel.getAudioUrl());
                        return trebbleAudioJSON;
                    }
                    return PersistentModels.getInstance().getRouter().executeFunctionAndShowProgressBarPopup (actionToExecute,`${window.getI18n(ti18n,"UPLOADING_YOUR_CLIP")}...`);
                }
            }).bind(this)).catch((function(){
                if(progressBarPopupController){
                    progressBarPopupController.close();
                }
            }).bind(this))
        }else{
            //Do nothing
        }
    },

    createClip: async function(){
        const currentSelectionInfo =  this.view.getSelectionNode();
        if(currentSelectionInfo && currentSelectionInfo.startSequencerNodeModel && currentSelectionInfo.endSequencerNodeModel){
            const hasVideoSegment =  this.model.getHasVideoSegment();
            if(hasVideoSegment){
                const videoDataForClipCreation = await this.model.getPreparedVideoDataForClipCreation(currentSelectionInfo.startSequencerNodeModel, currentSelectionInfo.endSequencerNodeModel);
                const deleteEverythingExceptSelectionAndGetProjectIdFunction =  async ()=>{
                   return await this.deleteAllExceptSelectedNodesAndSaveThenUndo(currentSelectionInfo.startSequencerNodeModel, currentSelectionInfo.endSequencerNodeModel, "Clip Creation");
                }
                const createAudioAndSaveInCloudFunction = async(progressReportFunction)=>{
                    const audioRenderedBlob = await this.model.getRenderedAudioBlobClip(true, currentSelectionInfo.startSequencerNodeModel, currentSelectionInfo.endSequencerNodeModel, false, progressReportFunction);
                    const {presignedUrl: presignedUrlForAudio, fileLocationUrl: fileLocationUrlForAudio} = await TrebbleClientAPIHelper.getInstance().getPresignedUrlForUploadingTemporaryAudioOrVideoFile("audio/wav");
                    const uploadPogressFunction = (p)=>{return progressReportFunction(p*100, window.getI18n(ti18n, "PREPARING_AUDIO"))};
                    await FileUploadHelper.getInstance().uploadBlobToS3UsignPreSignedUrlWithProgress(audioRenderedBlob, presignedUrlForAudio, progressReportFunction? uploadPogressFunction: null);
                    return fileLocationUrlForAudio;
                }
                return await PersistentModels.getInstance().getRouter().showClipCreatorPopup(videoDataForClipCreation, deleteEverythingExceptSelectionAndGetProjectIdFunction, createAudioAndSaveInCloudFunction, this.model.getProjectId() || this.model.getLoadedProjectId());
            } 
        }
    },

    showSoundEnhancerSettings : function(){
        return PersistentModels.getInstance().getRouter().showSoundEnhancerSettingsPopupContoller(this.model, this.view.getSoundEnhancerButton$el(), SoundEnhancerSettingsPopupContoller);
    },

    showAutomatedOperationsMenu: function(){
        return PersistentModels.getInstance().getRouter().showSequencerAutomatedOperationsPopup(this.model, this.view.getShowAutomatedOperationsMenuBtn$el(), SequencerAutomatedOperationsOptionController);
    },

    shouldOnboardingBeDisplayed: function(){
        return AUDIO_EDITOR_ONBOARDING && !window.isMobileBrowser && (PersistentModels.getInstance().getRouter().didSignUpOccuredInCurrentSession() ||  !LocalStorageHelper.getInstance().isAudioEditorOnboardingWasCompletedOrDismissed());
    },

    displayAIEditSuggestionsByRequestId : async function(requestId){
        return await this.model.displayAIEditSuggestionsByRequestId(requestId);
    },

    showOnboardingIfApplicable: function(){
        if(this.shouldOnboardingBeDisplayed() && this.view){
            this.view.showOnboardingInstruction();
        }
    },
    onOnboardingTourCompleted :function(){
        LocalStorageHelper.getInstance().setAudioEditorOnboardingWasCompletedOrDismissed();
    },

    onOnboardingTourCancelled:function(){
        LocalStorageHelper.getInstance().setAudioEditorOnboardingWasCompletedOrDismissed();
    },

    showConfigureViewPopup : function(){
        return PersistentModels.getInstance().getRouter().showSequencerViewConfigSettingsPopupContoller(this.model, this.view.getConfigureViewBtn$el(), SequencerViewConfigSettingsPopupContoller);
    },

    playSearchSelectionAndSurrounding: function(){
        const selectionInfo = this.view.getSelectionNode();
        if(selectionInfo){
            const startSequencerNodeModel = selectionInfo.startSequencerNodeModel;
            const endSequencerNodeModel = selectionInfo.endSequencerNodeModel;
            const firstSequencerNodeToPlay = SequencerNodeUtils.getInstance().getPreviousNWordSequencerNode(startSequencerNodeModel, 4, true);
            const lastSequencerNodeToPlay = SequencerNodeUtils.getInstance().getNextNWordSequencerNode(endSequencerNodeModel, 4, true);
            this.model.playPauseSequencerRenderingFromNode(firstSequencerNodeToPlay, true, 0, lastSequencerNodeToPlay, null);
        }
    },

    playSurroundingWithoutSelection: function(){
        const selectionInfo = this.view.getSelectionNode();
        if(selectionInfo){
            const startSequencerNodeModel = selectionInfo.startSequencerNodeModel;
            const endSequencerNodeModel = selectionInfo.endSequencerNodeModel;
            const firstSequencerNodeToPlay = SequencerNodeUtils.getInstance().getPreviousNWordSequencerNode(startSequencerNodeModel, 4, true);
            const lastSequencerNodeToPlay = SequencerNodeUtils.getInstance().getNextNWordSequencerNode(endSequencerNodeModel, 4, true);
            const arrayOfSequencerNodesToIgnore = SequencerNodeUtils.getInstance().getArrayOfSequencerNodesFromStartToEnd(startSequencerNodeModel, endSequencerNodeModel);
            const shouldSequencerNodeBePlayedValidatorFunction = (sequencerNode, nodeSchedulingInfo)=>{
                if(arrayOfSequencerNodesToIgnore){
                    return !arrayOfSequencerNodesToIgnore.includes(sequencerNode);
                }
                return true;
            }
            this.model.playPauseSequencerRenderingFromNode(firstSequencerNodeToPlay, true, 0, lastSequencerNodeToPlay, shouldSequencerNodeBePlayedValidatorFunction);
        }
    },

    deleteSearchSelection : function(){
        this.onIgnoreActionTriggered();
    },

    deleteAllSearchSelection: function(){
        this.model.deleteAllSearchResults();
    },

    restoreDeleteNode: function(){
        const selectionInfo = this.view.getSelectionNode();
        if(selectionInfo){
            const startSequencerNodeModel = selectionInfo.startSequencerNodeModel;
            const endSequencerNodeModel = selectionInfo.endSequencerNodeModel;
            if(startSequencerNodeModel == endSequencerNodeModel && Utils.getInstance().isDeletedSequencerNodeInstance(startSequencerNodeModel)){
                this.model.revertDeletedNodeCreation(startSequencerNodeModel);
                this.view.unselectCurrentlySelectedSequencerNodes();
            }
        }
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                const params = {};
                params.model = this.model ;
                params.sequencerController = this;
                const promiseArray = [];
                this.view = new SequencerView(params);

                this.listenTo(this.view, "ignoreSelectedNodes", this.onIgnoreActionTriggered);
                this.listenTo(this.view, "wrapSelectedNodesWithMusic", this.onWrapWithMusicActionTriggered);
                this.listenTo(this.view, "playFrom", this.onPlayFromTriggered);
                this.listenTo(this.view, "deleteMusicWrapper", this.onDeleteMusicWrapperTriggered);
                this.listenTo(this.view, "showSettingsForMusicWrapper", this.showSettingsForMusicWrapperTriggered);
                this.listenTo(this.view, "playPauseSequencer", this.playPauseSequencer);
                this.listenTo(this.view, "playFromPositionInMilliseconds", this.playFromPositionInMilliseconds);
                this.listenTo(this.view, "updatePlaybackPositionInMilliseconds", this.updatePlaybackPositionInMilliseconds);
                
                this.listenTo(this.view, "revertDeleteNodeCreated", this.onRevertDeleteNodeCreated);
                this.listenTo(this.view, "undo", this.onUndoOperationTriggered);
                this.listenTo(this.view, "redo", this.onRedoOperationTriggered);
                this.listenTo(this.view, "showHelp", this.onShowHelpTriggered);
                this.listenTo(this.view, "insertAudioNodeTriggered", this.onInsertAudioNodeTriggered);
                this.listenTo(this.view, "insertVideoNodeTriggered", this.onInsertVideoNodeTriggered);
                //this.listenTo(this.view, "deleteAudioSequencerNodeTriggered", this.onDeleteAudioSequencerNodeTriggered);
                this.listenTo(this.view, "uploadFileAndInsertBefore", this.uploadFileAndInsertBefore);
                this.listenTo(this.view, "uploadFileAndInsertAfter", this.uploadFileAndInsertAfter);
                this.listenTo(this.view, "uploadFileAndInsertAtTheEnd", this.uploadFileAndInsertAtTheEnd);
                this.listenTo(this.view, "uploadFileAndWrapSelection", this.uploadFileAndWrapSelection);
                this.listenTo(this.view, "saveAsClipTriggered", this.saveAsClip);
                this.listenTo(this.view, "createClipTriggered", this.createClip);
                this.listenTo(this.view, "copySelectionToClipboard", this.copySelectionToClipboard);
                this.listenTo(this.view, "pasteClipboardBeforeSelection", this.pasteClipboardBeforeSelection);
                this.listenTo(this.view, "cutAndCopySelectionToClipboard", this.cutAndCopySelectionToClipboard);
                this.listenTo(this.view, "showMoreOptionMenu", this.showMoreOptionMenu);
                this.listenTo(this.view, "onContextMenuDestroyed", this.onContextMenuDestroyed);
                this.listenTo(this.view, "searchTurnedOn", this.onSearchTurnedOn);
                this.listenTo(this.view, "showNextNodeFoundInSearch", this.onShowNextNodeFoundInSearch);
                this.listenTo(this.view, "showPreviousNodeFoundInSearch", this.onShowPreviousNodeFoundInSearch);
                this.listenTo(this.view, "exitCurrentMode", this.onExitCurrentMode);
                this.listenTo(this.view, "searchInputChange", this.onSearchInputChange);
                this.listenTo(this.view, "turnSearchPlaybackOn", this.onTurnSearchPlaybackOn);
                this.listenTo(this.view, "turnSearchPlaybackOff", this.onTurnSearchPlaybackOff);
                this.listenTo(this.view, "playfromNode", this.onPlayfromNode);
                this.listenTo(this.view, "showSoundEnhancerSettings", this.showSoundEnhancerSettings);
                this.listenTo(this.view, "showAutomatedOperationsMenu", this.showAutomatedOperationsMenu);
                
                this.listenTo(this.view, "onboardingTourCompleted", this.onOnboardingTourCompleted);
                this.listenTo(this.view, "onboardingTourCancelled", this.onOnboardingTourCancelled);
                this.listenTo(this.view, "showConfigureViewPopup", this.showConfigureViewPopup);
                this.listenTo(this.view, "downloadClipTriggered", this.downloadSelectionAsClip);
                this.listenTo(this.view, "playSelectionAndSurroundings", this.playSearchSelectionAndSurrounding);
                this.listenTo(this.view, "playSurroundingsWithoutSelection", this.playSurroundingWithoutSelection);
                this.listenTo(this.view, "deleteSearchSelection", this.deleteSearchSelection);
                this.listenTo(this.view, "deleteAllSearchSelection", this.deleteAllSearchSelection);
                this.listenTo(this.view, "transcribedSequencerNode", this.transcribedSelectedMedia);
                this.listenTo(this.view, "deleteSequencerNodeTriggered", this.deleteSequencerNode);
                this.listenTo(this.view, "deleteAllExceptSelectedNodes", this.deleteAllExceptSelectedNodes);
                this.listenTo(this.view, "recordAndInsertTriggered", this.recordAndInsert);
                this.listenTo(this.view, "restoreDeleteNode", this.restoreDeleteNode);

                
                
                
                
                this.view.render();
                if(this._setTranscribedAudioBeingAddedWhenViewGetCreated){
                    this._setTranscribedAudioBeingAddedWhenViewGetCreated = false;
                    this.view.setTranscribedAudioBeingAdded(true);
                }
                
                this._sequencerNodeInspectorSectionController = new SequencerNodeInspectorSectionController({"sequencer":this.model, "sequencerView":this.view});	
                promiseArray.push(this._sequencerNodeInspectorSectionController.buildModelAndRenderView().then((function(){
                    this.view.initSequencerNodeInspectorView(this._sequencerNodeInspectorSectionController.getView());
                    this.showOnboardingIfApplicable();
                }).bind(this)));
                /*this._sequencerSettingsController = new SequencerSettingsController({"sequencerSettings":this.model.getSequencerSettings()});
                promiseArray.push(this._sequencerSettingsController.buildModelAndRenderView().then((function(){
                    this.view.initSequencerSettingsView(this._sequencerSettingsController.getView())
                }).bind(this)));*/
                    
                RSVP.Promise.all(promiseArray).then(resolve).catch(reject);


                } catch (error) {
                    reject(error);
                }

            }).bind(this));

    }

});

export default SequencerController;