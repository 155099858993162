import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import CordovaHelper from "models/helper/CordovaHelper";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import Utils from "models/helper/Utils";
import AudioBufferHelper from "models/audioEditor/AudioBufferHelper";
import ti18n from "i18n!nls/helperi18n";
import DropzoneAsync from "dropzonejs";
import RSVP from "rsvp";
import swal from 'sweetalert';

const IMAGE_MIME_TYPE = "image/*";
const AUDIO_MIME_TYPE = "audio/*";
const AUDIO_AND_VIDEO_MIME_TYPE = "audio/*,video/*";


const FileUploadHelper = Backbone.Model.extend({


    initialize: function() {


    },

    getPersistentFileSystemUrl: function() {
        return (window.cordova && window.cordova.file) ? (window.cordova.file.externalDataDirectory ? this._processNativeUrl(window.cordova.file.externalDataDirectory) : this._getFileSystemUrl(window.LocalFileSystem.TEMPORARY)) : null;
        //return this._processNativeUrl("");
    },

    getTemporaryFileSystemUrl: function() {
        return (window.cordova && window.cordova.file) ? (window.cordova.file.externalDataDirectory ? this._processNativeUrl(window.cordova.file.externalDataDirectory) : this._getFileSystemUrl(window.LocalFileSystem.TEMPORARY)) : null;
        //return this._processNativeUrl("");
    },

    _processNativeUrl: function(nativeUrl) {
        if (nativeUrl.indexOf("file:///") == 0) {
            return RSVP.Promise.resolve(nativeUrl.substr(("file://").length));
        } else {
            return RSVP.Promise.resolve(nativeUrl);
        }
    },

    _getFileSystemUrl: function(fileSystemName) {
        if (false /*device.platform == "iOS" || window.isIOSMobileDevice*/ ) {
            return RSVP.Promise.resolve("cdvfile://localhost/persistent/");
        } else {
            return new RSVP.Promise((function(resolve, reject) {
                const cbSuccess = function(fileSystem) {
                    try {
                        const nativeUrl = fileSystem.root.nativeURL;
                        if (nativeUrl.indexOf("file:///") == 0) {
                            resolve(nativeUrl.substr(("file://").length));
                        } else {
                            resolve(nativeUrl);
                        }
                    } catch (error) {
                        reject(error);
                    }
                }
                const cbFailure = function(error) {
                    reject(error);
                }
                window.requestFileSystem(fileSystemName, 0, cbSuccess, cbFailure);
            }).bind(this))
        }

    },

    isFilenameEndWith: function(filename, suffix) {
        return filename.indexOf(suffix, filename.length - suffix.length) !== -1;
    },

    deleteFileFromPersistentFileSystemUrl: function(relativeFilePath) {
        return this.deleteFileFromLocalFileSystemUrl(window.cordova.file.externalDataDirectory, relativeFilePath)
    },

    deleteFileFromLocalFileSystemUrl: function(uri, relativeFilePath) {
        if (window.device.platform == "iOS" || window.isIOSMobileDevice) {
            return RSVP.Promise.resolve();
        } else {
            return new RSVP.Promise((function(resolve, reject) {
                const cbSuccess = function(fileSystem) {
                    try {
                        fileSystem.getFile(relativeFilePath, {
                            create: false
                        }, function(fileEntry) {
                            fileEntry.remove(function(file) {
                                window.log("File " + relativeFilePath + " removed!");
                                resolve();
                            }, function(error) {
                                console.error("error deleting the file" + relativeFilePath + " " + error.code,error);
                                reject(window.getI18n(ti18n, "ERROR_DELETING_FILE") + " " + relativeFilePath + " " + error.code);
                            });
                        }, function() {
                            window.log("file does not exist. File :" + relativeFilePath);
                        });

                    } catch (error) {
                        reject(error);
                    }
                }
                const cbFailure = function(error) {
                    reject(error);
                }
                window.resolveLocalFileSystemURL(uri, cbSuccess, cbFailure);
            }).bind(this))
        }
    },

    deleteFileNew: function(localURL) {

        if(localURL){
            return new RSVP.Promise((function(resolve, reject) {
                const cbSuccess = function(fileEntry) {
                    if(fileEntry){
                        try {
                            fileEntry.remove(function(file) {
                                window.log("File " + localURL + " removed!");
                                resolve();
                            }, function(error) {
                                console.error("error deleting the file" + localURL + " " + error.code,error);
                                reject(window.getI18n(ti18n, "ERROR_DELETING_FILE") + " " + localURL + " " + error.code);
                            });

                        } catch (error) {
                            reject(error);
                        }
                    }else{
                        resolve()
                    }
                }
                const cbFailure = function(error) {
                    reject(error);
                }
                window.resolveLocalFileSystemURL(localURL, cbSuccess, cbFailure);
            }).bind(this))

        }else{
            return RSVP.Promise.resolve()
        }
    },

    deleteFile: function(fileSystemName, relativeFilePath) {
        if (window.device.platform == "iOS" || window.isIOSMobileDevice) {
            return RSVP.Promise.resolve();
        } else {
            return new RSVP.Promise((function(resolve, reject) {
                const cbSuccess = function(fileSystem) {
                    try {
                        fileSystem.root.getFile(relativeFilePath, {
                            create: false
                        }, function(fileEntry) {
                            fileEntry.remove(function(file) {
                                window.log("File " + relativeFilePath + " removed!");
                                resolve();
                            }, function(error) {
                                console.error("error deleting the file" + relativeFilePath + " " + error.code, error);
                                reject(window.getI18n(ti18n, "ERROR_DELETING_FILE") + " " + relativeFilePath + " " + error.code);
                            });
                        }, function() {
                            window.log("file does not exist. File :" + relativeFilePath);
                        });

                    } catch (error) {
                        reject(error);
                    }
                }
                const cbFailure = function(error) {
                    reject(error);
                }
                window.requestFileSystem(fileSystemName, 0, cbSuccess, cbFailure);
            }).bind(this))
        }
    },

    getMimeType: function(fileName) {
        /*if (this.isFilenameEndWith(fileName, ".mp3")) {
            return "audio/mpeg";
        }
        if (this.isFilenameEndWith(fileName, ".wav")) {
            return "audio/wav";
        }
        if (this.isFilenameEndWith(fileName, ".oga")) {
            return "audio/ogg";
        }
         if (this.isFilenameEndWith(fileName, ".aac")) {
            return "audio/aac";
        }*/
        
        const  fileExtension =  Utils.getInstance().getFileExtensionFromUrl(fileName);
        if(!fileExtension){
            return Utils.getInstance().getMimeTypeForFileExtenstion(fileExtension);
        }
    },

    _uploadFile: function(fileLocalURI, uploadUrl, fileName, fileKey, mimeType, params) {
        return new RSVP.Promise(function(resolve, reject) {
            if (window.FileTransfer) {
                try {
                    const ft = new window.FileTransfer();
                    const options = new window.FileUploadOptions();
                    options.fileName = fileName;
                    options.fileKey = "file";
                    options.mimeType = mimeType;
                    options.chunkedMode = false;
                    options.params = params;
                    if(mimeType){
                        options.headers = {"Content-Type": mimeType};
                    }
                    ft.upload(fileLocalURI, encodeURI(uploadUrl),
                        function(result) {
                            window.log("Uploading file  '" + fileLocalURI + "' to '" + uploadUrl + "' was successfull.");
                            resolve(result);
                        },
                        function(e) {
                            console.error("Failed uploading file  '" + fileLocalURI + "' to '" + uploadUrl + "'. Error:" + e);
                            reject(e);
                        }, options);
                } catch (error) {
                    reject(error);
                }
            } else {
                reject(window.getI18n(ti18n, "FILE_TRANSFER_IS_NOT_AVAILABLE"));
            }
        })
    },

    uploadBlobToS3UsignPreSignedUrl : function(blob, preSignedUrl){
        return new RSVP.Promise(function(resolve,reject){
            fetch(preSignedUrl, {
              method: 'PUT',
              body: blob,
              mode: 'cors'
          }).then(resolve).catch(reject)
        });
    },

    uploadBlobToS3UsignPreSignedUrlWithProgress : function(blob, preSignedUrl, progressCallback){
        return this.uploadToS3(blob, preSignedUrl, progressCallback);
    },

     createProgressStream: function(blob, progressCallback) {
        return new ReadableStream({
            start(controller) {
                const reader = blob.stream().getReader();
                let totalBytesRead = 0;
    
                function read() {
                    reader.read().then(({ done, value }) => {
                        if (done) {
                            controller.close();
                            return;
                        }
    
                        totalBytesRead += value.byteLength;
                        // Calculate progress
                        const progress = totalBytesRead / blob.size;
                        // Report progress via callback
                        if (progressCallback) {
                            progressCallback(progress);
                        }
                        controller.enqueue(value);
                        read();
                    }).catch(error => {
                        controller.error(error);
                    });
                }
    
                read();
            }
        });
    },

     uploadToS3: function(blob, preSignedUrl, progressCallback) {
        return new Promise(function(resolve, reject) {
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', preSignedUrl, true);
            xhr.onload = function() {
                if (xhr.status === 200) {
                    resolve(xhr.response);
                } else {
                    reject(new Error('Failed to upload'));
                }
            };
            xhr.onerror = function() {
                reject(new Error('Failed to upload'));
            };
            xhr.upload.onprogress = function(event) {
                if (event.lengthComputable) {
                    const progress = event.loaded / event.total;
                    if (progressCallback) {
                        progressCallback(progress);
                    }
                }
            };
            xhr.send(blob);
        });
    },

    uploadStringToS3UsignPreSignedUrl : function(stringToSave, preSignedUrl){
        return new RSVP.Promise(function(resolve,reject){
            const blobData = new Blob([stringToSave], {type: "text/plain"});
            fetch(preSignedUrl, {
              method: 'PUT',
              body: blobData,
              mode: 'cors'
          }).then(resolve).catch(reject)
        });
    },


    uploadJSONToS3UsignPreSignedUrl : function(jsonToSave, preSignedUrl){
        return new RSVP.Promise(function(resolve,reject){
            const blobData = new Blob([JSON.stringify(jsonToSave)], {type: "application/json"});
            fetch(preSignedUrl, {
              method: 'PUT',
              body: blobData,
              mode: 'cors'
          }).then(resolve).catch(reject)
        });
    },

    deleteAllTemporaryDownloadedFiles : function(){
        const remoteFileToLocalFileURLMap = LocalStorageHelper.getInstance().getLocalFileURLsDownloadedToDeleteMap();
        const promises = [];
        if(remoteFileToLocalFileURLMap){
            for(let remoteFile in remoteFileToLocalFileURLMap){
                const localFile = remoteFileToLocalFileURLMap[remoteFile];
                promises.push(this.deleteFileNew(localFile).then((function(){
                 return LocalStorageHelper.getInstance().removeLocalFileURLDownloadedToDelete(remoteFile);
             }).bind(this)).catch(function(error){
                    console.error("error deleting "+localFile+". Error:"+error);
                    console.error("clearing the local storage anyway for "+remoteFile);
                    return LocalStorageHelper.getInstance().removeLocalFileURLDownloadedToDelete(remoteFile);
                }))
            }
        }
        return RSVP.Promise.all(promises);
    },

    getLocalFileURLDownloadedTemporary : function(remoteUrl){
        const fileMap = LocalStorageHelper.getInstance().getLocalFileURLsDownloadedToDeleteMap();
        if(fileMap){
            return fileMap[remoteUrl];
        }

    },

    downloadFileLocallyTemporary :function(fileURLToDownload){
        if(fileURLToDownload){
            const remoteFileToLocalFileURLMap = LocalStorageHelper.getInstance().getLocalFileURLsDownloadedToDeleteMap();
            if(remoteFileToLocalFileURLMap && remoteFileToLocalFileURLMap[fileURLToDownload]){
                return RSVP.Promise.resolve(remoteFileToLocalFileURLMap[fileURLToDownload]);
            }
            let localDownloadFileUrl = null;
            return this._getFileSystemUrl(window.LocalFileSystem.TEMPORARY).then((function(localFileSystemURL){
                localDownloadFileUrl = localFileSystemURL + (new Date()).getTime(); 
            }).bind(this)).then((function(){
                return new RSVP.Promise((function(resolve, reject) {
                    if (window.FileTransfer) {
                        try {
                            const fileTransfer = new window.FileTransfer();
                            fileTransfer.download(
                                fileURLToDownload,
                                localDownloadFileUrl,
                                function(downloadedFile) {
                                    LocalStorageHelper.getInstance().addLocalFileURLDownloadedToDelete(fileURLToDownload, downloadedFile.toURL());
                                    resolve(downloadedFile.toURL());
                                },
                                function(error) {              
                                    reject(error)
                                }
                                );


                        } catch (error) {
                            reject(error);
                        }
                    } else {
                        reject(window.getI18n(ti18n, "FILE_TRANSFER_IS_NOT_AVAILABLE"));
                    }
                }).bind(this))
            }).bind(this));
        }else{
            return RSVP.Promise.resolve();
        }
    },


    uploadAudioHigightsForSong: function(audioHighightFilenameInfo, capsuleIdOrSongUri, isCapsuleReply,functionNameToRetrieveUploadSignature) {

        return this._uploadAudioHigightsForSongRecursive(0, audioHighightFilenameInfo, capsuleIdOrSongUri, {}, [], isCapsuleReply, functionNameToRetrieveUploadSignature).then((function(fileUploadInfoArray) {
            return fileUploadInfoArray;
        }).bind(this));
    },

    _buildFileUploadInfo: function(uri, locationKey, locationEtag, duration, uploadDate, size, originalFilename, isVideo) {
        const fileUploadInfo = {};
        fileUploadInfo.uri = uri;
        fileUploadInfo.locationKey = locationKey;
        fileUploadInfo.locationEtag = locationEtag;
        fileUploadInfo.duration = duration;
        fileUploadInfo.uploadDate = uploadDate;
        fileUploadInfo.originalFilename = originalFilename;
        fileUploadInfo.isVideo = isVideo;
        fileUploadInfo.size = size;
        return fileUploadInfo;
    },

    _updateDurationOnUploadFileInfo : function(fileUploadInfo){
        if(fileUploadInfo && fileUploadInfo.uri && !fileUploadInfo.duration){
            return Utils.getInstance().getAudioDuration(fileUploadInfo.uri).catch((function(error){
                return AudioBufferHelper.getInstance().fetchAudioAndGetAudioDuration(fileUploadInfo.uri);
            }).bind(this)).then((function(durationInSeconds){
                if(durationInSeconds && isFinite(durationInSeconds)){
                    fileUploadInfo.duration =  durationInSeconds * 1000;
                }
            }).bind(this))
        }
        return RSVP.Promise.resolve();

    },

    _uploadAudioHigightsForSongRecursive: function(filenameIndex, audioHighightFilenameInfo, capsuleIdOrSongUri, filenameToFinalFileUploadMap, fileUploadInfoArray, isCapsuleReply, functionNameToRetrieveUploadSignature) {
        let fileUploadInfo = null;
        if (audioHighightFilenameInfo && audioHighightFilenameInfo.length > 0 && filenameIndex < audioHighightFilenameInfo.length) {
            const fileNameInfo = audioHighightFilenameInfo[filenameIndex];
            const fileName = fileNameInfo.name;
            const fileLocalUrl = fileNameInfo.url;
            let apiMethodToCall = "getSignatureForCapsuleUpload";
            if (isCapsuleReply) {
                apiMethodToCall = "getSignatureForCapsuleReplyUpload";
            }
            if (!capsuleIdOrSongUri) {
                apiMethodToCall = "getSignatureForCapsuleWithNoSongUpload";
            }
            if(functionNameToRetrieveUploadSignature){
                apiMethodToCall = functionNameToRetrieveUploadSignature;
            }
            return TrebbleClientAPIHelper.getInstance()[apiMethodToCall](fileName, capsuleIdOrSongUri).then((function(result) {
                const signature = result.signature;
                const uploadUrl = result.uploadUrl;
                const fileKey = result.filenameKey;
                filenameToFinalFileUploadMap[fileName] = uploadUrl;
                const completeFileUrl = uploadUrl + fileKey;
                fileUploadInfo = this._buildFileUploadInfo(completeFileUrl, fileKey, null, null, new Date(), null);
                fileUploadInfoArray.push(fileUploadInfo);
                return this._uploadFile(fileLocalUrl, uploadUrl, fileName, fileKey, this.getMimeType(fileName), signature);

            }).bind(this)).then((function(uploadResult) {
                return this._updateDurationOnUploadFileInfo(fileUploadInfo);
            }).bind(this)).then((function() {
                return this._uploadAudioHigightsForSongRecursive(filenameIndex + 1, audioHighightFilenameInfo, capsuleIdOrSongUri, filenameToFinalFileUploadMap, fileUploadInfoArray);
            }).bind(this))
        } else {
            return RSVP.Promise.resolve(fileUploadInfoArray);
        }

    },

    uploadCoverArtForTrebble: function(filenameLocationUrl, trebbleId) {
        let fileUploadInfo = null;
        if (filenameLocationUrl && trebbleId) {
            const fileName = "trebbleCoverArt_" + (new Date()).getTime() + ".jpg";
//filenameLocationUrl.substr(filenameLocationUrl.lastIndexOf("/")+1);
const fileLocalUrl = filenameLocationUrl;
let completeUploadUrl = null;
return TrebbleClientAPIHelper.getInstance().getSignatureForTrebbleCoverArtUpload(fileName, trebbleId).then((function(result) {
    const signature = result.signature;
    const uploadUrl = result.uploadUrl;
    const fileKey = result.filenameKey;
    const completeFileUrl = uploadUrl + fileKey;
    completeUploadUrl = completeFileUrl;
    fileUploadInfo = this._buildFileUploadInfo(completeFileUrl, fileKey, null, null, new Date());
    return this._uploadFile(fileLocalUrl, uploadUrl, fileName, fileKey, "image/jpeg", signature);
}).bind(this)).then((function() {
    return completeUploadUrl;
}).bind(this));
} else {
return RSVP.Promise.resolve();
}

},

uploadSongAsTrebbleSweeper: function(songModel, trebbleId) {
    let fileUploadInfo = null;
if (songModel && trebbleId) {
const fileName = "trebbleSweeper_" + (new Date()).getTime() + ".mp3";
let fileLocalUrl = null;
let completeUploadUrl = null;
return this.getSongUrlForUpload(songModel).then((function(url) {
    fileLocalUrl = url;
    return TrebbleClientAPIHelper.getInstance().getSignatureForTrebbleSweeperUpload(fileName, trebbleId);
}).bind(this)).then((function(result) {
    const signature = result.signature;
    const uploadUrl = result.uploadUrl;
    const fileKey = result.filenameKey;
    const completeFileUrl = uploadUrl + fileKey;
    completeUploadUrl = completeFileUrl;
    fileUploadInfo = this._buildFileUploadInfo(completeFileUrl, fileKey, null, null, new Date());
    return this._uploadFile(fileLocalUrl, uploadUrl, fileName, fileKey, "audio/mpeg", signature);
}).bind(this)).then((function() {
    return this._updateDurationOnUploadFileInfo(fileUploadInfo);
}).bind(this)).then((function() {
    return completeUploadUrl;
}).bind(this));
} else {
return RSVP.Promise.resolve();
}
},

uploadSongAsTrebbleGreater: function(songModel, trebbleId) {
    let fileUploadInfo = null;
if (songModel && trebbleId) {
const fileName = "trebbleGreater_" + (new Date()).getTime() + ".mp3";
let fileLocalUrl = null;
let completeUploadUrl = null;
return this.getSongUrlForUpload(songModel).then((function(url) {
    fileLocalUrl = url;
    return TrebbleClientAPIHelper.getInstance().getSignatureForTrebbleGreaterUpload(fileName, trebbleId);
}).bind(this)).then((function(result) {
    const signature = result.signature;
    const uploadUrl = result.uploadUrl;
    const fileKey = result.filenameKey;
    const completeFileUrl = uploadUrl + fileKey;
    completeUploadUrl = completeFileUrl;
    fileUploadInfo = this._buildFileUploadInfo(completeFileUrl, fileKey, null, null, new Date());
    return this._uploadFile(fileLocalUrl, uploadUrl, fileName, fileKey, "audio/mpeg", signature);
}).bind(this)).then((function() {
    return this._updateDurationOnUploadFileInfo(fileUploadInfo);
}).bind(this)).then((function() {
    return completeUploadUrl;
}).bind(this));
} else {
return RSVP.Promise.resolve();
}
},

uploadSongAsTrebbleIntro: function(songModel, trebbleId) {
    let fileUploadInfo = null;
if (songModel && trebbleId) {
const fileName = "trebbleIntro_" + (new Date()).getTime() + ".mp3";
let fileLocalUrl = null;
let completeUploadUrl = null;
return this.getSongUrlForUpload(songModel).then((function(url) {
    fileLocalUrl = url;
    return TrebbleClientAPIHelper.getInstance().getSignatureForTrebbleIntroUpload(fileName, trebbleId);
}).bind(this)).then((function(result) {
    const signature = result.signature;
    const uploadUrl = result.uploadUrl;
    const fileKey = result.filenameKey;
    const completeFileUrl = uploadUrl + fileKey;
    completeUploadUrl = completeFileUrl;
    fileUploadInfo = this._buildFileUploadInfo(completeFileUrl, fileKey, null, null, new Date());
    return this._uploadFile(fileLocalUrl, uploadUrl, fileName, fileKey, "audio/mpeg", signature);
}).bind(this)).then((function() {
    return this._updateDurationOnUploadFileInfo(fileUploadInfo);
}).bind(this)).then((function() {
    return completeUploadUrl;
}).bind(this));
} else {
return RSVP.Promise.resolve();
}
},

uploadSongAsTrebbleOutro: function(songModel, trebbleId) {
    let fileUploadInfo = null;
if (songModel && trebbleId) {
const fileName = "trebbleOutro_" + (new Date()).getTime() + ".mp3";
let fileLocalUrl = null;
let completeUploadUrl = null;
return this.getSongUrlForUpload(songModel).then((function(url) {
    fileLocalUrl = url;
    return TrebbleClientAPIHelper.getInstance().getSignatureForTrebbleOutroUpload(fileName, trebbleId);
}).bind(this)).then((function(result) {
    const signature = result.signature;
    const uploadUrl = result.uploadUrl;
    const fileKey = result.filenameKey;
    const completeFileUrl = uploadUrl + fileKey;
    completeUploadUrl = completeFileUrl;
    fileUploadInfo = this._buildFileUploadInfo(completeFileUrl, fileKey, null, null, new Date());
    return this._uploadFile(fileLocalUrl, uploadUrl, fileName, fileKey, "audio/mpeg", signature);
}).bind(this)).then((function() {
   return this._updateDurationOnUploadFileInfo(fileUploadInfo);
}).bind(this)).then((function() {
return completeUploadUrl;
}).bind(this));
} else {
return RSVP.Promise.resolve();
}
},

getSongUrlForUpload: function(songModel) {
if (window.device.platform == "iOS" || window.isIOSMobileDevice) {
const songUri = songModel.get("uri");
const destinationFile = "songCopy" + (new Date()).getTime() + ".mp3";
return CordovaHelper.getInstance().executePluginOperation("MusicFileReaderPlugin", "downloadAudioFileToDocument", [songUri, destinationFile]).then((function(result) {
    if (result && result.success) {
        return result.destinationFile;
    } else {
        //throw "Something went wrong file copying audio file to Document directory;
    }

}).bind(this));
} else {
return RSVP.Promise.resolve(songModel.get("uri"));
}
},

uploadUserProfileImage: function(filenameLocationUrl) {
if (filenameLocationUrl) {
const fileName = "userProfileImage_" + (new Date()).getTime() + ".jpg";
//filenameLocationUrl.substr(filenameLocationUrl.lastIndexOf("/")+1);
const fileLocalUrl = filenameLocationUrl;
let completeUploadUrl = null;
return TrebbleClientAPIHelper.getInstance().getSignatureForUserProfileImageUpload(fileName).then((function(result) {
    const signature = result.signature;
    const uploadUrl = result.uploadUrl;
    const fileKey = result.filenameKey;
    const completeFileUrl = uploadUrl + fileKey;
    completeUploadUrl = completeFileUrl;
    const fileUploadInfo = this._buildFileUploadInfo(completeFileUrl, fileKey, null, null, new Date());
    return this._uploadFile(fileLocalUrl, uploadUrl, fileName, fileKey, "image/jpeg", signature);
}).bind(this)).then((function() {
    return completeUploadUrl;
}).bind(this));
} else {
return RSVP.Promise.resolve();
}
},

collectFileName : function(){
return new RSVP.Promise((function(resolve, reject){
try{
  const onConfirmButtonClicked = (function(inputValue){
    resolve(inputValue);
}).bind(this)

  const isInputValueValidFunction = function(value){
    return !!value;
}
const onCancelButtonClicked = function(){
    resolve(null);
}
Utils.getInstance().showDialogToCollectInput(onConfirmButtonClicked,onCancelButtonClicked, isInputValueValidFunction, window.getI18n(ti18n, "ENTER_THE_NAME_OF_THE_CLIP"), window.getI18n(ti18n, "YOU_WILL_BE_ABLE_TO_REUSE_YOUR_SAVED_CLIP"), window.getI18n(ti18n, "CANCEL"), window.getI18n(ti18n, "SAVE_CLIP"), window.getI18n(ti18n, "NAME_OF_THE_CLIP"), "");
}catch(error){
reject(error);
}
}).bind(this))

},



collectClipNameAndUploadFileToClipLibrary : function(filePromiseOrFile, isVideo){
const onConfirmButtonClicked = (function(inputValue){
if(inputValue){
    $.mobile.loading("show");
    const filePromise = (filePromiseOrFile && filePromiseOrFile.then)? filePromiseOrFile: RSVP.Promise.resolve(filePromiseOrFile);
    filePromise.then((function(file){
        $.mobile.loading("hide");
        //swal.close();
        const fileExtension  =Utils.getInstance().getFileExtensionFromUrl(file.name);
        const newFilename = fileExtension? inputValue +"."+fileExtension: fileExtension;
        this.showUploadDialogAndUploadFileToSoundLibrary(Utils.getInstance().createFileWithNewName(file,newFilename) , inputValue, true, isVideo);

    }).bind(this)).catch(function(error){
        $.mobile.loading("hide");
        throw error;
    })
    
}
}).bind(this)

const isInputValueValidFunction = function(value){
return !!value;
}
const onCancelButtonClicked = function(){

}
Utils.getInstance().showDialogToCollectInput(onConfirmButtonClicked,onCancelButtonClicked, isInputValueValidFunction, window.getI18n(ti18n, "ENTER_THE_NAME_OF_THE_CLIP"), window.getI18n(ti18n, "YOU_WILL_BE_ABLE_TO_REUSE_YOUR_SAVED_CLIP"), window.getI18n(ti18n, "CANCEL"), window.getI18n(ti18n, "SAVE_CLIP"), window.getI18n(ti18n, "NAME_OF_THE_CLIP"), "");
},
 
_addHtmlStringToBody: function(htmlString) {
    // Create a temporary container
    const tempContainer = document.createElement('div');
    
    // Apply CSS styles to hide the temporary container
    tempContainer.style.display = 'none';
    
    // Set the HTML string as the innerHTML of the temporary container
    tempContainer.innerHTML = htmlString;
    
    // Extract the first child (the actual new element) from the temporary container
    const newElement = tempContainer.firstChild;

    // Append the temporary container to the body (optional)
    // document.body.appendChild(tempContainer); // This line is optional

    // Append the new element directly to the body
    newElement.style.display = 'none';
    document.body.appendChild(newElement);

    return newElement;
},


showUploadDialogAndUploadFileToSoundLibrary : function(file, providedName, requireCreatorSignup, isVideo, hideUploadWindow, progressReportFunction){


const loggedInUserUID = LocalStorageHelper.getInstance().getUserInfo()._id;
const fileSizeValid = true;
let tempDropzoneContainerEl = null;
let fileName = null;
let confirmCallback = null;
return new  RSVP.Promise((function(resolve, reject){
const uploadEementId = "uploadWrapper_"+((new Date()).getTime());
let nameOfFileToUpload =  isVideo? "userVideo_"+(new Date()).getTime()+".mp4":"userAudio_"+(new Date()).getTime()+".mp3";
let uploadInstruction = window.isMobileBrowser ? window.getI18n(ti18n, "TAP_HERE_TO_IMPORT_AN_AUDIO_FILE"): window.getI18n(ti18n, "DRAG_AUDIO_FILE_TO_IMPORT");
if(isVideo){
    uploadInstruction = window.isMobileBrowser ? window.getI18n(ti18n, "TAP_HERE_TO_IMPORT_A_VIDEO_FILE"): window.getI18n(ti18n, "DRAG_VIDEO_FILE_TO_IMPORT");
}
const uploadZoneHtml=  "<span class='uploadZonePlaceHolder' id='"+uploadEementId +"'><div class='pe-7s-upload uploadIcon' style='font-size:40px'></div><span  class='dz-message'>"+ uploadInstruction +"</span></span>";
const buttonLabels = [window.getI18n(ti18n,"CANCEL"), window.getI18n(ti18n,"IMPORT")];
let dropZoneObj =  null;
confirmCallback = (function(buttonIndex){
    if(buttonIndex ==  2){
        if(dropZoneObj){
            if(dropZoneObj.getQueuedFiles().length == 0){
                setTimeout(function(){swal.enableButtons()}, 300);
            }else{
                if(fileSizeValid){
                    const fname = dropZoneObj.getQueuedFiles()[0].name;
                    const extension = fname.substr(fname.lastIndexOf("."));
                    nameOfFileToUpload = "userFile_"+(new Date()).getTime()+extension;
                    const signatureFunctionName = isVideo? "getSignatureForTrebbleUserVideoUpload":"getSignatureForTrebbleUserAudioUpload";
                    const fileValidationFunction =  isVideo? this.getVideoFileTypeValidationFunction(): this.getAudioFileTypeValidationFunction();
                    this.validateAndSignFunctionHanlder(dropZoneObj, nameOfFileToUpload, fileValidationFunction,  signatureFunctionName ,loggedInUserUID, requireCreatorSignup).then(function(){
                        dropZoneObj.processQueue();
                    }).catch(function(error){
                        navigator.trebbleNotification.alert(error, null, false, "error");
                    })
                }

            }
        }
    }else{
        dropZoneObj.cancelUpload(file);
    }
}).bind(this);

if(hideUploadWindow){
    tempDropzoneContainerEl = this._addHtmlStringToBody(uploadZoneHtml);
}else{
    navigator.trebbleNotification.confirm(uploadZoneHtml , confirmCallback, window.getI18n(ti18n,"IMPORT_MUSIC"), buttonLabels, true, null, null, true);
}
const handlers = Utils.getInstance().getConfirmDialogDeferredHandlers(resolve, reject, this);
const onFileAccepted = (function(fileAdded, done){
    if(fileAdded){
        const file = dropZoneObj.files[0];
        fileName = file.name
        const url = URL.createObjectURL(file);
        done();

    }

}).bind(this)
const uploadFunction =isVideo? this.uploadUserBackgroundMusicVideoInWebBrowser.bind(this): this.uploadUserBackgroundMusicInWebBrowser.bind(this);
dropZoneObj = uploadFunction(nameOfFileToUpload,"#"+ uploadEementId,handlers.resolve,handlers.reject, onFileAccepted, progressReportFunction);

dropZoneObj.on("error", (function(file, error){
    //Do no throw error on screen as the promise which was to make this call will throw an error as well. This handler should not be remove as the default handler will unhide error message as soon as it is displayed
    setTimeout(function(){
        window.alertErrorMessage(error);
    }, 500);
}).bind(this));
dropZoneObj.addFile(file)
confirmCallback(2);


}).bind(this)).then((function(uploadedFileURL){
if(uploadedFileURL){
    return Utils.getInstance().getMediaMetadata(uploadedFileURL).then((function(metadata){
        const {duration} = metadata;
        return TrebbleClientAPIHelper.getInstance().addUploadedMediaToUserLibrary(providedName? providedName: fileName, uploadedFileURL, {duration: duration*1000, uri: uploadedFileURL, lastUdpdatedDate: new Date()}, metadata.isVideo, metadata);
    }).bind(this))
    
}
}).bind(this)).then((function(trebbleAudioJSON){
    if(tempDropzoneContainerEl){
        tempDropzoneContainerEl.remove(); 
    }
return trebbleAudioJSON;
}).bind(this))
},

uploadUserProfileImageInWebBrowser: function(nameOfFileToUpload, uploadElementSelector, resolve, reject, progressReportFunction) {
const mimeTypes = IMAGE_MIME_TYPE;
const accpetedFileType = [".jpg", ".png", ".bmp"];
return this._uploadFileInWebBrowser(uploadElementSelector, nameOfFileToUpload, mimeTypes, accpetedFileType, "getSignatureForUserProfileImageUpload", resolve, reject, null, null, null, null, null, progressReportFunction );
},

uploadTrebbleCoverImageInWebBrowser: function(nameOfFileToUpload, uploadElementSelector, resolve, reject, progressReportFunction) {
const mimeTypes = IMAGE_MIME_TYPE;
const accpetedFileType = [".jpg", ".png", ".bmp"];
return this._uploadFileInWebBrowser(uploadElementSelector, nameOfFileToUpload, mimeTypes, accpetedFileType, "getSignatureForTrebbleCoverArtUpload", resolve, reject, null, null, null, null, null, progressReportFunction );
},

uploadTrebbleSweeperInWebBrowser: function(nameOfFileToUpload, uploadElementSelector, resolve, reject, onFileAccepted, progressReportFunction) {
const mimeTypes = window.isIOSMobileDevice? null: AUDIO_MIME_TYPE;
const accpetedFileType = [".mp3", ".wav",".m4a",".oga"];
return this._uploadFileInWebBrowser(uploadElementSelector, nameOfFileToUpload, mimeTypes, accpetedFileType, "getSignatureForTrebbleSweeperUpload", resolve, reject, false, null, null, null, onFileAccepted, progressReportFunction);
},

uploadTrebbleGreaterInWebBrowser: function(nameOfFileToUpload, uploadElementSelector, resolve, reject, onFileAccepted, progressReportFunction) {
const mimeTypes = window.isIOSMobileDevice? null: AUDIO_MIME_TYPE;
const accpetedFileType = [".mp3", ".wav",".m4a",".oga"];
return this._uploadFileInWebBrowser(uploadElementSelector, nameOfFileToUpload, mimeTypes, accpetedFileType, "getSignatureForTrebbleGreaterUpload", resolve, reject, false, null, null, null, onFileAccepted, progressReportFunction);
},

uploadTrebbleIntroInWebBrowser: function(nameOfFileToUpload, uploadElementSelector, resolve, reject, onFileAccepted, progressReportFunction) {
const mimeTypes = window.isIOSMobileDevice? null: AUDIO_MIME_TYPE;
const accpetedFileType = [".mp3", ".wav",".m4a",".oga"];
return this._uploadFileInWebBrowser(uploadElementSelector, nameOfFileToUpload, mimeTypes, accpetedFileType, "getSignatureForTrebbleIntroUpload", resolve, reject, false, null, null, null, onFileAccepted, progressReportFunction);
},

uploadTrebbleOutroInWebBrowser: function(nameOfFileToUpload, uploadElementSelector, resolve, reject, onFileAccepted, progressReportFunction) {
const mimeTypes = window.isIOSMobileDevice? null: AUDIO_MIME_TYPE;
const accpetedFileType = [".mp3", ".wav",".m4a",".oga"];
return this._uploadFileInWebBrowser(uploadElementSelector, nameOfFileToUpload, mimeTypes, accpetedFileType, "getSignatureForTrebbleOutroUpload", resolve, reject, false, null, null, null, onFileAccepted, progressReportFunction);
},

uploadCapsuleInWebBrowser: function(nameOfFileToUpload, uploadElementSelector, resolve, reject, onFileAccepted, progressReportFunction) {
const mimeTypes = window.isIOSMobileDevice? null: AUDIO_AND_VIDEO_MIME_TYPE;
const accpetedFileType = [".mp3", ".wav",".m4a",".oga", ".mp4",".mov", ".webm", ".mpeg", ".ogg"];
return this._uploadFileInWebBrowser(uploadElementSelector, nameOfFileToUpload, mimeTypes, accpetedFileType, "getSignatureForCapsuleUpload", resolve, reject, true, null, null, null, onFileAccepted, progressReportFunction);
},

uploadUserBackgroundMusicInWebBrowser: function(nameOfFileToUpload, uploadElementSelector, resolve, reject, onFileAccepted, progressReportFunction) {
const mimeTypes = window.isIOSMobileDevice? null: AUDIO_MIME_TYPE;
const accpetedFileType = [".mp3", ".wav",".m4a",".oga"];
return this._uploadFileInWebBrowser(uploadElementSelector, nameOfFileToUpload, mimeTypes, accpetedFileType, "getSignatureForTrebbleUserBackgroundMusicUpload", resolve, reject, false, null, null, null, onFileAccepted, progressReportFunction);
},

uploadUserAudioInWebBrowser: function(nameOfFileToUpload, uploadElementSelector, resolve, reject, onFileAccepted, progressReportFunction) {
    return this.uploadUserBackgroundMusicInWebBrowser(nameOfFileToUpload, uploadElementSelector, resolve, reject, onFileAccepted, progressReportFunction);
},

uploadUserBackgroundMusicVideoInWebBrowser: function(nameOfFileToUpload, uploadElementSelector, resolve, reject, onFileAccepted, progressReportFunction) {
const mimeTypes = window.isIOSMobileDevice? null: AUDIO_AND_VIDEO_MIME_TYPE;
const accpetedFileType = [".mp3", ".wav",".m4a",".oga", ".mp4",".mov", ".webm", ".mpeg", ".ogg"];
return this._uploadFileInWebBrowser(uploadElementSelector, nameOfFileToUpload, mimeTypes, accpetedFileType, "getSignatureForTrebbleUserBackgroundMusicVideoUpload", resolve, reject, false, null, null, null, onFileAccepted, progressReportFunction);
},

uploadUserVideoInWebBrowser: function(nameOfFileToUpload, uploadElementSelector, resolve, reject, onFileAccepted, progressReportFunction) {
    return this.uploadUserBackgroundMusicVideoInWebBrowser(nameOfFileToUpload, uploadElementSelector, resolve, reject, onFileAccepted, progressReportFunction);
},


_uploadCapsulePartForSongInWebBrowser: function(blob, parentEl$, capsuleIdOrSongUri, fileUploadInfoArray, isCapsuleReply, alreadyUploadedInBytes, totalToUploadSizeInBytes, progressBarController, functionNameToRetrieveUploadSignature, requireCreatorSignup) {
return new RSVP.Promise((function(resolve, reject) {
const mimeTypes = window.isIOSMobileDevice? null: AUDIO_AND_VIDEO_MIME_TYPE;
const uploadElementId = "uploadWrapper_" + ((new Date()).getTime());
parentEl$.append("<div id='" + uploadElementId + "' style='display: none;'></div>");
const fileExtension = Utils.getInstance().getFileExtenstionForMimeType(blob.type);
const nameOfFileToUpload = "capsulePart_" + (new Date()).getTime() + (fileExtension?"."+fileExtension: "");
const accpetedFileType = [".mp3", ".wav",".m4a",".oga",".mp4",".mov", ".webm", ".mpeg", ".ogg"];
let apiMethodToCall = fileExtension === "mp3"? "getSignatureForCapsuleMP3Upload": "getSignatureForCapsuleUpload";
if (isCapsuleReply) {
    apiMethodToCall = "getSignatureForCapsuleReplyUpload";
}
if (!capsuleIdOrSongUri) {
    apiMethodToCall = fileExtension === "mp3"? "getSignatureForCapsuleMP3Upload":"getSignatureForCapsuleWithNoSongUpload";
}
if(functionNameToRetrieveUploadSignature){
    apiMethodToCall = functionNameToRetrieveUploadSignature;
}
const dropzoneObj = this._uploadFileInWebBrowser("#" + uploadElementId, nameOfFileToUpload, mimeTypes, accpetedFileType, apiMethodToCall, resolve, reject, false, alreadyUploadedInBytes, totalToUploadSizeInBytes, progressBarController);
dropzoneObj.addFile(blob);
this.validateAndSignFunctionHanlder(dropzoneObj, nameOfFileToUpload, null, apiMethodToCall, capsuleIdOrSongUri, requireCreatorSignup).then((function() {
    const f = dropzoneObj.getAcceptedFiles().length > 0 ? dropzoneObj.getAcceptedFiles()[0] : dropzoneObj.getQueuedFiles()[0];
    const sigInfo = f.sigInfo;
    const uploadUrl = sigInfo.uploadUrl;
    const fileKey = sigInfo.filenameKey;
    const originalFilename = sigInfo.originalFilename;
    const isVideo = sigInfo.isVideo;
    const completeFileUrl = uploadUrl + fileKey;
    const fileUploadInfo = this._buildFileUploadInfo(completeFileUrl, fileKey, null, null, new Date(), blob.size, originalFilename, isVideo);

    dropzoneObj.processQueue();
    parentEl$.remove("#" + uploadElementId);
    fileUploadInfoArray.push(fileUploadInfo);
}).bind(this)).catch(function(error) {
    reject(error);
    parentEl$.remove("#" + uploadElementId);
})
}).bind(this));
},

markBlobsSoTheyCanBeReuploadedViaDropzone : function(blobArray){
for(let i =0; i < blobArray.length; i++){
const blob  =blobArray[i];
blob.accepted = false;
}
},

uploadAudioHigightsInWebBrowser: function(audioHighightFilenameInfo, capsuleIdOrSongUri, parentEl$, isCapsuleReply, totalToUploadSizeInBytes, progressBarController, functionNameToRetrieveUploadSignature, reuploadIfAlreadyUploaded, requireCreatorSignup) {
if(reuploadIfAlreadyUploaded){
this.markBlobsSoTheyCanBeReuploadedViaDropzone(audioHighightFilenameInfo);
}

return this._uploadCapsulePartInWebBrowserRecursive(0, audioHighightFilenameInfo, capsuleIdOrSongUri, {}, [], parentEl$, isCapsuleReply, 0, totalToUploadSizeInBytes, progressBarController, functionNameToRetrieveUploadSignature, requireCreatorSignup).then((function(fileUploadInfoArray) {
const promiseArray = [];
fileUploadInfoArray.forEach((function(fileUploadInfo){
    if(!fileUploadInfo.duration){
        promiseArray.push(this._updateDurationOnUploadFileInfo(fileUploadInfo));
    }
}).bind(this))
return RSVP.Promise.all(promiseArray).then((function(){
    return fileUploadInfoArray;
}).bind(this));
}).bind(this));
},

_uploadCapsulePartInWebBrowserRecursive: function(filenameIndex, audioHighightFilenameInfo, capsuleIdOrSongUri, filenameToFinalFileUploadMap, fileUploadInfoArray, parentEl$, isCapsuleReply, alreadyUploadedInBytes, totalToUploadSizeInBytes, progressBarController, functionNameToRetrieveUploadSignature, requireCreatorSignup) {
const fileUploadInfo = null;
if (audioHighightFilenameInfo && audioHighightFilenameInfo.length > 0 && filenameIndex < audioHighightFilenameInfo.length) {
const blob = audioHighightFilenameInfo[filenameIndex];
return this._uploadCapsulePartForSongInWebBrowser(blob, parentEl$, capsuleIdOrSongUri, fileUploadInfoArray, isCapsuleReply, alreadyUploadedInBytes, totalToUploadSizeInBytes, progressBarController,functionNameToRetrieveUploadSignature, requireCreatorSignup).then((function() {
    //fileUploadInfoArray.push(uploadResult);
    return this._uploadCapsulePartInWebBrowserRecursive(filenameIndex + 1, audioHighightFilenameInfo, capsuleIdOrSongUri, filenameToFinalFileUploadMap, fileUploadInfoArray, parentEl$, isCapsuleReply, alreadyUploadedInBytes + blob.size, totalToUploadSizeInBytes, progressBarController, functionNameToRetrieveUploadSignature, requireCreatorSignup);
}).bind(this))
} else {
return RSVP.Promise.resolve(fileUploadInfoArray);
}

},



_getFileTypeValidationFunction: function(accpetedFileExtendions) {
return (function(file) {
if (this.accpetedFileExtendions) {
    return (new RegExp('(' + accpetedFileExtendions.join('|').replace(/\./g, '\\.') + ')$')).test(file.name);
} else {
    return true;
}
}).bind({
"self": this,
"accpetedFileExtendions": accpetedFileExtendions
});
},

getImageFileTypeValidationFunction: function() {
const accpetedFileType = [".jpg", ".png", ".bmp"];
return this._getFileTypeValidationFunction(accpetedFileType);
},

getAudioFileTypeValidationFunction: function() {
const accpetedFileType = [".mp3", ".wav",".m4a",".oga"];
return this._getFileTypeValidationFunction(accpetedFileType);
},

getVideoFileTypeValidationFunction: function() {
const accpetedFileType = [".mp3", ".wav",".m4a",".oga", ".mp4",".mov", ".webm", ".mpeg", ".ogg"];
return this._getFileTypeValidationFunction(accpetedFileType);
},

_uploadFileInWebBrowser: function(uploadElementSelector, nameOfFileToUpload, mimeTypes, accpetedFileType, signatureFunctionNameToCall, resolve, reject, returnFileUploadInfoArray, alreadyUploadedInBytes, totalToUploadSizeInBytes, progressBarController, onFileAccepted, progressReportFunction) {
const params = {
url: "fakeUrl",
method: "post",
autoProcessQueue: false,
clickable: uploadElementSelector + ", " + uploadElementSelector + " > *:not(.dz-message)", //upload element and all its children elements will show upload window
maxFiles: 1,
parallelUploads: 3,
maxFilesize: 2000, //2GB
//maxFilesize: 20,
// in mb
//maxThumbnailFilesize: 8,
// 3MB
//thumbnailWidth: 150,
//thumbnailHeight: 150,
thumbnailMethod: "crop",
addRemoveLinks: true,
acceptedFiles: mimeTypes,
dictInvalidFileType: window.getI18n(ti18n,"YOU_CANT_UPLOAD_FILES_OF_THIS_TYPE")+" "+ window.getI18n(ti18n,"ONLY_FILES_WITH_EXTENSION")+" "+ accpetedFileType.join(', ') +" "+ window.getI18n(ti18n,"ARE_ALLOWED")
};
if (onFileAccepted) {
params.accept = onFileAccepted
}
const d = new DropzoneAsync(uploadElementSelector, params);

d.on("uploadprogress", this._fileUploadProgressHandler(d, nameOfFileToUpload, alreadyUploadedInBytes, totalToUploadSizeInBytes, progressBarController, progressReportFunction));
d.on("accept", this._getDropzoneValidateAndSignFunctionHanlder(d, nameOfFileToUpload, this._getFileTypeValidationFunction(accpetedFileType), signatureFunctionNameToCall, reject));
d.on("sending", this._getDefaultDropzoneSendingFunctionHandler(reject));
d.on("addedFile", this._onFileAddedHandler(1, d));
if (returnFileUploadInfoArray) {
d.on("success", this._getDefaultDropzoneSuccessCallbackWithFileInfoArrayReturn(resolve));
} else {
d.on("success", this._getDefaultDropzoneSuccessCallback(resolve));
}
d.on("error", this._getDefaultDropzoneFailureCallback(reject));

return d;

},

_onFileAddedHandler: function(maxFile, dropzoneObject) {
return (function(file) {
file.previewElement.addEventListener("click", function() {
    this.dropzoneObject.removeFile(file);
});
if (this.dropzoneObject.files.length > this.maxFile) {
    //earliest added file
    this.dropzoneObject.removeFile(this.dropzoneObject.files[0]);
}
}).bind({
"dropzoneObject": dropzoneObject,
"maxFile": maxFile,
"self": this
});
},

_fileUploadProgressHandler: function(dropzoneObj, nameOfFileToUpload, alreadyUploadedInBytes, totalToUploadSizeInBytes, progressBarController, progressReportFunction) {
return (function(file, progressInPercentage, byteSent) {
    if(progressReportFunction){
        progressReportFunction(progressInPercentage)
    }
if (progressBarController && totalToUploadSizeInBytes) {
    const currentlyUploadedInBytes = alreadyUploadedInBytes + (file.size * progressInPercentage / 100);
    progressBarController.setPercentageProgress(Math.round(100 * currentlyUploadedInBytes / totalToUploadSizeInBytes) + "%");
}
}).bind({
"nameOfFileToUpload": nameOfFileToUpload,
"alreadyUploadedInBytes": alreadyUploadedInBytes,
"totalToUploadSizeInBytes": totalToUploadSizeInBytes,
"self": this
});
},

_getDropzoneValidateAndSignFunctionHanlder: function(dropzoneObj, nameOfFileToUpload, validateFileFunction, signatureFunctionNameToCall, errorCb) {
return (function(file, done) {
if (this.validateFileFunction) {
    try {
        this.validateFileFunction(file);
        if (this.signatureFunctionNameToCall) {
            let isAVideo  = false;
            return Utils.getInstance().isVideoFile(URL.createObjectURL(file)).then((function(isVid){
                isAVideo =  isVid;
                return TrebbleClientAPIHelper.getInstance()[this.signatureFunctionNameToCall](this.nameOfFileToUpload);
            }).bind(this)).then((function(result) {
                file.postData = (result && result.signature) ? result.signature : null;
                file.sigInfo = result;
                file.sigInfo.originalFilename =  file.name;
                file.sigInfo.isVideo =  isAVideo;
                file.nameOfFileToUpload = nameOfFileToUpload;
                file.custom_status = 'ready';
                file.s3 = file.postData.key;
                if (result && result.uploadUrl && dropzoneObj) {
                    dropzoneObj.options.url = result.uploadUrl;
                    file.uploadUrl = result.uploadUrl;
                    file.completeUrl = result.url;
                    //dropzoneObj.url = result.uploadUrl;
                }
                done();
            }).bind(this)).catch((function(e) {
                //done(e);  
                if (this.errorCb) {
                    this.errorCb(e);
                }
            }).bind(this))
        }

    } catch (error) {
        // done(error);
        if (this.errorCb) {
            this.errorCb(error);
        }
    }
}
}).bind({
"signatureFunctionNameToCall": signatureFunctionNameToCall,
"nameOfFileToUpload": nameOfFileToUpload,
"errorCb": errorCb,
"validateFileFunction": validateFileFunction,
"self": this
});
},

validateAndSignFunctionHanlder: function(dropzoneObj, nameOfFileToUpload, validateFileFunction, signatureFunctionNameToCall, extraSignParams, requireCreatorSignup) {
return new RSVP.Promise((function(resolve, reject) {

try {
    const file = dropzoneObj.getAcceptedFiles().length > 0 ? dropzoneObj.getAcceptedFiles()[0] : dropzoneObj.getQueuedFiles()[0];
    if (validateFileFunction) {
        validateFileFunction(file);
    }
    if (signatureFunctionNameToCall) {
        let isAVideo = false;
        return Utils.getInstance().isVideoFile(URL.createObjectURL(file)).then((function(isVid){
            isAVideo =  isVid;
            return TrebbleClientAPIHelper.getInstance()[signatureFunctionNameToCall](nameOfFileToUpload, extraSignParams, requireCreatorSignup);
        }).bind(this)).then((function(result) {

            file.postData = (result && result.signature) ? result.signature : null;
            file.sigInfo = result;
            file.sigInfo.originalFilename = file.name;
            file.sigInfo.isVideo =  isAVideo;
            file.nameOfFileToUpload = nameOfFileToUpload;
            file.custom_status = 'ready';
            file.s3 = file.postData.key;
            if (result && result.uploadUrl && dropzoneObj) {
                dropzoneObj.options.url = result.uploadUrl;
                file.uploadUrl = result.uploadUrl;
                file.completeUrl = result.url;
                //dropzoneObj.url = result.uploadUrl;
            }
            resolve();
        }).bind(this)).catch((function(e) {
            reject(e);
        }).bind(this))
    }

} catch (error) {
    reject(error);
}

}).bind(this));

},




_getDefaultDropzoneSendingFunctionHandler: function(errorCb) {

return (function(file, xhr, formData) {
try {
    $.each(file.postData, function(k, v) {
        formData.append(k, v);
    });
} catch (error) {
    if (this.errorCb) {
        this.errorCb(error);
    }
}
}).bind({
"self": this,
"errorCb": errorCb
});
},

_getDefaultDropzoneSuccessCallback: function(successCB) {
return (function(file, res) {
if (this.successCB) {
    this.successCB(file.completeUrl);
}

}).bind({
"self": this,
"successCB": successCB
});
},
_getDefaultDropzoneSuccessCallbackWithFileInfoArrayReturn: function(successCB) {
return (function(file, res) {
if (this.successCB) {
    const f = file;
    const sigInfo = f.sigInfo;
    const uploadUrl = sigInfo.uploadUrl;
    const fileKey = sigInfo.filenameKey;
    const completeFileUrl = uploadUrl + fileKey;
    const fileUploadInfo = this.self._buildFileUploadInfo(completeFileUrl, fileKey, null, null, new Date(), null, sigInfo.originalFilename, sigInfo.isVideo);
    const onFileUploadInfoUpdated  = function(){
        successCB([fileUploadInfo])
    }
    this.self._updateDurationOnUploadFileInfo(fileUploadInfo).then(onFileUploadInfoUpdated).catch(onFileUploadInfoUpdated)
}

}).bind({
"self": this,
"successCB": successCB
});
},

_getDefaultDropzoneFailureCallback: function(errorCb) {
return (function(file, errorMessage) {
if (this.errorCb) {
    this.errorCb(errorMessage);
}

}).bind({
"self": this,
"errorCb": errorCb
});
},




});

const FileUploadHelperInstance = new FileUploadHelper();

export default {
    getInstance: function() {
        return FileUploadHelperInstance;
    }
};