import { useRef } from "react";
import { useVideoConfig, OffthreadVideo, Audio } from "remotion";
import { VideoSegmentMultiFocusDesignSystemConfig } from "../../VideoSegmentMultiFocus";
import { onVideoFrame } from "../DefaultVideoSegmentMultiFocus";
import React from "react";

export const ScreenshareFocusVideoSegment: React.FC<VideoSegmentMultiFocusDesignSystemConfig> = ({ startTimestampMS, endTimestampMS, videoURL, spacing, borderRadius, border, framing, designSystem, audioURL }) => {
    const { width, height, fps } = useVideoConfig();
    const canvasRefs = [useRef<HTMLCanvasElement>(null), useRef<HTMLCanvasElement>(null)];

    const paddingSize = spacing ? Math.sqrt(width * height) * 0.05 : 0;

    const screenBorderThickness = spacing ? Math.sqrt(width * height) / 150 : 0;
    const faceCameraBorderThickness = Math.sqrt(width * height) / 150;
    const totalSpaceTakenByVisual = paddingSize + (screenBorderThickness);

    const faceSize = (Math.min(width, height) * 0.2);
    const screenWidth = width - totalSpaceTakenByVisual;
    const screenHeight = (screenWidth / 16) * 9;

    return (
        <div style={{ position: 'relative', width: screenWidth, height: screenHeight, padding: paddingSize }}>
            <OffthreadVideo
                style={{ opacity: 0, display: 'none' }}
                src={videoURL}
                startFrom={startTimestampMS / 1000 * fps}
                endAt={endTimestampMS / 1000 * fps}
                onVideoFrame={(frame) => onVideoFrame(frame, framing[designSystem.canvas.aspectRatio].focusRegions, canvasRefs)}
                muted={audioURL == undefined ? false : true}
            />
            {audioURL && <Audio src={audioURL} />}
            <canvas
                ref={canvasRefs[1]}
                width={screenWidth}
                height={screenHeight}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: screenWidth,
                    height: screenHeight,
                    borderRadius: borderRadius,
                    border: border ? `${screenBorderThickness}px solid black` : `none`,
                    aspectRatio: '16 / 9',
                }}
            />
            <canvas
                ref={canvasRefs[0]}
                width={faceSize}
                height={faceSize}
                style={{
                    position: 'absolute',
                    top: '5%',
                    left: '5%',
                    width: faceSize,
                    height: faceSize,
                    borderRadius: borderRadius,
                    border: border ? `${faceCameraBorderThickness}px solid black` : `none`,
                    aspectRatio: '1 / 1',
                }}
            />
        </div>
    );
};