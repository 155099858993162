import { Sequence, useVideoConfig } from "remotion";
import { Caption } from "@remotion/captions";
import { ClassicCaptionPage } from "./ClassicCaptionPage";
import { createGroupedCaptions, createLineBasedCaptionsWithProperTokens, CaptionToken, CaptionPage } from "../CaptionGrouper";
import { useMemo } from "react";
import { CaptionDesignSystem } from "../Caption";
import { getMaxFontSizeFromFontSizeRatio, getMaxCharacterPerLine, getSafeZonedWidth, VideoCanvas } from "../../DesignSystem";

export type CaptionGroupingMethod = 'time' | 'lines';

export interface ClassicCaptionSeriesProps extends CaptionDesignSystem {
  groupingMethod?: CaptionGroupingMethod;
  maxLinesNumber?: number;
  showOneWordOnly: boolean;
  maxWordsPerLine?: number;
  maxCharsPerLine?: number;
  spaceCharWidth?: number;
}

export const ClassicCaptionSeries: React.FC<ClassicCaptionSeriesProps> = ({ 
  videoRenderData, 
  firstScheduledNode,
  startTimeOffset,
  milisecondGrouping, 
  designSystem: { accentColor, primaryColor, canvas }, 
  fontSizeRatio, 
  position, 
  font, 
  useDesignSystemColor, 
  mainColor, 
  highlightColor, 
  outline, 
  background, 
  textAnimation, 
  borderRadius, 
  captionCase, 
  hidePunctuation,
  groupingMethod = 'lines',
  maxLinesNumber = 2,
  showOneWordOnly,

}) => {
    // Setup and configuration
    const { fps, width, height } = useVideoConfig();
    const safeZonedWidth = getSafeZonedWidth(width, canvas);
    const maxFontSize = getMaxFontSizeFromFontSizeRatio(fontSizeRatio, width, height, canvas);
    const maxWordsPerLine = showOneWordOnly? 1: 100;
    const maxCharsPerLine = getMaxCharacterPerLine(safeZonedWidth, maxFontSize);
    const spaceCharWidth = 1;
    

    // Extract captions from video render data
    const captions = useCaptionsFromVideoData(videoRenderData, startTimeOffset);

    // Create caption pages based on grouping method
    const { pages } = useCaptionPages(
        captions, 
        groupingMethod, 
        milisecondGrouping, 
        maxLinesNumber, 
        maxWordsPerLine, 
        maxCharsPerLine, 
        spaceCharWidth
    );

    return (
        <>
            {pages.map((page, index) => renderCaptionPage(
                page, 
                index, 
                pages, 
                fps, 
                groupingMethod, 
                milisecondGrouping,
                {
                    primaryColor: useDesignSystemColor ? primaryColor : mainColor,
                    accentColor: useDesignSystemColor ? accentColor : highlightColor,
                    font,
                    canvas,
                    maxFontSize,
                    position,
                    outline,
                    background,
                    textAnimation,
                    borderRadius,
                    captionCase,
                    hidePunctuation
                }
            ))}
        </>
    );
};

// Helper function to extract captions from video data
function useCaptionsFromVideoData(videoRenderData: any[], startTimeOffset: number): Caption[] {
    return useMemo(() => {
        return videoRenderData.flatMap((segment) => {
            return segment.nodeSchedulingInfoMergedArray.flatMap((schedulingNodeInfo: any) => {
                const sequencerNodeRef = schedulingNodeInfo.sequencerNodeReference;
                const captionSegment  = sequencerNodeRef.audioSegment;
                const nodeDuration  = schedulingNodeInfo.duration? schedulingNodeInfo.duration - schedulingNodeInfo.relativeStartTime + schedulingNodeInfo.relativeEndTime: schedulingNodeInfo.endTimeOffsetForSourceBuffer - schedulingNodeInfo.startTimeOffsetForSourceBuffer - schedulingNodeInfo.relativeStartTime + schedulingNodeInfo.relativeEndTime;
                if (captionSegment.type === 'WordAudioSegment' && captionSegment.content) {

                    return {
                        text: captionSegment.content,
                        startMs: schedulingNodeInfo.startTimeOffset - startTimeOffset,
                        endMs: schedulingNodeInfo.startTimeOffset - startTimeOffset + nodeDuration,
                        timestampMs: nodeDuration,
                        confidence: Number(captionSegment.confidence),
                    }
                }
                return [];
            })
        });
    }, [videoRenderData, startTimeOffset]);
}

// Helper function to create caption pages
function useCaptionPages(
    captions: Caption[], 
    groupingMethod: CaptionGroupingMethod,
    milisecondGrouping: number,
    maxLines: number,
    maxWordsPerLine: number,
    maxCharsPerLine: number,
    spaceCharWidth: number
): { pages: CaptionPage[] } {
    return useMemo(() => {
        if (groupingMethod === 'lines') {
            return createLineBasedCaptionsWithProperTokens({
                captions,
                maxLines,
                maxWordsPerLine,
                maxCharsPerLine,
                spaceCharWidth
            });
        }
        
        return createGroupedCaptions({
            combineTokensWithinMilliseconds: milisecondGrouping,
            captions,
        });
    }, [groupingMethod, milisecondGrouping, captions, maxLines, maxWordsPerLine, maxCharsPerLine, spaceCharWidth]);
}

// Helper function to render a caption page
function renderCaptionPage(
    page: CaptionPage, 
    index: number, 
    pages: CaptionPage[], 
    fps: number, 
    groupingMethod: CaptionGroupingMethod,
    milisecondGrouping: number,
    styles: {
        primaryColor: string;
        accentColor: string;
        font: string;
        canvas: VideoCanvas;
        maxFontSize: number;
        position: string;
        outline: 'none' |  'glow' |'shadow' | '3d-shadow' | 'stroke'| '3d-stroke';
        background: 'none' |'white'| 'colored' | 'translucent';
        textAnimation: string;
        borderRadius: number;
        captionCase: string;
        hidePunctuation: boolean;
    }
) {
    const nextPage = pages[index + 1] ?? null;
    const subtitleStartFrame = (page.startMs / 1000) * fps;
    
    // Calculate end frame based on next page or duration
    const subtitleEndFrame = Math.min(
        nextPage ? (nextPage.startMs / 1000) * fps : Infinity,
        // If using time-based grouping, use milisecondGrouping
        // Otherwise, calculate based on the last token's end time
        groupingMethod === 'time' 
            ? subtitleStartFrame + milisecondGrouping
            : (page.tokens[page.tokens.length - 1]?.toMs / 1000) * fps + 30 // Add a small buffer
    );
    
    const durationInFrames = subtitleEndFrame - subtitleStartFrame;
    if (durationInFrames <= 0) {
        return null;
    }

    // For line-based grouping, we need to split the text into lines
    if (groupingMethod === 'lines' && page.text.includes('\n')) {
        return renderMultiLineCaptions(
            page, 
            index, 
            subtitleStartFrame, 
            durationInFrames, 
            styles
        );
    }

    // For time-based grouping or single-line text, use the original approach
    return renderSingleLineCaptions(
        page, 
        index, 
        subtitleStartFrame, 
        durationInFrames, 
        styles
    );
}

// Helper function to render multi-line captions
function renderMultiLineCaptions(
    page: CaptionPage, 
    index: number, 
    startFrame: number, 
    duration: number,
    styles: any
) {
    // Split by newline and filter out empty lines
    const lines = page.text.split('\n').filter(line => line.trim() !== '');
    
    // Create line pages with proper token distribution
    const linePages = createLinePages(page, lines);
    return (
        <Sequence
            key={index}
            from={startFrame}
            durationInFrames={duration}
            layout="none"
        >
            <div style={{ 
                display: 'flex', 
                flexDirection: 'column', 
                gap: '0px',
                alignItems: getAlignmentFromPosition(styles.position)
            }}>
                {linePages.map((linePage, lineIndex) => (
                    <ClassicCaptionPage 
                        key={lineIndex}
                        primaryColor={styles.primaryColor} 
                        page={linePage} 
                        fontFamily={styles.font} 
                        canvas={styles.canvas} 
                        currentSpokenWordColor={styles.accentColor} 
                        maxFontSize={styles.maxFontSize} 
                        position={styles.position} 
                        outline={styles.outline} 
                        background={styles.background} 
                        textAnimation={styles.textAnimation} 
                        borderRadius={styles.borderRadius} 
                        captionCase={styles.captionCase} 
                        hidePunctuation={styles.hidePunctuation} 
                    />
                ))}
            </div>
        </Sequence>
    );
}

// Helper function to render single-line captions
function renderSingleLineCaptions(
    page: CaptionPage, 
    index: number, 
    startFrame: number, 
    duration: number,
    styles: any
) {
    return (
        <Sequence
            key={index}
            from={startFrame}
            durationInFrames={duration}
            layout="none"
        >
            <ClassicCaptionPage 
                primaryColor={styles.primaryColor} 
                page={page} 
                fontFamily={styles.font} 
                canvas={styles.canvas} 
                currentSpokenWordColor={styles.accentColor} 
                maxFontSize={styles.maxFontSize} 
                position={styles.position} 
                outline={styles.outline} 
                background={styles.background} 
                textAnimation={styles.textAnimation} 
                borderRadius={styles.borderRadius} 
                captionCase={styles.captionCase} 
                hidePunctuation={styles.hidePunctuation} 
            />
        </Sequence>
    );
}

// Helper function to create line pages from a multi-line page
function createLinePages(page: CaptionPage, lines: string[]): CaptionPage[] {
    const linePages: CaptionPage[] = [];
    
    // Find line boundaries in the original text
    const lineBoundaries: {start: number, end: number}[] = [];
    let pos = 0;
    
    for (const line of lines) {
        const start = page.text.indexOf(line, pos);
        if (start !== -1) {
            const end = start + line.length;
            lineBoundaries.push({start, end});
            pos = end;
        }
    }
    
    // Create a page for each line with its tokens
    lineBoundaries.forEach((boundary, lineIndex) => {
        const lineText = lines[lineIndex];
        const lineTokens = getTokensForLine(page, boundary);
        
        // Only add non-empty lines
        if (lineTokens.length > 0) {
            linePages.push({
                text: lineText.trim(),
                startMs: page.startMs,
                tokens: lineTokens
            });
        }
    });
    
    return linePages;
}

// Helper function to get tokens for a specific line
function getTokensForLine(page: CaptionPage, boundary: {start: number, end: number}): CaptionToken[] {
    const lineTokens: CaptionToken[] = [];
    
    // Assign tokens to this line based on text position
    for (const token of page.tokens) {
        const tokenText = token.text;
        // Find where this token appears in the original text
        const tokenStart = page.text.indexOf(tokenText);
        
        if (tokenStart === -1) continue;
        
        // Check if this token belongs to the current line
        if (tokenStart >= boundary.start && tokenStart < boundary.end) {
            lineTokens.push(token);
        }
        // Handle tokens that span multiple lines
        else if (tokenText.includes('\n')) {
            const parts = tokenText.split('\n');
            for (let i = 0; i < parts.length; i++) {
                const part = parts[i].trim();
                if (part && page.text.indexOf(part, boundary.start) >= boundary.start && 
                    page.text.indexOf(part, boundary.start) < boundary.end) {
                    lineTokens.push({
                        text: part,
                        fromMs: token.fromMs,
                        toMs: token.toMs
                    });
                }
            }
        }
    }
    
    return lineTokens;
}

// Helper function to get alignment style from position
function getAlignmentFromPosition(position: string): string {
    switch (position) {
        case 'center': return 'center';
        case 'left': return 'flex-start';
        case 'right': return 'flex-end';
        default: return 'center';
    }
}